import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from "react"
import { useAsyncEffect } from "@react-hook/async"
import { CircularProgress } from "@rmwc/circular-progress"
import {
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText
} from "@rmwc/list"

import { MAX_SUGGESTIONS, SUGGESTION_TYPE } from "lib/ample-editor/components/link-target-menu/constants"
import TagIcon from "lib/ample-editor/components/tag-icon"
import useSelectedSuggestion from "lib/ample-editor/hooks/use-selected-suggestion"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import { tagSearchTermFromText } from "lib/ample-editor/lib/parse-link-target-text"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import { normalizeTagText, TAG_TEXT_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
function keyFromTagSuggestion(tagSuggestion) {
  const { text } = tagSuggestion;

  return `tag-${ text }`;
}

// --------------------------------------------------------------------------
function useTagSuggestions(hideTagSuggestions, searchText, suggestTags) {
  const searchTerm = tagSearchTermFromText(searchText);

  // Note that this is called less frequently than the useMemo, since searchTerm gets normalized so "blah/" becomes
  // "blah", and we don't want to call suggestTags again redundantly if we just searched for "blah"
  const { value: allTagSuggestions } = useAsyncEffect(() => suggestTags(searchTerm), [ searchTerm ]);

  return useMemo(
    () => {
      if (!allTagSuggestions) return null;

      // If the user has typed the name of a tag exactly, followed by a slash, let them keep on with that tag
      const shouldUseMatchingTag = searchText.endsWith(TAG_TEXT_DELIMITER) &&
        // but let the user type another slash to go back to tag mode, e.g. "blah//" (with a tag named "blah")
        !searchText.endsWith(TAG_TEXT_DELIMITER + TAG_TEXT_DELIMITER) &&
        allTagSuggestions.find(({ text: tagText }) => searchTerm === tagText);

      if (shouldUseMatchingTag) {
        hideTagSuggestions();
        return null;
      }

      const tagSuggestions = allTagSuggestions.slice(0, MAX_SUGGESTIONS);

      if (searchTerm.length > 0) {
        if (tagSuggestions.length === 0) {
          tagSuggestions.push({ type: SUGGESTION_TYPE.NEW, text: normalizeTagText(searchTerm) });
        } else if (searchTerm.includes(TAG_TEXT_DELIMITER)) {
          const newTagText = normalizeTagText(searchTerm, { allowInvalidDelimiters: true });

          // If they have typed a new subtag in - e.g. "/zbaz/a/" - we want to suggest using it even if there's a
          // match like `zbaz/subtag`
          const haveParentTagMatch = tagSuggestions.find(({ text: tagSuggestionText }) => {
            const parentTagText = tagSuggestionText.split(TAG_TEXT_DELIMITER).slice(0, -1).join(TAG_TEXT_DELIMITER);
            return parentTagText && newTagText.startsWith(parentTagText + TAG_TEXT_DELIMITER);
          });

          if (haveParentTagMatch) {
            tagSuggestions.push({ type: SUGGESTION_TYPE.NEW, text: normalizeTagText(newTagText) });
          }
        }

        // Making a suggestion entry out of the "go back" option - instead of handling it during rendering only - makes
        // keyboard interaction handling simpler
        tagSuggestions.push({ type: SUGGESTION_TYPE.CANCEL });
      }

      return tagSuggestions;
    },
    [ allTagSuggestions, searchText ]
  );
}

// --------------------------------------------------------------------------
function TagSuggestion({ acceptTagSuggestion, isActiveSuggestion, tagSuggestion }) {
  const { html, text, ...tagMetadata } = tagSuggestion;

  const { shares } = tagMetadata;

  let sharedTagWarning = null;
  if (shares) {
    sharedTagWarning = (
      <ListItemSecondaryText className="shared-tag-warning">
        <i className="material-icons">error</i>
        <span className="text">Tag is shared with { shares } user{ shares === 1 ? "" : "s" }</span>
      </ListItemSecondaryText>
    );
  }

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptTagSuggestion(tagSuggestion);
    },
    [ tagSuggestion ]
  );

  return (
    <ListItem
      className="link-target-menu-item popup-list-item"
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isActiveSuggestion }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ <TagIcon { ...tagMetadata } /> } />
      <ListItemText>
        <ListItemPrimaryText>
          { html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : text }
        </ListItemPrimaryText>

        { sharedTagWarning }
      </ListItemText>
      { isActiveSuggestion ? <ListItemMeta icon="keyboard_return" /> : null }
    </ListItem>
  );
}

// --------------------------------------------------------------------------
export default function TagSuggestions(props, ref) {
  const {
    acceptNewTagSuggestion,
    acceptSuggestionOnTab,
    acceptTagSuggestion,
    hideTagSuggestions,
    insertContentMode,
    scrollToSuggestion,
    searchText,
    suggestTags,
  } = props;

  const tagSuggestions = useTagSuggestions(hideTagSuggestions, searchText, suggestTags);

  const selectedIndex = useSelectedSuggestion(
    hideTagSuggestions,
    ref,
    tagSuggestion => {
      if (tagSuggestion) {
        switch (tagSuggestion.type) {
          case SUGGESTION_TYPE.CANCEL:
            hideTagSuggestions();
            break;

          case SUGGESTION_TYPE.NEW:
            acceptNewTagSuggestion();
            break;

          default:
          case SUGGESTION_TYPE.EXISTING:
            acceptTagSuggestion(tagSuggestion);
            break;

        }
      } else {
        acceptNewTagSuggestion();
      }
    },
    acceptSuggestionOnTab,
    tagSuggestions
  );

  const haveUpdatedRef = useRef(false);
  useEffect(
    () => {
      if (haveUpdatedRef.current) {
        scrollToSuggestion(selectedIndex);
      } else {
        haveUpdatedRef.current = true
      }
    },
    [ selectedIndex ]
  );

  const onHideTagSuggestionsClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      hideTagSuggestions();
    },
    []
  );

  const onUseNewTagClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptNewTagSuggestion();
    },
    []
  );

  if (!tagSuggestions) {
    return (
      <ListItem disabled key="loading-tags">
        <ListItemGraphic icon={ <CircularProgress size="xsmall"/> }/>
        Loading tags.
      </ListItem>
    );
  } else if (tagSuggestions.length === 0) {
    return (
      <ListItem disabled key="tags-placeholder">Type to search for a tag.</ListItem>
    );
  } else {
    return tagSuggestions.map((tagSuggestion, index) => {
      const isSelected = selectedIndex === index;

      switch (tagSuggestion.type) {
        case SUGGESTION_TYPE.CANCEL: {
          const icon = isSelected ? "keyboard_return" : <svg viewBox="0 0 24 24">
            <path d={ VECTOR_ICON_PATHS["keyboard-esc"] }/>
          </svg>;

          return (
            <ListItem
              className="link-target-menu-item popup-list-item"
              key="tags-cancel"
              onClick={ onHideTagSuggestionsClick }
              onMouseDown={ preventEventDefault }
              selected={ isSelected }
              tabIndex="-1"
            >
              <ListItemGraphic icon="arrow_back"/>
              <ListItemText>
                <ListItemPrimaryText>
                  <span>Search for a note to { insertContentMode ? "insert" : "link" }</span>
                </ListItemPrimaryText>
              </ListItemText>
              <ListItemMeta icon={ icon }/>
            </ListItem>
          );
        }

        case SUGGESTION_TYPE.NEW: {
          const { text } = tagSuggestion;

          return (
            <ListItem
              className="link-target-menu-item popup-list-item"
              key="tags-new"
              onClick={ onUseNewTagClick }
              onMouseDown={ preventEventDefault }
              selected={ isSelected }
              tabIndex="-1"
            >
              <ListItemGraphic icon={ <TagIcon/> }/>
              <ListItemText>
                <ListItemPrimaryText>
                  <span>Use new tag <span className="value">{ text }</span></span>
                </ListItemPrimaryText>
              </ListItemText>
              { isSelected ? <ListItemMeta icon="keyboard_return"/> : null }
            </ListItem>
          );
        }

        default:
        case SUGGESTION_TYPE.EXISTING:
          return (
            <TagSuggestion
              acceptTagSuggestion={ acceptTagSuggestion }
              isActiveSuggestion={ index === selectedIndex }
              key={ keyFromTagSuggestion(tagSuggestion) }
              tagSuggestion={ tagSuggestion }
            />
          );
      }
    });
  }
}

// eslint-disable-next-line no-func-assign
TagSuggestions = forwardRef(TagSuggestions);

TagSuggestions.propTypes = {
  acceptNewTagSuggestion: PropTypes.func.isRequired,
  acceptSuggestionOnTab: PropTypes.bool,
  acceptTagSuggestion: PropTypes.func.isRequired,
  hideTagSuggestions: PropTypes.func.isRequired,
  insertContentMode: PropTypes.bool,
  scrollToSuggestion: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  suggestTags: PropTypes.func.isRequired,
};

// Note that some of these have string values that used in ProseMirror code, so are set up to match those strings
const TRANSACTION_META_KEY = {
  ADD_TO_HISTORY: "addToHistory",
  APPENDED_TRANSACTION: "appendedTransaction",
  AUTOMATIC: "automatic",
  CODE_BLOCK_REDO: "codeBlockRedo",
  CODE_BLOCK_UNDO: "codeBlockUndo",
  COMPLETELY_UNDO_INPUT_RULE: "completelyUndoInputRule",
  HAS_FOCUS: "hasFocus",
  KEY_PRESSED: "keyPressed",
  OPEN_NOTE_LINK: "openNoteLink",
  PASTE: "paste",
  POINTER: "pointer",
  READONLY: "readonly",
  REJECTED: "rejected",
  RETAIN_EDITOR_FOCUS: "retainEditorFocus",
  SILENT: "silent",
  UI_EVENT: "uiEvent",
};
export default TRANSACTION_META_KEY;

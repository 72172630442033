import { defer } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { Button } from "@rmwc/button"
import { Chip, ChipSet } from "@rmwc/chip"
import scrollIntoView from "scroll-into-view-if-needed"
import Tippy from "@tippyjs/react"

import DurationControls from "lib/ample-editor/components/list-item-detail/duration-controls"
import NotifyControls from "lib/ample-editor/components/list-item-detail/notify-controls"
import RepeatControls from "lib/ample-editor/components/list-item-detail/repeat-controls"
import ScheduledDateControls from "lib/ample-editor/components/list-item-detail/scheduled-date-controls"
import DateTimeInput from "lib/ample-editor/components/task-detail/date-time-input"
import DurationInput from "lib/ample-editor/components/task-detail/duration-input"
import TaskMetadata from "lib/ample-editor/components/task-detail/task-metadata"
import { DURATION_ANCHOR_NAME, DURATION_ANCHOR_RELATION } from "lib/ample-editor/components/task-detail/util"
import { deviceSupportsHover, isApplePlatform, modKeyName } from "lib/ample-editor/lib/client-info"
import { baseKeyName, hasModKeyOnly, preventEventDefault } from "lib/ample-editor/lib/event-util"
import { amplenoteParamsFromURL, urlFromAmplenoteParams } from "lib/ample-util/amplenote-area"
import {
  changesFromDue,
  changesFromRepeat,
  changesFromStartRule,
  EMPTY_RRULE,
  flagsObjectFromFlagsString,
  flagsStringFromFlagsObject,
  REPEAT_TYPE,
  repeatTypeFromRepeat,
  startAtFromStartRule,
  TASK_ATTRIBUTES,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const TOOLTIP_DELAY = [ 1000, 150 ];

// --------------------------------------------------------------------------
const SHORTCUT_KEY_COMBO = isApplePlatform ? "ctrl" : "alt+shift";

// --------------------------------------------------------------------------
function scrollIntoViewOnFocus(event) {
  scrollIntoView(event.target, {
    behavior: "smooth",
    boundary: event.target,
    scrollMode: "if-needed",
  });
}

// --------------------------------------------------------------------------
export default class TaskDetail extends React.PureComponent {
  static propTypes = {
    autoFocus: PropTypes.bool,
    close: PropTypes.func,
    note: PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    onAttrChange: PropTypes.func.isRequired,
    onCrossOutClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onDismissClick: PropTypes.func,
    openNoteLink: PropTypes.func,
    readonly: PropTypes.bool,
    // If true, try to keep the existing `due` attribute, rather than trying to interpret how other changes the
    // user makes should affect it.
    retainExistingDue: PropTypes.bool,
    redo: PropTypes.func,
    undo: PropTypes.func,

    // Note that task attributes can also be passed, but are not listed here as they are all optional
  };

  _taskDetailRef = React.createRef();

  // --------------------------------------------------------------------------
  async componentDidMount() {
    const { autoFocus, readonly } = this.props;

    // As of 4/2021, the `autoFocus` prop of Select doesn't work, nor does the `inputRef` ever get used, so if we
    // want to auto-focus the input (as we do on mouse-based devices) we have to do it ourselves
    if (autoFocus && deviceSupportsHover) {
      const { current: taskDetail } = this._taskDetailRef;

      let focusElement;
      if (readonly) {
        focusElement = taskDetail.querySelector(".close-button");
      } else {
        focusElement = taskDetail.querySelector(".repeat-input-container .mdc-select__selected-text");
      }

      if (focusElement) focusElement.focus();
    }
  }

  // --------------------------------------------------------------------------
  render() {
    const {
      close,
      createdAt,
      due,
      dueDayPart,
      duration,
      notify,
      onAttrChange,
      readonly,
      repeat,
    } = this.props;

    const repeatType = this._getRepeatType();

    return (
      <div className="task-detail" onKeyDown={ this._handleKeyDown } ref={ this._taskDetailRef }>
        <div className="list-item-detail-controls">
          <div className="controls">
            <RepeatControls
              close={ close }
              listItemType="check_list_item"
              onAttrChange={ onAttrChange }
              onFocus={ scrollIntoViewOnFocus }
              readonly={ readonly }
              repeatType={ repeatType }
            />

            <ScheduledDateControls
              createdAt={ createdAt }
              listItemType="check_list_item"
              onFocus={ scrollIntoViewOnFocus }
              readonly={ readonly }
              repeat={ repeat }
              scheduledAt={ due }
              scheduledDayPart={ dueDayPart }
              setRepeat={ this._setRepeat }
              setRrule={ this._setRrule }
              setScheduledDate={ this._setDueDate }
              setScheduledDayPart={ this._setDueDayPart }
            />

            {
              due
                ? (
                  <NotifyControls
                    // Disable input for non-repeating due dates that have passed
                    disabled={ readonly || (repeatType === REPEAT_TYPE.NONE && due < Math.floor(Date.now() / 1000)) }
                    notify={ notify }
                    onFocus={ scrollIntoViewOnFocus }
                    readonly={ readonly }
                    setNotify={ this._setNotify }
                  />
                )
                : null
            }

            { this._renderStartControls() }
            { this._renderPriorityControls() }

            <DurationControls
              duration={ duration }
              listItemType="check_list_item"
              onFocus={ scrollIntoViewOnFocus }
              onLastControlKeyDown={ this._handleLastDurationKeyDown }
              readonly={ readonly }
              setDuration={ this._setDuration }
            />
          </div>

          { this._renderTaskMetadata() }
        </div>

        { this._renderBottomBar() }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _getRepeatType() {
    const { repeat } = this.props;
    return repeatTypeFromRepeat(repeat);
  }

  // --------------------------------------------------------------------------
  _handleKeyDown = event => {
    let name = baseKeyName(event).toLowerCase();

    if (event.shiftKey) name = "Shift-" + name;
    if (event.metaKey) name = "Meta-" + name;
    if (event.ctrlKey) name = "Ctrl-" + name;
    if (event.altKey) name = "Alt-" + name;

    if (name === (isApplePlatform ? "Meta-z" : "Ctrl-z")) {
      const { undo } = this.props;
      if (undo) {
        event.preventDefault();
        undo();
      }
    } else if (name === (isApplePlatform ? "Meta-Shift-z" : "Ctrl-Shift-z")) {
      const { redo } = this.props;
      if (redo) {
        event.preventDefault();
        redo();
      }
    } else if (!isApplePlatform && name === "Ctrl-y") {
      const { redo } = this.props;
      if (redo) {
        event.preventDefault();
        redo();
      }
    } else if (name === (isApplePlatform ? "Meta-." : "Ctrl-.")) {
      const { close } = this.props;
      if (close) {
        event.preventDefault();
        close({ focus: true });
      }
    } else if (name === (isApplePlatform ? "Ctrl-1" : "Alt-Shift-1")) {
      event.preventDefault();
      this._setDuration("PT15M");
    } else if (name === (isApplePlatform ? "Ctrl-3" : "Alt-Shift-3")) {
      event.preventDefault();
      this._setDuration("PT30M");
    } else if (name === (isApplePlatform ? "Ctrl-6" : "Alt-Shift-6")) {
      event.preventDefault();
      this._setDuration("PT60M");
    } else if (name === (isApplePlatform ? "Ctrl-i" : "Alt-Shift-i")) {
      event.preventDefault();
      this._toggleImportant();
    } else if (name === (isApplePlatform ? "Ctrl-u" : "Alt-Shift-u")) {
      event.preventDefault();
      this._toggleUrgent();
    }
  };

  // --------------------------------------------------------------------------
  _handleLastDurationKeyDown = event => {
    if (event.key === "Tab" && !event.shiftKey) {
      const { close } = this.props;
      if (close) {
        // Need to defer this because the calling function expects the Chip to still exist after this is called, but
        // closing will immediately unmount and remove the Chip, resulting in a
        defer(close);
      }
    }
  };

  // --------------------------------------------------------------------------
  _onNoteLinkClick = event => {
    const { note, openNoteLink, uuid } = this.props;
    if (!note || !openNoteLink) return;

    event.preventDefault();

    const { url } = note;

    // Add highlightTaskUUID to the note URL
    const amplenoteParams = amplenoteParamsFromURL(url);
    if (amplenoteParams && amplenoteParams.note) {
      amplenoteParams.note = { ...amplenoteParams.note, highlightTaskUUID: uuid };
    }

    const noteURL = urlFromAmplenoteParams(amplenoteParams) || url;
    openNoteLink(noteURL, { openInSidebar: hasModKeyOnly(event) });
  };

  // --------------------------------------------------------------------------
  _renderBottomBar() {
    const { close, onCrossOutClick, onDeleteClick, onDismissClick, readonly } = this.props

    const actions = [];

    if (!readonly) {
      const isRepeating = this._getRepeatType() !== REPEAT_TYPE.NONE;

      if (onDismissClick) {
        let dismissTooltip = "Complete this task for half of assigned task score";
        if (isRepeating) dismissTooltip += ", repeating task according to schedule";
        dismissTooltip += ". Shortcut key: Alt-Shift-Space"

        actions.push(
          <Tippy
            content={ dismissTooltip }
            key="dismiss-action"
            delay={ TOOLTIP_DELAY }
            placement="top"
            touch={ false }
          >
            <Button
              className="action dismiss-button"
              onClick={ onDismissClick }
              // This is necessary to prevent the material web component forcing focus - we handle
              // focusing the editor after closing in situations where we actually want focus
              onMouseDown={ preventEventDefault }
              tabIndex="-1"
            >
              Dismiss<span className="extra-words"> Task</span>
            </Button>
          </Tippy>
        );
      }

      if (onCrossOutClick) {
        if (actions.length > 0) actions.push(<div className="divider" key="cross-out-divider" />);

        let crossOutTooltip = "Replace this task with a crossed-out bullet";
        if (isRepeating) crossOutTooltip += ", repeating task according to schedule";
        crossOutTooltip += ". Shortcut key: Ctrl-Shift-Space"

        actions.push(
          <Tippy
            content={ crossOutTooltip }
            key="cross-out-action"
            delay={ TOOLTIP_DELAY }
            placement="top"
            touch={ false }
          >
            <Button
              className="action cross-out-button"
              onClick={ onCrossOutClick }
              // This is necessary to prevent the material web component forcing focus - we handle
              // focusing the editor after closing in situations where we actually want focus
              onMouseDown={ preventEventDefault }
              tabIndex="-1"
            >
              Cross out<span className="extra-words"> Task</span>
            </Button>
          </Tippy>
        );
      }

      if (onDeleteClick) {
        if (actions.length > 0) actions.push(<div className="divider" key="delete-divider" />);

        actions.push(
          <Button
            className="action delete-button"
            key="delete-action"
            onClick={ onDeleteClick }
            // This is necessary to prevent the material web component forcing focus - we handle
            // focusing the editor after closing in situations where we actually want focus
            onMouseDown={ preventEventDefault }
            tabIndex="-1"
          >
            Delete<span className="extra-words"> Task</span>
          </Button>
        );
      }
    }

    const { note } = this.props;
    if (note) {
      const { icon, name, url } = note;

      if (actions.length > 0) {
        actions.push(<div className="divider" key={ `divider-${ actions.length }` } />);
      }

      actions.push(
        <Button
          className="action note-link"
          href={ url }
          icon={ icon }
          key="action-note-link"
          onClick={ this._onNoteLinkClick }
          // This is necessary to prevent the material web component forcing focus - we handle
          // focusing the editor after closing in situations where we actually want focus
          onMouseDown={ preventEventDefault }
          tabIndex="-1"
          tag="a"
        >
          { name }
        </Button>
      );
    }

    if (close) {
      actions.push(
        <Tippy
          content={ `Shortcut: ${ modKeyName }+.` }
          delay={ TOOLTIP_DELAY }
          key="close-action"
          placement="top"
          touch={ false }
          trigger="mouseenter"
        >
          <Button
            className="close-button"
            onClick={ close }
            // This is necessary to prevent the material web component forcing focus - we handle
            // focusing the editor after closing in situations where we actually want focus
            onMouseDown={ preventEventDefault }
            outlined
            tabIndex="-1"
          >Done</Button>
        </Tippy>
      );
    }

    if (actions.length === 0) return null;

    return (
      <div className="bottom-bar">
        { actions }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderPriorityControls() {
    const { flags, readonly } = this.props;
    const { important, urgent } = flagsObjectFromFlagsString(flags);

    return (
      <div className="control priority">
        <Tippy
          arrow={ false }
          content="Important and urgent tasks get visual indication of their priority to encourage completion"
          delay={ TOOLTIP_DELAY }
        >
          <div className="control-label">
            <span className="material-icons">star_border</span><span className="text">Priority</span>
          </div>
        </Tippy>

        <ChipSet>
          <Tippy content={ `Shortcut: ${ SHORTCUT_KEY_COMBO }+i` } delay={ TOOLTIP_DELAY } touch={ false }>
            <span>
              <Chip
                checkmark
                disabled={ readonly }
                label="Important"
                onClick={ this._toggleImportant }
                onFocus={ scrollIntoViewOnFocus }
                selected={ important }
              />
            </span>
          </Tippy>

          <Tippy content={ `Shortcut: ${ SHORTCUT_KEY_COMBO }+u` } delay={ TOOLTIP_DELAY } touch={ false }>
            <span>
              <Chip
                checkmark
                disabled={ readonly }
                label="Urgent"
                onClick={ this._toggleUrgent }
                onFocus={ scrollIntoViewOnFocus }
                selected={ urgent }
              />
            </span>
          </Tippy>
        </ChipSet>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderStartControls() {
    const { readonly, startAt, startRule } = this.props;

    let input;

    const repeatType = this._getRepeatType();
    switch (repeatType) {
      case REPEAT_TYPE.RELATIVE:
      case REPEAT_TYPE.FIXED: {
        const { due, repeat } = this.props;

        const expectedStartAt = due ? startAtFromStartRule(startRule, due, repeat) : null;

        let anchorRelation;
        let anchorName;
        if (due || (repeatType === REPEAT_TYPE.RELATIVE && repeat && repeat.length > 0)) {
          anchorRelation = DURATION_ANCHOR_RELATION.BEFORE;
          anchorName = DURATION_ANCHOR_NAME.DUE;
        } else {
          anchorRelation = DURATION_ANCHOR_RELATION.FROM;
          anchorName = DURATION_ANCHOR_NAME.NOW;
        }

        input = (
          <DurationInput
            anchorName={ anchorName }
            anchorRelation={ anchorRelation }
            disabled={ readonly }
            duration={ startRule || "" }
            expectedNextInstanceAt={ expectedStartAt }
            nextInstanceAt={ startAt }
            onFocus={ scrollIntoViewOnFocus }
            setDuration={ this._setStartRule }
            setNextInstance={ this._setStartDate }
          />
        );
      }
      break;

      case REPEAT_TYPE.NONE:
      default:
        input = (
          <DateTimeInput
            adjustPopupType="startAt"
            disabled={ readonly }
            date={ startAt ? new Date(startAt * 1000) : null }
            onDateChange={ this._setStartDate }
            onFocus={ scrollIntoViewOnFocus }
          />
        );
        break;
    }

    return (
      <div className="control start">
        <Tippy
          arrow={ false }
          content="Tasks that haven't reached this date will be hidden, allowing you to focus on the tasks at hand"
          delay={ TOOLTIP_DELAY }
        >
          <div className="control-label">
            <span className="material-icons">visibility_off</span><span className="text">Hide Until</span>
          </div>
        </Tippy>
        { input }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderTaskMetadata() {
    const { readonly } = this.props;

    return (
      <TaskMetadata
        { ...this._taskAttributes() }
        readonly={ readonly }
        setPoints={ this._setPoints }
      />
    );
  }

  // --------------------------------------------------------------------------
  _setDueDate = (dueDate, dueDayPart = null) => {
    const due = dueDate ? Math.floor(dueDate.getTime() / 1000) : null;

    const changes = changesFromDue(this._taskAttributes(), due);

    if (due && dueDayPart) changes.dueDayPart = dueDayPart;

    this.props.onAttrChange(changes);
  };

  // --------------------------------------------------------------------------
  _setDueDayPart = dueDayPart => {
    this.props.onAttrChange({ dueDayPart });
  };

  // --------------------------------------------------------------------------
  _setDuration = duration => {
    if (duration === this.props.duration) {
      this.props.onAttrChange({ duration: null });
    } else {
      this.props.onAttrChange({ duration });
    }
  };

  // --------------------------------------------------------------------------
  _setNotify = notify => {
    if (notify === this.props.notify) {
      this.props.onAttrChange({ notify: null });
    } else {
      this.props.onAttrChange({ notify });
    }
  };

  // --------------------------------------------------------------------------
  _setPoints = points => {
    this.props.onAttrChange({ points });
  };

  // --------------------------------------------------------------------------
  _setRepeat = repeat => {
    const { onAttrChange, retainExistingDue } = this.props

    const changes = retainExistingDue ? { repeat } : changesFromRepeat(this._taskAttributes(), repeat);
    onAttrChange(changes);
  };

  // --------------------------------------------------------------------------
  _setRrule = rrule => {
    const changes = changesFromRepeat(this._taskAttributes(), rrule && rrule.length > 0 ? rrule : EMPTY_RRULE);
    this.props.onAttrChange(changes);
  };

  // --------------------------------------------------------------------------
  _setStartDate = startDate => {
    const startAt = startDate ? Math.floor(startDate.getTime() / 1000) : null;
    this.props.onAttrChange({ startAt });
  };

  // --------------------------------------------------------------------------
  _setStartRule = startRule => {
    const changes = changesFromStartRule(this._taskAttributes(), startRule);
    this.props.onAttrChange(changes);
  };

  // --------------------------------------------------------------------------
  _taskAttributes = () => {
    const taskAttributes = {};

    TASK_ATTRIBUTES.forEach(attribute => {
      taskAttributes[attribute] = this.props[attribute];
    });

    return taskAttributes;
  };

  // --------------------------------------------------------------------------
  _toggleFlag = flagName => {
    const flags = flagsObjectFromFlagsString(this.props.flags);
    flags[flagName] = !flags[flagName];

    this.props.onAttrChange({ flags: flagsStringFromFlagsObject(flags) });
  };
  _toggleImportant = this._toggleFlag.bind(this, "important");
  _toggleUrgent = this._toggleFlag.bind(this, "urgent");
}

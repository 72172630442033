import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { Button } from "@rmwc/button"
import { MenuSurface, MenuSurfaceAnchor } from "@rmwc/menu"

import ListItemCommandsMenu from "lib/ample-editor/components/list-item-commands-menu"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"

// --------------------------------------------------------------------------
export default function CommandsMenuButton({ applyListItemCommand, attributes, listItemType, suggestNotes }) {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);

  const closeMenu = useCallback(() => setIsMenuOpen(false), []);
  const toggleMenu = useCallback(() => setIsMenuOpen(isMenuOpenWas => !isMenuOpenWas), []);

  const applyCommand = useCallback(
    (listItemAttributes, listItemListItemType, listItemCommand, listItemCommandOptions) => {
      applyListItemCommand(listItemAttributes, listItemListItemType, listItemCommand, listItemCommandOptions);
      closeMenu();
    },
    [ applyListItemCommand ]
  );

  return (
    <MenuSurfaceAnchor className={ `commands-menu-button ${ isMenuOpen ? "open" : "" }` }>
      <MenuSurface
        anchorCorner="bottomStart"
        onClose={ closeMenu }
        open={ isMenuOpen }
      >
        {
          isMenuOpen
            ? (
              <ListItemCommandsMenu
                applyCommand={ applyCommand }
                attributes={ attributes }
                dismiss={ close }
                listItemType={ listItemType }
                suggestNotes={ suggestNotes }
              />
            )
            : null
        }
      </MenuSurface>

      <Button
        className="action"
        icon="bolt"
        label="More options"
        onClick={ toggleMenu }
        // This is necessary to prevent the material web component forcing focus - we handle
        // focusing the editor after closing in situations where we actually want focus
        onMouseDown={ preventEventDefault }
        tabIndex="-1"
        trailingIcon="arrow_drop_down"
      />
    </MenuSurfaceAnchor>
  );
}

CommandsMenuButton.propTypes = {
  applyListItemCommand: PropTypes.func.isRequired,
  attributes: PropTypes.object.isRequired,
  listItemType: PropTypes.oneOf([ "bullet_list_item", "check_list_item" ]).isRequired,
  suggestNotes: PropTypes.func.isRequired,
};

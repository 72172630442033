import memoize from "memoize-one"
import React from "react"
import tippy from "tippy.js"

import { hrefPreviewCanHandle } from "lib/ample-editor/components/rich-footnote/href-preview"
import { deviceSupportsHover, isAndroidChrome } from "lib/ample-editor/lib/client-info"
import {
  hrefFromAttribute,
  pluginParamsFromURL,
  targetFromURL,
  urlFromLinkAttributes,
  youtubeVideoIdFromURL,
} from "lib/ample-editor/lib/link-util"
import { getLocalFileMetadata, isLocalFileURL } from "lib/ample-editor/plugins/file-plugin"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import AMPLENOTE_AREA, {
  amplenoteParamsFromRelativeURL,
  amplenoteParamsFromURL,
} from "lib/ample-util/amplenote-area"
import { isNewNoteURL } from "lib/ample-util/note-url"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"
import resolvePluginActionPromises from "lib/ample-util/resolve-plugin-action-promises"

// --------------------------------------------------------------------------
// These are types of links we have special handling of some sort for, so care
// to differentiate between them (and between them as a group vs. all other types of links)
const LINK_TYPE = {
  MAILTO: "mailto",
  NEW_NOTE: "new-note",
  NOTE: "note",
  PLUGIN: "plugin",
  PREVIEWABLE: "previewable",
  TASK: "task",
  YOUTUBE: "youtube",
};

// --------------------------------------------------------------------------
function createIconElement(nodeType, targetDestination) {
  const icon = document.createElement(nodeType);

  icon.className = "icon";
  icon.contentEditable = "false";

  if (nodeType === "a") {
    icon.rel = "noopener noreferrer";
    icon.target = targetDestination || "_blank";
  }

  // zero-width non-breaking space - we need this here so Chrome sees the icon as a valid space, otherwise extending
  // selections across the link icon won't work as expected (place cursor in normal text after a link, then hold
  // shift and press the left arrow key to expand the selection _into_ the link. when you press right arrow to move the
  // selection $head out of the link, you won't be able to, as it will jump back to selecting the entire link content
  // when $head hits the link icon).
  // This isn't necessary on Android Chrome though - and further, triggers https://bugs.chromium.org/p/chromium/issues/detail?id=612446
  // when deleting up to the edge of a link node (even if there's a still space between the cursor and the link icon),
  // dismissing the keyboard and showing the RF popup, which is pretty annoying.
  if (!isAndroidChrome) {
    icon.textContent = "\ufeff";
  }

  return icon;
}

// --------------------------------------------------------------------------
// When displaying a public note, we convert any editor links that don't have other RF properties to
// traditional-style direct links
export function transformTraditionalLink(link) {
  const description = link.getAttribute("description") || "";
  if (description.length > 0) return;

  const rawMedia = link.getAttribute("media") || null;
  const media = rawMedia ? JSON.parse(rawMedia) : null;
  if (media) return;

  const href = link.getAttribute("data-href") || "";
  if (!href) return;

  if (hrefPreviewCanHandle(href)) return;

  link.classList.add("traditional-link");

  // anchor links need to use target="_self", which they should already be set to
  if (!href.startsWith("#")) link.setAttribute("target", "_top");

  const icon = link.querySelector(".icon.has-href");
  if (icon) link.removeChild(icon);
}

// --------------------------------------------------------------------------
export default class LinkView {
  _destroyed = false;

  // --------------------------------------------------------------------------
  constructor(view, getPos, node, _decorations, { skipInitialUpdate = false } = {}) {
    this._onClick = this._onClick.bind(this, view, getPos);
    this.update = this.update.bind(this, view, getPos);

    const { attrs: { href } } = node;

    // Note that the outer element needs to be the link - if the outer element is the span, ProseMirror has some
    // confusion about what to do when text is inserted at the opening position of the node (moving the cursor backwards
    // after inserting it, such that typing in text comes out reversed).
    this.dom = document.createElement("a");
    this.dom.className = "link";
    this.dom.rel = "noopener noreferrer";
    this.dom.target = targetFromURL(href);
    this.dom.addEventListener("click", this._onClick);

    this.contentDOM = document.createElement("span");
    this.dom.appendChild(this.contentDOM);

    this.icon = createIconElement(view.editable ? "a" : "i", this.dom.target);
    this.dom.appendChild(this.icon);

    if (!skipInitialUpdate) this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy(_view) {
    this._destroyed = true;

    if (this.icon && this.icon._tippy) this.icon._tippy.destroy();

    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  update(view, _getPos, node) {
    if (node.type.name !== "link") return false;

    const { attrs, attrs: { description, href, media } } = node;

    const mediaURL = urlFromLinkAttributes(attrs);

    let localMediaURL = mediaURL;
    if (isLocalFileURL(mediaURL)) {
      const localFileMetadata = getLocalFileMetadata(view.state, mediaURL);
      if (localFileMetadata) localMediaURL = localFileMetadata.url;
    }

    // Note that we need to make sure not to read back `this.dom.href` as it won't be exactly the value that
    // we assigned to it in all cases (e.g. assigning `this.dom.href = "#blah"` then accessing `this.dom.href` will
    // result in the full URL being returned, not just the fragment we assigned).
    const effectiveHref = hrefFromAttribute(href) || localMediaURL || "";

    this.dom.href = effectiveHref;
    this.dom.target = targetFromURL(href);

    // Keep a separate un-adulterated version of the href on the node
    this.dom.setAttribute("data-href", href);

    if (description && description.length > 0) {
      if (typeof description === "string") {
        this.dom.setAttribute("description", description);
      } else {
        this.dom.setAttribute("description", JSON.stringify(description));
      }
    } else {
      this.dom.removeAttribute("description");
    }

    if (media) this.dom.setAttribute("media", JSON.stringify(media));
    else this.dom.removeAttribute("media");

    const linkType = this._memoizedLinkTypeFromHref(href);

    let iconClassName = "icon material-icons";
    if (media) {
      iconClassName += ` has-media has-href ${ media.type && media.type.startsWith("video") ? "has-video" : "has-image" }`;
    } else if (description && description.length > 0) {
      iconClassName += " has-description";

      if (Array.isArray(description) && description.length > 0 && description[0].type === "code_block") {
        iconClassName += " has-code-block";
      }
    } else if (href && href.length > 0) {
      iconClassName += " has-href";

      if (!view.editable && href === node.textContent) iconClassName += " direct-link";

      switch (linkType) {
        case LINK_TYPE.MAILTO:
          iconClassName += " mailto-link";
          break;

        case LINK_TYPE.NEW_NOTE:
          iconClassName += " new-note-link";
          break;

        case LINK_TYPE.NOTE:
          iconClassName += " note-link";
          break;

        case LINK_TYPE.PLUGIN:
          iconClassName += " plugin-link";
          break;

        case LINK_TYPE.PREVIEWABLE:
          iconClassName += " preview-link";
          break;

        case LINK_TYPE.TASK:
          iconClassName += " task-link";
          break;

        case LINK_TYPE.YOUTUBE:
          iconClassName += " has-video";
          break;

        default:
          break;
      }
    }

    // Only let the icon be clickable (to open the href in a new tab) if there's only a href, or only an image/video
    const clickableIcon = (!description || description.length === 0) && (
      (href && href.length > 0 && !media) || (media && (!href || href.length === 0))
    );
    if (clickableIcon) {
      this.icon.href = effectiveHref;
      this.icon.target = this.dom.target;

      let tooltip;
      if (linkType === LINK_TYPE.NEW_NOTE) {
        tooltip = "Click to open a new note";
      } else if (linkType === LINK_TYPE.PLUGIN) {
        tooltip = "Click to run plugin";
      } else if (this.icon.target === "_self") {
        tooltip = `Click to open this ${ linkType === LINK_TYPE.NOTE ? "note" : "link" }`;
      } else {
        tooltip = "Click to open this link in a new tab";
      }

      if (deviceSupportsHover) {
        if (this.icon._tippy) {
          this.icon._tippy.setContent(tooltip);
        } else {
          tippy(this.icon, { animation: "shift-away-subtle", content: tooltip, ignoreAttributes: true });
        }
      }
    } else {
      this.icon.removeAttribute("href");

      if (this.icon._tippy) this.icon._tippy.destroy();
    }

    this.icon.className = iconClassName;

    return true;
  }

  // --------------------------------------------------------------------------
  // Despite the icon being contentEditable="false", Chrome will let it get deleted by backspace when the cursor is
  // after it and there's content after the cursor. We'll add it back in that case, since we _don't_ want it removed.
  ignoreMutation(event) {
    if (event.type === "selection") return;

    if (event.removedNodes && event.removedNodes.length > 0) {
      // Note that this event happens when the icon itself is deleted, but also when something is inserted before the
      // parent node, which is distinguished by not having a previousSibling set
      if (!event.previousSibling) return false;

      let removedIcon = false;
      for (let i = 0; i < event.removedNodes.length; i++) {
        const node = event.removedNodes[i];

        if (node && node.classList && node.classList.contains("icon")) {
          removedIcon = node;
          break;
        }
      }

      if (removedIcon) {
        this.icon = createIconElement(removedIcon.tagName, this.dom.target);
        this.dom.appendChild(this.icon);
        return true;
      }
    } else if (event.target === this.icon && event.type === "attributes") {
      // tippy adds the aria-describedby attribute
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  _memoizedLinkTypeFromHref = memoize(href => {
    let amplenoteParams = amplenoteParamsFromURL(href);
    if (!amplenoteParams && href && href.startsWith("/")) {
      amplenoteParams = amplenoteParamsFromRelativeURL(href);
    }

    if (amplenoteParams) {
      if (amplenoteParams.area === AMPLENOTE_AREA.NOTES && amplenoteParams.note) {
        return LINK_TYPE.NOTE;
      } else if (amplenoteParams.area === AMPLENOTE_AREA.TASKS && amplenoteParams.task) {
        return LINK_TYPE.TASK;
      }
    } else if (isNewNoteURL(href)) {
      return LINK_TYPE.NEW_NOTE;
    } else if (pluginParamsFromURL(href)) {
      return LINK_TYPE.PLUGIN;
    } else if (youtubeVideoIdFromURL(href)) {
      return LINK_TYPE.YOUTUBE;
    } else if (hrefPreviewCanHandle(href)) {
      return LINK_TYPE.PREVIEWABLE;
    } else if (href && href.startsWith("mailto:")) {
      return LINK_TYPE.MAILTO;
    }

    return null;
  });

  // --------------------------------------------------------------------------
  _onClick(editorView, getPos, event) {
    // If the icon is clicked and has an actual link, let the click through to the icon (which is an anchor node) so
    // it opens the link (unless it's a plugin link, in which case we want to invoke the plugin).
    if (this.icon && event.target === this.icon && this.icon.href && this.icon.href.length > 0) {
      const pluginParams = pluginParamsFromURL(this.icon.href);
      if (pluginParams) {
        event.preventDefault();
        event.stopImmediatePropagation();

        const { props: { hostApp: { getPluginActions } } } = editorView;

        const editorContext = buildEditorContext(editorView);
        getPluginActions(
          [ PLUGIN_ACTION_TYPE.LINK_TARGET ],
          editorContext,
          pluginParams.args,
          { onlyPluginUUID: pluginParams.uuid },
        ).then(pluginActionPromises => resolvePluginActionPromises(pluginActionPromises, {
          eachPluginAction: ({ run }) => {
            run(buildEditorContext(editorView), pluginParams.args);
          },
          shouldCancel: () => this._destroyed,
        }));
      }

      return;
    }

    if (event.target && event.target.closest) {
      // An image can be wrapped in a link, in which case we want to allow interaction with the image menu buttons
      // and image overlay buttons
      if (event.target.closest(".menu-container") || event.target.closest(".image .buttons-container")) {
        return;
      }
    }

    if (!editorView.editable) {
      // Open the link directly if it matches the displayed text, or is an anchor link
      const nodePos = getPos();
      const node = editorView.state.doc.nodeAt(nodePos);
      if (node) {
        const { attrs: { description, href, media } } = node;
        if (!media && !description && (href === node.textContent || href.startsWith("#"))) return;
      }
    }

    // There doesn't appear to be a way to prevent ProseMirror's click handling at this point, but we want to stop
    // the default link handling
    event.preventDefault();
    event.stopImmediatePropagation();

    // In editable mode we can count on the cursor position opening the RF popup, but need to do so manually in
    // readonly mode
    if (!editorView.editable) {
      editorView.dispatch(setOpenLinkPosition(editorView.state.tr, getPos()));
    }
  }
}

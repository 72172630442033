import { Fragment, Slice } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"

import { selectedListItemDepth } from "lib/ample-editor/lib/list-item-commands"
import MultiListItemSelection from "lib/ample-editor/lib/multi-list-item-selection"
import { urlFromNewNoteParams } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
function expandSelection(state) {
  const { selection } = state;

  const { depth: listNodeDepth } = selectedListItemDepth(state);
  if (listNodeDepth !== null) {
    // Expand to select the entirety of list nodes touched by the selection
    let listSelection = selection;

    if (!(listSelection instanceof MultiListItemSelection)) {
      const { $anchor, $head } = listSelection;
      listSelection = MultiListItemSelection.expandToCreate($anchor, $head, listSelection.$head, state, true);
    }

    if (listSelection) {
      // The list selection is _before_ the list node position, so we want to move up a position to be inside the
      // paragraph we'll leave behind, at the correct start position for the link node.
      return { linkPos: listSelection.from + 1, selection: listSelection };
    }
  }

  return { linkPos: selection.from, selection };
}

// --------------------------------------------------------------------------
export default async function extractToLinkedNote(editorView, name, url, tagTexts) {
  const { linkPos, selection } = expandSelection(editorView.state)

  const { content } = selection.content().toJSON();

  const { props: { hostApp: { linkNote } } } = editorView;
  const noteURL = await linkNote(url || urlFromNewNoteParams({ name }), { content, tagTexts });

  const { state: { schema, tr: transform } } = editorView;

  const linkNode = schema.nodes.link.create({ href: noteURL }, [ schema.text(name) ]);
  const newNodes = [ linkNode ];

  // The link plugin will insert a space after the link if it's at the end of the parent node (so it's easier to
  // place a cursor there), but that can affect the selection, so it's better if we do it in the same transaction as
  // we set the selection in (i.e. this one).
  if (selection.$to.end() === selection.to) {
    newNodes.push(schema.text(" "));
  }

  selection.replace(transform, new Slice(Fragment.from(newNodes), 0, 0));

  transform.setSelection(TextSelection.between(
    transform.doc.resolve(linkPos + 1),
    transform.doc.resolve(linkPos + 1 + name.length)
  ));

  editorView.dispatch(transform);
}

import React from "react"
import ReactDOM from "react-dom"

import DateSuggestionMenu, { DATE_SUGGESTION_OPTION } from "lib/ample-editor/components/date-suggestion-menu"
import { deviceSupportsHover } from "lib/ample-editor/lib/client-info"
import {
  dateSuggestionPluginKey,
  parsedDateSuggestion,
  suggestionStartPos
} from "lib/ample-editor/plugins/date-suggestion-plugin"
import { hasModifierKey, hasModifierKeyExceptShift } from "lib/ample-editor/lib/event-util"
import findCheckListItem from "lib/ample-editor/lib/find-check-list-item"
import { positionPopupContainer } from "lib/ample-editor/lib/popup-util"
import { timestampFromDate } from "lib/ample-util/date"
import { DAY_PART, changesFromDue } from "lib/ample-util/tasks"

const MAX_POPUP_WIDTH = 300;

// --------------------------------------------------------------------------
export const buildApplyDateSuggestion = dateSuggestionOptionIndex => (state, dispatch) => {
  const dateSuggestion = parsedDateSuggestion(state);
  if (!dateSuggestion) return false;
  if (!Number.isInteger(dateSuggestionOptionIndex)) return false;

  const { numericTime, userSetTime, dueDatePart } = timeFromDateSuggestion(dateSuggestion);
  const checkListItem = findCheckListItem(state.doc, dateSuggestion.taskUUID);

  // This has been observed to happen in prod at a rate of 1x/week in June 2022. If we observe the circumstances that beget a clicked date suggestion not being applied, we can fix the root cause and remove this :fingerscrossed:
  if (!checkListItem) return false;

  const { node: checkListNode, nodePos } = checkListItem;
  const transaction = state.tr;
  let taskAttrs = { ...checkListNode.attrs };
  let cutText = false;

  switch (dateSuggestionOptionIndex) {
    case DATE_SUGGESTION_OPTION.START_DATE_TASK:
    case DATE_SUGGESTION_OPTION.SCHEDULE:
      if (userSetTime) {
        const dueAttrs = changesFromDue({ ...taskAttrs }, numericTime);
        if (dueDatePart) dueAttrs.dueDatePart = dueDatePart;
        taskAttrs = { ...taskAttrs, ...dueAttrs };
      } else {
        taskAttrs.due = numericTime; // Not using taskUpdate in order to combine transactions that close popup and set new task attrs
        taskAttrs.dueDayPart = DAY_PART.MORNING;
      }
      if (dateSuggestionOptionIndex === DATE_SUGGESTION_OPTION.SCHEDULE) {
        taskAttrs.startAt = numericTime;
      }
      cutText = true;
      transaction.setNodeMarkup(nodePos, null, taskAttrs);
      break;

    case DATE_SUGGESTION_OPTION.START_DATE_EVENT:
      cutText = true;
      transaction.setNodeMarkup(nodePos, state.schema.nodes.bullet_list_item, { scheduledAt: numericTime });
      break;

    case DATE_SUGGESTION_OPTION.HIDE_UNTIL:
      taskAttrs.startAt = numericTime;
      cutText = true;
      transaction.setNodeMarkup(nodePos, null, taskAttrs);
      break;

    case DATE_SUGGESTION_OPTION.DISMISS: // Intentionally blank, present only to prove that we handle all of the selectedOptions
      break;

    default:
      throw new Error(`Unimplemented date option: ${ dateSuggestionOptionIndex }.`);
  }

  if (cutText) {
    transaction.delete(dateSuggestion.pos, dateSuggestion.pos + dateSuggestion.text.length);
  }

  if (dispatch) {
    dispatch(transaction);
  }

  return true;
};

// --------------------------------------------------------------------------
export const dismissDateSuggestion = (state, dispatch) => {
  if (dispatch) {
    const cancelStartPos = suggestionStartPos(state);
    if (Number.isInteger(cancelStartPos)) {
      dispatch(state.tr.setMeta(dateSuggestionPluginKey, { cancelStartPos }));
    }
  }

  return true;
};

// --------------------------------------------------------------------------
// Approximate a specific integer date, filling in the time of day if the user's suggestion didn't specify it
const timeFromDateSuggestion = dateSuggestion => {
  const { dueDatePart, date } = dateSuggestion;
  const userSetTime = date.getMinutes() || date.getHours();
  let interpretedDateTime;
  if (userSetTime) {
    interpretedDateTime = date;
  } else {
    interpretedDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
  return { dueDatePart, numericTime: timestampFromDate(interpretedDateTime), userSetTime };
}

// --------------------------------------------------------------------------
export default class DateSuggestionPluginView {
  _dateSuggestionMenuContainer = null;
  _menuOpen = false;
  _selectedIndex = DATE_SUGGESTION_OPTION.START_DATE_TASK;

  // If this is a non-touch device (implying keyboard is available) then we'll hide the dismiss option and let them use ESC (per Liz design)
  optionCount = Object.keys(DATE_SUGGESTION_OPTION).length - (deviceSupportsHover ? 1 : 0);

  // --------------------------------------------------------------------------
  constructor(editorView) {
    this._apply = this._apply.bind(this, editorView);
    this.dismiss = this.dismiss.bind(this, editorView);

    this._dateSuggestionMenuContainer = document.createElement("div");
    this._dateSuggestionMenuContainer.className = "date-suggestion-menu-container";
    if (editorView.dom.parentNode) editorView.dom.parentNode.appendChild(this._dateSuggestionMenuContainer);

    this.update(editorView);
  }

  // --------------------------------------------------------------------------
  closeMenu() {
    if (this._dateSuggestionMenuContainer) {
      ReactDOM.unmountComponentAtNode(this._dateSuggestionMenuContainer);
    }

    this._menuOpen = false;
  }

  // --------------------------------------------------------------------------
  destroy() {
    this.closeMenu();

    this._dateSuggestionMenuContainer.remove();
    this._dateSuggestionMenuContainer = null;
  }

  // --------------------------------------------------------------------------
  dismiss(editorView) {
    this.closeMenu();

    if (editorView) {
      const { state, dispatch } = editorView;

      dismissDateSuggestion(state, dispatch);
    }
  }

  // --------------------------------------------------------------------------
  handleKeyDown(editorView, event) {
    // Since dateSuggestionPlugin calls this view in perpetuity on every keystroke, we use presence of an active
    // dateSuggestion to decide whether we should actually handle the keystroke (usually: no)
    if (!parsedDateSuggestion(editorView.state)) return false;

    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
        if (!hasModifierKey(event)) {
          this._changeSelectedIndex(editorView, event.key === "ArrowDown" ? 1 : -1);
          return true;
        }
        break;

      case "Enter":
        if (!hasModifierKey(event)) {
          // So sub-menus that use keypress don't handle this key press
          event.preventDefault();

          this._apply(this._selectedIndex);
          return true;
        }
        break;

      case "Escape":
        if (!hasModifierKey(event)) {
          this.dismiss();
          return true;
        }
        break;

      case "Tab":
        if (!hasModifierKeyExceptShift(event)) {
          event.stopImmediatePropagation();
          this._changeSelectedIndex(event.shiftKey ? -1 : 1);
          return true;
        }
        break;

      default:
        return false;
    }
  }

  // --------------------------------------------------------------------------
  isMenuOpen() {
    return this._menuOpen;
  }

  // --------------------------------------------------------------------------
  update(editorView) {
    const dateSuggestion = parsedDateSuggestion(editorView.state);
    if (dateSuggestion) {
      ReactDOM.render(
        <DateSuggestionMenu
          apply={ this._apply }
          bulletInSchema={ editorView.state.schema.nodes.bullet_list_item }
          dismiss={ this.dismiss }
          optionCount={ this.optionCount }
          selectedIndex={ this._selectedIndex }
          suggestion={ dateSuggestion }
        />,
        this._dateSuggestionMenuContainer,
        () => {
          positionPopupContainer(editorView, this._dateSuggestionMenuContainer, MAX_POPUP_WIDTH, dateSuggestion.pos);
        }
      );
    }

    this._menuOpen = !!dateSuggestion;
  }

  // --------------------------------------------------------------------------
  _apply = (editorView, dateSuggestionOptionIndex) => {
    const { dispatch, state } = editorView;

    this.dismiss();
    buildApplyDateSuggestion(dateSuggestionOptionIndex)(state, dispatch);

    // When we hide items, the pos they were under is removed so we need this to ensure editor window doesn't lose focus
    if (dateSuggestionOptionIndex === DATE_SUGGESTION_OPTION.HIDE_UNTIL) editorView.focus();
  };

  // --------------------------------------------------------------------------
  _changeSelectedIndex = (editorView, delta) => {
    if (delta < 0) {
      this._selectedIndex = this._selectedIndex === 0 ? this.optionCount - 1 : this._selectedIndex + delta;
    } else {
      this._selectedIndex = this._selectedIndex === this.optionCount - 1 ? 0 : this._selectedIndex + delta;
    }

    this.update(editorView)
  };
}

import PropTypes from "prop-types"
import React from "react"

import TooltipIcon from "./tooltip-icon"

// --------------------------------------------------------------------------
const FILE_INPUT_STYLE = {
  display: "none",
};

// --------------------------------------------------------------------------
export default class ToolbarImageButton extends React.Component {
  static propTypes = {
    insertMedia: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
  };

  // --------------------------------------------------------------------------
  shouldComponentUpdate(nextProps) {
    return nextProps.isActive !== this.props.isActive;
  }

  // --------------------------------------------------------------------------
  render() {
    const { isActive } = this.props;

    return (
      <label className="toolbar-image-button">
        <input
          accept="image/*,video/*"
          onChange={ this._onFileInputChange }
          style={ FILE_INPUT_STYLE }
          type="file"
        />

        <span className="button" data-active={ isActive }>
          <TooltipIcon icon="image" tooltip="Image or video" />
        </span>
      </label>
    );
  }

  // --------------------------------------------------------------------------
  _onFileInputChange = event => {
    if (event.target.files.length === 0) return;

    this.props.insertMedia(event.target.files[0]);

    event.target.value = null;
  };
}

import React from "react"
import LiteYouTubeEmbed from "react-lite-youtube-embed"

import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css"

// --------------------------------------------------------------------------
export default function YoutubePreview({ videoId, onLoad, startTime }) {
  return (
    <div className="preview-section youtube-preview responsive-iframe-container">
      <LiteYouTubeEmbed
        id={ videoId }
        onIframeAdded={ onLoad }
        params={ startTime ? `start=${ startTime }` : "" }
      />
    </div>
  );
}

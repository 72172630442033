import React, { useCallback, useRef, useState } from "react"
import { CircularProgress } from "@rmwc/circular-progress"

// --------------------------------------------------------------------------
// There are two ways we can embed Google Sheets:
//  - As an editable sheet, which requires the sheet to be publicly editable or the user to be logged in to
//    an account that can edit the sheet
//  - As a read-only public sheet, which requires that the sheet has been published to the web
// We'd prefer the editable version if that's possible, hence the loading sequence trying it first.
const LOAD_STATE = {
  LOADING_EDITABLE: "loading-editable",
  LOADED_EDITABLE: "loaded-editable",
  LOADING_PUBLISHED: "loading-published",
  LOADED_PUBLISHED: "loaded-published",
  LOADING_CHART: "loading-chart",
  LOADED_CHART: "loaded-chart",
  FAILED: "failed",
};

// --------------------------------------------------------------------------
const GoogleSheetsPreview = ({ chartId, documentId, onLoad: onLoadCallback }) => {
  const [ loadState, setLoadState ] = useState(chartId ? LOAD_STATE.LOADING_CHART : LOAD_STATE.LOADING_EDITABLE);

  const iframeRef = useRef(null);

  const onLoad = useCallback(
    () => {
      const iframe = iframeRef.current;
      if (!iframe) return;

      let loaded;
      if (loadState === LOAD_STATE.LOADING_EDITABLE) {
        // If you don't have access to the sheet, or aren't logged into your Google account, Google will redirect to
        // login, but adds CSP headers to prevent iFraming of the login pages. iframe.onerror doesn't get called on CSP
        // errors, and since this is a cross-domain iframe we can't check contentWindow.location to see if we were
        // redirected. Instead, we'll use a very random suggestion from https://stackoverflow.com/a/57492150 to check if
        // the iframe actually has any windows - which does work cross-domain (at least, in Firefox and Chrome) - which
        // it reliably won't if the frame was blocked due to CSP.
        loaded = iframe.contentWindow.window.length > 0;

        setLoadState(loaded ? LOAD_STATE.LOADED_EDITABLE : LOAD_STATE.LOADING_PUBLISHED);
      } else {
        // As of 11/2020 there doesn't appear to be a way to detect failure to load the published URL. The
        // contentWindow trick used for the editable URL doesn't work (length is zero when successfully or
        // unsuccessfully loaded), and attempts to probe other properties of the window result in CSP errors (again,
        // regardless of whether the page was loaded successfully or not).
        loaded = true;

        setLoadState(loadState === LOAD_STATE.LOADING_CHART ? LOAD_STATE.LOADED_CHART : LOAD_STATE.LOADED_PUBLISHED);
      }

      if (loaded && onLoadCallback) onLoadCallback();
    },
    [ loadState, onLoadCallback ]
  );

  const onError = useCallback(
    () => {
      setLoadState(LOAD_STATE.FAILED);
    },
    []
  );

  if (loadState === LOAD_STATE.FAILED) {
    return null;
  }

  let className = "preview-section google-sheets-preview responsive-iframe-container";
  let loading = false;
  if (loadState === LOAD_STATE.LOADED_CHART || loadState === LOAD_STATE.LOADED_EDITABLE || loadState === LOAD_STATE.LOADED_PUBLISHED) {
    className += " loaded";
  } else if (loadState !== LOAD_STATE.FAILED) {
    loading = true;
  }

  let src;
  switch (loadState) {
    case LOAD_STATE.LOADING_CHART:
    case LOAD_STATE.LOADED_CHART:
      src = `https://docs.google.com/spreadsheets/d/e/${ documentId }/pubchart?oid=${ chartId }`;
      break;

    case LOAD_STATE.LOADING_EDITABLE:
    case LOAD_STATE.LOADED_EDITABLE:
      src = `https://docs.google.com/spreadsheets/d/${ documentId }/edit?rm=minimal`;
      break;

    default:
      src = `https://docs.google.com/spreadsheets/d/e/${ documentId }/pubhtml?widget=true&headers=false`;
      break;
  }

  return (
    <div className={ className }>
      { loading ? <div className="loader"><CircularProgress size="large" /></div> : null }
      <iframe
        frameBorder="0"
        onError={ onError }
        onLoad={ onLoad }
        ref={ iframeRef }
        src={ src }
      />
    </div>
  );
};
export default GoogleSheetsPreview;

import {
  addDays,
  addHours,
  addMinutes,
  isAfter,
  isSameDay,
  isWeekend,
  nextMonday,
  nextSaturday,
  startOfDay,
  startOfHour,
} from "date-fns"
import { sortBy, uniqBy } from "lodash"

import { endOfWeek } from "lib/ample-util/date"

// --------------------------------------------------------------------------
// Builds the set of suggestions that should be presented to the user to quickly
// adjust the given date.
// --------------------------------------------------------------------------
const dateSuggestionsFromDate = (dateType, date, startTime) => {
  const now = new Date();
  const today = startOfDay(now);
  const dayStartHour = 9;
  const maxOptionsLength = 6; // Number looks to have come by decree of https://bonanza.atlassian.net/browse/AMPLENOTE-1253

  const options = [];

  const addOption = (optionName, optionDate) => {
    if (date && isSameDay(date, optionDate)) return;
    options.push({ name: optionName, date: optionDate });
  };

  // dateType of "due" corresponds to Start Time as of 2022
  if (dateType === "due") {
    const startsToday = date && isSameDay(now, date);

    if (startsToday) {
      addOption("In 2 days", addHours(addDays(today, 2), dayStartHour));
    } else {
      options.push({ name: "Today", date: addHours(today, dayStartHour) });
    }
  } else { // hide until
    const hour = now.getHours();

    const isHidden = date && isAfter(date, now);

    if (isHidden) {
      options.push({ name: "Now", date: startOfHour(now) });
    } else {
      if (hour < 14) {
        options.push({ name: "This afternoon", date: addMinutes(addHours(today, 14), 30) });
      } else if (hour < 17) {
        options.push({ name: "This evening", date: addHours(today, 18) });
      }
    }

    if (startTime && isAfter(startTime, now)) {
      addOption("Start time", startTime);
    }
  }

  // Order of options added isn't important since they are ultimately sorted in the `return` statement
  // Duplicates also don't matter since we'll filter unique dates before returning
  addOption("Tomorrow", addHours(addDays(today, 1), dayStartHour));
  addOption("One week", addHours(addDays(today, 7), dayStartHour));

  addOption("Monday", addHours(startOfDay(nextMonday(now)), dayStartHour));

  if (!date || !isWeekend(date)) {
    if (isWeekend(now)) {
      addOption("Next weekend", addHours(startOfDay(nextSaturday(now)), dayStartHour));
    } else {
      addOption("This weekend", addHours(startOfDay(endOfWeek(now)), dayStartHour));
    }
  }

  addOption("Three weeks", addHours(addDays(today, 21), dayStartHour));

  const uniqueOptions = uniqBy(options, option => option.date.getTime());
  const sortedOptions = sortBy(uniqueOptions, option => option.date.getTime());
  return sortedOptions.slice(0, maxOptionsLength);
};
export default dateSuggestionsFromDate;

import { Slice } from "prosemirror-model"
import { Selection } from "prosemirror-state"
import { Mapping, StepMap } from "prosemirror-transform"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import { getOpenLinkPos, setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import SetDocumentAttrsStep from "lib/ample-editor/steps/set-document-attrs-step"

// --------------------------------------------------------------------------
// This is `findDiffEnd` from prosemirror-model (reformatted) adjusted so a difference in attributes is not
// considered a difference, only differences in length that would affect positions or structurage differences.
function findPositionalDiffEnd(a, b, posA, posB) {
  for (let iA = a.childCount, iB = b.childCount; ;) {
    if (iA === 0 || iB === 0) {
      return iA === iB ? null : { a: posA, b: posB };
    }

    const childA = a.child(--iA);
    const childB = b.child(--iB);
    const size = childA.nodeSize;
    if (childA === childB) {
      posA -= size;
      posB -= size;
      continue;
    }

    if (childA.type !== childB.type) return { a: posA, b: posB };

    if (childA.isText && childA.text !== childB.text) {
      let same = 0;
      const minSize = Math.min(childA.text.length, childB.text.length);
      while (same < minSize && childA.text[childA.text.length - same - 1] === childB.text[childB.text.length - same - 1]) {
        same++;
        posA--;
        posB--;
      }
      return { a: posA, b: posB }
    }
    if (childA.content.size || childB.content.size) {
      const inner = findPositionalDiffEnd(childA.content, childB.content, posA - 1, posB - 1);
      if (inner) return inner;
    }
    posA -= size;
    posB -= size;
  }
}

// --------------------------------------------------------------------------
// This is `findDiffStart` from prosemirror-model (reformatted) adjusted so a difference in attributes is not
// considered a difference, only differences in length that would affect positions or structurage differences.
function findPositionalDiffStart(a, b, pos) {
  for (let i = 0; ; i++) {
    if (i === a.childCount || i === b.childCount) {
      return a.childCount === b.childCount ? null : pos;
    }

    const childA = a.child(i)
    const childB = b.child(i);
    if (childA === childB) {
      pos += childA.nodeSize;
      continue;
    }

    if (childA.type !== childB.type) return pos;

    if (childA.isText && childA.text !== childB.text) {
      for (let j = 0; childA.text[j] === childB.text[j]; j++) pos++;
      return pos;
    }
    if (childA.content.size || childB.content.size) {
      const inner = findPositionalDiffStart(childA.content, childB.content, pos + 1);
      if (inner !== null) return inner;
    }
    pos += childA.nodeSize;
  }
}

// --------------------------------------------------------------------------
// Produces a new editorState based on the given editorState, with the document updated to the new document. This is
// intended to be called when a user is actively viewing/editing a note, so it makes some additional adjustments to
// improve the experience of the document being swapped out while you may have your cursor in the editor (triggering
// popups/etc).
//
// See https://discuss.prosemirror.net/t/replacing-a-states-doc/634
export default function replaceDocument(editorState, newDocument) {
  const oldDocument = editorState.doc;

  // The StepMap allows us to translate positions from the old document to the new document
  const diffStart = findPositionalDiffStart(oldDocument.content, newDocument.content, 0);
  const diffEnd = findPositionalDiffEnd(oldDocument.content, newDocument.content, oldDocument.content.size, newDocument.content.size);
  // The diff might just include attributes, in which case a and b are the same size, and we don't need to perform
  // any sort of mapping on the cursor.
  const stepMap = (diffStart && diffEnd && (diffEnd.a - diffStart) !== (diffEnd.b - diffStart))
    ? new StepMap([
      diffStart,
      diffEnd.a - diffStart,
      diffEnd.b - diffStart
    ])
    : null;

  const transaction = editorState.tr.replace(0, oldDocument.content.size, new Slice(newDocument.content, 0, 0));
  transaction.step(new SetDocumentAttrsStep(editorState.schema, newDocument.attrs));
  transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);
  transaction.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);

  try {
    transaction.setSelection(editorState.selection.map(transaction.doc, stepMap || new Mapping()));
  } catch (_error) {
    // If the mapping fails (which can happen in for reasons unknown, where the position is out of range when
    // mapping through the StepMap) it's not the end of the world, since we're about to make sure it still falls
    // within the bounds of the new document.
    // This can also fail with `RangeError: Position x out of range` when _not_ mapping the position
  }

  // Ensure the selection stays within a document that may have just been shortened (if the document was completely
  // cleared out, for example, there is likely to be no stepMap).
  const { selection } = transaction;
  const endSelection = Selection.atEnd(transaction.doc);
  if (selection.from > endSelection.from || selection.to > endSelection.to) {
    transaction.setSelection(endSelection);
  }

  // We need to map the link position through as well, but we need to make sure it's still a link (hopefully the same
  // one) after the mapping
  const linkPos = getOpenLinkPos(editorState);

  if (linkPos !== null) {
    const { schema } = editorState;
    const newLinkPos = stepMap ? stepMap.map(linkPos) : linkPos;
    let linkNode = null;
    try {
      linkNode = newDocument.nodeAt(newLinkPos);
    } catch (_error) {
      // Probably a RangeError, if the position is outside the document
    }
    setOpenLinkPosition(
      transaction,
      linkNode && linkNode.type === schema.nodes.link ? newLinkPos : null
    );
  }

  return editorState.apply(transaction);
}

import { Selection, TextSelection } from "prosemirror-state"

import ExpandingListItemSelection, { expandListItemSelectionBackward } from "lib/ample-editor/lib/expanding-list-item-selection"
import { allListItemsBetween } from "lib/ample-editor/lib/list-item-util"
import { listItemDepthFromSchema } from "lib/ample-editor/lib/list-item-commands"
import { ARROW } from "lib/ample-editor/lib/selection-util"
import { nonHiddenPosAfter, nonHiddenPosBefore } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
// Called when an arrow key is pressed, this handler manually handles moving $head to an adjacent list node
// (with or without selection being retained, depending on presence of view.shiftKey), which Firefox struggles
// to figure out on its own (i.e., how should contentEditable handle a left arrow when cursor is in boundary between nodes)
//
// It only runs when selection is `MultiListItemSelection`, but it returns a TextSelection so that the
// listItemSelectionPlugin can always make the final determination about what MLIS selection should or shouldn't
// be set (even if the MLIS adjustment didn't happen as a consequence of keypress)
export const onMultiListItemSelectionArrowPress = arrowKey => (state, dispatch, view) => {
  const { selection, selection: { $head, $anchor } } = state;

  if (selection instanceof MultiListItemSelection) {
    if (dispatch) {
      let cursorPos;
      // Pick a moveIncrement such that when we adjust the cursorPos it will be at a valid location for cursor (i.e., not a list boundary)
      const { doc } = state;
      // If we're at a node boundary, we want to move out from the depth of the list node, through the paragraph node, and past the first text character to arrive at non-boundary
      const moveIncrement = $head.depth === 0 ? listItemDepthFromSchema(state.schema) + 2 : 1;
      if ([ ARROW.LEFT, ARROW.UP ].includes(arrowKey)) {
        cursorPos = nonHiddenPosBefore($head.pos - moveIncrement, state, true);
        cursorPos = (cursorPos === null ? 0 : cursorPos);
      } else {
        cursorPos = nonHiddenPosAfter($head.pos + moveIncrement, state, true);
        cursorPos = (cursorPos === null ? doc.content.size : cursorPos);
      }

      const transform = state.tr;
      if (view.input.shiftKey) {
        transform.setSelection(MultiListItemSelection.create($anchor, transform.doc.resolve(cursorPos)));
        dispatch(transform);
      } else {
        const $textAnchor = Selection.near(transform.doc.resolve(cursorPos), cursorPos > $anchor.pos ? 1 : -1).$anchor;
        transform.setSelection(TextSelection.create(transform.doc, $textAnchor.pos));
        dispatch(transform.scrollIntoView());
      }
    }

    return true;
  }
}

// --------------------------------------------------------------------------
// Return the resolved pos of the next sibling (or potentially child, if $fixedPos dictates) node after `$movingPos`.
// Will always return a position after $movingPos (or null).
// `$fixedPos` forms a coherent/continuous droppable range (i.e., that doesn't orphan node children) connected from
//    $fixedPos through the return $pos of this function (when non-null)
export const multiListItemSelectionForward = ($movingPos, $fixedPos) => {
  if (!$fixedPos) return null;
  const doc = $movingPos.node(0);
  const nextNodePos = nextListNodePos(doc.type.schema, $movingPos);

  // Early exit cases: we're at the end of the document, or we only have a nodeBefore where the $movingPos list item ends
  const $listItemEnd = doc.resolve(nextNodePos);
  if ($listItemEnd.pos === doc.content.size) return $listItemEnd;
  const nodeAfter = $listItemEnd.nodeAfter;
  const nodeBefore = $listItemEnd.nodeBefore;
  if (!nodeAfter || !nodeAfter.type.spec.isListItem) {
    if (nodeBefore && nodeBefore.type.spec.isListItem) {
      return $listItemEnd;
    } else {
      return null;
    }
  }

  let resultPos;
  let $expandFrom = doc.resolve($listItemEnd.pos === 0 ? 0 : $listItemEnd.pos - $listItemEnd.nodeBefore.nodeSize);

  // Check for whether there is a node between $fixedPos and the $listItemEnd we have derived that is a deeper
  // root connection between $fixedPos and $listItemEnd than the endpoints
  const $from = $fixedPos.pos < $listItemEnd.pos ? $fixedPos : $listItemEnd;
  const $to = $fixedPos.pos < $listItemEnd.pos ? $listItemEnd : $fixedPos;
  const $minIndent = minIndentPosBetween($from, $to, { nodeType: nodeAfter.type, firstMatch: ($fixedPos.pos > $listItemEnd.pos) });
  if ($minIndent && $minIndent.nodeAfter.attrs.indent < $expandFrom.nodeAfter.attrs.indent) {
    $expandFrom = $minIndent;
  }

  const listSelection = ExpandingListItemSelection.create($expandFrom);
  // If we're seeking toward a $fixedPos after the $expandFrom pos, and that $fixedPos is a direct descendant of
  // $expandFrom, then we can move forward one node (child) and maintain a coherent list selection
  if ($fixedPos && $fixedPos.pos > $expandFrom.pos && $fixedPos.pos <= listSelection.to && $fixedPos.nodeBefore &&
      $listItemEnd.pos > $movingPos.pos) {
    resultPos = $listItemEnd.pos;
  } else {
    resultPos = listSelection.to;
  }
  return doc.resolve(resultPos);
};

// --------------------------------------------------------------------------
// Select the previous sibling or parent before $movingPos. Will return null or a position before $movingPos in the doc.
export const multiListItemSelectionBackward = ($movingPos, $fixedPos) => {
  if (!$fixedPos) return null;
  const doc = $movingPos.node(0);
  const schema = doc.type.schema;
  const lastMaybeListNodePos = previousListNodePos(schema, $movingPos);

  // Early exit case: are we already at the start of the note?
  const $listItemStart = doc.resolve(lastMaybeListNodePos);
  const startNode = $listItemStart.nodeAfter;
  if (!startNode || !startNode.type.spec.isListItem) return null;
  if ($listItemStart.pos === 0) return $listItemStart;

  const movingTowardFixedPos = $fixedPos.pos < $movingPos.pos;
  // Since minIndentPosBetween analyzes the nodeBefore of its $to node, we need to give it a $to that
  // corresponds to the pos where our end node ($movingPos) is nodeBefore (doesn't matter if next node is a list node so we don't check)
  const $listItemEnd = doc.resolve(nextListNodePos(schema, $movingPos));
  // The chosen $moveEndpoint should maximize the range that will be scanned by minIndentPosBetween, relative to movement direction
  const $moveEndpoint = movingTowardFixedPos ? $listItemEnd : $listItemStart;
  const $from = $fixedPos.pos < $moveEndpoint.pos ? $fixedPos : $moveEndpoint;
  const $to = $fixedPos.pos < $moveEndpoint.pos ? $moveEndpoint : $fixedPos;
  const firstMatch = !movingTowardFixedPos; // If $fixedPos is later in note, we'll try to expand from the first pos we find, which will be closer to $movingPos
  const $minIndent = minIndentPosBetween($from, $to, { nodeType: startNode.type, firstMatch });

  const minSharedIndent = $minIndent && $minIndent.nodeAfter ? ($minIndent.nodeAfter.attrs.indent || 0) : 0;
  let startPos;
  // If the minimum indent between start and end pos is greater than zero, it implies they share a parent and
  // we need to derive a sibling startPos
  if (minSharedIndent > 0) {
    startPos = previousIndentedListNodePos($minIndent, $listItemStart, minSharedIndent);
  }

  // If we didn't find a more precise indent level above, then we will return to indent level 0 of this node's parent hierarchy
  if (typeof(startPos) !== "number") {
    const backSelection = expandListItemSelectionBackward($listItemStart);
    startPos = backSelection && backSelection.$start.pos;
    startPos = typeof(startPos) === "number" ? startPos : $listItemStart.pos
  }

  return doc.resolve(startPos);
}

// --------------------------------------------------------------------------
// Local/private methods
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Move backward from $movingPos through the minimum indent level
const previousIndentedListNodePos = ($movingPos, $fallbackPos, indent) => {
  let backSelection = expandListItemSelectionBackward($movingPos, indent);
  let startPos = (backSelection && backSelection.$start.pos);
  startPos = typeof(startPos) === "number" ? startPos : $movingPos.pos;

  // Min indent position did not succeed in moving selection backwards, so we need to indent from $fallbackPos instead
  if (startPos >= $movingPos.pos) {
    backSelection = expandListItemSelectionBackward($fallbackPos, indent);
    startPos = (backSelection && backSelection.$start.pos);
    startPos = typeof(startPos) === "number" ? startPos : $fallbackPos.pos;
  }

  return startPos;
}

const previousListNodePos = (schema, $pos) => $pos.before(listItemDepthFromSchema(schema));
const nextListNodePos = (schema, $pos) => $pos.after(listItemDepthFromSchema(schema));

// --------------------------------------------------------------------------
// Return a resolved position where the nodeAfter is at the lowest indent level (aka sibling closest to root node) between
// $from and $to. If nodeType is given, then return null if we encounter any nodes between the endpoints not of that type
// If multiple list nodes have the same indent level, node returned depends on `firstMatch`
const minIndentPosBetween = ($from, $to, { nodeType = null, firstMatch = false } = {}) => {
  const doc = $from.node(0);
  let minIndent = null;
  let minIndentPos = null;

  // First check indent level of endpoints, if they can be derived
  const fromEligible = $from.nodeAfter && ((!nodeType && $from.nodeAfter.type.spec.isListItem) || ($from.nodeAfter.type === nodeType));
  const toEligible = $to.nodeBefore && ((!nodeType && $to.nodeBefore.type.spec.isListItem) || ($to.nodeBefore.type === nodeType));
  if (fromEligible && toEligible) {
    if ($from.nodeAfter.attrs.indent < $to.nodeBefore.attrs.indent) {
      minIndent = $from.nodeAfter.attrs.indent;
      minIndentPos = $from.pos;
    } else {
      minIndent = $to.nodeBefore.attrs.indent;
      minIndentPos = $to.pos - $to.nodeBefore.nodeSize;
    }
  } else if (fromEligible) {
    minIndent = $from.nodeAfter.attrs.indent;
    minIndentPos = $from.pos;
  } else if (toEligible) {
    minIndent = $to.nodeBefore.attrs.indent;
    minIndentPos = $to.pos - $to.nodeBefore.nodeSize;
  } else {
    return null;
  }

  let nonEligibleNodes = false;
  if ($from.pos !== $to.pos) {
    doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
      if ((!nodeType && node.type.spec.isListItem) || node.type === nodeType) {
        if ((node.attrs.indent < minIndent) || (!firstMatch && node.attrs.indent === minIndent)) {
          minIndent = node.attrs.indent;
          minIndentPos = pos;
        }
      } else {
        nonEligibleNodes = true;
      }
      return false; // Don't recurse deeper than root nodes
    });
  }

  if (!nonEligibleNodes && minIndentPos !== null) {
    return doc.resolve(minIndentPos);
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
// Determine the { $head, $anchor } to send to MultiListItemSelection such that it expands out to the correct
// extents given cursor's position relative to node boundaries
// `$headWas` optional resolved position for head from previous selection. Used to snap to node boundaries on first
//     multi-list item selection iff the $headWas list item is adjacent to the $head list item
const deriveExpandToCreateEndpoints = ($anchor, $head, $headWas, state) => {
  const doc = $anchor.node(0);
  const schema = doc.type.schema;

  const $headWasListNode = $headWas && doc.resolve(resolvedPosListNodePos(schema, $headWas));
  const $headListNode = doc.resolve(resolvedPosListNodePos(schema, $head));

  // Doesn't seem to matter whether we find a list node before or after the $head pos for the sake of finding a valid expand endpoint?
  const resolvedPosToListNode = $pos => ($pos.nodeAfter && $pos.nodeAfter.type.spec.isListItem ? $pos.nodeAfter
    : ($pos.nodeBefore && $pos.nodeBefore.type.spec.isListItem ? $pos.nodeBefore : null));
  const headListNode = resolvedPosToListNode($headListNode);
  const headWasListNode = $headWas && resolvedPosToListNode($headWasListNode);
  let headSet = false;

  if (headListNode && headListNode.type.spec.isListItem) {
    if (headWasListNode && headWasListNode.type.spec.isListItem) {
      // If $head is one node away from $headWas and $headWas started at beginning/end of line, we want to snap to
      // other side of original line (as opposed to browsers' default contentEditable behavior to locate cursor
      // arbitrarily within node)
      if ($headWas.start() === $headWas.pos && $headWas.after(1) === $head.before(1)) {
        headSet = true;
        $head = doc.resolve($headWas.after(1));
      } else if ($headWas.end() === $headWas.pos && $head.after(1) === $headWas.before(1)) {
        headSet = true;
        $head = doc.resolve($headWas.before(1));
      }
    }

    if (!headSet) {
      // If head is beyond first character in a non-list node and not because of a contentEditable overshot (which would
      // have been detected by $headWas check above), drop out of MultiListItemSelection
      const pastFirstCharacter = $head.pos > ($headListNode.pos + $head.depth);
      if (pastFirstCharacter && $headListNode.nodeAfter && !$headListNode.nodeAfter.type.spec.isListItem) {
        return { $anchor: null, $head: null };
      }
    }

    // An anchor at the beginning/end of the line is equivalent to anchor at subsequent line (assuming next line exists)
    if ($anchor.end() === $anchor.pos && $head.pos > $anchor.pos) {
      const anchorAfter = state ? nonHiddenPosAfter($anchor.after(1), state) : $anchor.after(1);
      $anchor = doc.resolve(anchorAfter);
    } else if ($anchor.start() === $anchor.pos && $head.pos < $anchor.pos) {
      const anchorBefore = state ? nonHiddenPosBefore($anchor.before(1), state) : $anchor.after(1);
      $anchor = doc.resolve(anchorBefore);
    }

    // If $head is on the boundary of a text node, we can move one position to try to reach a list boundary
    if (!headSet && !resolvedPosToListNode($head)) {
      const directionToExpand = $headListNode.nodeAfter && $headListNode.nodeAfter.type.spec.isListItem ? 1 : -1;
      $head = doc.resolve($headListNode.pos + directionToExpand);
    }
  }

  return { $anchor, $head };
};

// --------------------------------------------------------------------------
const resolvedPosListNodePos = (schema, $pos) => $pos.before(listItemDepthFromSchema(schema));

// --------------------------------------------------------------------------
// Return interpretation of $anchor such that the region between $anchor and $head can be dragged without
// orphaned children.
const coherentDroppableSelectionAnchor = ($anchor, $head) => {
  if (!$anchor || !$head || $anchor.pos === $head.pos) return $anchor;
  const fromIsAnchor = $anchor.pos < $head.pos;
  const $from = fromIsAnchor ? $anchor : $head;
  const $to = fromIsAnchor ? $head : $anchor;
  const $minIndent = minIndentPosBetween($from, $to);

  // If $from.nodeAfter or $to.nodeBefore weren't list nodes, $minIndent will be null. Otherwise, we'll
  // move anchor back or forward such that it captures the selection entire range implied by $minIndent
  if ($minIndent && $minIndent.nodeAfter) {
    if (fromIsAnchor && $from.nodeAfter && $minIndent.nodeAfter.attrs.indent < $from.nodeAfter.attrs.indent) {
      const expandedList = ExpandingListItemSelection.createFromEnd($from, $minIndent.nodeAfter.attrs.indent);
      if (expandedList instanceof ExpandingListItemSelection) $anchor = expandedList.$from;
    } else if (!fromIsAnchor && $to.nodeAfter && $minIndent.nodeAfter.attrs.indent < $to.nodeAfter.attrs.indent) {
      const expandedList = ExpandingListItemSelection.create($minIndent);
      if (expandedList instanceof ExpandingListItemSelection) $anchor = expandedList.$to;
    }
  }

  return $anchor;
};

// --------------------------------------------------------------------------
// Whereas ExpandingListItemSelection is a utility distinctly concerned with expanding to the front or
// end of a node hierarchy, MultiListItemSelection is dedicated to finding the extents of a list item selection
// range, and then allowing those groups of list items to have batch actions taken on them (dragging the OG
// use case, also indent/dedent, item completion). This selection mechanism also exists because
// it's complex business to derive the correct start and end points for a node range in response to cursor
// movement that might put $head at the start of a text node in a list node that shouldn't be part of the selection
//
// The extents of this selection are defined as the minimum possible selection range that would allow $anchor
// and $head to form a contiguous block (i.e., a group of list nodes that could be dragged w/o losing parents/children).
// The set of nodes may be siblings, in which case the selection would include all the children of the siblings.
// If $start is a first child ("childA") of parentA and $from is parentB, that implies to connect the list items,
// MultiListItemSelection's "from" pos must start at parentA, so "parentA => childA, parentB" is a contiguous block.
//
// The movement/creation (and testing) of MultiListItemSelection often happens via its bff listItemSelectionPlugin
export default class MultiListItemSelection extends Selection {
  // --------------------------------------------------------------------------
  // Snap $anchor and $head to proximal list item boundaries, relative to node hierarchy. Fall back to
  // TextSelection if the range includes non-list-nodes
  static create($anchor, $head, failNull) {
    let fail = !$anchor || !$head;
    if (!fail) {
      const $from = $anchor.pos < $head.pos ? $anchor : $head;
      const $to = $anchor.pos < $head.pos ? $head : $anchor;
      fail = !allListItemsBetween($from, $to);
    }
    if (fail) {
      if (failNull) {
        return null;
      } else {
        const $inlineAnchor = Selection.near($anchor).$anchor;
        const $inlineHead = Selection.near($head).$head;
        return new TextSelection($inlineAnchor, $inlineHead);
      }
    }

    // Ensure that the range given won't orphan children
    $anchor = coherentDroppableSelectionAnchor($anchor, $head);
    return new MultiListItemSelection($anchor, $head);
  }

  // --------------------------------------------------------------------------
  // Snap $anchor and $head to list node boundaries such that a contiguous region of draggable selection
  // is created
  static expandToCreate($anchor, $head, $headWas = null, state = null, failNull = false) {
    const $originalAnchor = $anchor;
    const $originalHead = $head;
    ({ $anchor, $head } = deriveExpandToCreateEndpoints($anchor, $head, $headWas, state));

    // If we couldn't derive an $anchor/$head at list boundaries to expand from (e.g., cuz head is in non-list node), we'll try
    // to create a MLIS from the last known $anchor/$head (this.create return null if those weren't valid list extents)
    if (!$anchor || !$head) {
      return this.create($originalAnchor, $originalHead, failNull);
    }

    const fromIsAnchor = $originalAnchor.pos < $originalHead.pos;
    let $from = $anchor.pos < $head.pos ? $anchor : $head;
    let $to = $anchor.pos < $head.pos ? $head : $anchor;
    $from = multiListItemSelectionBackward($from, $to);
    $to = multiListItemSelectionForward($to, $from);
    $anchor = fromIsAnchor ? $from : $to;
    $head = fromIsAnchor ? $to : $from;
    return this.create($anchor, $head, failNull);
  }

  // --------------------------------------------------------------------------
  eq(other) {
    return other instanceof MultiListItemSelection && other.anchor === this.anchor && other.head === this.head;
  }

  // --------------------------------------------------------------------------
  // `bias` dictates whether, when evaluating a pos where an insert occurs, whether the cursor should move forward
  // with the newly inserted characters (the default), or should stay behind, in which case `bias` must be -1
  map(doc, mapping, bias = 1) {
    let deleted, pos;
    ({ deleted, pos } = mapping.mapResult(this.anchor, bias));
    const $anchor = doc.resolve(pos);
    if (deleted && (!$anchor.nodeAfter || !$anchor.nodeAfter.type.spec.isListItem) && (!$anchor.nodeBefore || !$anchor.nodeBefore.type.spec.isListItem)) {
      return Selection.near($anchor);
    }

    ({ deleted, pos } = mapping.mapResult(this.head, bias));
    const $head = doc.resolve(pos);
    if (deleted && (!$head.nodeAfter || !$head.nodeAfter.type.spec.isListItem) && (!$head.nodeBefore || !$head.nodeBefore.type.spec.isListItem)) {
      return Selection.near($head);
    }

    return MultiListItemSelection.create($anchor, $head);
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return { type: "multi-list-item", anchor: this.anchor, head: this.head };
  }
}

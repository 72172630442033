import { isAfter } from "date-fns"
import tippy from "tippy.js"

import { deviceSupportsHover } from "lib/ample-editor/lib/client-info"
import { formatDateTime } from "lib/ample-util/date"
import { formatPriority, iconNameFromPriority } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function createWidget(className, iconName, attributeName) {
  const widget = document.createElement("i");

  widget.className = `widget ${ className } material-icons`;
  widget.textContent = iconName;

  widget.dataset.attribute = attributeName;

  if (deviceSupportsHover) {
    tippy(widget, { animation: "shift-away-subtle", ignoreAttributes: true, touch: false });
  }

  return widget;
}

// --------------------------------------------------------------------------
export function createWidgetsBar(onClick) {
  const widgetsBar = document.createElement("div");
  widgetsBar.className = "widgets-bar";
  widgetsBar.contentEditable = "false";
  widgetsBar.addEventListener("click", onClick);

  widgetsBar.appendChild(createWidget("start", "visibility_off", "startAt"));
  widgetsBar.appendChild(createWidget("due-date", "event_available", "due"));
  widgetsBar.appendChild(createWidget("priority", "star_border", "priority"));

  const widgetPopupContainer = document.createElement("div");
  widgetPopupContainer.className = "widget-popup-container";
  widgetsBar.appendChild(widgetPopupContainer);

  return [ widgetsBar, widgetPopupContainer ]
}

// --------------------------------------------------------------------------
export function updateWidgetsBar(widgetsBar, dueDate, startAt, flags, quickAdjustAttributeName) {
  const startWidget = widgetsBar.querySelector(".widget.start");
  if (startWidget) {
    const className = `widget start material-icons${ quickAdjustAttributeName === "startAt" ? " active" : "" }`;

    const startDate = startAt ? new Date(startAt * 1000) : null;
    if (startDate && isAfter(startDate, Date.now())) {
      startWidget.className = `${ className } enabled`;
      if (startWidget._tippy) startWidget._tippy.setContent(`Hidden until ${ formatDateTime(startDate) }`);
    } else {
      startWidget.className = className;
      if (startWidget._tippy) startWidget._tippy.setContent("Hide until");
    }
  }

  const dueDateWidget = widgetsBar.querySelector(".widget.due-date");
  if (dueDateWidget) {
    const className = `widget due-date material-icons${ quickAdjustAttributeName === "due" ? " active" : "" }`;

    if (dueDate) {
      dueDateWidget.className = `${ className } enabled`;
      if (dueDateWidget._tippy) dueDateWidget._tippy.setContent(`Starts ${ formatDateTime(dueDate) }`);
    } else {
      dueDateWidget.className = className;
      if (dueDateWidget._tippy) dueDateWidget._tippy.setContent("Start at");
    }
  }

  const priorityWidget = widgetsBar.querySelector(".widget.priority");
  if (priorityWidget) {
    const className = `widget priority material-icons${ quickAdjustAttributeName === "priority" ? " active" : "" }`;

    if (flags) {
      priorityWidget.className = `${ className } enabled`;
      priorityWidget.textContent = iconNameFromPriority(flags);

      if (priorityWidget._tippy) priorityWidget._tippy.setContent(formatPriority(flags));
    } else {
      priorityWidget.className = className;
      priorityWidget.textContent = "star_border";
      if (priorityWidget._tippy) priorityWidget._tippy.setContent("Priority");
    }
  }
}

import { TextSelection } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"

import { listItemNodeFromPos } from "lib/ample-editor/lib/list-item-util"

// --------------------------------------------------------------------------
const MAX_AT_SIGN_MATCH_CHARACTERS = 50;

// Groups:
//  1 => leading space, zero-width boundary, or non-zero-width boundary _before_ opening link brackets
//  2 => opening link bracket, may include trailing `=`
//  3 => leading space, zero-width boundary, or non-zero-width boundary _before_ opening @
//  4 => opening link @, may include trailing `=`
//  5 => text content (to use as search pattern), if any
//  6 => closing bracket or brackets - may include zero-width/non-zero-width boundary
const NOTE_LINK_PATTERN = /(?:(\s|^|\b|\(|\ufffc)(\[\[=?)|(\s|^|\(|\ufffc)(@(?!\s)=?))(.*?)(]]?|$|\ufffc)/g;

// --------------------------------------------------------------------------
export default function buildLinkTargetMenuParams(state, { cancelNoteLinkMenuPos = null } = {}) {
  const { schema, selection, selection: { $from, $to } } = state;
  if (!(selection instanceof TextSelection) || !$to.parent || !$to.sameParent($from)) return null;

  // Leaf nodes include hard breaks, which we want to treat as the end of a line
  const leafNodeText = "\ufffc";

  let baseOffset = 0;
  let textContent = $to.parent.textBetween(baseOffset, $to.parent.content.size, null, leafNodeText);
  let pattern = new RegExp(NOTE_LINK_PATTERN);

  // Limiting to 10 tries to avoid infinite loops (`pattern` is stateful over repeated `exec` calls)
  for (let i = 0; i < 10; i++) {
    const match = pattern.exec(textContent);
    if (!match) return null;

    const matchedLeadingSpace = match[1] || match[3] || "";
    const matchedOpening = match[2] || match[4]; // One of these groups must be matched for regex to succeed
    let matchedContent = match[5];

    let matchedClosing = match[6];
    if (matchedClosing === leafNodeText) matchedClosing = "";

    let startOffset = baseOffset + match.index + matchedLeadingSpace.length;

    // textBetween doesn't account for the open+closing positions of any inline nodes (e.g. link nodes) with content
    // eslint-disable-next-line no-loop-func
    $to.parent.nodesBetween(baseOffset, startOffset, (node, nodePos) => {
      if (!node.isText && node.type.spec.inline && !node.type.isLeaf) {
        // We want the last position _in_ the node, so subtracting off the final 1 position, as this would otherwise
        // be the position after the  node
        const nodeEndPos = nodePos + node.nodeSize - 1;
        // Account for opening position, if we started before the node
        if (baseOffset <= nodePos) startOffset += 1;
        // Account for closing position only if we're after the node
        if (nodeEndPos <= startOffset) startOffset += 1;
      }
    });

    const openOffset = startOffset + matchedOpening.length;

    const isAtSignMatch = matchedOpening.startsWith("@");

    let isTooLongAtSignMatch = false;
    if (isAtSignMatch) {
      if (!matchedClosing) {
        // The @ trigger is restricted to the cursor, or the end of the word the cursor is in, as it's not expected
        // that `]` will be used to close the search region (though it can)
        const cursorOffset = $to.parentOffset - openOffset;

        const cursorBasedEndMatch = /[\p{P}\s]/u.exec(matchedContent.slice(cursorOffset));
        if (cursorBasedEndMatch) {
          matchedContent = matchedContent.slice(0, cursorOffset + cursorBasedEndMatch.index);
        }
      }

      isTooLongAtSignMatch = matchedContent.length > MAX_AT_SIGN_MATCH_CHARACTERS;
    }

    const endOffset = openOffset + matchedContent.length + matchedClosing.length;

    let opensInCodeMark = false;
    let opensInLink = false;
    try {
      $to.parent.nodesBetween(startOffset, Math.min($to.parent.nodeSize - 2, openOffset), node => {
        if (schema.marks.code.isInSet(node.marks)) opensInCodeMark = true;
        if (node.type === schema.nodes.link) opensInLink = true;
        return true;
      });
    } catch (_error) {
      // nodesBetween can throw `TypeError: Cannot read property 'nodeSize' of undefined` in some undetermined case,
      // and any exceptions in this function will result in repeated mismatched transactions as the state and transform
      // get out of sync
      return null;
    }

    if (isTooLongAtSignMatch || opensInCodeMark || opensInLink) {
      // There may be a subsequent opening `[[` or `@` that isn't in a code mark, but we've already
      // consumed it in the regex matching here so we need to reset the state to capture it
      // correctly on another pass.

      // If the openOffset is in a link, we need to account for the link node's opening position (`+ 1`)
      baseOffset = openOffset + (opensInLink ? 1 : 0);

      // Reset the regex state
      pattern = new RegExp(NOTE_LINK_PATTERN);

      // Note that we're indexing in the _text_ content here, so we can't use *Offset because those include the
      // opening/closing positions of nodes that aren't included in the text content
      textContent = textContent.slice(match.index + matchedLeadingSpace.length + matchedOpening.length);

      continue;
    }

    if ($to.parentOffset < openOffset || $from.parentOffset < openOffset) continue;

    // Note this needs to allow the cursor to be placed after the double closing bracket
    if (matchedClosing.length > 0 && ($to.parentOffset > endOffset || $from.parentOffset > endOffset)) continue;

    const pos = $to.start();
    const startPos = pos + startOffset;

    if (startPos === cancelNoteLinkMenuPos) continue;

    // This confirms that we actually can insert a link at this position
    if (insertPoint(state.doc, startPos, schema.nodes.link) !== startPos) continue;

    // The menu content can vary depending on whether we're in a check list item
    // eslint-disable-next-line no-unused-vars
    const listItem = listItemNodeFromPos(state.doc.resolve(startPos));

    const text = (matchedContent + matchedClosing).trim();

    return {
      endPos: pos + endOffset,
      // TEMP disabled, pending completion of AN-1677
      inTaskUUID: null, // listItem && listItem.type === schema.nodes.check_list_item ? listItem.attrs.uuid : null,
      openPos: pos + openOffset,
      shouldInsertContent: matchedOpening.endsWith("="),
      startPos,
      text,
    };
  }

  return null;
}

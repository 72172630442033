import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Chip, ChipSet } from "@rmwc/chip"
import Tippy from "@tippyjs/react"

import { durationFromMinutes, minutesFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const NOTIFY_MINUTES = [
  5,
  15,
  60,
  1440, // 1 Day
];

const TOOLTIP_DELAY = [ 1000, 150 ];

// --------------------------------------------------------------------------
function NotifyChip({ disabled, isSelected, minutes, onFocus, setNotify }) {
  const onClick = useCallback(
    () => {
      const duration = durationFromMinutes(minutes);
      setNotify(duration);
    },
    [ minutes, setNotify ]
  );

  return (
    <Chip
      disabled={ disabled }
      key={ minutes }
      label={ minutes === 1440 ? "1 day" : `${ minutes } min` }
      onClick={ onClick }
      onFocus={ onFocus }
      selected={ isSelected }
    />
  );
}

// --------------------------------------------------------------------------
export default function NotifyControls({ disabled, notify, onFocus, setNotify }) {
  // Note the handling for legacy boolean `notify` values
  const selectedMinutes = notify
    ? (notify === true ? 0 : minutesFromDuration(notify))
    : null;

  const options = NOTIFY_MINUTES.map((minutes, index) => {
    if (index === 0 && selectedMinutes !== null && !NOTIFY_MINUTES.includes(selectedMinutes)) {
      minutes = selectedMinutes;
    }

    return (
      <NotifyChip
        disabled={ disabled }
        isSelected={ minutes === selectedMinutes }
        key={ minutes }
        minutes={ minutes }
        onFocus={ onFocus }
        setNotify={ setNotify }
      />
    );
  });

  return (
    <div className="control notify">
      <Tippy
        arrow={ false }
        content="How long before the start time do you want to be notified?"
        delay={ TOOLTIP_DELAY }
      >
        <div className="control-label">
          <span className="material-icons">notifications_none</span><span className="text">Reminder</span>
        </div>
      </Tippy>

      <ChipSet>
        { options }
      </ChipSet>
    </div>
  );
}

NotifyControls.propTypes = {
  due: PropTypes.number,
  notify: PropTypes.string,
  onFocus: PropTypes.func,
  readonly: PropTypes.bool,
  setNotify: PropTypes.func.isRequired,
};

import { closeHistory } from "prosemirror-history"

import replaceFromMarkdown from "lib/ample-editor/commands/replace-from-markdown"
import { isTasksSchema } from "lib/ample-editor/components/tasks-editor/tasks-schema"
import markdownSerializer from "lib/ample-editor/lib/markdown-serializer"
import { pluginLinkFromLink, validUpdatesFromPluginLinkUpdates } from "lib/ample-editor/lib/plugin-link-util"

// --------------------------------------------------------------------------
function checkListItemFromPos($pos) {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth);
    if (node && node.type.name === "check_list_item") return node;
  }

  return null;
}

// --------------------------------------------------------------------------
function linkPosFromPos($pos) {
  if (!$pos.parent) return null;

  const { parent: { type: parentType, type: { schema } } } = $pos;
  if (parentType === schema.nodes.link) {
    const { doc } = $pos;
    return doc.resolve($pos.start($pos.depth) - 1).pos;
  }

  return null;
}

// --------------------------------------------------------------------------
function updateLink(linkPos, updates) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const node = doc.nodeAt(linkPos);
    if (!node || node.type !== schema.nodes.link) return false;

    if (dispatch) {
      dispatch(closeHistory(state.tr.setNodeMarkup(linkPos, null, { ...node.attrs, ...updates })));
    }

    return true;
  }
}

// --------------------------------------------------------------------------
// Returns the editor context object that the plugin system uses to interact
// with the current location in the editor.
export default function buildEditorContext(editorView, getSelectionRange = null, overrideSelection = {}) {
  const editorContext = {};

  const { state: { doc, schema, selection } } = editorView;

  const { from = null, head = null, to = null } = overrideSelection;

  const $from = from !== null ? doc.resolve(from) : selection.$from;
  const $head = head !== null ? doc.resolve(head) : selection.$head;
  const $to = to !== null ? doc.resolve(to) : selection.$to;

  const initialLinkPos = linkPosFromPos($from);
  editorContext.link = initialLinkPos !== null ? pluginLinkFromLink(doc.nodeAt(initialLinkPos)) : null;

  let selectionContentFrom = $from.pos;
  let selectionContentTo = $to.pos;
  if (getSelectionRange) {
    editorContext.replaceSelection = markdownContent => {
      const selectionRange = getSelectionRange();
      if (!selectionRange) return false;

      const { dispatch, state } = editorView;
      return replaceFromMarkdown(selectionRange.from, selectionRange.to, markdownContent)(state, dispatch);
    };

    if (initialLinkPos !== null) {
      editorContext.updateLink = updates => {
        const validUpdates = validUpdatesFromPluginLinkUpdates(updates);

        const selectionRange = getSelectionRange();
        if (!selectionRange) return false;

        const { dispatch, state } = editorView;
        const linkPos = linkPosFromPos(state.doc.resolve(selectionRange.from));
        if (!linkPos) return false;
        return updateLink(linkPos, validUpdates)(state, dispatch);
      };
    }

    // The actual selection might not reflect the more accurate range in getSelectionRange
    const selectionRange = getSelectionRange();
    if (selectionRange) {
      selectionContentFrom = selectionRange.from;
      selectionContentTo = selectionRange.to;
    }
  }

  try {
    // In the tasks schema, including the parent will wrap the text selection in a task, which is not really what we
    // want as it might not include all the content in the actual task
    const includeParentNode = !isTasksSchema(schema);
    const selectionSlice = doc.slice(selectionContentFrom, selectionContentTo, includeParentNode);
    editorContext.selectionContent = markdownSerializer.serialize(selectionSlice.content);
  } catch (_error) {
    // Avoiding possibility that selection can't be serialized
  }

  try {
    const checkListItem = checkListItemFromPos($head);
    if (checkListItem) {
      const { attrs: { uuid } } = checkListItem;
      if (uuid) editorContext.taskUUID = uuid;
    }
  } catch (_error) {
    // There are a number of reasons the position might be invalid
  }

  return editorContext;
}

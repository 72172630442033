import BigEval from "lib/ample-util/big-eval"

export default class BigEvalTableFormulas extends BigEval {
  // --------------------------------------------------------------------------
  mean(...args) {
    const sum = args.reduce((a, b) => this.add(a, b), 0);
    return sum / args.length;
  }

  // --------------------------------------------------------------------------
  average(...args) {
    return this.mean(...args);
  }

  // --------------------------------------------------------------------------
  median(...args) {
    const sortedValues = args.sort((a, b) => a - b);
    if (sortedValues.length === 0) return null;
    if (sortedValues.length % 2 === 0) {
      const midIndex = sortedValues.length / 2;
      return this.mean(sortedValues[midIndex - 1], sortedValues[midIndex]);
    } else {
      return sortedValues[Math.floor(sortedValues.length / 2)];
    }
  }

// --------------------------------------------------------------------------
  mode(...args) {
    const counts = {};
    args.forEach(value => {
      if (!counts[value]) counts[value] = 0;
      counts[value]++;
    });

    const maxCount = Math.max(...Object.values(counts));
    const maxIndex = Object.values(counts).findIndex(count => count === maxCount);
    return Object.keys(counts)[maxIndex];
  }

// --------------------------------------------------------------------------
  sum(...args) {
    return args.reduce((a, b) => this.add(a, b), 0);
  }

// --------------------------------------------------------------------------
  multiply(...args) {
    return args.reduce((a, b) => a * b, 1);
  }

  // --------------------------------------------------------------------------
  product(...args) {
    return this.multiply(...args);
  }
}

import { Slice } from "prosemirror-model"
import { NodeSelection, Plugin, Selection, TextSelection } from "prosemirror-state"
import { dropPoint } from "prosemirror-transform"
import { __parseFromClipboard, __serializeForClipboard } from "prosemirror-view"

import { brokenClipboardAPI, isAndroid, isApplePlatform, isIOS } from "lib/ample-editor/lib/client-info"
import ExpandingListItemSelection from "lib/ample-editor/lib/expanding-list-item-selection"
import { listItemDepthFromSchema } from "lib/ample-editor/lib/list-item-commands"
import MultiListItemSelection, {
  multiListItemSelectionBackward,
  multiListItemSelectionForward,
} from "lib/ample-editor/lib/multi-list-item-selection"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import { transactionChangesDoc, transactionMarkedReadonly } from "lib/ample-editor/lib/transaction-util"
import { isPosHidden, nonHiddenPosAfter } from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import { setIsDragging } from "lib/ample-editor/plugins/selection-menu-plugin"
import DropCursorView from "lib/ample-editor/views/drop-cursor-view"

// --------------------------------------------------------------------------
// As of inception May 2021, `list-item-selection-plugin.js` is concerned with
// 1. Invoking a MultiListItemSelection when the user's selection implies they would be best served by it
// 2. Modifying a MultiListItemSelection in response to subsequent cursor movement
// 3. Handling the start and end of all dragging (with special handling for groups of list nodes)
// 4. Illustrating the drop cursor shown when content is dragged (with special handling for groups of list nodes)

// --------------------------------------------------------------------------
// Move selection $head of an existing MultiListItemSelection in response to cursor movement
// Depends on the caller to ascertain that a new selection was initiated in response to keyboard
// input. WBH not confident whether this will make to include on mobile, but initially callers should avoid.
function adjustedMultiItemSelection(doc, selection, selectionWas) {
  let { $head } = selection;
  const { $anchor: $anchorWas, $head: $headWas } = selectionWas;

  if (($headWas.pos === doc.content.size && ($head.pos === $head.end() || $head.pos === $headWas.pos)) ||
    ($headWas.pos === 0 && ($head.pos === $head.start() || $head.pos === $headWas.pos))) {
    // Leave head in place under the assumption that user is trying to move cursor when there is nowhere to move
  } else if ($headWas.pos < $head.pos) {
    $head = multiListItemSelectionForward(doc.resolve($headWas.pos + 1), $anchorWas);
  } else {
    $head = multiListItemSelectionBackward(doc.resolve($headWas.pos - 1), $anchorWas);
  }

  return MultiListItemSelection.create($anchorWas, $head, true);
}

// --------------------------------------------------------------------------
// Create transactions to move a slice in response to a drop event
// Originally taken from PM view's editHandlers.drop
export function dispatchDropTransform(view, event) {
  const dragging = view.dragging;
  view.dragging = null;

  if (!event.dataTransfer) return null;

  const { doc } = view.state;
  const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
  if (!eventPos) return null;
  const $mouse = doc.resolve(eventPos.pos);
  if (!$mouse) return null;
  const textData = event.dataTransfer.getData(brokenClipboardAPI ? "Text" : "text/plain");
  const htmlData = brokenClipboardAPI ? null : event.dataTransfer.getData("text/html");
  const slice = dragging && dragging.slice || __parseFromClipboard(view, textData, htmlData, false, $mouse);

  const tr = view.state.tr;

  let insertPos;
  if (slice) {
    insertPos = dropPoint(view.state.doc, $mouse.pos, slice)
  } else {
    insertPos = $mouse.pos;
  }
  if (insertPos === null) insertPos = $mouse.pos;
  insertPos = nonHiddenPosAfter(insertPos, view.state);

  if (view.someProp("handleDrop", f => f(view, event, slice || Slice.empty, dragging && dragging.move, tr, insertPos))) {
    event.preventDefault();
    return null;
  }
  if (!slice) return null;

  event.preventDefault();

  const selection = view.state.selection;

  // Dropping into the current selection is to no effect (aside from event.preventDefault(), above)
  if (selection && selection.from < insertPos && selection.to > insertPos) return null;
  if (dragging && dragging.move) tr.deleteSelection();

  const pos = tr.mapping.map(insertPos);
  const isNode = slice.openStart === 0 && slice.openEnd === 0 && slice.content.childCount === 1;
  const beforeInsert = tr.doc;
  if (isNode) {
    tr.replaceRangeWith(pos, pos, slice.content.firstChild);
  } else {
    tr.replaceRange(pos, pos, slice);
  }
  if (tr.doc.eq(beforeInsert)) return null;

  const $pos = tr.doc.resolve(pos);
  if (isNode && NodeSelection.isSelectable(slice.content.firstChild) &&
    $pos.nodeAfter && $pos.nodeAfter.sameMarkup(slice.content.firstChild)) {
    tr.setSelection(new NodeSelection($pos));
  } else {
    tr.setSelection(selectionBetween(view, $pos, tr.doc.resolve(tr.mapping.map(insertPos))));
  }

  view.focus()
  setDroppedListNodeIndent(tr, view.state, $pos, view.state.doc.resolve(insertPos), slice.content.size);
  view.dispatch(tr.setMeta(TRANSACTION_META_KEY.UI_EVENT, "drop"));
  return slice;
}

// --------------------------------------------------------------------------
// Inlined from prosemirror-view (`handlers.dragstart`), with the addition of:
//  - expansion of the slice that is created from the selection
//  - expansion to the edges of a group of list nodes
export function editorDragStart(view, event, { dragStartPos = null } = {}) {
  const mouseDown = view.input.mouseDown;
  if (mouseDown) mouseDown.done();
  if (!event.dataTransfer) return;

  const sel = view.state.selection;
  if (dragStartPos === null) {
    dragStartPos = sel.empty ? null : view.posAtCoords({ left: event.clientX, top: event.clientY }).pos;
  }

  if (dragStartPos && dragStartPos >= sel.from && dragStartPos <= (sel instanceof NodeSelection ? sel.to - 1 : sel.to)) {
    // Drag started somewhere within selection
  } else if (mouseDown && mouseDown.mightDrag) {
    const $pos = view.state.doc.resolve(mouseDown.mightDrag.pos);
    view.dispatch(setIsDragging(view.state.tr.setSelection(ExpandingListItemSelection.create($pos)), true));
  } else if (event.target && event.target.nodeType === 1) {
    const desc = view.docView.nearestDesc(event.target, true)
    if (!desc || !desc.node.type.spec.draggable || desc === view.docView) return
    view.dispatch(view.state.tr.setSelection(NodeSelection.create(view.state.doc, desc.posBefore)))
  }

  const { selection } = view.state;
  const slice = selection.content();
  const { dom, text } = __serializeForClipboard(view, slice);

  event.dataTransfer.clearData()
  event.dataTransfer.setData(brokenClipboardAPI ? "Text" : "text/html", dom.innerHTML)
  if (!brokenClipboardAPI) event.dataTransfer.setData("text/plain", text)
  view.dragging = { slice, move: !event[isApplePlatform ? "altKey" : "ctrlKey"] };
}

// --------------------------------------------------------------------------
// Inlined from prosemirror-view
function selectionBetween(view, $anchor, $head, bias) {
  return view.someProp("createSelectionBetween", f => f(view, $anchor, $head)) ||
    TextSelection.between($anchor, $head, bias);
}

// --------------------------------------------------------------------------
// If all nodes in the selected range are list items, then expand selection to encompass them
// from their start pos through their end pos, so we don't end up with half-cut list item nodes that don't
// paste or drag into a hierarchy position
function selectNodeRangeIfApplicable(oldState, state) {
  const { selection } = state;
  const { selection: { $anchor, $head } } = state;

  const selectionContent = selection.content();
  if (!selectionContent || !selectionContent.content) return;
  const innerNodes = selectionContent.content.content;
  const { selection: selectionWas } = oldState;
  const listItems = innerNodes.filter(n => n.type.spec.isListItem);
  const blockItems = innerNodes.filter(n => n.type.spec.group === "block");
  const nonListBlockCount = Math.max(0, blockItems.length - listItems.length);
  if (nonListBlockCount > 2) return; // For a list selection to be coherent, there can't be more than one block at the start and end of it

  // Any selection with more than two list items is eligible. If there is one list item selected, eligibility is more complicated
  let multiItemSelectionEligible = listItems.length > 1;
  if (!multiItemSelectionEligible && listItems.length === 1) {
    multiItemSelectionEligible = blockItems.length > listItems.length; // If this selection spans more than just a list item, it's eligible
  }

  if (multiItemSelectionEligible) {
    let listItemSelection;
    if (selectionWas instanceof MultiListItemSelection) {
      listItemSelection = adjustedMultiItemSelection(state.doc, selection, selectionWas)
    } else {
      // If selection had been purely in a single list node, ideally we will expand to just select that node (unless the node can't be selected by itself cuz it would orphan its children)
      const hasSingleListItemBounds = (listItems.length === 2 && nonListBlockCount === 0) || (listItems.length === 1 && nonListBlockCount === 1);
      if (hasSingleListItemBounds && selectionWas && selectionWas.$anchor && selectionWas.$head && selectionWas.$anchor.parent === selectionWas.$head.parent) {
        listItemSelection = selectSingleListItemIfApplicable(state, selectionWas);
      }
      if (!listItemSelection) {
        listItemSelection = MultiListItemSelection.expandToCreate($anchor, $head, selectionWas.$head, state, true);
      }
    }

    if (!listItemSelection || listItemSelection.from === null || typeof(listItemSelection.from) === "undefined") return;
    const firstNode = listItemSelection.$from.nodeAfter;
    if (!firstNode || !firstNode.type.spec.isListItem) return;

    // In Firefox, allowing the cursor to be located in the margin leads to cursor being able to add text between adjacent list items
    // So, if cursor is in a single position, we'll revert to a nearby single position cursor
    if (listItemSelection.from === listItemSelection.to) {
      const bias = $head.pos >= $anchor.pos ? 1 : -1;
      const singlePosSelection = Selection.near(listItemSelection.$head, bias);
      return state.tr.setSelection(singlePosSelection);
    } else {
      return state.tr.setSelection(listItemSelection);
    }
  }
}

// --------------------------------------------------------------------------
function selectSingleListItemIfApplicable(state, selectionWas) {
  const { doc, selection: newSelection } = state;
  const { $from, $to, head: headWas } = selectionWas;
  if (!$from || !$to || $from.parent !== $to.parent) return null;
  const listItemDepth = listItemDepthFromSchema(state.schema);
  const listItemStart = $from.nodeAfter && $from.nodeAfter.type.spec.isListItem ? $from.pos : ($from.start(listItemDepth) - 1);
  const moveDown = newSelection.head > selectionWas.head;
  if (!Number.isInteger(listItemStart)) return null;
  const $start = doc.resolve(listItemStart);
  const singleListItemSelection = ExpandingListItemSelection.create($start);
  if (!singleListItemSelection || !Number.isInteger(singleListItemSelection.from) || !Number.isInteger(singleListItemSelection.to)) return null;
  if (singleListItemSelection.$from.nodeAfter && singleListItemSelection.$from.nodeAfter === singleListItemSelection.$to.nodeBefore) {
    if (singleListItemSelection.from === $from.pos && singleListItemSelection.to === $to.pos) return null;
    // Don't force the user into a single line selection if they are starting from the left or right edge and moving away from line they're on
    if ((singleListItemSelection.from + listItemDepth + 1) === headWas && !moveDown) return null;
    if ((singleListItemSelection.to - listItemDepth - 1) === headWas && moveDown) return null;

    const $anchor = moveDown ? singleListItemSelection.$from : singleListItemSelection.$to;
    const $head = moveDown ? singleListItemSelection.$to : singleListItemSelection.$from;
    return MultiListItemSelection.create($anchor, $head, true);
  }
  return null;
}

// --------------------------------------------------------------------------
// Change indent level of dropped nodes if they are dropped in front of a node of the same type
function setDroppedListNodeIndent(tr, state, $dropAt, $oldDocInsertAt, dropSize) {
  if (!$dropAt || !$dropAt.nodeAfter || !$dropAt.nodeAfter.type.spec.isListItem) return;
  if (!$oldDocInsertAt || (!$oldDocInsertAt.nodeAfter && !$oldDocInsertAt.nodeBefore)) return;
  const nodeBeingDropped = $dropAt.nodeAfter;
  let indentMatchNode;
  if ($oldDocInsertAt.nodeAfter && !isPosHidden(state, $oldDocInsertAt.pos)) {
    indentMatchNode = $oldDocInsertAt.nodeAfter;
  } else if ($oldDocInsertAt.nodeBefore && !isPosHidden(state, $oldDocInsertAt.pos - $oldDocInsertAt.nodeBefore.nodeSize)) {
    indentMatchNode = $oldDocInsertAt.nodeBefore;
  }

  let indentDelta;
  if (indentMatchNode && indentMatchNode.type === nodeBeingDropped.type) {
    indentDelta = indentMatchNode.attrs.indent - nodeBeingDropped.attrs.indent;
  } else { // Reset indent if dropping in front of heterogeneous node
    indentDelta = 0 - nodeBeingDropped.attrs.indent;
  }

  if (indentDelta !== 0) {
    try {
      tr.doc.nodesBetween($dropAt.pos, $dropAt.pos + dropSize, (node, pos) => {
        if (node.type !== nodeBeingDropped.type) return false;
        const { indent, ...nodeAttrs } = node.attrs;
        tr.setNodeMarkup(pos, null, { indent: indent + indentDelta, ...nodeAttrs });
      });
    } catch (_error) {
      // ProseMirror can raise `TypeError: Cannot read properties of undefined (reading 'nodeSize')` in
      // `nodesBetween` in some unknown circumstances. Short of being able to reproduce those circumstances,
      // we can have a pretty reasonable failure mode by just not setting the indents
    }
  }
}

// --------------------------------------------------------------------------
// Initially adopted from PM's dropcursor.js. Handles two aspects of a successful drop:
// 1. Show a decoration at the drop position when something is dragged over the editor (via DropCursorView, below).
// 2. Ensure that list items have indentation level to match the subsequent member of their list
const listItemSelectionPlugin = new Plugin({
  appendTransaction: (transactions, oldState, newState) => {
    if (isIOS || isAndroid) return; // This selection state is meant for responding to keyboard arrow inputs; it relies on getMeta("pointer") to ascertain transactions to ignore, but WBH has observed in iOS that getMeta("pointer") is not actually set on touch selection events
    if (transactions.find(transactionMarkedReadonly)) return;
    if (transactions.find(transactionChangesDoc)) return; // This plugin is only interested in modifying selection range
    if (!transactions.find(t => !t.getMeta(TRANSACTION_META_KEY.POINTER) && t.selectionSet)) return; // Unless there was a transaction set by non-pointer/touch, we shan't modify selection
    const { selection: { anchor, head } } = newState;
    if (anchor === head) return; // No selection range = no work to do

    // Expand selection to extents of list node hierarchy if selection range resides fully within list nodes
    return selectNodeRangeIfApplicable(oldState, newState);
  },
  view(editorView) { return new DropCursorView(editorView); }
});
export default listItemSelectionPlugin;

import { HAS_CHILDREN_CLASS } from "lib/ample-editor/lib/collapsible-defines"
import { isPosCollapsibleNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import { anchorNameFromHeadingText } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
const HIGHLIGHT_DURATION_MILLISECONDS = 2000;

// --------------------------------------------------------------------------
export default class HeadingView {
  _highlightTimer = null;

  // --------------------------------------------------------------------------
  constructor(node, editorView, getPos) {
    this.update = this.update.bind(this, editorView, getPos);

    this.level = Math.min(node.attrs.level, 3);

    this.dom = document.createElement("h" + this.level);
    this.dom.className = "heading";

    if (editorView.props.interactiveHeadingAnchors) {
      const anchor = document.createElement("a");
      // As of Q1 2023 `heading-anchor` is used to collect headings by sidebar-header-list.js
      anchor.className = "material-icons heading-anchor";
      anchor.textContent = "link";
      this.dom.appendChild(anchor);
    } else {
      this.dom.addEventListener("click", this._onClick.bind(this, editorView, getPos));
    }

    this.contentDOM = document.createElement("a");
    this.dom.appendChild(this.contentDOM);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy(_editorView) {
    clearTimeout(this._highlightTimer);

    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  ignoreMutation(event) {
    // This allows for external imperative code that adds the "highlight" class to highlight this heading
    if (event.type === "attributes" && event.attributeName === "class") {
      if (this.dom.classList.contains("highlight")) {
        clearTimeout(this._highlightTimer);
        this._highlightTimer = setTimeout(this._clearHighlight, HIGHLIGHT_DURATION_MILLISECONDS);
      }

      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  update(editorView, getPos, node) {
    if (Math.min(node.attrs.level, 3) !== this.level) return false;

    const name = anchorNameFromHeadingText(node.textContent);
    this.contentDOM.setAttribute("name", name);

    const anchor = this.dom.querySelector(".heading-anchor");
    if (anchor) {
      anchor.href = `#${ encodeURIComponent(name) }`;

      // If there's an anchor, it means `interactiveHeadingAnchors` is enabled and we don't want the expander
      this.dom.className = "heading";
    } else {
      let className = "heading";
      if (node.attrs.collapsed) className += " collapsed";
      if (isPosCollapsibleNode(editorView.state, getPos())) className += ` ${ HAS_CHILDREN_CLASS }`;
      this.dom.className = className
    }

    return true;
  }

  // --------------------------------------------------------------------------
  _clearHighlight = () => {
    this.dom.classList.remove("highlight");
  };

  // --------------------------------------------------------------------------
  _onClick = (editorView, getPos, event) => {
    // The expander is a pseudo element that extends to the left outside the heading's bounding box, so if we're
    // receiving a click event for the heading itself and it's outside the bounding box, it has to be the expander
    if (event.target === this.dom && event.offsetX < 20 /* 20px left padding */) {
      const { state, state: { doc, schema } } = editorView;

      const nodePos = getPos();
      if (typeof(nodePos) === "undefined") return;

      let node = null;
      try {
        node = doc.nodeAt(nodePos);
      } catch (_error) {
        // RangeError: Position N outside of fragment
      }
      if (!node || node.type !== schema.nodes.heading) return;

      event.preventDefault();
      event.stopImmediatePropagation();

      const transaction = state.tr;
      const attrs = { ...node.attrs, collapsed: !node.attrs.collapsed };
      transaction.setNodeMarkup(nodePos, null, attrs);
      editorView.dispatch(transaction);
    }
  };
}

import {
  addDays,
  compareDesc,
  differenceInDays,
  endOfDay,
  format,
  isBefore,
  isSameDay,
  isWithinInterval,
  max as maxDate,
  min as minDate,
  parseISO,
  startOfDay,
  subDays,
  subWeeks,
  subYears,
} from "date-fns"
import { isEmpty, sumBy } from "lodash"
import memoize from "memoize-one"
import PropTypes from "prop-types"
import { EditorView } from "prosemirror-view"
import React from "react"
import { Button } from "@rmwc/button"
import { MenuSurface, MenuSurfaceAnchor } from "@rmwc/menu"

import FilterMenuContent from "lib/ample-editor/components/completed-tasks/filter-menu-content"
import CompletedTasksProgressBar from "lib/ample-editor/components/completed-tasks-progress-bar"
import TasksEditor from "lib/ample-editor/components/tasks-editor"
import { completedTasksFindParamsFromFindPluginState } from "lib/ample-editor/plugins/find-plugin"
import { startOfWeek } from "lib/ample-util/date"
import {
  checkListItemFromCompletedTask,
  REMOVE_COMPLETED_TASKS_YEARS,
  TRIM_COMPLETED_TASKS_WEEKS,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function calculateRecentDaysCutoff(nowTimestamp = null, displayedTasksInterval = null) {
  let cutoff = startOfDay(subWeeks(nowTimestamp ? nowTimestamp * 1000 : Date.now(), TRIM_COMPLETED_TASKS_WEEKS));

  if (displayedTasksInterval) {
    const { start } = displayedTasksInterval;
    cutoff = minDate([ cutoff, start ]);
  }

  return cutoff;
}

// --------------------------------------------------------------------------
function calculateOlderWeeksCutoff(nowTimestamp = null) {
  return startOfWeek(subYears(nowTimestamp ? nowTimestamp * 1000 : Date.now(), REMOVE_COMPLETED_TASKS_YEARS));
}

// --------------------------------------------------------------------------
function defaultDisplayedTasksInterval(nowTimestamp) {
  const now = nowTimestamp ? new Date(nowTimestamp * 1000) : Date.now();
  return { start: startOfDay(subDays(now, 5)), end: endOfDay(now), isDefault: true };
}

// --------------------------------------------------------------------------
function partitionTasks(nowTimestamp, recentDaysCutoff, tasks) {
  const recentTasksByDay = {};
  const olderTasksByWeek = {};

  tasks.forEach(task => {
    const checkedAt = task.checkedAt ? new Date(task.checkedAt * 1000) : null;

    if (checkedAt === null || isBefore(checkedAt, recentDaysCutoff)) {
      const checkedAtWeek = checkedAt ? startOfWeek(checkedAt).toISOString() : null;
      if (!(checkedAtWeek in olderTasksByWeek)) olderTasksByWeek[checkedAtWeek] = [ task ];
      else olderTasksByWeek[checkedAtWeek].push(task);
    } else {
      const checkedAtDay = checkedAt ? startOfDay(checkedAt).toISOString() : null;
      if (!(checkedAtDay in recentTasksByDay)) recentTasksByDay[checkedAtDay] = [ task ];
      else recentTasksByDay[checkedAtDay].push(task);
    }
  });

  const recentDays = Object.keys(recentTasksByDay);

  if (recentDays.length > 0) {
    const now = nowTimestamp ? new Date(nowTimestamp * 1000) : Date.now();

    // Make sure we have groups for the past week, even if they are empty
    let day = startOfDay(now);
    for (let i = 0; i < 7; i++) {
      const isoDay = day.toISOString();

      if (!(isoDay in recentTasksByDay)) {
        recentTasksByDay[isoDay] = [];
        recentDays.push(isoDay);
      }
      day = subDays(day, 1);
    }
  }

  recentDays.sort((left, right) => compareDesc(parseISO(left), parseISO(right)));

  return { olderTasksByWeek, recentDays, recentTasksByDay };
}

// --------------------------------------------------------------------------
export default class CompletedTasks extends React.PureComponent {
  static propTypes = {
    checkListItemPluginState: PropTypes.object,
    dispatchChanges: PropTypes.func.isRequired,
    editorProps: PropTypes.shape({
      hostApp: PropTypes.shape({
        cloneNodes: PropTypes.func,
        fetchNoteContent: PropTypes.func,
        linkNote: PropTypes.func,
        openAttachment: PropTypes.func,
        openNoteLink: PropTypes.func,
        startAttachmentUpload: PropTypes.func,
        startMediaUpload: PropTypes.func,
        suggestNotes: PropTypes.func,
      }).isRequired,
      onPopupPositioned: PropTypes.func,
      onTaskGroupHeadingClick: PropTypes.func,
      renderTaskExpander: PropTypes.func,
    }).isRequired,
    filePluginState: PropTypes.object,
    findPluginState: PropTypes.object,
    parentEditorView: PropTypes.instanceOf(EditorView).isRequired,
    readonly: PropTypes.bool,
    tasks: PropTypes.array.isRequired,
  };

  _tasksEditorRef = React.createRef();

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    const { editorProps: { completedTasksNow } } = props;

    this.state = {
      displayedTasksInterval: defaultDisplayedTasksInterval(completedTasksNow),
      menuOpen: false,
      showingFindResults: false,
    };
  }

  // --------------------------------------------------------------------------
  closeRichFootnote() {
    const { current: tasksEditor } = this._tasksEditorRef;
    if (tasksEditor) tasksEditor.closeRichFootnote();
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    const { findPluginState } = this.props;

    const { currentIndex, query } = completedTasksFindParamsFromFindPluginState(findPluginState);
    if (query) {
      this._showFindResults(query, currentIndex);
    }
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    const { findPluginState: findPluginStateWas } = prevProps;
    const { findPluginState } = this.props;

    if (findPluginState !== findPluginStateWas) {
      const {
        currentIndex: currentIndexWas,
        query: queryWas,
      } = completedTasksFindParamsFromFindPluginState(findPluginStateWas);
      const { currentIndex, query } = completedTasksFindParamsFromFindPluginState(findPluginState);

      if (currentIndex !== currentIndexWas || query !== queryWas) {
        if (query) {
          this._showFindResults(query, currentIndex);
        } else {
          this._hideFindResults();
        }
      }
    }
  }

  // --------------------------------------------------------------------------
  render() {
    const { editorProps: { completedTasksNow }, tasks } = this.props;
    if (!tasks || tasks.length === 0) return null;

    const { displayedTasksInterval } = this.state;

    const recentDaysCutoff = calculateRecentDaysCutoff(completedTasksNow, displayedTasksInterval);
    const {
      olderTasksByWeek,
      recentDays,
      recentTasksByDay,
    } = partitionTasks(completedTasksNow, recentDaysCutoff, tasks);

    const effectiveNow = completedTasksNow ? completedTasksNow * 1000 : Date.now();

    return (
      <div className="completed-tasks">
        { this._renderHeader(effectiveNow, recentDaysCutoff, recentTasksByDay) }
        { this._renderBody(recentDaysCutoff, recentDays, recentTasksByDay) }
        { this._renderFooter(effectiveNow, recentDaysCutoff, olderTasksByWeek) }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _changesFromGroupId = (uuid, groupId) => {
    const { tasks } = this.props;

    const day = parseISO(groupId);
    const timestamp = Math.floor(day.getTime() / 1000);

    const task = tasks.find(completedTask => completedTask.uuid === uuid);
    if (task) {
      // We want to keep the same time of day when dragging completed tasks
      let { checkedAt } = task;

      const checkedAtDay = Math.floor(startOfDay(checkedAt * 1000).getTime() / 1000);
      if (checkedAtDay === timestamp) return {};

      const offsetInDay = checkedAt - checkedAtDay;
      checkedAt = timestamp + offsetInDay;

      return { checkedAt };
    } else {
      // This task isn't already completed, so we want to mark it as such (rather than changing checkedAt)
      return { completedAt: timestamp };
    }
  };

  // --------------------------------------------------------------------------
  _dispatchChanges = (changesByUUID, meta) => {
    this.props.dispatchChanges(changesByUUID, meta, this._changesFromGroupId);
  };

  // --------------------------------------------------------------------------
  _hideFindResults() {
    const { editorProps: { completedTasksNow } } = this.props;

    const stateUpdates = {
      displayedTasksInterval: defaultDisplayedTasksInterval(completedTasksNow),
      showingFindResults: false,
    };

    this.setState(stateUpdates, () => {
      const { current: tasksEditor } = this._tasksEditorRef;
      if (tasksEditor) tasksEditor.find(null, null, { currentIndex: -1 });
    });
  }

  // --------------------------------------------------------------------------
  _onMenuClose = () => {
    this.setState({ menuOpen: false });
  };

  // --------------------------------------------------------------------------
  _renderBody(recentDaysCutoff, recentDays, recentTasksByDay) {
    const {
      checkListItemPluginState,
      editorProps,
      filePluginState,
      parentEditorView,
      readonly,
    } = this.props;
    const { displayedTasksInterval, showingFindResults } = this.state;

    const tasksWithGroup = [];
    let haveMoreGroups = false;
    let groupsWithTasksCount = 0;
    let tasksDisplayedCount = 0;

    recentDays.forEach(day => {
      const tasks = recentTasksByDay[day].reverse();

      // `recentTasksByDay` can contain days with no tasks (i.e. within last week)
      if (tasks.length > 0) {
        groupsWithTasksCount += 1;
      }

      const date = day ? parseISO(day) : null;

      if (date && !showingFindResults && !isWithinInterval(date, displayedTasksInterval)) {
        if (!haveMoreGroups) haveMoreGroups = tasks.length > 0;
        return;
      }

      const groupName = date ? format(date, "iiii, LLLL d") : "";

      tasksWithGroup.push({ groupId: day, groupName, tasks: tasks.map(checkListItemFromCompletedTask) });

      tasksDisplayedCount += tasks.length;
    });

    if (groupsWithTasksCount === 0) {
      return null;
    }

    const isUserSpecifiedFilter = !displayedTasksInterval.isDefault && !displayedTasksInterval.isShowMore;

    if (tasksDisplayedCount === 0 && isUserSpecifiedFilter) {
      const { end, start } = displayedTasksInterval;

      const intervalDescription = isSameDay(start, end)
        ? `on ${ format(start, "LLLL d") }`
        : `${ format(start, "LLLL d") } through ${ format(end, "LLLL d") }`;

      return (
        <div className="no-tasks-message">
          No tasks completed { intervalDescription }
        </div>
      );
    }

    return (
      <React.Fragment>
        {
          tasksDisplayedCount === 0
            ? (
              <div className="no-tasks-message">
                No tasks completed on { format(displayedTasksInterval.start, "LLLL d") } or later
              </div>
            )
            : (
              <TasksEditor
                checkListItemPluginState={ checkListItemPluginState }
                dispatchChanges={ this._dispatchChanges }
                editorProps={ editorProps }
                filePluginState={ filePluginState }
                hideSelectionMenu
                parentEditorView={ parentEditorView }
                readonly={ readonly }
                readonlyContent
                ref={ this._tasksEditorRef }
                tasksWithGroup={ tasksWithGroup }
              />
            )
        }
        {
          (haveMoreGroups && !isUserSpecifiedFilter)
            ? (<div className="show-more-button" onClick={ this._showMoreGroups }>Show more completed tasks</div>)
            : null
        }
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _renderFooter(effectiveNow, recentDaysCutoff, olderTasksByWeek) {
    const olderWeeks = Object.keys(olderTasksByWeek);
    if (olderWeeks.length === 0) return null;

    const omittedCount = sumBy(olderWeeks, week => {
      const tasks = olderTasksByWeek[week];
      if (week) return tasks.length;

      // Items with a null week could be old data (pre-dating checkedAt tracking, or the { removedCount } entry that
      // tracks how many old items have been fully removed from list
      return sumBy(tasks, task => task.removedCount || 1);
    });

    const olderWeeksCutoff = calculateOlderWeeksCutoff(effectiveNow);
    const recentDaysFormat = differenceInDays(effectiveNow, recentDaysCutoff) > 320 ? "LLLL d yyyy" : "LLLL d";

    return (
      <div className="completed-tasks-footer">
        <div className="text">
          { omittedCount } task{ omittedCount === 1 ? "" : "s" } completed before { format(recentDaysCutoff, recentDaysFormat) }
        </div>
        <CompletedTasksProgressBar
          endDay={ recentDaysCutoff }
          height={ 40 }
          now={ effectiveNow }
          startDay={ olderWeeksCutoff }
          tasksByWeek={ olderTasksByWeek }
          tooltipPrefix="Week of "
          width={ 600 }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderHeader(effectiveNow, recentDaysCutoff, tasksByDay) {
    const { displayedTasksInterval, menuOpen } = this.state;

    const noTasksDisplayed = isEmpty(tasksByDay);
    const recentDaysFormat = differenceInDays(effectiveNow, recentDaysCutoff) > 320 ? "LLLL d yyyy" : "LLLL d";
    const taskCount = this._taskCount(tasksByDay);

    return (
      <div className="completed-tasks-header">
        <div className="text">
          { taskCount } task{ taskCount === 1 ? "" : "s" } completed since { format(recentDaysCutoff, recentDaysFormat) }
        </div>
        {
          noTasksDisplayed
            ? null
            : (
              <CompletedTasksProgressBar
                height={ 40 }
                now={ effectiveNow }
                onDayClick={ this._setDisplayedTasksDate }
                startDay={ recentDaysCutoff }
                tasksByDay={ tasksByDay }
                width={ 600 }
              />
            )
        }
        {
          noTasksDisplayed
            ? null
            : (
              <MenuSurfaceAnchor className={ `filter-menu ${ menuOpen ? "open" : "" }` }>
                <MenuSurface anchorCorner="bottomLeft" onClose={ this._onMenuClose } open={ menuOpen }>
                  {
                    menuOpen
                      ? (
                        <FilterMenuContent
                          earliestDate={ recentDaysCutoff }
                          interval={ displayedTasksInterval }
                          nowTimestamp={ effectiveNow }
                          setInterval={ this._setDisplayedTasksInterval }
                        />
                      )
                      : null
                  }
                </MenuSurface>

                <Button
                  className={ `filter-menu-button ${ displayedTasksInterval.isDefault ? "" : "filtered" }` }
                  icon="filter_alt"
                  onClick={ this._toggleMenuOpen }
                />
              </MenuSurfaceAnchor>
            )
        }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _setDisplayedTasksDate = date => {
    this.setState({ displayedTasksInterval: { start: startOfDay(date), end: endOfDay(date) } });
  };

  // --------------------------------------------------------------------------
  _setDisplayedTasksInterval = displayedTasksInterval => {
    if (!displayedTasksInterval) {
      const { editorProps: { completedTasksNow } } = this.props;
      displayedTasksInterval = defaultDisplayedTasksInterval(completedTasksNow);
    }
    this.setState({ displayedTasksInterval, menuOpen: false });
  };

  // --------------------------------------------------------------------------
  _showFindResults(query, currentIndex) {
    const { tasks } = this.props;

    const stateUpdates = { showingFindResults: true };

    // Ensure we're displaying a range around the current match, so they can always see it
    const currentTask = currentIndex > -1 ? tasks[currentIndex] : null;
    if (currentTask) {
      const { checkedAt } = currentTask;
      if (checkedAt) {
        const checkedAtDate = new Date(checkedAt * 1000);

        stateUpdates.displayedTasksInterval = {
          start: startOfDay(subDays(checkedAtDate, 10)),
          end: endOfDay(addDays(checkedAtDate, 4)),
          isShowMore: true,
        };
      }
    }

    this.setState(stateUpdates, () => {
      const { current: tasksEditor } = this._tasksEditorRef;
      if (tasksEditor) tasksEditor.find(query, null, { currentIndex });
    });
  }

  // --------------------------------------------------------------------------
  _showMoreGroups = () => {
    const { editorProps: { completedTasksNow } } = this.props;
    const { displayedTasksInterval: { start, end } } = this.state;

    const recentDaysCutoff = calculateRecentDaysCutoff(completedTasksNow);
    const newStart = maxDate([ subDays(start, 7), recentDaysCutoff ]);

    this.setState({ displayedTasksInterval: { start: newStart, end, isShowMore: true } });
  };

  // --------------------------------------------------------------------------
  _taskCount = memoize(tasksByDay => {
    return sumBy(Object.keys(tasksByDay), day => {
      return tasksByDay[day].length;
    });
  });

  // --------------------------------------------------------------------------
  _toggleMenuOpen = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };
}

import { escapeRegExp } from "lodash"
import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"
import { descriptionDocumentFromValue } from "lib/ample-editor/lib/rich-footnote-util"
import { nodePosHidingPos, nodesHidingNodeAtPos } from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import { updateEditorTabsPluginState } from "lib/ample-editor/plugins/editor-tabs-plugin"
import { getOpenLinkPos, setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"

// --------------------------------------------------------------------------
const INITIAL_PLUGIN_STATE = {
  // If set, a function to call when the match count and index are initially determined or change
  callback: null,
  // Each entry in the form of:
  //    `[ ... ]` where the last entry is a `{ from, to }` and the prior array entries can be used with
  //     `lodash.get` to get to the text node doc.attrs.completedTasks
  completedTasksPaths: [],
  // The index of the currently (extra-) highlighted match
  currentIndex: 0,
  // When non-null, the string text being searched for (that has been escaped for use in regex)
  escapedQuery: null,
  // Each entry in the form of:
  //    `[ ... ]` where the last entry is a `{ from, to }` and the prior array entries can be used with
  //     `lodash.get` to get to the text node doc.attrs.hiddenTasks
  hiddenTasksPaths: [],
  // Each entry in the form of:
  //    `{ from, to }` for each match in the document
  matchPositions: [],
};

// --------------------------------------------------------------------------
const pluginKey = new PluginKey("find-plugin");

// --------------------------------------------------------------------------
// Calls the callback that was passed to `AmpleEditor#find` or `TasksEditor#find`
function callFindCallback(pluginState, pluginStateWas) {
  const { callback, currentIndex } = pluginState;
  if (!callback) return;

  const { callback: callbackWas, currentIndex: currentIndexWas } = pluginStateWas;

  const matchCount = matchCountFromPluginState(pluginState);
  const matchCountWas = matchCountFromPluginState(pluginStateWas);

  // We only want to call the callback when there's an actual change, as it is used to scroll to
  // the current match, which we don't want to do if the user is editing the note elsewhere but
  // happens to still have the find dialog open
  if (callback !== callbackWas || currentIndex !== currentIndexWas || matchCount !== matchCountWas) {
    // eslint-disable-next-line callback-return
    callback(currentIndex, matchCount);
  }
}

// --------------------------------------------------------------------------
// Translates the plugin state to arguments to the `TasksEditor#find` function
export function completedTasksFindParamsFromFindPluginState(findPluginState) {
  const {
    completedTasksPaths,
    currentIndex,
    escapedQuery,
    hiddenTasksPaths,
    matchPositions,
  } = findPluginState;

  const completedTasksStartIndex = matchPositions.length + hiddenTasksPaths.length;

  if (completedTasksPaths.length === 0) {
    // Don't bother trying to search again in the sub-editor as we already know there are no matches
    return { currentIndex: -1, query: null };
  } else if (currentIndex < completedTasksStartIndex) {
    return { currentIndex: -1, query: escapedQuery };
  } else {
    return { currentIndex: currentIndex - completedTasksStartIndex, query: escapedQuery };
  }
}

// --------------------------------------------------------------------------
export function descriptionEditorFindParamsFromState(state, nodePos) {
  const { currentIndex, escapedQuery, matchPositions } = getFindPluginState(state);

  if (!escapedQuery || currentIndex < 0 || matchPositions.length === 0) {
    return { currentIndex: -1, query: null };
  }

  const firstMatchInLinkIndex = matchPositions.findIndex(({ linkPos: matchLinkPos }) => matchLinkPos === nodePos);
  // No matches in the link at the given nodePos
  if (firstMatchInLinkIndex < 0) return { currentIndex: -1, query: escapedQuery };

  let currentIndexInLink;
  const { linkPos } = matchPositions[currentIndex];
  if (linkPos && linkPos === nodePos) {
    currentIndexInLink = currentIndex - firstMatchInLinkIndex;
  } else {
    currentIndexInLink = -1;
  }

  // `from` is undefined when matches are in the OCR text for the media in the link node - it's only set when the
  // match is in the description content, and is only unset for the last match with the same `linkPos` (since the
  // image OCR is matched after the description)
  let imageTextHasMatch = false;
  let imageTextIsCurrentMatch = false;
  for (let i = firstMatchInLinkIndex; i < matchPositions.length; i++) {
    const { linkPos: otherMatchLinkPos, from } = matchPositions[i];
    if (otherMatchLinkPos !== nodePos) break;

    if (!from) {
      imageTextHasMatch = true;
      if (i === currentIndex) {
        imageTextIsCurrentMatch = true;
      }
    }
  }
  return {
    currentIndex: currentIndexInLink,
    imageTextHasMatch,
    imageTextIsCurrentMatch,
    query: escapedQuery,
  };
}

// --------------------------------------------------------------------------
export function domElementForFindMatch(editorView) {
  const domNode = domNodeForFindMatch(editorView);
  return domElementFromDomNode(domNode);
}

// --------------------------------------------------------------------------
function domElementFromDomNode(domNodeOrElement) {
  if (!domNodeOrElement) return null;

  if (domNodeOrElement.nodeType === Node.ELEMENT_NODE) {
    return domNodeOrElement;
  } else {
    return domElementFromDomNode(domNodeOrElement.parentElement || domNodeOrElement.parentNode);
  }
}

// --------------------------------------------------------------------------
export function domNodeForFindMatch(editorView) {
  const { dom, state } = editorView;

  const {
    completedTasksPaths,
    currentIndex,
    escapedQuery,
    hiddenTasksPaths,
    matchPositions,
  } = getFindPluginState(state);

  if (!escapedQuery || currentIndex < 0) return null;

  const completedTasksStartIndex = matchPositions.length + hiddenTasksPaths.length;

  if (currentIndex < matchPositions.length) {
    const { linkPos, from } = matchPositions[currentIndex];
    if (linkPos) {
      if (typeof(from) !== "undefined") {
        // In a Rich Footnote description
        return dom.parentNode.querySelector(".rich-footnote span.find-match.current");
      } else {
        // In a Rich Footnote's image OCR text
        return dom.parentNode.querySelector(".rich-footnote .media-view.find-match.current");
      }
    } else {
      // In document
      const domAtPos = editorView.domAtPos(from, -1);
      return domAtPos ? domAtPos.node : null;
    }
  } else if (currentIndex < completedTasksStartIndex) {
    // Hidden tasks
    const [ taskUUID ] = hiddenTasksPaths[currentIndex - matchPositions.length];
    return dom.parentNode.querySelector(`.editor-tabs .hidden-tasks .check-list-item[data-uuid="${ taskUUID }"]`);
  } else if (completedTasksPaths.length > 0) {
    // Completed tasks
    const [ taskUUID ] = completedTasksPaths[currentIndex - completedTasksStartIndex];
    return dom.parentNode.querySelector(`.editor-tabs .completed-tasks .check-list-item[data-uuid="${ taskUUID }"]`);
  }
}

// --------------------------------------------------------------------------
function findCompletedTasksPaths(escapedQuery, doc) {
  const { attrs: { completedTasks } } = doc;

  const paths = [];

  if (completedTasks) {
    // The newest completed tasks are at the end, but we want to move through them first
    for (let i = completedTasks.length - 1; i > -1; i--) {
      const { p, uuid } = completedTasks[i];
      if (!p) continue;

      findMatchPaths({ type: "paragraph", content: p }, escapedQuery, [ uuid, "p" ], paths);
    }
  }

  return paths;
}

// --------------------------------------------------------------------------
function findHiddenTasksPaths(escapedQuery, doc) {
  const { attrs: { hiddenTasks } } = doc;

  const paths = [];

  // We want to move through hidden tasks before going way back in time through completed tasks
  if (hiddenTasks) {
    for (let i = 0; i < hiddenTasks.length; i++) {
      const hiddenTask = hiddenTasks[i];
      const uuid = hiddenTask.attrs ? hiddenTask.attrs.uuid : null;
      findMatchPaths(hiddenTasks[i], escapedQuery, [ uuid ], paths);
    }
  }

  return paths;
}

// --------------------------------------------------------------------------
function findInText(text, escapedQuery, callback) {
  if (!text) return;

  const pattern = new RegExp(escapedQuery, "gi");

  let counter = 0;
  let match = pattern.exec(text)
  while (match) {
    // eslint-disable-next-line callback-return
    callback(match.index, match.index + match[0].length);

    match = pattern.exec(text);
    if (counter++ > 1000) break;
  }
}

// --------------------------------------------------------------------------
function findMatches(escapedQuery, doc, { noEditorTabs = false } = {}) {
  const completedTasksPaths = (escapedQuery && !noEditorTabs) ? findCompletedTasksPaths(escapedQuery, doc) : [];
  const hiddenTasksPaths = (escapedQuery && !noEditorTabs) ? findHiddenTasksPaths(escapedQuery, doc) : [];
  const matchPositions = escapedQuery ? findMatchPositions(escapedQuery, doc) : [];

  return {
    completedTasksPaths,
    count: matchPositions.length + hiddenTasksPaths.length + completedTasksPaths.length,
    hiddenTasksPaths,
    matchPositions,
  };
}

// --------------------------------------------------------------------------
function findMatchPaths(node, escapedQuery, basePath, paths) {
  if (node.type === "text") {
    const { text } = node;

    findInText(text, escapedQuery, (from, to) => {
      paths.push([ ...basePath, { from, to } ]);
    });
  } else {
    const { content } = node;
    if (!content) return;

    for (let i = 0; i < content.length; i++) {
      findMatchPaths(content[i], escapedQuery, [ ...basePath, "content", i ], paths);
    }
  }
}

// --------------------------------------------------------------------------
function findMatchPositions(escapedQuery, doc) {
  const positions = [];

  doc.descendants((node, pos) => {
    if (node.isText) {
      const { text } = node;

      findInText(text, escapedQuery, (from, to) => {
        positions.push({ from: pos + from, to: pos + to });
      });
    } else if (node.type.name === "image") {
      const { attrs: { text } } = node;
      if (text) {
        findInText(text, escapedQuery, (_from, _to) => {
          positions.push({ from: pos, imagePos: pos, to: pos + node.nodeSize });
        });
      }
    } else if (node.type.name === "link") {
      const { attrs: { description, media } } = node;
      if (description) {
        const descriptionDocument = descriptionDocumentFromValue(description);

        descriptionDocument.descendants((descriptionNode, descriptionPos) => {
          if (descriptionNode.isText) {
            const { text } = descriptionNode;

            findInText(text, escapedQuery, (from, to) => {
              positions.push({
                from: descriptionPos + from,
                linkPos: pos,
                to: descriptionPos + to,
              });
            });
          }
        });
      }

      if (media && media.text) {
        findInText(media.text, escapedQuery, (_from, _to) => {
          positions.push({ linkPos: pos });
        });
      }
    }
  });

  return positions;
}

// --------------------------------------------------------------------------
export function getFindPluginState(state) {
  return pluginKey.getState(state) || INITIAL_PLUGIN_STATE
}

// --------------------------------------------------------------------------
// Translates the plugin state to arguments to the `TasksEditor#find` function
export function hiddenTasksFindParamsFromFindPluginState(findPluginState) {
  const {
    currentIndex,
    escapedQuery,
    hiddenTasksPaths,
    matchPositions,
  } = findPluginState;

  const completedTasksStartIndex = matchPositions.length + hiddenTasksPaths.length;
  const hiddenTasksStartIndex = matchPositions.length;

  if (hiddenTasksPaths.length === 0) {
    // Don't bother trying to search again in the sub-editor as we already know there are no matches
    return { currentIndex: -1, query: null };
  } else if (currentIndex < matchPositions.length) {
    return { currentIndex: -1, query: escapedQuery };
  } else if (currentIndex < completedTasksStartIndex) {
    return { currentIndex: currentIndex - hiddenTasksStartIndex, query: escapedQuery };
  } else {
    return { currentIndex: -1, query: escapedQuery };
  }
}

// --------------------------------------------------------------------------
function matchCountFromPluginState(pluginState) {
  const { completedTasksPaths, hiddenTasksPaths, matchPositions } = pluginState;
  return matchPositions.length + hiddenTasksPaths.length + completedTasksPaths.length;
}

// --------------------------------------------------------------------------
export function setFindPluginIndex(transaction, index) {
  return transaction.setMeta(pluginKey, { index })
}

// --------------------------------------------------------------------------
export function setFindPluginIndexDelta(transaction, indexDelta) {
  return transaction.setMeta(pluginKey, { indexDelta })
}

// --------------------------------------------------------------------------
export function setFindPluginQuery(transaction, callback, query, { currentIndex = null } = {}) {
  return transaction.setMeta(pluginKey, { callback, currentIndex, query })
}

// --------------------------------------------------------------------------
export function createFindPlugin({ noEditorTabs = false } = {}) {
  return new Plugin({
    appendTransaction: (transactions, oldState, newState) => {
      // Here we can react to the currently highlighted match not being visible by adjusting the state of other plugins
      // that might collapse or otherwise hide the match we want to see
      const pluginStateWas = getFindPluginState(oldState);
      const pluginState = getFindPluginState(newState);

      // Most common case, nothing going on with the find plugin so early out
      if (pluginState === pluginStateWas) return;

      const { currentIndex, escapedQuery } = pluginState;
      if (!escapedQuery || currentIndex < 0) return;

      const { hiddenTasksPaths, matchPositions } = pluginState;
      if (escapedQuery === pluginStateWas.escapedQuery && currentIndex === pluginStateWas.currentIndex) return;

      if (currentIndex < matchPositions.length) {
        const { from, linkPos } = matchPositions[currentIndex];
        if (linkPos) {
          // Match is in a Rich Footnote description, open the RF
          const openLinkPos = getOpenLinkPos(newState);
          if (openLinkPos !== linkPos) return setOpenLinkPosition(newState.tr, linkPos);
        } else {
          // Match is in the document, ensure it's not hidden in a collapsed section/list
          const hidingNodePos = nodePosHidingPos(newState, from)
          const hiddenByNodes = hidingNodePos !== null ? nodesHidingNodeAtPos(newState, hidingNodePos) : [];
          if (hiddenByNodes.length > 0) {
            const transaction = newState.tr;

            hiddenByNodes.forEach(({ node: hiddenByNode, nodePos: hiddenByNodePos }) => {
              const attrs = { ...hiddenByNode.attrs, collapsed: false };
              transaction.setNodeMarkup(hiddenByNodePos, null, attrs);
            });

            return transaction;
          }
        }
      } else {
        // Match is in hidden or completed tasks
        const targetTab = currentIndex < matchPositions.length + hiddenTasksPaths.length
          ? EDITOR_TAB.HIDDEN
          : EDITOR_TAB.COMPLETED;
        return updateEditorTabsPluginState(newState.tr, { currentTab: targetTab, expanded: true });
      }
    },
    key: pluginKey,
    props: {
      decorations: state => {
        const { currentIndex, escapedQuery, matchPositions } = getFindPluginState(state);
        if (!escapedQuery || matchPositions.length === 0) return DecorationSet.empty;

        const decorations = [];
        matchPositions.forEach((positionOrPath, index) => {
          if (Array.isArray(positionOrPath)) return;

          const { from, imagePos, linkPos, to } = positionOrPath;
          if (linkPos) return;

          const decorationAttrs = { class: `find-match ${ currentIndex === index ? "current" : "" }` };

          let decoration;
          if (imagePos) {
            decoration = Decoration.node(from, to, decorationAttrs);
          } else {
            decoration = Decoration.inline(from, to, decorationAttrs);
          }

          decorations.push(decoration);
        });

        return DecorationSet.create(state.doc, decorations);
      },
    },
    state: {
      init: (_config, _state) => INITIAL_PLUGIN_STATE,
      apply: (tr, oldPluginState, lastState, _state) => {
        const meta = tr.getMeta(pluginKey);
        if (meta) {
          const newPluginState = { ...oldPluginState };

          if ("query" in meta) {
            const { callback, query } = meta;

            const escapedQuery = query ? escapeRegExp(query) : null;

            const {
              completedTasksPaths,
              count,
              hiddenTasksPaths,
              matchPositions,
            } = findMatches(escapedQuery, tr.doc, { noEditorTabs });

            newPluginState.callback = callback;
            newPluginState.completedTasksPaths = completedTasksPaths;
            if (meta.currentIndex !== null) {
              // Note this allows the index to be -1, to indicate that none of the matches are the current match, e.g.
              // if this editor is embedded in a larger document
              newPluginState.currentIndex = Math.min(meta.currentIndex, count - 1);
            } else {
              newPluginState.currentIndex = Math.max(0, Math.min(oldPluginState.currentIndex, count - 1));
            }
            newPluginState.escapedQuery = escapedQuery;
            newPluginState.hiddenTasksPaths = hiddenTasksPaths;
            newPluginState.matchPositions = matchPositions;
          }

          const { currentIndex } = newPluginState;

          // Needs to be after query change above, so positions are up to date
          const count = matchCountFromPluginState(newPluginState);

          if ("index" in meta) {
            newPluginState.currentIndex = meta.index;
          } else if ("indexDelta" in meta) {
            const lastIndex = Math.max(0, count - 1);

            let newIndex = currentIndex + meta.indexDelta;
            if (newIndex < 0) {
              newIndex = Math.max(0, Math.min(count + newIndex, lastIndex));
            } else if (newIndex > lastIndex) {
              newIndex = Math.min(Math.max(0, newIndex - count), lastIndex);
            }
            newPluginState.currentIndex = newIndex;
          }

          callFindCallback(newPluginState, oldPluginState);

          return newPluginState;
        } else if (oldPluginState.escapedQuery) {
          const { escapedQuery } = oldPluginState;

          const pluginStateUpdates = {};

          if (tr.doc.attrs.completedTasks !== lastState.doc.attrs.completedTasks) {
            pluginStateUpdates.completedTasksPaths = findCompletedTasksPaths(escapedQuery, tr.doc);
          }

          if (tr.docChanged) {
            pluginStateUpdates.matchPositions = findMatchPositions(escapedQuery, tr.doc);
          }

          if (tr.doc.attrs.hiddenTasks !== lastState.doc.attrs.hiddenTasks) {
            pluginStateUpdates.hiddenTasksPaths = findHiddenTasksPaths(escapedQuery, tr.doc);
          }

          if (Object.keys(pluginStateUpdates).length === 0) {
            return oldPluginState;
          }

          const newPluginState = { ...oldPluginState, ...pluginStateUpdates };

          const count = matchCountFromPluginState(newPluginState);
          newPluginState.currentIndex = Math.max(0, Math.min(newPluginState.currentIndex, count - 1));

          callFindCallback(newPluginState, oldPluginState);

          return newPluginState;
        }

        return oldPluginState;
      },
    }
  });
}

// --------------------------------------------------------------------------
// Notes are identified by a pair of UUIDs: the local and remote UUID, where
// a note may just have one UUID, or may have both. This is difficult to work
// with when storing notes in a single dictionary that must use a single string
// key for each entry, so these utilities provide a mechanism for consolidating
// to a single un-ambiguous UUID for each note.
//
// NOTE that these utilities will ignore a localUUID if a note also has a
// remoteUUID, which makes them un-suitable for situations where multiple
// references to the same note need to be reconciled and some references may
// only have a localUUID while others have both a localUUID and remoteUUID
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// As an optimization for the most common use case, remoteUUIDs are used as
// is, so no new string allocation is required, while only local UUIDs are
// prefixed to generate a new string.
const LOCAL_UUID_PREFIX = "local-";

// --------------------------------------------------------------------------
// Intended for use with memoization - returns the notes list, indexed by UUID - e.g.:
//
//  _noteByUUID = memoize(buildNoteByUUID)
//
// Can also be used by a redux selector, assuming updating on every `notes` change is acceptable performance-wise, e.g.:
//
//  const selectNoteByUUID = createSelector(state => state.notes.notes, buildNoteByUUID);
//  // ...
//  const mapStateToProps = state => {
//    return { noteByUUID: selectNoteByUUID(state) };
//  };
//
export function buildNoteByUUID(notes) {
  const noteByUUID = {};

  (notes || []).forEach(note => {
    noteByUUID[noteUUIDFromNoteParams(note)] = note;
  });

  return noteByUUID;
}

// --------------------------------------------------------------------------
export function buildNoteByUUIDWithLocal(notes) {
  const noteByUUID = {};

  (notes || []).forEach(note => {
    const { localUUID, remoteUUID } = note;

    if (remoteUUID) noteByUUID[noteUUIDFromUUIDs(null, remoteUUID)] = note;
    if (localUUID) noteByUUID[noteUUIDFromUUIDs(localUUID, null)] = note;
  });

  return noteByUUID;
}

// --------------------------------------------------------------------------
export function noteParamsFromNoteUUID(noteUUID) {
  if (!noteUUID) {
    return null;
  } else if (typeof(noteUUID) === "string" && noteUUID.startsWith(LOCAL_UUID_PREFIX)) {
    return { remoteUUID: null, localUUID: noteUUID.slice(LOCAL_UUID_PREFIX.length) };
  } else {
    return { remoteUUID: noteUUID, localUUID: null };
  }
}

// --------------------------------------------------------------------------
export function noteUUIDFromNoteParams(noteParams) {
  if (noteParams) {
    const { localUUID, remoteUUID } = noteParams;
    return noteUUIDFromUUIDs(localUUID, remoteUUID);
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
export function noteUUIDFromUUIDs(localUUID, remoteUUID) {
  if (remoteUUID) {
    return remoteUUID;
  } else if (localUUID) {
    return LOCAL_UUID_PREFIX + localUUID;
  } else {
    return null;
  }
}

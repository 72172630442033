import { Node } from "prosemirror-model"

import { schema } from "lib/ample-editor/schema"
import fuzzyMatch from "lib/ample-util/fuzzy-match"
import { anchorNameFromHeadingText } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
export default function fuzzyMatchHeadings(headings, headingSearchTerm) {
  headingSearchTerm = headingSearchTerm.replace(/]]?$/, "").trim().toLowerCase();

  // Anchors are derived from the text content of the heading, so it's possible for two headings to have the same
  // anchor. We only want to return the first heading with that anchor, as we don't (as of 11/2020) have a way to
  // disambiguate between them.
  const includedByAnchorName = {};

  const headingSuggestions = [];
  headings.forEach((heading, index) => {
    let node;
    try {
      node = Node.fromJSON(schema, heading);
    } catch (_error) {
      // Ignore malformed heading nodes
      return;
    }

    const { attrs: { level: headingLevel }, textContent: headingText } = node;
    const anchorName = anchorNameFromHeadingText(headingText);

    if (includedByAnchorName[anchorName]) return;
    includedByAnchorName[anchorName] = true;

    const headingSearchText = headingText.trim();

    let headingMatch = null;
    let score = 0;
    if (headingSearchTerm.length > 0) {
      headingMatch = fuzzyMatch(headingSearchTerm, headingSearchText, "<b>", "</b>", { shouldEscapeHTML: true });
      if (!headingMatch) return;

      score = headingMatch.score;
      if (headingSearchText.toLowerCase() === headingSearchTerm) {
        score += 0.1;
      }
    }

    headingSuggestions.push({
      anchorName,
      headingLevel,
      headingHTML: headingMatch ? headingMatch.rendered : null,
      headingText,
      index,
      score,
    });
  });

  return headingSuggestions.sort(function(a, b) {
    const compare = b.score - a.score;
    return compare ? compare : a.index - b.index;
  });
}

import PropTypes from "prop-types"
import React from "react"
import { Button } from "@rmwc/button"
import { CircularProgress } from "@rmwc/circular-progress"
import { IconButton } from "@rmwc/icon-button"
import Tippy from "@tippyjs/react"

import ReferencingNote from "lib/ample-editor/components/referencing-notes/referencing-note"
import EditorViewContext from "lib/ample-editor/contexts/editor-view-context"
import NOTE_CONTENT_TYPE from "lib/ample-editor/lib/note-content-type"

// --------------------------------------------------------------------------
export default class UnlinkedReferences extends React.PureComponent {
  static contextType = EditorViewContext;

  state = {
    convertingToLink: false,
    expanded: false,
    loading: false,
    referencingNotes: null,
  };

  // --------------------------------------------------------------------------
  render() {
    const { expanded, loading, referencingNotes } = this.state;

    let primaryButton;
    if (loading) {
      primaryButton = (
        <Button className="find-references-button body-text" disabled icon={ <CircularProgress size="xsmall" /> }>
          Searching for more references to this note
        </Button>
      );
    } else if (referencingNotes !== null) {
      let text;
      if (referencingNotes.length === 0) {
        text = "No additional references found";
      } else {
        const loadedReferencingNotes = referencingNotes.filter(({ references }) => references && references.length > 0);
        const noteCount = loadedReferencingNotes.length;
        text = `Found additional references in ${ noteCount } note${ noteCount === 1 ? "" : "s" }`;
      }

      primaryButton = (
        <Button className="find-references-button body-text" disabled icon="plagiarism">
          { text }
        </Button>
      );
    } else {
      primaryButton = (
        <Button className="find-references-button body-text" icon="plagiarism">
          Find more references to this note
        </Button>
      );
    }

    return (
      <div className="unlinked-references">
        <div className="unlinked-references-header note-link" onClick={ this._toggleExpanded }>
          { primaryButton }
          {
            referencingNotes === null || referencingNotes.length === 0
              ? null
              : (
                <IconButton
                  className="note-expander"
                  icon={ expanded ? "keyboard_arrow_up" : "keyboard_arrow_down" }
                  onClick={ this._toggleExpanded }
                />
              )
          }
        </div>
        { expanded ? this._renderReferencingNotes() : null }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  async _load() {
    this.setState({ loading: true });
    try {
      const { props: { hostApp: { fetchNoteContent, fetchReferencingNotes } } } = this.context;

      let { referencingNotes } = await fetchReferencingNotes("#", { search: true });
      this.setState({ referencingNotes });

      for (let i = 0; i < referencingNotes.length; i++) {
        const referencingNote = referencingNotes[i];

        // eslint-disable-next-line no-await-in-loop
        referencingNote.references = await fetchNoteContent(referencingNote.url, NOTE_CONTENT_TYPE.UNLINKED);

        referencingNotes = [ ...referencingNotes ];
        this.setState({ referencingNotes });
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  // --------------------------------------------------------------------------
  _onConvertToLink = async convertToLinkOptions => {
    const { props: { hostApp: { applyNoteContentActions } } } = this.context;

    this.setState({ convertingToLink: true });
    try {
      const { noteContentActions, noteParams } = convertToLinkOptions;
      await applyNoteContentActions(noteParams, noteContentActions);

      // Intentionally not awaited
      this._load();
      this.props.refreshReferencingNotes();
    } finally {
      this.setState({ convertingToLink: false });
    }
  };

  // --------------------------------------------------------------------------
  _renderReferencingNotes() {
    const { referencingNotes } = this.state;
    if (referencingNotes === null) return null;

    const loadedReferencingNotes = referencingNotes.filter(({ references }) => references && references.length > 0);
    if (loadedReferencingNotes.length === 0) return null;

    return loadedReferencingNotes.map(({ iconName, name, references, tags, url }) => {
      return (
        <ReferencingNote
          iconName={ iconName }
          key={ url }
          name={ name }
          renderSnippetAction={ this._renderSnippetAction.bind(this, url) }
          references={ references }
          tags={ tags }
          url={ url }
        />
      );
    });
  }

  // --------------------------------------------------------------------------
  _renderSnippetAction = (noteURL, index) => {
    const { convertingToLink, referencingNotes } = this.state;
    if (!referencingNotes) return null;

    const referencingNote = referencingNotes.find(({ url }) => url === noteURL);
    if (!referencingNote || !referencingNote.references) return null;

    const { convertToLinkOptions } = referencingNote.references[index] || {};
    if (!convertToLinkOptions) return null;

    return (
      <Tippy content="Convert this reference to a link" delay={ 150 }>
        <span className="snippet-action-container">
          <IconButton
            className="snippet-action"
            disabled={ convertingToLink }
            icon="link"
            onClick={ this._onConvertToLink.bind(this, convertToLinkOptions) }
          />
        </span>
      </Tippy>
    );
  };

  // --------------------------------------------------------------------------
  _toggleExpanded = () => {
    const { expanded: expandedWas, loading, referencingNotes } = this.state;
    this.setState({ expanded: !expandedWas });

    if (!expandedWas && !loading && referencingNotes === null) {
      this._load();
    }
  };
}

UnlinkedReferences.propTypes = {
  refreshReferencingNotes: PropTypes.func.isRequired,
};

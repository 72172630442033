import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
export function transactionChangesDoc(transaction) {
  return transaction.docChanged;
}

// --------------------------------------------------------------------------
export function transactionMarkedReadonly(transaction) {
  return transaction.getMeta(TRANSACTION_META_KEY.READONLY);
}

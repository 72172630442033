import { Plugin, PluginKey } from "prosemirror-state"

import EmojiPopperView from "lib/ample-editor/views/emoji-popper-view"

// --------------------------------------------------------------------------
// Note that any exports in this file need to have dummy versions in emoji-popper.embed.js
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export const emojiPopperPluginKey = new PluginKey("emoji-popper");

// --------------------------------------------------------------------------
// Return the string that follows the most recent emoji invocation (document position where user pressed ":"),
// or null if no such string exists. In the case where there is text following the emoji string, we'll only
// look at the emoji string, so ":lemon lime 7up" would return "lemon"
export function emojiSelectorString(startOfString, state) {
  if (startOfString) {
    const $pos = state.doc.resolve(startOfString);
    const parentNode = $pos.node($pos.depth);
    const fullText = parentNode.textBetween($pos.parentOffset, parentNode.content.size);
    if (fullText.length <= 1 || fullText[0] !== ":") return null;

    const regexMatch = fullText.match(/(\s|^|\():([^:\n\s]*)/);
    if (regexMatch) {
      return regexMatch[2];
    }
  }

  return null;
}

// --------------------------------------------------------------------------
export function openEmojiPopperTextStartPos(state) {
  const pluginState = emojiPopperPluginKey.getState(state);

  if (pluginState && pluginState.textStartPos !== null) {
    return pluginState.textStartPos;
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
export default function emojiPlugin() {
  let emojiPluginView = null;

  return new Plugin({
    key: emojiPopperPluginKey,

    // --------------------------------------------------------------------------
    props: {
      handleKeyDown: (view, event) => {
        return emojiPluginView ? emojiPluginView.handleKeyDown(view, event) : false;
      },

      // This is roughly what prosemirror-inputrules does, but doesn't replace the entire matched string, so it can
      // operate in/after/around inline nodes like links and images.
      handleTextInput: function handleTextInput(view, from, to, text) {
        const { state } = view;
        const $from = state.doc.resolve(from);
        if ($from.parent.type.spec.code) return false;

        // Only go 500 character back, at most (matching prosemirror-inputrules behavior)
        const textBefore = $from.parent.textBetween(Math.max(0, $from.parentOffset - 500), $from.parentOffset, null, "\ufffc") + text;
        // WBH initially tried \p{ Emoji } but found false positives mathcing numbers. This regex is second Google result https://www.regextester.com/106421. It doesn't catch everything, but it catches a lot and it doesn't falsely match numbers (example shows regex matches through 2018)
        const emojiRegex = "\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]";
        const regex = new RegExp(`(\\s|^|\\(|${ emojiRegex }):[^:\n\\s]*$`);
        const match = regex.exec(textBefore);
        if (match) {
          const transform = state.tr;

          const start = from - (match[0].length - text.length);
          const textMatchStartPos = start + match[1].length;

          transform.setMeta(emojiPopperPluginKey, { start: textMatchStartPos });
          transform.insertText(text);
          view.dispatch(transform);

          return true;
        }

        return false;
      }
    },

    // --------------------------------------------------------------------------
    state: {
      init: (_config, _state) => ({
        textStartPos: null,
        // If the user presses escape to cancel popper then continues typing, ensure we don't restore popper even
        // though the regex for it would still match
        cancelStartPos: null,
        suggestedEmoji: null,
      }),
      apply: (tr, pluginState, lastState, state) => {
        let { cancelStartPos, textStartPos, suggestedEmoji } = pluginState;
        const { selection: { $to } } = state;
        const metaProps = tr.getMeta(emojiPopperPluginKey);

        // Only present when the emoji regexp is matching and a transaction (most likely inputrules.js) has included this:
        if (metaProps) {
          if (metaProps.start) {
            if (cancelStartPos && cancelStartPos === metaProps.start) {
              // User previously hit escape at our regex-matching start point, so don't show them popper anymore
            } else if (!textStartPos || (textStartPos !== metaProps.start)) {
              // Resolving position here allows us to reference the position's parentOffset for each subsequent keypress where
              // we want to grab the emoji selector string
              textStartPos = metaProps.start;
            }
          }

          if (metaProps.cancelStartPos && metaProps.cancelStartPos === textStartPos) {
            cancelStartPos = metaProps.cancelStartPos;
            textStartPos = null;
          }
        }

        // If the popper has a textStartPos, we'll consider it open when the cursor is in the same node + adjacent to the start position
        if (textStartPos) {
          let textStartNode = null;
          try {
            textStartNode = state.doc.nodeAt(textStartPos);
          } catch (_error) {
            // Typically `RangeError: Position ... outside of fragment (...)`, indicating that the document has been
            // shortened, removing the textStartPos
            return { cancelStartPos, textStartPos: null, suggestedEmoji };
          }

          const cursorNode = state.doc.nodeAt($to.pos - 1);
          let stayOpen = false;

          const searchString = emojiSelectorString(textStartPos, state);
          if (!textStartNode || $to.pos < textStartPos) {
            // Close popper if cursor is before starting point of regex
          } else if (textStartNode === cursorNode && searchString && ((textStartPos + searchString.length + 1) >= $to.pos)) {
            stayOpen = true;
          } else if ($to.pos - textStartPos <= 1) {
            // If user just typed the emoji invocation, we still want to keep popper open even though there's no search
            // string yet - this can also mean that the user inserted a single-character emoji (e.g. ✅), in which case
            // we _don't_ want to leave the popper open. The view needs to impart this knowledge to us (that it's
            // just inserted an emoji) via the metaprops, otherwise we can't easily discern the difference.
            stayOpen = !(metaProps && metaProps.start === null);
          }

          if (stayOpen) {
            suggestedEmoji = emojiPluginView.searchPicker(searchString);
          } else {
            textStartPos = null;
          }
        }

        return { cancelStartPos, textStartPos, suggestedEmoji };
      },
      toJSON: ({ cancelStartPos, textStartPos, suggestedEmoji }) => ({ cancelStartPos, textStartPos, suggestedEmoji }),
      fromJSON: (_config, { cancelStartPos, textStartPos, suggestedEmoji }) => ({ cancelStartPos, textStartPos, suggestedEmoji }),
    },

    // --------------------------------------------------------------------------
    view: editorView => {
      emojiPluginView = new EmojiPopperView(editorView);
      return emojiPluginView;
    },
  });
}

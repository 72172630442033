// --------------------------------------------------------------------------
// code-block-util is the home of functions that are neither (state,dispatch)-style commands, nor directly pertaining
// to the code-block-view or code-mirror-editor, or are used by a combination of these existing locations.
export const LANGUAGE = {
  C: "c",
  CPP: "cpp",
  CSHARP: "csharp",
  CSS: "css",
  HTML: "html",
  JAVA: "java",
  JAVASCRIPT: "javascript",
  JSON: "json",
  PLAIN: "plain",
  PYTHON: "python",
  RAINBOW: "rainbow",
  RAINBOW2: "rainbow2",
  RAINBOW3: "rainbow3",
  RAINBOW4: "rainbow4",
  RAINBOW5: "rainbow5",
  RUBY: "ruby",
  RUST: "rust",
  XML: "xml",
};

export const LANGUAGE_LABEL = {
  [LANGUAGE.C]: "C",
  [LANGUAGE.CPP]: "C++",
  [LANGUAGE.CSHARP]: "C#",
  [LANGUAGE.CSS]: "CSS",
  [LANGUAGE.HTML]: "HTML",
  [LANGUAGE.JAVA]: "Java",
  [LANGUAGE.JAVASCRIPT]: "JavaScript",
  [LANGUAGE.JSON]: "JSON",
  [LANGUAGE.PLAIN]: "Plain",
  [LANGUAGE.PYTHON]: "Python",
  [LANGUAGE.RAINBOW]: "Rainbow",
  [LANGUAGE.RAINBOW2]: "Rainbow-2",
  [LANGUAGE.RAINBOW3]: "Rainbow-3",
  [LANGUAGE.RAINBOW4]: "Rainbow-4",
  [LANGUAGE.RAINBOW5]: "Rainbow-5",
  [LANGUAGE.RUBY]: "Ruby",
  [LANGUAGE.RUST]: "Rust",
  [LANGUAGE.XML]: "XML",
}

export const LANGUAGE_WORD_MATCHES = {
  "c": LANGUAGE.C,
  "c++": LANGUAGE.CPP,
  "csharp": LANGUAGE.CSHARP,
  "c#": LANGUAGE.CSHARP,
  "css": LANGUAGE.CSS,
  "disable": LANGUAGE.PLAIN,
  "html": LANGUAGE.HTML,
  "java": LANGUAGE.JAVA,
  "javascript": LANGUAGE.JAVASCRIPT,
  "js": LANGUAGE.JAVASCRIPT,
  "json": LANGUAGE.JAVASCRIPT,
  "none": LANGUAGE.PLAIN,
  "plain": LANGUAGE.PLAIN,
  "python": LANGUAGE.PYTHON,
  "rainbow": LANGUAGE.RAINBOW,
  "rainbow1": LANGUAGE.RAINBOW,
  "rainbow2": LANGUAGE.RAINBOW2,
  "rainbow3": LANGUAGE.RAINBOW3,
  "rainbow4": LANGUAGE.RAINBOW4,
  "rainbow5": LANGUAGE.RAINBOW5,
  "ruby": LANGUAGE.RUBY,
  "rust": LANGUAGE.RUST,
  "scss": LANGUAGE.CSS,
  "xml": LANGUAGE.XML,
};

// It's called "Legacy mode" cuz CM6 suggests defining languages via the modules embedded within languages-with-data.js,
// but for languages we want to customize, it's often been easier to do that by adapting CM5-style language definitions.
// Legacy language modes list https://codemirror.net/5/mode/ most all have single files ready to be adapted
// CM6-style language definitions are more complex affairs: https://github.com/codemirror?q=lang&type=all e.g., https://github.com/codemirror/lang-javascript/tree/main/src
export const LEGACY_MODE_LANGUAGES = {
  [LANGUAGE.C]: { file: "clike", extract: "c" },
  [LANGUAGE.CPP]: { file: "clike", extract: "cpp" },
  [LANGUAGE.CSHARP]: { file: "clike", extract: "csharp" },
  [LANGUAGE.JAVA]: { file: "clike", extract: "java" },
  [LANGUAGE.PLAIN]: { file: "plain", extract: "plain" },
  [LANGUAGE.PYTHON]: { file: "python", extract: "python" },
  [LANGUAGE.RAINBOW]: { file: "plain", extract: "plain" },
  [LANGUAGE.RAINBOW2]: { file: "plain", extract: "plain" },
  [LANGUAGE.RAINBOW3]: { file: "plain", extract: "plain" },
  [LANGUAGE.RAINBOW4]: { file: "plain", extract: "plain" },
  [LANGUAGE.RAINBOW5]: { file: "plain", extract: "plain" },
  [LANGUAGE.RUBY]: { file: "ruby", extract: "ruby" },
  [LANGUAGE.RUST]: { file: "rust", extract: "rust" },
  [LANGUAGE.XML]: { file: "xml", extract: "xml" },
};

export const CODE_BLOCK_COPY_ICON_CLASS = "code-block-copy-icon";

const CODE_LANGUAGE_TOKEN = "<code>";
const DEFAULT_COPY_PROMPT = `Copy all contents of this ${ CODE_LANGUAGE_TOKEN } block to clipboard`;
const MAX_BLOCK_SIZE_TO_SCAN_FOR_LANGUAGE = 5000; // Don't want to be splitting blocks of arbitrarily large size regularly
const MAX_LINES_TO_SCAN_FOR_LANGUAGE_PATTERN = 100; // Reduce amount of regex searching for possible language in code block

// --------------------------------------------------------------------------
export const codeBlockDepth = $pos => {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth);
    if (node && node.type.spec.code) {
      return depth;
    }
  }
  return null;
}

// --------------------------------------------------------------------------
// Returns a member of LANGUAGE, or null if no language is found
export const languageFromText = text => {
  // Not splitting by \n & grabbing first in case text is huge
  const firstNewLineIndex = text.indexOf("\n");
  const firstLine = firstNewLineIndex === -1 ? text : text.substr(0, firstNewLineIndex);

  const languageWordMatch = Object.keys(LANGUAGE_WORD_MATCHES).find(languageReferencedAs => {
    const includedWord = firstLine.split(/[\s\W]+/).map(word => word.toLowerCase()).includes(languageReferencedAs);
    if (includedWord) return true;
    return false;
  });

  if (languageWordMatch) return LANGUAGE_WORD_MATCHES[languageWordMatch];

  if (text.length < MAX_BLOCK_SIZE_TO_SCAN_FOR_LANGUAGE) {
    const lines = text.split("\n");
    for (let i = 1; i < Math.min(lines.length, MAX_LINES_TO_SCAN_FOR_LANGUAGE_PATTERN); i++) {
      const line = lines[i];
      const trimmedLine = line.trim();

      if (/&lt;(div|span)(\s.+)?&gt;/.test(line)) {
        return LANGUAGE.HTML;
      } else if (/^def\s[^:]+$/.test(trimmedLine)) {
        return LANGUAGE.RUBY;
      } else if (/^def\s[.]+:$/.test(trimmedLine)) {
        return LANGUAGE.PYTHON;
      } else if (/^void\s[.]+\(/.test(trimmedLine)) {
        return LANGUAGE.CSHARP;
      } else if (/^fn\s[.]+\([.]*\)\s{/.test(trimmedLine)) {
        return LANGUAGE.RUST;
      } else if (/^(background|border|color|display|margin|padding|position):/.test(trimmedLine)) {
        return LANGUAGE.CSS;
      }
    }
  }

  return null;
};

// --------------------------------------------------------------------------
export function copyIconTitle(languageEm) {
  return DEFAULT_COPY_PROMPT.replace(CODE_LANGUAGE_TOKEN, printedLanguageName(languageEm));
}

// --------------------------------------------------------------------------
function printedLanguageName(languageEm) {
  return LANGUAGE_LABEL[languageEm];
}


// --------------------------------------------------------------------------
const FAILURE_REASON = {
  NO_MOUNT_PLUGIN_EMBED: "no-mount-plugin-embed",
  NO_PLUGIN_RENDERED: "no-plugin-rendered",
};

// --------------------------------------------------------------------------
function classNameFromFailureReason(failureReason) {
  return failureReason ? failureReason : "";
}

// --------------------------------------------------------------------------
export default class EmbedView {
  _destroyPluginEmbed = null;
  _editorView = null;
  _failureReason = null;
  _iframe = null;
  _nodeType = null;
  _rendered = false;

  // --------------------------------------------------------------------------
  constructor(node, editorView, getPos) {
    this._editorView = editorView;
    this._nodeType = node.type;

    this._pluginContext = this._pluginContext.bind(this, editorView, getPos);

    this.dom = document.createElement("div");
    this.dom.className = "embed";

    this._iframe = document.createElement("iframe");
    this._iframe.setAttribute("frameborder", "0");
    this.dom.appendChild(this._iframe);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy() {
    if (this._destroyPluginEmbed) {
      this._destroyPluginEmbed();
      this._destroyPluginEmbed = null;
    }
  }

  // --------------------------------------------------------------------------
  update(node) {
    if (node.type !== this._nodeType) return false;

    const { attrs: { aspectRatio, src } } = node;

    if (!this._rendered) {
      this._failureReason = null;
      this._rendered = true;

      const { props: { hostApp: { mountPluginEmbed } } } = this._editorView;
      if (mountPluginEmbed) {
        const pluginMatch = src.match(/^plugin:\/\/([a-fA-F0-9-]+)(?:$|\/.+)?(\?[^#]+)?/);

        const pluginUUID = pluginMatch ? pluginMatch[1] : null;
        const query = pluginMatch && pluginMatch[2] ? pluginMatch[2].slice(1) : null;
        const args = query ? [ query ] : [];

        mountPluginEmbed(pluginUUID, this._pluginContext(), args, this._iframe).then(destroyPluginEmbed => {
          if (destroyPluginEmbed) {
            this._destroyPluginEmbed = destroyPluginEmbed;
          } else {
            this._failureReason = FAILURE_REASON.NO_PLUGIN_RENDERED;
            this._updateClassName();
          }
        });
      } else {
        this._failureReason = FAILURE_REASON.NO_MOUNT_PLUGIN_EMBED;
      }
    }

    this._updateClassName();

    this._iframe.setAttribute("data-aspect-ratio", aspectRatio);
    this.dom.style.paddingBottom = `${ (1 / aspectRatio) * 100 }%`;

    return true;
  }

  // --------------------------------------------------------------------------
  _getNode = (editorView, nodePos) => {
    const { state: { doc } } = editorView;

    let node;
    try {
      node = doc.nodeAt(nodePos);
    } catch (_error) {
      // Can throw a `RangeError: Index N out of range for ...` in `nodeAt`
      return null;
    }

    return (node && node.type === this._nodeType) ? node : null;
  };

  // --------------------------------------------------------------------------
  _pluginContext = (editorView, getPos) => {
    return {
      updateEmbedArgs: newArgs => {
        const nodePos = getPos();
        const node = this._getNode(editorView, nodePos);
        if (!node) return false;

        const { attrs: { src } } = node;
        const pluginMatch = src.match(/^plugin:\/\/([a-fA-F0-9-]+)(?:$|\/.+)?(\?[^#]+)?/);
        const pluginUUID = pluginMatch ? pluginMatch[1] : null;
        if (!pluginUUID) return false;

        const newSrc = `plugin://${ pluginUUID }${ newArgs ? `?${ newArgs.toString() }` : "" }`;

        const { dispatch, state } = editorView;
        dispatch(
          state.tr
            .setNodeMarkup(nodePos, null, { ...node.attrs, src: newSrc })
            .setMeta("addToHistory", false)
        );
      },
    };
  };

  // --------------------------------------------------------------------------
  _updateClassName() {
    this.dom.className = `embed ${ classNameFromFailureReason(this._failureReason) }`;
  }
}

import { TextSelection } from "prosemirror-state"

import { COLLAPSIBLE_HEADING_DEPTH } from "lib/ample-editor/lib/collapsible-defines"
import ExpandingListItemSelection, {
  expandListItemSelectionBackward,
  nodeChildrenRange,
} from "lib/ample-editor/lib/expanding-list-item-selection"
import { selectedListItemDepth } from "lib/ample-editor/lib/list-item-commands"
import { isPosCollapsibleNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"

// --------------------------------------------------------------------------
// When this command is invoked via click, we'll receive the `pos` to ascertain the
// position of the node that got collapsed. Otherwise we'll take the position from state, assuming hotkey
// was used to toggle collapse state
export function buildToggleNodeCollapsed(pos = null) {
  return function(state, dispatch) {
    const { doc, schema, tr: transform } = state;

    let collapsibleNodePos = null;
    if (pos !== null) {
      // Expander has been clicked:
      collapsibleNodePos = pos;
    } else {
      // Hotkey or toolbar used to toggle. Set position iff the node has children or is a childless child
      const { $from, depth: listItemDepth } = selectedListItemDepth(state, true);
      if (listItemDepth !== null) {
        // If we're already at a node boundary, use that. Otherwise, the node position before depth 1 (applies only to notes mode thus far)
        const candidateNodePos = $from.before(1) === $from.after(1) ? $from.pos : $from.before(1);
        const $candidatePos = doc.resolve(candidateNodePos);
        const candidateNode = $candidatePos.nodeAfter;
        if (!candidateNode || !candidateNode.type.spec.isListItem) return false;

        // If $candidatePos has children, that will be the collapse/expand target.
        if (hasChildListItems($candidatePos)) {
          collapsibleNodePos = candidateNodePos;
        } else if (candidateNode.attrs.indent > 0) {
          // If $candidatePos is a childless child, then toggle parent to collapsed on collapse hotkey
          const parentSelection = expandListItemSelectionBackward($candidatePos, $candidatePos.nodeAfter.attrs.indent - 1);
          if (parentSelection) {
            collapsibleNodePos = parentSelection.$start.pos;
          }
        }
      } else {
        const headingNode = $from.node(COLLAPSIBLE_HEADING_DEPTH);
        if (!headingNode || headingNode.type !== schema.nodes.heading) return false;

        collapsibleNodePos = $from.before(COLLAPSIBLE_HEADING_DEPTH);
        if (!isPosCollapsibleNode(state, collapsibleNodePos)) return false;
      }
    }

    if (collapsibleNodePos === null) return false;

    const collapsibleNode = doc.nodeAt(collapsibleNodePos);
    if (!isCollapsibleNode(schema, collapsibleNode)) return false;

    if (dispatch) {
      if (pos === null) {
        // If user's selection range is partially in newly collapsed area, we'll want to unset selection
        const { $from, $to } = state.selection;
        if ($to.pos !== $from.pos) {
          const childrenRange = nodeChildrenRange(doc, collapsibleNode, collapsibleNodePos);
          if (childrenRange.from < $to.pos && childrenRange.to > $to.pos && dispatch) {
            transform.setSelection(TextSelection.create(doc, $from.pos));
          }
        }
      }

      // It's annoying if a parent node starts with a link if expanding/collapsing it opens the RF (covering the
      // children when expanding the parent).
      setOpenLinkPosition(transform, null);

      const collapsed = !collapsibleNode.attrs.collapsed;
      transform.setNodeMarkup(collapsibleNodePos, null, { ...collapsibleNode.attrs, collapsed });
      dispatch(transform);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
function hasChildListItems($resolvedPos) {
  const selection = ExpandingListItemSelection.create($resolvedPos);
  const node = $resolvedPos.nodeAfter;
  return node && selection.to > selection.from + node.nodeSize;
}

// --------------------------------------------------------------------------
function isCollapsibleNode(schema, node) {
  if (!node) return false;

  return node.type.spec.isListItem || node.type === schema.nodes.heading;
}

// --------------------------------------------------------------------------
const toggleNodeCollapsed = buildToggleNodeCollapsed();
export default toggleNodeCollapsed;

import { html } from "@codemirror/lang-html"
import { javascript } from "@codemirror/lang-javascript"
import { css } from "@codemirror/lang-css"

// --------------------------------------------------------------------------
export default function languagesWithData(languageEm) {
  switch (languageEm) {
    case "html":
      return html()
    case "javascript":
      return javascript()
    case "css":
      return css()
    default:
      throw new Error(`Language ${ languageEm } not supported`)
  }
}

import { defer } from "lodash"
import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Button } from "@rmwc/button"
import { MenuSurface, MenuSurfaceAnchor } from "@rmwc/menu"

import CommandsMenuButton from "lib/ample-editor/components/list-item-detail/commands-menu-button"
import DurationControls from "lib/ample-editor/components/list-item-detail/duration-controls"
import NotifyControls from "lib/ample-editor/components/list-item-detail/notify-controls"
import RepeatControls from "lib/ample-editor/components/list-item-detail/repeat-controls"
import ScheduledDateControls from "lib/ample-editor/components/list-item-detail/scheduled-date-controls"
import SelectNoteMenu from "lib/ample-editor/components/select-note-menu"
import { deviceSupportsHover } from "lib/ample-editor/lib/client-info"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import LIST_ITEM_COMMAND from "lib/ample-editor/lib/list-item-command"
import {
  changesFromDue,
  changesFromRepeat,
  EMPTY_RRULE,
  minutesFromDuration,
  REPEAT_TYPE,
  repeatTypeFromRepeat,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// "changes" here can be changes intended for a task (check-list-item's attributes) or a bullet-list-item
function bulletListItemChangesFromChanges(changes) {
  const attributes = {};

  if ("duration" in changes) attributes.duration = changes.duration;
  if ("notify" in changes) attributes.notify = changes.notify;
  if ("repeat" in changes) attributes.repeat = changes.repeat;

  if ("due" in changes) {
    attributes.scheduledAt = changes.due;
  } else if ("scheduledAt" in changes) {
    attributes.scheduledAt = changes.scheduledAt;
  }

  return attributes;
}

// --------------------------------------------------------------------------
function useAutoFocus(shouldAutoFocus) {
  const eventDetailRef = useRef();

  useEffect(
    () => {
      if (!deviceSupportsHover || !shouldAutoFocus) return;

      const { current: eventDetail } = eventDetailRef;

      const focusElement = eventDetail.querySelector(".repeat-input-container .mdc-select__selected-text");
      if (focusElement) focusElement.focus();
    },
    [ shouldAutoFocus ]
  );

  return eventDetailRef;
}

// --------------------------------------------------------------------------
function BottomBar(props) {
  const {
    applyListItemCommand,
    close,
    convertToTask,
    crossOut,
    deleteListItem,
    disabled,
    duration,
    notify,
    repeat,
    scheduledAt,
    showCopyEventButton,
    suggestNotes,
    updateAttributes,
  } = props;

  const onCrossOutClick = useCallback(
    () => {
      crossOut();
      if (close) close();
    },
    [ close, crossOut ]
  );

  const onRemoveFromScheduleClick = useCallback(
    () => {
      updateAttributes({ duration: null, notify: null, repeat: null, scheduledAt: null });
      if (close) close();
    },
    [ close, updateAttributes ]
  );

  const endsAt = useMemo(
    () => {
      const durationMinutes = minutesFromDuration(duration) || 0;
      return scheduledAt + durationMinutes * 60;
    },
    [ duration, scheduledAt ]
  );

  const attributes = useMemo(
    () => ({ duration, notify, repeat, scheduledAt }),
    [ duration, notify, repeat, scheduledAt ]
  );

  let initialButton = null;
  if (applyListItemCommand) {
    if (showCopyEventButton) {
      initialButton = (
        <BottomBarCopyEventButton
          applyListItemCommand={ applyListItemCommand }
          attributes={ attributes }
          suggestNotes={ suggestNotes }
        />
      );
    } else {
      initialButton = (
        <CommandsMenuButton
          applyListItemCommand={ applyListItemCommand }
          attributes={ attributes }
          listItemType="bullet_list_item"
          suggestNotes={ suggestNotes }
        />
      );
    }
  }

  return (
    <div className="bottom-bar">
      { initialButton }

      {
        convertToTask
          ? (
            <React.Fragment>
              <BottomBarActionButton
                className="convert-to-task-button"
                label="Convert to task"
                onClick={ convertToTask }
              />

              <div className="divider" />
            </React.Fragment>
          )
          : null
      }

      {
        disabled
          ? null
          : (
            <BottomBarActionButton
              className="remove-from-schedule-button"
              label="Remove from schedule"
              onClick={ onRemoveFromScheduleClick }
            />
          )
      }

      {
        !crossOut || Math.floor(Date.now() / 1000) > endsAt
          ? null
          : (
            <React.Fragment>
              <div className="divider"/>

              <BottomBarActionButton
                className="cross-out-button"
                label="Cross out"
                onClick={ onCrossOutClick }
              />
            </React.Fragment>
          )
      }

      {
        deleteListItem
          ? (
            <React.Fragment>
              <div className="divider"/>

              <BottomBarActionButton
                className="delete-button"
                label="Delete"
                onClick={ deleteListItem }
              />
            </React.Fragment>
          )
          : null
      }

      {
        close
          ? (
            <Button
              className="close-button"
              label="Done"
              onClick={ close }
              // This is necessary to prevent the material web component forcing focus - we handle
              // focusing the editor after closing in situations where we actually want focus
              onMouseDown={ preventEventDefault }
              outlined
              tabIndex="-1"
            />
          )
          : null
      }
    </div>
  );
}

// --------------------------------------------------------------------------
function BottomBarActionButton({ className, icon, label, onClick, outlined }) {
  return (
    <Button
      className={ `action ${ className }` }
      icon={ icon }
      label={ label }
      onClick={ onClick }
      // This is necessary to prevent the material web component forcing focus - we handle
      // focusing the editor after closing in situations where we actually want focus
      onMouseDown={ preventEventDefault }
      outlined={ outlined }
      tabIndex="-1"
    />
  );
}

// --------------------------------------------------------------------------
function BottomBarCopyEventButton({ applyListItemCommand, attributes, suggestNotes }) {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);

  const closeMenu = useCallback(() => setIsMenuOpen(false), []);
  const toggleMenu = useCallback(() => setIsMenuOpen(isMenuOpenWas => !isMenuOpenWas), []);

  // Can't use autoFocus since menu isn't visible on first mount, so need to wait until menu opens
  const bottomBarCopyEventMenuContentRef = useRef();
  const onOpen = useCallback(
    () => {
      const { current: bottomBarCopyEventMenuContent } = bottomBarCopyEventMenuContentRef;
      if (bottomBarCopyEventMenuContent) bottomBarCopyEventMenuContent.focus();
    },
    []
  );

  return (
    <MenuSurfaceAnchor className={ `copy-event-button ${ isMenuOpen ? "open" : "" }` }>
      <MenuSurface
        anchorCorner="bottomStart"
        onClose={ closeMenu }
        onOpen={ onOpen }
        open={ isMenuOpen }
      >
        {
          isMenuOpen
            ? (
              <BottomBarCopyEventMenuContent
                applyListItemCommand={ applyListItemCommand }
                attributes={ attributes }
                close={ closeMenu }
                ref={ bottomBarCopyEventMenuContentRef }
                suggestNotes={ suggestNotes }
              />
            )
            : null
        }
      </MenuSurface>

      <BottomBarActionButton
        label="Copy event"
        icon="content_copy"
        onClick={ toggleMenu }
      />
    </MenuSurfaceAnchor>
  );
}

// --------------------------------------------------------------------------
function BottomBarCopyEventMenuContent({ applyListItemCommand, attributes, close, suggestNotes }, ref) {
  const applyCommandCopy = useCallback(
    options => {
      applyListItemCommand(attributes, "bullet_list_item", LIST_ITEM_COMMAND.COPY, options);
      close();
    },
    [ applyListItemCommand, attributes, close ]
  );

  const selectNoteMenuRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => {
      const { current: selectNoteMenu } = selectNoteMenuRef;
      if (selectNoteMenu) selectNoteMenu.focus();
    },
  }));

  return (
    <SelectNoteMenu
      actionDescription="copy this event"
      actionDescriptionShort="Copy"
      apply={ applyCommandCopy }
      cancel={ close }
      ref={ selectNoteMenuRef }
      suggestNotes={ suggestNotes }
    />
  );
}
// eslint-disable-next-line no-func-assign
BottomBarCopyEventMenuContent = forwardRef(BottomBarCopyEventMenuContent);

// --------------------------------------------------------------------------
export default function EventDetail(props) {
  const {
    applyListItemCommand,
    autoFocus,
    close,
    convertToTask,
    crossOut,
    deleteListItem,
    disabled,
    duration,
    notify,
    repeat,
    scheduledAt,
    showCopyEventButton,
    suggestNotes,
    updateAttributes,
  } = props;

  const onLastDurationKeyDown = useCallback(
    event => {
      if (close && event.key === "Tab" && !event.shiftKey) {
        // Need to defer this because the calling function expects the Chip to still exist after this is called, but
        // closing will immediately unmount and remove the Chip, resulting in a
        defer(close);
      }
    },
    [ close ]
  );

  const taskAttributes = useMemo(
    () => ({ due: scheduledAt, repeat }),
    [ repeat, scheduledAt ]
  );

  const setDuration = useCallback(
    newDuration => { updateAttributes({ duration: newDuration === "PT0S" ? null : newDuration }); },
    [ updateAttributes ]
  );

  const setNotify = useCallback(
    newNotify => { updateAttributes({ notify: newNotify }); },
    [ updateAttributes ]
  );

  const setRepeat = useCallback(
    newRepeat => {
      const changes = changesFromRepeat(taskAttributes, newRepeat);
      updateAttributes(bulletListItemChangesFromChanges(changes));
    },
    [ taskAttributes, updateAttributes ]
  );

  const setRrule = useCallback(
    newRrule => {
      const changes = changesFromRepeat(taskAttributes, newRrule && newRrule.length > 0 ? newRrule : EMPTY_RRULE);
      updateAttributes(bulletListItemChangesFromChanges(changes));
    },
    [ taskAttributes, updateAttributes ]
  );

  const setScheduledDate = useCallback(
    (newScheduledDate, _scheduledDayPart) => {
      const newScheduledAt = newScheduledDate ? Math.floor(newScheduledDate.getTime() / 1000) : null;
      const changes = changesFromDue(taskAttributes, newScheduledAt);
      updateAttributes(bulletListItemChangesFromChanges(changes));
    },
    [ taskAttributes, updateAttributes ]
  );

  const repeatType = repeatTypeFromRepeat(repeat);

  const eventDetailRef = useAutoFocus(autoFocus);

  return (
    <div className="event-detail" ref={ eventDetailRef }>
      <div className="list-item-detail-controls">
        <div className="controls">
          <RepeatControls
            close={ close }
            listItemType="bullet_list_item"
            onAttrChange={ updateAttributes }
            readonly={ disabled }
            repeatType={ repeatType }
          />

          <ScheduledDateControls
            listItemType="bullet_list_item"
            readonly={ disabled }
            repeat={ repeat }
            scheduledAt={ scheduledAt }
            setRepeat={ setRepeat }
            setRrule={ setRrule }
            setScheduledDate={ setScheduledDate }
          />

          <NotifyControls
            // Disable input for non-repeating due dates that have passed
            disabled={ disabled || (repeatType === REPEAT_TYPE.NONE && scheduledAt < Math.floor(Date.now() / 1000)) }
            notify={ notify }
            setNotify={ setNotify }
          />

          <DurationControls
            duration={ duration }
            listItemType="bullet_list_item"
            onLastControlKeyDown={ onLastDurationKeyDown }
            readonly={ disabled }
            setDuration={ setDuration }
          />
        </div>
      </div>

      <BottomBar
        applyListItemCommand={ applyListItemCommand }
        close={ close }
        convertToTask={ convertToTask }
        crossOut={ crossOut }
        deleteListItem={ deleteListItem }
        disabled={ disabled }
        duration={ duration }
        notify={ notify }
        repreat={ repeat }
        scheduledAt={ scheduledAt }
        suggestNotes={ suggestNotes }
        showCopyEventButton={ showCopyEventButton }
        updateAttributes={ updateAttributes }
      />
    </div>
  );
}

EventDetail.propTypes = {
  applyListItemCommand: PropTypes.func,
  autoFocus: PropTypes.bool,
  close: PropTypes.func,
  convertToTask: PropTypes.func,
  crossOut: PropTypes.func,
  deleteListItem: PropTypes.func,
  disabled: PropTypes.bool,
  duration: PropTypes.string,
  notify: PropTypes.string,
  repeat: PropTypes.string,
  scheduledAt: PropTypes.number.isRequired,
  showCopyEventButton: PropTypes.bool,
  suggestNotes: PropTypes.func,
  updateAttributes: PropTypes.func.isRequired,
};

import PropTypes from "prop-types"
import React, { useContext, useMemo } from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import { useAsyncEffect } from "@react-hook/async"

import { INLINE_DETAILS_TYPE } from "lib/ample-editor/components/check-list-item/inline-details"
import TasksEditor from "lib/ample-editor/components/tasks-editor"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { amplenoteParamsFromHref } from "lib/ample-util/amplenote-area"
import { urlFromNoteParams } from "lib/ample-util/note-url"
import { checkListItemFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function noop() {
  // NO-OP
}

// --------------------------------------------------------------------------
export default function TaskPreview({ taskHref }) {
  const hostApp = useContext(HostAppContext);

  const amplenoteParams = amplenoteParamsFromHref(taskHref);
  const taskUUID = amplenoteParams && amplenoteParams.task ? amplenoteParams.task.uuid : null;

  const { fetchTask } = hostApp;
  const { status, value: task } = useAsyncEffect(() => fetchTask(taskUUID), [ taskUUID ]);

  const editorProps = useMemo(
    () => ({ hostApp, inlineCheckListItemDetailsType: INLINE_DETAILS_TYPE.CREATED }),
    [ hostApp ]
  );

  const tasksWithGroup = useMemo(
    () => {
      if (!task) return [];

      const { note } = task;

      const checkListItem = {
        ...checkListItemFromTask(task),
        note: { name: note.name, url: urlFromNoteParams(note) },
      };

      return [ { groupId: "task", tasks: [ checkListItem ] } ];
    },
    [ task ]
  );

  let content;
  if (task) {
    content = (
      <TasksEditor
        dispatchChanges={ noop }
        editorProps={ editorProps }
        hideToolbar
        includeSourceNotes
        readonly
        tasksWithGroup={ tasksWithGroup }
      />
    );
  } else if (status === "loading") {
    content = (
      <div className="loading-indicator-container">
        <CircularProgress size="medium"/>
      </div>
    );
  } else {
    return null;
  }

  return (
    <div className="preview-section task-preview">
      { content }
    </div>
  );
}

TaskPreview.propTypes = {
  taskHref: PropTypes.string.isRequired,
};

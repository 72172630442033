import PropTypes from "prop-types"
import React from "react"

import GoogleSheetsPreview from "lib/ample-editor/components/rich-footnote/google-sheets-preview"
import PublicNotePreview from "lib/ample-editor/components/rich-footnote/public-note-preview"
import TwitterPreview from "lib/ample-editor/components/rich-footnote/twitter-preview"
import YoutubePreview from "lib/ample-editor/components/rich-footnote/youtube-preview"
import {
  googleSheetsParamsFromURL,
  publicNoteParamsFromURL,
  twitterTweetIdFromURL,
  youtubeStartTimeFromURL,
  youtubeVideoIdFromURL,
} from "lib/ample-editor/lib/link-util"

// --------------------------------------------------------------------------
export function hrefPreviewCanHandle(url) {
  return googleSheetsParamsFromURL(url) ||
    publicNoteParamsFromURL(url) ||
    twitterTweetIdFromURL(url) ||
    youtubeVideoIdFromURL(url);
}

// --------------------------------------------------------------------------
export default function HrefPreview({ href, onLoad }) {
  const googleSheetsParams = googleSheetsParamsFromURL(href);
  if (googleSheetsParams) {
    return (
      <GoogleSheetsPreview
        chartId={ googleSheetsParams.chartId }
        documentId={ googleSheetsParams.documentId }
        onLoad={ onLoad }
      />
    );
  }

  const publicNoteParams = publicNoteParamsFromURL(href);
  if (publicNoteParams) {
    return (
      <PublicNotePreview
        section={ publicNoteParams.fragment }
        token={ publicNoteParams.token }
      />
    );
  }

  const twitterTweetId = twitterTweetIdFromURL(href);
  if (twitterTweetId) {
    return <TwitterPreview onLoad={ onLoad } tweetId={ twitterTweetId} />;
  }

  const youtubeVideoId = youtubeVideoIdFromURL(href);
  if (youtubeVideoId) {
    const startTime = youtubeStartTimeFromURL(href);
    return <YoutubePreview onLoad={ onLoad } startTime={ startTime } videoId={ youtubeVideoId } />;
  }

  return null;
}

HrefPreview.propTypes = {
  href: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

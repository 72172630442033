import PropTypes from "prop-types"
import React from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import Tippy from "@tippyjs/react"

import { avatarTextFromPerson, displayNameFromPerson } from "lib/ample-util/person"

import "../styles/person-icon.scss"

// --------------------------------------------------------------------------
export default class PersonIcon extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    person: PropTypes.shape({
      avatar: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }),
    tooltipped: PropTypes.bool,
    tooltipSuffix: PropTypes.string,
  };

  // --------------------------------------------------------------------------
  render() {
    const { className, loading, person, tooltipped, tooltipSuffix } = this.props;

    let innerContent;
    if (loading) {
      innerContent = (<CircularProgress className="spinner" size="xsmall" />);
    } else if (person && person.avatar && person.avatar.startsWith("http")) {
      innerContent = (<img src={ person.avatar } />);
    } else {
      // Need a span wrapper for Tippy content (direct text results in exception)
      innerContent = (
        <span className="person-icon-text">
          { avatarTextFromPerson(person) }
        </span>
      );
    }

    let content;
    if (tooltipped && !loading) {
      const tooltipContent = (
        <span className={ `person-icon-tooltip-content${ person ? "" : " unknown-person" }` }>
          <PersonIcon className={ className } person={ person } />
          <span className="person-icon-tooltip-content-name">
            { displayNameFromPerson(person) }
          </span>
          { tooltipSuffix ? (<span className="person-icon-tooltip-content-suffix">{ tooltipSuffix }</span>) : null }
        </span>
      );

      content = (
        <Tippy content={ tooltipContent } delay={ 150 }>
          { innerContent }
        </Tippy>
      );
    } else {
      content = innerContent;
    }

    return (
      <div className={ `person-icon ${ person ? "" : "unknown-person" } ${ className || "" }` }>
        { content }
      </div>
    );
  }
}

import { dequal } from "dequal/lite"
import PropTypes from "prop-types"
import React from "react"

import ToolbarAttachmentButton from "lib/ample-editor/components/toolbar-attachment-button"
import ToolbarCommandButton from "lib/ample-editor/components/toolbar-command-button"
import ToolbarImageButton from "lib/ample-editor/components/toolbar-image-button"
import { deviceSupportsHover, modKeyName } from "lib/ample-editor/lib/client-info"
import TOOLBAR_COMMAND from "lib/ample-editor/lib/toolbar-command"

// --------------------------------------------------------------------------
const BLOCK_NODE_TYPE = {
  IMAGE: TOOLBAR_COMMAND.TOGGLE_BLOCK_IMAGE,
  VIDEO: TOOLBAR_COMMAND.TOGGLE_BLOCK_VIDEO,
};

// --------------------------------------------------------------------------
const TOOLBAR_BUTTONS = [
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_MARK_STRONG,
    icon: "format_bold",
    tooltip: `Bold - ${ modKeyName }+b`,
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_MARK_EM,
    icon: "format_italic",
    tooltip: `Italic - ${ modKeyName }+i`,
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_MARK_STRIKETHROUGH,
    icon: "format_strikethrough",
    tooltip: "Strikethrough",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_MARK_HIGHLIGHT,
    icon: "brush",
    tooltip: `Highlight - ${ modKeyName }+h`,
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_1,
    icon: "format-header-1",
    tooltip: "Heading 1 - shift+ctrl+1",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_2,
    icon: "format-header-2",
    tooltip: "Heading 2 - shift+ctrl+2",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_3,
    icon: "format-header-3",
    tooltip: "Heading 3 - shift+ctrl+3",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_CHECK_LIST_ITEM,
    hideWhenUnavailable: true,
    icon: "checkbox-marked-outline",
    tooltip: `Task list - ${ modKeyName }+enter`,
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_COLLAPSED,
    hideWhenUnavailable: true,
    icon: "unfold_less",
    tooltip: "Collapse or expand child nodes - ctrl-,",
  },
  {
    commandName: TOOLBAR_COMMAND.LIST_ITEM_INDENT,
    hideWhenInactive: true,
    icon: "format_indent_increase",
    noActiveDisplay: true,
    tooltip: "Indent - tab",
    touchDeviceOnly: true,
  },
  {
    commandName: TOOLBAR_COMMAND.LIST_ITEM_OUTDENT,
    hideWhenInactive: true,
    icon: "format_indent_decrease",
    noActiveDisplay: true,
    tooltip: "Outdent - shift+tab",
    touchDeviceOnly: true,
  },
  {
    commandName: TOOLBAR_COMMAND.LIST_ITEM_REORDER,
    hideWhenUnavailable: true,
    icon: "sort",
    tooltip: `Sort task list<br><br>Related commands:<br>shift+${ modKeyName }+up Sort task up<br>shift+${ modKeyName }+down Sort task down`,
  },
  {
    commandName: TOOLBAR_COMMAND.TABLE_OF_CONTENTS_REFRESH,
    hideWhenUnavailable: true,
    icon: "restore_page",
    noActiveDisplay: true,
    tooltip: "Refresh Table of Contents",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_BULLET_LIST_ITEM,
    icon: "format_list_bulleted",
    tooltip: `Bulleted list - ${ modKeyName }+enter`,
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_NUMBER_LIST_ITEM,
    icon: "format_list_numbered",
    tooltip: "Numbered list",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_TABLE,
    hideWhenUnavailable: true,
    icon: "table-outlined",
    tooltip: "Insert table - tab",
  },
  {
    commandName: TOOLBAR_COMMAND.TABLE_COLUMN_SORT,
    hideWhenUnavailable: true,
    icon: "sort",
    noActiveDisplay: true,
    tooltip: "Toggle sort direction for column",
  },
  {
    commandName: TOOLBAR_COMMAND.INSERT_TABLE_COLUMN_BEFORE,
    hideWhenUnavailable: true,
    icon: "table-column-before-plus",
    noActiveDisplay: true,
    tooltip: "Insert table column",
  },
  {
    commandName: TOOLBAR_COMMAND.INSERT_TABLE_ROW,
    hideWhenUnavailable: true,
    icon: "table-row-before-plus",
    noActiveDisplay: true,
    tooltip: "Insert table row",
  },
  {
    commandName: TOOLBAR_COMMAND.DELETE_TABLE_COLUMN,
    hideWhenUnavailable: true,
    icon: "table-column-remove",
    noActiveDisplay: true,
    tooltip: "Delete table column",
  },
  {
    commandName: TOOLBAR_COMMAND.DELETE_TABLE_ROW,
    hideWhenUnavailable: true,
    icon: "table-row-remove",
    noActiveDisplay: true,
    tooltip: "Delete table row",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_TABLE_COLUMN_LOCKED_WIDTH,
    hideWhenInactive: true,
    icon: "table-lock",
    tooltip: "Unlock the fixed width of this column",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_TABLE_FULL_WIDTH,
    hideWhenUnavailable: true,
    icon: "table-split-cell",
    tooltip: "Toggle full width table",
  },
  {
    commandName: TOOLBAR_COMMAND.CLEAR_MARKS,
    hideWhenInactive: true,
    icon: "format_clear",
    tooltip: `Clear active formatting - ${ modKeyName }+/`,
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_LINK,
    icon: "link",
    tooltip: `Link - ${ modKeyName }+k`,
  },
  {
    Component: AttachmentButton,
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_ATTACHMENT,
  },
  {
    Component: MediaButton,
    commandName: "insert-media",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_BLOCKQUOTE,
    icon: "format_quote",
    tooltip: "Block-quote - ctrl+>",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_MARK_CODE,
    icon: "literal-formatting",
    tooltip: "Literal text - ctrl+`",
  },
  {
    commandName: TOOLBAR_COMMAND.TOGGLE_BLOCK_CODE_BLOCK,
    icon: "code-formatting",
    tooltip: "Code block - shift+ctrl+\\",
  }
];

// --------------------------------------------------------------------------
function AttachmentButton({ availableCommands, insertAttachment }) {
  if (!insertAttachment || !(TOOLBAR_COMMAND.TOGGLE_BLOCK_ATTACHMENT in availableCommands)) return null;

  return (
    <ToolbarAttachmentButton
      insertAttachment={ insertAttachment }
      isActive={ availableCommands[TOOLBAR_COMMAND.TOGGLE_BLOCK_ATTACHMENT] }
    />
  );
}

// --------------------------------------------------------------------------
function MediaButton({ availableCommands, insertMedia }) {
  if (!insertMedia) return null;

  if (!(BLOCK_NODE_TYPE.IMAGE in availableCommands || BLOCK_NODE_TYPE.VIDEO in availableCommands)) {
    return null;
  }

  const isActive = availableCommands[BLOCK_NODE_TYPE.IMAGE] || availableCommands[BLOCK_NODE_TYPE.VIDEO];
  return (<ToolbarImageButton insertMedia={ insertMedia } isActive={ isActive } />);
}

// --------------------------------------------------------------------------
// React component rendered by the ToolbarView
export default class Toolbar extends React.Component {
  static propTypes = {
    availableCommands: PropTypes.object.isRequired,
    executeCommand: PropTypes.func.isRequired,
    insertAttachment: PropTypes.func,
    insertMedia: PropTypes.func,
    readonly: PropTypes.bool,
  };

  // --------------------------------------------------------------------------
  state = {
    expanded: false,
  };

  // --------------------------------------------------------------------------------
  render() {
    const { readonly } = this.props;
    if (readonly) return null;

    const { expanded } = this.state;

    return (
      <div className={ `toolbar${ expanded ? " expanded" : "" }` }>
        <div className="button-group collapsible" onMouseDown={ this._onCollapsibleGroupClick } tabIndex="-1">
          { TOOLBAR_BUTTONS.map(this._renderButton) }
          <i className="expander material-icons">{ expanded ? "expand_less" : "expand_more" }</i>
        </div>

        <div className="button-group last" tabIndex="-1">
          {
            this._renderButton({
              commandName: TOOLBAR_COMMAND.UNDO,
              disableWhenInactive: true,
              icon: "undo",
              tooltip: `Undo - ${ modKeyName }+z`,
            })
          }
          {
            this._renderButton({
              commandName: TOOLBAR_COMMAND.REDO,
              disableWhenInactive: true,
              icon: "redo",
              tooltip: `Redo - shift+${ modKeyName }+z`,
            })
          }
        </div>

        <div className="toolbar-indicator-container" />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  shouldComponentUpdate(nextProps, nextState) {
    return nextState.expanded !== this.state.expanded ||
      nextProps.readonly !== this.props.readonly ||
      !dequal(nextProps.availableCommands, this.props.availableCommands);
  }

  // --------------------------------------------------------------------------
  // We have to detect menu button clicks by process of elimination, since it's _under_ the button group (so it
  // hides when not wrapped). If we get a click through on an uncovered area of the bar, we assume that's on the menu
  // button.
  _onCollapsibleGroupClick = event => {
    if (!event.target || !event.target.className || !event.target.className.includes) return;
    if (!event.target.className.includes("button-group")) return;

    // Prevents mouse click event from stealing focus from editor when user is clicking to expand toolbar icons
    event.preventDefault();

    this.setState({ expanded: !this.state.expanded });
  };

  // --------------------------------------------------------------------------
  _renderButton = buttonConfig => {
    const {
      Component,
      commandName,
      disableWhenInactive,
      hideWhenInactive,
      hideWhenUnavailable,
      noActiveDisplay,
      icon,
      tooltip,
      touchDeviceOnly,
    } = buttonConfig;

    const { availableCommands, executeCommand } = this.props;

    if (Component) {
      const { insertAttachment, insertMedia } = this.props;

      return (
        <Component
          availableCommands={ availableCommands }
          insertAttachment={ insertAttachment }
          key={ commandName }
          insertMedia={ insertMedia }
        />
      );
    }

    if (!(commandName in availableCommands)) return null;

    const active = availableCommands[commandName];
    if (hideWhenUnavailable && (active === null || typeof(active) === "undefined")) return null;
    if (hideWhenInactive && !active) return null;

    if (touchDeviceOnly && deviceSupportsHover) return null;

    return (
      <ToolbarCommandButton
        active={ noActiveDisplay ? false : !!active }
        commandName={ commandName }
        disabled={ disableWhenInactive ? !active : false }
        executeCommand={ executeCommand }
        key={ commandName }
        icon={ icon }
        tooltip={ tooltip }
      />
    );
  };
}

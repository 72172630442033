import { defer } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Select } from "@rmwc/select"
import Tippy from "@tippyjs/react"

import { EMPTY_RELATIVE_REPEAT, EMPTY_RRULE, REPEAT_TYPE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const REPEAT_TYPE_OPTIONS_BY_LIST_ITEM_TYPE = {
  bullet_list_item: [
    {
      label: "Doesn't repeat",
      value: REPEAT_TYPE.NONE,
    },
    {
      label: "On a fixed schedule",
      value: REPEAT_TYPE.FIXED,
    },
  ],
  check_list_item: [
    {
      label: "Doesn't repeat",
      value: REPEAT_TYPE.NONE,
    },
    {
      label: "On a fixed schedule",
      value: REPEAT_TYPE.FIXED,
    },
    {
      label: "When task is complete",
      value: REPEAT_TYPE.RELATIVE,
    },
  ],
};

const TOOLTIP_DELAY = [ 1000, 150 ];

// --------------------------------------------------------------------------
export default function RepeatControls({ close, listItemType, onAttrChange, onFocus, readonly, repeatType }) {
  const onChange = useCallback(
    event => {
      let changes = null;

      switch (event.target.value) {
        case REPEAT_TYPE.NONE:
          changes = { repeat: null };
          break;

        case REPEAT_TYPE.FIXED:
          changes = { repeat: EMPTY_RRULE };
          break;

        case REPEAT_TYPE.RELATIVE:
          changes = { repeat: EMPTY_RELATIVE_REPEAT };
          break;

        default:
          break;
      }

      if (changes !== null) onAttrChange(changes);
    },
    [ onAttrChange ]
  );

  const onKeyDown = useCallback(
    event => {
      if (event.key === "Tab" && event.shiftKey && close) {
        event.preventDefault();
        event.stopPropagation();

        // Need to wait until the RMWC wrapper has had a chance to finish handling
        // this callback, as this will unmount the component immediately, resulting
        // in an exception in RMWC
        defer(close);
      }
    },
    [ close ]
  );

  let tooltip;
  if (listItemType === "bullet_list_item") {
    tooltip = "Use repeating events to capture periodic events on a fixed schedule or with a regular interval";
  } else {
    tooltip = "Use repeating tasks to capture periodic tasks that you perform on a fixed schedule or with a fixed interval";
  }

  return (
    <div className="control repeat">
      <Tippy arrow={ false } content={ tooltip } delay={ TOOLTIP_DELAY }>
        <div className="control-label">
          <span className="material-icons">cached</span><span className="text">Repeat</span>
        </div>
      </Tippy>

      <div className="repeat-input">
        <Select
          className="repeat-input-container"
          disabled={ readonly }
          enhanced
          onChange={ onChange }
          onFocus={ onFocus }
          onKeyDown={ onKeyDown }
          options={ REPEAT_TYPE_OPTIONS_BY_LIST_ITEM_TYPE[listItemType] }
          outlined
          value={ repeatType }
        />
      </div>
    </div>
  );
}

RepeatControls.propTypes = {
  close: PropTypes.func,
  listItemType: PropTypes.oneOf([ "bullet_list_item", "check_list_item" ]).isRequired,
  onAttrChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  readonly: PropTypes.bool,
  repeatType: PropTypes.oneOf(Object.values(REPEAT_TYPE)),
};

import { clamp, debounce } from "lodash"
import React from "react"
import ReactDOM from "react-dom"

import SelectNoteMenu from "lib/ample-editor/components/select-note-menu"
import SelectionMenu from "lib/ample-editor/components/selection-menu"
import { hasModifierKey } from "lib/ample-editor/lib/event-util"
import extractToLinkedNote from "lib/ample-editor/lib/extract-to-linked-note"
import { positionPopupContainer } from "lib/ample-editor/lib/popup-util"
import { executeToolbarCommand } from "lib/ample-editor/plugins/toolbar-plugin"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"

// --------------------------------------------------------------------------
const POPUP_BOTTOM_MARGIN = 8; // px
const POPUP_HORIZONTAL_MARGIN = 8; // px
const POPUP_HORIZONTAL_OFFSET = -80; // px

// --------------------------------------------------------------------------
const MAX_SELECT_NOTE_MENU_WIDTH = 400;
const SUB_MENU_RESERVED_HEIGHT = 250; // Examples of how it was chosen at https://public.amplenote.com/5JVSWCbWRZuNSN7A6cXKkqUs

// --------------------------------------------------------------------------
export default class SelectionMenuPluginView {
  _editorView = null;
  _extractToNoteSelection = null;
  // In some cases we may not be able to get the toolbar state from the editorView we have, though we still want to
  // use it for positioning and dispatching changes.
  _getAvailableToolbarCommands = null;
  _menuContainer = null;
  _replaceSelection = null;
  _shouldShowSelectionMenu = null;
  _suppressAtSelection = null;
  _visible = false;

  // --------------------------------------------------------------------------
  constructor(editorView, getAvailableToolbarCommands, replaceSelection, shouldShowSelectionMenu) {
    this._editorView = editorView;
    this._getAvailableToolbarCommands = getAvailableToolbarCommands;
    this._replaceSelection = replaceSelection;
    this._shouldShowSelectionMenu = shouldShowSelectionMenu;

    this._menuContainer = document.createElement("div");
    this._menuContainer.className = "selection-menu-container";
    if (editorView.dom.parentNode) {
      editorView.dom.parentNode.appendChild(this._menuContainer);
    }

    window.addEventListener("resize", this._onWindowResize);
  }

  // --------------------------------------------------------------------------
  destroy() {
    window.removeEventListener("resize", this._onWindowResize);

    ReactDOM.unmountComponentAtNode(this._menuContainer);
    this._menuContainer.remove();
  }

  // --------------------------------------------------------------------------
  handleKeyDown(editorView, event) {
    if (this._visible && event.key === "Escape" && !hasModifierKey(event)) {
      this._suppressAtSelection = editorView.state.selection;
      this.update(editorView);
      return true;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  update(editorView) {
    if (this._extractToNoteSelection !== null) {
      const { props: { hostApp: { fetchNoteContent, suggestNotes } }, state: { selection } } = this._editorView;

      if (this._extractToNoteSelection.eq(selection)) {
        ReactDOM.render(
          <SelectNoteMenu
            actionDescription="move the selected text"
            actionDescriptionShort="Move"
            apply={ this._extractToNote }
            cancel={ this._cancelExtractToNote }
            fetchNoteContent={ fetchNoteContent }
            suggestNotes={ suggestNotes }
          />,
          this._menuContainer,
          () => this._positionSelectNoteMenuContainer(editorView, this._extractToNoteSelection.from)
        );

        return;
      } else {
        this._extractToNoteSelection = null;
      }
    }

    if (!this._shouldShow(editorView)) {
      if (this._visible) {
        try {
          ReactDOM.unmountComponentAtNode(this._menuContainer);
        } catch (_error) {
          // `NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`
          // It's unclear why this happens, but seems to just be an indication that the node's parent has already been
          // removed from the dom, in which case we'd rather just ignore the error and move on.
          // See https://github.com/facebook/react/issues/24720
        }
        this._visible = false;
      }

      return;
    }

    this._visible = true;

    const { state: { doc, selection: { from, to } } } = editorView;

    const selectedText = doc.textBetween(from, to, "\n", " ");

    ReactDOM.render(
      <SelectionMenu
        availableCommands={ this._getAvailableToolbarCommands() }
        editorView={ editorView }
        executeCommand={ this._executeToolbarCommand }
        getReplaceTextPluginActions={ this._getReplaceTextPluginActions }
        selectedText={ selectedText }
        selectExtractToNoteTarget={ this._selectExtractToNoteTarget }
      />,
      this._menuContainer,
      () => this._positionSelectionMenuContainer(editorView, from, to)
    );
  }

  // --------------------------------------------------------------------------
  _cancelExtractToNote = () => {
    this._extractToNoteSelection = null;
    this._editorView.focus();
    this.update(this._editorView);
  };

  // --------------------------------------------------------------------------
  _executeToolbarCommand = commandName => {
    return executeToolbarCommand(this._editorView, commandName);
  };

  // --------------------------------------------------------------------------
  _extractToNote = async ({ name, suggestionTags, url }) => {
    this._extractToNoteSelection = null;

    await extractToLinkedNote(this._editorView, name, url, suggestionTags);

    this._editorView.focus();
  };

  // --------------------------------------------------------------------------
  _getReplaceTextPluginActions = async selectedText => {
    const { props: { hostApp: { getPluginActions } } } = this._editorView;
    if (!getPluginActions) return null;

    const pluginActionPromises = await getPluginActions(
      [ PLUGIN_ACTION_TYPE.REPLACE_TEXT ],
      buildEditorContext(this._editorView),
      [ selectedText ]
    );
    if (!pluginActionPromises) return null;

    return pluginActionPromises.map(pluginActionPromise => pluginActionPromise.then(pluginAction => {
      if (!pluginAction) return null;

      const { checkResult, icon, name, run } = pluginAction;
      return {
        checkResult,
        icon,
        name,
        replaceText: () => this._replaceSelection(this._editorView, run),
      };
    }));
  };

  // --------------------------------------------------------------------------
  _onWindowResize = debounce(() => {
    if (this._visible && this._extractToNoteSelection === null) {
      const { state: { selection: { from, to } } } = this._editorView;
      this._positionSelectionMenuContainer(this._editorView, from, to);
    }
  }, 150);

  // --------------------------------------------------------------------------
  _positionSelectionMenuContainer = (editorView, from, to) => {
    const container = this._menuContainer;

    // This is only expected in tests, where the DOM is simulated
    if (!container.offsetParent) return;

    const offsetParentBounds = container.offsetParent.getBoundingClientRect();

    const popupBounds = container.getBoundingClientRect();

    const fromCoords = editorView.coordsAtPos(from);

    // A bit hacky to check the class, but in the new-task-input editor we need to open all popups above the bar, even
    // though it seems like there isn't space (because the editor is small, but allows overflow), and it would require
    // a lot of layers of passing a prop through just to control this specific behavior
    const isNewTaskInput = container.offsetParent.classList.contains("new-task-input-ample-editor");

    let top = fromCoords.top - offsetParentBounds.top - popupBounds.height - POPUP_BOTTOM_MARGIN;
    if (top < SUB_MENU_RESERVED_HEIGHT && !isNewTaskInput) {
      // Not enough room above selection - it's possible the menu will be clipped if rendering above, so we'll
      // render below the last line of the selection instead
      const toCoords = editorView.coordsAtPos(to);
      top = toCoords.bottom - offsetParentBounds.top + POPUP_BOTTOM_MARGIN;
      container.classList.add("pop-below");
    } else {
      container.classList.remove("pop-below");
    }

    const left = clamp(
      fromCoords.left - offsetParentBounds.left + POPUP_HORIZONTAL_OFFSET,
      POPUP_HORIZONTAL_MARGIN,
      offsetParentBounds.width - POPUP_HORIZONTAL_MARGIN - popupBounds.width
    );

    container.style.left = `${ Math.floor(left) }px`;
    container.style.top = `${ top }px`;
    container.style.width = null;
  };

  // --------------------------------------------------------------------------
  _positionSelectNoteMenuContainer = (editorView, from) => {
    positionPopupContainer(editorView, this._menuContainer, MAX_SELECT_NOTE_MENU_WIDTH, from);
  };

  // --------------------------------------------------------------------------
  _selectExtractToNoteTarget = () => {
    const { state: { selection } } = this._editorView;
    this._extractToNoteSelection = selection;
    this.update(this._editorView);
  };

  // --------------------------------------------------------------------------
  _shouldShow = editorView => {
    if (this._suppressAtSelection) {
      if (editorView.state.selection.eq(this._suppressAtSelection)) {
        return false;
      } else {
        this._suppressAtSelection = null;
      }
    }

    return this._shouldShowSelectionMenu(editorView);
  };
}

// --------------------------------------------------------------------------
function findCheckListItemInternal(node, nodePos, uuid, matchBulletListItems) {
  let pos = nodePos;

  for (let i = 0; i < node.childCount; i++) {
    const childNode = node.child(i);

    if (childNode.type.name === "bullet_list_item") {
      if (matchBulletListItems && childNode.attrs.uuid === uuid) return { node: childNode, nodePos: pos };
    } else if (childNode.type.name === "check_list_item") {
      if (childNode.attrs.uuid === uuid) return { node: childNode, nodePos: pos };
    } else if (childNode.type.name === "tasks_group") {
      // Only valid in tasksSchema documents (i.e. in the TasksEditor)
      const result = findCheckListItemInternal(childNode, pos + 1, uuid, matchBulletListItems);
      if (result) return result;
    }

    pos += childNode.nodeSize;
  }

  return null;
}

// --------------------------------------------------------------------------
// Returns `{ node, nodePos }` if the check list item is found, `null` otherwise. Note that `nodePos` is the position
// _before_ the check list item's opening position, so it can be passed to `doc.nodeAt` to get the check-list-item node
export default function findCheckListItem(doc, uuid, { matchBulletListItems = false } = {}) {
  return findCheckListItemInternal(doc, 0, uuid, matchBulletListItems);
}

import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import DayPicker from "react-day-picker"

import HideForDaysMenu from "lib/ample-editor/components/list-item-commands-menu/hide-for-days-menu"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import { getWeekStartsOn } from "lib/ample-util/date"
import dateSuggestionsFromDate from "lib/ample-util/date-suggestions-from-date"
import { DATE_TYPE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const MODE = {
  NORMAL: "normal",
  DAY_SELECT: "date-select",
  NUMBER_OF_DAYS_SELECT: "number-of-days-select",
};

// --------------------------------------------------------------------------
export default class DateAdjustPopup extends React.PureComponent {
  static propTypes = {
    close: PropTypes.func.isRequired,
    date: PropTypes.any,
    dateType: PropTypes.oneOf(Object.values(DATE_TYPE)).isRequired,
    clearDate: PropTypes.func,
    dismissTask: PropTypes.func,
    dueDate: PropTypes.any,
    extraProps: PropTypes.object,
    onDateChange: PropTypes.func.isRequired,
    // If provided, will be called instead of showing the day picker when the option is selected
    selectDay: PropTypes.func,
    showNumberOfDaysOption: PropTypes.bool,
  };

  // --------------------------------------------------------------------------
  state = {
    mode: MODE.NORMAL,
  };

  // --------------------------------------------------------------------------
  render() {
    const { date, dateType } = this.props;

    const extraProps = this.props.extraProps || {};

    let headingText;
    if (dateType === DATE_TYPE.DUE) {
      headingText = date ? "Reschedule for" : "Start at";
    } else {
      headingText = "Hide until";
    }

    return (
      <div
        className="task-adjust-popup"
        key="date-adjust-popup"
        // Firefox makes each option draggable if user-select: none is applied, so we have to stop that
        onDragStart={ preventEventDefault }
        { ...extraProps }
      >
        <div className="heading">{ headingText }</div>
        <div className="options">
          { this._renderOptions() }
          { this._renderLastOption() }
        </div>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _onClearDateClick = event => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    this.props.clearDate();
  };

  // --------------------------------------------------------------------------
  _onDayPickerDayClick = (date, modifiers) => {
    if (!modifiers.disabled) this.props.onDateChange(date);
  }

  // --------------------------------------------------------------------------
  _onDismissClick = event => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    this.props.dismissTask();
  };

  // --------------------------------------------------------------------------
  _onDateOptionClick = (date, event) => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    this.props.onDateChange(date);
  };

  // --------------------------------------------------------------------------
  _onSelectDayClick = event => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    const { selectDay } = this.props;
    if (selectDay) {
      selectDay();
      return;
    }

    this.setState({ mode: MODE.DAY_SELECT });
  };

  // --------------------------------------------------------------------------
  _onSelectNumberOfDaysClick = event => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    this.setState({ mode: MODE.NUMBER_OF_DAYS_SELECT });
  };

  // --------------------------------------------------------------------------
  _renderLastOption() {
    const { clearDate, dismissTask } = this.props;
    if (dismissTask) {
      return (
        <React.Fragment>
          <div className="divider"/>
          <div className="option left-aligned" onClick={ this._onDismissClick }>
            <i className="material-icons">delete</i>
            Dismiss task
          </div>
        </React.Fragment>
      );
    } else if (clearDate) {
      if (!this.props.date) return null;

      return (
        <React.Fragment>
          <div className="divider"/>
          <div className="option left-aligned" onClick={ this._onClearDateClick }>
            <i className="material-icons">clear</i>
            Remove start time
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  // --------------------------------------------------------------------------
  _renderOption = ({ date, name }) => {
    const { dateType } = this.props;
    const dateFormatSpecifier = dateType === DATE_TYPE.DUE ? "iii, LLL d" : "iii, LLL d 'at' haaa";

    return (
      <div className="option" key={ name } onClick={ this._onDateOptionClick.bind(this, date) }>
        { name }<span className="exact-date">{ format(date, dateFormatSpecifier) }</span>
      </div>
    );
  };

  // --------------------------------------------------------------------------
  _renderOptions() {
    const { date } = this.props;
    const { mode } = this.state;

    if (mode === MODE.DAY_SELECT) {
      return (
        <DayPicker
          enableOutsideDaysClick
          firstDayOfWeek={ getWeekStartsOn() }
          onDayClick={ this._onDayPickerDayClick }
          selectedDays={ date }
          showOutsideDays
        />
      );
    } else if (mode === MODE.NUMBER_OF_DAYS_SELECT) {
      const { close } = this.props;

      return (
        <HideForDaysMenu
          buttonText="Hide"
          cancel={ close }
          setStartAt={ this._setDateValue }
          startAt={ date ? Math.floor(date.getTime() / 1000) : null }
        />
      );
    } else {
      const { dateType, dueDate, showNumberOfDaysOption } = this.props;

      return (
        <React.Fragment>
          { dateSuggestionsFromDate(dateType, date, dueDate).map(this._renderOption) }

          <div className="option left-aligned" onClick={ this._onSelectDayClick }>
            Choose date...
          </div>

          {
            showNumberOfDaysOption
              ? (
                <div className="option left-aligned" onClick={ this._onSelectNumberOfDaysClick }>
                  Choose number of days...
                </div>
              )
              : null
          }
        </React.Fragment>
      );
    }
  }

  // --------------------------------------------------------------------------
  _setDateValue = value => {
    const date = value ? new Date(value * 1000) : null;
    this.props.onDateChange(date);
  };
}

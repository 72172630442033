// --------------------------------------------------------------------------
// Utilities for working with various color-based/related concepts
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
const HEX_COLOR_PATTERN = /^\s*#?([A-F0-9]{2})([A-F0-9]{2})([A-F0-9]{2})([A-F0-9]{2})?\s*$/i;
const SHORTHAND_HEX_COLOR_PATTERN = /^\s*#?([A-F0-9])([A-F0-9])([A-F0-9])([A-F0-9])?\s*$/i;

// --------------------------------------------------------------------------
const DARK_TEXT_COLOR = "#000";
const LIGHT_TEXT_COLOR = "#fff";
// WCAG defines 7 as the threshold for extremely legible text, so if a contrast ratio is at or above this, we will
// not attempt to make it more legible. Might reduce it to 6 if the brightness tie-breaking algo from
// textColorFromBackgroundColor give imperfect results sometimes?
const AAA_CONTRAST_RATIO = 7;

// --------------------------------------------------------------------------
export function blendColors(colorA, opacityA, colorB) {
  const normalizedColorA = normalizeHexColor(colorA);
  if (!normalizedColorA) return null;

  const [ redA, greenA, blueA ] = normalizedColorA.match(HEX_COLOR_PATTERN).slice(1, 4).map(hex => {
    return parseInt(hex, 16) / 255;
  });

  const normalizedColorB = normalizeHexColor(colorB);
  if (!normalizedColorB) return null;

  opacityA = Math.max(0, Math.min(opacityA, 1));

  const [ redB, greenB, blueB ] = normalizedColorB.match(HEX_COLOR_PATTERN).slice(1, 4).map(hex => {
    return parseInt(hex, 16) / 255;
  });

  const blendedHexValues = [
    redA * opacityA + redB * (1 - opacityA),
    greenA * opacityA + greenB * (1 - opacityA),
    blueA * opacityA + blueB * (1 - opacityA),
  ].map(scalar => Math.round(scalar * 255).toString(16).padStart(2, "0"));

  return `#${ blendedHexValues.join("") }`;
}

// --------------------------------------------------------------------------
// Defined by https://www.w3.org/TR/WCAG20/#contrast-ratiodef
export function contrastRatioFromColors(colorA, colorB) {
  const luminanceA = luminanceFromHexColor(colorA);
  const luminanceB = luminanceFromHexColor(colorB);
  if (luminanceA === null || luminanceB === null) return null;

  if (luminanceA > luminanceB) {
    return (luminanceA + 0.05) / (luminanceB + 0.05);
  } else {
    return (luminanceB + 0.05) / (luminanceA + 0.05);
  }
}

// --------------------------------------------------------------------------
// Returns two character hex opacity value from 0.0-1.0 range numeric opacity
export function hexFromOpacity(opacity) {
  return Math.max(0, Math.min(Math.round(opacity * 255), 255)).toString(16).padStart(2, "0");
}

// --------------------------------------------------------------------------
// Defined by https://www.w3.org/TR/WCAG20/#relativeluminancedef
export function luminanceFromHexColor(hexColor) {
  const match = hexColor.match(HEX_COLOR_PATTERN) || hexColor.match(SHORTHAND_HEX_COLOR_PATTERN);
  if (!match) return null;

  const [ redFactor, greenFactor, blueFactor ] = match.slice(1, 4).map(hex => {
    if (hex.length === 1) hex = hex + hex; // Expanding shorthand color components

    const colorComponent = parseInt(hex, 16) / 255;
    return colorComponent <= 0.03928 ? colorComponent / 12.92 : ((colorComponent + 0.055) / 1.055) ** 2.4;
  });

  return redFactor * 0.2126 + greenFactor * 0.7152 + blueFactor * 0.0722;
}

// --------------------------------------------------------------------------
// Returns non-shorthand hex colors without alpha channel values (#RRGGBB), returning consistent casing - or `null`
// if the input isn't a valid hex color
export function normalizeHexColor(hexColor) {
  if (hexColor) {
    const match = hexColor.match(HEX_COLOR_PATTERN)
    if (match) {
      return ("#" + match[1] + match[2] + match[3]).toUpperCase();
    }

    // We don't want to normalize #RGBA shorthand colors, only RGB colors
    const shorthandMatch = hexColor.length === 4 ? hexColor.match(SHORTHAND_HEX_COLOR_PATTERN) : null;
    if (shorthandMatch) {
      return (
        "#" +
        shorthandMatch[1] + shorthandMatch[1] +
        shorthandMatch[2] + shorthandMatch[2] +
        shorthandMatch[3] + shorthandMatch[3]
      ).toUpperCase();
    }
  }

  return null;
}

// --------------------------------------------------------------------------
// `backgroundHexColor` a 6-character hex color string with a preceding "#"
export function textColorFromBackgroundColor(
  backgroundHexColor,
  darkTextHexColor = DARK_TEXT_COLOR,
  lightTextHexColor = LIGHT_TEXT_COLOR
) {
  let darkTextContrastRatio = contrastRatioFromColors(backgroundHexColor, darkTextHexColor);
  let lightTextContrastRatio = contrastRatioFromColors(backgroundHexColor, lightTextHexColor);

  if (darkTextContrastRatio < AAA_CONTRAST_RATIO && lightTextContrastRatio < AAA_CONTRAST_RATIO) {
    // Empirical testing suggests that the perceived brightness of a color as calculated by
    // https://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
    // (sample values: https://www.nbdtech.com/images/blog/20080427/AllColors.png) best approximates a good
    // pick for results that aren't above the AAA contrast ratio. Blog says cutoff range can vary from 128-145 per taste
    const brightnessCutoff = 138; // Background with higher brightness gets dark text
    const red = parseInt(backgroundHexColor.substring(1, 3), 16);
    const green = parseInt(backgroundHexColor.substring(3, 5), 16);
    const blue = parseInt(backgroundHexColor.substring(5, 7), 16);
    const perceivedBrightness = Math.sqrt(Math.pow(red, 2) * 0.241 + Math.pow(green, 2) * 0.691 + Math.pow(blue, 2) * 0.068);
    darkTextContrastRatio = perceivedBrightness >= brightnessCutoff ? 1 : 0;
    lightTextContrastRatio = perceivedBrightness >= brightnessCutoff ? 0 : 1;
  }

  return darkTextContrastRatio > lightTextContrastRatio ? darkTextHexColor : lightTextHexColor;
}

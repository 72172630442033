import { NodeSelection, Selection } from "prosemirror-state"

// --------------------------------------------------------------------------
export function expandedSelectionEligible(schema) {
  return !schema.nodes.tasks_group;
}

// --------------------------------------------------------------------------
// Invoked by ExpandingListItemSelection.create() to define the full range covered by a list item
// `$start` is expected to be a ResolvedPos whose `nodeAfter` is the bullet being fully selected
function expandListItemSelectionForward($start) {
  const node = $start.nodeAfter;
  if (!node || !node.type.spec.isListItem) return null;

  const startIndent = node.attrs.indent || 0;

  let end = $start.pos + node.nodeSize;
  let expanded = false;

  for (let childIndex = $start.index() + 1; childIndex < $start.parent.childCount; childIndex++) {
    const nextNode = $start.parent.maybeChild(childIndex);
    if (!nextNode || !nextNode.type.spec.isListItem) break;

    const nextIndent = nextNode.attrs.indent || 0;
    if (nextIndent <= startIndent) break;

    end += nextNode.nodeSize;
    expanded = true;
  }

  if (!expanded) return null;

  const doc = $start.node(0);
  return doc.resolve(end);
}

// --------------------------------------------------------------------------
// Note this works a bit differently from expanding forward, as it will expand
// through multiple siblings at the same indent level if there is a preceding
// sibling at a lower indent level, e.g.:
//
// - parent at level `indent` <- return value will be at the start of this node
//    - first child
//    - second child <- $end is at start of this node
export function expandListItemSelectionBackward($end, indent = 0) {
  const node = $end.nodeAfter;
  if (!node || !node.type.spec.isListItem) return null;

  let expanded = false;
  let nextIndent = node.attrs.indent || 0;
  let start = $end.pos;
  let pendingStart = start;

  for (let childIndex = $end.index() - 1; childIndex > -1; childIndex--) {
    if (nextIndent <= indent) break;

    const previousNode = $end.parent.maybeChild(childIndex);
    if (!previousNode || !previousNode.type.spec.isListItem) break;

    const previousIndent = previousNode.attrs.indent || 0;
    pendingStart -= previousNode.nodeSize;

    if (previousIndent < nextIndent) {
      expanded = true;
      start = pendingStart;
    }

    nextIndent = previousIndent;
  }

  if (!expanded) return null;

  const doc = $end.node(0);
  return { $start: doc.resolve(start), $end: doc.resolve($end.pos + node.nodeSize) };
}

// --------------------------------------------------------------------------
// Returns a range starting at the end of collapseFromNode's content (= the pos before the
// subsequent node), through to the end of all nodes that are children of `collapseFromNode`.
// `parentNode`: Node whose inner text will be bypassed in providing a range through its children
// `parentNodePos`: An index within doc that will resolve to the node whose children should be hidden
export function nodeChildrenRange(doc, parentNode, parentNodePos) {
  const listSelection = ExpandingListItemSelection.create(doc.resolve(parentNodePos));
  const from = listSelection.from + parentNode.nodeSize; // The collapsed range is everything _after_ the node that was collapsed or expanded
  return { from, to: listSelection.to };
}

// --------------------------------------------------------------------------
// Expands the selection to include any list items nested under the list item
// at the given position
export default class ExpandingListItemSelection extends Selection {
  // --------------------------------------------------------------------------
  // Falls back to default prosemirror node selection behavior if we can't expand
  // a list item selection
  static create($start) {
    const $end = expandListItemSelectionForward($start);
    if ($end) {
      return new ExpandingListItemSelection($start, $end);
    } else if ($start.nodeAfter) {
      // NodeSelection.create requires nodeAfter to be non-null
      return NodeSelection.create($start.node(0), $start.pos);
    } else {
      return Selection.near($start);
    }
  }

  // --------------------------------------------------------------------------
  // Note that while the given position should be _before_ the node in question (matching the way `create` is called),
  // the resulting $to will be expanded to include the node in question
  // `indent` the lowest-level indent to traverse down to. Default is 0
  static createFromEnd($pos, indent = 0) {
    const { $start, $end } = expandListItemSelectionBackward($pos, indent) || {};
    if ($start && $end) {
      return new ExpandingListItemSelection($start, $end);
    } else if ($pos.nodeAfter) {
      // NodeSelection.create requires nodeAfter to be non-null
      return NodeSelection.create($pos.node(0), $pos.pos);
    } else {
      return Selection.near($pos);
    }
  }

  // --------------------------------------------------------------------------
  eq(other) {
    return other instanceof ExpandingListItemSelection && other.anchor === this.anchor && other.head === this.head;
  }

  // --------------------------------------------------------------------------
  map(doc, mapping) {
    const { deleted, pos } = mapping.mapResult(this.anchor);
    const $pos = doc.resolve(pos);
    return deleted ? Selection.near($pos) : ExpandingListItemSelection.create($pos);
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return { type: "expanding-list-item", anchor: this.anchor, head: this.head };
  }
}

import { Plugin, PluginKey } from "prosemirror-state"
import CompletedCheckListItemPluginView from "lib/ample-editor/views/completed-check-list-items-plugin-view"

// --------------------------------------------------------------------------
const completedCheckListItemsPluginKey = new PluginKey("completed-check-list-items");

// --------------------------------------------------------------------------
export const animateCompletedTask = (transaction, checkListNode, nodePos, originalNodePos) => {
  const meta = transaction.getMeta(completedCheckListItemsPluginKey) || [];
  return transaction.setMeta(completedCheckListItemsPluginKey, meta.concat({ checkListAttrs: checkListNode.attrs, nodePos, originalNodePos }));
};

// --------------------------------------------------------------------------
const createCompletedCheckListItemsPlugin = () => {
  let pluginView = null;

  const plugin = new Plugin({
    // --------------------------------------------------------------------------
    appendTransaction: transactions => {
      transactions.forEach(transaction => {
        if (pluginView) pluginView.applyTransaction(transaction);
      });
    },

    // --------------------------------------------------------------------------
    key: completedCheckListItemsPluginKey,

    // --------------------------------------------------------------------------
    props: {
      decorations() {
        return pluginView ? pluginView.getDecorations() : null;
      },
    },

    // --------------------------------------------------------------------------
    view: editorView => {
      pluginView = new CompletedCheckListItemPluginView(editorView, completedCheckListItemsPluginKey);
      return pluginView;
    },
  });

  // --------------------------------------------------------------------------
  plugin.startAnimation = (nodePos, checkListAttrs) => {
    if (pluginView) pluginView.startAnimation(nodePos, checkListAttrs);
  };

  return plugin;
};
export default createCompletedCheckListItemsPlugin;

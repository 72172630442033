import isUrl from "is-url"
import { closeHistory } from "prosemirror-history"
import { NodeRange } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"
import { canSplit, findWrapping } from "prosemirror-transform"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import {
  setEditFieldName,
  setIsNewLink,
  setNoteLinkMenuOptions,
  setOpenLinkPosition,
} from "lib/ample-editor/plugins/link-plugin"
import ChangeCompletedTaskStep from "lib/ample-editor/steps/change-completed-task-step"
import ChangeHiddenTaskContentStep from "lib/ample-editor/steps/change-hidden-task-content-step"
import SetAttrsStep from "lib/ample-editor/steps/set-attrs-step"
import { isNewNoteURL, isNoteURL, replaceLocalNoteURL } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
const MARKDOWN_LINK_PREFIX = "[";
const MARKDOWN_LINK_DEFAULT_TEXT = "link";
const MARKDOWN_LINK_SUFFIX = "](";
const MARKDOWN_LINK_DEAULT_URL = "https://";

// --------------------------------------------------------------------------
// Performs triple duty of:
//  1. If the selection $head is in a link node, unwraps the link node (removing the link)
//  2. If the selection $head is not in a link node:
//    2.1. If the selection is collapsed, inserts a new link node
//    2.2. If the selection is expanded, converts the selection to a link node (if possible)
// Additionally, if the schema doesn't support links with Rich Footnotes (i.e. in a description-schema), will "make"
// links by inserting the correct markdown that will allow the user to type in a URL.
export const buildToggleLink = ({ createMarkdownLinks = false, editFieldName = null } = {}) => (state, dispatch) => {
  if (removeLink(state, dispatch)) {
    return true;
  }

  // Create a new link

  const { schema, selection: { empty, $head } } = state;

  let transform = null;

  if (empty) {
    // We'll attempt to expand out from the cursor to encompass any word the cursor might be in

    let $end = $head;
    if ($head.pos < $head.end()) {
      const textAfter = state.doc.textBetween($head.pos, $head.end(), "", " ");
      const matchAfter = textAfter.match(/^\w+?\b/);
      if (matchAfter) $end = state.doc.resolve($head.pos + matchAfter[0].length);
    }

    let $start = $head;
    if ($head.pos > $head.start()) {
      const textBefore = state.doc.textBetween($head.start(), $head.pos, "", " ");
      const matchBefore = textBefore.match(/\b\w+?$/);
      if (matchBefore) $start = state.doc.resolve($head.pos - matchBefore[0].length);
    }

    if ($end.pos !== $start.pos) {
      const range = new NodeRange($start, $end, $head.depth);
      const wrapping = findWrapping(range, schema.nodes.link);
      if (wrapping) {
        if (createMarkdownLinks) {
          transform = state.tr
            .insert($start.pos, schema.text(MARKDOWN_LINK_PREFIX))
            .insert($end.pos + 1, schema.text(MARKDOWN_LINK_SUFFIX + MARKDOWN_LINK_DEAULT_URL));

          transform.setSelection(TextSelection.create(
            transform.doc,
            // Select the inserted default URL, so it can be typed over easily
            $end.pos + 1 + MARKDOWN_LINK_SUFFIX.length,
            $end.pos + 1 + MARKDOWN_LINK_SUFFIX.length + MARKDOWN_LINK_DEAULT_URL.length
          ));
        } else {
          transform = state.tr.wrap(range, wrapping);
        }
      }
    }

    if (transform === null) {
      transform = state.tr;

      if (createMarkdownLinks) {
        transform = state.tr.insert($head.pos, schema.text(
          MARKDOWN_LINK_PREFIX + MARKDOWN_LINK_DEFAULT_TEXT + MARKDOWN_LINK_SUFFIX + MARKDOWN_LINK_DEAULT_URL
        ));

        transform.setSelection(TextSelection.create(
          transform.doc,
          // Select the inserted default text, so it can be typed over easily
          $head.pos + MARKDOWN_LINK_PREFIX.length,
          $head.pos + MARKDOWN_LINK_PREFIX.length + MARKDOWN_LINK_DEFAULT_TEXT.length
        ));
      } else {
        transform = state.tr.insert($head.pos, schema.nodes.link.create());
      }
    }

    if (!createMarkdownLinks) {
      transform.setSelection(TextSelection.create(transform.doc, $head.pos + 1));
    }
  } else {
    transform = linkSelectionTransform(state, createMarkdownLinks);
  }

  if (transform) {
    if (dispatch) {
      setEditFieldName(transform, editFieldName);
      setIsNewLink(transform, true);
      dispatch(transform);
    }

    return true;
  }

  return false;
};

// --------------------------------------------------------------------------
const canCreateLinkInNode = (schema, node) => {
  return node.type === schema.nodes.heading ||
    node.type === schema.nodes.paragraph ||
    (node.childCount === 1 && node.firstChild.type === schema.nodes.image);
};

// --------------------------------------------------------------------------
const linkNodeDepth = (schema, $pos) => {
  for (let depth = 1; depth <= $pos.depth; depth++) {
    const node = $pos.node(depth);
    if (!node) break;
    if (node.type === schema.nodes.link) return depth;
  }

  return null;
};

// --------------------------------------------------------------------------
export const linkSelectionTransform = (state, createMarkdownLinks, { defaultLinkAttributes = {} } = {}) => {
  const { schema, selection, selection: { $head } } = state;
  const { content, openStart } = selection.content();

  // The Slice returned by selection.content() will contain some text nodes, wrapped in the outer nodes that they
  // would be found in from the top of the document (but lacking any other content of those nodes), while we only
  // want to create a link with that inner text node content.
  let node = content;
  for (let depth = 0; depth < openStart; depth++) {
    if (node.childCount === 0 || node.childCount > 1) return null;
    node = node.firstChild;
  }

  if (!canCreateLinkInNode(schema, node)) return null;

  const transform = state.tr;

  if (createMarkdownLinks) {
    const { $from, $to } = selection;

    const insertLeadingSpace = $from.textOffset > 0 && !$from.node().textBetween(0, $from.textOffset).match(/\s$/);

    const prefixText = `${ insertLeadingSpace ? " " : "" }${ MARKDOWN_LINK_PREFIX }`;
    transform.insert($from.pos, schema.text(prefixText));

    transform.insert($to.pos + prefixText.length, schema.text(MARKDOWN_LINK_SUFFIX + MARKDOWN_LINK_DEAULT_URL));
    transform.setSelection(TextSelection.create(
      transform.doc,
      // Select the inserted default URL, so it can be typed over easily
      $to.pos + prefixText.length + MARKDOWN_LINK_SUFFIX.length,
      $to.pos + prefixText.length + MARKDOWN_LINK_SUFFIX.length + MARKDOWN_LINK_DEAULT_URL.length
    ));
  } else {
    const textContent = node.textContent;
    const linkAttributes = { ...defaultLinkAttributes };
    if (isUrl(textContent)) linkAttributes.href = textContent;
    const linkNode = schema.nodes.link.create(linkAttributes, node.content);

    selection.replaceWith(transform, linkNode);
    transform.setSelection(TextSelection.create(transform.doc, $head.pos + 1));
  }

  return transform;
};

// --------------------------------------------------------------------------
export const editLink = (state, dispatch) => {
  const { schema, selection: { $head } } = state;

  if ($head.parent.type !== schema.nodes.link) return false;

  if (dispatch) {
    const $nodePos = state.doc.resolve($head.start($head.depth) - 1);
    dispatch(setOpenLinkPosition(state.tr, $nodePos.pos, true));
  }

  return true;
};

// --------------------------------------------------------------------------
export const exitLink = (state, dispatch) => {
  const { schema, selection: { $head } } = state;

  if ($head.parent.type !== schema.nodes.link) return false;
  if ($head.parentOffset < $head.parent.content.size) return false;
  if ($head.pos < $head.end($head.depth - 1) - 1) return false;

  if (dispatch) {
    const transform = state.tr;

    transform.setSelection(TextSelection.create(transform.doc, $head.pos + 1));
    transform.insertText(" ");

    dispatch(transform.scrollIntoView());
  }

  return true;
};

// --------------------------------------------------------------------------
export const removeLink = (state, dispatch) => {
  const { schema, selection: { empty, $from, $head, $to } } = state;

  if (empty) {
    const fromLinkNodeDepth = linkNodeDepth(schema, $from);
    if (fromLinkNodeDepth === null) return false;

    // Un-link the entire link the cursor is in (by lifting the content out of it)
    const $start = state.doc.resolve($head.start(fromLinkNodeDepth));
    const $end = state.doc.resolve($head.end(fromLinkNodeDepth));
    const range = new NodeRange($start, $end, fromLinkNodeDepth);
    if (dispatch) dispatch(closeHistory(state.tr.lift(range, fromLinkNodeDepth - 1)));
    return true;
  } else {
    // Selection expanded across nodes, so we'll remove any links that are touched by the selection, including partially
    // unlinking any links where from/to fall in the middle of the link
    const linkNodePositions = [];
    state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
      if (node.type === schema.nodes.link) linkNodePositions.push(pos);
    });
    if (linkNodePositions.length === 0) return false;

    if (dispatch) {
      const transform = state.tr;

      // Operating on links in reverse so we can avoid mapping positions through the transform (for the most part)
      linkNodePositions.reverse().forEach(pos => {
        // We want to lift the content of the link node to the same depth as the link node
        const $pos = transform.doc.resolve(pos + 1);

        let $start = transform.doc.resolve($pos.start($pos.depth));
        let $end = transform.doc.resolve($start.end($pos.depth));

        // Handle unlinking just a portion of the link. First we'll split the link at either end of the selection,
        // then lift the content of the selection
        if ($end.pos > $to.pos) {
          transform.split($to.pos, 1);

          $start = transform.doc.resolve($pos.start($pos.depth));
          $end = transform.doc.resolve($start.end($pos.depth));

          // Don't leave the selection in the link after the split point
          transform.setSelection(TextSelection.create(transform.doc, $from.pos, $end.pos));
        }

        if ($start.pos < $from.pos) {
          transform.split($from.pos, 1);

          $start = transform.doc.resolve(transform.mapping.map($from.pos));
          $end = transform.doc.resolve($start.end($pos.depth));
        }

        const range = new NodeRange($start, $end, $start.depth);
        transform.lift(range, $start.depth - 1);
      });

      dispatch(closeHistory(transform));
    }

    return true;
  }
};

// --------------------------------------------------------------------------
// splitBlock won't work for link nodes, since they are inline, so we need a specific implementation to split the link
// node _and_ split the ancestor block nodes
export const splitLinkNode = (state, dispatch) => {
  const { schema, selection: { $from, $to } } = state;

  if ($from.parent.type !== schema.nodes.link) return false;

  if (dispatch) {
    const tr = state.tr;

    if (state.selection instanceof TextSelection) tr.deleteSelection();

    if ($from.parentOffset === 0) {
      // At the start of the link, we want to split from the positing before the content of the link, so we split the
      // parent and all ancestors, instead of leaving an empty link node behind
      tr.split(tr.mapping.map($from.pos - 1), $from.depth - 1, null);
    } else if ($to.parentOffset === $to.parent.content.size) {
      // At the end of the link, we want to split from the position after the last content in the link, so we split
      // the parent and all ancestors, instead of splitting off a new empty link too.
      tr.split(tr.mapping.map($from.pos + 1), $from.depth - 1, null);
      tr.setSelection(TextSelection.create(tr.doc, tr.mapping.map($from.pos + 1)));
    } else if (canSplit(tr.doc, $from.pos, $from.depth, null)) {
      tr.split(tr.mapping.map($from.pos), $from.depth, null);
    }

    dispatch(tr.scrollIntoView());
  }

  return true;
};

// --------------------------------------------------------------------------
export function buildStartNoteLink({ closingText = "]", insertSourceTags = false, triggerText = "[[" } = {}) {
  return function(state, dispatch) {
    const { schema, selection, selection: { empty, $from, $to } } = state;
    if (empty) return false;

    // Don't make from whitespace
    const text = state.doc.textBetween($from.pos, $to.pos);
    if (text.trim().length === 0) return false;

    // This would probably be better if we un-linked any links in the selection and wrapped it all up in a new note
    // link, but for now we'll just prevent surrounding links with links
    let haveLinkNodes = false;
    state.doc.nodesBetween($from.pos, $to.pos, node => {
      if (node.type === schema.nodes.link) {
        haveLinkNodes = true;
        return false;
      }

      return true;
    });
    if (haveLinkNodes) return false;

    const { content, openStart } = selection.content();

    // The Slice returned by selection.content() will contain some text nodes, wrapped in the outer nodes that they
    // would be found in from the top of the document (but lacking any other content of those nodes), while we only
    // want to create a link with that inner text node content.
    let node = content;
    for (let depth = 0; depth < openStart; depth++) {
      if (node.childCount === 0 || node.childCount > 1) return null;
      node = node.firstChild;
    }

    if (!canCreateLinkInNode(schema, node)) return null;

    if (dispatch) {
      const transaction = state.tr;
      const opening = triggerText + (insertSourceTags ? "" : "/");
      transaction.insertText(opening, $from.pos);
      transaction.insertText(closingText, transaction.mapping.map($to.pos));

      if (insertSourceTags) {
        setNoteLinkMenuOptions(transaction, transaction.mapping.map($from.pos), { insertSourceTags });
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
// Workaround for what appears to be a browser issue when delete is pressed on with the cursor at the first position
// of a link node, where Chrome's default behavior deletes the entire link node. If the link doesn't have a widget icon
// in it, this does not happen.
export const deleteLinkForward = (state, dispatch) => {
  const { schema, selection: { $head } } = state;

  const linkNode = $head.nodeAfter;
  if (!linkNode || linkNode.type !== schema.nodes.link) return false;

  if (dispatch) {
    const transform = state.tr;

    if (linkNode.textContent.length <= 2) {
      // The link only has one character in it: remove it entirely
      transform.delete($head.pos, $head.pos + linkNode.nodeSize);
    } else {
      // Just delete the first character of the link
      const linkStartPos = $head.pos + 1;
      transform.delete(linkStartPos, linkStartPos + 1);
    }

    dispatch(transform.scrollIntoView());
  }

  return true;
};

// --------------------------------------------------------------------------
export const openNoteLink = (state, dispatch) => {
  const { schema, selection: { $head, empty } } = state;

  if (!empty) return false;
  if ($head.parent.type !== schema.nodes.link) return false;

  const { attrs: { href } } = $head.parent;

  if (isNoteURL(href) || isNewNoteURL(href)) {
    if (dispatch) {
      const transaction = state.tr;
      transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);

      // We need the app to handle this, as we can't fall back to standard web navigation here
      transaction.setMeta(TRANSACTION_META_KEY.OPEN_NOTE_LINK, href);

      dispatch(transaction);
    }

    return true;
  }

  return false;
}

// --------------------------------------------------------------------------
// Note that the `content` here is a JSON representation of content (array of nodes)
const contentWithUpdatedNoteURLs = (content, localUUID, remoteUUID) => {
  if (!content) return null;

  let contentUpdated = false;

  const newContent = [];
  for (let childIndex = 0; childIndex < content.length; childIndex++) {
    let childNode = content[childIndex];

    if (childNode.type === "link" && childNode.attrs && childNode.attrs.href) {
      const newURL = replaceLocalNoteURL(childNode.attrs.href, localUUID, remoteUUID);

      if (newURL) {
        childNode = { ...childNode, attrs: { ...childNode.attrs, href: newURL } };
        contentUpdated = true;
      }
    } else if (childNode.content && childNode.content.length > 0) {
      const newChildNodeContent = contentWithUpdatedNoteURLs(childNode.content, localUUID, remoteUUID);
      if (newChildNodeContent) {
        childNode = { ...childNode, content: newChildNodeContent };
        contentUpdated = true;
      }
    }

    newContent.push(childNode);
  }

  return contentUpdated ? newContent : null;
};

// --------------------------------------------------------------------------
export const updateNoteURLs = (localUUID, remoteUUID) => (state, dispatch) => {
  const { doc, schema } = state;

  const linkNodes = [];

  doc.descendants((node, pos) => {
    if (node.type === schema.nodes.link && node.attrs.href) {
      const newURL = replaceLocalNoteURL(node.attrs.href, localUUID, remoteUUID);
      if (newURL) linkNodes.push({ newURL, node, pos });
    }
  });

  const { attrs: { completedTasks, hiddenTasks } } = doc;

  const completedTaskUpdates = [];
  completedTasks.forEach(completedTask => {
    const newContent = contentWithUpdatedNoteURLs(completedTask.p, localUUID, remoteUUID);
    if (newContent) completedTaskUpdates.push({ completedTask, newContent });
  });

  const hiddenTaskUpdates = [];
  hiddenTasks.forEach(hiddenTask => {
    const newContent = contentWithUpdatedNoteURLs(hiddenTask.content, localUUID, remoteUUID);
    if (newContent) hiddenTaskUpdates.push({ hiddenTask, newContent });
  });

  if (completedTaskUpdates.length === 0 && hiddenTaskUpdates.length === 0 && linkNodes.length === 0) return false;

  if (dispatch) {
    const transaction = state.tr;

    transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);

    linkNodes.forEach(({ newURL, node, pos }) => {
      transaction.step(new SetAttrsStep(pos, { ...node.attrs, href: newURL }));
    });

    completedTaskUpdates.forEach(({ completedTask, newContent }) => {
      transaction.step(new ChangeCompletedTaskStep(schema, completedTask, { p: newContent }));
    });

    hiddenTaskUpdates.forEach(({ hiddenTask, newContent }) => {
      const { attrs: { uuid }, content } = hiddenTask;
      transaction.step(new ChangeHiddenTaskContentStep(schema, uuid, content, newContent));
    });

    dispatch(transaction);
  }

  return true;
};

import { keydownHandler } from "prosemirror-keymap"
import { Selection, TextSelection } from "prosemirror-state"
import { CellSelection, isInTable, nextCell, selectedRect, TableMap } from "prosemirror-tables"

import { deleteCellSelection } from "lib/ample-editor/lib/table/table-commands"
import { AXIS, TABLE_DEPTH } from "lib/ample-editor/lib/table/table-constants"
import {
  cellFromPos,
  cellFromTable,
  isRenderFormulaCell,
  rowFromPos,
  setTableRedrawMeta,
  tableFromPos
} from "lib/ample-editor/lib/table/table-util"

const DIRECTION = {
  DOWN: 1,
  LEFT: -1,
  RIGHT: 1,
  UP: -1,
};

// --------------------------------------------------------------------------
// `axis` enum of AXIS
// `dir` enum of DIRECTION
const arrow = (axis, dir) => (state, dispatch, view) => {
  let selection = state.selection;
  if (selection instanceof CellSelection) {
    if (dir === DIRECTION.LEFT) {
      // On left/up, put selection in first position in head cell
      return maybeSetSelection(state, dispatch, Selection.near(selection.$headCell));
    } else {
      // On down/right, put selection in last position in head cell
      const endOfCell = state.doc.resolve(selection.$headCell.pos + selection.$headCell.nodeAfter.nodeSize);
      return maybeSetSelection(state, dispatch, Selection.near(endOfCell, -1));
    }
  }
  const end = atEndOfCell(view, axis, dir);
  if (end === null) {
    return false;
  }
  if (axis === AXIS.HORIZONTAL) {
    selection = Selection.near(state.doc.resolve(selection.head + dir), dir);
    return maybeSetSelection(state, dispatch, selection);
  } else {
    const $cell = state.doc.resolve(end);
    const $next = nextCell($cell, axis, dir);
    let newSelection;
    if ($next) {
      const startOffset = selection.$head.parentOffset;
      const cursorAtEndOfCellText = selection.$head.parent.content.size === startOffset;
      const nextCellSize = $next.nodeAfter.content.size;
      let nextCellPos;
      if (cursorAtEndOfCellText) {
        nextCellPos = $next.pos + nextCellSize;
      } else {
        nextCellPos = startOffset < nextCellSize
          ? $next.pos + startOffset + 2 // 1 for start of cell, 1 for start of paragraph
          : $next.pos + nextCellSize;
      }
      const bias = cursorAtEndOfCellText || startOffset > nextCellSize ? -1 : 1;
      newSelection = Selection.near(state.doc.resolve(nextCellPos), bias);
    } else if (dir === DIRECTION.UP) {
      newSelection = Selection.near(state.doc.resolve($cell.before(-1)), -1);
    } else {
      newSelection = Selection.near(state.doc.resolve($cell.after(-1)), 1);
    }
    return maybeSetSelection(state, dispatch, newSelection);
  }
}

// --------------------------------------------------------------------------
const shiftArrow = (axis, dir) => (state, dispatch, view) => {
  let selection = state.selection;
  let $head;
  let existingCellSelection = false;
  if (!(selection instanceof CellSelection)) {
    const end = atEndOfCell(view, axis, dir);
    if (end === null) {
      return axis === AXIS.VERTICAL && selectAdjacentTable(dir, state, dispatch);
    }
    selection = new CellSelection(state.doc.resolve(end));
  } else {
    existingCellSelection = true;
    $head = nextCell(selection.$headCell, axis, dir);
  }

  if ($head) { // If $head is truthy, we had an existing cell selection & a new cell to move to
    return maybeSetSelection(state, dispatch, new CellSelection(selection.$anchorCell, $head));
  } else {
    if (existingCellSelection) {
      // Even if the selection remains the same, if they used Shift-Arrow we don't want to let this get handled
      // by a later function that will remove the CellSelection
      return true;
    } else {
      return maybeSetSelection(state, dispatch, selection);
    }
  }
};

// --------------------------------------------------------------------------
// When shift-arrow is used above or below a table, this function revises selection to continue _through_
// the whole table, to preserve the divine right to hold shift-arrow to select through a note
const selectAdjacentTable = (dir, state, dispatch) => {
  const { doc, selection: { $anchor, $head } } = state;
  const $base = doc.resolve($head.before(TABLE_DEPTH));
  if (!$base) return false;

  if ((dir === DIRECTION.RIGHT && !$base.nodeAfter) || (dir === DIRECTION.LEFT && !$base.nodeBefore)) return false;
  const nextPos = dir === DIRECTION.RIGHT ? $base.pos + $base.nodeAfter.nodeSize : $base.pos - $base.nodeBefore.nodeSize;
  if (nextPos < 0 || nextPos >= doc.nodeSize) return false;
  const $moveTo = doc.resolve(nextPos);
  if (!$moveTo || !$moveTo.nodeAfter || $moveTo.nodeAfter.type.spec.tableRole !== "table") return false;

  if (dispatch) {
    const tr = state.tr;
    // If moving forward, text selection needs to run _through_ the subsequent table node. If moving back, nextPos already runs through it
    const moveTo = dir === DIRECTION.RIGHT ? $moveTo.pos + $moveTo.nodeAfter.nodeSize : $moveTo.pos;
    const afterMoveSelection = TextSelection.near(doc.resolve(moveTo), dir);
    const afterMovePos = afterMoveSelection.$head.pos;
    const selection = TextSelection.create(doc, $anchor.pos, afterMovePos);
    tr.setSelection(selection);
    dispatch(tr);
  }

  return true;
}

// --------------------------------------------------------------------------
const maybeSetSelection = (state, dispatch, selection) => {
  if (selection.eq(state.selection)) return false;
  if (dispatch) dispatch(state.tr.setSelection(selection).scrollIntoView());
  return true;
}

// --------------------------------------------------------------------------
// If moving in [axis,dir] would leave the current cell, returns the pos to which cursor would move
// If moving in [axis,dir] would remain in cell, returns null (ex: if a parent has multi-line content and cursor is in second line when "up" is pressed)
//
// WBH observes this inherited method isn't 100% accurate in determining when an arrow would leave the cell,
// for example, if cursor is after empty space on the first line in a multi-line cell and user presses up
const atEndOfCell = (view, axis, dir) => {
  if (!(view.state.selection instanceof TextSelection)) return null;
  const { $head } = view.state.selection;
  for (let d = $head.depth - 1; d >= 0; d--) {
    const parent = $head.node(d);

    if (parent.type.spec.tableRole === "cell") {
      const cellPos = $head.before(d);
      if (axis === AXIS.VERTICAL) {
        const headIndex = $head.index(d);
        // We're not leaving cell if there are more children of the cell in the direction we're moving
        if (dir === DIRECTION.UP && headIndex > 0) return null;
        if (dir === DIRECTION.DOWN && headIndex < parent.childCount - 1) return null;
      }
      const dirString = axis === AXIS.VERTICAL
        ? (dir === DIRECTION.DOWN ? "down" : "up")
        : dir === DIRECTION.RIGHT ? "right" : "left";
      return view.endOfTextblock(dirString) ? cellPos : null;
    }
  }
  return null;
}

// --------------------------------------------------------------------------
const moveToRowEdge = (direction, shiftHeld = false) => (state, dispatch) => {
  if (!isInTable(state)) return false;

  const { selection } = state;
  const $cell = cellFromPos(selection.$head);
  if (!$cell) return false;
  if (!isSelectionAtCellEdge($cell, direction, selection)) return false;

  if (dispatch) {
    const $row = rowFromPos($cell);
    const transform = state.tr;
    let rowPos;
    if (direction === DIRECTION.LEFT) {
      rowPos = $row.pos;
    } else {
      rowPos = $row.pos + $row.nodeAfter.nodeSize - 1;
    }

    let newSelection;
    if (shiftHeld) {
      const cellToPos = state.doc.resolve(rowPos + (direction === DIRECTION.LEFT ? 1 : -1));
      const $cellTo = cellFromPos(cellToPos);
      newSelection = new CellSelection($cell, $cellTo);
    } else {
      const bias = direction === DIRECTION.LEFT ? 1 : -1;
      newSelection = TextSelection.near(state.doc.resolve(rowPos), bias);
    }
    transform.setSelection(newSelection);
    dispatch(transform.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const maybeMoveColumnLeft = (state, dispatch) => maybeMoveColumn(-1)(state, dispatch);
export const maybeMoveColumnRight = (state, dispatch) => maybeMoveColumn(1)(state, dispatch);

// --------------------------------------------------------------------------
const maybeMoveColumn = direction => (state, dispatch) => {
  const { doc, selection: { $head, $anchor } } = state;
  const $row = cellFromPos($head);
  if (!$row) return false;
  if ($head.pos !== $anchor.pos) {
    const $anchorCell = cellFromPos($anchor);
    if (!$anchorCell || $anchorCell.pos !== $row.pos) return false;
  }

  const rectangle = selectedRect(state);
  if (direction === 1 && rectangle.right === rectangle.map.width) return false;
  if (direction === -1 && rectangle.left === 0) return false;

  if (dispatch) {
    const transform = state.tr;
    let startPos;
    const $table = tableFromPos($head);
    for (let row = 0; row < rectangle.map.height; row++) {
      let $leftCell, $rightCell;
      if (direction === 1) {
        // WBH faithfully propagates passing of `doc` from original version, tho it seems weird it wouldn't be transform.doc, no time to research at moment
        $leftCell = cellFromTable($table, row, rectangle.left, { doc });
        $rightCell = cellFromTable($table, row, rectangle.left + 1, { doc });
        if (!startPos) startPos = $rightCell.pos;
      } else {
        $leftCell = cellFromTable($table, row, rectangle.left - 1, { doc });
        $rightCell = cellFromTable($table, row, rectangle.left, { doc });
        if (!startPos) startPos = $leftCell.pos;
      }
      const orderedNodes = [ $rightCell.nodeAfter, $leftCell.nodeAfter ];
      transform.replaceWith($leftCell.pos, $rightCell.pos + $rightCell.nodeAfter.nodeSize, orderedNodes);
    }
    transform.setSelection(TextSelection.near(transform.doc.resolve(startPos)))
    setTableRedrawMeta(transform);
    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
// In general we want to leave it to higher-level handlers to decide how to jump across words with the cursor,
// but when it comes to tables that have formulas, it is often possible for the cursor to jump through the cell
// with the formula. This method exists only to prevent that specific case.
const maybeWordwiseMove = (direction, _shiftHeld = false) => (state, dispatch) => {
  if (!isInTable(state)) return false;

  const { selection, selection: { $head } } = state;
  const $cell = cellFromPos($head);
  if (!$cell) return false;
  if (!isSelectionAtCellEdge($cell, direction, selection)) return false;

  const $nextCell = nextCell($cell, AXIS.HORIZONTAL, direction);
  if (!$nextCell) return false;
  if (!isRenderFormulaCell($nextCell)) return false;

  if (dispatch) {
    const transform = state.tr;
    const $nextCellPos = direction === DIRECTION.LEFT ? $nextCell.pos : $nextCell.pos + $nextCell.nodeAfter.nodeSize;
    const bias = direction === DIRECTION.LEFT ? 1 : -1;
    const newSelection = TextSelection.near(state.doc.resolve($nextCellPos), bias);
    transform.setSelection(newSelection);
    dispatch(transform.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
// Is selection at the edge of a cell but not the edge of a row?
const isSelectionAtCellEdge = ($cell, direction, selection) => {
  let atCellEdge, atRowEdge;
  if (direction === DIRECTION.LEFT) {
    atCellEdge = selection.$head.parentOffset === 0;
  } else {
    atCellEdge = selection.$head.parentOffset === selection.$head.parent.content.size;
  }
  if (!atCellEdge) return false; // Let native keyhandler move to edge of cell, or end of line
  const $table = tableFromPos($cell);
  const map = TableMap.get($table.nodeAfter);
  if (direction === DIRECTION.LEFT) {
    atRowEdge = $cell.index() === 0;
  } else {
    atRowEdge = $cell.index() === map.width - 1;
  }
  return !atRowEdge;
}

// --------------------------------------------------------------------------
export const handleKeyDown = keydownHandler({
  ArrowLeft: arrow(AXIS.HORIZONTAL, -1),
  ArrowRight: arrow(AXIS.HORIZONTAL, 1),
  ArrowUp: arrow(AXIS.VERTICAL, -1),
  ArrowDown: arrow(AXIS.VERTICAL, 1),
  Delete: deleteCellSelection,

  "Alt-ArrowLeft": maybeWordwiseMove(DIRECTION.LEFT),
  "Alt-ArrowRight": maybeWordwiseMove(DIRECTION.RIGHT),
  "Cmd-ArrowLeft": moveToRowEdge(DIRECTION.LEFT),
  "Cmd-ArrowRight": moveToRowEdge(DIRECTION.RIGHT),
  "Ctrl-ArrowLeft": maybeWordwiseMove(DIRECTION.LEFT),
  "Ctrl-ArrowRight": maybeWordwiseMove(DIRECTION.RIGHT),
  "End": moveToRowEdge(DIRECTION.RIGHT),
  "Home": moveToRowEdge(DIRECTION.LEFT),
  "Mod-Backspace": deleteCellSelection,
  "Mod-Delete": deleteCellSelection,

  "Shift-Alt-Mod-ArrowLeft": maybeMoveColumnLeft,
  "Shift-Alt-Mod-ArrowRight": maybeMoveColumnRight,
  "Shift-ArrowLeft": shiftArrow(AXIS.HORIZONTAL, -1),
  "Shift-ArrowRight": shiftArrow(AXIS.HORIZONTAL, 1),
  "Shift-ArrowUp": shiftArrow(AXIS.VERTICAL, -1),
  "Shift-ArrowDown": shiftArrow(AXIS.VERTICAL, 1),
  "Shift-Cmd-ArrowLeft": moveToRowEdge(DIRECTION.LEFT, true),
  "Shift-Cmd-ArrowRight": moveToRowEdge(DIRECTION.RIGHT, true),
  "Shift-Home": moveToRowEdge(DIRECTION.LEFT, true),
  "Shift-End": moveToRowEdge(DIRECTION.RIGHT, true),
});

import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { useAsyncEffect } from "@react-hook/async"
import {
  List,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
} from "@rmwc/list"
import scrollIntoView from "scroll-into-view-if-needed"

import { hasModifierKey, preventEventDefault } from "lib/ample-editor/lib/event-util"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import evaluateExpression from "lib/ample-util/evaluate-expression"
import fuzzyMatch from "lib/ample-util/fuzzy-match"
import resolvePluginActionPromises from "lib/ample-util/resolve-plugin-action-promises"

// --------------------------------------------------------------------------
export const TABLE_OF_CONTENTS_EXPRESSION = "toc";

// --------------------------------------------------------------------------
const SUGGESTED_EXPRESSIONS = [
  // These are shown when there is no input from the user
  { isDefault: true, iconName: "today", text: "today" },
  { isDefault: true, iconName: "event", text: "tomorrow" },

  // These will be matched by user input
  { iconName: "schedule", text: "now" },
  { iconName: "event", text: "yesterday" },

  // These will only be matched by user input if they are reasonably good matches
  { minScore: 3, iconName: "event", text: "next Monday" },
  { minScore: 4, iconName: "event", text: "next Tuesday" },
  { minScore: 4, iconName: "event", text: "next Wednesday" },
  { minScore: 4, iconName: "event", text: "next Thursday" },
  { minScore: 4, iconName: "event", text: "next Friday" },
  { minScore: 3, iconName: "event", text: "next Saturday" },
  { minScore: 4, iconName: "event", text: "next Sunday" },
];

const SUGGESTED_EXPRESSION_TOC = { isDefault: true, iconName: "list_alt", text: TABLE_OF_CONTENTS_EXPRESSION };

// --------------------------------------------------------------------------
function addWrap(index, length, delta) {
  const maxIndex = Math.max(0, length - 1);

  let newIndex = index + delta;

  if (newIndex < 0) {
    newIndex = Math.max(0, Math.min(length + newIndex, maxIndex));
  } else if (newIndex > maxIndex) {
    newIndex = Math.min(Math.max(0, newIndex - length), maxIndex);
  }

  return newIndex;
}

// --------------------------------------------------------------------------
// Note that this evaluates to preview results - not the actual results - in the case of plugin expressions
function evaluateExpressionWithExtras(text, { pluginName = null, tableOfContentsEnabled = false } = {}) {
  if (pluginName) {
    return { result: text !== pluginName ? pluginName : "" };
  } else if (tableOfContentsEnabled && text.toLowerCase() === TABLE_OF_CONTENTS_EXPRESSION) {
    return { result: "Table of Contents" };
  } else {
    return evaluateExpression(text);
  }
}

// --------------------------------------------------------------------------
async function fuzzyMatchExpressions(searchText, { pluginActions = [], tableOfContentsEnabled = false } = {}) {
  const matches = [];

  const suggestedExpressions = SUGGESTED_EXPRESSIONS.concat(tableOfContentsEnabled ? [ SUGGESTED_EXPRESSION_TOC ] : []);

  pluginActions.forEach(({ checkResult, icon, name }) => {
    const text = typeof(checkResult) === "string" ? checkResult : name;

    suggestedExpressions.push({
      iconName: icon,
      isDefault: true,
      pluginName: name,
      text,
    });
  });

  suggestedExpressions.forEach(({ iconName, isDefault, minScore, pluginName, text }) => {
    if (searchText) {
      const match = fuzzyMatch(searchText, text, "<b>", "</b>", { shouldEscapeHTML: true });
      if (!match) return;

      const { rendered: html, score } = match;
      if (minScore && score < minScore) return;

      matches.push({
        iconName,
        html,
        isValidExpression: true,
        pluginName,
        score,
        text,
      });
    } else if (isDefault) {
      matches.push({
        iconName,
        isValidExpression: true,
        pluginName,
        text,
      });
    }
  });

  return matches.sort(function(a, b) {
    const compare = b.score - a.score;
    return compare ? compare : a.text - b.text;
  });
}

// --------------------------------------------------------------------------
function renderIcon(name) {
  return name in VECTOR_ICON_PATHS
    ? (<svg className="vector-icon" viewBox="0 0 24 24"><path d={ VECTOR_ICON_PATHS[name] }/></svg>)
    : name;
}

// --------------------------------------------------------------------------
function useSuggestions(getInsertTextPluginActions, tableOfContentsEnabled, text) {
  const [ pluginActions, setPluginActions ] = useState([]);

  // This is mostly to shush test warnings, where the test ends before waiting on the plugin action resolution
  const unmountedRef = useRef(false);
  useEffect(() => () => { unmountedRef.current = true; }, []);

  useAsyncEffect(
    async () => {
      if (!getInsertTextPluginActions) return;

      const pluginActionPromises = await getInsertTextPluginActions();

      resolvePluginActionPromises(pluginActionPromises, {
        setPluginActions,
        shouldCancel: () => unmountedRef.current,
      });
    },
    // Note that getInsertTextPluginActions isn't a dependency as it's expected to change (identity-wise) but
    // that doesn't change the actual function (it's coming from non-React land and gets rebound on render)
    []
  );

  const { value: suggestions } = useAsyncEffect(
    async () => {
      let matches = await fuzzyMatchExpressions(text, { pluginActions, tableOfContentsEnabled });

      if (text.length > 0) {
        let existingMatch = null;

        matches = matches.filter(match => {
          // Plugins are case-sensitive, but all other expressions aren't
          if (match.text === text || match.text === text.toLowerCase()) {
            existingMatch = match;
            return false;
          }
          return true;
        });

        if (existingMatch) {
          matches.unshift({
            iconName: existingMatch.iconName,
            isValidExpression: existingMatch.isValidExpression,
            pluginName: existingMatch.pluginName,
            text,
          });
        } else {
          const { result } = evaluateExpressionWithExtras(text, { tableOfContentsEnabled });

          matches.unshift({
            iconName: "calculator-variant-outline",
            isValidExpression: result !== null,
            pluginName: null,
            text,
          });
        }
      }

      return matches;
    },
    [ pluginActions, tableOfContentsEnabled, text ]
  );

  return suggestions;
}

// --------------------------------------------------------------------------
function useSuggestionsSelection(suggestions) {
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const suggestionCount = suggestions ? suggestions.length : 0;
  useEffect(
    () => {
      setSelectedIndex(selectedIndexWas => Math.min(selectedIndexWas, Math.max(0, suggestionCount - 1)));
    },
    [ suggestionCount ]
  );

  const ignoreFirstSuggestion = suggestions && suggestions.length && !suggestions[0].isValidExpression;
  const effectiveSelectedIndex = selectedIndex === 0 && ignoreFirstSuggestion ? selectedIndex + 1 : selectedIndex;

  const moveSelectedIndex = useCallback(
    delta => {
      let newSelectedIndex = addWrap(effectiveSelectedIndex, suggestionCount, delta);

      if (ignoreFirstSuggestion && newSelectedIndex === 0) {
        newSelectedIndex = addWrap(newSelectedIndex, suggestionCount, delta);
      }

      setSelectedIndex(newSelectedIndex);
    },
    [ effectiveSelectedIndex, ignoreFirstSuggestion, suggestionCount ]
  );

  return [ effectiveSelectedIndex, moveSelectedIndex ];
}

// --------------------------------------------------------------------------
function ExpressionSuggestion(props) {
  const {
    completeExpression,
    html,
    iconName,
    isSelected,
    pluginName,
    tableOfContentsEnabled,
    text,
  } = props;

  const { result } = useMemo(
    () => evaluateExpressionWithExtras(text, { pluginName, tableOfContentsEnabled }),
    [ pluginName, tableOfContentsEnabled, text ]
  );

  const isValid = result !== null;

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      if (isValid) completeExpression(text);
    },
    [ completeExpression, isValid, text ]
  );

  const listItemRef = useRef();
  const haveUpdatedRef = useRef(false);
  useEffect(
    () => {
      if (!haveUpdatedRef.current) {
        haveUpdatedRef.current = true;
        return;
      }

      if (!isSelected) return;

      const { current: listItem } = listItemRef;
      if (listItem) {
        scrollIntoView(listItem, { behavior: "smooth", scrollMode: "if-needed" });
      }
    },
    [ isSelected ]
  );

  return (
    <ListItem
      className="popup-list-item"
      disabled={ !isValid }
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      ref={ listItemRef }
      selected={ isSelected }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ renderIcon(isValid ? iconName : "alert-octagon-outline") } />
      <ListItemText>
        <ListItemPrimaryText>
          { html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : (<b>{ text }</b>) }
        </ListItemPrimaryText>
        <ListItemSecondaryText>
          { isValid ? result.toString() : (<em>invalid expression</em>) }
        </ListItemSecondaryText>
      </ListItemText>
      { isValid && isSelected ? (<ListItemMeta icon="keyboard_return" />) : null }
    </ListItem>
  );
}

// --------------------------------------------------------------------------
function ExpressionMenu(props, ref) {
  const { completeExpression, getInsertTextPluginActions, tableOfContentsEnabled, text } = props;

  const [ isDismissed, setIsDismissed ] = useState(false);

  const suggestions = useSuggestions(getInsertTextPluginActions, tableOfContentsEnabled, text);

  const [ selectedIndex, moveSelectedIndex ] = useSuggestionsSelection(suggestions);

  useImperativeHandle(ref, () => ({
    dismiss: () => {
      setIsDismissed(true);
    },

    handleKeyDown: (_editorView, event) => {
      if (isDismissed || hasModifierKey(event)) return false;

      switch (event.key) {
        case "ArrowDown":
          moveSelectedIndex(1);
          return true;

        case "ArrowUp":
          moveSelectedIndex(-1);
          return true;

        case "Enter": {
          const currentSuggestion = suggestions ? suggestions[selectedIndex] : { text };
          if (currentSuggestion) {
            const { result } = evaluateExpressionWithExtras(currentSuggestion.text, {
              pluginName: currentSuggestion.pluginName,
              tableOfContentsEnabled,
            });

            if (result !== null) {
              completeExpression(currentSuggestion.text);
            }
          }
          return true;
        }

        case "Escape":
          setIsDismissed(true);
          return true;

        default:
          break;
      }

      return false;
    },

    isDismissed: () => {
      return isDismissed;
    },
  }));

  if (isDismissed) return null;

  return (
    <List className="expression-menu popup-list">
      {
        (suggestions || []).map((suggestion, index) => (
          <ExpressionSuggestion
            { ...suggestion }
            completeExpression={ completeExpression }
            isSelected={ selectedIndex === index }
            key={ suggestion.text + (suggestion.pluginName || "") }
            tableOfContentsEnabled={ tableOfContentsEnabled }
          />
        ))
      }
    </List>
  );
}

// eslint-disable-next-line no-func-assign
ExpressionMenu = forwardRef(ExpressionMenu);

ExpressionMenu.propTypes = {
  completeExpression: PropTypes.func.isRequired,
  getInsertTextPluginActions: PropTypes.func,
  tableOfContentsEnabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default ExpressionMenu;

// --------------------------------------------------------------------------
// "Note URLs" are just links to notes on www.amplenote.com (or edge.amplenote.com), but get treated differently
// than links outside Amplenote in many ways.
// --------------------------------------------------------------------------
import { noteParamsFromNoteUUID, noteUUIDFromNoteParams, noteUUIDFromUUIDs } from "lib/ample-util/note-uuid"
import { TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
const BASE_URL = "https://www.amplenote.com";

const NOTE_URL_PATTERN = /^(https:\/\/(?:www|edge)\.amplenote\.com\/notes\/)(local-)?([A-F\d-]+)($|\?|#)/i;
const NEW_NOTE_URL_PATTERN = /^https:\/\/(?:www|edge)\.amplenote\.com\/notes\/new($|\?|#)/i;

const PUBLIC_NOTE_SOURCE_PREFIX = "public-";

// --------------------------------------------------------------------------
export function anchorNameFromHeadingText(text) {
  // Note that this could/should support unicode by percent encoding the characters, but it's tricky to allow just
  // the characters that are valid (percent symbol is only allowed with two following hex digits), and using the URI
  // class to encode works in some browsers (Chrome) but not in node (and probably not react-native's URL polyfill),
  // so we can't use that approach in tests. encodeURIComponent percent-encodes too many characters, but is still not
  // useful without a reasonable regex here that can allow just valid percent-encoded characters.
  return text.replace(/[^a-z0-9?/:@\-._~!$&'()*+,;=]/ig, "_");
}

// --------------------------------------------------------------------------
export function fragmentFromNoteParams(noteParams) {
  const { fragment } = noteParams;
  return fragment ? `#${ fragment }` : "";
}

// --------------------------------------------------------------------------
export function isNewNoteURL(href) {
  return href && href.match(NEW_NOTE_URL_PATTERN);
}

// --------------------------------------------------------------------------
export function isNoteURL(href) {
  return href && href.match(NOTE_URL_PATTERN);
}

// --------------------------------------------------------------------------
export function newNoteParamsFromRelativeURL(relativeURL) {
  return newNoteParamsFromURL(BASE_URL + relativeURL);
}

// --------------------------------------------------------------------------
export function newNoteParamsFromURL(url) {
  const match = url ? url.match(NEW_NOTE_URL_PATTERN) : null;
  if (!match) return null;

  const nameMatch = url.match(/[&?]name=(.+?)(?:&|\?|$|#)/i);
  const sourceMatch = url.match(/[&?]source=((?:local-)?[A-F\d-]+|public-[A-HJ-NP-Za-km-z1-9]+)(?:&|\?|$|#)/i);
  const tagsMatch = url.match(/[&?]tags=(.+?)(?:&|\?|$|#)/i);

  let name = null;
  try {
    if (nameMatch) name = decodeURIComponent(nameMatch[1]);
  } catch (_error) {
    // URIError: URI malformed
  }

  let source = null;
  try {
    if (sourceMatch) {
      const sourceValue = decodeURIComponent(sourceMatch[1]);
      if (sourceValue.startsWith(PUBLIC_NOTE_SOURCE_PREFIX)) {
        source = { token: sourceValue.substring(PUBLIC_NOTE_SOURCE_PREFIX.length) };
      } else {
        source = noteParamsFromNoteUUID(sourceValue);
      }
    }
  } catch (_error) {
    // URIError: URI malformed
  }

  let tags = [];
  try {
    if (tagsMatch) tags = decodeURIComponent(tagsMatch[1]).split(TAGS_DELIMITER);
  } catch (_error) {
    // URIError: URI malformed
  }

  return { name, source, tags };
}

// --------------------------------------------------------------------------
export function noteParamsFromHref(href) {
  if (!href) return null;

  return href.startsWith("/") ? noteParamsFromURL(BASE_URL + href) : noteParamsFromURL(href);
}

// --------------------------------------------------------------------------
export function noteParamsFromURL(url) {
  const match = url ? url.match(NOTE_URL_PATTERN) : null;
  if (!match) return null;

  const isLocalNote = !!match[2];
  const uuid = match[3];

  const noteParams = {
    localUUID: isLocalNote ? uuid : null,
    remoteUUID: isLocalNote ? null : uuid,
  };

  const queryAndFragment = url.slice(match.index + match[0].length - match[4].length);
  const [ query, fragment ] = queryAndFragment.split("#", 2);

  if (fragment) noteParams.fragment = fragment;

  if (query && query.length > 1) {
    const highlightTaskUUIDMatch = query.match(/[&?]highlightTaskUUID=([A-F\d-]+)(?:&|\?|$|#)/i);
    if (highlightTaskUUIDMatch) noteParams.highlightTaskUUID = highlightTaskUUIDMatch[1];

    const tabMatch = query.match(/[&?]tab=(.+?)(?:&|\?|$|#)/i);
    if (tabMatch) noteParams.tab = tabMatch[1];
  }

  return noteParams;
}

// --------------------------------------------------------------------------
export function pathFromNote({ localUUID, remoteUUID }) {
  return `/notes/${ noteUUIDFromUUIDs(localUUID, remoteUUID) }`;
}

// --------------------------------------------------------------------------
export function relativeURLFromNewNoteParams(newNoteParams) {
  if (!newNoteParams) return null;

  const { name, source, tags } = newNoteParams;

  const queryParams = [];

  if (name) {
    queryParams.push(`name=${ encodeURIComponent(name) }`);
  }

  if (source) {
    const noteUUID = noteUUIDFromNoteParams(source);
    if (noteUUID) queryParams.push(`source=${ encodeURIComponent(noteUUID) }`);
  }

  if (tags && tags.length > 0) {
    queryParams.push(`tags=${ encodeURIComponent(tags.join(TAGS_DELIMITER)) }`);
  }

  const queryString = queryParams.length > 0 ? `?${ queryParams.join("&") }` : "";
  return `/notes/new${ queryString }`;
}

// --------------------------------------------------------------------------
// Does not include the domain/port, just the path, query string, and fragment
export function relativeURLFromNoteParams(noteParams) {
  const noteUUID = noteUUIDFromNoteParams(noteParams);
  if (!noteUUID) return null;

  return `/notes/${ noteUUID }${ searchFromNoteParams(noteParams) }${ fragmentFromNoteParams(noteParams) }`;
}

// --------------------------------------------------------------------------
export function replaceLocalNoteURL(href, localUUID, remoteUUID) {
  let replaced = false;

  const newURL = href.replace(NOTE_URL_PATTERN, (match, notesURL, isLocalNote, uuid, anchor) => {
    if (isLocalNote && uuid === localUUID) {
      replaced = true;
      return notesURL + remoteUUID + anchor;
    } else {
      return match;
    }
  });

  return replaced ? newURL : null;
}

// --------------------------------------------------------------------------
// Note this returns an empty string if there are no relevant search params, and includes a leading "?" when non-empty
export function searchFromNoteParams(noteParams, search = "") {
  const { highlightTaskUUID, tab } = noteParams;

  if (highlightTaskUUID) {
    search += `${ search.length > 0 ? "&" : "?" }highlightTaskUUID=${ encodeURIComponent(highlightTaskUUID) }`;
  }

  if (tab) {
    search += `${ search.length > 0 ? "&" : "?" }tab=${ encodeURIComponent(tab) }`;
  }

  return search;
}

// --------------------------------------------------------------------------
export function urlFromNewNoteParams(newNoteParams) {
  const relativeURL = relativeURLFromNewNoteParams(newNoteParams);
  return relativeURL ? `${ BASE_URL }${ relativeURL }` : null;
}

// --------------------------------------------------------------------------
export function urlFromNoteParams(noteParams) {
  const relativeURL = relativeURLFromNoteParams(noteParams);
  return relativeURL ? `${ BASE_URL }${ relativeURL }` : null;
}

import { addDays, setHours, setMinutes, startOfDay } from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import { Button } from "@rmwc/button"
import { IconButton } from "@rmwc/icon-button"
import Tippy from "@tippyjs/react"

import { timeParamsFromTimeText } from "lib/ample-editor/components/task-detail/util"
import { hasModifierKey, hasModifierKeyExceptShift } from "lib/ample-editor/lib/event-util"
import { formatDateTime, formatTime } from "lib/ample-util/date"

// --------------------------------------------------------------------------
const BUTTON_TOOLTIP_DELAY = [ 750, 150 ];

// --------------------------------------------------------------------------
export default class HideForDaysMenu extends React.PureComponent {
  // --------------------------------------------------------------------------
  static propTypes = {
    buttonText: PropTypes.string,
    cancel: PropTypes.func.isRequired,
    preface: PropTypes.string,
    setStartAt: PropTypes.func.isRequired,
    startAt: PropTypes.number,
  };

  state = {
    daysValue: "",
    timeValue: "",
  };

  _daysInputRef = React.createRef();
  _timeInputRef = React.createRef();

  // --------------------------------------------------------------------------
  render() {
    const { buttonText, preface } = this.props;
    const { daysValue, timeValue } = this.state;

    const {
      daysPlaceholder,
      daysSpecified,
      daysValid,
      date,
      timePlaceholder,
      timeSpecified,
      timeValid,
    } = this._value(daysValue, timeValue);

    let daysInputClassName = "text-input numeric";
    if (daysSpecified) {
      daysInputClassName += daysValid ? " valid" : " invalid";
    }

    let timeInputClassName = "text-input numeric hours";
    if (timeSpecified) {
      timeInputClassName += timeValid ? " valid" : " invalid";
    }

    let helperText = null;
    if (date) {
      helperText = (
        <div className="helper-text">
          { formatDateTime(date) }
        </div>
      );
    }

    return (
      <div className="hide-for-days-menu sub-menu">
        <div className="label">{ preface || "Hide for" }</div>
        <input
          autoFocus
          className={ daysInputClassName }
          inputMode="numeric"
          maxLength={ 4 }
          onChange={ this._setDaysValue }
          onKeyDown={ this._onDaysInputKeyDown }
          placeholder={ daysPlaceholder }
          ref={ this._daysInputRef }
          size={ Math.max(1, daysValue.length) }
          type="text"
          value={ daysValue }
        />
        <div className="label">days, until</div>
        <input
          className={ timeInputClassName }
          onChange={ this._setTimeValue }
          onKeyDown={ this._onTimeInputKeyDown }
          placeholder={ timePlaceholder }
          ref={ this._timeInputRef }
          size={ 7 }
          type="text"
          value={ timeValue }
        />

        <Tippy
          content="Press enter to hide for the specified number of days"
          delay={ buttonText ? BUTTON_TOOLTIP_DELAY : 150 }
          placement={ buttonText ? "bottom" : "top" }
        >
          <span className="enter-icon-button-container">
            {
              buttonText
                ? (
                  <Button
                    disabled={ !date }
                    label={ buttonText }
                    onClick={ this._setStartAt }
                    unelevated
                  />
                )
                : (<IconButton icon="keyboard_return" onClick={ this._setStartAt } />)
            }

          </span>
        </Tippy>

        { helperText }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _handleKeyDown = event => {
    switch (event.key) {
      case "Escape":
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this.props.cancel();
          return true;
        }
        break;

      case "Enter":
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this._setStartAt();
          return true;
        }
        break;

      default:
        return false;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  _onDaysInputKeyDown = event => {
    if (this._handleKeyDown(event)) return;

    if (event.key === "Tab" && !hasModifierKeyExceptShift(event)) {
      event.preventDefault();

      if (event.shiftKey) {
        this.props.cancel();
      } else {
        const { current: timeInput } = this._timeInputRef;
        if (timeInput) timeInput.focus();
      }
    }
  };

  // --------------------------------------------------------------------------
  _onTimeInputKeyDown = event => {
    if (this._handleKeyDown(event)) return;

    if (event.key === "Tab" && !hasModifierKeyExceptShift(event)) {
      event.preventDefault();

      if (event.shiftKey) {
        const { current: daysInput } = this._daysInputRef;
        if (daysInput) daysInput.focus();
      } else {
        this.props.cancel();
      }
    }
  };

  // --------------------------------------------------------------------------
  _setStartAt = () => {
    const { cancel, setStartAt } = this.props;
    const { daysValue, timeValue } = this.state;

    const { value } = this._value(daysValue, timeValue);
    if (value) {
      setStartAt(value);
    } else {
      cancel();
    }
  };

  // --------------------------------------------------------------------------
  _setDaysValue = event => {
    this.setState({ daysValue: event.target.value });
  };

  // --------------------------------------------------------------------------
  _setTimeValue = event => {
    this.setState({ timeValue: event.target.value });
  };

  // --------------------------------------------------------------------------
  _value = (daysValue, timeValue) => {
    const { startAt } = this.props;

    let date = null;

    let daysPlaceholder = "";
    let daysSpecified = false;
    let daysValid = false;
    if (daysValue !== "") {
      daysSpecified = true;

      if (!isNaN(daysValue)) {
        daysValid = true;

        const days = parseInt(daysValue, 10);
        date = startOfDay(addDays(Date.now(), days));
      }
    }

    let timePlaceholder = "";
    let timeSpecified = false;
    let timeValid = false;
    if (timeValue !== "") {
      timeSpecified = true;

      const timeParams = timeParamsFromTimeText(timeValue);
      if (timeParams) {
        timeValid = true;

        if (!daysSpecified) daysPlaceholder = "0";
        if (!date) date = startOfDay(Date.now());

        date = setHours(date, timeParams.hour);
        date = setMinutes(date, timeParams.minute);
      }
    }

    if (!timeSpecified || !timeValid) {
      if (startAt) {
        const startAtDate = new Date(startAt * 1000);
        timePlaceholder = formatTime(startAtDate);

        if (date) {
          date = setHours(date, startAtDate.getHours());
          date = setMinutes(date, startAtDate.getMinutes());
        }
      } else if (date) {
        timePlaceholder = formatTime(date);
      }
    }

    return {
      date,
      daysPlaceholder,
      daysSpecified,
      daysValid,
      timePlaceholder,
      timeSpecified,
      timeValid,
      value: date ? Math.floor(date.getTime() / 1000) : null,
    }
  };
}

import { compact } from "lodash"

import { isNoteURL } from "lib/ample-util/note-url"
import { normalizeTagText, TAG_TEXT_DELIMITER, TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
const DEFAULT_FLAGS = {
  applyFilterTags: true,
  applySourceTags: false,
  headingSearchText: null,
  taskSearchText: null,
};

export const INCLUDE_SOURCE_TAGS_CHARACTER = "&";
export const OMIT_FILTER_TAGS_CHARACTER = "~";
export const SEARCH_HEADINGS_CHARACTER = "#";
export const SEARCH_TASKS_CHARACTER = "-";

// --------------------------------------------------------------------------
function parseFlags(text, { headingSearchStartOffset, taskSearchStartOffset }) {
  const result = {
    ...DEFAULT_FLAGS,
  };

  if (text.startsWith(INCLUDE_SOURCE_TAGS_CHARACTER)) {
    result.applySourceTags = true;
    text = text.substring(INCLUDE_SOURCE_TAGS_CHARACTER.length);
  } else if (text.startsWith(OMIT_FILTER_TAGS_CHARACTER)) {
    result.applyFilterTags = false;
    text = text.substring(OMIT_FILTER_TAGS_CHARACTER.length);
  }

  const searchHeadingsCharacterIndex = text.substring(headingSearchStartOffset).indexOf(SEARCH_HEADINGS_CHARACTER);
  const searchTasksCharacterIndex = text.substring(taskSearchStartOffset).indexOf(SEARCH_TASKS_CHARACTER);

  if (searchTasksCharacterIndex > -1) {
    result.taskSearchText = text.substring(taskSearchStartOffset + searchTasksCharacterIndex + SEARCH_TASKS_CHARACTER.length);
    text = text.substring(0, taskSearchStartOffset + searchTasksCharacterIndex);
  }

  // We don't allow searching headers when searching tasks (but searching tasks when searching headers is okay)
  if (searchHeadingsCharacterIndex > -1 && (searchTasksCharacterIndex === -1 || searchTasksCharacterIndex > searchHeadingsCharacterIndex)) {
    result.headingSearchText = text.substring(headingSearchStartOffset + searchHeadingsCharacterIndex + SEARCH_HEADINGS_CHARACTER.length);
    text = text.substring(0, headingSearchStartOffset + searchHeadingsCharacterIndex);
  }

  result.textWithoutFlags = text;

  return result;
}

// --------------------------------------------------------------------------
// e.g.:
//  "//blah" => "blah"
//  "/blah/subtag//" => "blah/subtag"
//  "/blah/subtag//,what" => "what"
//  "/blah/subtag//,/what/sub//" => "what/sub"
export function tagSearchTermFromText(text) {
  const tagTexts = text.split(TAGS_DELIMITER);
  return tagTexts[tagTexts.length - 1].replace(/^\/+|\/+$/g, "");
}

// --------------------------------------------------------------------------
// Separates a string into a set of tags and the remaining non-tag text, e.g.:
//
//    parseNoteLinkText("blah/something/and then") => {
//      applyFilterTags: false, // True if the text begins with the character that indicates user wants their suggestion to include the tags currently being filtered by UI
//      applySourceTags: false, // True if the text begins with the character that indicates user wants their suggestion to include the tags from the source note
//      tags: [ "blah/something" ],
//      taskSearchText: null, // Text to use for task search, or null if tasks should not be searched
//      text: "and then", // Base search query
//    }
//
export default function parseLinkTargetText(text, options = {}) {
  const {
    headingSearchStartOffset = 0,
    taskSearchStartOffset = 0,
  } = options;

  if (isNoteURL(text)) {
    const searchHeadingsCharacterIndex = text.substring(headingSearchStartOffset).indexOf(SEARCH_HEADINGS_CHARACTER);

    let headingSearchText = null;
    if (searchHeadingsCharacterIndex > -1) {
      headingSearchText = text.substring(headingSearchStartOffset + searchHeadingsCharacterIndex + SEARCH_HEADINGS_CHARACTER.length);
      text = text.substring(0, headingSearchStartOffset + searchHeadingsCharacterIndex);
    }

    return { ...DEFAULT_FLAGS, headingSearchText, tags: [], text };
  }

  const { textWithoutFlags, ...result } = parseFlags(text, { headingSearchStartOffset, taskSearchStartOffset });
  text = textWithoutFlags;

  result.tags = [];
  const textSegments = text.split(TAG_TEXT_DELIMITER).map(textSegment => textSegment.trim());
  if (textSegments.length > 1) {
    text = textSegments[textSegments.length - 1];

    const tagsText = compact(textSegments.slice(0, -1)).join(TAG_TEXT_DELIMITER);
    if (tagsText) {
      const haveTagByTagText = {};
      tagsText.split(TAGS_DELIMITER).forEach(tagText => {
        tagText = normalizeTagText(tagText.replace(/^\/+|\/+$/g, "").trim());
        if (tagText && !haveTagByTagText[tagText]) {
          result.tags.push(tagText);
          haveTagByTagText[tagText] = true;
        }
      });
    }
  }

  result.text = text;

  return result;
}

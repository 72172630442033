import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import { IconButton } from "@rmwc/icon-button"

import "./pdf-toolbar.scss"

// --------------------------------------------------------------------------
export default function PdfToolbar({ attachmentURL, openAttachment }) {
  const [ isOpening, setIsOpening ] = useState(false);

  const onOpenClick = useCallback(
    _event => {
      setIsOpening(true);
      (async () => {
        try {
          await openAttachment(attachmentURL);
        } finally {
          setIsOpening(false);
        }
      })();
    },
    [ attachmentURL, openAttachment ]
  );

  return (
    <div className="pdf-toolbar">
      {
        isOpening
          ? (<CircularProgress size="xsmall" />)
          : (<IconButton icon="open_in_new" onClick={ onOpenClick } />)
      }
    </div>
  )
}

PdfToolbar.propTypes = {
  attachmentURL: PropTypes.string.isRequired,
  iframeRef: PropTypes.shape({ current: PropTypes.any.isRequired }).isRequired,
  openAttachment: PropTypes.func.isRequired,
};

import React from "react"
import ReactDOM from "react-dom"

import Toolbar from "lib/ample-editor/components/toolbar"
import { insertAttachment, insertMedia } from "lib/ample-editor/lib/file-commands"
import { getTargetViews } from "lib/ample-editor/plugins/target-view-plugin"
import { executeToolbarCommand, getAvailableCommands } from "lib/ample-editor/plugins/toolbar-plugin"

// --------------------------------------------------------------------------
// Wraps Toolbar in a ProseMirror view, re-rendering it when the editor state changes (note that
// Toolbar implements shouldComponentUpdate to determine when an _actual_ re-render is really necessary)
export default class ToolbarView {
  // --------------------------------------------------------------------------
  constructor(editorView) {
    this._executeToolbarCommand = this._executeToolbarCommand.bind(this, editorView);
    this._insertAttachment = this._insertAttachment.bind(this, editorView);
    this._insertMedia = this._insertMedia.bind(this, editorView);

    this.dom = document.createElement("div");
    this.dom.className = "toolbar-wrapper";

    if (editorView.dom.parentNode) editorView.dom.parentNode.insertBefore(this.dom, editorView.dom);

    this.update(editorView);
  }

  // --------------------------------------------------------------------------
  update(editorView) {
    const { editable, state } = editorView;

    ReactDOM.render(
      <Toolbar
        availableCommands={ getAvailableCommands(state) }
        executeCommand={ this._executeToolbarCommand }
        insertAttachment={ this._insertAttachment }
        insertMedia={ this._insertMedia }
        readonly={ !editable }
      />,
      this.dom
    );
  }

  // --------------------------------------------------------------------------
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom);
    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  _executeToolbarCommand(editorView, command) {
    return executeToolbarCommand(editorView, command);
  }

  // --------------------------------------------------------------------------
  _insertAttachment(topLevelEditorView, attachment) {
    const { editorView } = getTargetViews(topLevelEditorView);

    const { state: { selection } } = editorView;
    insertAttachment(editorView, attachment, selection.from, true);

    editorView.focus();
  }

  // --------------------------------------------------------------------------
  _insertMedia(topLevelEditorView, media) {
    const { editorView } = getTargetViews(topLevelEditorView);

    const { state: { selection } } = editorView;
    insertMedia(editorView, media, selection.from, true);

    editorView.focus();
  }
}

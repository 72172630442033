import { NodeSelection, TextSelection } from "prosemirror-state"
import { canSplit } from "prosemirror-transform"

import { codeBlockDepth } from "lib/ample-editor/lib/code-block/code-block-util"
import { nearestLeafPos, buildSetBlockType } from "lib/ample-editor/lib/commands"
import DocChildrenSelection, { MAX_DEPTH_TO_ENTER_DOC_CHILD_SELECT } from "lib/ample-editor/lib/doc-children-selection"
import { ARROW } from "lib/ample-editor/lib/selection-util"

// When pressing up/down in proximity to a code block (which is wrapped in non-conteneditable div), browser will
// visually appear to include range from cursor to CM block as selection, but to Prosemirror, the selection will
// only be a single position since it couldn't move cursor into non-contenditable div, so we can't tell that the
// cursor even moved. Thus, this constant defines a degree of proximity, where if cursor is in neighboring node
// to CM, and fewer than this many characters away, we will move selection range through the CM node
const MAX_CHARACTER_DISTANCE_TO_CROSS_INTO_BLOCK = 100;

// --------------------------------------------------------------------------
export const unwrapCodeBlockAtStart = (state, dispatch) => {
  const { selection: { $head, $anchor }, schema } = state;
  if ($head.pos !== $anchor.pos) return false;
  if ($head.parentOffset) return false;
  if (!$head.parent || $head.parent.type !== schema.nodes.code_block) return false;

  if (dispatch) {
    convertFromCodeBlock(schema.nodes.paragraph)(state, dispatch);
  }

  return true;
}

// --------------------------------------------------------------------------
export const backspaceOutOfCodeBlock = (state, dispatch) => {
  return unwrapCodeBlockAtStart(state, dispatch);
}

// --------------------------------------------------------------------------
export const swallowCodeBlockEnter = (state, _dispatch) => {
  const { $from, $to } = state.selection;
  if (!$from.sameParent($to)) {
    return false;
  }

  // Enter in a code block is handled by CodeMirror. On Chrome desktop, since CM element has focus, Enter is never
  // received by PM. But on mobile, enter is received by both the inner contenteditable CM element and the outer
  // PM editor, so we need to explicitly stop PM from handling it:
  const depth = codeBlockDepth($from);
  return depth !== null;
}

// --------------------------------------------------------------------------
// When user is holding shift and traverses a boundary between CM and PM, we need to set selection to show
// the entire CM block as selected, along with some adjacent PM area
// `direction`: member of ARROW enum
export const selectThroughCodeMirrorBoundary = direction => (state, dispatch) => {
  const { doc, selection: { $anchor, $head } } = state;
  if (!$head || !$anchor || !$anchor.parent || !$head.parent) return false;
  // If code block is in a table cell, it would be too weird to select the entire table, so we'll prevent crossing CM boundary
  if ($head.depth > MAX_DEPTH_TO_ENTER_DOC_CHILD_SELECT || $anchor.depth > MAX_DEPTH_TO_ENTER_DOC_CHILD_SELECT) return false;
  let $selectionAnchor, $selectionHead;

  if (direction === ARROW.UP) {
    if ($head.pos === 0) return false; // Start of doc, nowhere to go
    if ($head.parent.type.spec.code && $head.parentOffset > 0) return false; // In code block but not at start, don't escape
    if (!$head.parent.type.spec.code && $head.parentOffset > MAX_CHARACTER_DISTANCE_TO_CROSS_INTO_BLOCK) return false; // In PM but not within requisite proximity of CM block
  } else {
    if ($head.pos === doc.nodeSize - 1) return false; // End of doc, nowhere to go
    if ($head.parent.type.spec.code && $head.parentOffset < $head.parent.content.size) return false; // In code block but not at very end of it
    if (!$head.parent.type.spec.code && $head.parentOffset < ($head.parent.content.size - MAX_CHARACTER_DISTANCE_TO_CROSS_INTO_BLOCK)) return false; // In PM but not within requisite proximity of CM block
  }

  const codeBlockDepths = [ $head, $anchor ].map($pos => codeBlockDepth($pos));
  const endpointsInCodeBlock = codeBlockDepths.filter(n => n);
  if (endpointsInCodeBlock.length === 2) { // Both endpoints in CM block
    if ($head.sameParent($anchor)) { // If they are in same code block, it can be represented with a NodeSelection
      // If endpoints are in code block, it shouldn't be possible for them to be on node boundaries (as implied by their parent being doc)
      if ($head.parent === doc) throw new Error("How can $head and $anchor be children of doc?");
      if (dispatch) {
        const codeBlockNodePos = $head.before(codeBlockDepths[0]);
        dispatch(state.tr.setSelection(NodeSelection.create(doc, codeBlockNodePos)));
      }
      return true;
    } else {
      // If the endpoints are in different code blocks, grab node extents for DocChildrenSelection
      $selectionAnchor = doc.resolve(direction === ARROW.UP ? $anchor.after(1) : $anchor.before(1));
      $selectionHead = doc.resolve(direction === ARROW.UP ? $head.before(1) : $head.after(1));
    }
  } else { // Zero or one endpoints in code block: extent is potentially on the edge of a code block
    let $neighbor;
    if (direction === ARROW.UP) {
      const posAbove = Math.max($head.pos - $head.parentOffset - 1, 0);
      $neighbor = nearestLeafPos(doc.resolve(posAbove), -1);
    } else {
      // There's no node after the top-level node, so `.after` will fail if the `$head` depth is zero, which it can
      // be if a gapcursor is placing the head between a horizontal_rule and a code block.
      const posBelow = $head.after(Math.max($head.depth, 1));
      $neighbor = nearestLeafPos(doc.resolve(posBelow));
    }
    if (![ $neighbor, $anchor ].find($pos => codeBlockDepth($pos))) return false;
    $selectionAnchor = doc.resolve(direction === ARROW.UP ? $anchor.after(1) : $anchor.before(1));
    $selectionHead = doc.resolve(direction === ARROW.UP ? $neighbor.before(1) : $neighbor.after(1));
  }

  if (dispatch) {
    const transform = state.tr;
    const selection = DocChildrenSelection.create($selectionAnchor, $selectionHead);
    dispatch(transform.setSelection(selection).scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const convertToCodeBlock = codeBlockType => (state, dispatch) => buildSetBlockType(codeBlockType, null, { liftLinkNodes: true })(state, transform => {
  if (!dispatch) return;

  const { selection: { $to: { parent: codeBlock } } } = transform;
  const { selection: { from: fromWas, to: toWas } } = state;

  const $from = transform.doc.resolve(transform.mapping.map(fromWas));
  const $to = transform.doc.resolve(transform.mapping.map(toWas));

  if (!$to.sameParent($from)) {
    // If we've converted multiple paragraphs to code blocks, we'll have multiple code blocks that we need to
    // join together into a single code block
    const parentNode = $from.node($from.sharedDepth($to.pos));
    const fromNodeIndex = $from.indexAfter($from.depth - 1);
    const toNodeIndex = $to.index($to.depth - 1);

    const joinPositions = [];
    parentNode.forEach((childNode, childOffset, childIndex) => {
      if (childIndex === 0) return;
      if (childIndex < fromNodeIndex) return;
      if (childIndex > toNodeIndex) return;

      joinPositions.push(childOffset);
    });

    try {
      joinPositions.reverse().forEach(pos => {
        // Insert the text before the child node (at the end of the previous node), then join this child with the
        // previous one
        transform.insertText("\n", pos - 1);
        transform.join(pos + 1);
      });
    } catch (_error) {
      // `join` can throw a number of exceptions if the opening and closing positions are at different depths, or in
      // nodes that can't be joined, e.g.:
      //  TransformError: Inconsistent open depths
      //  TransformError: Structure replace would overwrite content
      return;
    }

    // Note that we want to leave the start of the selection where it was, only mapping the position that is after
    // the newlines we've inserted.
    transform.setSelection(TextSelection.create(
      transform.doc,
      $from.pos,
      transform.mapping.map(toWas)
    ));
  } else if (codeBlock.content.size === 0) {
    transform.setSelection(TextSelection.near(transform.doc.resolve($to.pos)));
  } else {
    // This is what `setBlockType` does - at this point we know we can perform the transform, but want to handle it
    // here so we can perform some additional post-processing on the content (e.g. converting hard breaks to newlines)
    const { selection: { from, empty } } = transform;

    // If the trailing part of the selection is at the start of a top-level block, it's likely the user doesn't realize
    // that, because the browser doesn't render the cursor there (e.g. if the user was at the start of one block and
    // pressed shift+down to select the entire block).
    const atNodeStart = !empty && $to.pos === $to.start(1);
    const to = atNodeStart ? state.selection.to - 1 : state.selection.to;

    state.doc.nodesBetween(from, to, (node, pos) => {
      switch (node.type.name) {
        case "hard_break":
          transform.insertText("\n", pos);
          break;

        case "link":
          transform.insertText(node.textContent, pos);
          break;

        default:
          break;
      }
    });
  }

  dispatch(transform);
});

// --------------------------------------------------------------------------
// Converts a code block back to a non-code block type. Note this is more complex than just calling setBlockType due
// to the newlines in the code block being actual newlines.
export const convertFromCodeBlock = paragraphType => (state, dispatch) => {
  const wrappedDispatch = transform => {
    const { selection, selection: { $from, $to } } = transform;

    const splitPositions = [];

    transform.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
      if (node.type !== paragraphType) return;

      let offset = 0;
      node.textContent.split("\n").forEach(textLine => {
        offset += textLine.length;

        splitPositions.push(pos + offset);

        // Skipping over the newline character, note this only matters for the next
        // line (if there is one).
        offset += 1;
      });
    });

    // We proceed from back to start so that deletions don't require extra mapping. Don't need to consider
    // the final array element, it will get get handled during first split or insert
    const reversedPositions = splitPositions.reverse().slice(1);
    reversedPositions.forEach(pos => {
      transform.delete(pos + 1, pos + 2);

      if (canSplit(transform.doc, pos + 1)) {
        transform.split(pos + 1);
      } else {
        // If we're manually inserting a hard-break we don't want one at the end of the (former) code block, which
        // is the first split index, since we've reversed the list to run them from later to earlier
        transform.insert(pos + 1, state.schema.nodes.hard_break.create());
      }
    });

    // DocChildrenSelection has its boundaries at nodes, so snapping to start/end like an inline node takes us to end of doc
    if (selection instanceof DocChildrenSelection) {
      const $anchor = transform.doc.resolve(transform.mapping.map(selection.anchor));
      const $head = transform.doc.resolve(transform.mapping.map(selection.head));
      transform.setSelection(TextSelection.between($anchor, $head));
    } else {
      const mapStart = transform.mapping.map($from.start());
      const mapEnd = transform.mapping.map($to.end());
      transform.setSelection(TextSelection.create(transform.doc, mapStart, mapEnd));
    }
    dispatch(transform);
  };

  return buildSetBlockType(paragraphType)(state, dispatch ? wrappedDispatch : null);
};

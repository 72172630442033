import { omit } from "lodash"

// --------------------------------------------------------------------------
// Defines the default values for all nodes with attributes. This is defined here (ample-util) so various consumers
// that might not have ample-editor can elide attributes that match the default value to reduce the footprint of
// a serialized document (usually by about 50%).
//
// Note that required attributes may or may not be listed here, as they don't have valid default values - when they are
// listed here the default should be `undefined`.
export const DEFAULT_ATTRIBUTES_BY_NODE_NAME = {
  attachment: {
    expanded: false,
    text: "",
  },
  bullet_list_item: {
    collapsed: false,
    crossedOutAt: null,
    duration: null,
    flags: null,
    indent: 0,
    notify: null,
    repeat: null,
    scheduledAt: null,
    uuid: null,
  },
  check_list_item: {
    collapsed: false,
    completedAt: null,
    createdAt: null,
    crossedOutAt: null,
    deadline: null,
    dismissedAt: null,
    due: null,
    dueDayPart: null,
    indent: 0,
    notify: null,
    repeat: null,
    startAt: null,
    startRule: null,
    duration: null,
    flags: null,
    points: null,
    pointsUpdatedAt: null,
    uuid: null,
  },
  code_block: {
    language: null,
  },
  doc: {
    completedTasks: [],
    hiddenTasks: [],
    serializedVersion: null,
    storage: {},
  },
  embed: {
    aspectRatio: 1,
  },
  heading: {
    collapsed: false,
    level: 1,
  },
  image: {
    align: null,
    // eslint-disable-next-line no-undefined
    src: undefined,
    text: "",
    width: null,
  },
  link: {
    description: "",
    href: "",
    media: null,
  },
  number_list_item: {
    collapsed: false,
    indent: 0,
    offset: 0,
    uuid: null,
  },
  video: {
    width: null,
  },
};

// --------------------------------------------------------------------------
// Provides a minor optimization so we don't need to use Object.keys repeatedly in omitDefaultNodeAttributes. Measured
// as reducing runtime by ~10% in a very large document (on a very high-end machine).
const DEFAULT_ATTRIBUTE_NAMES_BY_NODE_NAME = Object.keys(DEFAULT_ATTRIBUTES_BY_NODE_NAME).reduce((memo, nodeName) => {
  memo[nodeName] = Object.keys(DEFAULT_ATTRIBUTES_BY_NODE_NAME[nodeName]);
  return memo;
}, {});

// --------------------------------------------------------------------------
export function omitDefaultNodeAttributes(node) {
  const { attrs, content, type } = node;

  if (attrs) {
    const defaultAttributeNames = DEFAULT_ATTRIBUTE_NAMES_BY_NODE_NAME[type];
    if (defaultAttributeNames) {
      const defaultAttrs = DEFAULT_ATTRIBUTES_BY_NODE_NAME[type];
      const omitAttributeNames = defaultAttributeNames.filter(attributeName => {
        const attributeValue = attrs[attributeName];
        const defaultAttributeValue = defaultAttrs[attributeName];

        if (Array.isArray(attributeValue)) {
          // We know we only default to empty arrays
          return Array.isArray(defaultAttributeValue) && attributeValue.length === 0;
        } else if (typeof(attributeValue) === "object" && typeof(defaultAttributeValue) === "object" && defaultAttributeValue !== null) {
          // We know we only default to empty objects
          return attributeValue && Object.keys(attributeValue).length === 0;
        } else {
          return attributeValue === defaultAttributeValue;
        }
      });

      if (omitAttributeNames.length > 0) {
        node.attrs = omit(attrs, omitAttributeNames);
      }
    }
  }

  if (content) {
    content.forEach(childNode => {
      omitDefaultNodeAttributes(childNode);
    });
  }

  return node;
}

// --------------------------------------------------------------------------
export function populateDefaultNodeAttributes(node) {
  const { attrs, content, type } = node;

  if (attrs) {
    const defaultAttributeNames = DEFAULT_ATTRIBUTE_NAMES_BY_NODE_NAME[type];
    if (defaultAttributeNames) {
      const defaultAttrs = DEFAULT_ATTRIBUTES_BY_NODE_NAME[type];

      const addAttributes = {};
      defaultAttributeNames.forEach(attributeName => {
        if (!(attributeName in attrs)) {
          addAttributes[attributeName] = defaultAttrs[attributeName];
        }
      });

      if (addAttributes) {
        node.attrs = { ...attrs, ...addAttributes };
      }
    }
  }

  if (content) {
    content.forEach(childNode => {
      populateDefaultNodeAttributes(childNode);
    });
  }

  return node;
}

import { DOMSerializer, Node } from "prosemirror-model"

import { schema } from "lib/ample-editor/schema"

// --------------------------------------------------------------------------
export default function snippetFromNoteReference({ node }) {
  if (!(node instanceof Node)) {
    node = Node.fromJSON(schema, node);
  }

  const domSerializer = DOMSerializer.fromSchema(schema);

  let domNode;
  if (node && node.type.name === "doc") {
    // This is a bit roundabout, since the parent node can be `type: "doc"`, which can't be serialized by
    // DOMSerializer#serializerNode
    const documentFragment = domSerializer.serializeFragment(node.content);
    domNode = document.createElement("div");
    domNode.appendChild(documentFragment);
  } else {
    domNode = domSerializer.serializeNode(node);
  }

  return domNode.outerHTML;
}

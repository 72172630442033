import { cloneDeep } from "lodash"
import { Step, StepMap, StepResult } from "prosemirror-transform"

// --------------------------------------------------------------------------
export function setDocumentStorageTransform(transform, name, value) {
  return transform.step(new UpdateDocumentStorageStep(name, value));
}

// --------------------------------------------------------------------------
export default class UpdateDocumentStorageStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(_schema, json) {
    if (typeof(json.name) !== "string") {
      throw new RangeError("Invalid input for UpdateDocumentStorageStep.fromJSON");
    }

    return new UpdateDocumentStorageStep(json.name, json.value);
  }

  // --------------------------------------------------------------------------
  constructor(name, value) {
    super();

    this.name = name;
    this.value = value;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const attrs = Object.create(null);
    // eslint-disable-next-line guard-for-in
    for (const name in doc.attrs) {
      let value = doc.attrs[name];

      if (name === "storage") {
        value = cloneDeep(value) || {};
        value[this.name] = this.value;
      }

      attrs[name] = value;
    }

    const updated = doc.type.create(attrs, doc.content, doc.marks);
    return StepResult.ok(updated);
  }

  // --------------------------------------------------------------------------
  getMap() {
    return StepMap.empty;
  }

  // --------------------------------------------------------------------------
  invert(doc) {
    return new UpdateDocumentStorageStep(this.name, doc.attrs.storage[this.name]);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return { stepType: "updateDocumentStorageStep", name: this.name, value: this.value };
  }
}
Step.jsonID("updateDocumentStorageStep", UpdateDocumentStorageStep);

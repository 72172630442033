import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import { IconButton } from "@rmwc/icon-button"

import { targetFromURL } from "lib/ample-editor/lib/link-util"
import snippetFromNoteReference from "lib/ample-editor/components/referencing-notes/snippet-from-note-reference"
import TagIcon from "lib/ample-editor/components/tag-icon"

// --------------------------------------------------------------------------
function NoteTags({ tags }) {
  return (
    <div className="note-tags">
      {
        tags.map(({ html, text, ...tagMetadata }) => (
          <div className="note-tag" key={ text }>
            <TagIcon { ...tagMetadata } />
            <span className="note-tag-text">
              { html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : text }
            </span>
          </div>
        ))
      }
    </div>
  );
}

// --------------------------------------------------------------------------
function Snippets({ references, renderAction }) {
  const snippets = useMemo(() => (references || []).map(snippetFromNoteReference), [ references ]);

  if (references) {
    return snippets.map((snippet, index) => {
      return (
        <div className="snippet-container" key={ `${ snippet }-${ index }` }>
          <div className="snippet ProseMirror" dangerouslySetInnerHTML={ { __html: snippet } } />
          { renderAction ? renderAction(index) : null }
        </div>
      );
    });
  } else {
    return (
      <div className="snippet loader-container">
        <CircularProgress size="small" />
      </div>
    );
  }
}

// --------------------------------------------------------------------------
function ToggleCollapsedButton({ collapsed, toggleNoteCollapsed, url }) {
  const onClick = useCallback(
    () => { toggleNoteCollapsed(url); },
    [ toggleNoteCollapsed, url ]
  );

  return (
    <IconButton
      className="note-expander"
      icon={ collapsed ? "keyboard_arrow_down" : "keyboard_arrow_up" }
      onClick={ onClick }
    />
  )
}

// --------------------------------------------------------------------------
export default function ReferencingNote(props) {
  const { collapsed, iconName, name, references, renderSnippetAction, tags, toggleNoteCollapsed, url } = props;

  let className = "referencing-note";
  if (collapsed) className += " collapsed";

  return (
    <div className={ className }>
      <div className="note-header">
        <div className="note-metadata">
          <a className="note-link" href={ url } target={ targetFromURL(url) }>
            <i className="note-icon material-icons">{ iconName || "description" }</i>
            <span className="note-name">{ name }</span>
          </a>
          { !collapsed && tags && tags.length > 0 ? (<NoteTags tags={ tags } />) : null }
        </div>
        {
          toggleNoteCollapsed
            ? (
              <ToggleCollapsedButton
                collapsed={ collapsed }
                toggleNoteCollapsed={ toggleNoteCollapsed }
                url={ url }
              />
            )
            : null
        }
      </div>

      { collapsed ? null : (<Snippets references={ references } renderAction={ renderSnippetAction } />) }
    </div>
  );
}

ReferencingNote.propTypes = {
  collapsed: PropTypes.bool,
  iconName: PropTypes.string,
  name: PropTypes.string,
  references: PropTypes.array,
  renderSnippetAction: PropTypes.func,
  tags: PropTypes.array,
  toggleNoteCollapsed: PropTypes.func,
  url: PropTypes.string,
};

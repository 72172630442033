import { Fragment, Slice } from "prosemirror-model"

// --------------------------------------------------------------------------
// Converts plain text that can contain newlines to a slice with hard breaks
export default function sliceFromText(schema, text) {
  const nodes = [];

  const lines = text.split(/\r\n|\r|\n/);
  lines.forEach((line, index) => {
    if (line.length > 0) nodes.push(schema.text(line));
    if (index < lines.length - 1) nodes.push(schema.nodes.hard_break.create());
  });

  return new Slice(Fragment.from(nodes), 0, 0);
}

import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react"
import { IconButton } from "@rmwc/icon-button"
import Tippy from "@tippyjs/react"

import DateTimeInput from "lib/ample-editor/components/task-detail/date-time-input"
import { hasModifierKey, hasModifierKeyExceptShift } from "lib/ample-editor/lib/event-util"
import { formatDateTime } from "lib/ample-util/date"

// --------------------------------------------------------------------------
function AdjustDateMenu({ cancel, initialDate, label, save }, ref) {
  const [ date, setDate ] = useState(initialDate);

  useImperativeHandle(ref, () => ({
    // This is for keydown events when the editor is focused
    handleKeyDown(event) {
      switch (event.key) {
        case "Escape":
          if (!hasModifierKey(event)) {
            cancel();
            return true;
          }
          break;

        default:
          break;
      }

      return false;
    }
  }));

  const containerRef = useRef();

  const isFocused = useCallback(
    () => {
      const { current: container } = containerRef;
      return container && document.activeElement && container.contains(document.activeElement);
    },
    []
  );

  const onEnterIconClick = useCallback(() => { save(date); }, [ date, save ]);

  const onKeyDown = useCallback(
    event => {
      switch (event.key) {
        case "Enter":
          if (!hasModifierKey(event)) {
            // It's vital that the event is stopped from all further propagation, or ProseMirror will see it (in some
            // browsers, namely Chrome) as a mutation event coming out of the check-list-item's DOM node. It's unclear
            // how or why this enter translates to that event, but that results in any content after a hard break in
            // the check-list-item being removed.
            event.stopPropagation();
            event.preventDefault();

            save(date);
          }
          break;

        case "Escape":
          if (!hasModifierKey(event)) {
            cancel();
          }
          break;

        case "Tab": {
          const firstInputFocused = () => isFocused() && document.activeElement.classList.contains("date-input");
          const lastInputFocused = () => isFocused() && document.activeElement.classList.contains("text-input");

          if (!hasModifierKeyExceptShift(event) && (event.shiftKey ? firstInputFocused() : lastInputFocused())) {
            event.preventDefault();
            cancel();
          }
          break;
        }

        default:
          break;
      }
    },
    [ cancel, date, save ]
  );

  let helperText = null;
  if (date) {
    helperText = (
      <div className="helper-text">
        { formatDateTime(date) }
      </div>
    );
  }

  return (
    <div className="adjust-date-menu sub-menu" ref={ containerRef }>
      <div className="label">{ label }</div>

      <DateTimeInput
        autoFocus
        adjustPopupType="none"
        date={ date }
        onDateChange={ setDate }
        onKeyDown={ onKeyDown }
        selectExistingText
        setTimeOnChange
      />

      <Tippy content="Press enter to change date" delay={ 150 }>
        <span className="enter-icon-button-container">
          <IconButton icon="keyboard_return" onClick={ onEnterIconClick } />
        </span>
      </Tippy>

      { helperText }
    </div>
  );
}

// eslint-disable-next-line no-func-assign
AdjustDateMenu = forwardRef(AdjustDateMenu);

AdjustDateMenu.propTypes = {
  cancel: PropTypes.func.isRequired,
  initialDate: PropTypes.instanceOf(Date),
  label: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired
};

export default AdjustDateMenu;

import { Node } from "prosemirror-model"

import descriptionSchema from "lib/ample-editor/components/rich-footnote/description-schema"
import markdownParser from "lib/ample-editor/lib/markdown-parser"
import markdownSerializer from "lib/ample-editor/lib/markdown-serializer"
import {
  descriptionDocumentFromValue,
  valueFromDescriptionDocumentContent,
} from "lib/ample-editor/lib/rich-footnote-util"

// --------------------------------------------------------------------------
export function pluginLinkFromLink(linkNode) {
  const { attrs: { description, href } } = linkNode;

  let markdownDescription = null;
  if (description) {
    const descriptionDocument = descriptionDocumentFromValue(description);

    markdownDescription = markdownSerializer.serialize(descriptionDocument);
  }

  return {
    description: markdownDescription,
    href,
  };
}

// --------------------------------------------------------------------------
export function validUpdatesFromPluginLinkUpdates(updates) {
  const validUpdates = {};

  if ("description" in updates) {
    const { description: markdownDescription } = updates;
    const newDocument = markdownParser.parse(markdownDescription);
    const descriptionDocumentContent = newDocument.toJSON();

    // Make sure this is a valid description document
    Node.fromJSON(descriptionSchema, descriptionDocumentContent);

    validUpdates.description = valueFromDescriptionDocumentContent(descriptionDocumentContent);
  }

  if ("href" in updates) {
    const { href } = updates;
    validUpdates.href = href.toString();
  }

  return validUpdates;
}

import { Fragment, Node, Slice } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"
import { replaceStep } from "prosemirror-transform"

import descriptionSchema from "lib/ample-editor/components/rich-footnote/description-schema"
import { isTasksSchema } from "lib/ample-editor/components/tasks-editor/tasks-schema"
import { valueFromDescriptionDocumentContent } from "lib/ample-editor/lib/rich-footnote-util"
import { setIsNewLink } from "lib/ample-editor/plugins/link-plugin"

// --------------------------------------------------------------------------
// (0) doc -> (1) tasks_group -> (2) check_list_item
const CHECK_LIST_ITEM_DEPTH = 2;

// --------------------------------------------------------------------------
// This is Fragment#toJSON, with images pulled out separately
function extractFragment(fragment, content, mediaNodeRef) {
  if (!fragment.size) return true;

  const { content: fragmentContent } = fragment;
  for (let i = 0; i < fragmentContent.length; i++) {
    const node = fragmentContent[i];
    if (!extractNode(node, content, mediaNodeRef)) return false;
  }

  return true;
}

// --------------------------------------------------------------------------
// This is Node#toJSON, with images pulled out separately
function extractNode(node, parentContent, mediaNodeRef) {
  if (node.toJSON === Node.prototype.toJSON) {
    const type = node.type.name;

    if (type === "image" || type === "video") {
      if (mediaNodeRef.current) return false;
      mediaNodeRef.current = node;
      return true;
    }

    const nodeJSON = { type };

    // This is a rather oblique way to check if the attributes are `emptyAttrs`, which is a single (global) instance that
    // has no properties (i.e. `Object.create(null)`) so we can assume any object with properties isn't that (and don't
    // have the reference here to test it anyway, though ProseMirror source - where this code comes from - does have it,
    // :shrug:).
    // eslint-disable-next-line guard-for-in
    for (const _ in node.attrs) {
      nodeJSON.attrs = node.attrs;
      break;
    }

    if (node.marks.length) nodeJSON.marks = node.marks.map(mark => mark.toJSON());

    const content = [];
    if (!extractFragment(node.content, content, mediaNodeRef)) return false;
    if (content.length) nodeJSON.content = content;

    parentContent.push(nodeJSON);
  } else {
    // TextNode has a different `toJSON` (and doesn't contain images so we don't care)
    parentContent.push(node.toJSON());
  }

  return true;
}

// --------------------------------------------------------------------------
function extractSelection(schema, selectionSlice) {
  const descriptionDocumentContent = { type: "doc", content: [] };
  const mediaNodeRef = { current: null };

  // We're unrolling and inlining what happens in Slice#toJSON here so we can pull out images and
  // stop if we hit too many images, both as optimally as possible.

  // ProseMirror likes to call things "content" (who doesn't?):
  //  - Selection#content() returns a Slice
  //  - Slice#content returns a Fragment
  //  - Fragment#content is an array of Nodes
  const { content: selectionFragment } = selectionSlice;

  if (!extractFragment(selectionFragment, descriptionDocumentContent.content, mediaNodeRef)) {
    return false;
  }

  unwrapCheckListItemSlice(descriptionDocumentContent, schema, selectionSlice);

  try {
    Node.fromJSON(descriptionSchema, descriptionDocumentContent);
  } catch (_error) {
    // e.g. RangeError: Unknown node type: check_list_item
    return null;
  }

  let media = null;
  const { current: mediaNode } = mediaNodeRef;
  if (mediaNode && mediaNode.attrs) {
    const { attrs: { src, text } } = mediaNode;
    media = { type: `${ mediaNode.type.name }/*`, url: src };
    if (text) media.text = text;
  }

  return {
    descriptionDocumentContent,
    media,
  };
}

// --------------------------------------------------------------------------
// If we're extracting some partial content inside a check-list-item, the top-level node in the selection's slice
// will be a check-list-item (or the tasks_gorup in tasks editors), but we'd like to support just extracting the
// selection itself.
function unwrapCheckListItemSlice(descriptionDocumentContent, schema, selectionSlice) {
  if (descriptionDocumentContent.content.length !== 1) return;
  if (selectionSlice.openEnd !== selectionSlice.openStart) return;

  if (isTasksSchema(schema)) {
    // Handle extra tasks_group node in tasks schema
    if (selectionSlice.openStart !== CHECK_LIST_ITEM_DEPTH + 1) return;

    const { content: [ tasksGroup ] } = descriptionDocumentContent;

    if (tasksGroup.type !== "tasks_group" || tasksGroup.content.length !== 1) return;

    const { content: [ checkListItem ] } = tasksGroup;

    descriptionDocumentContent.content = checkListItem.content;
  } else {
    if (selectionSlice.openStart !== CHECK_LIST_ITEM_DEPTH) return;

    const { content: [ checkListItem ] } = descriptionDocumentContent;
    if (checkListItem.type !== "check_list_item") return;

    descriptionDocumentContent.content = checkListItem.content;
  }
}

// --------------------------------------------------------------------------
export default function extractToLinkDescription(state, dispatch) {
  const { selection, selection: { from, empty, to }, schema } = state;
  if (empty) return false;

  const selectionSlice = selection.content();

  // There are two considerations here:
  //   1. Would the selection be valid content in a RF description document?
  //   2. Can we replace the selection with a link?

  const linkAttributes = extractSelection(schema, selectionSlice);
  if (!linkAttributes) return false;

  if (dispatch) {
    const { descriptionDocumentContent, media } = linkAttributes;
    const description = valueFromDescriptionDocumentContent(descriptionDocumentContent);
    const linkNode = schema.nodes.link.create({ description, media });

    const transform = state.tr;
    selection.replaceWith(transform, linkNode);
    transform.setSelection(TextSelection.create(transform.doc, from + 1));
    setIsNewLink(transform, true);

    dispatch(transform);

    return true;
  } else {
    // Just check if we _could_ replace with a link
    const linkNode = schema.nodes.link.create();
    return !!replaceStep(state.doc, from, to, new Slice(Fragment.from(linkNode)));
  }
}

import { dequal } from "dequal/lite"
import { truncate } from "lodash"
import tippy from "tippy.js"

import { deviceSupportsHover } from "lib/ample-editor/lib/client-info"
import { targetFromURL } from "lib/ample-editor/lib/link-util"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import { getOpenLinkPos, setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import { amplenoteParamsFromTaskLinkNode } from "lib/ample-editor/util/is-task-link-node"
import LinkView from "lib/ample-editor/views/link-view"
import extractNodeContent from "lib/ample-util/extract-node-content"
import { TASK_RELATION, VECTOR_ICON_NAME_BY_TASK_RELATION } from "lib/ample-util/task-url"
import { checkListItemFromTask, isDoneFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const DEFAULT_VECTOR_ICON_NAME = VECTOR_ICON_NAME_BY_TASK_RELATION[TASK_RELATION.CONNECTED];

// --------------------------------------------------------------------------
function createVectorIcon() {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("class", "vector-icon");
  svg.setAttribute("viewBox", "0 0 24 24");

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", VECTOR_ICON_PATHS[DEFAULT_VECTOR_ICON_NAME]);

  svg.appendChild(path);

  return svg;
}

// --------------------------------------------------------------------------
// Styles are shared with LinkView as styling is based on the node and this is rendering
// a `link` node that LinkView would otherwise render.
export default class TaskLinkView extends LinkView {
  _lastContent = null;
  _taskContentDOM = null;

  // --------------------------------------------------------------------------
  constructor(editorView, getPos, node, decorations) {
    super(editorView, getPos, node, decorations, { skipInitialUpdate: true });

    this._onTaskLinkViewClick = this._onTaskLinkViewClick.bind(this, editorView, getPos);

    this.dom.classList.add("task-link");

    // Replace LinkView's click handling with our own
    this.dom.removeEventListener("click", this._onClick);
    this.dom.addEventListener("click", this._onTaskLinkViewClick);

    this.icon.appendChild(createVectorIcon());

    // Handle all child content ourselves, so we can show a controlled preview of the task content
    this.dom.removeChild(this.contentDOM);
    this.contentDOM = null;

    if (deviceSupportsHover) {
      tippy(this.dom, {
        animation: "shift-away-subtle",
        content: "Show linked task details",
        delay: [ 500, 150 ],
        ignoreAttributes: true,
      });
    }

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy(editorView) {
    if (this.dom && this.dom._tippy) this.dom._tippy.destroy();

    super.destroy(editorView);
  }

  // --------------------------------------------------------------------------
  ignoreMutation(_event) {
    return true; // We'll handle all the content updates ourselves
  }

  // --------------------------------------------------------------------------
  update(editorView, getPos, node) {
    if (node.type.name !== "link") return false;

    const { props: { hostApp: { fetchTask } }, state } = editorView;

    const amplenoteParams = amplenoteParamsFromTaskLinkNode(node, state, getPos);
    if (!amplenoteParams) return false;

    const { attrs: { href } } = node;

    this.dom.href = href;
    this.dom.target = targetFromURL(href);
    this.dom.setAttribute("data-href", href);

    this.icon.href = href;
    this.icon.target = this.dom.target;

    // This relies on the link-plugin adding a unique decoration when the state changes, so this `update` is called
    const openLinkPos = getOpenLinkPos(state);
    if (openLinkPos === getPos()) {
      this.dom.classList.add("open");
    } else {
      this.dom.classList.remove("open");
    }

    const { task: { uuid: taskUUID }, taskParams } = amplenoteParams;
    const taskRelation = (taskParams ? taskParams.relation : null) || TASK_RELATION.CONNECTED;

    const path = this.icon.querySelector("path");
    if (path) {
      const vectorIconName = VECTOR_ICON_NAME_BY_TASK_RELATION[taskRelation];
      path.setAttribute("d", VECTOR_ICON_PATHS[vectorIconName]);
    }

    if (taskRelation === TASK_RELATION.MIRRORED || taskRelation === TASK_RELATION.MIRRORING) {
      this.dom.classList.add("icon-only");

      this._lastContent = null;

      if (this._taskContentDOM) {
        this.dom.removeChild(this._taskContentDOM);
        this._taskContentDOM = null;
      }
    } else {
      this.dom.classList.remove("icon-only");

      if (fetchTask && taskUUID) {
        fetchTask(taskUUID).then(task => {
          if (!this._destroyed) this._renderTaskContent(task);
        });
      }
    }

    return true;
  }

  // --------------------------------------------------------------------------
  _onTaskLinkViewClick(editorView, getPos, event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    editorView.dispatch(setOpenLinkPosition(editorView.state.tr, getPos()));
  }

  // --------------------------------------------------------------------------
  _renderTaskContent(task) {
    const { content } = task || {};

    if (!dequal(content, this._lastContent)) {
      this._lastContent = content;

      const checkListItem = checkListItemFromTask(task);

      // Note that we don't want to pass attributes, as the attributes can affect whether the text content is visible
      const { text } = extractNodeContent({ content: checkListItem.content, type: checkListItem.type });

      if (text) {
        const truncatedText = truncate(text, { length: 50 });
        const textNode = document.createTextNode(truncatedText);
        if (this._taskContentDOM) {
          this.dom.replaceChild(textNode, this._taskContentDOM)
        } else {
          this.dom.appendChild(textNode);
        }
        this._taskContentDOM = textNode;
      } else {
        this.dom.classList.add("icon-only");

        if (this._taskContentDOM) {
          this.dom.removeChild(this._taskContentDOM);
          this._taskContentDOM = null;
        }
      }
    }

    if (task && isDoneFromTask(task)) {
      this.dom.classList.add("done");
    } else {
      this.dom.classList.remove("done");
    }
  }
}

import {
  chainCommands,
  createParagraphNear,
  deleteSelection,
  exitCode,
  joinBackward,
  joinForward,
  liftEmptyBlock,
  selectNodeBackward,
  selectNodeForward,
  splitBlock,
  wrapIn,
} from "prosemirror-commands"
import { keydownHandler } from "prosemirror-keymap"
import { Plugin, Selection, TextSelection } from "prosemirror-state"

import { isApplePlatform, isWindows } from "lib/ample-editor/lib/client-info"
import { backspaceOutOfCodeBlock, swallowCodeBlockEnter } from "lib/ample-editor/lib/code-block-commands"
import { buildSetBlockType, insertHardBreak, toggleMark } from "lib/ample-editor/lib/commands"
import { buildToggleLink } from "lib/ample-editor/lib/link-commands"
import {
  buildCompleteListItems,
  buildToggleBulletItemCheckListItem,
  joinBackwardToListItem,
  maybeIndentListItem,
  maybeOutdentListItem,
  moveListItemDown,
  moveListItemUp,
  splitListItem,
} from "lib/ample-editor/lib/list-item-commands"
import { buildDeleteParagraphForward } from "lib/ample-editor/commands/paragraph-commands"

// --------------------------------------------------------------------------
// The selectAll command in prosemirror-commands creates an AllSelection, but that's not really what people think
// of when the use mod-a, since it isn't treated like a normal text selection (e.g. up/down arrows don't collapse the
// selection back down). Instead of using
function selectAllText(state, dispatch) {
  if (dispatch) {
    const { doc } = state;
    const { $from } = Selection.atStart(doc);
    const { $to } = Selection.atEnd(doc);

    dispatch(state.tr.setSelection(TextSelection.between($from, $to)));
  }

  return true;
}

// --------------------------------------------------------------------------
function swallowInput(_state, _dispatch) {
  return true;
}

// --------------------------------------------------------------------------
function buildKeymap(schema) {
  const keys = {};
  function bind(key, cmd) {
    keys[key] = cmd;
  }

  const backspace = chainCommands(
    deleteSelection,
    backspaceOutOfCodeBlock,
    joinBackward,
    joinBackwardToListItem,
    selectNodeBackward
  );
  bind("Backspace", backspace);
  bind("Mod-Backspace", backspace);

  const del = chainCommands(
    deleteSelection,
    buildDeleteParagraphForward(schema.nodes.paragraph),
    joinForward,
    selectNodeForward
  );
  bind("Delete", del);
  bind("Mod-Delete", del);

  bind("Mod-a", selectAllText);

  bind("Mod-b", toggleMark(schema.marks.strong));
  bind("Mod-h", toggleMark(schema.marks.highlight));
  bind("Mod-i", toggleMark(schema.marks.em));
  bind("Mod-`", toggleMark(schema.marks.code));

  bind("Mod-k", chainCommands(
    buildToggleLink({ createMarkdownLinks: true }),
    swallowInput,
  ));

  bind("Ctrl->", wrapIn(schema.nodes.blockquote));

  bind("Ctrl-Space", buildCompleteListItems());

  // On macOS we'll also bind this to ctrl-enter, as that's a common convention
  const modEnterCommand = chainCommands(
    exitCode,
    buildToggleBulletItemCheckListItem(),
  );
  bind("Mod-Enter", modEnterCommand);

  bind("Shift-Enter", chainCommands(
    insertHardBreak(schema.nodes.hard_break)
  ));

  bind("Enter", chainCommands(
    splitListItem,
    createParagraphNear,
    liftEmptyBlock,
    swallowCodeBlockEnter,
    splitBlock,
  ));

  bind("Tab", maybeIndentListItem);
  bind("Shift-Tab", maybeOutdentListItem);
  bind("Mod-Shift-ArrowDown", moveListItemDown);
  bind("Mod-Shift-ArrowUp", moveListItemUp);

  bind("Shift-Ctrl-\\", buildSetBlockType(schema.nodes.code_block));

  if (!isWindows) {
    bind("Shift-Ctrl-0", buildSetBlockType(schema.nodes.paragraph));
    for (let i = 1; i <= 6; i++) {
      bind("Shift-Ctrl-" + i, buildSetBlockType(schema.nodes.heading, { level: i }));
    }
  }

  const hr = schema.nodes.horizontal_rule;
  bind("Mod-_", (state, dispatch) => {
    dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
    return true
  });

  // Platform-specific bindings
  if (isApplePlatform) {
    bind("Ctrl-Enter", modEnterCommand);
    bind("Ctrl-h", keys["Backspace"]);
    bind("Alt-Backspace", keys["Mod-Backspace"]);
    bind("Ctrl-d", keys["Delete"]);
    bind("Ctrl-Alt-Backspace", keys["Mod-Delete"]);
    bind("Alt-Delete", keys["Mod-Delete"]);
    bind("Alt-d", keys["Mod-Delete"]);
  }

  return keys;
}

// --------------------------------------------------------------------------
export function createDescriptionKeymapPlugin(schema) {
  const pluginProps = {
    handleKeyDown: keydownHandler(buildKeymap(schema)),
  };

  // As of Windows 10, Ctrl + Shift + <number> is the default for switching the keyboard language. Even if the user
  // has no alternative keyboard languages configured, we won't get a keydown event for Ctrl + Shift + 0 - each
  // additional language configured blocks a subsequent number's keydown event.
  // The keyup event, however, _does_ fire, so we need to watch that on windows for any Ctrl + Shift + <number> combos
  // to be detected.
  if (isWindows) {
    const mapping = {
      "Shift-Ctrl-0": buildSetBlockType(schema.nodes.paragraph),
    };

    for (let i = 1; i <= 6; i++) {
      mapping["Shift-Ctrl-" + i] = buildSetBlockType(schema.nodes.heading, { level: i });
    }

    pluginProps.handleKeyUp = keydownHandler(mapping);
  }

  return new Plugin({ props: pluginProps });
}

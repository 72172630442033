import PropTypes from "prop-types"
import React, { useContext, useMemo } from "react"
import { useAsyncEffect } from "@react-hook/async"
import { Button } from "@rmwc/button"
import { CircularProgress } from "@rmwc/circular-progress"

import TasksEditor from "lib/ample-editor/components/tasks-editor"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { hasModKeyOnly } from "lib/ample-editor/lib/event-util"
import { amplenoteParamsFromURL, urlFromAmplenoteParams } from "lib/ample-util/amplenote-area"
import { formatDate } from "lib/ample-util/date"
import { urlFromNoteParams } from "lib/ample-util/note-url"
import { TASK_RELATION } from "lib/ample-util/task-url"
import { checkListItemFromTask, iconNameFromPriority } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function noop() {
  // NO-OP
}

// --------------------------------------------------------------------------
function taskRelationFromAmplenoteParams(amplenoteParams) {
  const { taskParams } = amplenoteParams || {};
  return (taskParams ? taskParams.relation : null) || TASK_RELATION.CONNECTED;
}

// --------------------------------------------------------------------------
function useNoteURLFromTask(task) {
  const { note, uuid: taskUUID } = task || {};

  return useMemo(
    () => {
      if (!note || !taskUUID) return null;

      const noteURL = urlFromNoteParams(note);

      // Add highlightTaskUUID to the note URL
      const amplenoteParams = amplenoteParamsFromURL(noteURL);
      if (amplenoteParams && amplenoteParams.note) {
        amplenoteParams.note = { ...amplenoteParams.note, highlightTaskUUID: taskUUID };
      }

      return urlFromAmplenoteParams(amplenoteParams) || noteURL;
    },
    [ note, taskUUID ]
  );
}

// --------------------------------------------------------------------------
function useTasksWithGroupFromTask(task) {
  return useMemo(
    () => {
      if (!task) return [];

      const { note } = task;

      const checkListItem = {
        ...checkListItemFromTask(task),
        note: { name: note.name, url: urlFromNoteParams(note) },
      };

      return [ { groupId: "task", tasks: [ checkListItem ] } ];
    },
    [ task ]
  );
}

// --------------------------------------------------------------------------
function ActionButtons({ close, removeLink, task, taskRelation }) {
  const { applyNoteContentActions, openNoteLink } = useContext(HostAppContext) || {};

  const noteURLWithHighlight = useNoteURLFromTask(task);

  const onDeleteMirroredTaskClick = () => {
    close();

    const { note, uuid: taskUUID } = task || {};
    if (applyNoteContentActions && note && taskUUID) {
      const { localUUID, remoteUUID } = note;
      applyNoteContentActions({ localUUID, remoteUUID }, [ { type: "REMOVE_NODES", uuids: [ taskUUID ] } ]);
      removeLink();
    }
  };

  const onOpenTaskClick = event => {
    if (openNoteLink) {
      event.preventDefault();

      openNoteLink(noteURLWithHighlight, { openInSidebar: hasModKeyOnly(event) });
    }
  };

  const onRemoveLinkClick = () => {
    close();
    removeLink();
  };

  return (
    <div className="action-buttons">
      <Button
        disabled={ !noteURLWithHighlight }
        href={ noteURLWithHighlight }
        label="Go to task"
        onClick={ onOpenTaskClick }
        tag="a"
      />

      {
        taskRelation === TASK_RELATION.MIRRORED && task !== null
          ? (
            <Button
              className="delete-link-button"
              disabled={ !task }
              label="Delete mirrored task"
              onClick={ onDeleteMirroredTaskClick }
            />
          )
          : (
            <Button
              disabled={ typeof(task) === "undefined" }
              label="Remove link"
              onClick={ onRemoveLinkClick }
            />
          )
      }

      <Button className="close-button" label="Done" onClick={ close } />
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskLinkDetailsBenefiting({ task }) {
  return (
    <div className="task-link-details">
      <div className="heading">Beneficiary task</div>
      <div className="description">Task is helped by this task's completion</div>

      <TaskPreview task={ task } />
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskLinkDetailsConnected({ task }) {
  return (
    <div className="task-link-details">
      <div className="heading">Connected task</div>
      <div className="description">Tasks are linked, without any other effect</div>

      <TaskPreview task={ task } />
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskLinkDetailsMirrored({ task }) {
  return (
    <div className="task-link-details">
      <div className="heading">Mirrored task</div>
      <div className="description">Concluding either task will apply to both</div>

      <TaskPreview task={ task } />
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskLinkDetailsWaiting({ task }) {
  return (
    <div className="task-link-details">
      <div className="heading">Waiting task</div>
      <div className="description">Task is hidden until this task is complete</div>

      <TaskPreview isHiddenTask task={ task } />
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskPreview({ isHiddenTask, task }) {
  const hostApp = useContext(HostAppContext);
  const editorProps = useMemo(() => ({ hostApp }), [ hostApp ]);
  const tasksWithGroup = useTasksWithGroupFromTask(task);

  let content;
  if (task) {
    content = (
      <React.Fragment>
        <TasksEditor
          dispatchChanges={ noop }
          editorProps={ editorProps }
          hideToolbar
          includeSourceNotes
          readonly
          tasksWithGroup={ tasksWithGroup }
        />

        <TaskPreviewDetails task={ task }/>
      </React.Fragment>
    );
  } else if (task === null) {
    content = (<div className="missing-task-message">Linked task not found</div>);
  } else {
    content = (<div className="loading-indicator-container"><CircularProgress size="medium"/></div>);
  }

  return (
    <div className={ `task-preview ${ isHiddenTask ? "hidden-task" : "" }` }>
      { content }
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskPreviewDetails({ task }) {
  const { openNoteLink } = useContext(HostAppContext) || {};

  const noteURLWithHighlight = useNoteURLFromTask(task);

  const onClick = event => {
    if (openNoteLink) {
      event.preventDefault();

      openNoteLink(noteURLWithHighlight, { openInSidebar: hasModKeyOnly(event) });
    }
  };

  const { due, flags, note } = task;

  const details = [];

  if (due) {
    details.push(
      <span className="task-preview-detail due" key="due">
        <i className="material-icons">event_available</i>
        <span className="text">Starts { formatDate(new Date(due * 1000)) }</span>
      </span>
    );
  }

  const priorityIconName = iconNameFromPriority(flags)
  if (priorityIconName) {
    details.push(
      <span className="task-preview-detail priority" key="priority">
        <i className="material-icons">{ priorityIconName }</i>
      </span>
    );
  }

  const { icon, name } = note;

  return (
    <div className="task-preview-details">
      { details }
      <a className="note-link" href={ noteURLWithHighlight } onClick={ onClick }>
        <i className="material-icons">{ icon || "description" }</i>
        <span className="text">{ name }</span>
      </a>
    </div>
  );
}

// --------------------------------------------------------------------------
export default function TaskLinkPopup({ amplenoteParams, close, removeLink }) {
  const { task: { uuid: linkedTaskUUID } } = amplenoteParams;

  const hostApp = useContext(HostAppContext);
  const { fetchTask } = hostApp || {};
  const { value: task } = useAsyncEffect(
    () => fetchTask ? fetchTask(linkedTaskUUID) : null,
    [ linkedTaskUUID ]
  );

  const taskRelation = taskRelationFromAmplenoteParams(amplenoteParams);

  let taskLinkDetails;
  switch (taskRelation) {
    case TASK_RELATION.BENEFITING:
      taskLinkDetails = (<TaskLinkDetailsBenefiting task={ task } />);
      break;

    default:
    case TASK_RELATION.CONNECTED:
      taskLinkDetails = (<TaskLinkDetailsConnected task={ task } />);
      break;

    case TASK_RELATION.MIRRORED:
    case TASK_RELATION.MIRRORING:
      taskLinkDetails = (<TaskLinkDetailsMirrored task={ task } />);
      break;

    case TASK_RELATION.WAITING:
      taskLinkDetails = (<TaskLinkDetailsWaiting task={ task } />);
      break;
  }

  return (
    <div className="task-link-popup">
      { taskLinkDetails }

      <ActionButtons
        close={ close }
        removeLink={ removeLink }
        task={ task }
        taskRelation={ taskRelation }
      />
    </div>
  );
}

TaskLinkPopup.propTypes = {
  amplenoteParams: PropTypes.shape({
    task: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
    taskParams: PropTypes.shape({
      relation: PropTypes.oneOf(Object.values(TASK_RELATION)),
    }),
  }).isRequired,
  close: PropTypes.func.isRequired,
  removeLink: PropTypes.func.isRequired,
};

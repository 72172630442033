/* eslint-disable global-require */
import { isTaskLinkNode } from "lib/ample-editor/util/is-task-link-node"

// --------------------------------------------------------------------------
// We want to use dynamic requires that happen _later_ here, as this is both a dependency of AmpleEditor and ends
// up requiring TasksEditor, for which it is also a dependency. The circular imports are okay except for a couple
// cases where an inheriting class ends up getting imported before the parent class.
export default function nodeViews() {
  const AttachmentView = require("lib/ample-editor/views/attachment-view").default;
  const BulletListItemView = require("lib/ample-editor/views/bullet-list-item-view").default;
  const CodeBlockView = require("lib/ample-editor/views/code-block-view").default;
  const CheckListItemView = require("lib/ample-editor/views/check-list-item-view").default;
  const EmbedView = require("lib/ample-editor/views/embed-view").default;
  const HeadingView = require("lib/ample-editor/views/heading-view").default;
  const ImageView = require("lib/ample-editor/views/image-view").default;
  const LinkView = require("lib/ample-editor/views/link-view").default;
  const NumberListItemView = require("lib/ample-editor/views/number-list-item-view").default;
  const TableCellView = require("lib/ample-editor/views/table-cell-view").default;
  const TableView = require("lib/ample-editor/views/table-view").default;
  const TaskLinkView = require("lib/ample-editor/views/task-link-view").default;
  const VideoView = require("lib/ample-editor/views/video-view").default;

  return {
    attachment: (node, editorView, getPos, decorations) => new AttachmentView(node, editorView, getPos, decorations),
    bullet_list_item: (node, editorView, getPos) => new BulletListItemView(editorView, getPos, node),
    check_list_item: (node, editorView, getPos) => new CheckListItemView(editorView, getPos, node),
    code_block: (node, editorView, getPos) => new CodeBlockView(node, editorView, getPos),
    embed: (node, editorView, getPos) => new EmbedView(node, editorView, getPos),
    heading: (node, editorView, getPos) => new HeadingView(node, editorView, getPos),
    image: (node, editorView, getPos, decorations) => new ImageView(node, editorView, getPos, decorations),
    link: (node, editorView, getPos, decorations) => {
      if (isTaskLinkNode(node, editorView.state, getPos)) {
        return new TaskLinkView(editorView, getPos, node, decorations);
      } else {
        return new LinkView(editorView, getPos, node, decorations);
      }
    },
    number_list_item: (node, editorView, getPos) => new NumberListItemView(editorView, getPos, node),
    table: (node, editorView, getPos) => new TableView(node, editorView, getPos),
    table_cell: (node, editorView, getPos) => new TableCellView(node, editorView, getPos),
    video: (node, editorView, getPos) => new VideoView(node, editorView, getPos),
  };
}

// --------------------------------------------------------------------------
export function nodeViewsForRender() {
  // code_block's view loads in asynchronous components that aren't accommodated by renderer as of Nov 2022
  // eslint-disable-next-line camelcase
  const { code_block, ...viewsForRender } = nodeViews();
  return viewsForRender;
}

/* eslint-disable */

// --------------------------------------------------------------------------
// Inlined from https://github.com/nodeca/probe-image-size - which has large dependencies like "got" that have issues
// compiling, require webpacker config changes (adding "json" to resolver extensions), and are not useful to us.
// --------------------------------------------------------------------------

const sliceEq = (src, start, dest) => {
  for (let i = start, j = 0; j < dest.length;) {
    if (src[i++] !== dest[j++]) return false;
  }
  return true;
};

const str2arr = (str, format) => {
  const arr = [];
  let i = 0;

  if (format && format === 'hex') {
    while (i < str.length) {
      arr.push(parseInt(str.slice(i, i + 2), 16));
      i += 2;
    }
  } else {
    for (; i < str.length; i++) {
      /* eslint-disable no-bitwise */
      arr.push(str.charCodeAt(i) & 0xFF);
    }
  }

  return arr;
};

const readUInt16LE = (data, offset) => {
  return data[offset] | (data[offset + 1] << 8);
};

const readUInt16BE = (data, offset) => {
  return data[offset + 1] | (data[offset] << 8);
};

const readUInt32LE = (data, offset) => {
  return data[offset] |
    (data[offset + 1] << 8) |
    (data[offset + 2] << 16) |
    (data[offset + 3] * 0x1000000);
};

const readUInt32BE = function (data, offset) {
  return data[offset + 3] |
    (data[offset + 2] << 8) |
    (data[offset + 1] << 16) |
    (data[offset] * 0x1000000);
};

const readUInt16 = (buffer, offset, is_big_endian) => {
  return is_big_endian ?
    readUInt16BE(buffer, offset) :
    readUInt16LE(buffer, offset);
};

const readUInt32 = (buffer, offset, is_big_endian) => {
  return is_big_endian ?
    readUInt32BE(buffer, offset) :
    readUInt32LE(buffer, offset);
};

const readIFDValue= (data, data_offset, is_big_endian) => {
  const type       = readUInt16(data, data_offset + 2, is_big_endian);
  const values     = readUInt32(data, data_offset + 4, is_big_endian);

  if (values !== 1 || (type !== 3 && type !== 4)) return null;

  return type === 3 ?
    readUInt16(data, data_offset + 8, is_big_endian) :
    readUInt32(data, data_offset + 8, is_big_endian);
};

const parseVP8 = data => {
  if (data.length < 16 + 14) return;

  if (data[16 + 7] !== 0x9D || data[16 + 8] !== 0x01 || data[16 + 9] !== 0x2A) {
    // bad code block signature
    return;
  }

  return {
    width:  readUInt16LE(data, 16 + 10) & 0x3FFF,
    height: readUInt16LE(data, 16 + 12) & 0x3FFF,
    type:   'webp',
    mime:   'image/webp',
    wUnits: 'px',
    hUnits: 'px'
  };
};

const parseVP8L = data => {
  if (data.length < 16 + 9) return;

  if (data[16 + 4] !== 0x2F) return;

  const bits = readUInt32LE(data, 16 + 5);

  return {
    width:  (bits & 0x3FFF) + 1,
    height: ((bits >> 14) & 0x3FFF) + 1,
    type:   'webp',
    mime:   'image/webp',
    wUnits: 'px',
    hUnits: 'px'
  };
};

const parseVP8X = data => {
  if (data.length < 16 + 14) return;

  return  {
    // TODO: replace with `data.readUIntLE(8, 3) + 1`
    //       when 0.10 support is dropped
    width:  ((data[16 + 10] << 16) | (data[16 + 9] << 8) | data[16 + 8]) + 1,
    height: ((data[16 + 13] << 16) | (data[16 + 12] << 8) | data[16 + 11]) + 1,
    type:   'webp',
    mime:   'image/webp',
    wUnits: 'px',
    hUnits: 'px'
  };
};

const SIG_BM = str2arr('BM');

const SIG_GIF87a = str2arr('GIF87a');
const SIG_GIF89a = str2arr('GIF89a');

const SIG_PNG  = str2arr('\x89PNG\r\n\x1a\n');
const SIG_IHDR = str2arr('IHDR');

const SIG_1 = str2arr('II\x2A\0');
const SIG_2 = str2arr('LL\0\x2A');

const SIG_RIFF    = str2arr('RIFF');
const SIG_WEBPVP8 = str2arr('WEBPVP8');

const parseBMP = data => {
  if (data.length < 26) return;

  if (!sliceEq(data, 0, SIG_BM)) return;

  return {
    width:  readUInt16LE(data, 18),
    height: readUInt16LE(data, 22),
    type: 'bmp',
    mime: 'image/bmp',
    wUnits: 'px',
    hUnits: 'px'
  };
};

const parseGIF = data =>  {
  if (data.length < 10) return;

  if (!sliceEq(data, 0, SIG_GIF87a) && !sliceEq(data, 0, SIG_GIF89a)) return;

  return {
    width:  readUInt16LE(data, 6),
    height: readUInt16LE(data, 8),
    type: 'gif',
    mime: 'image/gif',
    wUnits: 'px',
    hUnits: 'px'
  };
};

const parseJPEG = data => {
  if (data.length < 2) return;

  // first marker of the file MUST be 0xFFD8
  if (data[0] !== 0xFF || data[1] !== 0xD8) return;

  let offset = 2;

  for (;;) {
    if (data.length - offset < 2) return;
    // not a JPEG marker
    if (data[offset] !== 0xFF) return;

    const code = data[offset + 1];
    let length;

    offset += 2;

    // standalone markers, according to JPEG 1992,
    // http://www.w3.org/Graphics/JPEG/itu-t81.pdf, see Table B.1
    if ((0xD0 <= code && code <= 0xD9) || code === 0x01) {
      length = 0;
    } else if (0xC0 <= code && code <= 0xFE) {
      // the rest of the unreserved markers
      if (data.length - offset < 2) return;

      length = readUInt16BE(data, offset) - 2;
      offset += 2;
    } else {
      // unknown markers
      return;
    }

    if (code === 0xD9 /* EOI */ || code === 0xDA /* SOS */) {
      // end of the datastream
      return;
    }

    if (length >= 5 &&
      (0xC0 <= code && code <= 0xCF) &&
      code !== 0xC4 && code !== 0xC8 && code !== 0xCC) {

      if (data.length - offset < length) return;

      return {
        width:  readUInt16BE(data, offset + 3),
        height: readUInt16BE(data, offset + 1),
        type:   'jpg',
        mime:   'image/jpeg',
        wUnits: 'px',
        hUnits: 'px'
      };
    }

    offset += length;
  }
};

const parsePNG = data => {
  if (data.length < 24) return;

  // check PNG signature
  if (!sliceEq(data, 0, SIG_PNG)) return;

  // check that first chunk is IHDR
  if (!sliceEq(data, 12, SIG_IHDR)) return;

  return {
    width:  readUInt32BE(data, 16),
    height: readUInt32BE(data, 20),
    type: 'png',
    mime: 'image/png',
    wUnits: 'px',
    hUnits: 'px'
  };
};

const parseTIFF = data => {
  if (data.length < 8) return;

  // check TIFF signature
  if (!sliceEq(data, 0, SIG_1) && !sliceEq(data, 0, SIG_2)) return;

  const is_big_endian = (data[0] === 77 /* 'LL' */);
  const count = readUInt32(data, 4, is_big_endian) - 8;

  if (count < 0) return;

  // skip until IFD
  let offset = count + 8;

  if (data.length - offset < 2) return;

  // read number of IFD entries
  const ifd_size = readUInt16(data, offset, is_big_endian) * 12;

  if (ifd_size <= 0) return;

  offset += 2;

  // read all IFD entries
  if (data.length - offset < ifd_size) return;

  let i, width, height, tag;

  for (i = 0; i < ifd_size; i += 12) {
    tag = readUInt16(data, offset + i, is_big_endian);

    if (tag === 256) {
      width = readIFDValue(data, offset + i, is_big_endian);
    } else if (tag === 257) {
      height = readIFDValue(data, offset + i, is_big_endian);
    }
  }

  if (width && height) {
    return {
      mime:   "image/tiff",
      width:  width,
      height: height,
    };
  }
};

const parseWEBP = data => {
  if (data.length < 16) return;

  // check /^RIFF....WEBPVP8([ LX])$/ signature
  if (sliceEq(data, 0, SIG_RIFF) && sliceEq(data, 8, SIG_WEBPVP8)) {
    switch (data[15]) {
      case 32/*' '*/: return parseVP8(data);
      case 76/* L */: return parseVP8L(data);
      case 88/* X */: return parseVP8X(data);
      default: return;
    }
  }
};

// --------------------------------------------------------------------------
const parsers = [
  parseBMP,
  parseGIF,
  parseJPEG,
  parsePNG,
  parseTIFF,
  parseWEBP,
];

// --------------------------------------------------------------------------
export default function readImageInfo(data) {
  for (let i = 0; i < parsers.length; i++) {
    const parser = parsers[i];

    const imageInfo = parser(data);
    if (imageInfo) return imageInfo;
  }

  return null;
}

import memoize from "memoize-one"
import { EditorView } from "prosemirror-view"
import React from "react"

// --------------------------------------------------------------------------
// Provides a wrapper for an EditorView that will never re-render, so we can re-
// render the containing component on changes without re-mounting the ProseMirror
// EditorView.
export default class EditorViewWrapper extends React.Component {
  _editorView = null;
  _keyCounter = 1;

  // --------------------------------------------------------------------------
  _createEditorView = element => {
    if (element) {
      const {
        className,
        dispatchTransaction,
        editorProps,
        editorState,
        isEditable,
        nodeViews,
        setEditorView,
      } = this.props;

      this._editorView = new EditorView(element, {
        attributes: {
          class: className
        },
        dispatchTransaction,
        editable: isEditable,
        nodeViews,
        state: editorState,

        ...(editorProps || {}),
      });

      setEditorView(this._editorView);
    }
  };

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    if (this._editorView) this._editorView.destroy();
  }

  // --------------------------------------------------------------------------
  render() {
    const { editorProps, onBlur, onFocus, onPaste } = this.props;

    return (
      <div
        key={ this._key(editorProps) }
        onBlur={ onBlur }
        onFocus={ onFocus }
        onPaste={ onPaste }
        ref={ this._createEditorView }
      />
    );
  }

  // --------------------------------------------------------------------------
  shouldComponentUpdate(nextProps) {
    return nextProps.editorProps !== this.props.editorProps;
  }

  // --------------------------------------------------------------------------
  // As of 2/2022 the only reason `editorProps` changes is to change configuration options that control rendering
  // of specific node views, but using `EditorView#setProps` doesn't re-create node views (despite seeming like it
  // should, so this may be a bug in how it is updated when detecting changes in `componentDidUpdate`), so we'll fall
  // back to creating a new editor when the editorProps are changed.
  _key = memoize(_editorProps => {
    return this._keyCounter++;
  });
}

import { flatMap, sumBy } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Button } from "@rmwc/button"
import { IconButton } from "@rmwc/icon-button"
import { TextField } from "@rmwc/textfield"

import EditorViewContext from "lib/ample-editor/contexts/editor-view-context"
import { noteParamsFromURL } from "lib/ample-util/note-url"

import "./rename-references-section.scss"

// --------------------------------------------------------------------------
export const FILTER_GROUP_MISMATCHED_REFERENCES = "mismatched-references";

// --------------------------------------------------------------------------
function ExpandedContent(props) {
  const {
    collapse,
    filterQuery,
    mismatchedCount,
    mismatchedReferencesByUrl,
    refreshReferencingNotes,
    setFilterQuery,
  } = props;

  const onInputChange = useCallback(
    event => {
      const { target: { value } } = event;
      setFilterQuery(value);
    },
    []
  );

  const clearFilterQuery = useCallback(
    () => {
      setFilterQuery("");
    },
    []
  );

  const [ renamingReferences, setRenamingReferences ] = useState(false);

  const editorView = useContext(EditorViewContext);
  const renameReferences = useCallback(
    () => {
      setRenamingReferences(true);

      (async () => {
        try {
          const { props: { hostApp: { applyNoteContentActions } } } = editorView;

          const promises = Object.keys(mismatchedReferencesByUrl).map(url => {
            const noteParams = noteParamsFromURL(url);
            const noteContentActions = flatMap(
              mismatchedReferencesByUrl[url],
              ({ updateTextOptions }) => updateTextOptions.noteContentActions
            );

            return applyNoteContentActions(noteParams, noteContentActions)
          });

          await Promise.all(promises);
        } finally {
          await refreshReferencingNotes();
          setRenamingReferences(false);
          collapse();
        }
      })();
    },
    [ mismatchedReferencesByUrl ]
  );

  const renameReferencesButtonText = (mismatchedCount === 0 && filterQuery)
    ? "No references contain this text"
    : `Update ${ mismatchedCount } reference${ mismatchedCount === 1 ? "" : "s" } to current title`;

  const trailingIcon = filterQuery
    ? (
      <IconButton
        className="clear-button"
        icon="cancel"
        onClick={ clearFilterQuery }
      />
    )
    : null;

  return (
    <React.Fragment>
      <div className="header">
        <i className="leading-icon material-icons">filter_alt</i>
        <span className="text">Filtering to references with link text different from the note title</span>
        <IconButton className="close-button" icon="close" onClick={ collapse } />
      </div>

      <div className="input-section">
        <TextField
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={ `search-input ${ filterQuery ? "" : "empty" }` }
          icon="search"
          onChange={ onInputChange }
          outlined
          placeholder="Search reference links to filter list"
          spellCheck="false"
          value={ filterQuery }
          trailingIcon={ trailingIcon }
        />
        <Button
          className="rename-references-button body-text"
          disabled={ renamingReferences || mismatchedCount === 0 }
          label={ renameReferencesButtonText }
          onClick={ renameReferences }
          unelevated
        />
      </div>

      <IndexingIndicator />
    </React.Fragment>
  );
}

// --------------------------------------------------------------------------
function IndexingIndicator() {
  const { props: { hostApp: { getIndexingStatus } } } = useContext(EditorViewContext);

  const [ indexedPercent, setIndexedPercent ] = useState(null);

  useEffect(
    () => {
      const updateIndexedPercent = () => {
        const { indexedCount, notesCount } = getIndexingStatus();
        if (indexedCount < notesCount) {
          setIndexedPercent(Math.min(99, Math.round(indexedCount * 100 / notesCount)));
        } else {
          setIndexedPercent(null);
        }
      };
      updateIndexedPercent();

      const intervalId = setInterval(() => { updateIndexedPercent(); }, 1000);
      return () => { clearInterval(intervalId); };
    },
    []
  );

  if (indexedPercent !== null) {
    return (
      <div className="indexing-indicator">
        <i className="material-icons">cached</i>
        <span>Indexing note content ({ indexedPercent }% complete). Results may be limited until indexing completes.</span>
      </div>
    );
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
export default function RenameReferencesSection(props) {
  const {
    filterParams,
    mismatchedReferencesByUrl,
    refreshReferencingNotes,
    setFilterParams,
  } = props;

  const [ expanded, setExpanded ] = useState(false);

  const haveMountedRef = useRef(false);
  useEffect(
    () => {
      if (!haveMountedRef.current) {
        haveMountedRef.current = true;
        return;
      }

      setFilterParams(currentFilterParams => ({
        ...currentFilterParams,
        group: expanded ? FILTER_GROUP_MISMATCHED_REFERENCES : null,
        query: expanded ? "" : null,
      }));
    },
    [ expanded, setFilterParams ]
  );

  // Collapse when filters are cleared externally
  const { group } = filterParams;
  const groupWasRef = useRef(group);
  useEffect(
    () => {
      if (expanded && groupWasRef.current && !group) {
        setExpanded(false);
      }
      groupWasRef.current = group;
    },
    [ expanded, group ]
  );

  const setFilterQuery = useCallback(
    filterQuery => {
      setFilterParams(currentFilterParams => ({ ...currentFilterParams, query: filterQuery }));
    },
    [ setFilterParams ]
  );

  const mismatchedCount = useMemo(
    () => sumBy(Object.values(mismatchedReferencesByUrl), mismatchedReferences => mismatchedReferences.length),
    [ mismatchedReferencesByUrl ]
  );

  const collapse = useCallback(() => { setExpanded(false); }, []);
  const toggleExpanded = useCallback(() => { setExpanded(currentExpanded => !currentExpanded); }, []);

  return (
    <div className={ `rename-references-section ${ expanded ? "expanded" : "" }` }>
      {
        expanded
          ? (
            <ExpandedContent
              collapse={ collapse }
              filterQuery={ filterParams.query || "" }
              mismatchedCount={ mismatchedCount }
              mismatchedReferencesByUrl={ mismatchedReferencesByUrl }
              refreshReferencingNotes={ refreshReferencingNotes }
              setFilterQuery={ setFilterQuery }
            />
          )
          : (
            <Button
              className="open-button body-text"
              label={ `${ mismatchedCount } reference${ mismatchedCount === 1 ? "" : "s" } with link text different from the note title` }
              onClick={ toggleExpanded }
              trailingIcon="cached"
            />
          )
      }
    </div>
  );
}

RenameReferencesSection.propTypes = {
  filterParams: PropTypes.object.isRequired,
  mismatchedReferencesByUrl: PropTypes.object.isRequired,
  refreshReferencingNotes: PropTypes.func.isRequired,
  setFilterParams: PropTypes.func.isRequired,
};

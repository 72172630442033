import { DecorationSet } from "prosemirror-view"
import { startTaskCompletedEffects } from "lib/ample-editor/lib/task-completed-animations"
import { DEFAULT_TASK_COMPLETION_EFFECT_LEVEL } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// This plugin view handles the animation of tasks that have been completed, which are placed into the document
// as widget decorations.
//
// This uses a plugin view instead of plugin state because:
//  1. We need access to the dom node for the completed check-list-item immediately, before it is removed (requires
//     a reference to the editorView, which plugin state doesn't already get).
//  2. We're starting timers, so we need a hook to clear them when unmounting the editor
export default class CompletedCheckListItemPluginView {
  _animationByKey = {};
  _decorations = DecorationSet.empty;
  _editorView = null;
  _pluginKey = null;
  _taskCompletionEffectLevelEm = null;

  // --------------------------------------------------------------------------
  constructor(editorView, pluginKey) {
    this._editorView = editorView;
    this._pluginKey = pluginKey;
    this._taskCompletionEffectLevelEm = editorView.props.taskCompletionEffectLevelEm || DEFAULT_TASK_COMPLETION_EFFECT_LEVEL;
  }

  // --------------------------------------------------------------------------
  applyTransaction(transaction) {
    const { doc, mapping } = transaction;
    this._decorations = this._decorations.map(mapping, doc, {
      onRemove: ({ key }) => {
        // ProseMirror is removing the decoration (e.g. entire document was cleared out), in which case we don't want
        // to fire the timer to remove it any more.
        this._destroyAnimation(key);
      },
    });

    const meta = transaction.getMeta(this._pluginKey);
    if (meta) {
      const decorations = [];

      meta.forEach(({ checkListAttrs, nodePos, originalNodePos }) => {
        const decoration = this._startAnimation(doc, checkListAttrs, nodePos, originalNodePos);
        if (decoration) decorations.push(decoration);
      });

      this._decorations = this._decorations.add(doc, decorations);
    }
  }

  // --------------------------------------------------------------------------
  destroy() {
    Object.keys(this._animationByKey).forEach(animationKey => {
      this._destroyAnimation(animationKey);
    });
  }

  // --------------------------------------------------------------------------
  getDecorations() {
    return this._decorations;
  }

  // --------------------------------------------------------------------------
  startAnimation(nodePos, checkListAttrs) {
    const { state: { doc } } = this._editorView;

    const decoration = this._startAnimation(doc, checkListAttrs, nodePos, nodePos);
    if (decoration) this._decorations = this._decorations.add(doc, [ decoration ]);
  }

  // --------------------------------------------------------------------------
  _destroyAnimation = key => {
    const animation = this._animationByKey[key];
    if (animation) {
      const { clonedCheckListItemDomNode, destroyAnimationTimer } = animation;
      // When _destroyAnimation is called via its timeout, then these clearTimeouts are superfluous; but this isn't method
      // isn't called only through timer (e.g., when document is unloaded)
      clearTimeout(destroyAnimationTimer);

      // ProseMirror won't ask for the decorations until a transaction changes the document, so we hide the dom node
      // entirely until PM gets around to that.
      clonedCheckListItemDomNode.style.display = "none";
      clonedCheckListItemDomNode.classList.remove("is-shrinking");
      clonedCheckListItemDomNode.classList.add("is-complete");
      const imageEl = clonedCheckListItemDomNode.querySelector("img.mario-data");
      if (imageEl) {
        // WBH has confirmed as of June 2024, Mario will not reanimate in each new element unless this witchcraft is undertaken
        // Browsers do NOT like to reload a data URL image, in this case the 1x animating gif.
        imageEl.src = "";
      }

      // We must re-look-up the decoration to remove from its key in case the document changed during animation,
      // in which case from/to of the known decoration wouldn't match the decoration in the array
      const removeDeco = this._decorations.find(null, null, deco => deco.key === key);
      this._decorations = this._decorations.remove(removeDeco);
      delete this._animationByKey[key];
    }
  }

  // --------------------------------------------------------------------------
  _startAnimation = (doc, checkListAttrs, nodePos, originalNodePos) => {
    let originalDomNode;
    try {
      originalDomNode = this._editorView.nodeDOM(originalNodePos);
    } catch (_error) {
      return null;
    }

    // This can happen if an already-completed task is dragged to the editor and dropped, in which case it won't have
    // been rendered in the document yet
    if (!originalDomNode) return null;

    const effectInfo = startTaskCompletedEffects(
      window.document, originalDomNode, checkListAttrs, nodePos, this._taskCompletionEffectLevelEm
    );
    if (!effectInfo) return null;
    const { decoration, destroyAnimationMs, key, newDomNode } = effectInfo;

    newDomNode.classList.add("is-shrinking");

    const destroyAnimationTimer = setTimeout(this._destroyAnimation.bind(this, key), destroyAnimationMs);
    this._animationByKey[key] = { clonedCheckListItemDomNode: newDomNode, destroyAnimationTimer };
    return decoration;
  };
}

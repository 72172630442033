import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import scriptjs from "scriptjs"

// --------------------------------------------------------------------------
const EMBED_JAVASCRIPT_URL = "https://public.amplenote.com/embed.js";

// --------------------------------------------------------------------------
export default function PublicNotePreview({ section, token }) {
  const [ failed, setFailed ] = useState(false);
  const [ loading, setLoading ] = useState(true);

  const embedContainerRef = useRef(null);

  useEffect(
    () => {
      scriptjs(EMBED_JAVASCRIPT_URL, () => {
        const embedContainer = embedContainerRef.current;

        if (!window.ampleembed || !embedContainer) {
          setFailed(true);
          return;
        }

        window.ampleembed(embedContainer);
        setLoading(false);
      });
    },
    []
  );

  const onError = useCallback(() => {
    setFailed(true);
  }, []);

  if (failed) return null;

  let url = `https://public.amplenote.com/embed/${ token }`;
  if (section) url += `?section=${ encodeURIComponent(section) }`;

  return (
    <div
      className="preview-section public-note-preview"
      data-note-token={ token }
      data-section={ section }
      ref={ embedContainerRef }
    >
      <iframe frameBorder="0" onError={ onError } src={ url } />
      { loading ? <div className="loader"><CircularProgress size="large" /></div> : null }
    </div>
  );
}

PublicNotePreview.propTypes = {
  section: PropTypes.string,
  token: PropTypes.string.isRequired,
};

import { isIOS14OrLater } from "./client-info"

// --------------------------------------------------------------------------
// This is a bizarre issue that appeared in iOS 14, where Safari changes the selection position
// when the cursor is at the end of a bullet list item or number list item and you press enter,
// but does not perform any dom changes. ProseMirror reads the selection update, moving the
// cursor to the beginning of the next list item, however ProseMirror _also_ detects that it was
// an enter press on iOS Safari and manually calls handleKeyDown - this results in our own keymap
// handlers triggering, but they are triggering _after_ the selection has been moved, so they don't
// do what the user would expect. This may be an indication that some mobile Safari workarounds are
// no longer necessary in ProseMirror, but only on iOS 14+ (ugg). In the meantime, we can just ignore
// this very specific transaction. Note that these checks don't evaluate to true when pressing enter
// anywhere else in the document, just at the end of bullet and number list items (and not at the end
// of check-list-items :shrug:).
const shouldSkipIosEnterTransaction = (view, transaction) => {
  if (!isIOS14OrLater) return false;

  return view && transaction.selectionSet && !transaction.docChanged &&
    // This matches the checks in prosemirror-view, where an lastIOSEnter is set along with a 200ms
    // timer that fires the handleKeyDown (if nothing cancels it in the interim
    (view.input.lastIOSEnter > Date.now() - 225);
};
export default shouldSkipIosEnterTransaction;

// --------------------------------------------------------------------------
export const isPlainTextBetween = (doc, startPos, endPos) => {
  let hasNonTextNodes = false;
  doc.nodesBetween(startPos, endPos, (node, offset) => {
    if (offset < startPos) return true; // Probably the parent node (a paragraph, typically)

    if (!node.isText) hasNonTextNodes = true;

    return false;
  });

  if (hasNonTextNodes) return false;

  // We don't really want any marks in the text (particularly not code marks)
  const $end = doc.resolve(endPos);
  const marks = doc.resolve(startPos).marksAcross($end);

  return (!marks || marks.length === 0);
}

import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

// --------------------------------------------------------------------------
export default class ChangeHiddenTaskContentStep extends Step {
  _newContent = null;
  _oldContent = null;
  _schema = null;
  _uuid = null;

  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    const { newContent, oldContent, uuid } = json;
    return new ChangeHiddenTaskContentStep(schema, uuid, oldContent, newContent);
  }

  // --------------------------------------------------------------------------
  constructor(schema, uuid, oldContent, newContent) {
    super();

    this._newContent = newContent;
    this._oldContent = oldContent;
    this._schema = schema;
    this._uuid = uuid;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const serializedDoc = doc.toJSON();

    const hiddenTasks = (serializedDoc.attrs.hiddenTasks || []).map(hiddenTask => {
      if (hiddenTask.attrs.uuid === this._uuid) {
        return { ...hiddenTask, content: this._newContent };
      } else {
        return hiddenTask;
      }
    });

    serializedDoc.attrs = { ...serializedDoc.attrs, hiddenTasks };

    doc = Node.fromJSON(this._schema, serializedDoc);

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    return new ChangeHiddenTaskContentStep(this._schema, this._uuid, this._newContent, this._oldContent);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      newContent: this._newContent,
      oldContent: this._oldContent,
      stepType: "changeHiddenTaskContent",
      uuid: this._uuid,
    };
  }
}
Step.jsonID("changeHiddenTaskContent", ChangeHiddenTaskContentStep);

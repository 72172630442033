import React from "react"

// --------------------------------------------------------------------------
// This module is imported instead of NimblePickerWithData if there is an
// error lazy-loading the NimblePickerWithData chunk
export default class NimblePickerFailure extends React.Component {
  // --------------------------------------------------------------------------
  render() {
    return (
      <div className="nimble-picker-failure">
        Failed to load emoji data
      </div>
    );
  }
}

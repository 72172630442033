// --------------------------------------------------------------------------
// Based on https://javascript.plainenglish.io/how-to-deal-with-network-failures-from-code-splitting-13dd7fd3648 -
// in the wild, we've seen chunk loads fail as:
//    Loading chunk 0 failed. (timeout: file:// ... /editor.ample-editor.js)
//    Loading chunk 4 failed. (timeout: file:// ... /editor.vendors~ample-editor.js)
// With no apparent reason, as we're loading from the filesystem, so a timeout doesn't seem very likely.
export default async function importRetry(importFn, retries = 3, interval = 500) {
  try {
    return await importFn();
  } catch (error) {
    if (retries) {
      await new Promise(resolve => setTimeout(resolve, interval));
      return importRetry(importFn, retries - 1, interval);
    } else {
      throw new Error(error);
    }
  }
}

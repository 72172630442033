import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
const codeBlockPluginKey = new PluginKey("codeBlock");

// --------------------------------------------------------------------------
const codeBlockPlugin = new Plugin({
  key: codeBlockPluginKey,
  props: {
    decorations(state) {
      const { doc, selection: { $from, $to } } = state;
      const decorations = [];

      if ($from && $to && (!$to.sameParent($from) || ($to.depth === 0 && $from.pos !== $to.pos))) {
        doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
          if (node.type.isInline) return false;
          if (node.type.spec.code) {
            decorations.push(Decoration.node(pos, pos + node.nodeSize, {
              class: "selection-through",
              nodeName: "div"
            }));
            return false;
          } else if (!node.type.spec.content || !node.type.spec.content.includes("block")) {
            return false;
          }
        });
      }

      if (decorations.length === 0) {
        return DecorationSet.empty;
      } else {
        return DecorationSet.create(state.doc, decorations);
      }
    },
    handleScrollToSelection(view) {
      const pluginState = codeBlockPluginKey.getState(view.state);

      // When a user invokes undo/redo within a code block, PMs default scrollToSelection would scroll to the beginning
      // of the code block, which is mighty annoying if the beginning of the code block wasn't visible from where the
      // undo took place
      if (pluginState && pluginState.codeBlockSuppressScroll) {
        return true;
      }
    }
  },
  state: {
    init: () => ({}),
    apply: (tr, _pluginState) => {
      // If the transaction occurred within a code block, for one update cycle we'll update its state to indicate
      // it should handle scroll to selection
      if (tr.getMeta(TRANSACTION_META_KEY.CODE_BLOCK_UNDO) || tr.getMeta(TRANSACTION_META_KEY.CODE_BLOCK_REDO)) {
        return { codeBlockSuppressScroll: true };
      } else {
        return {};
      }
    }
  },
});
export default codeBlockPlugin;

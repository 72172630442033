import { chainCommands, deleteSelection, toggleMark } from "prosemirror-commands"
import { keymap } from "prosemirror-keymap"

import {
  crossOutSelectedCheckListItem,
  deleteSelectedCheckListItemAtStart,
  dismissSelectedCheckListItem,
  selectCheckListItemContent,
  buildSetSelectedCheckListItemDuration,
  buildToggleSelectedCheckListItemFlag,
} from "lib/ample-editor/commands/check-list-item-commands"
import { isApplePlatform } from "lib/ample-editor/lib/client-info"
import {
  buildSetBlockType,
  clearActiveMarks,
  clearStoredMarksAtStart,
  exitLastMark,
  insertHardBreak,
  redoWithRetainEditorFocus,
  undoInputRuleWithoutHistory,
  undoWithRetainEditorFocus,
} from "lib/ample-editor/lib/commands"
import {
  buildStartNoteLink,
  buildToggleLink,
  deleteLinkForward,
  editLink,
  exitLink,
  openNoteLink,
} from "lib/ample-editor/lib/link-commands"
import { buildCompleteListItems, toggleListItemDetail } from "lib/ample-editor/lib/list-item-commands"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
// This allows detection and handling of keys being pressed without key event handlers, which can be difficult
// to scope to the editor (and not any sub-editors, e.g. in a Rich Footnote).
const dispatchKeyPressedTransaction = (key, { shiftKey = false } = {}) => (state, dispatch) => {
  if (dispatch) dispatch(state.tr.setMeta(TRANSACTION_META_KEY.KEY_PRESSED, { key, shiftKey }));
  return true;
};

// --------------------------------------------------------------------------
export function buildKeymap(schema) {
  const keys = {};
  function bind(key, cmd) {
    keys[key] = cmd;
  }

  const backspace = chainCommands(
    deleteSelection,
    deleteSelectedCheckListItemAtStart,
  );
  bind("Backspace", backspace);
  bind("Mod-Backspace", backspace);

  const del = chainCommands(
    deleteSelection,
    deleteLinkForward,
  );
  bind("Delete", del);
  bind("Mod-Delete", del);

  bind("Mod-a", selectCheckListItemContent);

  bind("Mod-z", chainCommands(
    undoInputRuleWithoutHistory,
    undoWithRetainEditorFocus
  ));
  bind("Shift-Mod-z", redoWithRetainEditorFocus);

  bind("Mod-k", buildToggleLink());
  bind("Mod-b", toggleMark(schema.marks.strong));
  bind("Mod-h", toggleMark(schema.marks.highlight));
  bind("Mod-i", toggleMark(schema.marks.em));
  bind("Ctrl-`", toggleMark(schema.marks.code));
  bind("Ctrl-[", buildStartNoteLink());
  bind("Ctrl-Shift-[", buildStartNoteLink({ insertSourceTags: true }));
  bind("@", buildStartNoteLink({ closingText: "", triggerText: "@" }));

  bind("Ctrl-Space", chainCommands(
    openNoteLink,
    buildCompleteListItems(),
  ));
  bind("Ctrl-Shift-Space", crossOutSelectedCheckListItem);
  bind("Alt-Shift-Space", dismissSelectedCheckListItem);

  bind("Enter", dispatchKeyPressedTransaction("Enter"));

  bind("Shift-Enter", insertHardBreak(schema.nodes.hard_break));

  bind("Escape", dispatchKeyPressedTransaction("Escape"));

  bind("Tab", chainCommands(
    editLink,
    dispatchKeyPressedTransaction("Tab"),
  ));

  bind("Shift-Tab", dispatchKeyPressedTransaction("Tab", { shiftKey: true }));

  bind("Mod-.", toggleListItemDetail);

  bind("Mod->", openNoteLink);

  bind("ArrowLeft", clearStoredMarksAtStart);

  bind("ArrowRight", chainCommands(
    exitLastMark,
    exitLink,
  ));

  bind("Mod-/", clearActiveMarks);

  bind("Shift-Ctrl-\\", buildSetBlockType(schema.nodes.code_block));

  // Platform-specific bindings
  if (isApplePlatform) {
    bind("Ctrl-h", keys["Backspace"]);
    bind("Alt-Backspace", keys["Mod-Backspace"]);
    bind("Ctrl-d", keys["Delete"]);
    bind("Ctrl-Alt-Backspace", keys["Mod-Delete"]);
    bind("Alt-Delete", keys["Mod-Delete"]);
    bind("Alt-d", keys["Mod-Delete"]);

    bind("Ctrl-i", buildToggleSelectedCheckListItemFlag("I"));
    bind("Ctrl-u", buildToggleSelectedCheckListItemFlag("U"));

    bind("Ctrl-1", buildSetSelectedCheckListItemDuration("PT15M"));
    bind("Ctrl-3", buildSetSelectedCheckListItemDuration("PT30M"));
    bind("Ctrl-6", buildSetSelectedCheckListItemDuration("PT60M"));
    bind("Ctrl-9", buildSetSelectedCheckListItemDuration("PT90M"));
  } else {
    bind("Mod-y", redoWithRetainEditorFocus);

    bind("Alt-Shift-i", buildToggleSelectedCheckListItemFlag("I"));
    bind("Alt-Shift-u", buildToggleSelectedCheckListItemFlag("U"));

    bind("Alt-Shift-1", buildSetSelectedCheckListItemDuration("PT15M"));
    bind("Alt-Shift-3", buildSetSelectedCheckListItemDuration("PT30M"));
    bind("Alt-Shift-6", buildSetSelectedCheckListItemDuration("PT60M"));
    bind("Alt-Shift-9", buildSetSelectedCheckListItemDuration("PT90M"));
  }

  return keys;
}

// --------------------------------------------------------------------------
const tasksKeymapPlugin = schema => keymap(buildKeymap(schema));
export default tasksKeymapPlugin;

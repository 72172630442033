import { Selection } from "prosemirror-state"

import { filterAttachments, filterImages, filterVideos } from "lib/ample-editor/lib/filter-files"
import { insertMedia, insertPendingAttachment } from "lib/ample-editor/lib/file-commands"

// --------------------------------------------------------------------------
// https://discuss.prosemirror.net/t/drag-and-drop-image-and-upload/243/3
export default function handleDropFiles(view, event) {
  const { dataTransfer } = event;
  if (!(dataTransfer && dataTransfer.files && dataTransfer.files.length > 0)) return false;

  const mediaFiles = filterImages(dataTransfer.files).concat(filterVideos(dataTransfer.files));
  if (mediaFiles.length > 0) {
    event.preventDefault();

    const $insertPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
    if (!$insertPos) return true;

    let insertPos = $insertPos.pos;

    mediaFiles.forEach(mediaFile => {
      insertMedia(view, mediaFile, insertPos, true);

      // Move past the inserted node so files are inserted in the correct order
      insertPos += 2;

      const endSelection = Selection.atEnd(view.state.doc);
      insertPos = Math.min(insertPos, endSelection.to);
    });

    return true;
  }

  const { props: { hostApp: { startAttachmentUpload } } } = view;
  if (startAttachmentUpload) {
    const attachments = filterAttachments(dataTransfer.files);
    if (attachments.length > 0) {
      event.preventDefault();

      const insertPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
      if (!insertPos) return true;

      attachments.forEach(attachment => insertPendingAttachment(view, attachment, insertPos.pos));

      return true;
    }
  }

  return false;
}

import React from "react"
import ReactDOM from "react-dom"

import EditorTabs from "lib/ample-editor/components/editor-tabs"
import EditorViewContext from "lib/ample-editor/contexts/editor-view-context"
import { getCheckListItemPluginState } from "lib/ample-editor/plugins/check-list-item-plugin"
import { getEditorTabsPluginState, updateEditorTabsPluginState } from "lib/ample-editor/plugins/editor-tabs-plugin"
import { getFilePluginState } from "lib/ample-editor/plugins/file-plugin"
import { getFindPluginState } from "lib/ample-editor/plugins/find-plugin"

// --------------------------------------------------------------------------
export default class EditorTabsPluginView {
  _viewHadFocusOnMouseDown = false;

  // --------------------------------------------------------------------------
  constructor(editorView) {
    this._onMouseDown = this._onMouseDown.bind(this, editorView);
    this._setCurrentTab = this._setCurrentTab.bind(this, editorView);
    this._setExpanded = this._setExpanded.bind(this, editorView);
    this._setHaveReferencingNotes = this._setHaveReferencingNotes.bind(this, editorView);

    this.dom = document.createElement("div");
    this.dom.className = "editor-tabs-container";
    this.dom.addEventListener("mousedown", this._onMouseDown);
    editorView.dom.parentNode.appendChild(this.dom);

    this.update(editorView);
  }

  // --------------------------------------------------------------------------
  update(editorView) {
    const { state, state: { doc: { attrs: { completedTasks, hiddenTasks } } } } = editorView;

    const { currentTab, expanded } = getEditorTabsPluginState(state);

    ReactDOM.render(
      <EditorViewContext.Provider value={ editorView }>
        <EditorTabs
          checkListItemPluginState={ getCheckListItemPluginState(state) }
          completedTasks={ completedTasks }
          currentTab={ currentTab }
          editorHasFocus={ editorView.dom.contains(document.activeElement) }
          editorView={ editorView }
          expanded={ expanded }
          filePluginState={ getFilePluginState(state) }
          findPluginState={ getFindPluginState(state) }
          hiddenTasks={ hiddenTasks }
          readonly={ !editorView.editable }
          setCurrentTab={ this._setCurrentTab }
          setExpanded={ this._setExpanded }
          setHaveReferencingNotes={ this._setHaveReferencingNotes }
        />
      </EditorViewContext.Provider>,
      this.dom
    );
  }

  // --------------------------------------------------------------------------
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom);
    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  _onMouseDown(editorView) {
    this._viewHadFocusOnMouseDown = editorView.hasFocus();
  }

  // --------------------------------------------------------------------------
  _setCurrentTab(editorView, currentTab) {
    const { dispatch, state } = editorView;
    dispatch(updateEditorTabsPluginState(state.tr, { currentTab }));

    if (this._viewHadFocusOnMouseDown) editorView.focus();
  }

  // --------------------------------------------------------------------------
  _setExpanded(editorView, expanded) {
    const { dispatch, state } = editorView;
    dispatch(updateEditorTabsPluginState(state.tr, { expanded }));

    if (this._viewHadFocusOnMouseDown) editorView.focus();
  }

  // --------------------------------------------------------------------------
  _setHaveReferencingNotes(editorView, haveReferencingNotes) {
    const { dispatch, state } = editorView;
    dispatch(updateEditorTabsPluginState(state.tr, { haveReferencingNotes }));
  }
}

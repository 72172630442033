import { isAfter } from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import Tippy from "@tippyjs/react"

import DateTimeInput from "lib/ample-editor/components/task-detail/date-time-input"
import DurationInput from "lib/ample-editor/components/task-detail/duration-input"
import RepeatRuleInput from "lib/ample-editor/components/task-detail/repeat-rule-input"
import { DURATION_ANCHOR_NAME, DURATION_ANCHOR_RELATION } from "lib/ample-editor/components/task-detail/util"
import { DAY_PART, nextInstanceFromDuration, REPEAT_TYPE, repeatTypeFromRepeat } from "lib/ample-util/tasks"

const TOOLTIP_DELAY = [ 1000, 150 ];

// --------------------------------------------------------------------------
export default function ScheduledDateControls(props) {
  const {
    createdAt,
    listItemType,
    onFocus,
    readonly,
    repeat,
    scheduledAt,
    scheduledDayPart,
    setRepeat,
    setRrule,
    setScheduledDate,
    setScheduledDayPart,
  } = props;

  const repeatType = repeatTypeFromRepeat(repeat);

  let className = "control scheduled-date";
  let content;
  switch (repeatType) {
    case REPEAT_TYPE.FIXED:
      content = (
        <RepeatRuleInput
          disabled={ readonly }
          nextInstanceAt={ scheduledAt }
          onFocus={ onFocus }
          rrule={ repeat }
          setNextInstance={ setScheduledDate }
          setRrule={ setRrule }
        />
      );
      break;

    case REPEAT_TYPE.RELATIVE: {
      const expectedNextInstanceAt = createdAt ? nextInstanceFromDuration(repeat, createdAt) : null;

      content = (
        <DurationInput
          anchorName={ DURATION_ANCHOR_NAME.COMPLETE }
          anchorRelation={ DURATION_ANCHOR_RELATION.AFTER }
          disabled={ readonly }
          duration={ repeat }
          expectedNextInstanceAt={ expectedNextInstanceAt }
          nextInstanceAt={ scheduledAt }
          onFocus={ onFocus }
          setDuration={ setRepeat }
          setNextInstance={ setScheduledDate }
        />
      );

      break;
    }

    case REPEAT_TYPE.NONE:
    default: {
      const scheduledDate = scheduledAt ? new Date(scheduledAt * 1000) : null;

      className += " has-fuzzy-time";

      content = (
        <DateTimeInput
          adjustPopupType="due"
          className={ (scheduledDate && !isAfter(scheduledDate, Date.now())) ? "overdue" : null }
          date={ scheduledDate }
          dayPart={ scheduledDayPart }
          disabled={ readonly }
          onDateChange={ setScheduledDate }
          onFocus={ onFocus }
          setDayPart={ setScheduledDayPart }
        />
      );

      break;
    }
  }

  const tooltip = `Set the time at which this ${ listItemType === "bullet_list_item" ? "event" : "task" } begins`;

  return (
    <div className={ className }>
      <Tippy arrow={ false } content={ tooltip } delay={ TOOLTIP_DELAY }>
        <div className="control-label">
          <span className="material-icons">event_available</span><span className="text">Start at</span>
        </div>
      </Tippy>
      { content }
    </div>
  );
}

ScheduledDateControls.propTypes = {
  createdAt: PropTypes.number,
  listItemType: PropTypes.oneOf([ "bullet_list_item", "check_list_item" ]).isRequired,
  onFocus: PropTypes.func,
  readonly: PropTypes.bool,
  repeat: PropTypes.string,
  scheduledAt: PropTypes.number,
  scheduledDayPart: PropTypes.oneOf(Object.values(DAY_PART)),
  setRepeat: PropTypes.func.isRequired,
  setRrule: PropTypes.func.isRequired,
  setScheduledDate: PropTypes.func.isRequired,
  setScheduledDayPart: PropTypes.func,
};

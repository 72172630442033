import isUrl from "is-url"
import { closeHistory } from "prosemirror-history"
import {
  inputRules,
  wrappingInputRule,
  textblockTypeInputRule,
  InputRule,
} from "prosemirror-inputrules"
import { Fragment, NodeRange, Slice } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"
import { findWrapping, insertPoint } from "prosemirror-transform"
import { v4 as uuidv4 } from "uuid"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
function codeFenceRule(codeBlockType, pattern) {
  return new InputRule(pattern, function(state, match, start) {
    const { tr: transform, schema, selection: { $from, $to } } = state;

    const $replacePos = transform.doc.resolve($from.before());
    if (!$replacePos || !$replacePos.nodeAfter) return false;
    const nonBacktickLength = $replacePos.nodeAfter.textContent.replace(/`/g, "").length;
    // Space conditionally inserted after the final character of input fence because, as of Nov 2022,
    // in description editor only, code block be visible when it has no content
    closeHistory(transform.insert($to.pos, schema.text("`" + (nonBacktickLength ? "" : " "))));
    const endPos = start + match[0].length; // `end` as reported by the inputRule doesn't account for the final match character
    transform.setBlockType($replacePos.pos, $replacePos.pos + $replacePos.nodeAfter.nodeSize, schema.nodes.code_block);

    // Remove the backticks themselves
    transform.deleteRange(start, endPos);

    return transform;
  });
}

// --------------------------------------------------------------------------
// Based on https://discuss.prosemirror.net/t/input-rules-for-wrapping-marks/537/11
function codeMarkRule(markType, pattern) {
  return new InputRule(pattern, function(state, match, start, end) {
    const content = match[3];

    // Ignore potential code fences (```)
    if (content === "`") return;

    // Make sure the opening backtick isn't in a non-text node of some sort - `start` will not account for the
    // closing position in that case (see comments below for more discussion of why that is)
    const parentNode = state.doc.resolve(start).parent;
    if (parentNode && !parentNode.isTextblock) return;

    const lookbehind = match[1];
    start += lookbehind.length;

    // Because there could be non-text nodes in the matched region, start could have been calculated incorrectly
    // (it's anchored to the end position, but uses textBetween so it doesn't count node open/close positions) so
    // we need to adjust for any non-text positions that fit inside the node
    state.doc.nodesBetween(start, end, (node, offset) => {
      if (offset < start) return true; // Probably the parent node (a paragraph, typically)

      // We don't need to examine text nodes
      if (node.isText) return false;

      start -= node.nodeSize - node.textContent.length;
      return false;
    });

    const transform = state.tr;

    // Replace entire match with plain-text content
    transform.insertText(
      content,
      transform.mapping.map(start),
      transform.mapping.map(end)
    );

    // Apply mark
    transform.addMark(transform.mapping.map(start), transform.mapping.map(end), markType.create());
    transform.removeStoredMark(markType);

    return transform;
  });
}

// --------------------------------------------------------------------------
function markdownLinkRule(imageType, linkType, pattern) {
  return new InputRule(pattern, function(state, match, start, end) {
    const lookbehind = match[1];
    start += lookbehind.length;

    // Adjust for any inline nodes (i.e. links), as the user might have typed out `[blah](` then pasted a URL which
    // got auto-linked, so we want to be able to type out the closing `)` and create a link
    state.doc.nodesBetween(start, end, node => {
      if (!node.isText && node.type.spec.inline) start -= 2;
    });

    const isImage = match[2] === "!";

    const transform = state.tr;

    const text = match[3].trim();
    const href = match[4].trim();

    if (isImage) {
      // As of 5/2021, some schemas (e.g. description-schema) have links, but no images
      if (!imageType) return false;

      // We let links target whatever, but images should be real URLs, and HTTPS so they can be displayed on HTTPS pages
      if (!isUrl(href) || !href.startsWith("https://")) return false;
    } else {
      // Don't allow blank link text - this would need to be handled below, and adjusted so the link popup shows for it so
      // new text can be inserted
      if (text.length === 0) return false;
    }

    // Replace entire match with plain-text content
    transform.insertText(
      text,
      transform.mapping.map(start),
      transform.mapping.map(end)
    );

    // Wrap inserted text with link, or replace with image
    if (isImage) {
      return state.tr.replaceRangeWith(start, end, imageType.create({ src: href }));
    } else {
      const $start = transform.doc.resolve(transform.mapping.map(start));
      const $end = transform.doc.resolve(transform.mapping.map(end));
      const range = new NodeRange($start, $end, $start.depth);

      const wrapping = findWrapping(range, state.schema.nodes.link, { href });
      if (wrapping) transform.wrap(range, wrapping);
    }

    return transform;
  });
}

// --------------------------------------------------------------------------
// Note that this assumes the closing character is a single character (true for all uses as of 2/2019) and does not
// attempt to remove additional characters in the closing sequence (i.e. it can't support **bold**)
function markRule(markType, pattern) {
  return new InputRule(pattern, function(state, match, start, end) {
    const lookbehind = match[1];
    start += lookbehind.length;

    const opening = match[2];

    // InputRules determines the start position by moving backwards by textContent.length from the end position, but
    // there may be inline nodes in between, which would have their own opening and closing positions, so we need to
    // include those positions for any inline nodes where the start would fall inside the replacement segment.
    state.doc.nodesBetween(start, end, node => {
      if (!node.isText && node.type.spec.inline) start -= 2;
    });

    const transform = state.tr;

    // Remove opening sequence
    transform.delete(start, start + opening.length);

    // Apply mark
    transform.addMark(transform.mapping.map(start), transform.mapping.map(end), markType.create());
    transform.removeStoredMark(markType);

    return transform;
  });
}

// --------------------------------------------------------------------------
const noteLinkRule = new InputRule(/(\[?)\[$/, function(state, match, start, end) {
  const { selection: { from, to, $from, $to } } = state;

  let textToInsert;
  if (from === to) {
    const lookbehind = match[1];
    if (lookbehind !== "[") return;

    const { doc } = state;

    const textNode = doc.nodeAt(end);
    if (!textNode || !textNode.isText) return false;

    const $end = doc.resolve(end);
    const followingContent = textNode.textContent.substring($end.textOffset);
    if (!followingContent.match(/^\s+\S/)) return false;

    textToInsert = `${ lookbehind }]`;
  } else {
    if (!$from.sameParent($to)) return false;

    const { parent } = $from;
    if (!parent || !parent.isTextblock) return;

    let parentOffset = $from.start();

    // We need to know about any nodes that would have open/close positions in the parent before getting to our
    // specific starting offset, as `textContent` will _not_ include those open/start positions, but `from` and `to`
    // _do_ account for them, so we need to remove them from `from` and `to`
    state.doc.nodesBetween(parentOffset, start, node => {
      if (!node.isText && node.type.spec.inline) parentOffset += 2;
    });

    const text = parent.textContent.slice(from - parentOffset, to - parentOffset);

    const lookbehind = match[1];

    textToInsert = `${ lookbehind }[[${ text }]`;
  }

  const transform = state.tr;

  transform.insertText(textToInsert, transform.mapping.map(from), transform.mapping.map(to));

  // Move the selection back one character so it's before the closing bracket
  transform.setSelection(TextSelection.between(
    transform.doc.resolve(transform.mapping.map(to) - 1),
    transform.doc.resolve(transform.mapping.map(to) - 1)
  ));

  transform.setMeta(TRANSACTION_META_KEY.COMPLETELY_UNDO_INPUT_RULE, true);

  return closeHistory(transform);
});

// --------------------------------------------------------------------------
function replaceWithHRRule(pattern, nodeType) {
  return new InputRule(pattern, function(state, match, start, end) {
    const transform = state.tr;

    // This is what `replaceRangeWith` does to make from/to work as hints, so they can be more useful in a variety of
    // selection positions
    if (start === end && this.doc.resolve(start).parent.content.size) {
      const point = insertPoint(this.doc, start, nodeType);
      if (point !== null) start = end = point;
    }

    // We want to insert a paragraph after the horizontal rule, so the user can just start typing in the new section
    const fragment = Fragment.from([
      nodeType.create(),
      state.schema.nodes.paragraph.createAndFill(),
    ])

    transform.replaceRange(start, end, new Slice(fragment, 0, 0));

    transform.setSelection(TextSelection.near(transform.doc.resolve(start)));

    return transform;
  });
}

// --------------------------------------------------------------------------
// Unlike codeMarkRule, this handles the _opening_ backtick being specified first, which means it needs to perform
// extra checks against the text that comes _after_ the matched input (since the inputrules only match the content _up
// to_ the character being inserted).
function reverseCodeMarkRule(markType, pattern) {
  return new InputRule(pattern, function(state, match, start, _end) {
    const lookbehind = match[1];
    start += lookbehind.length;

    const { doc } = state;

    const textNode = doc.nodeAt(start);
    if (!textNode || !textNode.isText) return;

    const $start = doc.resolve(start);
    const followingContent = textNode.textContent.substring($start.textOffset);

    const codeMarkMatch = /([^`\s](?:[^`]*?))`/.exec(followingContent);
    if (!codeMarkMatch) return;

    const transform = state.tr;

    const content = codeMarkMatch[1];

    // Replace entire match with content
    const contentStart = start + 1; // + 1 to skip over opening backtick
    const contentEnd = contentStart + content.length - 1; // - 1 to omit the closing backtick

    // Remove closing backtick
    transform.delete(contentEnd, contentEnd + 1);

    // Apply mark
    transform.addMark(start, contentEnd, markType.create());
    transform.removeStoredMark(markType);

    return transform;
  });
}

// --------------------------------------------------------------------------
function smileyRule(pattern) {
  const smiliesByLastCharacter = {
    "3": { "<3": "❤️", "</3": "💔" },
    "b": { ":b": "😛", ":-b": "😛", ";b": "😜", ";-b": "😜" },
    "D": { ":D": "😄", ":-D": "😄" },
    "o": { ":o": "😮", ":-o": "😮" },
    "p": { ":p": "😛", ":-p": "😛", ";p": "😜", ";-p": "😜" },
    ")": {
      "8)": "😎",
      ":o)": "🐵",
      "=)": "😃",
      "=-)": "😃",
      ";)": "😉",
      ";-)": "😉",
      ":)": "🙂",
      ":-)": "🙂",
    },
    ":": { "D:": "😦", "(:": "🙂", "):": "😞" },
    "|": { ":|": "😐" },
    ">": { ":>": "😆", ":->": "😆" },
    "(": { ">:(": "😠", ">:-(": "😠", ":(": "😞", ":-(": "😞" },
    "/": { ":/": "😕", ":-/": "😕" },
    "*": { ":*": "😘", ":-*": "😘" },
    "\\": { ":\\": "😕", ":-\\": "😕" },
  };

  return new InputRule(pattern, (state, match, start, end) => {
    const lastCharacter = match[3];
    const smilies = smiliesByLastCharacter[lastCharacter];
    if (!smilies) return false;

    const smileyText = match[2];
    const smiley = smilies[smileyText];
    if (!smiley) return false;

    const transform = state.tr;

    const lookbehind = match[1];
    start += lookbehind.length;

    const spaceCharacter = match[4];

    // Replace entire match with unicode smiley
    transform.insertText(
      smiley + spaceCharacter,
      transform.mapping.map(start),
      transform.mapping.map(end)
    );

    return transform;
  });
}

// --------------------------------------------------------------------------
// Create a set of table cells from the user's input
function tableRule(tableType, pattern) {
  return new InputRule(pattern, function(state, match, start, _end) {
    // Strip the first and last | characters so we don't end up with faux cells when we split on the following line
    const innerMatch = match[0].replace(/(^\||\|[\s]*\n*$)/g, "");
    const cellContentArray = innerMatch.split("|").map(cell => cell.trim());
    if (!cellContentArray.length) return false;
    const { doc, tr: transform, schema, schema: { nodes } } = state;
    const point = insertPoint(doc, start, nodes.table);
    // If existing start location isn't a valid place to insert a table (after deducting one for the cursor depth), we're out
    if ((point + 1) !== start) return false;
    const $contentPos = transform.doc.resolve(point);

    const cellNodes = cellContentArray.map(cellContent =>
      nodes.table_cell.createAndFill(null, nodes.paragraph.createAndFill(null, schema.text(cellContent)))
    );

    transform.deleteRange($contentPos.pos, $contentPos.pos + $contentPos.nodeAfter.nodeSize);

    let insertPos, resultNode;
    if ($contentPos.nodeBefore && $contentPos.nodeBefore.type === nodes.table) {
      insertPos = $contentPos.pos - 1;
      resultNode = nodes.table_row.createAndFill(null, cellNodes);
    } else {
      insertPos = $contentPos.pos;
      resultNode = nodes.table.createAndFill(null, nodes.table_row.createAndFill(null, cellNodes));
    }

    transform.insert(insertPos, resultNode);
    const selection = TextSelection.near(transform.doc.resolve(insertPos + resultNode.nodeSize, -1));
    transform.setSelection(selection);

    return transform;
  });
}

// --------------------------------------------------------------------------
export default function createInputRulesPlugin(schema) {
  const rules = [
    // --------------------------------------------------------------------------
    // Block quote from "> " at the start of a textblock
    wrappingInputRule(
      /^\s*>\s$/,
      schema.nodes.blockquote,
      null,
      // This prevents joining to a preceding blockquote, as that is somewhat annoying behavior when trying to make
      // multiple separate quotes in a row. If the user really wants them connected, they can backspace from the second
      // one to join it back to the first one.
      () => false,
    ),

    // --------------------------------------------------------------------------
    // HR from "---" at the start of a textblock - or an em-dash, as iOS will replace "--" with "—" (an em-dash)
    replaceWithHRRule(/^(?:--|—)-$/, schema.nodes.horizontal_rule),
  ];

  // --------------------------------------------------------------------------
  // Bullet list from "- " or "+ " or "* " at the start of a textblock
  if (schema.nodes.bullet_list_item) {
    rules.push(
      wrappingInputRule(/^\s*([-+*])\s$/, schema.nodes.bullet_list_item, () => {
        return { uuid: uuidv4() };
      })
    );
  }

  if (schema.nodes.table) {
    rules.push(
      // WBH thinks that in the longer term it would nice to allow table md regex to be invoked via Enter instead of
      // just space, but the prior art matching multi-line input rule didn't show easy path in 60 mins research Dec 2022
      tableRule(schema.nodes.table, /^(\|[^|]+)+\|\s$/)
    )
  }

  // --------------------------------------------------------------------------
  // Check list from "[] " or "[ ] " at the start of a textblock
  if (schema.nodes.check_list_item) {
    rules.push(
      wrappingInputRule(/^\s*\[\s?\]\s$/, schema.nodes.check_list_item, () => {
        return { createdAt: Math.floor(Date.now() / 1000), uuid: uuidv4() };
      })
    );
  }

  // --------------------------------------------------------------------------
  // Ordered list from a number followed by a dot at the start of a textblock
  if (schema.nodes.number_list_item) {
    rules.push(
      wrappingInputRule(/^(\d+)\.\s$/, schema.nodes.number_list_item, match => {
        return { offset: parseInt(match[1], 10) - 1, uuid: uuidv4() };
      })
    );
  }

  // --------------------------------------------------------------------------
  // Markdown style links and images from [link](https://blah) and ![image](https://image.png)
  if (schema.nodes.link) {
    rules.push(
      markdownLinkRule(schema.nodes.image, schema.nodes.link, /(\s|^)(!?)\[([^[]*)]\((.*)\)$/),
    );
  }

  rules.push(...[
    // --------------------------------------------------------------------------
    // Code-block from triple-backtick fence
    codeFenceRule(schema.nodes.code_block, /(^|\ufffc)```$/),

    // --------------------------------------------------------------------------
    // Heading from "# " at the beginning of a textblock
    textblockTypeInputRule(
      /^(#{1,6})\s$/,
      schema.nodes.heading,
      match => ({ level: Math.min(match[1].length, 3) })
    ),

    // --------------------------------------------------------------------------
    // Code-mark from surrounding single backticks
    codeMarkRule(schema.marks.code, /(\s|^|\ufffc|\(|-)(`)([^`\s][^`]*?)`$/),
    reverseCodeMarkRule(schema.marks.code, /(\s|^|\ufffc|\(|-)`$/),

    // --------------------------------------------------------------------------
    // Italics (emphasis) from _text_
    markRule(schema.marks.em, /(\s|^|\()(_)([^_\s][^_\n]*)_$/),

    // --------------------------------------------------------------------------
    // Bold (strong) from *text*
    markRule(schema.marks.strong, /(\s|^|\()(\*)([^*\s][^*\n]*)\*$/),

    // --------------------------------------------------------------------------
    // Highlight from ^text^
    markRule(schema.marks.highlight, /(\s|^|\()(\^)(([^^\s][^^\n]*)?[^^\s])\^$/),

    // --------------------------------------------------------------------------
    // Strikethrough from ~text~
    markRule(schema.marks.strikethrough, /(\s|^|\()(~)(([^~\s][^~\n]*)?[^~\s])~$/),

    // --------------------------------------------------------------------------
    // Wrap in [[ ... ] when typing [ with an expanded selection (of text) and
    // insert a closing [ when typing a second opening [ before whitespace
    noteLinkRule,

    // --------------------------------------------------------------------------
    // Replace some common smiley-style emojis with unicode emojis
    smileyRule(/(\s|^)(.{1,3}([3bDop):|>(/*\\]))(\s)$/),
  ]);

  return inputRules({ rules });
}

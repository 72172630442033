import { listItemNodeFromPos } from "lib/ample-editor/lib/list-item-util"
import { pluginTaskFromTask } from "lib/ample-editor/lib/plugin-task-util"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"

// --------------------------------------------------------------------------
async function buildPluginTask(doc, pos, fetchTask) {
  const $pos = doc.resolve(pos);
  const { attrs, content } = listItemNodeFromPos($pos);

  const editorTask = { ...attrs, content: content.toJSON() };

  if (fetchTask) {
    const task = await fetchTask(attrs.uuid);
    if (task) {
      const { localUUID, remoteUUID } = task;
      editorTask.localUUID = localUUID;
      editorTask.remoteUUID = remoteUUID;
    }
  }

  return pluginTaskFromTask(editorTask);
}

// --------------------------------------------------------------------------
export default async function getTaskOptionPluginActions(editorView, listItemCommandsMenuPos) {
  const { props: { hostApp: { fetchTask, getPluginActions } } } = editorView;

  let pluginActionPromises = null;

  const overrideSelection = {
    from: listItemCommandsMenuPos,
    head: listItemCommandsMenuPos,
    to: listItemCommandsMenuPos,
  };

  if (getPluginActions) {
    const { state: { doc } } = editorView;

    const checkEditorContext = buildEditorContext(editorView, null, overrideSelection);
    const checkPluginTask = await buildPluginTask(doc, listItemCommandsMenuPos, fetchTask);

    pluginActionPromises = await getPluginActions(
      [ PLUGIN_ACTION_TYPE.TASK_OPTION ],
      checkEditorContext,
      checkPluginTask,
    );
  }

  return (pluginActionPromises || []).map(pluginActionPromise => pluginActionPromise.then(pluginAction => {
    if (!pluginAction) return null;

    const { run } = pluginAction;
    return {
      ...pluginAction,
      run: async () => {
        const { state: { doc } } = editorView;

        const runEditorContext = buildEditorContext(editorView, null, overrideSelection);
        const runPluginTask = await buildPluginTask(doc, listItemCommandsMenuPos, fetchTask);

        return run(runEditorContext, runPluginTask);
      },
    };
  }));
}

import { addDays, addWeeks, format, isAfter, isSunday, startOfDay } from "date-fns"
import { min as arrayMin, max as arrayMax, sumBy } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import Tippy from "@tippyjs/react"

import { startOfWeek } from "lib/ample-util/date"

// --------------------------------------------------------------------------
const TOOLTIP_OFFSET = [ 0, 2 ];

// --------------------------------------------------------------------------
function dataToPoints({ data, width = 1, height = 1, max = arrayMax(data), min = arrayMin(data) }) {
  const len = data.length;
  const margin = 2;

  const vfactor = (height - margin * 2) / ((max - min) || 2);
  const hfactor = (width - margin * 2) / (len - (len > 1 ? 1 : 0));

  return data.map((d, i) => ({
    x: i * hfactor + margin,
    y: (max === min ? 1 : (max - d)) * vfactor + margin,
    width: hfactor
  }));
}

// --------------------------------------------------------------------------
function Line({ counts, days, delimiterDays, height, onDayClick, points, tooltipPrefix, values }) {
  const linePoints = points.map(p => [ p.x, p.y ]).reduce((a, b) => a.concat(b), []);

  const closePolyPoints = [
    points[points.length - 1].x, height, // Bottom right
    2, height, // Bottom left
    2, points[0].y, // Top left
  ];

  const fillPoints = linePoints.concat(closePolyPoints);

  const tooltips = points.map((p, i) => {
    const day = days[i];
    const count = counts[i];
    const value = values[i];
    const tooltip = (
      <React.Fragment>
        { tooltipPrefix || "" }{ format(day, "iiii, LLLL d") }<br />
        { count > 0 ? count : "No" } task{ count === 1 ? "" : "s" } completed<br />
        Task score: { Math.round(value * 100) / 100 }
      </React.Fragment>
    );

    return (
      <Tippy arrow={ false } content={ tooltip } delay={ 50 } offset={ TOOLTIP_OFFSET } key={ i } theme="completed-tasks">
        <svg
          className="tooltip-trigger"
          height={ height }
          width={ p.width }
          x={ p.x - p.width / 2 }
          y={ 0 }
          onClick={ onDayClick ? event => onDayClick(day, event) : null }
        >
          <rect
            className="tooltip-rect"
            x={ 0 }
            y={ 0 }
            width={ p.width }
            height={ height }
          />
          <line
            className="tooltip-line"
            x1={ p.width / 2 }
            x2={ p.width / 2 }
            y1="0"
            y2={ height }
          />
        </svg>
      </Tippy>
    );
  });

  const weekMarkers = delimiterDays.map(({ day, index }) => {
    const x = points[index].x;

    return (
      <line
        className="week-marker"
        key={ Math.floor(day.getTime() / 1000) }
        x1={ x }
        x2={ x }
        y1="0"
        y2={ height }
      />
    );
  });

  return (
    <g>
      <polyline className="line-fill" points={ fillPoints.join(" ") } />
      { weekMarkers }
      <polyline className="line-stroke" points={ linePoints.join(" ") }/>
      { tooltips }
    </g>
  );
}

// --------------------------------------------------------------------------
export default function CompletedTasksProgressBar(props) {
  const {
    endDay,
    height,
    now,
    onDayClick,
    startDay,
    tasksByDay,
    tasksByWeek,
    tooltipPrefix,
    width,
  } = props;

  const counts = [];
  const days = [];
  const delimiterDays = [];
  const values = [];

  if (tasksByDay) {
    const today = startOfDay(now);
    for (let day = startDay; !isAfter(day, today); day = startOfDay(addDays(day, 1))) {
      days.push(day);

      const count = (tasksByDay[day.toISOString()] || []).length;
      counts.push(count);

      const value = sumBy(tasksByDay[day.toISOString()] || [], task => task.value || 1);
      values.push(value);

      if (isSunday(day)) delimiterDays.push({ day, index: counts.length - 1 });
    }
  } else if (tasksByWeek) {
    const endWeek = startOfWeek(endDay);
    for (let week = startOfWeek(startDay); !isAfter(week, endWeek); week = addWeeks(week, 1)) {
      days.push(week);

      const count = (tasksByWeek[week.toISOString()] || []).length;
      counts.push(count);

      const value = sumBy(tasksByWeek[week.toISOString()] || [], task => task.value || 1);
      values.push(value);

      if (counts.length % 4 === 0) delimiterDays.push({ day: week, index: counts.length - 1 });
    }
  }

  const points = dataToPoints({ data: values, width, height });
  if (points.length === 0) return null;

  const svgOpts = {
    viewBox: `0 0 ${ width } ${ height }`,
    preserveAspectRatio: "xMaxYMid",
  };

  return (
    <div className="completed-tasks-progress-bar">
      <svg { ...svgOpts }>
        <defs>
          <linearGradient id="completed-tasks-fill-gradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="rgb(245,98,76)" stopOpacity="0.3" />
            <stop offset="50%" stopColor="rgb(255,178,56)" stopOpacity="0.2"/>
            <stop offset="100%" stopColor="rgb(255,215,56)" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient id="completed-tasks-stroke-gradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="rgb(245,98,76)" />
            <stop offset="50%" stopColor="rgb(255,178,56)" />
            <stop offset="100%" stopColor="rgb(255,215,56)" />
          </linearGradient>
        </defs>

        <Line
          counts={ counts }
          days={ days }
          delimiterDays={ delimiterDays }
          height={ height }
          onDayClick={ onDayClick }
          points={ points }
          tooltipPrefix={ tooltipPrefix }
          values={ values}
        />
      </svg>
    </div>
  );
}

CompletedTasksProgressBar.propTypes = {
  endDay: PropTypes.instanceOf(Date),
  height: PropTypes.number,
  now: PropTypes.number,
  onDayClick: PropTypes.func,
  startDay: PropTypes.instanceOf(Date),
  tasksByDay: PropTypes.object,
  tasksByWeek: PropTypes.object,
  tooltipPrefix: PropTypes.string,
  width: PropTypes.number,
};

import { endOfDay, format, parse } from "date-fns"

import { matchesTagFilters } from "lib/ample-util/filter-tag"

// --------------------------------------------------------------------------
// Constants and helper functions for the jots view / working with daily jot notes
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export const DAILY_JOTS_TAG_TEXT = "daily-jots";
const DAILY_NOTE_NAME_FORMAT = "LLLL do, yyyy";

// --------------------------------------------------------------------------
export function dailyNoteNameFromDate(date) {
  return format(date, DAILY_NOTE_NAME_FORMAT);
}

// --------------------------------------------------------------------------
export function dateFromDailyNoteName(dailyNoteName) {
  try {
    const date = parse(dailyNoteName, DAILY_NOTE_NAME_FORMAT, Date.now());
    if (dailyNoteNameFromDate(date) === dailyNoteName) {
      // We place the daily note at the last second of the day, so it sorts before any notes created that day, when
      // sorting by descending date (as in jots views).
      return endOfDay(date);
    } else {
      return null;
    }
  } catch (_error) {
    // `RangeError: Invalid time value`
    return null;
  }
}

// --------------------------------------------------------------------------
export function isEligibleDailyJotNote(tagFilters, note) {
  return note && !note.deleted && matchesTagFilters(tagFilters, note);
}

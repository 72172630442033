// --------------------------------------------------------------------------
export function listItemNodeFromPos($pos) {
  for (let i = 1; i < $pos.depth; i++) {
    const node = $pos.node(i);
    if (node.type.spec.isListItem) return node;
  }
  return null;
}

// --------------------------------------------------------------------------
// listItemElement: an HTMLElement, potentially from within AN, or as pasted from another app
export function indentFromListElement(listItemElement) {
  if (!listItemElement) return null;

  let match = (listItemElement.className || "").match(/\bindent-(\d)/);
  if (match) {
    return parseInt(match[1], 10);
  }

  // As used to indicate indent level when pasting from Google Docs (where aria-level is on li item but needs to
  // be found when we call indentFromListItem on its parent ul)
  const ariaElement = listItemElement.querySelector("[aria-level]");
  if (ariaElement) {
    const ariaLevel = ariaElement.getAttribute("aria-level");
    return parseInt(ariaLevel, 10) - 1; // Google's aria-level is 1-based
  }

  if (listItemElement.parentNode) {
    match = (listItemElement.parentNode.className || "").match(/\bindent-(\d)/);
    if (match) {
      return parseInt(match[1], 10);
    } else {
      let parentIterator = listItemElement;
      let indent = -1; // Because the first ul/ol starts at indent level 0 in AN
      while ((parentIterator = parentIterator.parentNode) && (parentIterator = (parentIterator.closest("ul") || parentIterator.closest("ol")))) {
        indent += 1;
      }
      if (indent >= 0) return indent;
    }
  }

  return null;
}

// --------------------------------------------------------------------------
// listItemDepthFromSchema replicated cuz importing it causes circular dependency as of July 2023
function listItemDepth($pos) {
  return $pos.doc.type.schema.nodes.tasks_group ? 2 : 1;
}

// --------------------------------------------------------------------------
// Return the pos of the listItem prior to $listItemPos
// `$listItemPos`: A position within a list item where we should get the previous border between the current
//   list item and its siblings. If $listItemPos is already at the border, we'll return the border before that.
export function previousListItemPos($listItemPos) {
  const depth = listItemDepth($listItemPos);
  let candidateBorderPos = $listItemPos.before(depth);
  if (candidateBorderPos === $listItemPos.pos) { // We were already starting on the border
    if ($listItemPos.nodeBefore && $listItemPos.nodeBefore.type.spec.isListItem) {
      candidateBorderPos = $listItemPos.pos - $listItemPos.nodeBefore.nodeSize;
    } else {
      return null;
    }
  }
  const $borderPos = $listItemPos.doc.resolve(candidateBorderPos);
  if (!$borderPos || !$borderPos.nodeAfter) return null; // WBH can't imagine how this would happen, but maybe it could?
  if ($borderPos.nodeAfter.type.spec.isListItem) {
    return $borderPos;
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
// Return the $pos of the first consecutive list item in a series of list items of which $listItemPos is a
// member. If there are no previous list items, the start of the line for $listItemPos is returned.
export function startOfList($listItemPos) {
  if (!$listItemPos) return null;
  let $pos = $listItemPos;
  // Start of the line for list item passed in
  while ($pos) {
    const $subsequent = previousListItemPos($pos);
    if ($subsequent) {
      $pos = $subsequent;
    } else {
      break;
    }
  }
  return $pos;
}

// --------------------------------------------------------------------------
// Return pos for the start of the list item after $listItemPos, or null if $listItemPos not a list item.
// If $listItemPos is already at the border, we'll return the border after that, if it is preceded by a list item.
export function nextListItemPos($listItemPos) {
  const depth = listItemDepth($listItemPos);
  let candidateBorderPos = $listItemPos.after(depth);
  if (candidateBorderPos === $listItemPos.pos) { // We were already starting on the border
    if ($listItemPos.nodeAfter && $listItemPos.nodeAfter.type.spec.isListItem) {
      candidateBorderPos = $listItemPos.pos + $listItemPos.nodeAfter.nodeSize;
    } else {
      return null;
    }
  }
  const $borderPos = $listItemPos.doc.resolve(candidateBorderPos);
  if (!$borderPos || !$borderPos.nodeBefore) return null;
  if ($borderPos.nodeBefore.type.spec.isListItem) {
    return $borderPos;
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
// Returns the $pos where nodeBefore is the final item in a list contiguous with $listItemPos,
// or null if $listItemPos doesn't seem to be part of a list
export function endOfList($listItemPos) {
  if (!$listItemPos) return null;
  let $pos = $listItemPos;
  while ($pos) {
    const $subsequent = nextListItemPos($pos);
    if ($subsequent && $subsequent.pos > $pos.pos) {
      $pos = $subsequent;
    } else {
      break;
    }
  }
  return $pos;
}

// --------------------------------------------------------------------------
// Returns true iff the entire range of $from through $to is comprised of items that are spec.isListItem;
// in notes view these nodes will always be present at depth=1, but in tasks mode, they are children of the
// tasksSchema.task_group so we'll recurse into that in search of checklistitems before concluding a false result
//
// `onlySingleParent` if true, then the additional constraint is applied that only one node can be the parent (implying
//   that an ExpandingListItemSelection can be used to capture the full range)
export function allListItemsBetween($from, $to, { onlySingleParent = false } = {}) {
  const doc = $from.node(0);
  let deepestParentDepth = null;
  let allEligibleListItems = true;
  doc.nodesBetween($from.pos, $to.pos, (node, _pos) => {
    if (node.type.spec.isListItem) {
      if (onlySingleParent) {
        if (deepestParentDepth === null) {
          deepestParentDepth = node.attrs.indent;
          return false;
        } else if (node.attrs.indent <= deepestParentDepth) {
          allEligibleListItems = false;
        }
      } else {
        return false;
      }
    }
    if (!allEligibleListItems) return false;

    if (node.type.name === "tasks_group") {
      // We shall recurse in search of tasks within this group
    } else {
      allEligibleListItems = false;
      return false;
    }
  });
  return allEligibleListItems;
}

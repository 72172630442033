import {
  endOfWeek as originalEndOfWeek,
  format,
  isThisWeek as originalIsThisWeek,
  isThisYear,
  isToday,
  isTomorrow,
  startOfWeek as originalStartOfWeek,
  subDays,
} from "date-fns"

// --------------------------------------------------------------------------
// Utility functions that operate on a Date instance (or on the possible
// arguments that date-fns can take in place of a Date instance, e.g. a
// millisecond timestamp number).
//
// Note that these also wrap various date-fns functions to provide results
// for the currently set options like the first day of the week or hour format.
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Matching https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
export const DAY_OF_WEEK = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};

// --------------------------------------------------------------------------
const weekConfig = {
  // the index of the first day of the week (0 - Sunday); see https://date-fns.org/v2.29.3/docs/endOfWeek#arguments
  weekStartsOn: DAY_OF_WEEK.SUNDAY,
};

// --------------------------------------------------------------------------
export function daysAgoTimestamp(now, daysAgo) {
  return Math.floor(subDays(now, daysAgo) / 1000);
}

// --------------------------------------------------------------------------
export function endOfWeek(date) {
  return originalEndOfWeek(date, weekConfig);
}

// --------------------------------------------------------------------------
export function formatDate(date, { short = false, strict = false } = {}) {
  if (!date || isNaN(date)) return "unknown";

  const formatString = (!strict || short) && isThisYear(date) ? "LLL d" : "LLL d yyyy";
  const formattedDate = format(date, formatString);

  if (short) {
    return formattedDate;
  } else {
    let prefix;
    if (!strict && isToday(date)) {
      prefix = "Today";
    } else if (!strict && isTomorrow(date)) {
      prefix = "Tomorrow";
    } else {
      prefix = format(date, "iiii");
    }

    return `${ prefix }, ${ formattedDate }`;
  }
}

// --------------------------------------------------------------------------
export function formatDateTime(date, { strictDate = false } = {}) {
  if (!date || isNaN(date)) return "unknown";

  return `${ formatDate(date, { strict: strictDate }) } at ${ formatTime(date) }`
}

// --------------------------------------------------------------------------
export function formatTime(date, { lowercase = false } = {}) {
  if (!date || isNaN(date)) return "unknown";

  return format(date, `h:mm${ lowercase ? "aaa" : "a" }`);
}

// --------------------------------------------------------------------------
export function getWeekStartsOn() {
  return weekConfig.weekStartsOn;
}

// --------------------------------------------------------------------------
export function isThisWeek(date) {
  return originalIsThisWeek(date, weekConfig);
}

export function setWeekConfig({ weekStartsOn }) {
  weekConfig.weekStartsOn = weekStartsOn;
}

// --------------------------------------------------------------------------
export function startOfWeek(date) {
  return originalStartOfWeek(date, weekConfig);
}

// --------------------------------------------------------------------------
export function timestampFromDate(date) {
  return Math.floor(date.getTime() / 1000);
}

import { chainCommands } from "prosemirror-commands"
import { history, redo, undo } from "prosemirror-history"
import { EditorState, Selection } from "prosemirror-state"
import { StepMap } from "prosemirror-transform"
import React from "react"
import { LineRipple } from "@rmwc/line-ripple"

import EditorViewWrapper from "lib/ample-editor/components/editor-view-wrapper"
import { createDescriptionKeymapPlugin } from "lib/ample-editor/components/rich-footnote/description-keymap-plugin"
import DescriptionLinkView from "lib/ample-editor/components/rich-footnote/description-link-view"
import descriptionSchema from "lib/ample-editor/components/rich-footnote/description-schema"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { isApplePlatform } from "lib/ample-editor/lib/client-info"
import { undoInputRuleWithoutHistory } from "lib/ample-editor/lib/commands"
import {
  descriptionDocumentFromValue,
  valueFromDescriptionDocument,
} from "lib/ample-editor/lib/rich-footnote-util"
import shouldSkipIosEnterTransaction from "lib/ample-editor/lib/should-skip-ios-enter-transaction"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import codeBlockPlugin from "lib/ample-editor/plugins/code-block-plugin"
import createCodePlugin from "lib/ample-editor/plugins/code-plugin"
import createExpressionPlugin from "lib/ample-editor/plugins/expression-plugin"
import { createFindPlugin, setFindPluginQuery } from "lib/ample-editor/plugins/find-plugin"
import createInputRulesPlugin from "lib/ample-editor/plugins/input-rules-plugin"
import { placeholderPlugin } from "lib/ample-editor/plugins/placeholder"
import CodeBlockView from "lib/ample-editor/views/code-block-view"
import EmbedView from "lib/ample-editor/views/embed-view"

// --------------------------------------------------------------------------
function buildStepMap(newDocument, oldDocument) {
  const diffStart = oldDocument.content.findDiffStart(newDocument.content);
  const diffEnd = oldDocument.content.findDiffEnd(newDocument.content);
  if (!diffStart || !diffEnd) return null;

  return new StepMap([
    diffStart,
    diffEnd.a - diffStart,
    diffEnd.b - diffStart
  ]);
}

// --------------------------------------------------------------------------
export default class DescriptionEditor extends React.Component {
  static contextType = HostAppContext;

  // --------------------------------------------------------------------------
  static getDerivedStateFromProps(props, state) {
    const document = descriptionDocumentFromValue(props.value);

    if (state.document && state.document.eq(document)) {
      return null;
    }

    return { document };
  }

  _domRef = React.createRef();
  _editorProps = null;
  _editorState = null;
  _editorView = null;
  _lineRippleRef = React.createRef();
  _nodeViews = null;
  _targetDocument = null;

  // --------------------------------------------------------------------------
  constructor(props, context) {
    super(props);

    const document = descriptionDocumentFromValue(props.value);

    this.state = {
      document,
      focused: false,
    };

    this._editorProps = { hostApp: context || {} };

    this._editorState = EditorState.create({
      doc: document,
      placeholder: props.placeholder,
      plugins: [
        createCodePlugin(descriptionSchema),
        codeBlockPlugin,
        createInputRulesPlugin(descriptionSchema),
        createExpressionPlugin({ disableExpressionMenu: true }),
        createDescriptionKeymapPlugin(descriptionSchema),
        createFindPlugin(),
        history(),
        placeholderPlugin,
      ],
    });

    this._nodeViews = {
      code_block: (node, editorView, getPos) => new CodeBlockView(node, editorView, getPos),
      embed: (node, editorView, getPos) => new EmbedView(node, editorView, getPos),
      link: (node, editorView, _getPos, _decorations) => new DescriptionLinkView(editorView, node),
    };
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    if (this.props.autoFocus) this.focus();
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const { document } = this.state;

    if (document !== prevState.document) {
      // Handle the document being changed by an external agent (e.g. an in-place update of the outer document)
      if (!this._targetDocument || !this._targetDocument.eq(document)) {
        this._replaceDocument(document, buildStepMap(document, prevState.document));
      }

      this._targetDocument = null;
    }

    if (prevProps.disabled !== this.props.disabled) {
      // We need to force the ProseMirror view to update the dom so the contentEditable attribute is set correctly
      if (this._editorView) this._editorView.updateState(this._editorState);
    }
  }

  // --------------------------------------------------------------------------
  contains = element => {
    const { current: dom } = this._domRef;
    return dom ? dom.contains(element) : false;
  };

  // --------------------------------------------------------------------------
  find = (query, callback = null, { currentIndex = null } = {}) => {
    if (this._editorView) {
      const { dispatch, state } = this._editorView;
      dispatch(setFindPluginQuery(state.tr, callback, query, { currentIndex }));
      return true;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  focus = () => {
    if (this._editorView) this._editorView.focus();
  };

  // --------------------------------------------------------------------------
  isFocused() {
    return this.state.focused;
  }

  // --------------------------------------------------------------------------
  render() {
    const { disabled, readonly } = this.props;
    const { focused } = this.state;

    let className = "description-editor";
    if (disabled) className += " disabled";
    if (focused) className += " focused";
    if (readonly) className += " readonly";

    return (
      <div
        className={ className }
        onBlur={ this._onBlur }
        onFocus={ this._onFocus }
        onKeyDown={ this._onKeyDown }
        onKeyUp={ this._onKeyUp }
        ref={ this._domRef }
      >
        <EditorViewWrapper
          className="description-editor-editor"
          dispatchTransaction={ this._dispatchTransaction }
          editorProps={ this._editorProps }
          editorState={ this._editorState }
          isEditable={ this._isEditable }
          nodeViews={ this._nodeViews }
          setEditorView={ this._setEditorView }
        />
        <span className="mdc-floating-label">Description</span>
        <LineRipple ref={ this._lineRippleRef }/>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.disabled !== this.props.disabled ||
      nextProps.readonly !== this.props.readonly ||
      nextState.document !== this.state.document ||
      nextState.focused !== this.state.focused;
  }

  // --------------------------------------------------------------------------
  _dispatchTransaction = transaction => {
    const { doc } = this._editorState;

    if (shouldSkipIosEnterTransaction(this._editorView, transaction)) return;

    const editorState = this._editorState.apply(transaction);

    if (this._editorView !== null) {
      this._editorView.updateState(editorState);
    }

    this._editorState = editorState;

    const { dispatchChanges } = this.props;
    if (dispatchChanges) {
      // Note we always want to call dispatchChanges, so the containing app can update toolbar state to reflect
      // changes to selection/storedMarks
      if (!editorState.doc.eq(doc)) {
        this._targetDocument = editorState.doc;
        const value = valueFromDescriptionDocument(editorState.doc);
        dispatchChanges(value);
      } else {
        dispatchChanges(null);
      }
    }
  };

  // --------------------------------------------------------------------------
  _isEditable = () => {
    return !this.props.readonly && !this.props.disabled;
  };

  // --------------------------------------------------------------------------
  _onBlur = () => {
    const { current: lineRipple } = this._lineRippleRef;
    if (lineRipple) lineRipple.foundation.deactivate();

    this.setState({ focused: false });
  };

  // --------------------------------------------------------------------------
  _onFocus = () => {
    const { current: lineRipple } = this._lineRippleRef;
    if (lineRipple) lineRipple.foundation.activate();

    this.setState({ focused: true });

    const { dispatchChanges } = this.props;
    if (dispatchChanges) dispatchChanges(null);
  };

  // --------------------------------------------------------------------------
  _onKeyDown = event => {
    if (this._isEditable() && this._editorView) {
      let command = null;

      if (event.key === "z" || event.key === "Z") {
        if (isApplePlatform ? event.metaKey : event.ctrlKey) {
          command = event.shiftKey
            ? redo
            : chainCommands(
              undoInputRuleWithoutHistory,
              undo
            );
        }
      } else if (event.key === "y" && !isApplePlatform && event.ctrlKey) {
        command = redo;
      }

      if (command) command(this._editorView.state, this._editorView.dispatch);
    }

    // ProseMirror will preventDefault on input events that it handles, in which case we don't want
    // to have PM accept the input and then potentially react to it in the wrapper UI (e.g. hitting tab),
    // though PM says it handles escape even when the keymap doesn't provide handling for it
    if (event.isDefaultPrevented() && event.key !== "Escape") return;

    const { onKeyDown } = this.props;
    if (onKeyDown) onKeyDown(event);
  };

  // --------------------------------------------------------------------------
  _onKeyUp = event => {
    if (this.isFocused()) {
      this._editorView.someProp("handleKeyUp", f => f(this._editorView, event));
    }
  };

  // --------------------------------------------------------------------------
  _registerEditorView = editorView => {
    if (!editorView) return;

    const { registerEditorView } = this.props;
    if (registerEditorView) registerEditorView(editorView);
  };

  // --------------------------------------------------------------------------
  _replaceDocument = (document, stepMap) => {
    let editorState = this._editorState;

    if (stepMap) {
      try {
        editorState["selection"] = editorState.selection.map(document, stepMap);
      } catch (_error) {
        // If the mapping fails (which can happen in for reasons unknown, where the position is out of range when
        // mapping through the StepMap) it's not the end of the world, since we're about to make sure it still falls
        // within the bounds of the new document.
      }
    }

    // Ensure the selection stays within a document that may have just been shortened (if the document was completely
    // cleared out, for example, there is likely to be no stepMap).
    const { selection } = editorState;
    const endSelection = Selection.atEnd(document);
    if (selection.from > endSelection.from || selection.to > endSelection.to) {
      editorState["selection"] = endSelection;
    }

    // Set the doc to our replacement doc
    editorState["doc"] = document;

    // Apply another dummy transform so plugins get a chance to update their views with the new doc
    editorState = editorState.apply(editorState.tr.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false));

    if (this._editorView !== null) {
      this._editorView.updateState(editorState);
    }

    this._editorState = editorState;
  };

  // --------------------------------------------------------------------------
  _setEditorView = editorView => {
    this._editorView = editorView;
    this._registerEditorView(editorView);
  };
}

import { GapCursor } from "prosemirror-gapcursor"
import { Plugin, PluginKey, TextSelection } from "prosemirror-state"
import { Mapping } from "prosemirror-transform"
import { Decoration, DecorationSet } from "prosemirror-view"

import { HAS_CHILDREN_CLASS } from "lib/ample-editor/lib/collapsible-defines"
import { transactionMarkedReadonly } from "lib/ample-editor/lib/transaction-util"
import { Fragment, Slice } from "prosemirror-model";

// --------------------------------------------------------------------------
const pluginKey = new PluginKey("collapsible-nodes");

// --------------------------------------------------------------------------
export function isPosCollapsibleNode(state, pos) {
  const pluginState = pluginKey.getState(state);
  return pluginState ? (pos in pluginState.isCollapsibleByPos) : false;
}

// --------------------------------------------------------------------------
// Returns `true` if the node that would be found by doc.nodeAt() of `documentPos` is a child of a collapsed parent,
// as judged by the decorations stored in plugin's state
export function isPosHidden(state, pos) {
  return nodePosHidingPos(state, pos) !== null;
}

// --------------------------------------------------------------------------
export function isPosHiddenNode(state, pos) {
  const pluginState = pluginKey.getState(state);
  return pluginState ? (pos in pluginState.hiddenNodeEndByPos) : false;
}

// --------------------------------------------------------------------------
export function nodesHidingNodeAtPos(state, pos) {
  if (!isPosHiddenNode(state, pos)) return [];

  const { doc } = state;
  const { type: { schema } } = doc;
  const { nodes: { heading: headingNodeType, horizontal_rule: horizontalRuleNodeType } } = schema;

  const node = doc.nodeAt(pos);

  let canBeHiddenByListItemIndent = node.type.spec.isListItem ? node.attrs.indent - 1 : null;
  let canBeHiddenByHeadingLevel = node.type === headingNodeType ? node.attrs.level - 1 : 100; // Arbitrarily large level

  const $from = doc.resolve(pos);
  const topLevelIndex = $from.index(0);

  const result = [];
  for (let i = topLevelIndex - 1; i > -1; i--) {
    const siblingNode = doc.maybeChild(i);
    if (!siblingNode) break;

    if (canBeHiddenByListItemIndent !== null) {
      if (siblingNode.type.spec.isListItem) {
        if (siblingNode.attrs.collapsed && siblingNode.attrs.indent <= canBeHiddenByListItemIndent) {
          result.push({
            node: siblingNode,
            nodePos: $from.posAtIndex(i, 0),
          });

          canBeHiddenByListItemIndent = siblingNode.attrs.indent - 1;
        }
      } else {
        canBeHiddenByListItemIndent = null;
      }
    }

    if (canBeHiddenByHeadingLevel !== null) {
      if (siblingNode.type === headingNodeType) {
        if (siblingNode.attrs.collapsed && siblingNode.attrs.level <= canBeHiddenByHeadingLevel) {
          result.push({
            node: siblingNode,
            nodePos: $from.posAtIndex(i, 0),
          });

          canBeHiddenByHeadingLevel = siblingNode.attrs.level - 1;
        }
      } else if (siblingNode.type === horizontalRuleNodeType) {
        canBeHiddenByHeadingLevel = null;
      }
    }
  }

  return result;
}

// --------------------------------------------------------------------------
// Return the pos for the first non-hidden pos found at or after fromPos, or null if none can be found
export function nonHiddenPosAfter(fromPos, state, untilValidCursor = false) {
  return nonHiddenPosTraverse(1, fromPos, state, untilValidCursor);
}

// --------------------------------------------------------------------------
// Return the pos for the first non-hidden pos found at or before fromPos, or null if none can be found
export function nonHiddenPosBefore(fromPos, state, untilValidCursor = false) {
  return nonHiddenPosTraverse(-1, fromPos, state, untilValidCursor);
}

// --------------------------------------------------------------------------
// Given an arbitrary position, returns the position of the node hiding it, as can be used with the other exported
// functions in this file, or `null` if the given pos isn't hidden.
export function nodePosHidingPos(state, pos) {
  const pluginState = pluginKey.getState(state);
  if (!pluginState) return null;

  return Object.keys(pluginState.hiddenNodeEndByPos).find(from => {
    const to = pluginState.hiddenNodeEndByPos[from];
    return pos >= from && pos < to;
  }) || null;
}

// --------------------------------------------------------------------------
// Local functions
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
function adjacentNonHiddenPos(pos, lastPos, state) {
  const dir = pos > lastPos ? 1 : -1;
  let $pos;
  const { doc } = state;
  let resultPos = nonHiddenPosTraverse(dir, pos, state, doc, true);
  if (resultPos !== null) {
    $pos = doc.resolve(resultPos);
  } else {
    if (dir > 0) { // We hit end of doc without finding non-hidden space. Move backwards from end of doc until we find something good.
      resultPos = nonHiddenPosTraverse(-1, doc.content.size - 1, state, doc, true);
      $pos = doc.resolve(resultPos);
    } else {
      $pos = doc.resolve(0);
    }
  }
  return $pos;
}

// --------------------------------------------------------------------------
// Returns the pos for the next non-hidden node (optionally where the cursor can be placed),
// starting from `fromPos` in the direction of `dir`. Returns null if no such pos can be found in given dir before
// beginning or end of doc.
// `dir` +1 to search forward, -1 to search backward
// `fromPos` position index, the first position that we will check for whether node is hidden (thus the first position eligible to be returned as $pos)
// `state` state from which pluginState will be grabbed, and decorationSet extracted from pluginState, and doc will be grabbed
// `untilValidCursor` if true, we'll continue traversing until we find a position that would be valid to place cursor
function nonHiddenPosTraverse(dir, fromPos, state, untilValidCursor) {
  const { doc } = state;
  let resultPos = fromPos - dir;
  let hidden = true;
  let validTextSelection = !untilValidCursor; // If we don't care about cursor, the "until valid cursor" vars are always true
  let validGapCursor = !untilValidCursor;

  while (hidden || (!validGapCursor && !validTextSelection)) {
    resultPos += dir;
    if (resultPos <= 0 || resultPos >= doc.content.size) {
      if (resultPos < 0 || resultPos > doc.content.size) return null;
      // End and beginning of doc are always considered visible, but not suitable for cursor
      if (untilValidCursor) {
        // If we've reached end of the document and we need a valid cursor, we're SOL. If we were going in the other direction, fall through to below
        if ((resultPos === 0 && dir < 0) || (resultPos === doc.content.size && dir > 0)) return null;
      } else {
        return resultPos;
      }
    }
    const $pos = doc.resolve(resultPos);
    if (untilValidCursor) {
      validTextSelection = $pos.parent.inlineContent;
      validGapCursor = GapCursor.valid($pos);
    }
    hidden = isPosHidden(state, $pos.pos);
  }

  return resultPos;
}

// --------------------------------------------------------------------------
// Scan the full doc for nodes with children and nodes that should be hidden based upon ancestors with attrs.collapsed
function pluginStateFromDocument(doc, mapping, pluginStateWas = null) {
  const pluginState = {
    decorationCounter: (pluginStateWas ? pluginStateWas.decorationCounter : 0) + 1,
    decorationSet: DecorationSet.empty,
    hiddenNodeEndByPos: {},
    isCollapsibleByPos: {},
  };

  if (!doc) return pluginState;

  // To optimize Decoration creation, we'll re-use decorations from the previous state wherever possible, only
  // re-creating the minimal set of decorations
  const decorations = [];

  let visitChildNode;
  let visitParentNode;
  if (pluginStateWas) {
    const { hiddenNodeEndByPos: hiddenNodeEndByPosWas, isCollapsibleByPos: isCollapsibleByPosWas } = pluginStateWas;

    const decorationSpec = { decorationCounter: pluginState.decorationCounter };
    const invertedMapping = mapping ? mapping.invert() : new Mapping();

    visitChildNode = (pos, node, isHidden) => {
      if (isHidden) {
        pluginState.hiddenNodeEndByPos[pos] = pos + node.nodeSize;
      }

      const posWas = invertedMapping.map(pos);
      const wasHidden = posWas in hiddenNodeEndByPosWas;
      if (wasHidden !== isHidden) {
        decorations.push(Decoration.node(pos, pos + node.nodeSize, {}, decorationSpec));
      }
    };

    visitParentNode = (pos, node, isCollapsible) => {
      if (isCollapsible) {
        pluginState.isCollapsibleByPos[pos] = true;
      }

      const posWas = invertedMapping.map(pos);
      const wasCollapsible = posWas in isCollapsibleByPosWas;
      if (wasCollapsible !== isCollapsible) {
        decorations.push(Decoration.node(pos, pos + node.nodeSize, {}, decorationSpec));
      }
    };
  } else {
    // When there's no existing state, we're initializing the document, in which case the NodeViews will be created
    // and we don't need to force them to update with decorations.
    visitChildNode = (pos, node, isHidden) => {
      if (isHidden) {
        pluginState.hiddenNodeEndByPos[pos] = pos + node.nodeSize;
      }
    };

    visitParentNode = (pos, node, isCollapsible) => {
      if (isCollapsible) {
        pluginState.isCollapsibleByPos[pos] = true;
      }
    };
  }

  // We'll iterate through immediate children of the document (the only place collapsible nodes can be found in the
  // document), using a stack of ancestor nodes as a context to determine if any given node is hidden due to an
  // ancestor, and to tell parent nodes (the last ancestor) whether they have children.
  const listItemAncestorsStack = [
    // Each entry in the form of:
    // {
    //   node,
    //   collapsed,
    //   hasCollapsibleContent,
    //   indent,
    //   pos,
    //   type,
    // }
  ];

  // When an ancestor is popped off the the stack, we can mark it as having children if we've iterated over a child
  // since it was pushed on the stack.
  const popListItemAncestor = () => {
    const ancestor = listItemAncestorsStack.pop();
    if (ancestor) {
      const { node, pos } = ancestor;
      visitParentNode(pos, node, ancestor.hasCollapsibleContent);
    }
  };

  // For headings, we only really care about two things:
  //  1. Are we in a node after a collapsed heading?
  //  2. Are in a node immediately after a heading that would constitute the heading's section having content?
  let collapsedHeadingNode = null;
  let lastHeading = null;

  const { type: { schema } } = doc;
  const { nodes: { heading: headingNodeType, horizontal_rule: horizontalRuleNodeType } } = schema;

  doc.forEach((node, pos) => {
    const isHeadingNode = node.type === headingNodeType;
    const isListItemNode = node.type.spec.isListItem;

    // When visiting a node, it may introduce a new ancestor scope for subsequent children or close a previous scope
    for (let i = listItemAncestorsStack.length - 1; i > -1; i--) {
      const listItemAncestor = listItemAncestorsStack[i];

      if (!isListItemNode || node.attrs.indent <= listItemAncestor.indent) {
        popListItemAncestor();
      } else {
        listItemAncestor.hasCollapsibleContent = true;

        visitChildNode(pos, node, listItemAncestor.collapsed);

        break;
      }
    }

    if (lastHeading) {
      const { node: lastHeadingNode, pos: lastHeadingPos } = lastHeading;

      if (!isHeadingNode || node.attrs.level > lastHeadingNode.attrs.level) {
        pluginState.isCollapsibleByPos[lastHeadingPos] = true;

        decorations.push(Decoration.node(
          lastHeadingPos, lastHeadingPos + lastHeadingNode.nodeSize, { class: HAS_CHILDREN_CLASS }, {}
        ));
      }

      // At this point, we have all the info that we need w.r.t. `lastHeadingNode` - i.e. whether it has any nodes
      // that can be considered content
      lastHeading = null;
    }

    if (collapsedHeadingNode) {
      if (isHeadingNode && node.attrs.level <= collapsedHeadingNode.attrs.level) {
        collapsedHeadingNode = node.attrs.collapsed ? node : null;
      } else {
        decorations.push(Decoration.node(pos, pos + node.nodeSize, { style: "display: none;" }, {}));

        if (node.type === horizontalRuleNodeType) {
          // Because this is a leaf node, the position is actually 1 greater than `pos`, while nodeSize is always 1,
          // but we want to span the _ending_ position of the node when considering a position hidden, so there's no
          // GapCursor at the end position
          pluginState.hiddenNodeEndByPos[pos] = pos + node.nodeSize + 1;

          // The HR ends the heading's section
          collapsedHeadingNode = null;
        } else {
          pluginState.hiddenNodeEndByPos[pos] = pos + node.nodeSize;
        }
      }

      if (isHeadingNode) lastHeading = { node, pos };
    } else if (isHeadingNode) {
      if (node.attrs.collapsed) {
        collapsedHeadingNode = node;
      }
      lastHeading = { node, pos };
    } else if (isListItemNode) {
      const ancestor = listItemAncestorsStack[listItemAncestorsStack.length - 1];

      listItemAncestorsStack.push({
        node,
        pos,
        collapsed: (ancestor ? ancestor.collapsed : false) || node.attrs.collapsed,
        hasCollapsibleContent: false,
        indent: node.attrs.indent,
        type: node.type,
      });

      // The node may have formerly been a child, in which case we want to make sure it updates
      if (!ancestor) visitChildNode(pos, node, false);
    }
  });

  while (listItemAncestorsStack.length > 0) {
    popListItemAncestor();
  }

  // Now we can update the decoration set, removing any existing decorations that weren't retained and adding any
  // new decorations

  pluginState.decorationSet = DecorationSet.create(doc, decorations);

  return pluginState;
}

// --------------------------------------------------------------------------
// Called _before_ the `ClipboardSerializer` is used to serialize the slice - can return a new/adjusted slice
// to serialize instead of the originally copied/cut slice
function transformCopied(slice, editorView) {
  const { state, state: { doc, schema: { nodes: { heading: headingType } } } } = editorView;

  // We only care about the collapsed node being the last child node, as any selection that spans the hidden section
  // will include the hidden nodes too
  const { content: { lastChild } } = slice;
  if (!lastChild) return slice;

  if (lastChild.type !== headingType) return slice;
  if (!lastChild.attrs.collapsed) return slice;

  let newFragment = Fragment.empty;
  slice.content.forEach(node => {
    newFragment = newFragment.append(Fragment.from(node));

    if (node !== lastChild) return;

    // The slice is fully detached form the document, but we can find the original node/position in the document by
    // finding the exact node object, as the slice will reference the same object. The entire node needs to be selected
    // for the references to match - if any subset of the node's content is selected, the slice will have a different
    // node object (which is desirable as we only want to apply this to fully selected nodes).
    let matchingDocNode = null;
    let matchingDocNodePos = null;
    doc.content.forEach((docNode, docPos) => {
      if (docNode === node) {
        matchingDocNode = docNode;
        matchingDocNodePos = docPos;
      }
    });
    if (!matchingDocNode) return;

    const hiddenContentPos = matchingDocNodePos + matchingDocNode.nodeSize;
    const hiddenContentEndPos = nonHiddenPosAfter(hiddenContentPos, state, false);
    if (hiddenContentEndPos === hiddenContentPos) return;

    const { content: hiddenContent } = doc.slice(hiddenContentPos, hiddenContentEndPos);
    newFragment = newFragment.append(hiddenContent);
  });

  return new Slice(newFragment, slice.openStart, slice.openEnd);
}

// --------------------------------------------------------------------------
const collapsibleNodesPlugin = new Plugin({
  key: pluginKey,

  // --------------------------------------------------------------------------
  props: {
    decorations(state) {
      const pluginState = pluginKey.getState(state);
      return pluginState ? pluginState.decorationSet : DecorationSet.empty;
    },
    transformCopied,
  },

  // --------------------------------------------------------------------------
  state: {
    init: (_config, state) => pluginStateFromDocument(state.doc),
    apply: (tr, pluginState) => {
      if (tr.docChanged) {
        return pluginStateFromDocument(tr.doc, tr.mapping, pluginState);
      } else {
        return pluginState;
      }
    }
  },
  // --------------------------------------------------------------------------
  // If cursor ends up at a hidden position (e.g., because it resided within an area that was newly hidden), move
  // it to the nearest non-hidden space
  appendTransaction: (transactions, oldState, newState) => {
    if (transactions.find(transactionMarkedReadonly)) return;

    let { selection: { $anchor, $head } } = newState;
    const { selection: { anchor: anchorWas, head: headWas } } = newState;

    if (isPosHidden(newState, $anchor.pos)) {
      $anchor = adjacentNonHiddenPos($anchor.pos, oldState.selection.anchor, newState);
    }

    if ($anchor.pos !== $head.pos) {
      if (isPosHidden(newState, $head.pos)) {
        $head = adjacentNonHiddenPos($head.pos, oldState.selection.head, newState);
      }
    }

    if ($anchor.pos !== anchorWas || $head.pos !== headWas) {
      let selection;
      if ($head.parent.inlineContent && $anchor.parent.inlineContent) {
        selection = new TextSelection($anchor, $head);
      } else {
        selection = new GapCursor($head);
      }
      return newState.tr.setSelection(selection);
    }
  }
});
export default collapsibleNodesPlugin;

import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import { anchorNameFromHeadingText } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
const DEFAULT_HEADING_LEVEL = DEFAULT_ATTRIBUTES_BY_NODE_NAME["heading"]["level"];

// --------------------------------------------------------------------------
function headingLevelFromNode(node) {
  if (node.type.name === "heading") {
    return node.attrs.level;
  } else {
    return DEFAULT_HEADING_LEVEL;
  }
}

// --------------------------------------------------------------------------
export default function findSectionPositions(section, state) {
  const {
    doc,
    schema: {
      nodes: {
        heading: headingType,
        horizontal_rule: horizontalRuleType,
      },
    },
  } = state;

  const { heading, index: sectionIndex } = section;

  // Allows for referencing exact sections when there are multiple headings with the same text
  const headingsByText = {};

  let replaceEndOffset = -1;
  let replaceStartOffset = -1;
  let targetHeadingLevel = DEFAULT_HEADING_LEVEL;
  doc.forEach((childNode, offset, index) => {
    if (replaceEndOffset > -1) return;

    let headingLevel = DEFAULT_HEADING_LEVEL;
    let headingText;
    let skipCurrentNode = true;
    if (childNode.type === headingType) {
      headingText = childNode.textContent;
      headingLevel = headingLevelFromNode(childNode);
    } else if (childNode.type === horizontalRuleType) {
      if (replaceStartOffset > -1) {
        // The horizontal rule itself might not be the start of a section (if there's a heading after it), but if
        // we're looking for the _end_ of the section, we don't want to include it in the section's content
        headingText = null;
      } else {
        const nextChildNode = doc.maybeChild(index + 1);
        if (nextChildNode && nextChildNode.type !== headingType) {
          headingText = null;
        } else {
          return;
        }
      }
    } else if (index === 0) {
      headingText = null;
      skipCurrentNode = false;
    } else {
      return;
    }

    let headingIndex;
    if (headingText in headingsByText) {
      headingIndex = headingsByText[headingText].length;
      headingsByText[headingText].push(childNode);
    } else {
      headingIndex = 0;
      headingsByText[headingText] = [ childNode ];
    }

    let isTargetHeading;
    if (heading) {
      const isMatchingTextOrAnchor = heading.anchor
        ? anchorNameFromHeadingText(headingText || "") === heading.anchor
        : headingText === heading.text;
      isTargetHeading = isMatchingTextOrAnchor && headingIndex === (sectionIndex || 0);
    } else {
      isTargetHeading = headingText === null && headingIndex === (sectionIndex || 0);
    }

    if (isTargetHeading) {
      replaceStartOffset = offset + (skipCurrentNode ? childNode.nodeSize : 0);
      targetHeadingLevel = headingLevelFromNode(childNode);
    } else if (replaceStartOffset > -1 && headingLevel <= targetHeadingLevel) {
      replaceEndOffset = offset - 1;
    }
  });

  return { endPos: replaceEndOffset, startPos: replaceStartOffset };
}

import { upperFirst } from "lodash"
import PropTypes from "prop-types"
import React, { forwardRef, useCallback, useEffect, useRef } from "react"
import { useAsyncEffect } from "@react-hook/async"
import { CircularProgress } from "@rmwc/circular-progress"
import { ListItem, ListItemGraphic, ListItemMeta, ListItemPrimaryText, ListItemText } from "@rmwc/list"

import { MAX_SUGGESTIONS } from "lib/ample-editor/components/link-target-menu/constants"
import {
  headingNoteURLFromNoteSuggestion,
  newTagTextsFromNoteSuggestion,
} from "lib/ample-editor/components/link-target-menu/note-suggestions"
import useSelectedSuggestion from "lib/ample-editor/hooks/use-selected-suggestion"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import fuzzyMatchHeadings from "lib/ample-editor/lib/fuzzy-match-headings"
import NOTE_CONTENT_TYPE from "lib/ample-editor/lib/note-content-type"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import { anchorNameFromHeadingText } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
const HEADING_ICON_PATHS = [
  VECTOR_ICON_PATHS["format-header-1"],
  VECTOR_ICON_PATHS["format-header-2"],
  VECTOR_ICON_PATHS["format-header-3"],
];

// --------------------------------------------------------------------------
function keyFromHeadingSuggestion(headingSuggestion) {
  const { headingText, index } = headingSuggestion;

  return `heading-${ headingText }-${ index }`;
}

// --------------------------------------------------------------------------
function useHeadingSuggestions(activeNoteSuggestion, editorView, fetchNoteContent, fullUserInputText, searchText) {
  const { status, value } = useAsyncEffect(
    async () => {
      let noteURL = headingNoteURLFromNoteSuggestion(activeNoteSuggestion, fullUserInputText);

      const headingsResult = await fetchNoteContent(noteURL, NOTE_CONTENT_TYPE.HEADINGS);
      if (headingsResult === null) return [];

      const { headings, note } = headingsResult;

      const searchingCurrentNote = noteURL === "#";

      let currentHeadingNode = null;
      if (searchingCurrentNote && editorView) {
        // We've got a better idea of the headings in the current note - including where the cursor currently is, which
        // may not be a section defined by a heading, but we need the `note` value from the result to correctly produce
        // links to the current note.
        headings.splice(0, headings.length);

        const { state: { doc, selection: { from } } } = editorView;

        doc.descendants((node, nodePos) => {
          if (node.type.name === "heading") {
            if (from > nodePos) {
              if (currentHeadingNode) headings.push(currentHeadingNode.toJSON());
              currentHeadingNode = node;
            } else {
              headings.push(node.toJSON());
            }
          }
        });

        // If the user enters a search term, we don't want to force the current section to be first
        if (searchText && currentHeadingNode) {
          headings.unshift(currentHeadingNode.toJSON());
          currentHeadingNode = null;
        }
      }

      let noteName = null;
      if (note) {
        noteName = note.name;
        noteURL = note.url;
      }

      const headingSuggestions = fuzzyMatchHeadings(headings, searchText).map(headingSuggestion => {
        const { anchorName, headingText } = headingSuggestion;
        return {
          ...headingSuggestion,
          linkText: searchingCurrentNote ? headingText : `${ noteName }#${ headingText }`,
          linkURL: `${ noteURL }#${ anchorName }`,
        };
      });

      if (currentHeadingNode) {
        // Make sure the current section is listed first
        const { attrs: { level: headingLevel }, textContent: headingText } = currentHeadingNode;
        const anchorName = anchorNameFromHeadingText(headingText);
        headingSuggestions.unshift({
          anchorName,
          headingLevel,
          headingHTML: `<span>Current section: ${ headingText }</span>`,
          headingText,
          linkText: headingText,
          linkURL: `${ noteURL }#${ anchorName }`,
        });
      } else if (searchingCurrentNote && editorView && !searchText) {
        // Not in a heading-defined section, offer option to just link to note
        headingSuggestions.unshift({
          anchorName: null,
          headingLevel: -1,
          headingText: noteName,
          linkText: noteName,
          linkURL: noteURL,
        });
      }

      if (note) {
        headingSuggestions.note = note;
      }

      return headingSuggestions;
    },
    [ activeNoteSuggestion, fullUserInputText, searchText ]
  );

  return [ value, typeof(value) === "undefined" || status === "loading" ];
}

// --------------------------------------------------------------------------
function HeadingSuggestion({ acceptHeadingSuggestion, headingSuggestion, isActiveSuggestion }) {
  const { headingHTML, headingLevel, headingText } = headingSuggestion;

  let icon;
  if (headingLevel < 0) {
    icon = "description";
  } else {
    const iconPath = HEADING_ICON_PATHS[headingLevel - 1];
    if (iconPath) {
      icon = (<svg viewBox="0 0 24 24"><path d={ iconPath }/></svg>);
    } else {
      icon = "title";
    }
  }

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptHeadingSuggestion(headingSuggestion);
    },
    [ headingSuggestion ]
  );

  return (
    <ListItem
      className="link-target-menu-item popup-list-item"
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isActiveSuggestion }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ icon } />
      <ListItemText>
        <ListItemPrimaryText>
          { headingHTML ? <span dangerouslySetInnerHTML={ { __html: headingHTML } } /> : headingText }
        </ListItemPrimaryText>
      </ListItemText>
      { isActiveSuggestion ? <ListItemMeta icon="keyboard_return" /> : null }
    </ListItem>
  );
}

// --------------------------------------------------------------------------
export default function HeadingSuggestions(props, ref) {
  const {
    acceptSuggestion,
    acceptSuggestionOnTab,
    actionDescription,
    activeNoteSuggestion,
    editorView,
    fetchNoteContent,
    fullUserInputText,
    hideHeadingSuggestions,
    insertContentMode,
    scrollToSuggestion,
    searchText,
  } = props;

  const [ headingSuggestions, loadingHeadingSuggestions ] = useHeadingSuggestions(
    activeNoteSuggestion,
    editorView,
    fetchNoteContent,
    fullUserInputText,
    searchText,
  );

  const acceptHeadingSuggestion = headingSuggestion => {
    const { linkText, linkURL } = headingSuggestion;

    const newTagTexts = activeNoteSuggestion ? newTagTextsFromNoteSuggestion(activeNoteSuggestion) : [];

    acceptSuggestion(linkText, linkURL, newTagTexts);
  };

  const limitedHeadingSuggestions = (headingSuggestions || []).slice(0, MAX_SUGGESTIONS);

  const selectedIndex = useSelectedSuggestion(
    hideHeadingSuggestions,
    ref,
    acceptHeadingSuggestion,
    acceptSuggestionOnTab,
    limitedHeadingSuggestions
  );

  const haveUpdatedRef = useRef(false);
  useEffect(
    () => {
      if (haveUpdatedRef.current) {
        scrollToSuggestion(selectedIndex);
      } else {
        haveUpdatedRef.current = true
      }
    },
    [ selectedIndex ]
  );

  const renderedSuggestions = [];

  if (activeNoteSuggestion) {
    const { name } = activeNoteSuggestion;

    renderedSuggestions.push(
      <ListItem className="link-target-menu-item popup-list-item heading-list-item" disabled key="heading">
        <ListItemText>
          <span>
            {
              insertContentMode
                ? "Insert a section of"
                : `${ upperFirst(actionDescription || "link") } to a heading ${ name !== null ? "in" : "" }`
            } <span className="value">{ name }</span>
          </span>
        </ListItemText>
        <ListItemMeta className="meta-text"><span className="shortcut">ESC</span> to go back</ListItemMeta>
      </ListItem>
    );
  }

  if (loadingHeadingSuggestions) {
    renderedSuggestions.push(
      <ListItem disabled key="loading-headings">
        <ListItemGraphic icon={ <CircularProgress size="xsmall"/> } />
        Loading headings.
      </ListItem>
    );
  } else if (headingSuggestions.length === 0 && !activeNoteSuggestion) {
    return (
      <ListItem disabled>
        Type to search for a section to { insertContentMode ? "insert" : "link to" }.
      </ListItem>
    );
  } else {
    const omittedCount = headingSuggestions.length - limitedHeadingSuggestions.length;

    limitedHeadingSuggestions.forEach((headingSuggestion, index) => {
      renderedSuggestions.push(
        <HeadingSuggestion
          acceptHeadingSuggestion={ acceptHeadingSuggestion }
          headingSuggestion={ headingSuggestion }
          isActiveSuggestion={ index === selectedIndex }
          key={ keyFromHeadingSuggestion(headingSuggestion) }
        />
      );
    });

    if (headingSuggestions.length === 0) {
      renderedSuggestions.push(
        <ListItem disabled key="no-headings">
          No matching headings found.
        </ListItem>
      );
    } else if (omittedCount > 0) {
      renderedSuggestions.push(
        <ListItem className="hint" disabled key="omitted-headings">
          Continue typing to search { omittedCount } heading{ omittedCount === 1 ? "" : "s" } not listed above.
        </ListItem>
      );
    }
  }

  return renderedSuggestions;
}

// eslint-disable-next-line no-func-assign
HeadingSuggestions = forwardRef(HeadingSuggestions);

HeadingSuggestions.propTypes = {
  acceptSuggestion: PropTypes.func.isRequired,
  acceptSuggestionOnTab: PropTypes.bool,
  actionDescription: PropTypes.string,
  activeNoteSuggestion: PropTypes.object,
  editorView: PropTypes.object,
  fullUserInputText: PropTypes.string,
  hideHeadingSuggestions: PropTypes.func.isRequired,
  fetchNoteContent: PropTypes.func.isRequired,
  insertContentMode: PropTypes.bool,
  scrollToSuggestion: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

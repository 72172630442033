import { hasTagWithText, TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
// While a "filterTag" is a string describing one or more filters to apply based on tags, a "tag filter" is an
// object describing each of those filters, e.g.:
//
//  filterTag="blah" tagFilters=[ { tagText: "blah" } ]
//  filterTag="blah,tag-2" tagFilters=[ { tagText: "blah" }, { tagText: "tag-2" } ]
//  filterTag="^blah,tag-2" tagFilters=[ { isNegated: true, tagText: "blah" }, { tagText: "tag-2" } ]
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Note this is not a valid tag character
export const NEGATED_TAG_PREFIX = "^";

// --------------------------------------------------------------------------
export function filterTagFromTagFilter({ isNegated, tagText }) {
  return isNegated ? NEGATED_TAG_PREFIX + tagText : tagText;
}

// --------------------------------------------------------------------------
export function filterTagFromTagFilters(tagFilters) {
  return tagFilters && tagFilters.length > 0
    ? tagFilters.map(filterTagFromTagFilter).join(TAGS_DELIMITER)
    : null;
}

// --------------------------------------------------------------------------
export function matchesTagFilters(tagFilters, note) {
  return tagFilters.every(({ isNegated, tagText }) => {
    const hasTag = hasTagWithText(tagText, note);
    return isNegated ? !hasTag : hasTag;
  });
}

// --------------------------------------------------------------------------
export function tagFiltersFromFilterTag(filterTag) {
  const filterTags = filterTag ? filterTag.split(TAGS_DELIMITER) : [];
  return tagFiltersFromTagTexts(filterTags);
}

// --------------------------------------------------------------------------
export function tagFiltersFromTagTexts(tagTexts) {
  const tagFilters = [];

  (tagTexts || []).forEach(tagText => {
    if (!tagText) return; // Blank due to leading/trailing/doubled comma

    let isNegated = false;

    if (tagText.startsWith(NEGATED_TAG_PREFIX)) {
      isNegated = true;
      tagText = tagText.slice(NEGATED_TAG_PREFIX.length);
    }

    tagFilters.push({ isNegated, tagText });
  });

  return tagFilters;
}

// --------------------------------------------------------------------------
export function tagTextsFromTagFilters(tagFilters) {
  return tagFilters.reduce(
    (tagTexts, { isNegated, tagText }) => {
      if (!isNegated) tagTexts.push(tagText);
      return tagTexts;
    },
    []
  );
}

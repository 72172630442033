import React from "react"
import { IconButton } from "@rmwc/icon-button"
import Tippy from "@tippyjs/react"

import { hasModifierKey, hasModifierKeyExceptShift } from "../../lib/event-util"

// --------------------------------------------------------------------------
export default class RepeatIntervalMenu extends React.PureComponent {
  state = {
    value: "",
  };

  _containerRef = React.createRef();

  // --------------------------------------------------------------------------
  render() {
    const { value } = this.state;

    let inputClassName = "text-input numeric";
    if (value !== "") inputClassName += isNaN(value) ? " invalid" : " valid";

    return (
      <div className="repeat-interval-menu sub-menu" ref={ this._containerRef }>
        <div className="label">Repeat</div>
        <input
          autoFocus
          className={ inputClassName }
          inputMode="numeric"
          maxLength={ 4 }
          onChange={ this._setValue }
          onKeyDown={ this._onKeyDown }
          type="text"
          size={ Math.max(1, value.length) }
          value={ value }
        />
        <div className="label">days after completion</div>

        <Tippy content="Press enter to set repeating interval" delay={ 150 }>
          <span className="enter-icon-button-container">
            <IconButton icon="keyboard_return" onClick={ this._setRepeat } />
          </span>
        </Tippy>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _isFocused() {
    const { current: container } = this._containerRef;
    return container && document.activeElement && container.contains(document.activeElement);
  }

  // --------------------------------------------------------------------------
  _onKeyDown = event => {
    switch (event.key) {
      case "Escape":
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this.props.cancel();
        }
        break;

      case "Enter":
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this._setRepeat();
        }
        break;

      case "Tab":
        if (!hasModifierKeyExceptShift(event) && this._isFocused()) {
          event.preventDefault();
          this.props.cancel();
        }
        break;

      default:
        break;
    }
  };

  // --------------------------------------------------------------------------
  _setRepeat = () => {
    const { value } = this.state;

    if (value === "") {
      this.props.setRepeat(null);
    } else if (isNaN(value)) {
      this.props.cancel();
    } else {
      const days = parseInt(value, 10);
      this.props.setRepeat(`P${ days }DT9H`);
    }
  };

  // --------------------------------------------------------------------------
  _setValue = event => {
    this.setState({ value: event.target.value })
  };
}

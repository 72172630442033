import { Node, Slice } from "prosemirror-model"

import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import markdownParser from "lib/ample-editor/lib/markdown-parser"
import markdownSerializer from "lib/ample-editor/lib/markdown-serializer"
import { parsePluginMarkdownContent } from "lib/ample-editor/lib/plugin-content-util"

// --------------------------------------------------------------------------
export function buildPluginImage(editorView, getPos) {
  const { state: { doc, schema } } = editorView;

  const imagePos = getPos();
  const imageNode = doc.nodeAt(imagePos);
  if (!imageNode && imageNode.type !== schema.nodes.image) return null;

  const { attrs: { src, text } } = imageNode;

  let caption = null;
  if (imageNode.childCount > 0) {
    const captionDoc = Node.fromJSON(schema, { content: imageNode.content.toJSON(), type: "doc" });
    caption = markdownSerializer.serialize(captionDoc);
  }

  let index = 0;
  doc.nodesBetween(0, imagePos, node => {
    if (node.type === schema.nodes.image && node.attrs.src === src) index++;
  });

  return { caption, index, src, text, };
}

// --------------------------------------------------------------------------
export function transactionFromPluginImageUpdates(editorView, getPos, updates) {
  const { state, state: { doc, schema } } = editorView;

  const imagePos = getPos();
  const imageNode = doc.nodeAt(imagePos);
  if (!imageNode && imageNode.type !== schema.nodes.image) return null;

  const attributeUpdates = {};

  const transaction = state.tr;

  if ("caption" in updates) {
    const captionDoc = parsePluginMarkdownContent(updates.caption);
    markdownParser.schema.nodes.image.createChecked(imageNode.attrs, captionDoc.content);

    let slice = new Slice(captionDoc.content, 1, 1);
    // Convert from markdown schema to whatever editor we are in (task, description, etc)
    slice = Slice.fromJSON(schema, slice.toJSON());

    const $imagePos = state.doc.resolve(imagePos);
    const childPos = $imagePos.start($imagePos.depth + 1);

    transaction.replace(childPos, childPos + imageNode.content.size, slice);
  }

  if ("src" in updates) attributeUpdates.src = updates.src.toString();
  if ("text" in updates) attributeUpdates.text = updates.text.toString();
  if ("width" in updates) attributeUpdates.width = parseInt(updates.width, 10);

  return transaction.setNodeMarkup(imagePos, null, { ...imageNode.attrs, ...attributeUpdates });
}

// --------------------------------------------------------------------------
export function validUpdatesFromPluginImageUpdates(updates) {
  const validUpdates = {};

  if ("caption" in updates) {
    let newContent = null;

    const { caption } = updates;
    if (caption) {
      const captionDoc = parsePluginMarkdownContent(updates.caption);
      markdownParser.schema.nodes.image.createChecked({ src: "placeholder" }, captionDoc.content);

      newContent = captionDoc.content.toJSON();
    }

    validUpdates.content = newContent;
  }

  if ("src" in updates) {
    validUpdates.src = updates.src.toString();
  }

  if ("text" in updates) {
    const { text } = updates;
    if (text) {
      validUpdates.text = text.toString();
    } else {
      validUpdates.text = DEFAULT_ATTRIBUTES_BY_NODE_NAME["image"]["text"];
    }
  }

  if ("width" in updates) {
    const width = parseInt(updates.width, 10);
    if (width && !Number.isNaN(width)) validUpdates.width = width;
  }

  return validUpdates;
}

import { omit } from "lodash"
import memoize from "memoize-one"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import {
  List,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
} from "@rmwc/list"
import scrollIntoView from "scroll-into-view-if-needed"

import AdjustDateMenu from "lib/ample-editor/components/list-item-commands-menu/adjust-date-menu"
import HideForDaysMenu from "lib/ample-editor/components/list-item-commands-menu/hide-for-days-menu"
import RepeatIntervalMenu from "lib/ample-editor/components/list-item-commands-menu/repeat-interval-menu"
import SetIntegerMenu from "lib/ample-editor/components/list-item-commands-menu/set-integer-menu"
import SelectNoteMenu from "lib/ample-editor/components/select-note-menu"
import { modKeyName } from "lib/ample-editor/lib/client-info"
import { hasModifierKey, hasModifierKeyExceptShift } from "lib/ample-editor/lib/event-util"
import LIST_ITEM_COMMAND from "lib/ample-editor/lib/list-item-command"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import fuzzyMatch from "lib/ample-util/fuzzy-match"
import resolvePluginActionPromises from "lib/ample-util/resolve-plugin-action-promises"

// --------------------------------------------------------------------------
const COMPONENT_BY_LIST_ITEM_COMMAND = {
  [LIST_ITEM_COMMAND.COPY]: SubMenuCopy,
  [LIST_ITEM_COMMAND.DURATION]: SubMenuDuration,
  [LIST_ITEM_COMMAND.EVERY]: SubMenuEvery,
  [LIST_ITEM_COMMAND.HIDE]: SubMenuHide,
  [LIST_ITEM_COMMAND.MOVE]: SubMenuMove,
  [LIST_ITEM_COMMAND.SCHEDULE]: SubMenuSchedule,
  [LIST_ITEM_COMMAND.SET_TASK_SCORE]: SubMenuSetTaskScore,
  [LIST_ITEM_COMMAND.START]: SubMenuStart,
};

const SUGGESTION_COLLAPSE = {
  description: "Collapse nested items",
  icon: "unfold_less",
  listItemCommand: LIST_ITEM_COMMAND.COLLAPSE,
};

const SUGGESTION_EXPAND = {
  description: "Expand nested items",
  icon: "unfold_more",
  listItemCommand: LIST_ITEM_COMMAND.EXPAND,
};

// These are ordered as they are displayed to the user, so not alphabetical
const SUGGESTIONS_BY_LIST_ITEM_TYPE = {
  bullet_list_item: [
    {
      description: ({ scheduledAt }) => `Cross out ${ scheduledAt ? "event" : "bullet point" }`,
      icon: "strikethrough_s",
      listItemCommand: LIST_ITEM_COMMAND.CROSS_OUT,
    },
    {
      description: ({ scheduledAt }) => {
        return `Change ${ scheduledAt ? "event" : "bullet" } to a task (${ modKeyName }+Enter)`;
      },
      listItemCommand: LIST_ITEM_COMMAND.SWITCH_TO_TASK,
      vectorIconName: "checkbox-marked-outline",
    },
    {
      description: ({ scheduledAt }) => {
        if (scheduledAt) {
          return "Specify a start time";
        } else {
          return "Specify a start time and add event to the calendar";
        }
      },
      icon: "event_available",
      listItemCommand: LIST_ITEM_COMMAND.START,
    },
    {
      description: "Repeat after a specified number of days",
      icon: "cached",
      listItemCommand: LIST_ITEM_COMMAND.EVERY,
      shouldShow: ({ scheduledAt }) => !!scheduledAt,
    },
    {
      description: "Specify a duration in minutes",
      icon: "timer",
      listItemCommand: LIST_ITEM_COMMAND.DURATION,
      shouldShow: ({ scheduledAt }) => !!scheduledAt,
    },
    {
      description: ({ scheduledAt }) => `Copy this ${ scheduledAt ? "event" : "bullet" } to another note`,
      icon: "content_copy",
      listItemCommand: LIST_ITEM_COMMAND.COPY,
    },
    {
      description: ({ scheduledAt }) => `Delete this ${ scheduledAt ? "event" : "bullet" }`,
      icon: "delete",
      listItemCommand: LIST_ITEM_COMMAND.DELETE,
    },
  ],
  check_list_item: [
    {
      description: "Complete this task",
      icon: "check",
      listItemCommand: LIST_ITEM_COMMAND.COMPLETE,
    },
    {
      description: "Copy this task to another note",
      icon: "done_all",
      listItemCommand: LIST_ITEM_COMMAND.COPY,
    },
    {
      aliases: [ "due" ],
      description: "Specify a new start time",
      icon: "event_available",
      listItemCommand: LIST_ITEM_COMMAND.START,
    },
    {
      description: "Specify a duration in minutes",
      icon: "timer",
      listItemCommand: LIST_ITEM_COMMAND.DURATION,
    },
    {
      aliases: [ "sleep", "snooze" ],
      description: "Hide for a specified number of days",
      icon: "visibility_off",
      listItemCommand: LIST_ITEM_COMMAND.HIDE,
    },
    {
      description: "Set Task Score",
      icon: "calculate",
      listItemCommand: LIST_ITEM_COMMAND.SET_TASK_SCORE,
    },
    {
      description: "Repeat after a specified number of days",
      icon: "cached",
      listItemCommand: LIST_ITEM_COMMAND.EVERY,
    },
    {
      description: "Match task score of highest value task",
      icon: "maximize",
      listItemCommand: LIST_ITEM_COMMAND.MAX_SCORE,
    },
    {
      description: "Clear accumulated task score",
      icon: "minimize",
      listItemCommand: LIST_ITEM_COMMAND.RESET_SCORE,
    },
    {
      description: "Toggle important priority",
      icon: "star_border",
      listItemCommand: LIST_ITEM_COMMAND.IMPORTANT,
    },
    {
      description: "Hide the task while scheduling its start time",
      icon: "timer",
      listItemCommand: LIST_ITEM_COMMAND.SCHEDULE,
    },
    {
      description: "Toggle urgent priority",
      icon: "star_half",
      listItemCommand: LIST_ITEM_COMMAND.URGENT,
    },
    {
      description: "Move this task to another note",
      icon: "send",
      listItemCommand: LIST_ITEM_COMMAND.MOVE,
    },
    {
      description: "Replace task with a crossed-out bullet list item",
      icon: "strikethrough_s",
      listItemCommand: LIST_ITEM_COMMAND.CROSS_OUT,
    },
    {
      description: "Complete this task for half of the task score",
      icon: "done_outline",
      listItemCommand: LIST_ITEM_COMMAND.DISMISS,
    },
    {
      description: "Delete this task",
      icon: "delete",
      listItemCommand: LIST_ITEM_COMMAND.DELETE,
    },
    {
      description: `Change task to a bullet list item (${ modKeyName }+Enter)`,
      icon: "format_list_bulleted",
      listItemCommand: LIST_ITEM_COMMAND.SWITCH_TO_BULLET,
      shouldShow: ({ due }) => !due,
    },
    {
      description: `Change task to a scheduled bullet (${ modKeyName }+Enter)`,
      icon: "format_list_bulleted",
      listItemCommand: LIST_ITEM_COMMAND.SWITCH_TO_SCHEDULED_BULLET,
      shouldShow: ({ due }) => !!due,
    },
  ],
};

// --------------------------------------------------------------------------
function SubMenuCopy({ activeCommandMenuRef, applyCommand, dismiss, fetchNoteContent, suggestNotes }) {
  const applyCommandCopy = useCallback(
    options => {
      applyCommand(LIST_ITEM_COMMAND.COPY, options);
    },
    [ applyCommand ]
  );

  return (
    <SelectNoteMenu
      actionDescription="copy this task"
      actionDescriptionShort="Copy"
      apply={ applyCommandCopy }
      cancel={ dismiss }
      fetchNoteContent={ fetchNoteContent }
      ref={ activeCommandMenuRef }
      suggestNotes={ suggestNotes }
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuDuration({ activeCommandMenuRef, applyCommand, dismiss }) {
  const applyCommandDuration = useCallback(
    minutes => {
      applyCommand(LIST_ITEM_COMMAND.DURATION, { minutes });
    },
    [ applyCommand ]
  );

  return (
    <SetIntegerMenu
      cancel={ dismiss }
      label="Set duration to"
      ref={ activeCommandMenuRef }
      setInteger={ applyCommandDuration }
      tooltip="Press enter to set the duration to the specified number of minutes"
      units="minutes"
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuEvery({ activeCommandMenuRef, applyCommand, attributes, dismiss }) {
  const { repeat } = attributes;

  const applyCommandEvery = useCallback(
    newRepeat => {
      applyCommand(LIST_ITEM_COMMAND.EVERY, { repeat: newRepeat });
    },
    [ applyCommand ]
  );

  return (
    <RepeatIntervalMenu
      cancel={ dismiss }
      ref={ activeCommandMenuRef }
      repeat={ repeat }
      setRepeat={ applyCommandEvery }
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuHide({ activeCommandMenuRef, applyCommand, attributes, dismiss }) {
  const { startAt } = attributes;

  const applyCommandHide = useCallback(
    newStartAt => {
      applyCommand(LIST_ITEM_COMMAND.HIDE, { startAt: newStartAt });
    },
    [ applyCommand ]
  );

  return (
    <HideForDaysMenu
      cancel={ dismiss }
      ref={ activeCommandMenuRef }
      setStartAt={ applyCommandHide }
      startAt={ startAt }
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuSchedule({ activeCommandMenuRef, applyCommand, attributes, dismiss }) {
  const { startAt } = attributes;

  const applyCommandSchedule = useCallback(
    newStartAt => {
      applyCommand(LIST_ITEM_COMMAND.SCHEDULE, { startAt: newStartAt });
    },
    [ applyCommand ]
  );

  return (
    <HideForDaysMenu
      cancel={ dismiss }
      preface={ "Hide until starting in" }
      ref={ activeCommandMenuRef }
      setStartAt={ applyCommandSchedule }
      startAt={ startAt }
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuSetTaskScore({ activeCommandMenuRef, applyCommand, dismiss }) {
  const applyCommandSetTaskScore = useCallback(
    points => {
      if (Number.isInteger(points) && points >= 0) {
        applyCommand(LIST_ITEM_COMMAND.SET_TASK_SCORE, { points });
      }
    },
    [ applyCommand ]
  );

  return (
    <SetIntegerMenu
      cancel={ dismiss }
      label="Set Task Score to"
      ref={ activeCommandMenuRef }
      setInteger={ applyCommandSetTaskScore }
      tooltip="Press enter to set the Task Score to the specified value"
      units="points"
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuMove({ activeCommandMenuRef, applyCommand, dismiss, fetchNoteContent, suggestNotes }) {
  const applyCommandMove = useCallback(
    options => {
      applyCommand(LIST_ITEM_COMMAND.MOVE, options);
    },
    [ applyCommand ]
  );

  return (
    <SelectNoteMenu
      actionDescription="move this task"
      actionDescriptionShort="Move"
      fetchNoteContent={ fetchNoteContent }
      apply={ applyCommandMove }
      cancel={ dismiss }
      ref={ activeCommandMenuRef }
      suggestNotes={ suggestNotes }
    />
  );
}

// --------------------------------------------------------------------------
function SubMenuStart({ activeCommandMenuRef, applyCommand, attributes, dismiss }) {
  const { due, scheduledAt } = attributes;

  // bullets use `scheduledAt` while check list items use `due`
  const effectiveDue = due || scheduledAt;

  const dueDate = effectiveDue ? new Date(effectiveDue * 1000) : null;

  const applyCommandStart = useCallback(
    date => {
      applyCommand(LIST_ITEM_COMMAND.START, { date });
    },
    [ applyCommand ]
  );

  return (
    <AdjustDateMenu
      cancel={ dismiss }
      initialDate={ dueDate }
      label="Start at"
      ref={ activeCommandMenuRef }
      save={ applyCommandStart }
    />
  );
}

// --------------------------------------------------------------------------
export default class ListItemCommandsMenu extends React.PureComponent {
  static propTypes = {
    applyCommand: PropTypes.func.isRequired,
    attributes: PropTypes.object.isRequired,
    canCollapse: PropTypes.bool,
    canExpand: PropTypes.bool,
    dismiss: PropTypes.func.isRequired,
    // If set, overrides the display to match the given type, while the underlying commands work on
    // whatever type is in `listItemType`
    displayedListItemType: PropTypes.oneOf([ "bullet_list_item", "check_list_item" ]),
    fetchNoteContent: PropTypes.func,
    getTaskOptionPluginActions: PropTypes.func,
    listItemType: PropTypes.oneOf([ "bullet_list_item", "check_list_item" ]).isRequired,
    onKeyDown: PropTypes.func, // Only called when the list actually has focus (it doesn't by default)
    removeText: PropTypes.func,
    suggestNotes: PropTypes.func.isRequired,
    text: PropTypes.string,
  };

  state = {
    activeListItemCommand: null,
    activeSuggestionIndex: 0,
    pluginActions: [],
  };

  _activeCommandMenuRef = React.createRef();
  _commandListItems = [];
  _unmounted = false;

  // --------------------------------------------------------------------------
  activeListItemCommand() {
    return this.state.activeListItemCommand;
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    this._refreshPluginActions();
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const { activeListItemCommand } = this.state;
    if (activeListItemCommand && activeListItemCommand !== prevState.activeListItemCommand) {
      const { removeText } = this.props;
      if (removeText) removeText();

      if (!(activeListItemCommand in COMPONENT_BY_LIST_ITEM_COMMAND)) {
        this._applyCommand(activeListItemCommand, {});
        return;
      }
    }

    const { text } = this.props;
    if (text !== prevProps.text) {
      const suggestions = this._suggestCommands();
      const { activeSuggestionIndex } = this.state;
      if (activeSuggestionIndex >= suggestions.length || activeSuggestionIndex < 0) {
        this.setState({ activeSuggestionIndex: suggestions.length - 1 });
      }

      this._refreshPluginActions();
    }
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._unmounted = true;
  }

  // --------------------------------------------------------------------------
  handleKeyDown(editorView, event) {
    const { current: activeCommandMenu } = this._activeCommandMenuRef;
    if (activeCommandMenu) {
      // Note this only passes on key events with the editor focused, the command menu needs to handle it's own
      // events if it has focusable inputs (e.g. SelectNoteMenu)
      return activeCommandMenu.handleKeyDown ? activeCommandMenu.handleKeyDown(event) : false;
    }

    switch (event.key) {
      case "ArrowDown":
        if (!hasModifierKey(event)) {
          event.stopImmediatePropagation();
          this._moveSelection(1);
          return true;
        }
        break;

      case "ArrowUp":
        if (!hasModifierKey(event)) {
          event.stopImmediatePropagation();
          this._moveSelection(-1);
          return true;
        }
        break;

      case "Enter":
        if (!hasModifierKey(event)) {
          // So sub-menus that use keypress don't handle this key press
          event.preventDefault();

          const { activeSuggestionIndex } = this.state;
          const suggestions = this._suggestCommands();
          this._selectSuggestion(suggestions[activeSuggestionIndex]);
          return true;
        }
        break;

      case "Escape":
        if (!hasModifierKey(event)) {
          this.props.dismiss();
          return true;
        }
        break;

      case "Tab":
        if (!hasModifierKeyExceptShift(event)) {
          event.stopImmediatePropagation();

          if (event.shiftKey) {
            this.props.dismiss();
          } else {
            const { activeSuggestionIndex } = this.state;
            const suggestions = this._suggestCommands();
            this._selectSuggestion(suggestions[activeSuggestionIndex]);
          }
          return true;
        }
        break;

      default:
        return false;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  render() {
    const { activeListItemCommand } = this.state;

    const SubMenuComponent = COMPONENT_BY_LIST_ITEM_COMMAND[activeListItemCommand];
    if (SubMenuComponent) {
      const { attributes, dismiss, fetchNoteContent, suggestNotes } = this.props;

      return (
        <div className="list-item-commands-menu popup-list">
          <SubMenuComponent
            activeCommandMenuRef={ this._activeCommandMenuRef }
            applyCommand={ this._applyCommand }
            attributes={ attributes }
            dismiss={ dismiss }
            fetchNoteContent={ fetchNoteContent }
            suggestNotes={ suggestNotes }
          />
        </div>
      );
    }

    const { onKeyDown } = this.props;
    const suggestions = this._suggestCommands();

    if (suggestions.length > 0) {
      return (
        <List className="list-item-commands-menu popup-list" onKeyDown={ onKeyDown }>
          { suggestions.map(this._renderSuggestion) }
        </List>
      );
    } else {
      return null;
    }
  }

  // --------------------------------------------------------------------------
  _applyCommand = (listItemCommand, listItemCommandOptions) => {
    const { applyCommand, attributes, listItemType } = this.props;
    applyCommand(attributes, listItemType, listItemCommand, listItemCommandOptions);
  };

  // --------------------------------------------------------------------------
  _effectiveAttributes = () => {
    const { attributes, displayedListItemType, listItemType } = this.props;

    return this._effectiveAttributesMemoized(attributes, displayedListItemType, listItemType);
  };

  // --------------------------------------------------------------------------
  _effectiveAttributesMemoized = memoize((attributes, displayedListItemType, listItemType) => {
    const effectiveListItemType = displayedListItemType || listItemType;

    // Translate attributes when there's a mismatch
    if (effectiveListItemType !== listItemType) {
      if (effectiveListItemType === "bullet_list_item") {
        attributes = omit({ ...attributes, scheduledAt: attributes.due }, [ "due" ]);
      } else {
        attributes = omit({ ...attributes, due: attributes.scheduledAt }, [ "scheduledAt" ]);
      }
    }

    return attributes;
  });

  // --------------------------------------------------------------------------
  _moveSelection = delta => {
    const suggestions = this._suggestCommands();

    let activeSuggestionIndex = this.state.activeSuggestionIndex + delta;
    if (activeSuggestionIndex < 0) activeSuggestionIndex = suggestions.length - 1;
    else if (activeSuggestionIndex >= suggestions.length) activeSuggestionIndex = 0;

    const commandListItem = this._commandListItems[activeSuggestionIndex];
    if (commandListItem) scrollIntoView(commandListItem, { behavior: "smooth", scrollMode: "if-needed" });

    this.setState({ activeSuggestionIndex });
  };

  // --------------------------------------------------------------------------
  _onClick = (suggestion, event) => {
    event.preventDefault();
    event.stopPropagation();

    this._selectSuggestion(suggestion);
  };

  // --------------------------------------------------------------------------
  _refreshPluginActions = () => {
    const { displayedListItemType, getTaskOptionPluginActions, listItemType, text } = this.props;

    const effectiveListItemType = displayedListItemType || listItemType;

    if (!getTaskOptionPluginActions || effectiveListItemType !== "check_list_item") return;

    getTaskOptionPluginActions().then(pluginActionPromises => {
      if (this._unmounted) return;

      resolvePluginActionPromises(pluginActionPromises, {
        setPluginActions: pluginActions => this.setState({ pluginActions }),
        shouldCancel: () => this._unmounted || this.props.text !== text,
      });
    });
  };

  // --------------------------------------------------------------------------
  _renderSuggestion = (suggestion, index) => {
    const {
      aliases,
      aliasesHtml,
      html,
      icon,
      listItemCommand,
      pluginAction,
      vectorIconName,
    } = suggestion;
    const { activeSuggestionIndex } = this.state;

    const isSelected = index === activeSuggestionIndex;

    let renderedAliases = null;
    if (aliasesHtml || aliases) {
      renderedAliases = (aliasesHtml || aliases).map(aliasHtml => (
        <span className="alias" dangerouslySetInnerHTML={ { __html: aliasHtml } } key={ aliasHtml } />
      ));
    }

    let { description } = suggestion;
    let text;
    if (pluginAction) {
      const { checkResult, name } = pluginAction;
      if (typeof(checkResult) === "string") {
        description = (<span><span className="prefix">Plugin:</span>{ name }</span>);
        text = checkResult;
      } else {
        text = name;
      }
    } else {
      if (typeof(description) === "function") {
        description = description(this._effectiveAttributes());
      }
      text = listItemCommand;
    }
    const renderedText = html ? (<span dangerouslySetInnerHTML={ { __html: html } } />) : text;

    let graphic;
    if (vectorIconName) {
      graphic = (<svg viewBox="0 0 24 24"><path d={ VECTOR_ICON_PATHS[vectorIconName] }/></svg>);
    } else {
      graphic = icon;
    }

    return (
      <ListItem
        className="list-item-commands-menu-item popup-list-item"
        key={ text }
        onClick={ this._onClick.bind(this, suggestion) }
        ref={ this._setCommandListItem.bind(this, index) }
        selected={ isSelected }
      >
        <ListItemGraphic icon={ graphic } />
        <ListItemText>
          <ListItemPrimaryText>
            { (pluginAction && !description) ? (<span className="prefix">Plugin:</span>) : null }{ renderedText }
            { renderedAliases }
          </ListItemPrimaryText>
          <ListItemSecondaryText>
            { description }
          </ListItemSecondaryText>
        </ListItemText>
        { isSelected ? <ListItemMeta icon="keyboard_return" /> : null }
      </ListItem>
    );
  };

  // --------------------------------------------------------------------------
  _selectSuggestion = suggestion => {
    const { dismiss, removeText } = this.props;

    const { listItemCommand, pluginAction } = suggestion || {};

    if (listItemCommand) {
      this.setState({ activeListItemCommand: listItemCommand });
    } else if (pluginAction) {
      const { run } = pluginAction;
      if (removeText) removeText();
      run();
      dismiss();
    } else {
      dismiss();
    }
  };

  // --------------------------------------------------------------------------
  _setCommandListItem = (index, commandListItem) => {
    if (commandListItem) this._commandListItems[index] = commandListItem;
  };

  // --------------------------------------------------------------------------
  _suggestCommands = () => {
    const { canCollapse, canExpand, displayedListItemType, listItemType, text } = this.props;
    const { pluginActions } = this.state;

    return this._suggestCommandsMemoized(
      this._effectiveAttributes(),
      canCollapse,
      canExpand,
      displayedListItemType || listItemType,
      pluginActions,
      text,
    );
  };

  // --------------------------------------------------------------------------
  _suggestCommandsMemoized = memoize((
    attributes,
    canCollapse,
    canExpand,
    listItemType,
    pluginActions,
    query,
  ) => {
    query = (query || "").toLowerCase().trim();

    let availableSuggestions = SUGGESTIONS_BY_LIST_ITEM_TYPE[listItemType].concat(
      pluginActions.map(pluginAction => ({ icon: pluginAction.icon || "extension", pluginAction }))
    );

    if (canCollapse) {
      availableSuggestions.unshift(SUGGESTION_COLLAPSE);
    } else if (canExpand) {
      availableSuggestions.unshift(SUGGESTION_EXPAND);
    }

    availableSuggestions = availableSuggestions.filter(({ shouldShow }) => {
      return !shouldShow || shouldShow(attributes);
    });

    let suggestions;
    if (query.length > 0) {
      const matchingSuggestions = [];

      availableSuggestions.forEach(suggestion => {
        const { aliases, listItemCommand, pluginAction } = suggestion;

        let text;
        if (pluginAction) {
          const { checkResult, name } = pluginAction;
          text = typeof(checkResult) === "string" ? checkResult : name;
        } else {
          text = listItemCommand;
        }

        const match = fuzzyMatch(query, text, "<b>", "</b>", { shouldEscapeHTML: true });

        let score = match ? match.score : null;

        const aliasesHtml = (aliases || []).map(alias => {
          const aliasMatch = fuzzyMatch(query, alias, "<b>", "</b>", { shouldEscapeHTML: true });
          if (aliasMatch) {
            const { rendered: aliasHtml, score: aliasScore } = aliasMatch;
            if (score !== null) {
              score = Math.max(score, aliasScore);
            } else {
              score = aliasScore;
            }
            return aliasHtml;
          } else {
            return alias;
          }
        });

        if (score !== null) {
          const { rendered } = match || { rendered: text };
          matchingSuggestions.push({ ...suggestion, aliasesHtml, html: rendered, score, text });
        }
      });

      suggestions = matchingSuggestions.sort((a, b) => {
        const compare = b.score - a.score;
        return compare ? compare : a.text.localeCompare(b.text);
      });
    } else {
      suggestions = availableSuggestions;
    }

    this._commandListItems = new Array(suggestions.length);

    return suggestions;
  });
}

import { tableNodes as originalTableNodes } from "prosemirror-tables"

import {
  hexColorFromCycleColor,
  hexColorFromString,
  isCycleVarColor,
} from "lib/ample-editor/lib/color-util"
import TABLE_CELL_ATTRIBUTE_DEFAULTS from "lib/ample-editor/lib/table/table-cell-attribute-defaults"

export const CELL_CONTENT_CLASS = "cell-content";
export const CELL_INNER_CONTENT_CLASS = "inner-content";

// --------------------------------------------------------------------------
function borderWidth(borderValue) {
  const match = borderValue.match(/([\d.]+)px/);
  const integer = match && Math.round(parseFloat(match[1]));
  return integer && integer > 0 ? integer : null;
}

// --------------------------------------------------------------------------
// Parse various strings that could have been pasted from another app to describe the colwidth of the cell
// Returns an integer pixel width for cell, or null if one couldn't be derived from `cellDom`
function pxFromCellDom(cellDom) {
  const widthStyle = cellDom.style.width;

  if (widthStyle) {
    if (/^[\d.]+[\s]*p[xt]$/.test(widthStyle)) {
      return parseInt(widthStyle.replace("px", ""), 10);
    } else if (/^[\d.]+$/.test(widthStyle)) {
      return parseInt(widthStyle, 10);
      // The most audacious case: try to deduce a px width from a %-specified cell
    } else if (/^[\d.]+%$/.test(widthStyle)) {
      const tableEl = cellDom.closest("table");
      if (!tableEl) return null; // Perhaps v2 could somehow estimate the width of the table the cell is being pasted into?
      const tableWidth = tableEl.offsetWidth;
      const percent = parseFloat(widthStyle.replace("%", ""));
      return Math.floor(tableWidth * percent);
    }
  }

  const table = cellDom.closest("table");
  const colgroup = table && table.querySelector("colgroup");
  if (colgroup) {
    const columnIndex = Array.from(cellDom.parentNode.children).indexOf(cellDom);
    const columnEl = Array.from(colgroup.children)[columnIndex];
    const widthString = columnEl && columnEl.getAttribute("width");
    return widthString && widthString.length ? parseInt(widthString, 10) : null;
  }
  return null;
}

// --------------------------------------------------------------------------
// Most all of our table schema definition comes in the form of defining the attributes for table_cell. The
// definition of cellAttributes is given as an input to prosemirror-table's tableNodes() function, described at
// https://github.com/ProseMirror/prosemirror-tables#documentation
//
// It is not passed directly to pm's tableNodes() because the setCellAttrs method we took from pm-tables
// consumes the setDOMAttr key from each cell attribute
const cellAttributes = {
  align: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["align"],
    getFromDOM(dom) {
      const align = (dom.align || dom.style.textAlign);
      if (align && align.length) return align;
      let result = TABLE_CELL_ATTRIBUTE_DEFAULTS["align"];
      for (const child of dom.querySelectorAll("div, p")) {
        if (child.style.textAlign) {
          result = child.style.textAlign;
          break;
        }
      }
      return result;
    }
  },
  backgroundColor: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["backgroundColor"],
    getFromDOM(dom) {
      if (dom.dataset.backgroundColor && isCycleVarColor(dom.dataset.backgroundColor)) {
        return dom.dataset.backgroundColor;
      } else {
        return hexColorFromString(dom.style.backgroundColor) || TABLE_CELL_ATTRIBUTE_DEFAULTS["backgroundColor"];
      }
    },
    setDOMAttr(value, attrs) {
      let hexColor = hexColorFromCycleColor(value)
      if (hexColor) {
        attrs["data-background-color"] = value;
      } else {
        hexColor = hexColorFromString(value);
      }

      if (hexColor) {
        attrs.style = (attrs.style || "") + `background-color: ${ hexColor };`;
      }
    },
  },
  borderBottom: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["borderBottom"],
    getFromDOM(dom) {
      return borderWidth(dom.style.borderBottom) ? true : TABLE_CELL_ATTRIBUTE_DEFAULTS["borderBottom"];
    },
    setDOMAttr(value, attrs) {
      if (value) {
        attrs.style = (attrs.style || "") + "border-bottom-width: 1px;";
      }
    }
  },
  borderLeft: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["borderLeft"],
    getFromDOM(dom) {
      return borderWidth(dom.style.borderLeft) ? true : TABLE_CELL_ATTRIBUTE_DEFAULTS["borderLeft"];
    },
    setDOMAttr(value, attrs) {
      if (value) {
        attrs.style = (attrs.style || "") + "border-left-width: 1px;";
      }
    }
  },
  borderRight: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["borderRight"],
    getFromDOM(dom) {
      return borderWidth(dom.style.borderRight) ? true : TABLE_CELL_ATTRIBUTE_DEFAULTS["borderRight"];
    },
    setDOMAttr(value, attrs) {
      if (value) {
        attrs.style = (attrs.style || "") + "border-right-width: 1px;";
      }
    }
  },
  borderTop: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["borderTop"],
    getFromDOM(dom) {
      return borderWidth(dom.style.borderTop) ? true : TABLE_CELL_ATTRIBUTE_DEFAULTS["borderTop"];
    },
    setDOMAttr(value, attrs) {
      if (value) {
        attrs.style = (attrs.style || "") + "border-top-width: 1px;";
      }
    }
  },
  color: {
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["color"],
    getFromDOM(dom) {
      if (dom.dataset.textColor && isCycleVarColor(dom.dataset.textColor)) {
        return dom.dataset.textColor;
      } else if (dom.style.color && hexColorFromString(dom.style.color)) {
        return hexColorFromString(dom.style.color);
      }
      let result = TABLE_CELL_ATTRIBUTE_DEFAULTS["color"];
      for (const child of dom.querySelectorAll("div, font, p, span")) {
        if (child.style.color && hexColorFromString(child.style.color)) {
          result = hexColorFromString(child.style.color);
          break;
        }
      }
      return result;
    },
    setDOMAttr(value, attrs) {
      let hexColor = hexColorFromCycleColor(value);
      if (hexColor) {
        attrs["data-text-color"] = value;
      } else {
        hexColor = hexColorFromString(value);
      }

      if (hexColor) {
        attrs.style = (attrs.style || "") + `color: ${ hexColor };`;
      }
    }
  },
  colwidth: { // Stored internally as an integer
    default: TABLE_CELL_ATTRIBUTE_DEFAULTS["colwidth"],
    getFromDOM(dom) {
      const dataWidth = dom.getAttribute("data-column-width") && parseInt(dom.getAttribute("data-column-width"), 10);
      return dataWidth || pxFromCellDom(dom) || TABLE_CELL_ATTRIBUTE_DEFAULTS["colwidth"];
    },
    setDOMAttr(value, attrs) {
      if (value) {
        attrs["data-column-width"] = value;
        attrs.style = (attrs.style || "") + `width: ${ value }px;`;
      }
    }
  },

  // Currently required for selectedRect/TableMap to work properly
  colspan: { default: TABLE_CELL_ATTRIBUTE_DEFAULTS["colspan"] },
  rowspan: { default: TABLE_CELL_ATTRIBUTE_DEFAULTS["rowspan"] }
};

// eslint-disable-next-line camelcase
const { table_row } = originalTableNodes({});

// pm-tables default table_row content rule is `(table_cell | table_header)*` We modify this because 1) we hope
// not to need to make a distinction between table_cell & table_header 2) we don't want to allow
// rows without cells
table_row.content = "table_cell+";

// --------------------------------------------------------------------------
function getCellAttrs(dom) {
  const attrs = [];
  Object.keys(cellAttributes).forEach(attrKey => {
    const getter = cellAttributes[attrKey].getFromDOM;
    const value = getter && getter(dom);
    if (value !== null) attrs[attrKey] = value;
  })
  return attrs;
}

// --------------------------------------------------------------------------
// Inherited from pm-tables, its purpose is to translate the cellAttributes object into
// attrs that set/get DOM attributes. We could potentially remove these functions in favor of a standard schema toDOM/fromDOM
// plus a bunch of individual get/set methods per attribute, but WBH likes the delineation of cellAttributes (as of Nov 2022)
function setCellAttrs(node, extraAttrs) {
  const attrs = {};
  if (node.attrs.colwidth) {
    attrs["data-column-width"] = node.attrs.colwidth;
  }
  Object.keys(extraAttrs).forEach(prop => {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) {
      setter(node.attrs[prop], attrs);
    }
  });
  return attrs;
}

export default {
  table_row,
  table: {
    attrs: {
      fullWidth: { default: false },
    },
    content: "table_row+",
    group: "doc_child",
    isolating: true,
    parseDOM: [
      { tag: "table", getAttrs: dom => (dom.dataset.fullWidth === "true" ? { fullWidth: true } : {}) }
    ],
    tableRole: "table",
    toDOM(node) {
      return [ "table", (node.attrs.fullWidth ? { "data-full-width": "true" } : {}), [ "tbody", 0 ] ];
    },
  },
  table_cell: {
    content: "block+",
    attrs: cellAttributes,
    tableRole: "cell",
    isolating: true,
    parseDOM: [
      { tag: "td", getAttrs: dom => getCellAttrs(dom) },
      { tag: "th", getAttrs: dom => getCellAttrs(dom) },
    ],

    // --------------------------------------------------------------------------
    toDOM: node => {
      // We put cell content in a div so that there is a predictable way to draw borders around cells, in spite
      // of using `border-collapse: collapsed`, which makes for unpredictable behavior when trying to impart border
      // via `td` (it uses the border definition of upper-leftmost cell, discarding if a right cell says it has left border)
      const divAttrs = { class: CELL_CONTENT_CLASS };
      if ([ "center", "left", "right" ].includes(node.attrs.align)) {
        const textAlignStyle = `text-align: ${ node.attrs.align }`; // Requisite for Sheets to maintain alignment
        const justifyFromAlign = { left: "flex-start", center: "center", right: "flex-end" };
        divAttrs.style = `${ textAlignStyle }; align-items: ${ justifyFromAlign[node.attrs.align] };`;
      }
      return [ "td", setCellAttrs(node, cellAttributes), [ "div", divAttrs, [ "div", { class: CELL_INNER_CONTENT_CLASS }, 0 ] ] ];
    },
  },
};

import { endOfDay, startOfDay } from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import DayPicker from "react-day-picker"
import { Button } from "@rmwc/button"

import { getWeekStartsOn } from "lib/ample-util/date"

// --------------------------------------------------------------------------
export default class FilterMenuContent extends React.Component {
  static propTypes = {
    earliestDate: PropTypes.instanceOf(Date).isRequired,
    interval: PropTypes.shape({
      start: PropTypes.instanceOf(Date).isRequired,
      end: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
    nowTimestamp: PropTypes.number,
    setInterval: PropTypes.func.isRequired,
  };

  state = {
    firstDate: null,
  };

  // --------------------------------------------------------------------------
  render() {
    const { earliestDate, interval, nowTimestamp } = this.props;
    const { firstDate } = this.state;

    const now = nowTimestamp ? new Date(nowTimestamp * 1000) : new Date();

    const disabledDays = [
      { before: firstDate || earliestDate },
      { after: now },
    ];

    const from = firstDate ? firstDate : interval.start;

    const selectedDays = firstDate ? from : { from, to: interval.end };

    // This adds the .DayPicker-Day--from and .DayPicker-Day--to classes to the endpoints
    const modifiers = firstDate ? { from } : { from, to: interval.end };

    return (
      <div className="filter-menu-content">
        <DayPicker
          disabledDays={ disabledDays }
          firstDayOfWeek={ getWeekStartsOn() }
          modifiers={ modifiers }
          onDayClick={ this._onDayPickerDayClick }
          selectedDays={ selectedDays }
        />
        { firstDate ? (<Button label="Cancel" onClick={ this._clearFirstDate } />) : null }
        { (firstDate || interval.isDefault) ? null : (<Button label="Reset" onClick={ this._resetInterval } />) }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _clearFirstDate = () => {
    this.setState({ firstDate: null });
  };

  // --------------------------------------------------------------------------
  _onDayPickerDayClick = (date, modifiers) => {
    if (modifiers.disabled) return;

    const { firstDate } = this.state;
    if (firstDate) {
      this.props.setInterval({ start: startOfDay(firstDate), end: endOfDay(date) });
    } else {
      this.setState({ firstDate: date });
    }
  };

  // --------------------------------------------------------------------------
  _resetInterval = () => {
    this.props.setInterval(null);
  };
}

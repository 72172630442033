// --------------------------------------------------------------------------
export default function focusDomNodeAtEnd(domNode) {
  if (!domNode) return;

  if (document.createRange) { // Firefox, Chrome, Opera, Safari, IE 9+
    const range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(domNode); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start

    const selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
  } else if (document.selection) { // IE 8 and lower
    const range = document.body.createTextRange(); // Create a range (a range is a like the selection but invisible)
    range.moveToElementText(domNode); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    range.select(); // Select the range (make it the visible selection
  }

  domNode.focus();
}

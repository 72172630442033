// --------------------------------------------------------------------------
// Helper functions that operate on plain JSON note content data
// --------------------------------------------------------------------------
import { v4 as uuidv4 } from "uuid"

// --------------------------------------------------------------------------
const updateCheckListItem = checkListItem => {
  if (!checkListItem.attrs) checkListItem.attrs = {};

  checkListItem.attrs.createdAt = Math.floor(Date.now() / 1000);
  checkListItem.attrs.uuid = uuidv4();
};

// --------------------------------------------------------------------------
const updateHiddenTasks = hiddenTasks => {
  if (!hiddenTasks) return;

  hiddenTasks.forEach(hiddenTask => {
    updateCheckListItem(hiddenTask);
  });
};

// --------------------------------------------------------------------------
// Performs various updates in place on the given note content with the understanding that the source note content it
// came from has not been removed/changed.
//
// e.g. changing check-list-item UUIDs is necessary, as when duplicating note content that can contain check_list_item
// nodes:
//
//    - When duplicating a document, we need to make sure the tasks have unique UUIDs, as they will otherwise conflict
//      with the UUIDs in the source note.
//
//    - When creating a note with entirely new content, we need to make sure the tasks actually have UUIDs, so they can
//      be indexed if the note isn't opened immediately.
//
export const updateDuplicatedNoteContent = node => {
  if (!node) return node;

  if (node.type === "check_list_item") {
    updateCheckListItem(node);
    return;
  }

  if (node.type === "doc" && node.attrs) {
    const { attrs: { completedTasks, hiddenTasks } } = node;
    updateHiddenTasks(hiddenTasks);

    // Clear completedTasks - it's generally weird if they are duplicated (beyond having UUIDs)
    if (completedTasks) completedTasks.splice(0, completedTasks.length);
  }

  if (node.content) {
    node.content.forEach(childNode => updateDuplicatedNoteContent(childNode));
  }
};

import { formatDistanceToNow, format } from "date-fns"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Tippy from "@tippyjs/react"

import { bucketFromTaskValue, calculateTaskValue, doneAtFromTask } from "lib/ample-util/tasks"
import { hasModifierKey } from "lib/ample-editor/lib/event-util";

// --------------------------------------------------------------------------
function CreatedAt({ createdAt }) {
  const createdAtDate = createdAt ? new Date(createdAt * 1000) : null;
  const createdAtTooltip = createdAtDate ? format(createdAtDate, "iiii, LLLL do yyyy, h:mm:ss a") : "exact time unknown";

  return (

    <Tippy content={ createdAtTooltip } delay={ 150 } placement="bottom">
      <div className="timestamp created-at">
        Created { createdAtDate ? formatDistanceToNow(createdAtDate, { addSuffix: true }) : "a while back" }
      </div>
    </Tippy>
  );
}

// --------------------------------------------------------------------------
function DoneAt(props) {
  const doneAt = doneAtFromTask(props);
  if (!doneAt) return null;

  const { crossedOutAt, dismissedAt } = props;

  const doneAtDate = new Date(doneAt * 1000);
  const doneAtTooltip = format(doneAtDate, "iiii, LLLL do yyyy, h:mm:ss a");

  let doneVerbiage;
  if (crossedOutAt) {
    doneVerbiage = "Crossed out";
  } else if (dismissedAt) {
    doneVerbiage = "Dismissed";
  } else {
    doneVerbiage = "Completed";
  }

  return (
    <Tippy content={ doneAtTooltip } delay={ 150 } placement="bottom">
      <div className="timestamp">
        { doneVerbiage } { formatDistanceToNow(doneAtDate, { addSuffix: true }) }
      </div>
    </Tippy>
  );
}

// --------------------------------------------------------------------------
function TaskValue({ startEditing, value }) {
  return (
    <div className="value" onClick={ startEditing }>
      { Math.round(value * 100) / 100 }
    </div>
  );
}

// --------------------------------------------------------------------------
function TaskValueInput({ stopEditing, value }) {
  const [ inputValue, setInputValue ] = useState(Number(value.toFixed(2)).toString());

  const onBlur = useCallback(
    () => {
      stopEditing(inputValue);
    },
    [ inputValue ]
  );

  const onKeyDown = useCallback(
    event => {
      if (hasModifierKey(event)) return;

      if (event.key === "Enter") {
        event.preventDefault();
        stopEditing(inputValue);
      } else if (event.key === "Escape") {
        event.preventDefault();
        stopEditing(null);
      }
    },
    [ inputValue, stopEditing ]
  );

  const onValueChange = useCallback(
    event => {
      setInputValue(event.target.value);
    },
    []
  );

  return (
    <input
      autoFocus
      className="value text-input"
      inputMode="numeric"
      maxLength={ 4 }
      onBlur={ onBlur }
      onChange={ onValueChange }
      onKeyDown={ onKeyDown }
      type="text"
      value={ inputValue }
    />
  );
}

// --------------------------------------------------------------------------
export default function TaskMetadata(props) {
  const { readonly, setPoints } = props;

  const [ editing, setEditing ] = useState(false);
  const [ flexWrapped, setFlexWrapped ] = useState(false);

  const domRef = useRef();

  useEffect(
    () => {
      const { current: dom } = domRef;

      // This exploits the knowledge that this element is shown next to the controls container with flex-wrapping
      // happening if the two cannot fit side by side. But if we do wrap, we want to adjust the display to match the
      // narrow _viewport_ display that we use when forcing wrapping on small screens. There's no media query equivalent
      // that looks at the container element, so we need to detect this after rendering.
      if (dom.offsetTop > dom.previousSibling.offsetHeight) setFlexWrapped(true);
    },
    []
  );

  const value = calculateTaskValue(props);

  const startEditing = useCallback(
    () => {
      if (!readonly) setEditing(true);
    },
    [ readonly ]
  );

  const stopEditing = inputValue => {
    setEditing(false);

    if (inputValue) {
      const newValue = inputValue.includes(".") ? parseFloat(inputValue) : parseInt(inputValue || "0", 10);
      if (!isNaN(newValue)) {
        const valueWithoutPoints = calculateTaskValue({ ...props, points: 0 });
        const points = newValue - valueWithoutPoints;
        setPoints(points);
      }
    }
  };

  let className = `task-metadata value-${ bucketFromTaskValue(value) }`;
  if (flexWrapped) className += " flex-wrapped";
  if (readonly) className += " readonly";

  return (
    <div className={ className } ref={ domRef }>
      <div>Task Score</div>

      {
        editing
          ? (<TaskValueInput stopEditing={ stopEditing } value={ value } />)
          : (<TaskValue startEditing={ startEditing } value={ value } />)
      }

      <CreatedAt { ...props } />
      <DoneAt { ...props } />
    </div>
  );
}

TaskMetadata.propTypes = {
  readonly: PropTypes.bool,
  setPoints: PropTypes.func.isRequired,
};

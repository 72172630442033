import { omit } from "lodash"
import { Schema } from "prosemirror-model"

import { marks, nodes as standardSchemaNodes } from "lib/ample-editor/schema"

// --------------------------------------------------------------------------
const nodes = {
  // --------------------------------------------------------------------------
  doc: {
    attrs: {
      // Only gets populated if the tasks-completion-plugin is enabled
      completedTasks: { default: [] },
    },
    content: "tasks_group*"
  },

  // --------------------------------------------------------------------------
  // Paragraph must be first after doc so it's the default block node type
  paragraph: standardSchemaNodes.paragraph,

  // --------------------------------------------------------------------------
  tasks_group: {
    attrs: {
      className: { default: null },
      empty: { default: false },
      href: { default: null },
      icon: { default: null },
      id: { default: null },
      name: { default: "" },
    },
    content: "check_list_item*",
    defining: true,
    draggable: false,
    group: "tasks_group",
    isolating: true,
    selectable: false,
    toDOM(_node) {
      return [ "div", { class: "tasks-group" }, 0 ];
    }
  },

  // --------------------------------------------------------------------------
  // In tasks editors we don't want to show an option to insert an image's OCR text after the image, as it doesn't
  // format well, getting inserted as multiple subsequent check-list-item nodes.
  image: {
    ...standardSchemaNodes.image,

    disableTextInsertion: true,
  },

  // --------------------------------------------------------------------------
  // We don't want the other list item types, nor the types we define differently/specifically
  ...omit(standardSchemaNodes, [ "doc", "bullet_list_item", "image", "number_list_item", "paragraph" ]),
};

// --------------------------------------------------------------------------
// Allows identification of a given schema as a tasks schema
export function isTasksSchema(schema) {
  return !!schema.nodes.tasks_group;
}

// --------------------------------------------------------------------------
const tasksSchema = new Schema({ nodes, marks });
export default tasksSchema;

import { chainCommands, wrapIn } from "prosemirror-commands"
import { NodeSelection, Plugin, PluginKey, TextSelection } from "prosemirror-state"
import { isInTable } from "prosemirror-tables"

import { isDescriptionSchema } from "lib/ample-editor/components/rich-footnote/description-schema"
import { isTasksSchema } from "lib/ample-editor/components/tasks-editor/tasks-schema"
import { reorderCheckListItems } from "lib/ample-editor/commands/check-list-item-commands"
import extractToLinkDescription from "lib/ample-editor/commands/extract-to-link-description"
import { maybeRefreshTableOfContents } from "lib/ample-editor/commands/table-of-content-commands"
import toggleNodeCollapsed from "lib/ample-editor/commands/toggle-node-collapsed"
import { convertFromCodeBlock, convertToCodeBlock } from "lib/ample-editor/lib/code-block-commands"
import {
  buildSetBlockType,
  clearActiveMarks,
  hasBlock,
  liftEach,
  liftFrom,
  redoWithRetainEditorFocus,
  toggleMark,
  undoInputRuleWithoutHistory,
  undoWithRetainEditorFocus,
  wrapEachIn,
} from "lib/ample-editor/lib/commands"
import { buildToggleLink } from "lib/ample-editor/lib/link-commands"
import {
  maybeIndentListItem,
  maybeMoveListItemDown,
  maybeMoveListItemUp,
  maybeOutdentListItem,
} from "lib/ample-editor/lib/list-item-commands"
import {
  addColumnAndNext,
  addColumnBefore,
  addRowBefore,
  deleteColumnOrReturnNull,
  deleteRowOrReturnNull,
  toggleTableBlock,
  toggleTableColumnLockedWidth,
  toggleTableColumnSort,
  toggleTableFullWidth,
} from "lib/ample-editor/lib/table/table-commands"
import TOOLBAR_COMMAND from "lib/ample-editor/lib/toolbar-command"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import { getTargetViewStates, getTargetViews } from "lib/ample-editor/plugins/target-view-plugin"
import expandCollapseNodeAvailable from "lib/ample-editor/util/expand-collapse-node-available"
import ToolbarView from "lib/ample-editor/views/toolbar-view"

// --------------------------------------------------------------------------
const MARKS = [
  [ TOOLBAR_COMMAND.TOGGLE_MARK_CODE, "code" ],
  [ TOOLBAR_COMMAND.TOGGLE_MARK_EM, "em" ],
  [ TOOLBAR_COMMAND.TOGGLE_MARK_HIGHLIGHT, "highlight" ],
  [ TOOLBAR_COMMAND.TOGGLE_MARK_STRIKETHROUGH, "strikethrough" ],
  [ TOOLBAR_COMMAND.TOGGLE_MARK_STRONG, "strong" ],
];

// --------------------------------------------------------------------------
const BLOCK_NODES = [
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_BLOCKQUOTE, "blockquote", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_1, "heading", { level: 1 } ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_2, "heading", { level: 2 } ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_3, "heading", { level: 3 } ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_ATTACHMENT, "attachment", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_IMAGE, "image", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_NUMBER_LIST_ITEM, "number_list_item", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_BULLET_LIST_ITEM, "bullet_list_item", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_CHECK_LIST_ITEM, "check_list_item", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_TABLE, "table", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_CODE_BLOCK, "code_block", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_VIDEO, "video", null ],
];

// --------------------------------------------------------------------------
const TASKS_EDITOR_BLOCK_NODES = [
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_IMAGE, "image", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_CODE_BLOCK, "code_block", null ],
  [ TOOLBAR_COMMAND.TOGGLE_BLOCK_VIDEO, "video", null ],
];

// --------------------------------------------------------------------------
const chainedUndo = chainCommands(
  undoInputRuleWithoutHistory,
  undoWithRetainEditorFocus,
);

// --------------------------------------------------------------------------
const pluginKey = new PluginKey("toolbar");

// --------------------------------------------------------------------------
// Builds a hash where they keys are the names of commands that are relevant to the current editor schema, while the
// values are booleans indicating whether the selection contains what the command would produce (and generally the
// command would remove that if executed).
function buildAvailableCommands(topLevelState) {
  const { state, historyState } = getTargetViewStates(topLevelState);
  const { schema } = state;

  // The tasks editor may or may not be using a targetView (it can be standalone, in which case it's the top-level
  // editor), so just observing targetViewType isn't enough to determine if this is a tasks-editor
  const isTasksEditor = isTasksSchema(schema);

  const availableCommands = {};

  MARKS.forEach(([ commandName, markName ]) => {
    availableCommands[commandName] = !!hasMark(state, schema.marks[markName])
  });

  let headingActive = false;
  let listItemActive = false;
  (isTasksEditor ? TASKS_EDITOR_BLOCK_NODES : BLOCK_NODES).forEach(([ commandName, nodeName, attrs ]) => {
    const nodeType = schema.nodes[nodeName];
    if (!nodeType) return;

    const active = !!hasBlock(state, nodeType, attrs);

    if (nodeType.spec.isListItem) {
      if (active) {
        listItemActive = true;
      } else if (schema.nodes.image) {
        // Ideally we would test that the list item command could actually succeed here, but doing so is expensive
        // enough (and complicated enough to just test for) that we'll just disable list commands entirely when in
        // an image's caption, which is the main case where we don't want to have list item commands appear to be
        // available
        const { selection: { $from, $to } } = state;
        if ($from.blockRange($to, node => node.type === schema.nodes.image)) return;
      }
    } else if (nodeType === schema.nodes.code_block) {
      // When an image is selected, code block will replace the image, which is not what users anticipate happening
      const { selection } = state;
      if (selection instanceof NodeSelection && selection.node.type === schema.nodes.image) return;

      // Similarly, a code block in an image caption will result in the image being replaced by the code block
      const { selection: { $from, $to } } = state;
      if ($from.blockRange($to, node => node.type === schema.nodes.image)) return;
    } else if (nodeType === schema.nodes.heading) {
      if (active) headingActive = true;
    }

    availableCommands[commandName] = active;
  });

  const linkNodeType = schema.nodes.link;
  if (linkNodeType) {
    const linkNodePos = firstNodePos(state, linkNodeType);
    if (linkNodePos !== null) {
      availableCommands[TOOLBAR_COMMAND.TOGGLE_LINK] = true;

      const linkNode = state.doc.nodeAt(linkNodePos);
      if (linkNode) {
        availableCommands[TOOLBAR_COMMAND.EDIT_LINK_DESCRIPTION] = !!linkNode.attrs.description;
        availableCommands[TOOLBAR_COMMAND.EDIT_LINK_MEDIA] = !!linkNode.attrs.media;
      }
    } else {
      const canLink = buildToggleLink({ createMarkdownLinks: isDescriptionSchema(schema) })(state, null);
      availableCommands[TOOLBAR_COMMAND.TOGGLE_LINK] = canLink ? false : null;

      if (extractToLinkDescription(state, null)) {
        availableCommands[TOOLBAR_COMMAND.EXTRACT_TO_LINK_DESCRIPTION] = true;
      }
    }
  }

  // When we're in a tasks editor, we can't replace the check-list-item that the selection is in with a link
  // in a paragraph, as that's not valid at the top level of that schema. To support that, we would need to be
  // able to tell the outer client that we want to replace the check-list-item with some other content, but as
  // of 1/2022 we don't have a way to do that.
  availableCommands[TOOLBAR_COMMAND.EXTRACT_TO_LINKED_NOTE] = !isTasksEditor;

  if (listItemActive) {
    availableCommands[TOOLBAR_COMMAND.TABLE_OF_CONTENTS_REFRESH] = maybeRefreshTableOfContents(state, null);
    availableCommands[TOOLBAR_COMMAND.LIST_ITEM_MOVE_DOWN] = maybeMoveListItemDown(state, null);
    availableCommands[TOOLBAR_COMMAND.LIST_ITEM_MOVE_UP] = maybeMoveListItemUp(state, null);
    availableCommands[TOOLBAR_COMMAND.LIST_ITEM_INDENT] = maybeIndentListItem(state, null);
    availableCommands[TOOLBAR_COMMAND.LIST_ITEM_OUTDENT] = maybeOutdentListItem(state, null);

    if (reorderCheckListItems(state, null)) {
      availableCommands[TOOLBAR_COMMAND.LIST_ITEM_REORDER] = true;
    }
  }

  if (listItemActive || headingActive) {
    availableCommands[TOOLBAR_COMMAND.TOGGLE_COLLAPSED] = expandCollapseNodeAvailable(state);
  }

  if (schema.nodes.table) {
    const cursorInTable = isInTable(state);
    if (cursorInTable) {
      availableCommands[TOOLBAR_COMMAND.INSERT_TABLE_COLUMN_BEFORE] = addColumnBefore(state, null);
      availableCommands[TOOLBAR_COMMAND.TABLE_COLUMN_SORT] = toggleTableColumnSort(state, null);
      availableCommands[TOOLBAR_COMMAND.INSERT_TABLE_ROW] = addRowBefore(state, null);
      availableCommands[TOOLBAR_COMMAND.DELETE_TABLE_COLUMN] = deleteColumnOrReturnNull(state, null);
      availableCommands[TOOLBAR_COMMAND.DELETE_TABLE_ROW] = deleteRowOrReturnNull(state, null);
      availableCommands[TOOLBAR_COMMAND.TOGGLE_TABLE_FULL_WIDTH] = toggleTableFullWidth(state, null);
      availableCommands[TOOLBAR_COMMAND.TOGGLE_TABLE_COLUMN_LOCKED_WIDTH] = toggleTableColumnLockedWidth(state, null);
      availableCommands[TOOLBAR_COMMAND.LIST_ITEM_REORDER] = null;
      availableCommands[TOOLBAR_COMMAND.TOGGLE_BLOCK_CHECK_LIST_ITEM] = null;
      availableCommands[TOOLBAR_COMMAND.TOGGLE_BLOCK_CODE_BLOCK] = null;
    }
  } else {
    availableCommands[TOOLBAR_COMMAND.TOGGLE_BLOCK_TABLE] = null;
  }

  availableCommands[TOOLBAR_COMMAND.CLEAR_MARKS] = clearActiveMarks(state, null);

  availableCommands[TOOLBAR_COMMAND.REDO] = redoWithRetainEditorFocus(historyState, null);
  availableCommands[TOOLBAR_COMMAND.UNDO] = chainedUndo(historyState, null);

  return availableCommands;
}

// --------------------------------------------------------------------------
function createAndEditLink(editFieldName) {
  return function(state, dispatch) {
    const { schema } = state;

    const linkNodePos = firstNodePos(state, schema.nodes.link);
    if (linkNodePos !== null) {
      const transaction = setOpenLinkPosition(state.tr, linkNodePos, editFieldName);

      // Need to select just the (first) link node, in case the selection spans multiple links or spans outside the link,
      // in which case the open link from state would not be the same as the link here
      const linkNode = state.doc.nodeAt(linkNodePos);
      transaction.setSelection(TextSelection.create(transaction.doc, linkNodePos, linkNodePos + linkNode.nodeSize - 1));

      if (dispatch) dispatch(transaction);
      return true;
    }

    return buildToggleLink({ createMarkdownLinks: isDescriptionSchema(schema), editFieldName })(state, dispatch);
  }
}

// --------------------------------------------------------------------------
function executeBlockCommand(state, dispatch, blockType, attrs) {
  const { schema } = state;

  const command = hasBlock(state, blockType, attrs)
    ? buildSetBlockType(schema.nodes.paragraph)
    : buildSetBlockType(blockType, attrs);

  command(state, dispatch);
}

// --------------------------------------------------------------------------
function executeCodeBlockCommand(state, dispatch, blockType) {
  const { schema } = state;

  const command = hasBlock(state, blockType)
    ? convertFromCodeBlock(schema.nodes.paragraph)
    : convertToCodeBlock(blockType);

  command(state, dispatch);
}

// --------------------------------------------------------------------------
export function executeToolbarCommand(topLevelEditorView, commandName) {
  const { editorView, historyEditorView } = getTargetViews(topLevelEditorView);

  const { dispatch, state, state: { schema } } = editorView;

  // The name of the command corresponds to a name built in buildPluginState and returned in the availableCommands object
  switch (commandName) {
    // --------------------------------------------------------------------------
    // Marks
    case TOOLBAR_COMMAND.TOGGLE_MARK_CODE: toggleMark(schema.marks.code)(state, dispatch); break;
    case TOOLBAR_COMMAND.TOGGLE_MARK_EM: toggleMark(schema.marks.em)(state, dispatch); break;
    case TOOLBAR_COMMAND.TOGGLE_MARK_HIGHLIGHT: toggleMark(schema.marks.highlight)(state, dispatch); break;
    case TOOLBAR_COMMAND.TOGGLE_MARK_STRIKETHROUGH: toggleMark(schema.marks.strikethrough)(state, dispatch); break;
    case TOOLBAR_COMMAND.TOGGLE_MARK_STRONG: toggleMark(schema.marks.strong)(state, dispatch); break;

    case TOOLBAR_COMMAND.CLEAR_MARKS: clearActiveMarks(state, dispatch); break;

    // --------------------------------------------------------------------------
    // Block nodes
    case TOOLBAR_COMMAND.TOGGLE_BLOCK_BLOCKQUOTE:
      executeWrapCommand(state, dispatch, schema.nodes.blockquote);
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_1:
      executeBlockCommand(state, dispatch, schema.nodes.heading, { level: 1 });
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_2:
      executeBlockCommand(state, dispatch, schema.nodes.heading, { level: 2 });
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_HEADING_3:
      executeBlockCommand(state, dispatch, schema.nodes.heading, { level: 3 });
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_BULLET_LIST_ITEM:
      if (schema.nodes.bullet_list_item) executeWrapEachCommand(state, dispatch, schema.nodes.bullet_list_item);
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_CHECK_LIST_ITEM:
      if (schema.nodes.check_list_item) executeWrapEachCommand(state, dispatch, schema.nodes.check_list_item);
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_NUMBER_LIST_ITEM:
      if (schema.nodes.number_list_item) executeWrapEachCommand(state, dispatch, schema.nodes.number_list_item);
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_TABLE:
      if (schema.nodes.table) toggleTableBlock(state, dispatch);
      break;

    case TOOLBAR_COMMAND.TOGGLE_BLOCK_CODE_BLOCK:
      executeCodeBlockCommand(state, dispatch, schema.nodes.code_block);
      break;

    case TOOLBAR_COMMAND.TOGGLE_COLLAPSED:
      toggleNodeCollapsed(state, dispatch);
      break;

    // --------------------------------------------------------------------------
    // Links
    case TOOLBAR_COMMAND.EDIT_LINK_DESCRIPTION:
      createAndEditLink("description")(state, dispatch);
      break;

    case TOOLBAR_COMMAND.EDIT_LINK_MEDIA:
      createAndEditLink("media")(state, dispatch);
      break;

    case TOOLBAR_COMMAND.EXTRACT_TO_LINK_DESCRIPTION: {
      const linkNodePos = firstNodePos(state, schema.nodes.link);
      if (!linkNodePos) extractToLinkDescription(state, dispatch);
      break;
    }

    case TOOLBAR_COMMAND.TOGGLE_LINK:
      buildToggleLink({ createMarkdownLinks: isDescriptionSchema(schema) })(state, dispatch);
      break;

    // --------------------------------------------------------------------------
    // List item manipulation
    case TOOLBAR_COMMAND.LIST_ITEM_INDENT: maybeIndentListItem(state, dispatch); break;
    case TOOLBAR_COMMAND.LIST_ITEM_MOVE_DOWN: maybeMoveListItemDown(state, dispatch); break;
    case TOOLBAR_COMMAND.LIST_ITEM_MOVE_UP: maybeMoveListItemUp(state, dispatch); break;
    case TOOLBAR_COMMAND.LIST_ITEM_OUTDENT: maybeOutdentListItem(state, dispatch); break;
    case TOOLBAR_COMMAND.LIST_ITEM_REORDER:
      if (schema.nodes.check_list_item) reorderCheckListItems(state, dispatch);
      break;
    case TOOLBAR_COMMAND.TABLE_OF_CONTENTS_REFRESH: maybeRefreshTableOfContents(state, dispatch); break;

    // --------------------------------------------------------------------------
    // Table
    case TOOLBAR_COMMAND.INSERT_TABLE_COLUMN_AFTER: addColumnAndNext(state, dispatch); break;
    case TOOLBAR_COMMAND.INSERT_TABLE_COLUMN_BEFORE: addColumnBefore(state, dispatch); break;
    case TOOLBAR_COMMAND.INSERT_TABLE_ROW: addRowBefore(state, dispatch); break;
    case TOOLBAR_COMMAND.DELETE_TABLE_COLUMN: deleteColumnOrReturnNull(state, dispatch); break;
    case TOOLBAR_COMMAND.DELETE_TABLE_ROW: deleteRowOrReturnNull(state, dispatch); break;
    case TOOLBAR_COMMAND.TABLE_COLUMN_SORT: toggleTableColumnSort(state, dispatch); break;
    case TOOLBAR_COMMAND.TOGGLE_TABLE_COLUMN_LOCKED_WIDTH: toggleTableColumnLockedWidth(state, dispatch); break;
    case TOOLBAR_COMMAND.TOGGLE_TABLE_FULL_WIDTH: toggleTableFullWidth(state, dispatch); break;

    // --------------------------------------------------------------------------
    // History
    case TOOLBAR_COMMAND.REDO:
      redoWithRetainEditorFocus(historyEditorView.state, historyEditorView.dispatch);
      break;

    case TOOLBAR_COMMAND.UNDO:
      chainedUndo(historyEditorView.state, historyEditorView.dispatch);
      break;

    // --------------------------------------------------------------------------
    default:
      break;
  }

  return null;
}

// --------------------------------------------------------------------------
function executeWrapCommand(state, dispatch, blockType) {
  const command = hasBlock(state, blockType)
    ? liftFrom(blockType)
    : wrapIn(blockType, null);

  command(state, dispatch);
}

// --------------------------------------------------------------------------
function executeWrapEachCommand(state, dispatch, blockType) {
  const command = hasBlock(state, blockType)
    ? liftEach(blockType)
    : wrapEachIn(blockType, null);

  command(state, dispatch);
}

// --------------------------------------------------------------------------
function firstNodePos(state, type) {
  const { selection: { from, to } } = state;

  let foundNodePos = null;

  state.doc.nodesBetween(from, to, (node, pos) => {
    if (foundNodePos !== null) return false;

    if (node.type === type) {
      foundNodePos = pos;
      return false;
    }
  });

  return foundNodePos;
}
// --------------------------------------------------------------------------
export function getAvailableCommands(state) {
  const pluginState = pluginKey.getState(state);
  return pluginState ? pluginState.availableCommands : {};
}

// --------------------------------------------------------------------------
function hasMark(state, type) {
  const { from, $from, to, empty } = state.selection;

  if (empty) return type.isInSet(state.storedMarks || $from.marks());
  else return state.doc.rangeHasMark(from, to, type);
}

// --------------------------------------------------------------------------
export default function createToolbarPlugin({ hideToolbar = false } = {}) {
  return new Plugin({
    key: pluginKey,

    state: {
      init: (_config, state) => ({
        availableCommands: buildAvailableCommands(state),
      }),
      apply: (tr, pluginState, _oldState, state) => ({
        availableCommands: buildAvailableCommands(state),
      }),
    },

    view: hideToolbar ? null : editorView => new ToolbarView(editorView),
  });
}

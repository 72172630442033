import {
  addHours,
  addMinutes,
  addSeconds,
  format,
  isEqual as areEqualDates,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
} from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import Tippy from "@tippyjs/react"

import { blurTargetOnEnter } from "lib/ample-editor/components/task-detail/util"
import TimeInput from "lib/ample-editor/components/task-detail/time-input"
import parseDateText from "lib/ample-editor/lib/parse-date-text"
import { formatDate, formatDateTime, getWeekStartsOn } from "lib/ample-util/date"
import { durationParamsFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const DAY_PICKER_CLASS_NAMES = {
  container: "next-instance-date-container DayPickerInput",
  overlayWrapper: "DayPickerInput-OverlayWrapper",
  overlay: "DayPickerInput-Overlay"
};

// --------------------------------------------------------------------------
function timeDurationFromDate(date) {
  return date === null ? "" : format(date, "'PT'HH'H'mm'M'ss'S'");
}

// --------------------------------------------------------------------------
export default class NextInstanceInput extends React.PureComponent {
  // --------------------------------------------------------------------------
  static propTypes = {
    disabled: PropTypes.bool,
    expectedNextInstance: PropTypes.instanceOf(Date),
    nextInstance: PropTypes.instanceOf(Date),
    setNextInstance: PropTypes.func.isRequired,
  };

  state = {
    inputText: null,
  };

  // --------------------------------------------------------------------------
  render() {
    const { disabled, expectedNextInstance, nextInstance } = this.props;
    if (!nextInstance) return null;

    const { inputText } = this.state;

    const inputProps = {
      className: `next-instance-date-input ${ nextInstance ? "valid" : "invalid" }`,
      disabled,
      onBlur: this._onInputBlur,
      onChange: this._onInputChange,
      onKeyPress: blurTargetOnEnter,
    };

    const adjusted = !expectedNextInstance || !areEqualDates(expectedNextInstance, nextInstance);

    return (
      <div className={ `next-instance-input${ adjusted ? " adjusted" : "" }` }>
        <DayPickerInput
          classNames={ DAY_PICKER_CLASS_NAMES }
          dayPickerProps={ { firstDayOfWeek: getWeekStartsOn() } }
          inputProps={ inputProps }
          formatDate={ formatDate }
          onDayChange={ this._setDay }
          parseDate={ this._parseDateText }
          placeholder={ `e.g. ${ formatDate(Date.now()) }` }
          value={ inputText || nextInstance || "" }
        />
        { this._renderTimeInput(nextInstance) }

        <Tippy content={ this._renderAdjustedTooltip(expectedNextInstance) }>
          <i className="material-icons adjusted-icon">update</i>
        </Tippy>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  // Clear out the user's text so we fall back to a validly formatted date matching the `nextInstance` prop
  _onInputBlur = () => {
    this.setState({ inputText: null });
  };

  // --------------------------------------------------------------------------
  _onInputChange = event => {
    const inputText = event.target.value;
    this.setState({ inputText });
  };

  // --------------------------------------------------------------------------
  _parseDateText = text => {
    const { nextInstance } = this.props;
    const { inputText } = this.state;

    // react-day-picker will try to re-parse whatever date string we have, but we omit the year for dates this year,
    // while parseDateText assumes any date given is in the future. This would result in the calendar picker showing
    // next year if `nextInstance` is in the past (but this year). We want to show the calendar picker for this year
    // until the user has entered something different in the input, as that would indicate they want a _new_ date and
    // presumably want it to be in the future.
    if (!inputText && nextInstance && text === formatDate(nextInstance)) {
      return nextInstance;
    }

    return parseDateText(text);
  };

  // --------------------------------------------------------------------------
  _renderAdjustedTooltip(expectedNextInstance) {
    if (expectedNextInstance) {
      return `Changed from schedule of ${ formatDateTime(expectedNextInstance) }`;
    } else {
      return "Changed from schedule (not repeating)";
    }
  }

  // --------------------------------------------------------------------------
  _renderTimeInput = nextInstance => {
    const { disabled } = this.props;
    const duration = nextInstance ? timeDurationFromDate(nextInstance) : "";

    return (
      <TimeInput
        disabled={ disabled }
        duration={ duration }
        setDuration={ this._setTime }
      />
    );
  };

  // --------------------------------------------------------------------------
  _setDay = date => {
    if (date) {
      const { nextInstance } = this.props;

      // Retain the time-of-day from the existing next instance
      if (nextInstance) {
        date = setSeconds(
          setMinutes(
            setHours(date, nextInstance.getHours()),
            nextInstance.getMinutes()
          ),
          nextInstance.getSeconds()
        );
      } else {
        date = startOfDay(date);
      }
    }

    this._setNextInstance(date);
  };

  // --------------------------------------------------------------------------
  _setNextInstance = date => {
    const { expectedNextInstance, setNextInstance } = this.props;

    // If  the user is clearing out the date, we'll assume they want to return to the expected next instance, rather
    // than actually clearing out the date entirely.
    if (!date) date = expectedNextInstance;

    if (setNextInstance) setNextInstance(date);
  };

  // --------------------------------------------------------------------------
  _setTime = duration => {
    const { expectedNextInstance, nextInstance } = this.props;

    let date = startOfDay(nextInstance);

    const durationParams = durationParamsFromDuration(duration);
    if (durationParams) {
      if (durationParams.hours) date = addHours(date, durationParams.hours);
      if (durationParams.minutes) date = addMinutes(date, durationParams.minutes);
      if (durationParams.seconds) date = addSeconds(date, durationParams.seconds);
    } else if (expectedNextInstance) {
      date = addHours(date, expectedNextInstance.getHours());
      date = addMinutes(date, expectedNextInstance.getMinutes());
      date = addSeconds(date, expectedNextInstance.getSeconds());
    }

    this._setNextInstance(date);
  };
}

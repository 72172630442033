import PropTypes from "prop-types"
import React from "react"
import { List, ListItem, ListItemMeta } from "@rmwc/list"
import scrollIntoView from "scroll-into-view-if-needed"

import { MAX_SUGGESTIONS } from "lib/ample-editor/components/link-target-menu/constants"
import HeadingSuggestions from "lib/ample-editor/components/link-target-menu/heading-suggestions"
import NoteSuggestions, {
  headingNoteURLFromNoteSuggestion,
  isNewNoteSuggestion,
  newTagTextsFromNoteSuggestion,
} from "lib/ample-editor/components/link-target-menu/note-suggestions"
import TagSuggestions from "lib/ample-editor/components/link-target-menu/tag-suggestions"
import TaskSuggestions from "lib/ample-editor/components/link-target-menu/task-suggestions"
import { isAndroidChrome } from "lib/ample-editor/lib/client-info"
import { hasModifierKey } from "lib/ample-editor/lib/event-util"
import parseLinkTargetText, {
  INCLUDE_SOURCE_TAGS_CHARACTER,
  OMIT_FILTER_TAGS_CHARACTER,
  SEARCH_HEADINGS_CHARACTER,
  SEARCH_TASKS_CHARACTER,
  tagSearchTermFromText,
} from "lib/ample-editor/lib/parse-link-target-text"
import { normalizeTagText, TAG_TEXT_DELIMITER, tagFromTagText, TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
function closedTextFromText(text) {
  const match = text.match(/^(.+)\s*]]\s*$/)
  return match ? match[1] : null;
}

// --------------------------------------------------------------------------
function haveTagWithText(tags, newTagText) {
  if (!tags) return false;

  for (let i = 0; i < tags.length; i++) {
    const { text: tagText } = tags[i];

    // If you have a tag "blah/sub-tag" then you already have the "blah" tag
    if (tagText === newTagText || tagText.startsWith(newTagText + TAG_TEXT_DELIMITER)) {
      return true;
    }
  }

  return false;
}

// --------------------------------------------------------------------------
// The text prop gets passed to this component with a trailing `]` that allows
// this menu to detect a closing `]]`, but we don't typically want to include it
// in search terms
function textWithoutTrailingBracket(text) {
  return text.replace(/]$/, "");
}

// --------------------------------------------------------------------------
function NoteSuggestionsHint({ activeNoteSuggestion, sourceTags, text }) {
  const shouldShowIncludeSourceTagsHint = activeNoteSuggestion &&
    sourceTags && sourceTags.length &&
    !text.startsWith(INCLUDE_SOURCE_TAGS_CHARACTER) && text.length < 3 &&
    (!activeNoteSuggestion.newTags || activeNoteSuggestion.newTags.length === 0);
  if (shouldShowIncludeSourceTagsHint) {
    return (
      <ListItem className="hint" disabled>
        Start with "{ INCLUDE_SOURCE_TAGS_CHARACTER }" to apply tags from current note to
        the { isNewNoteSuggestion(activeNoteSuggestion) ? "new" : "selected" } note
      </ListItem>
    );
  }

  if (text && !text.includes("#") && activeNoteSuggestion && !isNewNoteSuggestion(activeNoteSuggestion)) {
    return (
      <ListItem className="hint" disabled>
        Search the selected note's headers by typing "#"
      </ListItem>
    );
  }

  return null;
}

// --------------------------------------------------------------------------
export default class LinkTargetMenu extends React.Component {
  static propTypes = {
    acceptSuggestion: PropTypes.func.isRequired,
    acceptSuggestionOnTab: PropTypes.bool,
    actionDescription: PropTypes.string,
    editorView: PropTypes.object,
    // If given, matching headings in the active note suggestion will be enabled (by including `#` in the text)
    fetchNoteContent: PropTypes.func,
    // Only consumed on initial construction
    initialOptions: PropTypes.shape({
      // If true, the `sourceTags` returned by suggestNotes will be inserted so they are present in props.text and
      // get applied to any existing note being linked to (in addition to any new notes).
      insertSourceTags: PropTypes.bool,
    }),
    // The intention is to insert the content of the target note, not to link to it, which changes some text/behavior
    insertContentMode: PropTypes.bool,
    insertTagText: PropTypes.func,
    inTaskUUID: PropTypes.string,
    mirrorTask: PropTypes.func,
    onCancel: PropTypes.func,
    placeholder: PropTypes.string,
    shouldSuggestTags: PropTypes.func,
    suggestNotes: PropTypes.func.isRequired,
    suggestTags: PropTypes.func,
    suggestTasks: PropTypes.func,
    text: PropTypes.string.isRequired,
  };

  _shouldInsertSourceTags = false; // Inserts the source tags' text into the note content when the note link menu shows up, such that the tags would be applied (as if the user had typed them out)
  _listRef = React.createRef();
  _suggestionsInstanceRef = React.createRef();
  _textLastChangedAt = 0;

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    const { insertSourceTags } = props.initialOptions || { insertSourceTags: false };
    this._shouldInsertSourceTags = insertSourceTags;

    const { shouldSuggestTags, text } = this.props;

    const showTagSuggestions = text.endsWith(TAG_TEXT_DELIMITER) && (!shouldSuggestTags || shouldSuggestTags(text));

    this.state = {
      activeNoteSuggestionIndex: 0,
      dismissed: false,
      headingSearchStartOffset: 0,
      taskSearchStartOffset: 0,
      noteSuggestions: [],
      showTagSuggestions,
      sourceTags: null, // Array of tags currently applied to the active note/note being edited, inasmuch as they've been successfully loaded
    };
  }

  // --------------------------------------------------------------------------
  async componentDidMount() {
    const { insertTagText, suggestNotes } = this.props;

    const { sourceTags } = await suggestNotes("source-tags-search", []);
    this.setState({ sourceTags });
    if (this._shouldInsertSourceTags && insertTagText && sourceTags && sourceTags.length > 0) {
      const tagsText = sourceTags.map(({ text }) => text).join(TAGS_DELIMITER);
      insertTagText(tagsText, { nonTagText: this.props.text });
    }

    this._updateSuggestions();
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const { text } = this.props;
    const { activeNoteSuggestionIndex, showTagSuggestions } = this.state;

    const textChanged = text !== prevProps.text;

    if (textChanged || (showTagSuggestions !== prevState.showTagSuggestions && !showTagSuggestions)) {
      const closedText = closedTextFromText(text);
      if (closedText && !closedTextFromText(prevProps.text)) {
        this._acceptActiveSuggestion();
        return;
      }

      if (this.state.dismissed) this.setState({ dismissed: false });

      if (textChanged) {
        this._textLastChangedAt = Date.now();

        if (showTagSuggestions) {
          // When suggesting tags, there are a few cases where we want to drop back to note suggestions, under the
          // assumption that the user is no longer interested in suggestions for tags
          const stopSuggestingTags = text === "" || // If the user types `[[/` then deletes the `/`
            /^.+\/.*\s/.test(text); // Typing a space, indicating we're no longer looking for sub-tags

          if (stopSuggestingTags) {
            this.setState({ showTagSuggestions: false });
            return;
          }
        } else if (isAndroidChrome && text.endsWith(TAG_TEXT_DELIMITER)) {
          // Android Chrome doesn't send a keydown event for `/` if it happens close to composition input (i.e. via the
          // soft keyboard), likely due to some over-broad Android Chrome workarounds in ProseMirror.

          const { shouldSuggestTags } = this.props;
          if (!shouldSuggestTags || shouldSuggestTags(text)) {
            this.setState({ showTagSuggestions: true });
            return;
          }
        }
      }

      this._updateSuggestions();
    }

    if (textChanged) {
      let updates = null;
      const { headingSearchStartOffset, taskSearchStartOffset } = this.state;
      if (headingSearchStartOffset && headingSearchStartOffset > text.length) {
        updates = { headingSearchStartOffset: text.length };
      }

      if (taskSearchStartOffset && taskSearchStartOffset > text.length) {
        if (!updates) updates = {};
        updates = { taskSearchStartOffset: text.length };
      }

      if (updates) this.setState(updates);
    }

    const { activeNoteSuggestionIndex: activeNoteSuggestionIndexWas } = prevState;
    if (activeNoteSuggestionIndex !== activeNoteSuggestionIndexWas) {
      this._scrollToSuggestion(activeNoteSuggestionIndex);
    }
  }

  // --------------------------------------------------------------------------
  contains(element) {
    const { current: list } = this._listRef;
    if (list && list.root && list.root.ref) {
      return list.root.ref.contains(element);
    }
    return false;
  }

  // --------------------------------------------------------------------------
  // `cancel` (when `true`) indicates that the user doesn't want to see this menu here again
  dismiss = (cancel = false) => {
    this.setState({ dismissed: true });

    if (cancel) {
      const { onCancel } = this.props;
      if (onCancel) onCancel();
    }
  };

  // --------------------------------------------------------------------------
  handleKeyDown(event) {
    if (this.state.dismissed) return false;

    // On iOS, pressing enter when the word the cursor is at the end of has a highlighted autocorrect suggestion
    // will result in the suggestion being accepted, replacing the text, _and_ inserting a newline. ProseMirror
    // detects this as an enter keypress (despite no actual keypress event occurring) and sends us this synthetic
    // key event. The problem is we just updated the suggestions to match the autocorrected text but the user
    // hasn't had a chance to see that yet, so it can seem as if the wrong item was selected. We'll just leave
    // the new text there so they get a chance to see it (need to return true to keep ProseMirror from keeping
    // the newline that Safari inserted).
    if (event.key === "Enter" && !hasModifierKey(event) && (Date.now() - this._textLastChangedAt) < 50) {
      return true;
    }

    const { current: suggestionsInstance } = this._suggestionsInstanceRef;
    if (suggestionsInstance) return suggestionsInstance.handleKeyDown(event);

    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
        if (!hasModifierKey(event)) {
          this._moveSelection(event.key === "ArrowDown" ? 1 : -1);
          return true;
        }
        break;

      case "Escape":
        if (!hasModifierKey(event)) {
          const { showTagSuggestions } = this.state;
          if (showTagSuggestions) {
            this._hideTagSuggestions();
          } else {
            this.dismiss(true);
          }
          return true;
        }
        break;

      case "Tab":
        if (!this.props.acceptSuggestionOnTab) return false;
        // eslint-disable-next-line no-fallthrough
      case "Enter":
        if (!hasModifierKey(event)) {
          this._acceptActiveSuggestion();
          return true;
        }
        break;

      case TAG_TEXT_DELIMITER: {
        const { shouldSuggestTags } = this.props;

        if (!shouldSuggestTags || shouldSuggestTags(this.props.text + TAG_TEXT_DELIMITER)) {
          const { showTagSuggestions, tagSuggestions } = this.state;

          const text = textWithoutTrailingBracket(this.props.text);

          const stateUpdates = { showTagSuggestions: true };

          // We want to let the user type out an exact match of a tag (though this relies on the tag autocomplete
          // having finished by this point) and a slash then continue typing a note name instead of continuing to
          // complete tag names. They can type another slash to come back to tag mode.
          if (showTagSuggestions && tagSuggestions && !text.endsWith(TAG_TEXT_DELIMITER)) {
            const searchTerm = tagSearchTermFromText(text);
            if (tagSuggestions.find(({ text: tagText }) => searchTerm === tagText)) {
              stateUpdates.showTagSuggestions = false;
            }
          }

          this.setState(stateUpdates);
        }

        // We don't want to swallow this keydown, just respond to it
        break;
      }

      default:
        break;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  isDismissed() {
    return this.state.dismissed;
  }

  // --------------------------------------------------------------------------
  render() {
    if (this.isDismissed()) return null;

    const {
      acceptSuggestion,
      acceptSuggestionOnTab,
      actionDescription,
      fetchNoteContent,
      insertContentMode,
      inTaskUUID,
      mirrorTask,
      suggestTasks,
      text,
    } = this.props;

    const {
      noteSuggestions,
      showTagSuggestions,
    } = this.state;

    const activeNoteSuggestion = this._activeNoteSuggestion();

    const { headingSearchText, taskSearchText, text: searchText } = this._parseLinkTargetText();

    let content;
    if (inTaskUUID && taskSearchText !== null && mirrorTask && suggestTasks) {
      const { suggestNotes } = this.props;

      content = (
        <TaskSuggestions
          acceptSuggestion={ acceptSuggestion }
          acceptSuggestionOnTab={ acceptSuggestionOnTab }
          activeNoteSuggestion={ activeNoteSuggestion }
          close={ this._hideTaskSuggestions }
          inTaskUUID={ inTaskUUID }
          mirrorTask={ mirrorTask }
          ref={ this._suggestionsInstanceRef }
          searchText={ taskSearchText }
          suggestNotes={ suggestNotes }
          suggestTasks={ suggestTasks }
        />
      );
    } else if (showTagSuggestions) {
      const { suggestTags } = this.props;

      let tagSearchText = textWithoutTrailingBracket(text);

      if (tagSearchText.startsWith(OMIT_FILTER_TAGS_CHARACTER) || tagSearchText.startsWith(INCLUDE_SOURCE_TAGS_CHARACTER)) {
        tagSearchText = tagSearchText.substring(OMIT_FILTER_TAGS_CHARACTER.length);
      }

      content = (
        <TagSuggestions
          acceptNewTagSuggestion={ this._acceptNewTagSuggestion }
          acceptSuggestionOnTab={ acceptSuggestionOnTab }
          acceptTagSuggestion={ this._acceptTagSuggestion }
          hideTagSuggestions={ this._hideTagSuggestions }
          insertContentMode={ insertContentMode }
          ref={ this._suggestionsInstanceRef }
          scrollToSuggestion={ this._scrollToSuggestion }
          searchText={ tagSearchText }
          suggestTags={ suggestTags }
        />
      );
    } else if (headingSearchText !== null && fetchNoteContent) {
      const { editorView } = this.props;

      content = (
        <HeadingSuggestions
          acceptSuggestion={ acceptSuggestion }
          acceptSuggestionOnTab={ acceptSuggestionOnTab }
          actionDescription={ actionDescription }
          activeNoteSuggestion={ activeNoteSuggestion }
          editorView={ editorView }
          fetchNoteContent={ fetchNoteContent }
          fullUserInputText={ text }
          hideHeadingSuggestions={ this._hideHeadingSuggestions }
          insertContentMode={ insertContentMode }
          ref={ this._suggestionsInstanceRef }
          scrollToSuggestion={ this._scrollToSuggestion }
          searchText={ headingSearchText }
        />
      );
    } else if (noteSuggestions.length === 0) {
      const haveText = searchText.length > 0 && searchText !== "]";

      let { noMatchPlaceholder, placeholder } = this.props;
      if (!noMatchPlaceholder) {
        if (searchText.trim() === "#") {
          noMatchPlaceholder = `Close brackets to ${ insertContentMode ? "insert" : "create a link to" } the current section.`;
        } else if (insertContentMode) {
          noMatchPlaceholder = "Type to search for a note to insert";
        } else {
          noMatchPlaceholder = "Close brackets to create a new linked note.";
        }
      }
      if (!placeholder) {
        placeholder = `Type to search for a note to ${ insertContentMode ? "insert" : "link" }.`;
      }

      content = (
        <ListItem disabled>
          { haveText ? noMatchPlaceholder : placeholder }
          { haveText ? <ListItemMeta icon="keyboard_return" /> : null }
        </ListItem>
      );
    } else {
      const { sourceTags } = this.state;

      content = (
        <React.Fragment>
          <NoteSuggestions
            acceptNoteSuggestion={ this._acceptNoteSuggestion }
            actionDescription={ actionDescription }
            activeNoteSuggestion={ activeNoteSuggestion }
            noteSuggestions={ noteSuggestions }
          />

          <NoteSuggestionsHint
            activeNoteSuggestion={ activeNoteSuggestion }
            sourceTags={ sourceTags }
            text={ text }
          />
        </React.Fragment>
      );
    }

    return (
      <List className="link-target-menu popup-list" ref={ this._listRef }>
        { content }
      </List>
    );
  }

  // --------------------------------------------------------------------------
  undismiss() {
    this.setState({ activeNoteSuggestionIndex: 0, dismissed: false });
  }

  // --------------------------------------------------------------------------
  _acceptActiveSuggestion() {
    const { current: suggestionsInstance } = this._suggestionsInstanceRef;
    if (suggestionsInstance) {
      suggestionsInstance.acceptActiveSuggestion();
      return;
    }

    const noteSuggestion = this._activeNoteSuggestion();
    if (noteSuggestion) {
      this._acceptNoteSuggestion(noteSuggestion);
    }
  }

  // --------------------------------------------------------------------------
  _acceptNewTagSuggestion = () => {
    // Use whatever they've entered (that has no matches) verbatim
    this._hideTagSuggestions();

    const { text } = this.props;
    const tagTexts = textWithoutTrailingBracket(text).split(TAGS_DELIMITER);
    const newTagText = normalizeTagText(tagSearchTermFromText(tagTexts[tagTexts.length - 1]));

    const prefix = text.startsWith(OMIT_FILTER_TAGS_CHARACTER) || text.startsWith(INCLUDE_SOURCE_TAGS_CHARACTER) ? text[0] : "";
    const newTagsText = prefix + tagTexts.slice(0, -1).concat([ newTagText ]).join(TAGS_DELIMITER);

    // This ensures that a trailing / is added and the cursor is moved appropriately
    this.props.insertTagText(newTagsText);
  };

  // --------------------------------------------------------------------------
  _acceptNoteSuggestion = noteSuggestion => {
    const { name, url } = noteSuggestion;
    const newTagTexts = newTagTextsFromNoteSuggestion(noteSuggestion);

    this.props.acceptSuggestion(name, url || null, newTagTexts);
  };

  // --------------------------------------------------------------------------
  _acceptTagSuggestion = tagSuggestion => {
    const { text: newTagText } = tagSuggestion;

    const { text } = this.props;
    const tagTexts = textWithoutTrailingBracket(text).split(TAGS_DELIMITER);

    const prefix = text.startsWith(OMIT_FILTER_TAGS_CHARACTER) || text.startsWith(INCLUDE_SOURCE_TAGS_CHARACTER) ? text[0] : "";
    const newTagsText = prefix + tagTexts.slice(0, -1).concat([ newTagText ]).join(TAGS_DELIMITER);

    this.props.insertTagText(newTagsText);
    this._hideTagSuggestions();
  };

  // --------------------------------------------------------------------------
  _activeNoteSuggestion() {
    const { activeNoteSuggestionIndex, noteSuggestions } = this.state;
    return noteSuggestions.length ? noteSuggestions[activeNoteSuggestionIndex] : null;
  }

  // --------------------------------------------------------------------------
  _hideHeadingSuggestions = () => {
    const { text } = this.props;
    const { headingSearchStartOffset } = this.state;

    const newHeadingSearchStartOffset = headingSearchStartOffset +
      text.substring(headingSearchStartOffset).indexOf(SEARCH_HEADINGS_CHARACTER) +
      SEARCH_HEADINGS_CHARACTER.length;

    this.setState({ headingSearchStartOffset: newHeadingSearchStartOffset });
  };

  // --------------------------------------------------------------------------
  _hideTagSuggestions = () => {
    this.setState({ showTagSuggestions: false });
  };

  // --------------------------------------------------------------------------
  _hideTaskSuggestions = () => {
    const { text } = this.props;
    const { taskSearchStartOffset } = this.state;

    const newTaskSearchStartOffset = taskSearchStartOffset +
      text.substring(taskSearchStartOffset).indexOf(SEARCH_TASKS_CHARACTER) +
      SEARCH_TASKS_CHARACTER.length;

    this.setState({ taskSearchStartOffset: newTaskSearchStartOffset });
  };

  // --------------------------------------------------------------------------
  _moveSelection = delta => {
    const { noteSuggestions } = this.state;

    let activeNoteSuggestionIndex = this.state.activeNoteSuggestionIndex + delta;
    if (activeNoteSuggestionIndex < 0) {
      activeNoteSuggestionIndex = Math.max(0, noteSuggestions.length - 1);
    } else if (activeNoteSuggestionIndex >= noteSuggestions.length) {
      activeNoteSuggestionIndex = 0;
    }

    this.setState({ activeNoteSuggestionIndex });
  };

  // --------------------------------------------------------------------------
  _parseLinkTargetText = () => {
    const { inTaskUUID, text } = this.props;
    const { headingSearchStartOffset, taskSearchStartOffset } = this.state;

    const headingNoteURL = headingNoteURLFromNoteSuggestion(this._activeNoteSuggestion(), text);

    return parseLinkTargetText(text.trim(), {
      headingSearchStartOffset: headingNoteURL ? headingSearchStartOffset : text.length,
      // This effectively disables task search character when it isn't available
      taskSearchStartOffset: inTaskUUID ? taskSearchStartOffset : text.length,
    });
  };

  // --------------------------------------------------------------------------
  async _performAsyncAction(action) {
    const { text } = this.props;

    const result = await action();

    // Async calls can take varying time, and if they are triggered by user input (typing) they can overlap and come
    // back out of order. We only want to use the result of this call if the user input still matches what it was
    // before awaiting it
    if (this.props.text !== text) return null;

    return result;
  }

  // --------------------------------------------------------------------------
  _scrollToSuggestion = suggestionIndex => {
    const { current: list } = this._listRef;
    const element = list ? list.listElements[suggestionIndex] : null;
    if (!element) return;

    // As of 6/2024, only nesting in the list-item-commands-menu has scrolling
    const scrollAncestor = element.closest(".list-item-commands-menu");
    if (!scrollAncestor) return;

    scrollIntoView(element, {
      block: "start",
      behavior: "smooth",
      boundary: scrollAncestor,
      scrollMode: "if-needed",
    });
  };

  // --------------------------------------------------------------------------
  async _updateNoteSuggestions(queryText, queryTagTexts, applyFilterTags) {
    const { insertContentMode, suggestNotes } = this.props;

    const result = await this._performAsyncAction(() => suggestNotes(queryText, queryTagTexts));
    if (!result) return;

    const { filterTags, notes, sourceTags } = result;

    const noteSuggestions = insertContentMode
      ? notes.filter(({ url }) => url).slice(0, MAX_SUGGESTIONS)
      : notes.slice(0, MAX_SUGGESTIONS);

    // Tags are divided into groups that can be displayed appropriately by the note link dialog here:
    //
    //    - `filterTags` that are present in the current editing context. These are only added to new notes if the
    //      link text does not start with "~" (see the `parseLinkTargetText` function) and are not added when linking to
    //      existing notes.
    //
    //    - Tags that the user typed out in such that they are included in `props.text`. These tags will be added to
    //      any note that is linked to, whether new and existing. These tags are also used to weight the ranking of the
    //      notes that are shown based on the current search text.
    //
    //    - Any other tags that the note already has, displayed to provide additional identification/disambiguation
    //
    noteSuggestions.forEach(noteSuggestion => {
      const { isSecureNote, isUnpersistedDailyNote, url } = noteSuggestion;

      const isExistingNote = isUnpersistedDailyNote || url;

      const matchingFilterTags = [];
      const newTags = [];
      const normalizedNoteName = (noteSuggestion.name || "").toLowerCase();

      const tags = (isExistingNote ? noteSuggestion.tags : null) || [];

      if (queryTagTexts && !insertContentMode) {
        queryTagTexts.forEach(queryTagText => {
          if (!haveTagWithText(tags, queryTagText) && !haveTagWithText(newTags, queryTagText)) {
            const tag = tagFromTagText(result.queryTagByText, queryTagText);

            if (normalizedNoteName.includes(queryTagText + TAG_TEXT_DELIMITER)) return;

            // Can't add shared tag to secure notes
            if (isSecureNote && tag.shares) return;

            newTags.push(tag);
          }
        });
      }

      // We want to do this _after_ examining tagsFromText in case tagsFromText includes a filter note
      if (applyFilterTags && filterTags) {
        if (isExistingNote) {
          filterTags.forEach(filterTag => {
            const { text: filterTagText } = filterTag;

            if (!haveTagWithText(tags, filterTagText)) return;
            if (haveTagWithText(newTags, filterTagText)) return;
            if (haveTagWithText(matchingFilterTags, filterTagText)) return;

            matchingFilterTags.push(filterTag);
          });
        } else if (!insertContentMode) {
          // Need to add filterTags to new notes
          filterTags.forEach(filterTag => {
            const { text: filterTagText } = filterTag;

            if (haveTagWithText(tags, filterTagText)) return;
            if (haveTagWithText(newTags, filterTagText)) return;

            // Can't add shared tag to secure notes
            if (isSecureNote && filterTag.shares) return;

            newTags.push({ ...filterTag, canBeOmitted: true });
          });
        }
      }

      noteSuggestion.matchingFilterTags = matchingFilterTags;
      noteSuggestion.newTags = newTags;
    });

    const activeNoteSuggestionIndex = Math.min(
      this.state.activeNoteSuggestionIndex,
      Math.max(0, noteSuggestions.length - 1)
    );

    this.setState({
      activeNoteSuggestionIndex,
      noteSuggestions,
      showTagSuggestions: false,
      sourceTags,
    });
  }

  // --------------------------------------------------------------------------
  _updateSuggestions() {
    const { showTagSuggestions, sourceTags } = this.state;

    if (showTagSuggestions) return;

    const { applyFilterTags, applySourceTags, tags, text } = this._parseLinkTargetText();

    let suggestionTags = tags;

    if (applySourceTags && sourceTags && sourceTags.length) {
      const tagTexts = sourceTags.map(({ text: tagText }) => tagText);
      suggestionTags = suggestionTags.concat(tagTexts);
    }

    this._updateNoteSuggestions(text, suggestionTags, applyFilterTags);
  }
}

import { cloneDeep } from "lodash"
import { closeHistory } from "prosemirror-history"
import { v4 as uuidv4 } from "uuid"

import { listItemNodeFromPos } from "lib/ample-editor/lib/list-item-util"
import AMPLENOTE_AREA, { urlFromAmplenoteParams } from "lib/ample-util/amplenote-area"
import { TASK_RELATION } from "lib/ample-util/task-url"

// --------------------------------------------------------------------------
function createMirroringTask(editorView, startPos, endPos, mirroringTaskURL, mirroringTaskUUID, noteURL) {
  const { props: { hostApp: { cloneNodes } } } = editorView;

  const checkListItem = listItemNodeFromPos(editorView.state.doc.resolve(startPos));

  const mirroringNode = cloneDeep(checkListItem.toJSON());

  const mirroredTaskUUID = mirroringNode.attrs.uuid;
  const mirroredTaskURL = urlFromAmplenoteParams({
    area: AMPLENOTE_AREA.TASKS,
    task: { uuid: mirroredTaskUUID },
    taskParams: { relation: TASK_RELATION.MIRRORING },
  });

  mirroringNode.attrs.uuid = mirroringTaskUUID;

  replaceMirroringTaskURL(mirroringNode, mirroringTaskURL, mirroredTaskURL);

  cloneNodes([ mirroringNode ], noteURL, { move: true });
}

// --------------------------------------------------------------------------
function insertLinkToMirroringTask(editorView, startPos, endPos, mirroringTaskURL) {
  const { state, state: { schema } } = editorView;

  const transaction = closeHistory(state.tr);

  const linkNode = schema.nodes.link.create({ href: mirroringTaskURL }, [ schema.text("mirrored task") ]);

  transaction.replaceWith(startPos, endPos, linkNode);

  editorView.dispatch(transaction);
  editorView.focus();

  return true;
}

// --------------------------------------------------------------------------
function replaceMirroringTaskURL(node, mirroringTaskURL, mirroredTaskURL) {
  const { content } = node;
  if (!content) return;

  for (let childIndex = 0; childIndex < node.content.length; childIndex++) {
    const childNode = node.content[childIndex];

    if (childNode.type === "link" && childNode.attrs && childNode.attrs.href === mirroringTaskURL) {
      childNode.attrs.href = mirroredTaskURL;
    } else {
      replaceMirroringTaskURL(childNode, mirroringTaskURL, mirroredTaskURL);
    }
  }
}

// --------------------------------------------------------------------------
export default function mirrorTaskInNote(editorView, startPos, endPos, noteURL) {
  const mirroringTaskUUID = uuidv4();
  const mirroringTaskURL = urlFromAmplenoteParams({
    area: AMPLENOTE_AREA.TASKS,
    task: { uuid: mirroringTaskUUID },
    taskParams: { relation: TASK_RELATION.MIRRORED },
  });

  insertLinkToMirroringTask(editorView, startPos, endPos, mirroringTaskURL);

  createMirroringTask(editorView, startPos, endPos, mirroringTaskURL, mirroringTaskUUID, noteURL);

  return true;
}

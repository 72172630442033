/* eslint-disable */

// --------------------------------------------------------------------------
const apiConfig = {
  host: "http://192.168.1.13:5000",

  accessToken: "f21483e5ab74624b26eaeea7bfd6974538702bf5e84c84ff1d98bdb56df20ba6",

  // `AccessToken.joins(:application).where(applications: { name: "Amplenote Mobile" }).last.refresh_token`
  refreshToken: "5d5c2c3e0df4b0e79ad85bf4108d53be1dd339eecbe545dc4a94fd68c782c8d5",

  // ample-mobile development - note that the `refreshToken` needs to be from an authorization for this client
  clientId: "52c048e1461bef8db64ea53dd12e821b5adc305838fe397214b14315d8b3ac07",

  // This needs to be a note that the owner of the accessToken/refreshToken can edit
  // `AccessToken.joins(:application).where(applications: { name: "Amplenote Mobile" }).last.account.notes.last.uuid`
  attachmentNoteUUID: "7e71b6dc-79e9-11ef-98ba-42182fb061a2",
};

// --------------------------------------------------------------------------
const callApiWithRefresh = async (path, options = {}) => {
  options.headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",

    ...(options.headers || {}),

    "Authorization": `Bearer ${ apiConfig.accessToken }`
  };

  const url = `${ apiConfig.host }/v2/${ path }`;
  const response = await fetch(url, options);
  if (response.status !== 401) return response;

  const refreshResponse = await fetch(`${ apiConfig.host }/oauth/token`, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-Client-Name": "ample-editor-app",
    },
    body: JSON.stringify({
      client_id: apiConfig.clientId,

      grant_type: "refresh_token",
      refresh_token: apiConfig.refreshToken,

      client_user_agent: "ample-editor-app"
    }),
    method: "POST"
  });

  if (!refreshResponse.ok) return response;

  const result = await refreshResponse.json();
  const accessToken = result && result["access_token"];
  if (!accessToken) return response;

  apiConfig.accessToken = accessToken;

  options.headers["Authorization"] = `Bearer ${ accessToken }`;
  return fetch(url, options);
};

// --------------------------------------------------------------------------
export class FileTooLargeError extends Error {
  constructor(message) {
    super(message);

    this.constructor = FileTooLargeError;
    this.__proto__ = FileTooLargeError.prototype;
    this.message = message;
  }
}

// --------------------------------------------------------------------------
export async function openAttachment(attachmentURL, options = {}) {
  console.log("opening attachment", attachmentURL, options);

  const { getURL = false } = options;

  const attachmentUUID = attachmentURL.replace(/^attachment:\/\//, "");

  const response = await callApiWithRefresh(`notes/${ apiConfig.attachmentNoteUUID }/attachments/${ attachmentUUID }`);
  if (response.ok) {
    const { url } = await response.json();

    if (!getURL && url) window.open(url, "_blank", "noopener noreferrer");

    return url;
  }

  return null;
}

// --------------------------------------------------------------------------
export const uploadAttachment = async file => {
  const response = await callApiWithRefresh(`notes/${ apiConfig.attachmentNoteUUID }/attachments`, {
    body: JSON.stringify({ size: file.size, type: file.type }),
    method: "POST"
  });
  if (response.status === 400) {
    const { error } = await response.json();
    if (error === "invalid_size") throw new FileTooLargeError("File too large");
  }
  if (response.status !== 201) throw Error(response.statusText);

  const { url: signedUploadURL, uuid: accountFileUUID } = await response.json();

  const uploadResponse = await fetch(signedUploadURL, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type
    }
  });
  if (!uploadResponse.ok) throw new Error(uploadResponse.statusText);

  return `attachment://${ accountFileUUID }`;
};

// --------------------------------------------------------------------------
export const uploadMedia = async (file, mimeType) => {
  const contentType = mimeType || file.type;

  // Uncomment to test uploads failing entirely:
  // throw("upload disabled");

  // Uncomment to make all images Griffey (after a simulated 3 second delay):
  // return await new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve("https://s3.us-east-2.amazonaws.com/images-dev.amplenote.com/d21159e6-62d9-11e9-a3a9-e0d55e4a68f7/95956e97-677f-49bc-9d49-36b533af70b9.jpg");
  //   }, 3000)
  // });

  const response = await callApiWithRefresh("accounts/media", {
    method: "POST",
    body: JSON.stringify({ size: file.size, type: contentType })
  });
  if (response.status === 400) {
    const { error } = await response.json();
    if (error === "invalid_size") throw new FileTooLargeError("File too large");
  }
  if (response.status !== 201) throw new Error(response.statusText);

  const { src: mediaURL, url: signedUploadURL } = await response.json();

  const options = {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": contentType
    }
  };

  const uploadResponse = await fetch(signedUploadURL, options);
  if (!uploadResponse.ok) throw new Error(uploadResponse.statusText);

  if (contentType.startsWith("image/")) {
    // Pre-download the image to avoid a flash of a blank image while it loads
    const img = new Image();

    return new Promise((accept, reject) => {
      img.onload = () => accept(mediaURL);
      img.onerror = reject;
      img.src = mediaURL;
    });
  } else {
    return mediaURL;
  }
};

import React from "react"
import ReactDOM from "react-dom"

import PersonIcon from "lib/ample-editor/components/person-icon"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"

// --------------------------------------------------------------------------
function TasksGroupHeading({ className, href, icon, name, onLinkClick }) {
  let renderedIcon = null;
  if (icon) {
    if (typeof(icon) === "string") {
      renderedIcon = (<i className="group-icon material-icons">{ icon }</i>);
    } else if ("person" in icon) {
      renderedIcon = (<PersonIcon person={ icon.person } />);
    }
  }

  const headingClassName = `tasks-group-heading ${ className || "" }`;

  if (href) {
    return (
      <a
        className={ headingClassName}
        href={ href }
        onClick={ onLinkClick }
        // This prevents the mousedown focusing the editor on mobile, which brings up the keyboard if it wasn't already up
        onMouseDown={ preventEventDefault }
        spellCheck="false"
      >
        { renderedIcon } { name }
      </a>
    );
  } else {
    return (
      <div className={ headingClassName } spellCheck="false">
        { renderedIcon } { name }
      </div>
    );
  }
}

// --------------------------------------------------------------------------
export default class TasksGroupView {
  _tasksGroupHeadingContainer = null;

  // --------------------------------------------------------------------------
  constructor(editorView, getPos, node) {
    this._onLinkClick = this._onLinkClick.bind(this, editorView, getPos);

    this.dom = document.createElement("div");
    this.dom.className = "tasks-group";
    this.dom.addEventListener("dragenter", this._onDragEnter, false);
    this.dom.addEventListener("dragleave", this._onDragLeave, false);
    this.dom.addEventListener("drop", this._onDrop, false);

    this._tasksGroupHeadingContainer = document.createElement("div");
    this._tasksGroupHeadingContainer.contentEditable = "false";
    this.dom.appendChild(this._tasksGroupHeadingContainer);

    this.contentDOM = document.createElement("div");
    this.dom.appendChild(this.contentDOM);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy() {
    ReactDOM.unmountComponentAtNode(this._tasksGroupHeadingContainer);
  }

  // --------------------------------------------------------------------------
  ignoreMutation(_event) {
    return true; // task-groups don't have mutable content, so we can ignore any changes
  }

  // --------------------------------------------------------------------------
  update(node) {
    const { attrs: { className, empty, href, icon, name } } = node;

    if (empty) this.dom.classList.add("empty");
    else this.dom.classList.remove("empty");

    ReactDOM.render(
      <TasksGroupHeading
        className={ className }
        href={ href }
        icon={ icon }
        name={ name }
        onLinkClick={ this._onLinkClick }
      />,
      this._tasksGroupHeadingContainer
    );

    return true;
  }

  // --------------------------------------------------------------------------
  _onDragEnter = () => {
    this.dom.classList.add("dropping");
  };

  // --------------------------------------------------------------------------
  _onDragLeave = () => {
    this.dom.classList.remove("dropping");
  };

  // --------------------------------------------------------------------------
  _onDrop = () => {
    this.dom.classList.remove("dropping");
  };

  // --------------------------------------------------------------------------
  _onLinkClick = (editorView, getPos, event) => {
    const { props: { onTaskGroupHeadingClick }, state: { doc, schema } } = editorView;

    const nodePos = getPos();
    const node = doc.nodeAt(nodePos);
    if (!node || node.type !== schema.nodes.tasks_group) return;

    if (onTaskGroupHeadingClick) {
      const { attrs: { id } } = node;
      onTaskGroupHeadingClick(id, event);
    } else {
      // This is necessary in the embed editor, where touching the anchor doesn't trigger navigation for
      // some undiagnosed reason (as of 9/2020)
      const { attrs: { href } } = node;
      if (href) window.location = href;
    }
  };
}

import { isEqual as areEqualDates, format, startOfDay, setSeconds } from "date-fns"
import { dequal } from "dequal/lite"
import { omit } from "lodash"
import memoize from "memoize-one"
import PropTypes from "prop-types"
import React from "react"
import { RRule } from "rrule"

import NextInstanceInput from "lib/ample-editor/components/task-detail/next-instance-input"
import TextInputWithSuggestions from "lib/ample-editor/components/task-detail/text-input-with-suggestions"
import TimeInput from "lib/ample-editor/components/task-detail/time-input"
import {
  adjustRRuleTime,
  nextDueFromTask,
  repeatRuleFromRRULE,
  rruleFromRepeatRule,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const ERRANT_RULE_KEYS = [ "byweekday", "dtstart", "freq" ];

// --------------------------------------------------------------------------
function repeatRuleFromText(text, anchorDate = null) {
  let options = null;
  try {
    options = RRule.parseText(text);
  } catch (_error) {
    // There are various reasons this can raise an exception, just indicating the input isn't recognized
  }
  if (!options) return null;

  if (!options.dtstart) {
    options.dtstart = anchorDate || startOfDay(Date.now());
  }

  // As of 3/2019, rrule.js has a bug where it will translate "every month on th" to freq: monthly, byweekday: TH - but
  // that's actually "every week on thursday". We'll translate to the _first_ thursday if that's the case, which turns
  // out to be "every month on the 1st thursday"
  if (dequal(Object.keys(options).sort(), ERRANT_RULE_KEYS) && options.freq === RRule.MONTHLY && options.byweekday.length === 1 && !options.byweekday[0].n) {
    options.byweekday[0] = options.byweekday[0].nth(1);
  }

  return new RRule(options);
}

// --------------------------------------------------------------------------
// Can use https://jakubroztocil.github.io/rrule/ to easily generate RRULEs
function repeatSuggestionsFromText(text, anchorDate = null) {
  const repeatRule = repeatRuleFromText(text, anchorDate);

  // Generic suggestions varying in a few dimensions based on today
  if (repeatRule === null) {
    const today = new Date();
    const month = today.getMonth() + 1; // getMonth is zero-based
    const monthDay = today.getDate();

    return [
      { text: "every day", rrule: "FREQ=DAILY" },
      {
        text: `every week on ${ format(today, "iiii") }`,
        rrule: `FREQ=WEEKLY;BYDAY=${ format(today, "iiiiii").toUpperCase() }`
      },
      {
        text: `every month on the ${ format(today, "do") }`,
        rrule: `FREQ=MONTHLY;BYMONTHDAY=${ monthDay }`
      },
      {
        text: `every ${ format(today, "LLLL") } on the ${ format(today, "do") }`,
        rrule: `FREQ=YEARLY;BYMONTH=${ month };BYMONTHDAY=${ monthDay }`
      },
    ];
  }

  // We have a number of dimensions to vary: FREQ, INTERVAL
  const options = omit(repeatRule.options, [ "byhour", "byminute", "bysecond", "dtstart", "count" ]);

  const suggestions = [
    {
      text: repeatRule.toText(),
      rrule: rruleFromRepeatRule(repeatRule),
    }
  ];

  [ RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY, RRule.YEARLY ].forEach(freq => {
    let freqOptions = options;

    // Clear out options that don't make much sense for each specified frequency
    // eslint-disable-next-line default-case
    switch (freq) {
      case RRule.DAILY:
        freqOptions = omit(freqOptions, [ "byday" ]);
        // eslint-disable-next-line no-fallthrough
      case RRule.WEEKLY:
        freqOptions = omit(freqOptions, [ "byweekday", "bymonthday", "bymonth", "byyearday", "byweekno" ]);
        // eslint-disable-next-line no-fallthrough
      case RRule.MONTHLY:
        freqOptions = omit(freqOptions, [ "byday", "byweekday", "bymonth", "byyearday", "byweekno" ]);
    }

    if (freq === options.freq) {
      // If they entered "every week", then suggest "every 2 weeks" (and vice-versa)
      const otherIntervalRule = new RRule({ ...freqOptions, interval: freqOptions.interval === 1 ? 2 : 1 });
      suggestions.push({ text: otherIntervalRule.toText(), rrule: rruleFromRepeatRule(otherIntervalRule) });

      // If they entered "every week", suggest a specific weekday
      if (freq === RRule.WEEKLY && (!options.byweekday || !options.byweekday.length)) {
        const byWeekdayRule = new RRule({ ...freqOptions, byweekday: [ new Date().getDay() ] });
        suggestions.push({ text: byWeekdayRule.toText(), rrule: rruleFromRepeatRule(byWeekdayRule) });
      }
    } else {
      const otherFreqRule = new RRule({ ...freqOptions, freq });
      suggestions.push({ text: otherFreqRule.toText(), rrule: rruleFromRepeatRule(otherFreqRule) });
    }
  });

  return suggestions;
}

// --------------------------------------------------------------------------
export default class RepeatRuleInput extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    nextInstanceAt: PropTypes.number,
    onFocus: PropTypes.func,
    rrule: PropTypes.string,
    setNextInstance: PropTypes.func.isRequired,
    setRrule: PropTypes.func.isRequired,
  };

  state = {
    focused: false,
    repeatRuleText: null,
  };

  _repeatingInputContainerRef = React.createRef();
  _resetFocusTimeout = null;

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    clearTimeout(this._resetFocusTimeout);
  }

  // --------------------------------------------------------------------------
  render() {
    const { disabled, nextInstanceAt, rrule } = this.props;
    const { focused } = this.state;

    const nextInstance = nextInstanceAt ? new Date(nextInstanceAt * 1000) : null;
    const repeatRule = repeatRuleFromRRULE(rrule, nextInstance);

    let { repeatRuleText } = this.state;

    // If the user hasn't changed the text, pull it from the RRULE
    if (repeatRuleText === null && repeatRule !== null) {
      repeatRuleText = repeatRule.toText();
    }

    const repeatSuggestions = this._memoizedRepeatSuggestions(this._anchorDate(), repeatRuleText || "", rrule);

    const [ nextInstanceIsAdjusted, renderedNextInstance ] = this._renderNextInstance(rrule, nextInstance)

    let className = `repeat-rule-input ${ repeatRule ? "valid" : "invalid" }`;
    if (focused) className += " focused";
    if (nextInstanceIsAdjusted) className += " next-instance-adjusted";

    return (
      <div className={ className }>
        <div
          className="repeating-input-container"
          onBlur={ this._onBlur }
          onFocus={ this._onFocus }
          ref={ this._repeatingInputContainerRef }
        >
          <div className="text-input-container">
            <TextInputWithSuggestions
              additionalClassName={ `rule-input ${ repeatRule || nextInstance ? "with-next-instance" : "" }` }
              disabled={ disabled }
              invalid={ repeatRule === null }
              onBlur={ this._onRepeatRuleInputBlur }
              placeholder="e.g. Every day"
              selectSuggestion={ this._selectRepeatSuggestion }
              setValue={ this._setRepeatRuleText }
              suggestions={ repeatSuggestions }
              value={ repeatRuleText || "" }
            />
            <i className="material-icons repeat-icon">cached</i>
          </div>

          { this._renderTimeInput(repeatRule) }
        </div>
        { renderedNextInstance }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  // This is intended to return an existing anchor date, which should be used for new rrules - it can return `null`
  // to indicate that no specific anchor is set.
  _anchorDate() {
    const { rrule, nextInstanceAt } = this.props;

    const repeatRule = repeatRuleFromRRULE(rrule);
    if (repeatRule) {
      return repeatRule.getDTSTART();
    } else if (nextInstanceAt) {
      return new Date(nextInstanceAt * 1000);
    }

    return null;
  }

  // --------------------------------------------------------------------------
  _memoizedRepeatSuggestions = memoize((anchorDate, repeatRuleText, selectedRRule) => {
    // The actual selected RRule probably has a DTSTART, which the suggestions won't have
    const selectedRepeatRule = repeatRuleFromRRULE(selectedRRule, null, () => null);

    return repeatSuggestionsFromText(repeatRuleText, anchorDate).map(({ rrule, text }) => {
      const repeatRuleWithoutDTSTART = repeatRuleFromRRULE(rrule, null, () => null);
      const selected = selectedRepeatRule && repeatRuleWithoutDTSTART &&
        dequal(repeatRuleWithoutDTSTART.options, selectedRepeatRule.options);
      return { selected, text, value: rrule };
    });
  });

  // --------------------------------------------------------------------------
  _onBlur = () => {
    // When shifting focus from the date input to the time input, there will be a single render where nothing is
    // focused, which will switch the time input back to hidden, preventing it from focusing. To let it focus, we'll
    // defer the detection of focus being lost by one tick, so we can query whether focus has just moved within the
    // container, rather than moving out of it.
    clearTimeout(this._resetFocusTimeout);
    this._resetFocusTimeout = setTimeout(this._resetFocus, 1);
  };

  // --------------------------------------------------------------------------
  _onRepeatRuleInputBlur = () => {
    this.setState({ repeatRuleText: null });
  };

  // --------------------------------------------------------------------------
  _onFocus = event => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(event);

    this.setState({ focused: true });
  };

  // --------------------------------------------------------------------------
  _renderNextInstance = (rrule, nextInstance) => {
    if (!rrule && !nextInstance) return [ false, null ];

    const { disabled } = this.props;
    const expectedNextInstanceAt = nextDueFromTask({ repeat: rrule });
    const expectedNextInstance = expectedNextInstanceAt ? setSeconds(expectedNextInstanceAt * 1000, 0) : null;

    // We don't expose seconds to the user, but it's possible for the value to get set, in which case its confusing
    // if we say the time is changes when it's only the invisible seconds that are different
    if (nextInstance) nextInstance = setSeconds(nextInstance, 0);

    return [
      (nextInstance && expectedNextInstance) ? !areEqualDates(expectedNextInstance, nextInstance) : false,
      (
        <NextInstanceInput
          disabled={ disabled }
          expectedNextInstance={ expectedNextInstance }
          nextInstance={ nextInstance }
          setNextInstance={ this._setNextInstance }
        />
      ),
    ];
  };

  // --------------------------------------------------------------------------
  _renderTimeInput = repeatRule => {
    const { disabled, onFocus } = this.props;

    const duration = (repeatRule && repeatRule.durationFromDTSTARTTime) ? repeatRule.durationFromDTSTARTTime() : "";

    return (
      <TimeInput
        disabled={ disabled }
        duration={ duration }
        onFocus={ onFocus }
        setDuration={ this._setTime }
      />
    );
  };

  // --------------------------------------------------------------------------
  _resetFocus = () => {
    const { current: repeatingInputContainer } = this._repeatingInputContainerRef;
    const focused = !!(repeatingInputContainer && repeatingInputContainer.querySelector(":focus"));
    this.setState({ focused });
  };

  // --------------------------------------------------------------------------
  _selectRepeatSuggestion = ({ value: rrule }) => {
    const repeatRule = repeatRuleFromRRULE(rrule);
    if (repeatRule !== null) this._setRepeatRuleText(repeatRule.toText());
  };

  // --------------------------------------------------------------------------
  _setNextInstance = date => {
    this.props.setNextInstance(date);
  };

  // --------------------------------------------------------------------------
  _setRepeatRuleText = newRepeatRuleText => {
    if (newRepeatRuleText === this.state.repeatRuleText) return;

    const repeatRule = repeatRuleFromText(newRepeatRuleText, this._anchorDate());
    const rrule = rruleFromRepeatRule(repeatRule);

    if (rrule !== this.props.rrule) {
      this.props.setRrule(rrule);
    }

    this.setState({ repeatRuleText: newRepeatRuleText });
  };

  // --------------------------------------------------------------------------
  _setTime = duration => {
    const { nextInstanceAt, rrule } = this.props;
    const newRRule = adjustRRuleTime(rrule, nextInstanceAt, duration);
    if (newRRule !== rrule) this.props.setRrule(newRRule);
  };
}

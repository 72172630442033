import PropTypes from "prop-types"
import React, { useCallback, useContext } from "react"

import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import { pluginParamsFromURL, targetFromURL } from "lib/ample-editor/lib/link-util"
import AMPLENOTE_AREA, { amplenoteParamsFromHref } from "lib/ample-util/amplenote-area"
import { isNewNoteURL } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
// Non-other types have special handling (previews, etc)
export const HREF_TYPE = {
  NOTE: "note",
  OTHER: "other",
  PLUGIN: "plugin",
  TASK: "task",
};

// --------------------------------------------------------------------------
export function hrefTypeFromHref(href) {
  if (!href) return HREF_TYPE.OTHER;

  const amplenoteParams = amplenoteParamsFromHref(href);
  if (amplenoteParams) {
    if (amplenoteParams.area === AMPLENOTE_AREA.NOTES && amplenoteParams.note) {
      return HREF_TYPE.NOTE;
    } else if (amplenoteParams.area === AMPLENOTE_AREA.TASKS && amplenoteParams.task) {
      return HREF_TYPE.TASK;
    }
  }

  if (pluginParamsFromURL(href)) {
    return HREF_TYPE.PLUGIN;
  }

  return HREF_TYPE.OTHER;
}

// --------------------------------------------------------------------------
export default function HrefIcon({ close, href, linkHref, nonBlankLinkTarget, runLinkTargetPluginActions }) {
  const haveHref = href && href.length > 0;

  const hostAppContext = useContext(HostAppContext);
  const hrefType = hrefTypeFromHref(href);

  const targetDestination = targetFromURL(href, nonBlankLinkTarget);

  let iconName;
  if (hrefType === HREF_TYPE.NOTE && hostAppContext) {
    iconName = "description";
  } else if (hrefType === HREF_TYPE.PLUGIN && hostAppContext) {
    iconName = "extension";
  } else if (hrefType === HREF_TYPE.TASK && hostAppContext) {
    iconName = "check_box";
  } else if (isNewNoteURL(linkHref)) {
    iconName = "file_copy";
  } else {
    iconName = "open_in_new";
  }

  const onClick = useCallback(
    event => {
      const pluginParams = pluginParamsFromURL(href);
      if (pluginParams) {
        event.preventDefault();
        event.stopPropagation();
        runLinkTargetPluginActions(pluginParams);
        if (close) close();
      } else if (haveHref) {
        // Since this is a leading icon in the text field, we need to stop the event before it hits the text field, since
        // we're about to close and unmount the popup (the text field will try to handle the input otherwise, resulting in
        // an exception since it will be in the process of unmounting at that point).
        event.stopPropagation();
        if (close) close();
      } else {
        event.preventDefault();
      }
    },
    [ close, href ]
  );

  return (
    <a
      className={ `href-icon material-icons${ haveHref ? " valid" : "" }` }
      href={ linkHref }
      target={ targetDestination }
      rel="noopener noreferrer"
      tabIndex="-1"
      onClick={ onClick }
    >
      { iconName }
    </a>
  );
}

HrefIcon.propTypes = {
  close: PropTypes.func,
  href: PropTypes.string,
  linkHref: PropTypes.string,
  nonBlankLinkTarget: PropTypes.string,
  runLinkTargetPluginActions: PropTypes.func,
};

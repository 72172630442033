import { hrefFromAttribute, targetFromURL } from "lib/ample-editor/lib/link-util"

// --------------------------------------------------------------------------
// Links in description view are treated like plain text in editable mode, and are just normal <a> tags in readonly mode
export default class DescriptionLinkView {
  // --------------------------------------------------------------------------
  constructor(view, node) {
    this.stopEvent = this.stopEvent.bind(this, view);
    this.update = this.update.bind(this, view);

    const { attrs: { href } } = node;

    // Note that the outer element needs to be the link - if the outer element is the span, ProseMirror has some
    // confusion about what to do when text is inserted at the opening position of the node (moving the cursor backwards
    // after inserting it, such that typing in text comes out reversed).
    this.dom = document.createElement("a");
    this.dom.className = "link traditional-link";
    this.dom.rel = "noopener noreferrer";
    this.dom.target = targetFromURL(href);

    this.contentDOM = document.createElement("span");
    this.dom.appendChild(this.contentDOM);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  stopEvent(view, event) {
    // Prevent mousedown focusing outer editor
    if (!view.editable && event.type === "mousedown") {
      event.preventDefault();
      return true;
    }
  }

  // --------------------------------------------------------------------------
  update(view, node) {
    if (node.type.name !== "link") return false;

    const { attrs: { href } } = node;

    this.dom.href = hrefFromAttribute(href) || "";
    this.dom.target = targetFromURL(href);

    // Keep a separate un-adulterated version of the href on the node
    this.dom.setAttribute("data-href", href);

    return false;
  }
}

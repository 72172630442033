import { sortBy } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useState } from "react"
import { useAsyncEffect } from "@react-hook/async"
import { Button } from "@rmwc/button"
import { CircularProgress } from "@rmwc/circular-progress"
import { List, SimpleListItem } from "@rmwc/list"
import { Tab, TabBar } from "@rmwc/tabs"
import { TextField, TextFieldHelperText } from "@rmwc/textfield"

import FilterChips from "lib/ample-editor/components/referencing-notes/filter-chips"
import TagIcon from "lib/ample-editor/components/tag-icon"
import useShiftKeyIsDown from "lib/ample-editor/hooks/use-shift-key-is-down"
import extractNodeContent from "lib/ample-util/extract-node-content"
import {
  filterReferencesFromNoteReferences,
  noteReferencesFromFilterReferences,
} from "lib/ample-util/filter-references"
import { filterTagFromTagFilters, tagFiltersFromFilterTag } from "lib/ample-util/filter-tag"
import { noteParamsFromURL, urlFromNoteParams } from "lib/ample-util/note-url"
import { noteParamsFromNoteUUID, noteUUIDFromNoteParams } from "lib/ample-util/note-uuid"

// --------------------------------------------------------------------------
const buildSuggestedNoteReferences = async (
  fetchReferencedNotes,
  inputValue,
  referencedNote,
  referencesByUrl,
  referencingNotes,
  suggestNotes,
) => {
  if (!referencesByUrl) return [];

  const noteByUUID = {};
  const referenceCountByNoteUUID = {};

  let suggestedNotes;
  if (inputValue) {
    const { notes } = await suggestNotes(inputValue);
    suggestedNotes = notes.map(note => ({ ...note, url: note.url || urlFromNoteParams(note) }));
  } else {
    suggestedNotes = referencingNotes;
  }

  const fetchReferencedNotePromises = [];
  suggestedNotes.forEach(note => {
    const { url } = note;

    const noteUUID = noteUUIDFromNoteParams(noteParamsFromURL(url));
    noteByUUID[noteUUID] = note;

    const references = referencesByUrl[url];
    if (references && references.length > 0) {
      if (noteUUID in referenceCountByNoteUUID) {
        referenceCountByNoteUUID[noteUUID] += references.length;
      } else {
        referenceCountByNoteUUID[noteUUID] = references.length;
      }

      references.forEach(({ node }) => {
        // When we show "N references in X notes" descriptions, a "reference" means a single snippet, even if it
        // has multiple references to the note in it. To match that count, we'll only count the first reference to
        // a note in this snippet.
        const isReferencedByNoteUUID = {};

        const { noteLinks } = extractNodeContent(node);
        noteLinks.forEach(noteParams => {
          const referencedNoteUUID = noteUUIDFromNoteParams(noteParams);

          if (referencedNoteUUID in isReferencedByNoteUUID) return; // Already counted
          isReferencedByNoteUUID[referencedNoteUUID] = true;

          if (referencedNoteUUID in referenceCountByNoteUUID) {
            referenceCountByNoteUUID[referencedNoteUUID]++;
          } else {
            referenceCountByNoteUUID[referencedNoteUUID] = 1;
          }
        });
      });
    }

    // If there are references to notes that aren't in suggestedNotes, we need to get metadata for those notes too
    const { localUUID, remoteUUID } = noteParamsFromNoteUUID(noteUUID);
    fetchReferencedNotePromises.push(
      // Ensuring the promise always fulfills, so we can use Promise.all instead of Promise.allSettled, which
      // isn't supported on some older browser versions (that our users still use).
      Promise.resolve(fetchReferencedNotes(localUUID, remoteUUID)).then(
        referencedNoteByUUID => {
          Object.keys(referencedNoteByUUID).forEach(referencedNoteUUID => {
            if (referencedNoteUUID in noteByUUID) return; // Existing metadata might have `html` key from match
            noteByUUID[referencedNoteUUID] = referencedNoteByUUID[referencedNoteUUID];
          });
          return { status: "fulfilled", value: referencedNoteByUUID };
        },
        reason => ({ status: "rejected", reason })
      )
    );
  });

  await Promise.all(fetchReferencedNotePromises);

  const results = [];

  const noteUUIDs = inputValue
    ? suggestedNotes.map(({ url }) => noteUUIDFromNoteParams(noteParamsFromURL(url)))
    : Object.keys(referenceCountByNoteUUID);

  const referencedNoteUUID = referencedNote ? noteUUIDFromNoteParams(referencedNote) : null;

  noteUUIDs.forEach(noteUUID => {
    const note = noteByUUID[noteUUID];
    if (!note) return;

    // Don't include the current note as a suggestion, since they're already seeing only backlinks to the current
    // note, so it's redundant.
    if (noteUUID === referencedNoteUUID) return;

    const referenceCount = referenceCountByNoteUUID[noteUUID] || 0;

    results.push({
      note,
      noteUUID,
      referenceCount,
    });
  });

  if (inputValue) {
    return results;
  } else {
    return sortBy(results, ({ referenceCount }) => -referenceCount);
  }
};

// --------------------------------------------------------------------------
const tagByTagTextFromReferencingNotes = referencingNotes => {
  const tagByTagText = {};

  referencingNotes.forEach(note => {
    const { tags } = note;
    if (!tags) return;

    const noteUUID = noteUUIDFromNoteParams(note);

    tags.forEach(tag => {
      const { text } = tag;

      if (text in tagByTagText) {
        tagByTagText[text].noteUUIDs.push(noteUUID);
      } else {
        tagByTagText[text] = { ...tag, noteUUIDs: [ noteUUID ] };
      }
    });
  });

  return tagByTagText;
};

// --------------------------------------------------------------------------
function ReferencesListItem({ note, noteUUID, referenceCount, selectReference }) {
  const { html, name } = note;

  const onClick = useCallback(
    event => {
      selectReference(noteUUID, event.shiftKey);
    },
    [ noteUUID, selectReference ]
  );

  return (
    <SimpleListItem
      className={ referenceCount ? "" : "no-backlinks" }
      graphic="description"
      meta={ referenceCount.toString() }
      onClick={ onClick }
      text={
        <span className="text">
          { html ? (<span dangerouslySetInnerHTML={ { __html: html } } />) : name }
        </span>
      }
    />
  );
}

// --------------------------------------------------------------------------
function ReferencesTab(props) {
  const {
    fetchReferencedNotes,
    filterReferences,
    referencedNote,
    referencesByUrl,
    referencingNotes,
    setFilterReferences,
    suggestNotes,
  } = props;

  const [ inputValue, setInputValue ] = useState("");

  const onChange = useCallback(
    ({ target: { value } }) => {
      setInputValue(value);
    },
    [ setInputValue ]
  );

  const noteReferences = useMemo(() => noteReferencesFromFilterReferences(filterReferences), [ filterReferences ]);

  const selectReference = useCallback(
    (noteUUID, isNegated) => {
      const existingNoteReference = noteReferences.find(({ noteUUID: existingNoteUUID }) => {
        return existingNoteUUID === noteUUID;
      });
      if (existingNoteReference) {
        existingNoteReference.isNegated = isNegated;
      } else {
        noteReferences.push({ ...noteParamsFromNoteUUID(noteUUID), isNegated, noteUUID });
      }

      const newFilterReferences = filterReferencesFromNoteReferences(noteReferences);
      setFilterReferences(newFilterReferences);
    },
    [ noteReferences, setFilterReferences ]
  );

  const { value: suggestedNoteReferences } = useAsyncEffect(
    // Note that the functions are assumed to be stable, so are not included in dependencies
    () => buildSuggestedNoteReferences(
      fetchReferencedNotes,
      inputValue,
      referencedNote,
      referencesByUrl,
      referencingNotes,
      suggestNotes,
    ),
    [ inputValue, referencesByUrl, referencingNotes ]
  );

  const filteredSuggestedNoteReferences = (suggestedNoteReferences || []).filter(({ noteUUID }) => {
    return !noteReferences.find(noteReference => noteReference.noteUUID === noteUUID);
  });

  let noSuggestionsMessage = null;
  if (filteredSuggestedNoteReferences.length === 0 && !inputValue) {
    if (!suggestedNoteReferences) {
      noSuggestionsMessage = (<CircularProgress size="small"/>);
    } else if (noteReferences.length > 0) {
      noSuggestionsMessage = "No unselected notes are referenced by the backlinks currently shown.";
    } else {
      noSuggestionsMessage = "This note has no backlinks with note references.";
    }
  }

  return (
    <React.Fragment>
      <TextField
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        className="search-input"
        icon="search"
        onChange={ onChange }
        outlined
        placeholder="Search notes..."
        spellCheck="false"
        value={ inputValue }
      />
      <TextFieldHelperText persistent>Click to add, shift-click to exclude.</TextFieldHelperText>

      <List>
        {
          filteredSuggestedNoteReferences.map(({ note, noteUUID, referenceCount }) => (
            <ReferencesListItem
              key={ noteUUID }
              note={ note }
              noteUUID={ noteUUID }
              referenceCount={ referenceCount }
              selectReference={ selectReference }
            />
          ))
        }
      </List>

      {
        noSuggestionsMessage
          ? (<div className="no-suggestions-message">{ noSuggestionsMessage }</div>)
          : null
      }
    </React.Fragment>
  );
}

// --------------------------------------------------------------------------
function TagsListItem({ selectTag, tag }) {
  const { html, noteUUIDs, text } = tag;

  const noteCount = noteUUIDs ? Object.keys(noteUUIDs).length : 0;

  const onClick = useCallback(
    event => {
      selectTag(text, event.shiftKey);
    },
    [ selectTag, text ]
  );

  return (
    <SimpleListItem
      className={ noteCount ? "" : "no-backlinks" }
      graphic={ <TagIcon { ...tag } /> }
      meta={ noteCount.toString() }
      onClick={ onClick }
      text={ html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : text }
    />
  );
}

// --------------------------------------------------------------------------
function TagsTab({ filterTag, referencingNotes, setFilterTag, suggestTags }) {
  const [ inputValue, setInputValue ] = useState("");

  const tagFilters = useMemo(() => tagFiltersFromFilterTag(filterTag), [ filterTag ]);

  const selectTag = useCallback(
    (tagText, isNegated) => {
      const newTagFilters = tagFilters.filter(tagFilter => tagFilter.tagText !== tagText)
        .concat({ isNegated, tagText });

      setFilterTag(filterTagFromTagFilters(newTagFilters));
    },
    [ setFilterTag, tagFilters ]
  );

  const { value: suggestedTags } = useAsyncEffect(
    async () => {
      // These are all tags that are applied to the notes that reference this note
      const tagByTagText = tagByTagTextFromReferencingNotes(referencingNotes);

      if (inputValue) {
        const tags = await suggestTags(inputValue);
        return tags.map(tag => {
          const { noteUUIDs } = tagByTagText[tag.text] || {};
          return noteUUIDs ? { ...tag, noteUUIDs } : tag;
        });
      } else {
        return Object.values(tagByTagText);
      }
    },
    [ inputValue, referencingNotes ]
  );

  const onChange = useCallback(
    ({ target: { value } }) => {
      setInputValue(value);
    },
    [ setInputValue ]
  );

  const filteredSuggestedTags = (suggestedTags || []).filter(tag => {
    return !tagFilters.find(tagFilter => tagFilter.tagText === tag.text);
  });

  let noSuggestionsMessage = null;
  if (filteredSuggestedTags.length === 0 && !inputValue) {
    if (tagFilters.length > 0) {
      noSuggestionsMessage = "No unselected tags available for the backlinks currently shown.";
    } else {
      noSuggestionsMessage = "This note has no backlinks in tagged notes.";
    }
  }

  return (
    <React.Fragment>
      <TextField
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        className="search-input"
        icon="search"
        onChange={ onChange }
        outlined
        placeholder="Search tags..."
        spellCheck="false"
        value={ inputValue }
      />
      <TextFieldHelperText persistent>Click to add, shift-click to exclude.</TextFieldHelperText>

      <List>
        {
          filteredSuggestedTags.map(tag => (
            <TagsListItem key={ tag.text } selectTag={ selectTag } tag={ tag }/>
          ))
        }
      </List>

      {
        noSuggestionsMessage
          ? (<div className="no-suggestions-message">{ noSuggestionsMessage }</div>)
          : null
      }
    </React.Fragment>
  );
}

// --------------------------------------------------------------------------
export default function FilterMenuContent(props) {
  const {
    fetchReferencedNotes,
    filterParams,
    getNote,
    getTag,
    referencedNote,
    referencesByUrl,
    referencingNotes,
    setFilterParams,
    suggestNotes,
    suggestTags,
  } = props;

  const [ activeTabIndex, setActiveTabIndex ] = useState(0);
  const shiftKeyIsDown = useShiftKeyIsDown();

  // We need to use Tab#onInteraction instead of TabBar#onActivate so we can switch the tab in jest, as
  // there isn't a way to do that using onActivate (see https://github.com/rmwc/rmwc/issues/710).
  const onTabInteraction = useCallback(({ detail: { tabId: index } }) => { setActiveTabIndex(index); }, []);

  const { references: filterReferences, tag: filterTag } = filterParams;

  const clearFilters = useCallback(
    () => { setFilterParams({}); },
    [ setFilterParams ]
  );

  const setFilterReferences = useCallback(
    newFilterReferences => {
      setFilterParams(currentFilterParams => ({ ...currentFilterParams, references: newFilterReferences }));
    },
    [ setFilterParams ]
  );

  const setFilterTag = useCallback(
    newFilterTag => {
      setFilterParams(currentFilterParams => ({ ...currentFilterParams, tag: newFilterTag }));
    },
    [ setFilterParams ]
  );

  return (
    <div className="filter-menu-content">
      <div className="menu-heading">
        <div className="menu-heading-text">Filter backlinks</div>
        {
          (filterReferences || filterTag)
            ? (<Button className="clear-filters-button body-text" onClick={ clearFilters }>Clear all</Button>)
            : null
        }
      </div>
      <div className="menu-description">
        Narrow backlinks list by filtering on <a href="https://www.amplenote.com/help/inline_tags_note_reference_filtering" rel="noopener" target="_blank">Note References</a> or
        source note tags.
      </div>

      <FilterChips
        filterParams={ filterParams }
        getNote={ getNote }
        getTag={ getTag }
        setFilterParams={ setFilterParams }
      />

      <TabBar>
        <Tab id={ 0 } icon="description" label="References" onInteraction={ onTabInteraction } />
        <Tab id={ 1 } icon={ <TagIcon /> } label="Tags" onInteraction={ onTabInteraction } />
      </TabBar>
      <div className={ `tab-content ${ shiftKeyIsDown ? "shift-key-down" : "" }` }>
        {
          activeTabIndex === 1
            ? (
              <TagsTab
                filterTag={ filterTag }
                referencingNotes={ referencingNotes }
                setFilterTag={ setFilterTag }
                suggestTags={ suggestTags }
              />
            )
            : (
              <ReferencesTab
                fetchReferencedNotes={ fetchReferencedNotes }
                filterReferences={ filterReferences }
                referencedNote={ referencedNote }
                referencesByUrl={ referencesByUrl }
                referencingNotes={ referencingNotes }
                setFilterReferences={ setFilterReferences }
                suggestNotes={ suggestNotes }
              />
            )
        }
      </div>
    </div>
  );
}

FilterMenuContent.propTypes = {
  fetchReferencedNotes: PropTypes.func.isRequired,
  filterParams: PropTypes.shape({
    references: PropTypes.string,
    tag: PropTypes.string,
  }).isRequired,
  getNote: PropTypes.func,
  getTag: PropTypes.func,
  referencedNote: PropTypes.shape({
    localUUID: PropTypes.string,
    remoteUUID: PropTypes.string,
  }),
  referencesByUrl: PropTypes.object,
  referencingNotes: PropTypes.array.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  suggestNotes: PropTypes.func.isRequired,
  suggestTags: PropTypes.func.isRequired,
};

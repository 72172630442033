import PropTypes from "prop-types"
import React from "react"
import { TextField } from "@rmwc/textfield"

import LinkTargetMenu from "lib/ample-editor/components/link-target-menu"
import { isFirefox } from "lib/ample-editor/lib/client-info"
import { replaceExpression } from "lib/ample-util/evaluate-expression"

// --------------------------------------------------------------------------
export default class SelectNoteMenu extends React.PureComponent {
  static propTypes = {
    actionDescription: PropTypes.string.isRequired,
    actionDescriptionShort: PropTypes.string.isRequired,
    apply: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    fetchNoteContent: PropTypes.func,
    isCopy: PropTypes.bool,
    suggestNotes: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------
  state = {
    value: "",
  };

  _closeTimeout = null;
  _inputRef = React.createRef();
  _noteLinkMenuRef = React.createRef();

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    clearTimeout(this._closeTimeout);
  }

  // --------------------------------------------------------------------------
  focus() {
    const { current: input } = this._inputRef;
    if (input) input.focus();
  }

  // --------------------------------------------------------------------------
  handleKeyDown = event => {
    switch (event.key) {
      case "ArrowDown":
      case "ArrowUp":
      case "Enter": {
        const { current: noteLinkMenu } = this._noteLinkMenuRef;
        if (noteLinkMenu) {
          if (noteLinkMenu.handleKeyDown(event)) {
            // Necessary to prevent task after one(s) being moved from being cleared (on enter)
            event.preventDefault();

            // Need to stop propagation or MDC will try to active the ripple (on enter)
            event.stopPropagation();

            return true;
          }

          return false;
        }
      }
      break;

      case "Escape":
        this._close();
        return true;

      default:
        return false;
    }

    return false;
  };

  // --------------------------------------------------------------------------
  render() {
    const { actionDescription, fetchNoteContent } = this.props;
    const { value } = this.state;

    return (
      <div className="select-note-menu">
        <TextField
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          autoFocus
          className="search-input"
          fullwidth
          icon="search"
          inputRef={ this._inputRef }
          onKeyDown={ this.handleKeyDown }
          onChange={ this._onChange }
          spellCheck="false"
          value={ value }
        />

        <LinkTargetMenu
          acceptSuggestion={ this._selectSuggestion }
          actionDescription={ actionDescription }
          fetchNoteContent={ fetchNoteContent }
          placeholder={ `Type to search for a note to ${ actionDescription } to.` }
          ref={ this._noteLinkMenuRef }
          suggestNotes={ this._suggestNotes }
          text={ value }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _close() {
    // MDC wants to add a ripple on click if this was instigated by a mouse event, which will throw an exception if
    // we unmount immediately
    this._closeTimeout = setTimeout(this.props.cancel, 1);
  }

  // --------------------------------------------------------------------------
  _onChange = event => {
    let { target: { value } } = event;

    const { value: valueWas } = this.state;
    if (value.length === valueWas.length + 1) {
      const newValue = replaceExpression(value);
      if (newValue !== null) {
        if (isFirefox) {
          // The `document.execCommand` path doesn't work in Firefox (may be https://bugzilla.mozilla.org/show_bug.cgi?id=1220696)
          value = newValue;
        } else {
          // This allows the replacement to be undoable via native browser undo (it will re-call this function too),
          // though undoing will leave the entire input value selected.
          document.execCommand("selectAll", false, null);
          document.execCommand("insertText", false, newValue);

          return;
        }
      }
    }

    this.setState({ value });
  };

  // --------------------------------------------------------------------------
  _selectSuggestion = (name, url, suggestionTags) => {
    this.props.apply({ name, suggestionTags, url })
  };

  // --------------------------------------------------------------------------
  _suggestNotes = async (queryText, queryTagTexts) => {
    const { actionDescriptionShort, suggestNotes } = this.props;

    const result = await suggestNotes(queryText);

    // The source note is the note we're currently in, not a good option to move/copy to
    result.notes = result.notes.filter(({ isSourceNote }) => !isSourceNote);

    if (result.notes.length === 0 && queryText.length > 0) {
      result.notes.unshift({
        name: queryText,
        tags: queryTagTexts.map(tagText => ({ text: tagText })),
        text: (<span>{ actionDescriptionShort } to a new note named <span className="value">{ queryText }</span></span>),
      });
    }

    return result;
  };
}

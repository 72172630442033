import { dequal } from "dequal/lite"
import { Slice } from "prosemirror-model"
import { TextSelection } from "prosemirror-state"
import {
  addRow,
  CellSelection,
  deleteColumn,
  deleteRow,
  goToNextCell,
  isInTable,
  moveCellForward,
  nextCell,
  removeColumn,
  selectedRect,
  setAttr,
  tableNodeTypes
} from "prosemirror-tables"

import { hasBlock } from "lib/ample-editor/lib/commands"
import { selectedListItemDepth } from "lib/ample-editor/lib/list-item-commands"
import { clearCellHover } from "lib/ample-editor/plugins/table-plugin"
import {
  AXIS,
  CELL_SELECT_INACTIVE,
  CELL_MIN_WIDTH,
  CLEAR_BORDER_COMMAND_NAME,
  COLUMN_WIDTH_ESTIMATE_EXTRA_PX,
  COLUMN_WIDTH_MAX_ESTIMATE_PX,
  TABLE_DEPTH,
  SORT_APPLIED
} from "lib/ample-editor/lib/table/table-constants"
import {
  cellFromTable,
  cellFromPos,
  columnWidthFromColumnIndex,
  estimateColumnContentWidth,
  forEachColumnIndexCell,
  isRowEmpty,
  rowFromPos,
  rowIndexFromCell,
  setTableRedrawMeta,
  sortedRowIndexesFromColumn,
  tableFromPos,
  tableHasHeaderRow,
  tablePluginKey,
} from "lib/ample-editor/lib/table/table-util"
import { openEmojiPopperTextStartPos } from "lib/ample-editor/plugins/emoji-popper"

const TABLE_CELL_SPLIT_CHARACTER = "|";

// --------------------------------------------------------------------------
export const toggleTableBlock = (state, dispatch) => {
  if (hasBlock(state, state.schema.nodes.table)) {
    return unwrapTable(state, dispatch);
  } else {
    return wrapInTable(state, dispatch);
  }
}

// --------------------------------------------------------------------------
export const clearCellFormatting = (state, dispatch) => {
  if (!isInTable(state)) return null;
  const { schema, selection } = state;
  const { colwidth: _, ...defaultAttrs } = schema.nodes.table_cell.defaultAttrs;
  let $cell, rangeHasFormatting;
  if (selection instanceof CellSelection) {
    selection.forEachCell((node, _pos) => {
      if (rangeHasFormatting) return;
      const { colwidth, ...nodeClearableAttrs } = node.attrs;
      if (!dequal(nodeClearableAttrs, defaultAttrs)) rangeHasFormatting = true;
    });
  } else {
    $cell = cellFromPos(selection.$head);
    if (!$cell) return null;
    const { colwidth, ...nodeClearableAttrs } = $cell.nodeAfter.attrs;
    rangeHasFormatting = !dequal(nodeClearableAttrs, defaultAttrs);
  }

  if (!rangeHasFormatting) return false;

  if (dispatch) {
    const transform = state.tr;
    if ($cell) {
      transform.setNodeMarkup($cell.pos, null, { ...defaultAttrs, colwidth: $cell.nodeAfter.attrs.colwidth });
    } else {
      selection.forEachCell((node, pos) => {
        transform.setNodeMarkup(pos, null, { ...defaultAttrs, colwidth: node.attrs.colwidth });
      });
    }
    dispatch(transform);
  }
  return true;
}

// --------------------------------------------------------------------------
export const maybeCreateTableFromTab = (state, dispatch) => {
  const { doc, selection: { $head, $anchor }, schema } = state;
  if ($head.pos !== $anchor.pos) return false;
  if (openEmojiPopperTextStartPos(state)) return false;
  const $parent = doc.resolve($head.before(TABLE_DEPTH));
  if (!$parent.nodeAfter || $parent.nodeAfter.type !== schema.nodes.paragraph) return false;
  if ($head.pos < ($parent.pos + $parent.nodeAfter.nodeSize - 1)) return false;

  if (dispatch) {
    const transform = state.tr;

    // Delete the paragraph content that had existed, replacing it with a table that has two cells: one containing
    // our deleted paragraph content, the other an empty cell with cursor focus
    const tablePos = $parent.pos
    transform.delete(tablePos, tablePos + $parent.nodeAfter.nodeSize);
    const cellNodes = [
      schema.nodes.table_cell.createAndFill(null, schema.nodes.paragraph.createAndFill(null, $parent.nodeAfter.content)),
    ];

    if ($head.parentOffset) {
      cellNodes.push(schema.nodes.table_cell.createAndFill(null, schema.nodes.paragraph.create()));
    }

    const tableNode = schema.nodes.table.createAndFill(null, schema.nodes.table_row.createAndFill(null, cellNodes));
    transform.insert(tablePos, tableNode);
    const selection = TextSelection.near(transform.doc.resolve(tablePos + tableNode.nodeSize), -1);
    transform.setSelection(selection);

    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
export const addNewCellIfTopRow = (state, dispatch) => {
  if (!isInTable(state)) return false;
  const rectangle = selectedRect(state);
  if (rectangle.top === 0 && rectangle.right === rectangle.map.width) {
    return addColumnAndNext(state, dispatch);
  } else {
    return false;
  }
}

// --------------------------------------------------------------------------
export const addNewRowIfBottomRow = (state, dispatch) => {
  if (!isInTable(state)) return false;

  const rectangle = selectedRect(state);
  if (rectangle.bottom === rectangle.map.height && rectangle.right === rectangle.map.width) {
    return addRowAndNext(state, dispatch);
  } else {
    return false;
  }
};

// --------------------------------------------------------------------------
export const setCellAttrWithBorder = (name, value, borderOnly = false) => (state, dispatch) => {
  if (borderOnly && [ "borderTop", "borderBottom", "borderLeft", "borderRight" ].indexOf(name) !== -1) {
    const $table = tableFromPos(state.selection.$head);
    if (!$table) return false;
    const rect = selectedRect(state);
    if (!rect) return false;
    let column, row;
    switch (name) {
      case "borderTop": row = rect.top; break;
      case "borderBottom": row = rect.bottom - 1; break;
      case "borderLeft": column = rect.left; break;
      case "borderRight": column = rect.right - 1; break;
      default:
        throw new Error(`Invalid border name: ${ name }`);
    }

    const transform = state.tr;
    if (Number.isInteger(row)) {
      for (let columnIndex = rect.left; columnIndex < rect.right; columnIndex++) {
        const $cell = cellFromTable($table, row, columnIndex, { doc: transform.doc });
        if ($cell.nodeAfter.attrs[name] !== value) {
          transform.setNodeMarkup($cell.pos, null, setAttr($cell.nodeAfter.attrs, name, value));
        }
      }
    } else if (Number.isInteger(column)) {
      for (let rowIndex = rect.top; rowIndex < rect.bottom; rowIndex++) {
        const $cell = cellFromTable($table, rowIndex, column, { doc: transform.doc });
        if ($cell.nodeAfter.attrs[name] !== value) {
          transform.setNodeMarkup($cell.pos, null, setAttr($cell.nodeAfter.attrs, name, value));
        }
      }
    }

    if (dispatch) dispatch(transform);
    return true;
  } else {
    const { selection, tr: transform } = state;
    if (!(selection instanceof CellSelection)) return false;
    selection.forEachCell((node, pos) => {
      if (name === CLEAR_BORDER_COMMAND_NAME) {
        const { borderTop, borderBottom, borderLeft, borderRight, ...nodeAttrs } = node.attrs;
        transform.setNodeMarkup(pos, null, nodeAttrs);
      } else if (node.attrs[name] !== value) {
        transform.setNodeAttribute(pos, name, value);
      }
    });
    if (dispatch) {
      dispatch(transform);
    }
    return true;
  }

}

// --------------------------------------------------------------------------
// An ultimatum to add a column and move into it so long as selection is in a table
export const addColumnAndNext = (state, dispatch) => {
  if (!isInTable(state)) return false;

  if (dispatch) {
    const rectangle = selectedRect(state);
    const { selection: { $head }, tr } = state;
    addColumn(tr, rectangle, rectangle.right, state.schema);
    const $table = tableFromPos($head);
    const $nextCell = cellFromTable($table, rectangle.top, rectangle.right, { doc: tr.doc });
    const $nextCellPos = TextSelection.near($nextCell);
    dispatch(tr.setSelection($nextCellPos).scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const addColumnBefore = (state, dispatch) => {
  if (!isInTable(state)) return false;

  if (dispatch) {
    const rectangle = selectedRect(state);
    const { selection: { $head }, tr } = state;
    addColumn(tr, rectangle, rectangle.left, state.schema);
    const $table = tableFromPos(tr.doc.resolve($head.pos));
    const $nextCell = cellFromTable($table, rectangle.top, rectangle.left, { doc: tr.doc });
    const $nextCellPos = TextSelection.near($nextCell);
    dispatch(tr.setSelection($nextCellPos).scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const applyColumnSelect = (columnAnchorPos, { columnHeadPos = null, shiftHeld = null } = {}) => (state, dispatch) => {
  const { doc, tr: transform, selection } = state;

  if (columnHeadPos === null) {
    if (shiftHeld && selection instanceof CellSelection) {
      columnHeadPos = columnAnchorPos;
      columnAnchorPos = selection.$anchorCell.pos;
    } else {
      columnHeadPos = columnAnchorPos;
    }
  }

  if (columnAnchorPos >= doc.content.size || columnHeadPos >= doc.content.size) return false;

  const $columnAnchorCell = cellFromPos(doc.resolve(columnAnchorPos));
  const $columnHeadCell = cellFromPos(doc.resolve(columnHeadPos));
  if (!$columnAnchorCell || !$columnHeadCell) return false;
  const cellSelection = CellSelection.colSelection($columnAnchorCell, $columnHeadCell);
  if (!cellSelection.eq(state.selection)) {
    transform.setSelection(cellSelection);
  }

  const pluginState = tablePluginKey.getState(state);
  const metas = []
  if (pluginState.columnSelectDragFrom === CELL_SELECT_INACTIVE) {
    metas.setColumnSelectDragFrom = columnAnchorPos;
  } else if (columnHeadPos !== pluginState.setSelectDragTo) {
    metas.setSelectDragTo = columnHeadPos;
  }

  if (pluginState.rowSelectDragFrom !== CELL_SELECT_INACTIVE) {
    metas.setRowSelectDragFrom = CELL_SELECT_INACTIVE;
  }

  if (Object.keys(metas).length) {
    transform.setMeta(tablePluginKey, metas);
  }

  if (transform) dispatch(transform);
  return true;
}

// --------------------------------------------------------------------------
export const applyRowSelect = (rowCellAnchorPos, { rowCellHeadPos = null, shiftHeld = null }) => (state, dispatch) => {
  const { doc, tr: transform, selection } = state;

  if (rowCellHeadPos === null) {
    if (shiftHeld && selection instanceof CellSelection) {
      rowCellHeadPos = rowCellAnchorPos;
      rowCellAnchorPos = selection.$anchorCell.pos;
    } else {
      rowCellHeadPos = rowCellAnchorPos;
    }
  }

  if (rowCellAnchorPos >= doc.content.size || rowCellHeadPos >= doc.content.size) return false;

  const $rowCellAnchorCell = cellFromPos(doc.resolve(rowCellAnchorPos));
  const $rowCellHeadCell = cellFromPos(doc.resolve(rowCellHeadPos));
  if (!$rowCellAnchorCell || !$rowCellHeadCell) return false;
  const cellSelection = CellSelection.rowSelection($rowCellHeadCell, $rowCellAnchorCell);
  if (!cellSelection.eq(state.selection)) {
    transform.setSelection(cellSelection);
  }

  const pluginState = tablePluginKey.getState(state);
  const metas = [];
  if (pluginState.rowSelectDragFrom === CELL_SELECT_INACTIVE) {
    metas.setRowSelectDragFrom = rowCellAnchorPos;
  } else if (pluginState.setSelectDragTo !== rowCellHeadPos) {
    metas.setSelectDragTo = rowCellHeadPos;
  }

  if (pluginState.columnSelectDragFrom !== CELL_SELECT_INACTIVE) {
    metas.setColumnSelectDragFrom = CELL_SELECT_INACTIVE;
  }

  if (Object.keys(metas).length) {
    transform.setMeta(tablePluginKey, metas);
  }

  if (transform) dispatch(transform);
  return true;
}


// --------------------------------------------------------------------------
export const addRowAndNext = (state, dispatch) => {
  const $table = tableFromPos(state.selection.$head);
  if (!$table) return false;

  if (dispatch) {
    const rectangle = selectedRect(state);
    const $row = rowFromPos(state.selection.$head);
    const tr = state.tr;
    let nextSelection;
    const rowEmpty = isRowEmpty($row)

    if (rowEmpty) {
      if (rectangle.map.height === 1) {
        return unwrapTable(state, dispatch);
      } else if ($row) {
        return handleEmptyRowEnter($table, $row, rectangle, state, dispatch);
      } else {
        return false;
      }
    } else {
      const { selection } = state;
      let insertBefore = selection instanceof TextSelection && selection.$head.parentOffset === 0 && rectangle.left === 0;
      if (insertBefore) {
        const $cursorCell = cellFromPos(selection.$head);
        // If cursor is in first pos of a cell, it must be exactly 2 places past cell start (cell itself and its containing paragraph)
        insertBefore = $cursorCell && ($cursorCell.pos + 2 === selection.head);
      }
      if (insertBefore) {
        addRow(tr, rectangle, rectangle.top);
      } else {
        addRow(tr, rectangle, rectangle.bottom);
      }
      const $cell = tr.doc.resolve(tr.steps[tr.steps.length - 1].from);
      nextSelection = tr.setSelection(TextSelection.between($cell, moveCellForward($cell)));
    }
    dispatch(nextSelection.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const addRowBefore = (state, dispatch) => {
  if (!isInTable(state)) return false;

  if (dispatch) {
    const rectangle = selectedRect(state);
    const tr = state.tr;
    addRow(tr, rectangle, rectangle.top);
    const $cell = tr.doc.resolve(tr.steps[tr.steps.length - 1].from);

    const nextSelection = tr.setSelection(TextSelection.between($cell, moveCellForward($cell)));
    dispatch(nextSelection.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const moveToNextRow = (state, dispatch) => {
  if (!state.selection.$head) return false;
  const $cell = cellFromPos(state.selection.$head);
  if (!$cell) return false;

  const rectangle = selectedRect(state);
  if (rectangle.bottom < rectangle.map.height) {
    const selection = state.selection;
    // If user is in the first position of the leftmost cell, an enter press is interpreted as intent to insert a row (not moveToNextRow)
    // If text is in first position of cell, it will be +2 past $cell.pos (the pos before cell): the cell itself and its paragraph
    if (rectangle.left === 0 && selection instanceof TextSelection && $cell.pos + 2 === selection.head) {
      return false;
    }

    if (dispatch) {
      const tr = state.tr;
      const $nextRow = nextCell($cell, AXIS.VERTICAL, 1);
      const newSelection = TextSelection.near($nextRow);

      // If not for waiting a tick, mobile Safari will ignore moved newSelection. WBH has been unable to determine
      // specific cause since setting a breakpoint to trace what happens has the same effect as this setTimeout
      // (i.e., the Safari-specific mechanism that chooses to ignore Enter press is gone after 1 tick, debugger or not)
      setTimeout(() => {
        dispatch(tr.setSelection(newSelection).scrollIntoView());
      }, 1);
    }

    return true;
  } else {
    return false;
  }
}

// --------------------------------------------------------------------------
export const moveToNextCell = (state, dispatch) => {
  return goToNextCell(1)(state, dispatch);
}

// --------------------------------------------------------------------------
export const moveToPrevCell = (state, dispatch) => {
  return goToNextCell(-1)(state, dispatch);
}

// --------------------------------------------------------------------------
export const backspaceInTable = (state, dispatch) => {
  if (!isInTable(state)) return false;

  const { selection } = state;
  if (selection instanceof CellSelection) {
    return deleteCellSelection(state, dispatch);
  }

  if (!selection.$cursor) return false;
  const selectionNode = selection.$cursor.parent;
  if (selectionNode) {
    if (selectionNode.type.spec.code) return false;
    const { depth } = selectedListItemDepth(state);
    if (depth) return false;
  }

  const $cell = cellFromPos(selection.$head);
  if ($cell.nodeAfter.textContent !== "") {
    // At start of a cell when content exists after cursor = Do nothing. Otherwise, delegate to standard backspace handling
    const swallowBackspace = (selection.$anchor.pos === selection.$head.pos &&
      (selection.$anchor.pos === ($cell.pos + selection.$anchor.depth - $cell.depth)));
    return swallowBackspace;
  }

  // If we made it this far, cell has no text content, so we can possibly delete a row, column, or table
  const rectangle = selectedRect(state);
  const $row = rowFromPos(state.selection.$head);

  // If row is empty, backspace at start of first cell will delete row or table
  // If cell is empty, backspace at start will move to previous cell (a la OneNote)
  if ($row.nodeAfter.textContent.trim() === "" && rectangle.left === 0) {
    if (rectangle.map.height === 1 && rectangle.map.width === 1) {
      return unwrapTable(state, dispatch);
    } else {
      // The row is empty, delete it:
      if (dispatch) {
        const transform = state.tr;
        transform.delete($row.pos, $row.pos + $row.nodeAfter.nodeSize);
        transform.setSelection(TextSelection.near(transform.doc.resolve($row.pos), -1));
        dispatch(transform);
      }
      return true;
    }
  } else if (rectangle.map.height === 1) {
    const transform = state.tr;
    removeColumn(transform, rectangle, rectangle.right - 1);
    transform.setSelection(TextSelection.near(transform.doc.resolve($cell.pos), -1));
    dispatch(transform);
    return true;
  } else {
    return moveToPrevCell(state, dispatch);
  }
}

// --------------------------------------------------------------------------
// Following the lead of OneNote and Google Docs, pressing delete from the end of a cell does nothing,
// while pressing delete from within a cell is handled by other methods
export const deleteInTable = (state, dispatch) => {
  if (!isInTable(state)) return false;
  const { selection, selection: { $head, $anchor } } = state;
  if (selection instanceof CellSelection) return deleteCellSelection(state, dispatch);
  if ($head.pos !== $anchor.pos) return false;
  // At the end of the cell?
  const $cell = cellFromPos($head);
  if ($head.pos + ($head.depth - $cell.depth) === $cell.pos + $cell.nodeAfter.nodeSize) return true;
  return false;
}

// --------------------------------------------------------------------------
export const selectionSpansTableCells = state => {
  const { selection: { $from, $to } } = state;
  if (!$from || !$to || $from.pos === $to.pos) return false;
  const $fromCell = cellFromPos($from);
  const $toCell = cellFromPos($to);

  if (!$fromCell && !$toCell) return false;

  // If only half the selection is in a table cell, or if the cells positions are different, then we span table cells
  return !$toCell || !$fromCell || $toCell.pos !== $fromCell.pos;
}

// --------------------------------------------------------------------------
export const deleteColumnOrReturnNull = (state, dispatch) => {
  if (!isInTable(state)) return null;
  const selection = selectedRect(state);
  if (selection.map.width === 1) return null;
  return deleteColumn(state, dispatch);
}

// --------------------------------------------------------------------------
export const deleteRowOrReturnNull = (state, dispatch) => {
  if (!isInTable(state)) return null;
  const selection = selectedRect(state);
  if (selection.map.height === 1) return null;
  return deleteRow(state, dispatch);
}

// --------------------------------------------------------------------------
export function deleteCellSelection(state, dispatch) {
  const selection = state.selection;
  if (!(selection instanceof CellSelection)) return false;
  if (!dispatch) return true;

  const tr = state.tr;
  const baseCell = tableNodeTypes(state.schema).cell.createAndFill();
  const { colwidth: _, ...baseCellAttrs } = baseCell.attrs;
  const baseContent = baseCell.content;
  let contentEmpty = true;
  let formattingEmpty = true;
  selection.forEachCell((cell, pos) => {
    const { colwidth, ...cellAttrs } = cell.attrs;
    if (!cell.content.eq(baseContent)) {
      contentEmpty = false;
      tr.replace(
        tr.mapping.map(pos + 1),
        tr.mapping.map(pos + cell.nodeSize - 1),
        new Slice(baseContent, 0, 0),
      );
    } else if (!dequal(baseCellAttrs, cellAttrs)) {
      formattingEmpty = false;
    }
  });

  if (contentEmpty && formattingEmpty) { // If cell selection range was void of content, look to delete column/rows
    removeSelectionEmptyColumnRows(state, tr);
  } else if (contentEmpty) {
    removeSelectionColumnRowFormatting(state, tr, baseCell);
  }

  if (tr.docChanged) {
    dispatch(tr);
  }

  return true;
}

// --------------------------------------------------------------------------
export const resizeColumnToEstimatedContentWidth = (state, dispatch) => {
  const { selection } = state;
  const $table = tableFromPos(selection.$head);
  if (!$table) return false;

  let endColumnIndex, endRowIndex, startColumnIndex, startRowIndex;
  if (selection instanceof CellSelection) {
    startColumnIndex = Math.min(selection.$anchorCell.index(), selection.$headCell.index());
    startRowIndex = Math.min(rowIndexFromCell(selection.$anchorCell), rowIndexFromCell(selection.$headCell));
    endColumnIndex = Math.max(selection.$anchorCell.index(), selection.$headCell.index());
    endRowIndex = Math.max(rowIndexFromCell(selection.$anchorCell), rowIndexFromCell(selection.$headCell));
  } else {
    const $headCell = cellFromPos(selection.$head);
    const $anchorCell = selection.anchor !== selection.head ? cellFromPos(selection.$anchor) : $headCell;

    startColumnIndex = Math.min($anchorCell.index(), $headCell.index());
    startRowIndex = Math.min(rowIndexFromCell($anchorCell), rowIndexFromCell($headCell));
    endColumnIndex = Math.max($anchorCell.index(), $headCell.index());
    endRowIndex = Math.max(rowIndexFromCell($anchorCell), rowIndexFromCell($headCell));
  }

  const transform = state.tr;
  for (let column = startColumnIndex; column <= endColumnIndex; column++) {
    const columnWidth = columnWidthFromColumnIndex($table, column, startRowIndex);
    const contentWidth = estimateColumnContentWidth($table, column, startRowIndex, endRowIndex);
    const contentWidthPadded = (contentWidth + COLUMN_WIDTH_ESTIMATE_EXTRA_PX <= CELL_MIN_WIDTH
      ? CELL_MIN_WIDTH
      : Math.min(contentWidth + COLUMN_WIDTH_ESTIMATE_EXTRA_PX, COLUMN_WIDTH_MAX_ESTIMATE_PX)
    );
    if (contentWidthPadded !== columnWidth) {
      setColumnWidth($table, column, contentWidthPadded, { transform })(state, null);
    }
  }

  if (transform.docChanged) {
    dispatch(transform);
  }
}

// --------------------------------------------------------------------------
export const setColumnWidth = ($table, column, width, { transform = null } = {}) => (state, dispatch) => {
  transform = (transform || state.tr);

  forEachColumnIndexCell($table, column, $columnCell => {
    if ($columnCell.nodeAfter.attrs.colwidth !== width) {
      transform.setNodeAttribute($columnCell.pos, "colwidth", width);
    }
  });

  if (dispatch && transform.docChanged) {
    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
export const escapeCellSelectionMenu = (state, dispatch) => {
  const { selection } = state;
  if (selection instanceof CellSelection) {
    const pluginState = tablePluginKey.getState(state);
    if (pluginState.dismissSelectionPos !== selection.$anchorCell.pos) {
      dispatch(state.tr.setMeta(tablePluginKey, { setDismissSelectionPos: selection.$anchorCell.pos }));
    } else {
      dispatch(state.tr.setMeta(tablePluginKey, { setDismissSelectionPos: null }));
    }

    // Return true for any sort of cellSelection since "selecting parent" (next in chainCommand) doesn't
    // make sense in this CellSelection context
    return true;
  } else {
    return false;
  }
}

// --------------------------------------------------------------------------
export const maybeMoveRowDown = (state, dispatch) => maybeMoveRow(1)(state, dispatch);
export const maybeMoveRowUp = (state, dispatch) => maybeMoveRow(-1)(state, dispatch);

// --------------------------------------------------------------------------
// Re-order a set of rows: either the entire set of rows (sans header) if selection was within one cell, or the
// set of CellSelection cells if they are only in one column (returns null if CellSelection is multi-column)
export const toggleTableColumnSort = (state, dispatch) => {
  const { selection } = state;

  let $fromCell, $toCell;
  if (selection instanceof CellSelection) {
    const anchorColumn = selection.$anchorCell.index();
    const headColumn = selection.$headCell.index();
    if (selection.$anchorCell.pos === selection.$headCell.pos) {
      $fromCell = selection.$anchorCell;
    } else if (anchorColumn === headColumn) {
      $fromCell = selection.$anchorCell.pos < selection.$headCell.pos ? selection.$anchorCell : selection.$headCell;
      $toCell = selection.$anchorCell.pos < selection.$headCell.pos ? selection.$headCell : selection.$anchorCell;
    } else {
      return null;
    }
  } else {
    $fromCell = cellFromPos(selection.$from);
    if (!$fromCell) return null;
    if (selection.from !== selection.to) {
      const $anchorCell = cellFromPos(selection.$to);

      // If a non-cell selection spans cells, no column sorting is possible
      if (!$anchorCell || $anchorCell.pos !== $fromCell.pos) return null;
    }
  }

  if (dispatch) {
    const $table = tableFromPos($fromCell);
    // Unless a specific cellRange was selected (which would make $toCell truthy), our start row is zero. Otherwise it's $fromCell
    const sortRowMin = $toCell ? $fromCell.index(-1) : 0;
    const tableHasHeader = tableHasHeaderRow($table, $fromCell);
    const selectionIncludesHeaderRow = sortRowMin === 0 && tableHasHeader;
    const sortRowStart = selectionIncludesHeaderRow ? 1 : sortRowMin;
    const selectionColumn = $fromCell.index();
    const selectionRowEnd = $toCell ? Math.max($toCell.index(-1), sortRowStart) : null;
    const $sortFromCell = cellFromTable($table, sortRowStart, selectionColumn);
    let newRowIndexes = sortedRowIndexesFromColumn($table, $sortFromCell, tableHasHeader, SORT_APPLIED.ASC, { $sortEndCell: $toCell });
    const sortedIndexes = newRowIndexes.slice();
    sortedIndexes.sort((a, b) => a - b);
    // If the indexes of rows are unchanged, that indicates we were already ASC sorted so we'll DESC sort instead
    // dequal === isEqual from lodash, but apparently faster
    if (dequal(newRowIndexes.slice(), sortedIndexes)) {
      newRowIndexes = sortedRowIndexesFromColumn($table, $sortFromCell, tableHasHeader, SORT_APPLIED.DESC, { $sortEndCell: $toCell });
    }
    const transform = state.tr;
    const orderedNodes = [];
    newRowIndexes.forEach(rowIndex => {
      const rowNode = $table.nodeAfter.child(rowIndex);
      orderedNodes.push(rowNode);
    });

    transform.replaceWith($table.pos + 1, $table.pos + $table.nodeAfter.nodeSize, orderedNodes);

    // Since we are replacing the whole table, PM's ability to map cells through the transform was inoperable during
    // WBH implementation tests Nov 2022, thus we look up CellSelection range from scratch based on column/row index
    const $resortedTable = transform.doc.resolve($table.pos);
    const $selectionHead = cellFromTable($resortedTable, sortRowMin, selectionColumn);
    const $selectionAnchor = selectionRowEnd ? cellFromTable($resortedTable, selectionRowEnd, selectionColumn) : $selectionHead;
    if ($selectionHead.pos === $selectionAnchor.pos) {
      transform.setSelection(TextSelection.near($selectionHead));
    } else {
      const newSelection = new CellSelection($selectionAnchor, $selectionHead);
      transform.setSelection(newSelection);
    }
    dispatch(transform.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export const toggleTableColumnLockedWidth = (state, dispatch) => {
  const $cell = cellFromPos(state.selection.$head);
  if (!$cell) return null;

  const lockedWidth = Number.isFinite($cell.nodeAfter.attrs.colwidth);
  if (lockedWidth && dispatch) {
    const $table = tableFromPos(state.selection.$head);
    const transform = state.tr;
    forEachColumnIndexCell($table, $cell.index(), $columnCell => {
      transform.setNodeAttribute($columnCell.pos, "colwidth", null);
    })
    dispatch(transform);
  }

  return lockedWidth;
}

// --------------------------------------------------------------------------
export const toggleTableFullWidth = (state, dispatch) => {
  const $table = tableFromPos(state.selection.$from);
  if (!$table) return null;

  const fullWidth = $table.nodeAfter.attrs.fullWidth;
  if (dispatch) {
    dispatch(state.tr.setNodeAttribute($table.pos, "fullWidth", !fullWidth));
  }

  return fullWidth;
}

// --------------------------------------------------------------------------
// Local functions
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
const maybeMoveRow = direction => (state, dispatch) => {
  const { selection: { $head, $anchor } } = state;
  const $row = rowFromPos($head);
  if (!$row || $head.pos !== $anchor.pos) return false;

  const swapRowPos = direction === 1
    ? ($row.nodeAfter ? $row.pos + $row.nodeAfter.nodeSize : null)
    : ($row.nodeBefore ? $row.pos - $row.nodeBefore.nodeSize : null);
  if (swapRowPos === null) return false;
  const $swapRow = state.doc.resolve(swapRowPos);
  if (!$swapRow.nodeAfter || $swapRow.nodeAfter.type.spec.tableRole !== "row") return false;

  if (dispatch) {
    const transform = state.tr;
    const deleteStartPos = $swapRow.pos > $row.pos ? $row.pos : $swapRow.pos;
    const deleteEndPos = $swapRow.pos > $row.pos ? $swapRow.pos + $swapRow.nodeAfter.nodeSize : $row.pos + $row.nodeAfter.nodeSize;
    const orderedNodes = direction === 1 ? [ $swapRow.nodeAfter, $row.nodeAfter ] : [ $row.nodeAfter, $swapRow.nodeAfter ];
    transform.replaceWith(deleteStartPos, deleteEndPos, orderedNodes);
    const selectionPos = direction === 1 ? deleteStartPos + $swapRow.nodeAfter.nodeSize : deleteStartPos;
    transform.setSelection(TextSelection.near(transform.doc.resolve(selectionPos)));
    setTableRedrawMeta(transform);
    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
// Unwrap tables at state selection to some approximation of CSV
// "Some approximation" cuz we don't just want to fart out a text blob, but we also don't want to write a full
// ProseMirror->tilde CSV translation layer given so many types of PM nodes, and usage freq as yet completely unproven
const unwrapTable = (state, dispatch) => {
  const { schema, selection: { $from }, tr } = state;
  const transform = tr;
  const convertedRows = [];
  const tableStartPos = $from.before(TABLE_DEPTH);
  const tableEndPos = $from.after(TABLE_DEPTH);

  if (dispatch) {
    state.doc.nodesBetween(tableStartPos, tableEndPos, tableRowNode => {
      if (tableRowNode.type !== schema.nodes.table_row) return;
      const delimiter = schema.text(TABLE_CELL_SPLIT_CHARACTER);
      let inlineNodes = [];
      tableRowNode.forEach((cellNode, columnIndex) => {
        if (cellNode.type !== schema.nodes.table_cell) return;

        if (columnIndex > 0) {
          inlineNodes.push(delimiter);
        }

        cellNode.descendants(descendant => {
          if (descendant.isLeaf || descendant.type.spec.group === "inline") {
            inlineNodes.push(descendant);
            return false;
          }
        });
      });

      convertedRows.push(inlineNodes);
      inlineNodes = [];
    });

    transform.delete(tableStartPos, tableEndPos);
    let iterPos = tableStartPos;
    convertedRows.forEach(paragraphFragments => {
      const paragraph = schema.nodes.paragraph.createAndFill(null, paragraphFragments);
      if (paragraph) {
        transform.insert(iterPos, paragraph);
        iterPos += paragraph.nodeSize;
      } else {
        // eslint-disable-next-line no-console
        console.error("Converted unable to create paragraph from", paragraphFragments);
      }
    });

    const $anchor = transform.doc.resolve(tableStartPos);
    const $head = transform.doc.resolve(iterPos);
    const selection = TextSelection.between($anchor, $head);
    transform.setSelection(selection);
    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
const wrapInTable = (state, dispatch) => {
  if (dispatch) {
    const { schema } = state;
    const transform = state.tr;
    const rows = cellArrayFromSelection(state, transform);
    const columnCount = Math.max(...rows.map(columns => columns.length));
    const tablePos = state.selection.$from.before(TABLE_DEPTH);

    if (rows.length) {
      const rowNodes = [];
      rows.forEach(row => {
        const cellNodes = [];
        for (let colIndex = 0; colIndex < columnCount; colIndex++) {
          const cellContent = row[colIndex];
          let paragraph;
          if (cellContent && cellContent.length) {
            if (cellContent.length === 1 && cellContent[0].isBlock) {
              paragraph = cellContent[0];
            } else {
              paragraph = schema.nodes.paragraph.createAndFill(null, cellContent);
            }
          } else {
            paragraph = schema.nodes.paragraph.create();
          }

          cellNodes.push(schema.nodes.table_cell.createAndFill(null, paragraph));
        }
        rowNodes.push(schema.nodes.table_row.createAndFill(null, cellNodes));
      });
      transform.insert(tablePos, schema.nodes.table.createAndFill(null, rowNodes));
    } else {
      const tableNode = schema.nodes.table.createAndFill(null,
        schema.nodes.table_row.createAndFill(null,
          schema.nodes.table_cell.createAndFill(null, schema.nodes.paragraph.create())
        )
      );
      transform.insert(tablePos, tableNode);
    }

    const $table = transform.doc.resolve(tablePos);
    const startOfTable = TextSelection.near(transform.doc.resolve($table.pos));
    transform.setSelection(startOfTable);
    dispatch(transform);
  }

  return true;
}

// --------------------------------------------------------------------------
// Derive and return a 2-dimensional array of table contents from the selection range. Cells are
// interpreted to be delimited by TABLE_CELL_SPLIT_CHARACTER
//
// To  allow a variety of different node types to be assimilated into the table from inline paragraph content
// is tricky business, thus the unsavory density of this method in its v1
const cellArrayFromSelection = (state, transform) => {
  const { selection: { $from, $to } } = state;
  const rows = [];
  const deleteAt = [];

  const startPos = $from.before(TABLE_DEPTH);
  state.doc.nodesBetween(startPos, $to.after(TABLE_DEPTH), (node, pos) => {
    const cells = [];
    let cellFragments = [];
    let pendingCellInsert = false;

    node.forEach((child, _offset, _index) => {
      if (!child.textContent.includes(TABLE_CELL_SPLIT_CHARACTER)) {
        pendingCellInsert = false;
        cellFragments.push(child);
        return;
      }

      // We're here because this child block node has at least one delimiter (= two delimited Fragments) to be split apart
      const fragments = child.textContent.split(TABLE_CELL_SPLIT_CHARACTER);
      let fragmentIndex = 0;
      fragments.forEach((fragment, index) => {
        // Every loop is to create a cell, as each loop should represent content bounded by TABLE_CELL_SPLIT_CHARACTER (or edges)
        if (fragment.length) {
          let childSlice;
          if (child.textContent.length === fragment.length) {
            childSlice = child;
          } else {
            childSlice = child.cut(fragmentIndex, fragmentIndex + fragment.length);
          }
          cellFragments.push(childSlice);
        }
        fragmentIndex += fragment.length + 1; // 1 for the TABLE_CELL_SPLIT_CHARACTER that split after this (if further processing takes place)

        // We have arrived at a delimiter, shuttle current cell fragments to cells array
        const moreFragmentsRemain = (index + 1) < fragments.length;
        if (moreFragmentsRemain || (fragment.length === 0)) {
          if (cellFragments.length) {
            pendingCellInsert = false;
            cells.push(cellFragments);
            cellFragments = [];
          } else if (pendingCellInsert) {
            cells.push(null);
          } else {
            // We want to ensure a new cell is created if a delimiter exists, but if the subsequent content is a valid
            // node, we can use that. If it's not a valid node, then this bool will tell us to push an empty cell
            pendingCellInsert = true;
          }
        }
      });
    });

    // Handle possibility of leftover contents to be added as final cell
    if (cellFragments.length) {
      cells.push(cellFragments);
    } else if (pendingCellInsert) {
      cells.push(null);
    }

    if (cells.length) rows.push(cells);
    deleteAt.push([ pos, pos + node.nodeSize ]);
    return false; // Don't recurse into children
  }); // Row loop

  // Delete from end of doc since each delete changes position of stuff after
  deleteAt.reverse().forEach(tuple => transform.delete(tuple[0], tuple[1]));

  return rows;
}

// --------------------------------------------------------------------------
const removeSelectionEmptyColumnRows = (state, tr) => {
  const rectangle = selectedRect(state);
  clearCellHover(tr);
  const { map, table: tableNode, tableStart: tableInnerStartPos } = rectangle;
  if (rectangle.left === 0 && rectangle.right === map.width && rectangle.bottom === map.height && rectangle.top === 0) {
    const tableStartPos = tableInnerStartPos - 1;
    tr.delete(tableStartPos, tableStartPos + tableNode.nodeSize);
    tr.setSelection(TextSelection.near(tr.doc.resolve(tableStartPos)));
  } else {
    if (rectangle.top === 0 && rectangle.bottom === map.height) { // Full column(s) selected
      for (let row = rectangle.bottom - 1; row >= 0; row--) {
        const rowDeleteStartPos = tableInnerStartPos + map.positionAt(row, rectangle.left, tableNode);
        const rowDeleteEndPos = tableInnerStartPos + map.positionAt(row, rectangle.right, tableNode);
        tr.delete(rowDeleteStartPos, rowDeleteEndPos);
      }

      if (rectangle.left === 0) {
        tr.setSelection(TextSelection.near(tr.doc.resolve(tableInnerStartPos)));
      } else { // Position of left of top cell will not have changed from column deletion
        tr.setSelection(TextSelection.near(tr.doc.resolve(tableInnerStartPos + map.positionAt(0, rectangle.left - 1, tableNode))));
      }
    } else if (rectangle.left === 0 && rectangle.right === map.width) {
      for (let row = rectangle.bottom - 1; row >= rectangle.top; row--) {
        const $cell = tr.doc.resolve(tableInnerStartPos + map.positionAt(row, 0, tableNode));
        const $deleteRow = rowFromPos($cell);
        tr.delete($deleteRow.pos, $deleteRow.pos + $deleteRow.nodeAfter.nodeSize);
      }
      if (rectangle.top === 0) {
        tr.setSelection(TextSelection.near(tr.doc.resolve(tableInnerStartPos)));
      } else {
        tr.setSelection(TextSelection.near(tr.doc.resolve(tableInnerStartPos + map.positionAt(rectangle.top - 1, 0, tableNode))));
      }
    }
  }
}

// --------------------------------------------------------------------------
const removeSelectionColumnRowFormatting = (state, tr, baseCell, deleteColwidth = false) => {
  const rectangle = selectedRect(state);
  const { map, table: tableNode, tableStart: tableStartPos } = rectangle;
  for (let row = rectangle.top; row < rectangle.bottom; row++) {
    for (let column = rectangle.left; column < rectangle.right; column++) {
      const cellPos = tableStartPos + map.positionAt(row, column, tableNode);
      const $cell = tr.doc.resolve(cellPos);
      const { colwidth, ...attrs } = baseCell.attrs;
      if (deleteColwidth) {
        attrs.colwidth = colwidth;
      } else {
        attrs.colwidth = $cell.nodeAfter.attrs.colwidth;
      }
      tr.setNodeMarkup(cellPos, null, attrs);
    }
  }
}

// --------------------------------------------------------------------------
// Add a column at the given position in a table, propagating color/bgColor from left
const addColumn = (tr, rectangle, col, schema) => {
  const { map, tableStart, table } = rectangle;
  for (let row = 0; row < map.height; row++) {
    const attrColumnIndex = Math.max(Math.min(col, map.width - 1), 0);
    const adjacentNode = table.child(row).child(attrColumnIndex);
    const pos = map.positionAt(row, col, table);
    tr.insert(tr.mapping.map(tableStart + pos), schema.nodes.table_cell.createAndFill({ ...adjacentNode.attrs }));
  }
  return tr;
}

// --------------------------------------------------------------------------
// There are a number of cases where pressing enter in an empty row should escape the table, but what state
// that leaves the surrounding table in is what this function determines
const handleEmptyRowEnter = ($table, $row, rectangle, state, dispatch) => {
  const { doc, tr: transform } = state;
  const $rowBefore = $row.nodeBefore && rowFromPos(doc.resolve($row.pos - 1));
  const $rowAfter = rowFromPos(doc.resolve($row.pos + $row.nodeAfter.nodeSize + 1));
  let selectionPos;

  // If user is in first or last row, they might be eligible to escape the table
  if (!$rowAfter) {
    transform.delete($row.pos, $row.pos + $row.nodeAfter.nodeSize);
    $table = tableFromPos(transform.doc.resolve($row.pos - 1));
    selectionPos = $table.pos + $table.nodeAfter.nodeSize;
    transform.insert(selectionPos, state.schema.nodes.paragraph.createAndFill());
  } else if (isRowEmpty($rowAfter) && !$rowBefore) {
    transform.delete($row.pos, $row.pos + $row.nodeAfter.nodeSize);
    selectionPos = $table.pos;
    transform.insert(selectionPos, state.schema.nodes.paragraph.createAndFill());
  } else {
    addRow(transform, rectangle, rectangle.bottom);
    selectionPos = $row.pos + $row.nodeAfter.nodeSize;
  }
  transform.setSelection(TextSelection.near(transform.doc.resolve(selectionPos)));

  if (dispatch) dispatch(transform);
  return true;
}

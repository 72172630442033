import { dequal } from "dequal/lite"
import { NodeSelection, Plugin, TextSelection } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import {
  CellSelection,
  fixTables,
  handlePaste,
} from "prosemirror-tables"

import {
  CELL_RESIZE_INACTIVE,
  CELL_SELECT_INACTIVE,
  META_TABLE_SELECTION_DISABLE,
  TABLE_DEPTH,
} from "lib/ample-editor/lib/table/table-constants"
import { handleKeyDown } from "lib/ample-editor/lib/table/table-keymap"
import { onTableMouseDown, onTablePropMouseLeave } from "lib/ample-editor/lib/table/table-mouse-movement"
import { formulaDependenciesFromTable, setRedrawIfFormulaDependentsChanged } from "lib/ample-editor/lib/table/table-plugin-formula"
import { isTextSelectionAcrossCells } from "lib/ample-editor/lib/table/table-selection"
import {
  cellFromPos,
  forEachColumnCell,
  forEachTableCell,
  rowFromPos,
  tableFromPos,
  tablePluginKey
} from "lib/ample-editor/lib/table/table-util"

// --------------------------------------------------------------------------
// Every state variable as of April 2023 set by a corresponding meta key, for example setting the meta key `setCellToResizePos`
// becomes `cellToResizePos` to increase ease of figuring out which handler beget which variable change
const TABLE_PLUGIN_INITIAL_STATE = {
  calculatedDependentsAtPos: null, // If the table pos doesn't match this pos, it is used as a sign to recalculate dependents
  cellToResizePos: CELL_RESIZE_INACTIVE, // After user has hovered on a column edge long enough, resizeHoverPos becomes cellToResizePos. If user starts an attempted column resize and this isn't in a valid position, that's a problem we sometimes have to deal with
  columnSelectDragFrom: CELL_SELECT_INACTIVE, // The cell pos from which an ongoing drag began, as invoked when applyColumnSelect is called cuz user clicked with a valid hoverColumnSelectPos
  dismissSelectionPos: null, // Position at which user recently, explicitly dismissed the select menu (= pos not to re-pop it even if we usually would)
  dragging: false, // Becomes { startX: mouseDownEvent.clientX, startWidth: width } a user starts to resize a column from a currently-valid `cellToResizePos`
  formulaDependentCells: {}, // An object that describes which cells as "row-col" strings with dependents array, e.g., { "0-0": [ "0-1", "0-2" ] }
  hoverColumnSelectPos: CELL_SELECT_INACTIVE, // Set to pos of a table cell during onTableMouseMove event iff user is hovering near top of a table
  hoverRowSelectPos: CELL_SELECT_INACTIVE, // Set to pos of a table cell during onTableMouseMove event iff user is hovering near left of a table
  pendingRedrawPosArray: [], // Array of cell poses that are awaiting redraw, e.g., [173, 189, 313, 318]
  redrawsRequested: 0, // A redraw currently applies to entire table/all its cells
  rowSelectDragFrom: CELL_SELECT_INACTIVE, // The cell pos from which a row drag began if user clicked while hoverRowSelectPos was valid
  selectDragTo: CELL_SELECT_INACTIVE, // Complete the column or cell selection range inferred by rowSelectDragFrom/columnSelectDragFrom
  selectionStartPos: null, // An arbitrary cell start position for defining a selection range, used for example when a user triggers mousedown to start dragging a cell range
};

// --------------------------------------------------------------------------
export function selectionStartPos(state) {
  return tablePluginKey.getState(state).selectionStartPos;
}

// --------------------------------------------------------------------------
export function clearCellHover(transaction, { preserveDragFrom = false, redraw = true, originalMetas = {} } = {}) {
  const metas = { ...originalMetas };
  if (redraw) metas.redrawRequested = true;
  if (!preserveDragFrom) {
    metas.setColumnSelectDragFrom = CELL_SELECT_INACTIVE;
    metas.setRowSelectDragFrom = CELL_SELECT_INACTIVE;
    metas.setSelectDragTo = CELL_SELECT_INACTIVE;
  }
  metas.setCellToResizePos = CELL_RESIZE_INACTIVE;
  metas.setHoverColumnSelectPos = CELL_SELECT_INACTIVE;
  metas.setHoverRowSelectPos = CELL_SELECT_INACTIVE;
  metas.setSelectionStartPos = META_TABLE_SELECTION_DISABLE;
  metas.stopDragging = true;

  transaction.setMeta(tablePluginKey, metas);
}

// --------------------------------------------------------------------------
export function cellResizeTimeout(view, tableView, pos) {
  if (tableView.resizeHoverPos === pos) {
    view.dispatch(view.state.tr.setMeta(tablePluginKey, { setCellToResizePos: pos }));
  }
}

// --------------------------------------------------------------------------
// `state` is state for the entire PM editor, not just pluginState
const drawDecorations = (redrawCounter, state) => {
  const selectionDecorations = cellSelectionDecorations(state);
  const resizeDecorations = resizeHandleDecorations(state);
  const manyCellDecorations = selectManyCellsDecorations(state);
  const redrawDecorations = redrawDecorationsFromState(redrawCounter, state);

  const allDecorations = selectionDecorations.concat(resizeDecorations).concat(manyCellDecorations).concat(redrawDecorations);
  if (allDecorations.length) {
    return DecorationSet.create(state.doc, allDecorations);
  } else {
    return DecorationSet.empty;
  }
}

// --------------------------------------------------------------------------
const selectManyCellsDecorations = state => {
  const selectDecorations = [];
  const { hoverColumnSelectPos, hoverRowSelectPos } = tablePluginKey.getState(state);
  if (hoverColumnSelectPos !== CELL_SELECT_INACTIVE && hoverColumnSelectPos < state.doc.content.size) {
    const $cell = cellFromPos(state.doc.resolve(hoverColumnSelectPos));
    if ($cell) {
      const $table = tableFromPos($cell);
      selectDecorations.push(Decoration.node($table.pos, $table.pos + $table.nodeAfter.nodeSize, { class: "column-selecting" }));
      forEachColumnCell($table, $cell, $columnCell => {
        selectDecorations.push(Decoration.node($columnCell.pos, $columnCell.pos + $columnCell.nodeAfter.nodeSize, { class: "select-column-hover" }));
      });
    } else {
      // eslint-disable-next-line no-console
      console.error("No cell found at hoverColumnSelectPos", hoverColumnSelectPos);
    }
  }
  if (hoverRowSelectPos !== CELL_SELECT_INACTIVE && hoverRowSelectPos < state.doc.content.size) {
    const $row = rowFromPos(state.doc.resolve(hoverRowSelectPos));
    if ($row) {
      const $table = tableFromPos($row);
      selectDecorations.push(Decoration.node($table.pos, $table.pos + $table.nodeAfter.nodeSize, { class: "row-selecting" }));
      selectDecorations.push(Decoration.node($row.pos, $row.pos + $row.nodeAfter.nodeSize, { class: "select-row-hover" }));
    } else {
      // eslint-disable-next-line no-console
      console.error("No row found at hoverRowSelectPos", hoverRowSelectPos);
    }
  }

  return selectDecorations;
}

// --------------------------------------------------------------------------
const redrawDecorationsFromState = (redrawCounter, state) => {
  const { dismissSelectionPos, pendingRedrawPosArray, redrawAt, redrawsRequested } = tablePluginKey.getState(state);
  const decorations = [];

  if (redrawsRequested > redrawCounter.complete) {
    const decorationSpec = { redrawCounter: redrawsRequested };
    const $redrawPos = Number.isInteger(redrawAt) ? state.doc.resolve(redrawAt) : state.selection.$head
    const $table = tableFromPos($redrawPos);
    if (!$table) return decorations;
    forEachTableCell($table, $cell => {
      decorations.push(Decoration.node($cell.pos, $cell.pos + $cell.nodeAfter.nodeSize, {}, decorationSpec));
    });
    redrawCounter.complete = redrawsRequested;
  } else if (pendingRedrawPosArray && pendingRedrawPosArray.length) {
    const time = new Date().getTime();
    pendingRedrawPosArray.forEach(redrawPos => {
      const $cell = state.doc.resolve(redrawPos);
      if (!$cell || !$cell.nodeAfter || $cell.nodeAfter.type.spec.tableRole !== "cell") return;
      // redrawRequestedAt forces the cell at redrawPos to redraw, incorporating changes from its dependent cells
      const cellRedrawDeco = Decoration.node($cell.pos, $cell.pos + $cell.nodeAfter.nodeSize, {}, { redrawRequestedAt: time });
      decorations.push(cellRedrawDeco);
    });
  }

  if (Number.isInteger(dismissSelectionPos)) {
    const $table = tableFromPos(state.doc.resolve(dismissSelectionPos))
    if (!$table) return decorations;
    const redrawTableDeco = Decoration.node($table.pos, $table.pos + $table.nodeAfter.nodeSize, {}, { dismissSelectionPos });
    decorations.push(redrawTableDeco);
  }

  return decorations;
}

// --------------------------------------------------------------------------
const cellSelectionDecorations = state => {
  const selectionNode = state.selection.$head && state.selection.$head.node(TABLE_DEPTH);
  const cellDecorations = [];
  if (selectionNode && selectionNode.type.spec.tableRole === "table") {
    const tableStartPos = state.selection.$head.before(TABLE_DEPTH);
    cellDecorations.push(Decoration.node(tableStartPos, tableStartPos + selectionNode.nodeSize, { class: "cursor-within" }));
  }
  if (state.selection instanceof CellSelection) {
    state.selection.forEachCell((node, pos) => {
      cellDecorations.push(Decoration.node(pos, pos + node.nodeSize, { class: "selected-cell" }));
    });
  }
  return cellDecorations;
}

// --------------------------------------------------------------------------
// Return an array of decorations pertaining to cell resizing
const resizeHandleDecorations = state => {
  const pluginState = tablePluginKey.getState(state)
  if (!pluginState || pluginState.cellToResizePos === CELL_RESIZE_INACTIVE) return [];
  const cellToResizePos = pluginState.cellToResizePos;
  const decorations = [];
  const $cell = state.doc.resolve(cellToResizePos);
  const $table = tableFromPos($cell);
  if (!$table) return decorations;

  const tableNode = $table.nodeAfter;

  const resizeDecoration = Decoration.node($table.pos, $table.pos + tableNode.nodeSize, { class: "resize-cursor" });
  decorations.push(resizeDecoration);

  forEachColumnCell($table, $cell, $columnCell => {
    const deco = Decoration.node($columnCell.pos, $columnCell.pos + $columnCell.nodeAfter.nodeSize, { class: "highlight-right-border" });
    decorations.push(deco);
  })

  return decorations;
}

// --------------------------------------------------------------------------
const pluginStateFromMetaAction = (action, pluginState) => {
  // Setup plugin state for cell selection
  const newPluginState = { ...pluginState };

  if (action.setSelectionStartPos) {
    if (action.setSelectionStartPos === META_TABLE_SELECTION_DISABLE) {
      newPluginState.selectionStartPos = null;
    } else if (action.setSelectionStartPos) {
      newPluginState.selectionStartPos = action.setSelectionStartPos;
    }
  }

  if ("leftView" in action) {
    newPluginState.hoverColumnSelectPos = CELL_SELECT_INACTIVE;
    newPluginState.hoverRowSelectPos = CELL_SELECT_INACTIVE;
  }
  if ("setColumnSelectDragFrom" in action) newPluginState.columnSelectDragFrom = action.setColumnSelectDragFrom;
  if ("setRowSelectDragFrom" in action) newPluginState.rowSelectDragFrom = action.setRowSelectDragFrom;
  if ("setHoverRowSelectPos" in action) newPluginState.hoverRowSelectPos = action.setHoverRowSelectPos;
  if ("setHoverColumnSelectPos" in action) newPluginState.hoverColumnSelectPos = action.setHoverColumnSelectPos;
  if ("redrawRequested" in action) {
    newPluginState.redrawsRequested += 1;
    if (Number.isInteger(action.redrawAt)) newPluginState.redrawAt = action.redrawAt;
  }
  if ("setDragging" in action) newPluginState.dragging = action.setDragging;
  if ("stopDragging" in action) newPluginState.dragging = false;
  if ("setCellToResizePos" in action) newPluginState.cellToResizePos = action.setCellToResizePos;
  if ("setSelectDragTo" in action) newPluginState.selectDragTo = action.setSelectDragTo;
  if ("setDismissSelectionPos" in action) newPluginState.dismissSelectionPos = action.setDismissSelectionPos;
  if ("setRedrawPosArray" in action) newPluginState.pendingRedrawPosArray = action.setRedrawPosArray;

   return newPluginState;
}

// --------------------------------------------------------------------------
// tablePlugin concerns itself with three separate functionalities:
//
// 1) Draw decorations to show where table CellSelection is happening
// 2) Setup mouse events & decorations to enable column resizing
// 3) Apply decorations that show column & row indexes for each cell
//
// Per PM, "You should probably put this plugin near the end of your array of plugins, since it handles
// mouse and arrow key events in tables rather broadly, and other plugins, like the gap cursor or the
// column-width dragging plugin, might want to get a turn first to perform more specific behavior."
const createTablePlugin = () => {
  const redrawCounter = { complete: 0 };

  return new Plugin({
    // --------------------------------------------------------------------------
    key: tablePluginKey,

    // --------------------------------------------------------------------------
    props: {
      decorations: drawDecorations.bind(null, redrawCounter),

      handleDOMEvents: {
        mousedown: onTableMouseDown,
        mouseleave: onTablePropMouseLeave,
      },

      handleKeyDown,
      handlePaste,
    },

    // --------------------------------------------------------------------------
    state: {
      init: (_config, _state) => TABLE_PLUGIN_INITIAL_STATE,

      // --------------------------------------------------------------------------
      apply: (tr, pluginState, oldState, state) => {
        const oldPluginState = tablePluginKey.getState(oldState);
        let newPluginState = { ...pluginState };
        if (oldPluginState && oldPluginState.pendingRedrawPosArray && oldPluginState.pendingRedrawPosArray.length &&
            pluginState.pendingRedrawPosArray && pluginState.pendingRedrawPosArray.length) {
          newPluginState.pendingRedrawPosArray = [];// setMeta can repopulate this if there are new cells pending redraw. Otherwise this should be reset with each state.apply
        }

        if (tr.getMeta(tablePluginKey)) {
          newPluginState = pluginStateFromMetaAction(tr.getMeta(tablePluginKey), newPluginState);
        }

        const { selection } = state;
        if (Number.isInteger(newPluginState.dismissSelectionPos) && !(selection instanceof CellSelection)) {
          newPluginState.dismissSelectionPos = null;
        }

        if (tr.docChanged) {
          // Ensure that our dependent cell positions adapt as the doc does
          const mapping = tr.mapping;
          if (pluginState.cellToResizePos !== CELL_RESIZE_INACTIVE) newPluginState.cellToResizePos = mapping.map(pluginState.cellToResizePos);
          if (pluginState.columnSelectDragFrom !== CELL_SELECT_INACTIVE) newPluginState.columnSelectDragFrom = mapping.map(pluginState.columnSelectDragFrom);
          if (pluginState.hoverColumnSelectPos !== CELL_SELECT_INACTIVE) newPluginState.hoverColumnSelectPos = mapping.map(pluginState.hoverColumnSelectPos);
          if (pluginState.hoverRowSelectPos !== CELL_SELECT_INACTIVE) newPluginState.hoverRowSelectPos = mapping.map(pluginState.hoverRowSelectPos);
          if (pluginState.rowSelectDragFrom !== CELL_SELECT_INACTIVE) newPluginState.rowSelectDragFrom = mapping.map(pluginState.rowSelectDragFrom);
          if (pluginState.selectDragTo !== CELL_SELECT_INACTIVE) newPluginState.selectDragTo = mapping.map(pluginState.selectDragTo);
          if (Number.isInteger(selectionStartPos)) newPluginState.selectionStartPos = mapping.map(pluginState.selectionStartPos);
        }

        const $table = tableFromPos(state.selection.$head);
        if ($table && ($table.pos !== newPluginState.calculatedDependentsAtPos || newPluginState.redrawsRequested > redrawCounter.complete)) {
          newPluginState.calculatedDependentsAtPos = $table.pos;
          newPluginState.formulaDependentCells = formulaDependenciesFromTable($table);
        } else if (!$table) {
          newPluginState.calculatedDependentsAtPos = null;
          newPluginState.formulaDependentCells = {};
        }

        if (dequal(newPluginState, pluginState)) {
          return pluginState;
        } else {
          return newPluginState;
        }
      },
    },

    // --------------------------------------------------------------------------
    // Inlined from pm-tables `normalizeSelection`
    appendTransaction: (transactions, oldState, state) => {
      let tr = fixTables(state, oldState);
      const sel = (tr || state).selection;
      const doc = (tr || state).doc;
      let normalize, role;

      if (sel instanceof NodeSelection && (role = sel.node.type.spec.tableRole)) {
        if (role === "cell") {
          normalize = CellSelection.create(doc, sel.from);
        } else if (role === "row") {
          const $cell = doc.resolve(sel.from + 1);
          normalize = CellSelection.rowSelection($cell, $cell);
        }
      } else if (sel instanceof TextSelection && isTextSelectionAcrossCells(sel)) {
        // Text selection across cells should normally be impossible, but mobile browsers can allow it,
        // and insertRowAbove leaves selection in this state as of Dec 2022. This moves cursor to start of row instead
        normalize = TextSelection.create(doc, sel.$from.start(), sel.$from.end());
      }

      tr = setRedrawIfFormulaDependentsChanged(state, oldState, transactions, tr);

      if (normalize) {
        (tr || (tr = state.tr)).setSelection(normalize);
      }
      return tr;
    },
  });
}
export default createTablePlugin;

import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

import AddCompletedTaskStep from "./add-completed-task-step"

// --------------------------------------------------------------------------
export default class RemoveCompletedTaskStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    return new RemoveCompletedTaskStep(schema, json.task)
  }

  // --------------------------------------------------------------------------
  constructor(schema, task) {
    super();

    this.schema = schema;
    this.task = task;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const { uuid: uuidToRemove } = this.task;

    if (uuidToRemove) {
      const serializedDoc = doc.toJSON();

      const completedTasks = (serializedDoc.attrs.completedTasks || []).filter(({ uuid }) => uuid !== uuidToRemove);
      serializedDoc.attrs = { ...serializedDoc.attrs, completedTasks };

      doc = Node.fromJSON(this.schema, serializedDoc);
    }

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    return new AddCompletedTaskStep(this.schema, this.task);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      stepType: "removeCompletedTask",
      task: this.task,
    };
  }
}
Step.jsonID("removeCompletedTask", RemoveCompletedTaskStep);

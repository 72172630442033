// --------------------------------------------------------------------------
// Utility function for working with the "person" shape (extracting data
// from it consistently, etc)
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function avatarTextFromPerson(person) {
  if (person) {
    const { email, name } = person;
    return (name || email || "a").substr(0, 1).toUpperCase();
  } else {
    return "";
  }
}

// --------------------------------------------------------------------------
export function displayNameFromPerson(person) {
  if (person) {
    const { email, name } = person;
    return name || email || "Amplenote user";
  } else {
    return "Unknown user";
  }
}

// --------------------------------------------------------------------------
export function personFromUUID(personByUUID, uuid) {
  return (personByUUID ? personByUUID[uuid] : null) || { uuid };
}

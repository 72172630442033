import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

import RemoveCompletedTaskStep from "./remove-completed-task-step"

// --------------------------------------------------------------------------
export default class AddCompletedTaskStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    return new AddCompletedTaskStep(schema, json.task);
  }

  // --------------------------------------------------------------------------
  constructor(schema, task) {
    super();

    this.schema = schema;
    this.task = task;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const completedTasks = [ ...(doc.attrs.completedTasks || []), this.task ];

    const serializedDoc = doc.toJSON();
    serializedDoc.attrs = { ...serializedDoc.attrs, completedTasks };

    doc = Node.fromJSON(this.schema, serializedDoc);

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    return new RemoveCompletedTaskStep(this.schema, this.task);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      stepType: "addCompletedTask",
      task: this.task,
    };
  }
}
Step.jsonID("addCompletedTask", AddCompletedTaskStep);

import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
const checkListItemPluginKey = new PluginKey("check-list-item");

// --------------------------------------------------------------------------
function applySelectedTaskUUIDState(state, tr, pluginState) {
  // ProseMirror doesn't copy the meta over to transactions that are appended via plugins (e.g. the tasks-plugin
  // setting the uuid on new tasks), but it does store it as a meta
  const rootTransaction = tr.getMeta(TRANSACTION_META_KEY.APPENDED_TRANSACTION) || tr;
  const hasFocus = rootTransaction.getMeta(TRANSACTION_META_KEY.HAS_FOCUS) || false;

  // We don't want to show the selected node on mobile before the user actually taps to focus the editor, as it's
  // confusing if this happens without their interaction. Note that the editor may have _just_ lost focus if the user
  // had the cursor in a task and pressed the checkbox to complete it, so if there's already a selected task, we may
  // as well select a new task (if necessary)
  if (hasFocus || pluginState.selectedTaskUUID) {
    const selectedTaskUUID = findSelectedTaskUUID(state);
    if (selectedTaskUUID !== pluginState.selectedTaskUUID) {
      const decorationCounterByUUID = { ...pluginState.decorationCounterByUUID };

      incrementDecorationCounter(decorationCounterByUUID, selectedTaskUUID);
      incrementDecorationCounter(decorationCounterByUUID, pluginState.selectedTaskUUID);

      return { ...pluginState, decorationCounterByUUID, selectedTaskUUID };
    }
  }

  return pluginState;
}

// --------------------------------------------------------------------------
function decorationsFromDecorationCounterByUUID(schema, parentNode, parentPos, decorationCounterByUUID) {
  const decorations = [];

  parentNode.forEach((node, nodePos) => {
    if (node.type === schema.nodes.bullet_list_item || node.type === schema.nodes.check_list_item) {
      const { uuid } = node.attrs;

      if (uuid && uuid in decorationCounterByUUID) {
        const decorationCounter = decorationCounterByUUID[uuid];
        decorations.push(
          Decoration.node(parentPos + nodePos, parentPos + nodePos + node.nodeSize, {}, { decorationCounter })
        );
      }
    } else if (node.type === schema.nodes.tasks_group) {
      // This is only valid in tasksSchema documents, where check_list_item nodes are nested at the second level
      // of the document instead of only showing up at the top level
      decorations.push(...decorationsFromDecorationCounterByUUID(schema, node, nodePos + 1, decorationCounterByUUID));
    }
  });

  return decorations;
}

// --------------------------------------------------------------------------
function findSelectedTaskUUID(state) {
  const { selection: { $head } } = state;

  for (let depth = $head.depth; depth > 0; depth--) {
    const node = $head.node(depth);
    if (node && node.type.name === "check_list_item") {
      return node.attrs.uuid;
    }
  }

  return null;
}

// --------------------------------------------------------------------------
export function getCheckListItemPluginState(state) {
  return checkListItemPluginKey.getState(state) || initialPluginState;
}

// --------------------------------------------------------------------------
export function getExpandedTaskUUID(state) {
  return getCheckListItemPluginState(state).expandedTaskUUID;
}

// --------------------------------------------------------------------------
export function getHighlightedTaskUUID(state) {
  return getCheckListItemPluginState(state).highlightedTaskUUID;
}

// --------------------------------------------------------------------------
export function getQuickAdjustPopup(state) {
  return getCheckListItemPluginState(state).quickAdjustPopup;
}

// --------------------------------------------------------------------------
export function getSelectedTaskUUID(state) {
  return getCheckListItemPluginState(state).selectedTaskUUID;
}

// --------------------------------------------------------------------------
export function getTransactionExpandedTaskUUID(transaction) {
  const meta = transaction.getMeta(checkListItemPluginKey) || {};
  return meta.expandedTaskUUID;
}

// --------------------------------------------------------------------------
export function getTransactionSetsExpandedTaskUUID(transaction) {
  const meta = transaction.getMeta(checkListItemPluginKey) || {};
  return "expandedTaskUUID" in meta;
}

// --------------------------------------------------------------------------
// This allows us to create a new decoration (and force a node view to update) even if the node already had a
// decoration on it (assuming this counter is used in the decoration spec).
function incrementDecorationCounter(decorationCounterByUUID, uuid) {
  if (!uuid) return;

  decorationCounterByUUID[uuid] = (decorationCounterByUUID[uuid] || 0) + 1;
}

// --------------------------------------------------------------------------
export function setExpandedTaskUUID(transaction, expandedTaskUUID) {
  const meta = transaction.getMeta(checkListItemPluginKey) || {};
  return transaction.setMeta(checkListItemPluginKey, { ...meta, expandedTaskUUID });
}

// --------------------------------------------------------------------------
export function setHighlightedTaskUUID(transaction, highlightedTaskUUID) {
  const meta = transaction.getMeta(checkListItemPluginKey) || {};
  return transaction.setMeta(checkListItemPluginKey, { ...meta, highlightedTaskUUID });
}

// --------------------------------------------------------------------------
export function setQuickAdjustPopup(transaction, quickAdjustPopup) {
  const meta = transaction.getMeta(checkListItemPluginKey) || {};
  return transaction.setMeta(checkListItemPluginKey, { ...meta, quickAdjustPopup });
}

// --------------------------------------------------------------------------
export function setSelectedTaskUUID(transaction, selectedTaskUUID) {
  const meta = transaction.getMeta(checkListItemPluginKey) || {};
  return transaction.setMeta(checkListItemPluginKey, { ...meta, selectedTaskUUID });
}

// --------------------------------------------------------------------------
const initialPluginState = {
  // check-list-item nodes use node views to render a more complex UI, but node views
  // only re-render when the node changes. Since those nodes both consume the state defined here - in addition to
  // the attributes of their nodes - we need to add a decoration to the node when we change state here that would
  // affect the node, which will update the node view for that node.
  // Note that state.apply can be called multiple times before the NodeViews are updated, so we need to retain any
  // updates through all calls, until the entire update is done.
  decorationCounterByUUID: {},

  // The UUID of the check-list-item we are currently showing the task details for
  expandedTaskUUID: null,

  // The UUID of a check-list-item we want to highlight visually
  highlightedTaskUUID: null,

  // If non-null, indicates which task detail quick adjust popup should be shown, and for which task, in the form
  // of: `{ uuid: "<uuid of check-list-item>", attributeName: "name of task detail attribute to show popup for" }`
  quickAdjustPopup: null,

  // The UUID of the check-list-item that contains the selection
  selectedTaskUUID: null,
};

// --------------------------------------------------------------------------
// This plugin is concerned with interactive features of tasks displayed as check_list_item nodes (including
// actual check_list_item nodes in the document, hidden tasks, and completed tasks), but does not seek to make
// any changes to the document, so it can be used in sub-editors (e.g. TasksEditor) that treat their documents
// as readonly.
// --------------------------------------------------------------------------
const checkListItemPlugin = new Plugin({
  // --------------------------------------------------------------------------
  key: checkListItemPluginKey,

  // --------------------------------------------------------------------------
  props: {
    decorations(state) {
      const { doc, schema } = state;

      const { decorationCounterByUUID } = this.getState(state);
      const decorations = decorationsFromDecorationCounterByUUID(schema, doc, 0, decorationCounterByUUID);

      if (decorations.length > 0) {
        return DecorationSet.create(doc, decorations);
      } else {
        return DecorationSet.empty;
      }
    },
  },

  // --------------------------------------------------------------------------
  state: {
    init: (_config, _state) => initialPluginState,
    apply: (tr, pluginState, _oldState, state) => {
      const meta = tr.getMeta(checkListItemPluginKey);
      if (meta) {
        let {
          decorationCounterByUUID,
          expandedTaskUUID,
          highlightedTaskUUID,
          quickAdjustPopup,
          selectedTaskUUID,
        } = pluginState;

        if ("expandedTaskUUID" in meta) {
          expandedTaskUUID = meta.expandedTaskUUID;

          if (!("highlightedTaskUUID" in meta) && highlightedTaskUUID && highlightedTaskUUID === meta.expandedTaskUUID) {
            highlightedTaskUUID = null;
          }
        }
        if ("highlightedTaskUUID" in meta) highlightedTaskUUID = meta.highlightedTaskUUID;
        if ("quickAdjustPopup" in meta) quickAdjustPopup = meta.quickAdjustPopup;

        decorationCounterByUUID = { ...decorationCounterByUUID };

        if (expandedTaskUUID !== pluginState.expandedTaskUUID) {
          incrementDecorationCounter(decorationCounterByUUID, expandedTaskUUID);
          incrementDecorationCounter(decorationCounterByUUID, pluginState.expandedTaskUUID);
        }

        if (highlightedTaskUUID !== pluginState.highlightedTaskUUID) {
          incrementDecorationCounter(decorationCounterByUUID, highlightedTaskUUID);
          incrementDecorationCounter(decorationCounterByUUID, pluginState.highlightedTaskUUID);
        }

        if (quickAdjustPopup !== pluginState.quickAdjustPopup) {
          const newUUID = quickAdjustPopup ? quickAdjustPopup.uuid : null;
          const oldUUID = pluginState.quickAdjustPopup ? pluginState.quickAdjustPopup.uuid : null;

          incrementDecorationCounter(decorationCounterByUUID, oldUUID);
          incrementDecorationCounter(decorationCounterByUUID, newUUID);
        }

        const newPluginState = {
          decorationCounterByUUID,
          expandedTaskUUID,
          highlightedTaskUUID,
          quickAdjustPopup,
          selectedTaskUUID,
        };

        if ("selectedTaskUUID" in meta) {
          selectedTaskUUID = meta.selectedTaskUUID;

          if (selectedTaskUUID !== newPluginState.selectedTaskUUID) {
            decorationCounterByUUID = { ...newPluginState.decorationCounterByUUID };

            incrementDecorationCounter(decorationCounterByUUID, selectedTaskUUID);
            incrementDecorationCounter(decorationCounterByUUID, newPluginState.selectedTaskUUID);

            newPluginState.decorationCounterByUUID = decorationCounterByUUID;
            newPluginState.selectedTaskUUID = selectedTaskUUID;
          }

          return newPluginState;
        } else {
          return applySelectedTaskUUIDState(state, tr, newPluginState);
        }
      }

      return applySelectedTaskUUIDState(state, tr, pluginState);
    },
    toJSON: pluginState => pluginState,
    fromJSON: (_config, pluginState) => pluginState,
  },
});
export default checkListItemPlugin;

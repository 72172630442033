import { dequal } from "dequal/lite"
import { debounce, throttle } from "lodash"
import { CellSelection, selectedRect } from "prosemirror-tables"
import React from "react"
import ReactDOM from "react-dom"

import CellSelectionMenu from "lib/ample-editor/components/cell-selection-menu"
import { parentsOffsetFromChildDom } from "lib/ample-editor/lib/popup-util"
import {
  clearCellFormatting,
  escapeCellSelectionMenu,
  setCellAttrWithBorder,
  resizeColumnToEstimatedContentWidth
} from "lib/ample-editor/lib/table/table-commands"
import {
  CELL_MIN_WIDTH,
  LARGEST_SUBMENU_VERTICAL_PX,
  MOUSE_MOVE_DEBOUNCE_INTERVAL,
  TABLE_SELECTION_CONTAINER_CLASS
} from "lib/ample-editor/lib/table/table-constants"
import { onTableMouseLeave, onTableMouseMove, tableTouchSelect } from "lib/ample-editor/lib/table/table-mouse-movement"
import { expandCellSelectionRange, selectionAttrs, upperRightCellPos } from "lib/ample-editor/lib/table/table-selection"
import { cellFromTable, dismissCellSelectionMenuPos, tableFromPos } from "lib/ample-editor/lib/table/table-util"

// --------------------------------------------------------------------------
const POPUP_BOTTOM_MARGIN = 4; // px
const MAX_ALLOWABLE_TOUCH_MOVE_DISTANCE = 4;

// --------------------------------------------------------------------------
// Returns integer sum of column widths
function minWidthFromTableNode(tableNode) {
  let tableMinWidth = 0;
  const row = tableNode.firstChild;

  row.forEach(cellNode => {
    const columnWidth = cellNode.attrs.colwidth;
    tableMinWidth += columnWidth || CELL_MIN_WIDTH;
  });

  return tableMinWidth;
}

// --------------------------------------------------------------------------
// Returns $pos of upper right cell, used as default target for where to pop cell selection menu
function popCellFromView(view) {
  const { state: { selection } } = view;
  if (!(selection instanceof CellSelection)) return null;
  const $table = tableFromPos(selection.$from);
  if (!$table) return null;
  const rect = selectedRect(view.state);
  if (!rect) return null;
  return cellFromTable($table, rect.top, rect.right - 1);
}

// --------------------------------------------------------------------------
function isDestinationCellInScrollView(view) {
  const $cell = popCellFromView(view);
  if (!view.docView || !view.dom) return false; // During hot reload, view.docView is unavailable and causes infinite exception loop trying to call coordsAtPos
  const domCell = view.nodeDOM($cell.pos);
  // Valid domCell & view.dom `Element`s are necessary to calculate whether destination in scroll view
  if (!domCell || !domCell.getBoundingClientRect || !view.dom.getBoundingClientRect) return false;

  const editorBoundaries = view.dom.getBoundingClientRect();
  const domBoundaries = domCell.getBoundingClientRect();
  return editorBoundaries.left < domBoundaries.left && editorBoundaries.right > domBoundaries.left;
}

// --------------------------------------------------------------------------
function tableWrapperOffsetsFromDom(tableDom) {
  const { parentsLeftOffset, parentsTopOffset } = parentsOffsetFromChildDom(tableDom, TABLE_SELECTION_CONTAINER_CLASS);

  // For browsers that aren't iOS Safari, tableBounding's `top` is relative to the viewport, and will thus be negative
  // if scrolling has occurred, allowing us to calculate parentScrollTop to ensure that CSM is positioned onscreen. For
  // iOS Safari, tableBounding.top is always 0, and as best WBH can tell, all dom elements consider themselves
  // scrollTop 0 always. In a future version, we might architect an alternate calculation to get scrollTop for iOS Safari
  const tableBounding = tableDom.getBoundingClientRect();
  const parentsScrollTop = parentsTopOffset + tableDom.offsetTop - tableBounding.top;

  return { parentsLeftOffset, parentsScrollTop, parentsTopOffset };
}

// --------------------------------------------------------------------------
export default class TableView {
  _cellSelectionMenuContainer = null;
  _cellSelectionMenuMounted = false;
  _onTableMouseLeave = null;
  _onTableMouseMove = null;
  _renderedSelectionAttrs = null;
  _renderedFormatCanBeCleared = false;
  _resizeTimer = null;
  _selectionRectangle = null;
  _selectionFormatCanBeCleared = false;
  _startTouch = null;
  _table = null;

  // --------------------------------------------------------------------------
  constructor(node, view, getPos) {
    this.update = this.update.bind(this, view, getPos);
    this.resizeHoverPos = null;
    this._view = view;

    // Construct visual table
    this.dom = document.createElement("div");
    this.dom.className = "table-wrapper";
    const existingSelectionMenuContainer = view.dom.parentNode.querySelector(`.${ TABLE_SELECTION_CONTAINER_CLASS }`);
    if (existingSelectionMenuContainer) {
      this._cellSelectionMenuContainer = existingSelectionMenuContainer;
    } else {
      this._ensureCellSelectionMenuContainer();
    }
    this._table = this.dom.appendChild(document.createElement("table"));
    this.contentDOM = this._table.appendChild(document.createElement("tbody"));

    this.update(node);

    this._onTableMouseLeave = throttle(onTableMouseLeave.bind(null, view), MOUSE_MOVE_DEBOUNCE_INTERVAL);
    this._onTableMouseMove = throttle(onTableMouseMove.bind(null, this, view, getPos), MOUSE_MOVE_DEBOUNCE_INTERVAL);
    this._onTouchEnd = this._onTouchEnd.bind(this, view, getPos);
    this._onTouchStart = this._onTouchStart.bind(this, view);
    this.dom.addEventListener("mouseleave", this._onTableMouseLeave);
    this.dom.addEventListener("mousemove", this._onTableMouseMove);
    this.dom.addEventListener("scroll", this._onTableScroll);
    this.dom.addEventListener("touchend", this._onTouchEnd);
    this.dom.addEventListener("touchstart", this._onTouchStart);
    window.addEventListener("resize", this._onWindowResize);
  }

  // --------------------------------------------------------------------------
  update(view, getPos, node) {
    if (node.type.spec.tableRole !== "table") return false;
    const tableMinWidth = minWidthFromTableNode(node);
    this._table.style.minWidth = `${ tableMinWidth }px`;

    if (node.attrs.fullWidth && !this._table.classList.contains("full-width")) {
      this._table.classList.add("full-width");
    } else if (!node.attrs.fullWidth && this._table.classList.contains("full-width")) {
      this._table.classList.remove("full-width");
    }

    const { state: { selection } } = view;

    if (selection instanceof CellSelection) {
      // In case the destruction of a different TableView (or a hot reload?) clears our cellSelectionMenuContainer, restore it
      if (!this._cellSelectionMenuContainer || !this._cellSelectionMenuContainer.offsetParent) {
        this._ensureCellSelectionMenuContainer();
      }
      let componentRendered = false;
      const rect = selectedRect(view.state);
      const cellSelectionAttrs = selectionAttrs(view.state);
      this._selectionFormatCanBeCleared = clearCellFormatting(view.state, null);
      const selectionMenuHiddenAtAnchor = dismissCellSelectionMenuPos(view.state) === selection.$anchorCell.pos;
      // dequal checks deep isEqual equality between objects
      const selectionAttrsChanged = !dequal(cellSelectionAttrs, this._renderedSelectionAttrs);
      const formatClearChanged = this._selectionFormatCanBeCleared !== this._renderedFormatCanBeCleared;
      if (!this._cellSelectionMenuMounted || selectionAttrsChanged || formatClearChanged) {
        this._renderCellSelectionComponent(cellSelectionAttrs, rect);
        componentRendered = true;

        // If destination isn't yet in view, a scroll will occur and we will render at that point, skipping a one-render
        // pop if we positioned the menu at this point
        if ((isDestinationCellInScrollView(view, this.dom) || selection.isRowSelection() || selection.isColSelection()) &&
            !selectionMenuHiddenAtAnchor) {
          this._positionSelectionMenuContainer(view, getPos);
        }
      } else if (selectionMenuHiddenAtAnchor) {
        const component = this._cellSelectionMenuContainer.querySelector(".cell-selection-menu");
        if (component) {
          this._unmountCellSelectionMenu();
        }
      } else if (rect.top !== this._selectionRectangle.top || rect.right !== this._selectionRectangle.right) {
        if (isDestinationCellInScrollView(view, this.dom) || selection.isRowSelection()) {
          this._positionSelectionMenuContainer(view, getPos);
        }
      }

      // If we didn't already re-render for changed selection attrs, ensure we re-render component (with submenus closed) for new selection range
      if (!componentRendered && this._selectionRectangle && !dequal(rect, this._selectionRectangle)) {
        this._renderCellSelectionComponent(cellSelectionAttrs, rect);
      }

      this._selectionRectangle = rect;
    } else if (this._cellSelectionMenuMounted) {
      this._unmountCellSelectionMenu();
    }

    return true;
  }

  // --------------------------------------------------------------------------
  destroy() {
    this.dom.removeEventListener("mousemove", this._onTableMouseMove);
    this.dom.removeEventListener("mouseleave", this._onTableMouseLeave);
    this.dom.removeEventListener("touchend", this._onTouchEnd);
    this.dom.removeEventListener("touchstart", this._onTouchStart);
    this.dom.removeEventListener("scroll", this._onTableScroll);
    window.removeEventListener("resize", this._onWindowResize);
    if (this._resizeTimer) {
      clearTimeout(this._resizeTimer);
      this._resizeTimer = null;
    }

    this._unmountCellSelectionMenu(true);
  }

  // --------------------------------------------------------------------------
  // Inherited from PM-tables without WBH review of necessity
  ignoreMutation(record) {
    return (record.type === "attributes" && (record.target === this._table));
  }

  // --------------------------------------------------------------------------
  setResizeHoverPos(pos) {
    this.resizeHoverPos = pos;
  }

  // --------------------------------------------------------------------------
  _onWindowResize = debounce(() => {
    const { state } = this._view;
    if (this._cellSelectionMenuMounted && !this._isSelectionMenuDismissedAtPos(state)) {
      const { selection: { from } } = state;
      this._positionSelectionMenuContainer(this._view, from);
    }
  }, 150)

  // --------------------------------------------------------------------------
  _onTableScroll = debounce(() => {
    const { state } = this._view;
    if (this._cellSelectionMenuMounted && !this._isSelectionMenuDismissedAtPos(state)) {
      const { selection: { from } } = state;
      this._positionSelectionMenuContainer(this._view, from);
    }
  }, 150)

  // --------------------------------------------------------------------------
  _renderCellSelectionComponent(cellSelectionAttrs, selectionRect) {
    const renderedExtents = (({ bottom, left, right, top }) => ({ bottom, left, right, top }))(selectionRect);

    const cellPos = upperRightCellPos(selectionRect);
    let submenuAbove = false;
    if (this._view.docView) {
      const coordsAtPos = this._view.coordsAtPos(cellPos);
      submenuAbove = coordsAtPos.top > LARGEST_SUBMENU_VERTICAL_PX;
    }

    ReactDOM.render(
      <CellSelectionMenu
        { ...cellSelectionAttrs }
        apply={ this._applyToCellSelection }
        clearFormatting={ this._selectionFormatCanBeCleared ? this._clearFormatting : null }
        dismiss={ this._dismissCellSelectionMenu }
        expandSelection={ this._expandSelection }
        parentContainer={ this._cellSelectionMenuContainer }
        renderedExtents={ renderedExtents }
        resizeColumnToContentWidth={ this._resizeColumnToContentWidth }
        submenuPositionAbove={ submenuAbove }
      />,
      this._cellSelectionMenuContainer,
    );
    this._renderedFormatCanBeCleared = this._selectionFormatCanBeCleared;
    this._renderedSelectionAttrs = cellSelectionAttrs;
    this._cellSelectionMenuMounted = true;
  }

  // --------------------------------------------------------------------------
  // Deduce a position at which the CellSelectionMenu can be placed without being clipped by the viewport
  // Preferably popping the initial menu and its submenus above cell selection, but falling back to other options if necessary
  _positionSelectionMenuContainer = (view, _getPos) => {
    const container = this._cellSelectionMenuContainer;

    // This is only expected in tests, where the DOM is simulated
    if (!container.offsetParent || !view) return;
    if (!view.docView) return; // view.coordsAtPos throws exception if view.docView is unavailable
    const $upperRightCell = popCellFromView(view);
    if (!$upperRightCell) return;
    const upperRightCellDom = view.nodeDOM($upperRightCell.pos);
    if (!upperRightCellDom) return;

    const component = container.querySelector(".cell-selection-menu");
    if (component) {
      component.classList.add("visible");
      const componentContentHeight = component.offsetHeight;

      // this.dom is table-wrapper, which has its width set to 100%
      const { parentsLeftOffset, parentsScrollTop, parentsTopOffset } = tableWrapperOffsetsFromDom(this.dom);

      let innerTop;
      const desiredTop = upperRightCellDom.offsetTop - componentContentHeight - POPUP_BOTTOM_MARGIN;
      if (desiredTop >= 0) {
        innerTop = desiredTop;
      } else {
        const desiredBottom = upperRightCellDom.offsetTop + upperRightCellDom.offsetHeight + POPUP_BOTTOM_MARGIN;
        if (desiredBottom + componentContentHeight <= view.dom.clientHeight) {
          innerTop = desiredBottom;
        } else {
          innerTop = 0;
        }
      }
      let finalTop;

      if (innerTop + parentsTopOffset > parentsScrollTop) {
        finalTop = innerTop + parentsTopOffset;
      } else {
        const editorDom = view.dom.closest(".ample-editor");
        const toolbarDom = editorDom.querySelector(".toolbar-wrapper");
        const bodyDom = view.dom.closest("body");
        const headerDom = bodyDom ? bodyDom.querySelector("header") : null;

        finalTop = parentsScrollTop + (toolbarDom ? toolbarDom.offsetHeight : 0) + (headerDom ? headerDom.offsetHeight : 0);
      }

      const innerLeftMax = this.dom.offsetWidth + this.dom.offsetLeft - component.offsetWidth;
      const leftMax = innerLeftMax + parentsLeftOffset;
      const leftMin = this.dom.offsetLeft + parentsLeftOffset;
      const leftOfTargetCell = parentsLeftOffset + upperRightCellDom.offsetLeft + upperRightCellDom.offsetWidth -
        component.offsetWidth - this.dom.scrollLeft
      const left = Math.max(Math.min(leftMax, leftOfTargetCell), leftMin);

      if (Math.abs(leftMax - left) <= 1) {
        component.classList.add("right-aligned");
      } else {
        component.classList.remove("right-aligned");
      }
      component.style.left = `${ Math.floor(left) }px`;
      component.style.top = `${ Math.floor(finalTop) }px`;
    }
  }

  // --------------------------------------------------------------------------
  _isSelectionMenuDismissedAtPos = state => {
    if (!(state.selection instanceof CellSelection)) return false;
    const dismissPos = dismissCellSelectionMenuPos(state);
    if (!Number.isInteger(dismissPos)) return false;
    if (!state.selection.$anchorCell) return false;
    return dismissPos === state.selection.$anchorCell.pos;
  }

  // --------------------------------------------------------------------------
  _applyToCellSelection = (name, value, borderOnly = false) => {
    return setCellAttrWithBorder(name, value, borderOnly)(this._view.state, this._view.dispatch);
  }

  // --------------------------------------------------------------------------
  _resizeColumnToContentWidth = () => {
    resizeColumnToEstimatedContentWidth(this._view.state, this._view.dispatch);
  }

  // --------------------------------------------------------------------------
  _expandSelection = cellSelectionDirection => {
    // React will be in the process of ensuring submenu isn't rendering when this is called, but
    // in a race to see whether the submenu is hidden before we choose the correct horizontal
    // position for cell selection, this will ensure that the menu is gone by the time we reposition CellSelectionMenu
    this._cellSelectionMenuContainer.querySelector(".sub-menu").classList.remove("visible");
    expandCellSelectionRange(cellSelectionDirection)(this._view.state, this._view.dispatch);
  }

  // --------------------------------------------------------------------------
  _dismissCellSelectionMenu = () => {
    escapeCellSelectionMenu(this._view.state, this._view.dispatch);
  }

  // --------------------------------------------------------------------------
  _clearFormatting = () => {
    clearCellFormatting(this._view.state, this._view.dispatch);
  }

  // --------------------------------------------------------------------------
  _unmountCellSelectionMenu = (removeSelectionMenu = false) => {
    ReactDOM.unmountComponentAtNode(this._cellSelectionMenuContainer);
    this._cellSelectionMenuMounted = false;
    if (removeSelectionMenu) {
      this._cellSelectionMenuContainer.remove();
      this._cellSelectionMenuContainer = null;
    }
  }

  // --------------------------------------------------------------------------
  _ensureCellSelectionMenuContainer = () => {
    const container = this._view.dom.parentNode.querySelector(`.${ TABLE_SELECTION_CONTAINER_CLASS }`);
    if (!container) {
      this._cellSelectionMenuContainer = document.createElement("div");
      this._cellSelectionMenuContainer.className = TABLE_SELECTION_CONTAINER_CLASS;
      this._view.dom.parentNode.appendChild(this._cellSelectionMenuContainer);
    } else {
      this._cellSelectionMenuContainer = container;
    }
  }

  // --------------------------------------------------------------------------
  _onTouchStart(view, event) {
    if (!event.touches.length) return false;
    const touch = event.touches[0];
    const { state: { selection: startSelection } } = view;
    this._startTouch = { pos: startSelection && startSelection.head, x: touch.pageX, y: touch.pageY };
  }

  // --------------------------------------------------------------------------
  _onTouchEnd(view, getPos, event) {
    if (!event.changedTouches.length || !this._startTouch) return;
    const { pageX: x, pageY: y } = event.changedTouches[0];

    if (Math.abs(x - this._startTouch.x) < MAX_ALLOWABLE_TOUCH_MOVE_DISTANCE &&
        Math.abs(y - this._startTouch.y) < MAX_ALLOWABLE_TOUCH_MOVE_DISTANCE) {
      tableTouchSelect(view, getPos(), this._startTouch.pos, event);

      // Upon receiving setting CellSelection, ProseMirror applies some "selection management hacks" in Safari
      // to change selection to TextSelection. This function prevents focus from changing for 50ms, so that the
      // CellSelection that was just applied does not get unset by PM hacks
      view.domObserver.suppressSelectionUpdates();
    }

    this._startTouch = null;
  }
}

// ------------------------------------------------------------------------------
// Plugins are allowed to run code to handle a specific "action", which may have
// some input (and the code they run can prompt for more input). This enumerates
// the types of actions that plugins can handle.
// --------------------------------------------------------------------------
const PLUGIN_ACTION_TYPE = {
  APP_OPTION: "appOption",
  DAILY_JOT_OPTION: "dailyJotOption",
  EVENT_OPTION: "eventOption",
  IMAGE_OPTION: "imageOption",
  INSERT_TEXT: "insertText",
  LINK_OPTION: "linkOption",
  LINK_TARGET: "linkTarget",
  NOTE_OPTION: "noteOption",
  ON_EMBED_CALL: "onEmbedCall",
  RENDER_EMBED: "renderEmbed",
  REPLACE_TEXT: "replaceText",
  TASK_OPTION: "taskOption",
};
export default PLUGIN_ACTION_TYPE;

import { Plugin, PluginKey } from "prosemirror-state"

import taskUpdatesFromDocChanges from "./task-updates-from-doc-changes"

// --------------------------------------------------------------------------
// Filters changes to tasks in a tasksSchema/TasksEditor to only allow changes
// to the attributes related to completion, or the `groupId` of the task
// --------------------------------------------------------------------------
const tasksReadonlyContentPlugin = new Plugin({
  // --------------------------------------------------------------------------
  filterTransaction: (transaction, state) => {
    if (transaction.docChanged) {
      const changesByUUID = taskUpdatesFromDocChanges(transaction.doc, state.doc);

      const haveDisallowedChanges = Object.keys(changesByUUID).find(uuid => {
        const { added, completedAt, crossedOutAt, dismissedAt, groupId, ...changes } = changesByUUID[uuid];

        return Object.keys(changes).length > 0;
      });

      if (haveDisallowedChanges) return false;
    }

    return true;
  },

  // --------------------------------------------------------------------------
  key: new PluginKey("tasks-readonly-content"),
});
export default tasksReadonlyContentPlugin;

import { EditorState, Selection } from "prosemirror-state"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
// Applies a _Transaction_ to an existing _Transaction_ - note these are not transforms, but
// transactions that can include more state than transforms
// See https://prosemirror.net/docs/ref/#state.Transaction
const mergeTransaction = (baseTransaction, transaction) => {
  let mergedSuccessfully = true;

  transaction.steps.forEach(step => {
    // There are various reasons a step could fail, mainly centered around unexpected state when the transaction is
    // re-applied. We'd rather fail application than raise an exception here, as this is generally called at a point
    // that isn't recoverable.
    if (baseTransaction.maybeStep(step).failed) {
      mergedSuccessfully = false;
    }
  });

  Object.keys(transaction.meta).forEach(metaKey => {
    if (metaKey === TRANSACTION_META_KEY.ADD_TO_HISTORY) return;

    const metaValue = transaction.getMeta(metaKey);
    baseTransaction.setMeta(metaKey, metaValue);
  });

  // Both transactions must be addToHistory=false for the resulting transaction to retain addToHistory=false
  const addToHistory = baseTransaction.getMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY) !== false ||
    transaction.getMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY) !== false;
  baseTransaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, addToHistory);

  if (transaction.scrolledIntoView) baseTransaction.scrollIntoView();

  if (transaction.selectionSet) {
    const newSelection = Selection.fromJSON(baseTransaction.doc, transaction.selection.toJSON());
    baseTransaction.setSelection(newSelection);
  }

  if (transaction.storedMarksSet) {
    baseTransaction.setStoredMarks(transaction.storedMarks);
  }

  baseTransaction.setTime(transaction.time);

  return mergedSuccessfully;
};

// --------------------------------------------------------------------------
// Handles merging transactions dispatched by multiple commands (functions in the form of `blah(state, dispatch)`) into
// a single transaction, e.g.:
//
//    const { dispatch, state } = editorView;
//
//    const transactionMerger = new TransactionMerger(state);
//
//    transactionMerger.apply(someCommand);
//    transactionMerger.apply(someOtherCommand);
//
//    dispatch(transactionMerger.transaction);
//
export default class TransactionMerger {
  state = null;
  transaction = null;

  // --------------------------------------------------------------------------
  // If a transaction is provided, it's assumed it *has not yet been applied to the state* and it will be applied to
  // the internal state, in addition to serving as the underlying transaction that will be created.
  constructor(state, transaction = null) {
    this.transaction = transaction || state.tr;

    const { doc, schema, selection, storedMarks } = state;

    // We must create a state without plugins, as plugins are intended to operate on a single transaction and can
    // append or filter transactions, decisions we don't have easy access to when creating our combined transaction and
    // which we will let happen again on the final combined transaction when it is dispatched.
    this.state = EditorState.create({ doc, schema, selection, storedMarks });

    // Some transactions might check plugin state, in which case we want it to be accurate, at least at the start, so
    // they can take the appropriate actions based on that state
    Object.keys(state.config.pluginsByKey).forEach(pluginKey => {
      this.state[pluginKey] = state[pluginKey];
    });

    if (transaction) {
      this.state = this.state.apply(transaction);
    }
  }

  // --------------------------------------------------------------------------
  apply(command) {
    return command(this.state, transaction => {
      this.state = this.state.apply(transaction);
      mergeTransaction(this.transaction, transaction);
    });
  }
}

import { Plugin, PluginKey } from "prosemirror-state"

import ListItemCommandsMenuPluginView from "lib/ample-editor/views/list-item-commands-menu-plugin-view"

// --------------------------------------------------------------------------
export default function createListItemCommandsMenuPlugin() {
  const listItemCommandsMenuPluginKey = new PluginKey("list-item-commands");

  let pluginView = null;

  return new Plugin({
    key: listItemCommandsMenuPluginKey,

    props: {
      handleKeyDown: (editorView, event) => {
        return pluginView ? pluginView.handleKeyDown(editorView, event) : false;
      },

      handleTextInput: (editorView, from, to, text) => {
        if (pluginView) pluginView.onTextInput(editorView, from, to, text);
      },

      closeListItemCommandsMenu: () => {
        if (pluginView) pluginView.dismiss();
      },

      isListItemCommandsMenuOpen: () => {
        return pluginView ? pluginView.isOpen() : false;
      },
    },

    view: editorView => {
      pluginView = new ListItemCommandsMenuPluginView(editorView);
      return pluginView;
    },
  });
}

import readImageInfo from "lib/ample-editor/lib/read-image-info"

// --------------------------------------------------------------------------
export function blobFromDataURL(dataURL) {
  let byteString;
  try {
    byteString = atob(dataURL.split(",")[1]);
  } catch (error) {
    // Likely `InvalidCharacterError`
    return null;
  }
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const buffer = new ArrayBuffer(byteString.length);
  const array = new Uint8Array(buffer);
  for (let i = 0; i < byteString.length; i++) {
    array[i] = byteString.charCodeAt(i);
  }
  return new Blob([ buffer ], { type: mimeString });
}

// --------------------------------------------------------------------------
export function dataURLFromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      resolve(event.target.result);
    };

    reader.onerror = function(event) {
      reader.abort();
      reject(event.target.error);
    };

    reader.readAsDataURL(blob);
  });
}

// --------------------------------------------------------------------------
export function probeImage(image) {
  return new Promise((accept, _) => {
    const reader = new FileReader();
    reader.onloadend = e => {
      let imageInfo = null;

      if (e.target.readyState === FileReader.DONE) {
        const data = new Uint8Array(e.target.result);
        imageInfo = readImageInfo(data);
      }

      accept(imageInfo);
    };

    // Read a chunk of 256 bytes so we have a better chance of including a JPG's dimensions, which may be
    // further into the file.
    const blob = image.slice(0, 1024);
    reader.readAsArrayBuffer(blob);
  });
}

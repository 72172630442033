import { TextSelection } from "prosemirror-state"
import React from "react"
import ReactDOM from "react-dom"

import ListItemCommandsMenu from "lib/ample-editor/components/list-item-commands-menu"
import applyListItemCommand from "lib/ample-editor/lib/apply-list-item-command"
import { positionPopupContainer } from "lib/ample-editor/lib/popup-util"
import { isPlainTextBetween } from "lib/ample-editor/lib/prosemirror-util"
import { getExpandedTaskUUID } from "lib/ample-editor/plugins/check-list-item-plugin"
import { getOpenLinkPos } from "lib/ample-editor/plugins/link-plugin"
import getTaskOptionPluginActions from "lib/ample-editor/util/get-task-option-plugin-actions"

// --------------------------------------------------------------------------
const MAX_POPUP_WIDTH = 400;

// --------------------------------------------------------------------------
function findListItemCommandsMenuPos(state, { allowMissingTrigger = false } = {}) {
  const { selection, selection: { empty, $head } } = state;
  if (!(selection instanceof TextSelection) || !empty || !$head.parent) return { menuPos: null };

  const listItemNode = listItemNodeFromPos($head);
  if (!listItemNode) return { menuPos: null };

  const { attrs, type } = listItemNode;
  if (!attrs || !attrs.uuid) return { menuPos: null };

  const textContent = $head.parent.textBetween(0, $head.parentOffset, null, "\ufffc");
  const match = /(?:\ufffc|\s|^)(!(\w*))?$/.exec(textContent);
  if (!match) return { menuPos: null };

  let menuPos = $head.pos;

  if (typeof(match[1]) === "undefined") {
    if (!allowMissingTrigger) return { menuPos: null };

    menuPos += 1;
  } else {
    menuPos -= match[1].length;
  }

  // Make sure there are no inline nodes in the text, as they would throw off linear position mapping (since they have
  // open/close positions)
  const hasNonTextNodes = !isPlainTextBetween(state.doc, menuPos, $head.pos);
  if (hasNonTextNodes) return { menuPos: null };

  return {
    attributes: attrs,
    endPos: $head.pos,
    menuPos,
    text: match[2] || "",
    type: type.name,
  };
}

// --------------------------------------------------------------------------
function listItemNodeFromPos($pos) {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth);
    if (!node) return null;

    switch (node.type.name) {
      case "link":
        // Don't allow menu when cursor is in a link, as we have other popups there
        return null;

      case "bullet_list_item":
      case "check_list_item":
        return node;

      default:
        break;
    }
  }

  return null;
}

// --------------------------------------------------------------------------
// Allows some changes to the document/state, like opening another popup, to force
// the menu to close
function shouldCloseMenu(state, prevState) {
  return (getExpandedTaskUUID(state) !== getExpandedTaskUUID(prevState)) ||
    (getOpenLinkPos(state) !== null && getOpenLinkPos(prevState) === null);
}

// --------------------------------------------------------------------------
export default class ListItemCommandsMenuPluginView {
  _listItemAttributes = null;
  _listItemCommandsMenuContainer = null;
  _listItemCommandsMenuPos = null;
  _listItemCommandsMenuRef = React.createRef();
  _listItemType = null;

  // --------------------------------------------------------------------------
  constructor(editorView) {
    this._dismissMenu = this._dismissMenu.bind(this, editorView);
    this._removeInvocationText = this._removeInvocationText.bind(this, editorView);

    this._listItemCommandsMenuContainer = document.createElement("div");
    this._listItemCommandsMenuContainer.className = "list-item-commands-menu-container";
    if (editorView.dom.parentNode) editorView.dom.parentNode.appendChild(this._listItemCommandsMenuContainer);

    this.update(editorView);
  }

  // --------------------------------------------------------------------------
  dismiss() {
    this._listItemAttributes = null;
    this._listItemCommandsMenuPos = null;
    this._listItemType = null;
    ReactDOM.unmountComponentAtNode(this._listItemCommandsMenuContainer);
  }

  // --------------------------------------------------------------------------
  handleKeyDown(editorView, event) {
    const { current: listItemCommandsMenu } = this._listItemCommandsMenuRef;
    return listItemCommandsMenu ? listItemCommandsMenu.handleKeyDown(editorView, event) : false;
  }

  // --------------------------------------------------------------------------
  isOpen() {
    return this._listItemCommandsMenuPos !== null;
  }

  // --------------------------------------------------------------------------
  onTextInput(editorView, from, to, text) {
    if (text === "!" && from === editorView.state.selection.head && to === from) {
      const { attributes, menuPos, type } = findListItemCommandsMenuPos(editorView.state, { allowMissingTrigger: true });
      this._listItemAttributes = attributes;
      this._listItemCommandsMenuPos = menuPos;
      this._listItemType = type;
    }
  }

  // --------------------------------------------------------------------------
  update(editorView, prevState) {
    let invocationText = null;

    if (this._listItemCommandsMenuPos !== null) {
      if (shouldCloseMenu(editorView.state, prevState)) {
        this.dismiss();
      } else {
        const { current: listItemCommandsMenu } = this._listItemCommandsMenuRef;
        const activeListItemCommand = listItemCommandsMenu ? listItemCommandsMenu.activeListItemCommand() : null;
        if (activeListItemCommand && !editorView.hasFocus()) {
          invocationText = activeListItemCommand;
        } else {
          const { attributes, menuPos, text, type } = findListItemCommandsMenuPos(editorView.state);
          this._listItemAttributes = attributes;
          this._listItemCommandsMenuPos = menuPos;
          this._listItemType = type;

          invocationText = text;
        }
      }
    }

    this._render(editorView, invocationText, this._listItemCommandsMenuPos);
  }

  // --------------------------------------------------------------------------
  destroy() {
    ReactDOM.unmountComponentAtNode(this._listItemCommandsMenuContainer);
    this._listItemCommandsMenuContainer.remove();
  }

  // --------------------------------------------------------------------------
  _applyCommand = (editorView, menuPos, attributes, listItemType, commandName, commandOptions) => {
    this._dismissMenu(editorView);

    applyListItemCommand(editorView, menuPos, attributes, listItemType, commandName, commandOptions);
  };

  // --------------------------------------------------------------------------
  _dismissMenu = editorView => {
    this.dismiss();
    editorView.focus();
  };

  // --------------------------------------------------------------------------
  _removeInvocationText = editorView => {
    const { dispatch, state } = editorView;

    const { endPos, menuPos } = findListItemCommandsMenuPos(editorView.state);
    if (menuPos) dispatch(state.tr.delete(menuPos, endPos));
  };

  // --------------------------------------------------------------------------
  _render(editorView, invocationText, listItemCommandsMenuPos) {
    if (listItemCommandsMenuPos !== null) {
      const {
        props: {
          hostApp: { fetchNoteContent, suggestNotes },
          overrideListItemCommandMenuListItemType,
        },
      } = editorView;

      ReactDOM.render(
        <ListItemCommandsMenu
          applyCommand={ this._applyCommand.bind(this, editorView, listItemCommandsMenuPos) }
          attributes={ this._listItemAttributes }
          dismiss={ this._dismissMenu }
          displayedListItemType={ overrideListItemCommandMenuListItemType }
          fetchNoteContent={ fetchNoteContent }
          getTaskOptionPluginActions={ getTaskOptionPluginActions.bind(null, editorView, listItemCommandsMenuPos) }
          listItemType={ this._listItemType }
          ref={ this._listItemCommandsMenuRef }
          removeText={ this._removeInvocationText }
          suggestNotes={ suggestNotes }
          text={ invocationText }
        />,
        this._listItemCommandsMenuContainer
      );

      positionPopupContainer(editorView, this._listItemCommandsMenuContainer, MAX_POPUP_WIDTH, this._listItemCommandsMenuPos);
    } else {
      ReactDOM.unmountComponentAtNode(this._listItemCommandsMenuContainer);
    }
  }
}

import { Schema } from "prosemirror-model"

import {
  marks as standardSchemaMarks,
  nodes as standardSchemaNodes,
} from "lib/ample-editor/schema"

// --------------------------------------------------------------------------
const nodes = {
  // --------------------------------------------------------------------------
  doc: {
    content: "block+"
  },

  // Paragraph must be first after doc so it's the default block node type
  paragraph: standardSchemaNodes.paragraph,

  // --------------------------------------------------------------------------
  blockquote: standardSchemaNodes.blockquote,
  bullet_list_item: standardSchemaNodes.bullet_list_item,
  code_block: standardSchemaNodes.code_block,
  embed: standardSchemaNodes.embed,
  hard_break: standardSchemaNodes.hard_break,
  heading: standardSchemaNodes.heading,
  horizontal_rule: standardSchemaNodes.horizontal_rule,
  text: standardSchemaNodes.text,

  // --------------------------------------------------------------------------
  link: {
    // This supports the same attributes as the main schema's links so you can copy and paste a link from an outer
    // note into the description schema, then copy+paste it back without losing the attributes (media, text) that
    // aren't used in this schema.
    attrs: standardSchemaNodes.link.attrs,
    content: "(hard_break|text)*",
    defining: true,
    group: "inline",
    inline: true,
    marks: "code em highlight strikethrough strong",
    // Allows for pasting of links from outer editor into descriptions (the extra unsupported attrs will be dropped)
    parseDOM: standardSchemaNodes.link.parseDOM,
    toDOM: standardSchemaNodes.link.toDOM,
  },
};

// --------------------------------------------------------------------------
const marks = {
  code: standardSchemaMarks.code,
  em: standardSchemaMarks.em,
  highlight: standardSchemaMarks.highlight,
  strikethrough: standardSchemaMarks.strikethrough,
  strong: standardSchemaMarks.strong,
};

// --------------------------------------------------------------------------
export function isDescriptionSchema(schema) {
  return !!(schema.nodes.bullet_list_item && !schema.nodes.check_list_item);
}

// --------------------------------------------------------------------------
const descriptionSchema = new Schema({ nodes, marks });
export default descriptionSchema;

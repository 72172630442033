import { closeHistory } from "prosemirror-history"
import { Plugin, PluginKey, TextSelection } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import { v4 as uuidv4 } from "uuid"

import MultiListItemSelection from "lib/ample-editor/lib/multi-list-item-selection"
import sliceFromText from "lib/ample-editor/lib/slice-from-text"
import { selectionSpansTableCells } from "lib/ample-editor/lib/table/table-commands"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import { getAvailableCommands } from "lib/ample-editor/plugins/toolbar-plugin"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import SelectionMenuPluginView from "lib/ample-editor/views/selection-menu-plugin-view"

// --------------------------------------------------------------------------
// Drag tracking based on https://github.com/ProseMirror/prosemirror/issues/587#issuecomment-289820718 so we can
// disable the selection menu when dragging (e.g. when dragging a bullet with children, there's an expanded selection
// but the selection menu may cover the desired drag position)
let dragLeaveTimer = null;

// --------------------------------------------------------------------------
const pluginKey = new PluginKey("selection-menu");

// --------------------------------------------------------------------------
// Note that we want `dragging` passed in as the pluginState in `state` might
// not have been updated yet to reflect the new dragging state that will go
// with the new result from this function
export function canShowSelectionMenuFromState(state, dragging) {
  const { selection, selection: { from, to } } = state;
  if (from === to) return false;

  if (selection instanceof TextSelection) {
    // There's a particular case where the text selection just spans the end of one node to the start of the next
    // node, but doesn't actually contain any content
    const { $from, $to } = selection;
    if ($from.pos === $from.end() && $from.after() === $to.before() && $to.pos === $to.start()) {
      return false;
    }
  } else if (!(selection instanceof MultiListItemSelection)) {
    return false;
  }

  return !dragging && !selectionSpansTableCells(state);
}

// --------------------------------------------------------------------------
function dragenter(editorView) {
  clearTimeout(dragLeaveTimer);

  if (!isDragging(editorView.state)) {
    editorView.dispatch(setIsDragging(editorView.state.tr, true));
  }
}

// --------------------------------------------------------------------------
function dragleave(editorView, event) {
  if (event.target === editorView.dom && isDragging(editorView.state)) {
    clearTimeout(dragLeaveTimer);

    // Can't be synchronous because this is also fired when the drag goes onto a child element,
    // and we can't tell from the event whether that's the case.
    dragLeaveTimer = setTimeout(() => {
      editorView.dispatch(setIsDragging(editorView.state.tr, false));
    }, 50);
  }
}

// --------------------------------------------------------------------------
function dragover(editorView) {
  clearTimeout(dragLeaveTimer);

  if (!isDragging(editorView.state)) {
    editorView.dispatch(setIsDragging(editorView.state.tr, true));
  }
}

// --------------------------------------------------------------------------
function isDragging(state) {
  const pluginState = pluginKey.getState(state);
  return pluginState && pluginState.dragging;
}

// --------------------------------------------------------------------------
export async function replaceSelection(editorView, run) {
  const { state: { doc, schema, selection: { from, to } } } = editorView;

  const selectedText = doc.textBetween(from, to, "\n", " ");

  const decorationUUID = uuidv4();
  editorView.dispatch(
    editorView.state.tr
      .setSelection(TextSelection.create(editorView.state.tr.doc, from))
      .setMeta(pluginKey, { add: { from, to, uuid: decorationUUID } })
  );

  // We use the decoration to keep track of where the expression is, as the document may change while we're waiting
  const getDecoration = () => {
    const pluginState = pluginKey.getState(editorView.state);
    const decorations = pluginState
      ? pluginState.decorations.find(null, null, ({ uuid }) => uuid === decorationUUID)
      : null;
    return decorations ? decorations[0] : null;
  };

  const editorContext = buildEditorContext(editorView, getDecoration);

  let transform = null;
  try {
    const newText = await run(editorContext, selectedText);

    const decoration = getDecoration();
    if (decoration) {
      transform = editorView.state.tr.setMeta(pluginKey, { remove: decorationUUID });

      if (newText !== null && typeof(newText) !== "undefined") {
        // We don't want to insert any newline characters, as they'll be rendered in HTML but disappear when
        // editing the node. We could use `buildClipboardTextParser` to parse the text into full paragraph
        // nodes here, but that runs into issues in the tasks editor, as we can't always insert multiple
        // paragraphs, so we'll use hard breaks instead, as they can go anywhere text nodes can.
        const slice = sliceFromText(schema, newText);

        // We use the decoration to keep track of where the expression is, as the document may change while we're
        // waiting
        transform.replaceRange(decoration.from, decoration.to, slice);
      }
    }
  } catch (_error) {
    transform = editorView.state.tr.setMeta(pluginKey, { remove: decorationUUID });
  }

  if (transform) {
    editorView.dispatch(closeHistory(transform));
  }
}

// --------------------------------------------------------------------------
// Note this isn't typically intended to be called externally, but there are some
// cases where external code might know about a drag starting before we do and we
// want to catch it as soon as that code dispatches a transaction.
export function setIsDragging(transform, dragging) {
  return transform.setMeta(pluginKey, { dragging });
}

// --------------------------------------------------------------------------
function shouldShowSelectionMenu(editorView) {
  // Note that this `hasFocus` check relies on a transaction being dispatched when focus changes
  if (!editorView.hasFocus()) return false;

  const { state } = editorView;

  const pluginState = pluginKey.getState(state);
  return pluginState && pluginState.canShowSelectionMenu;
}

// --------------------------------------------------------------------------
export default function createSelectionMenuPlugin(options = {}) {
  const {
    getAvailableToolbarCommands = null,
    hideSelectionMenu = false,
  } = options;

  let pluginView = null;

  return new Plugin({
    key: pluginKey,
    props: {
      decorations: state => {
        const pluginState = pluginKey.getState(state);
        return pluginState ? pluginState.decorations : DecorationSet.empty;
      },
      handleDOMEvents: {
        dragenter,
        dragleave,
        dragover,
        // Note that there's no `drop` here - see discussion in `uiEvent` handling, below
      },
      handleKeyDown: (editorView, event) => {
        return pluginView ? pluginView.handleKeyDown(editorView, event) : false;
      },
    },
    state: {
      init() {
        return {
          canShowSelectionMenu: null,
          decorations: DecorationSet.empty,
          dragging: false,
        };
      },
      apply(tr, pluginState, _lastState, _state) {
        const meta = tr.getMeta(pluginKey);
        if (meta) {
          if ("dragging" in meta) {
            pluginState = { ...pluginState, dragging: meta.dragging };
          }

          const { add, remove } = meta;
          if (add) {
            const { from, to, uuid } = add;
            const decoration = Decoration.inline(from, to, { class: "pending-text-replacement" }, { uuid });
            pluginState = { ...pluginState, decorations: pluginState.decorations.add(tr.doc, [ decoration ]) };
          } else if (remove) {
            const decorations = pluginState.decorations.find(null, null, ({ uuid }) => uuid === remove);
            pluginState = { ...pluginState, decorations: pluginState.decorations.remove(decorations) };
          }
        } else if (tr.getMeta(TRANSACTION_META_KEY.UI_EVENT) === "drop") {
          // This is an indicator that this is what otherwise would be the `drop` event in `handleDOMEvents` but gets
          // preventDefault-ed by list-item-selection-plugin.js' `dispatchDropTransform` function, so we have to
          // detect it here instead.
          pluginState = { ...pluginState, dragging: false };
        }

        if (tr.docChanged) {
          return {
            ...pluginState,
            canShowSelectionMenu: canShowSelectionMenuFromState(tr, pluginState.dragging),
            decorations: pluginState.decorations.map(tr.mapping, tr.doc),
          };
        } else if (tr.selectionSet || pluginState.canShowSelectionMenu === null) {
          return {
            ...pluginState,
            canShowSelectionMenu: canShowSelectionMenuFromState(tr, pluginState.dragging),
          };
        } else {
          return pluginState;
        }
      },
    },
    view: hideSelectionMenu
      ? null
      : editorView => {
        pluginView = new SelectionMenuPluginView(
          editorView,
          getAvailableToolbarCommands || (() => getAvailableCommands(editorView.state)),
          replaceSelection,
          shouldShowSelectionMenu
        );
        return pluginView;
      },
  });
}

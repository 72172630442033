// --------------------------------------------------------------------------
export const TASK_RELATION = {
  BENEFITING: "benefiting",
  CONNECTED: "connected",
  MIRRORED: "mirrored",
  MIRRORING: "mirroring",
  WAITING: "waiting",
};

export const VECTOR_ICON_NAME_BY_TASK_RELATION = {
  [TASK_RELATION.BENEFITING]: "vector-link",
  [TASK_RELATION.CONNECTED]: "link-variant",
  [TASK_RELATION.MIRRORED]: "mirror-variant",
  [TASK_RELATION.MIRRORING]: "mirror-variant",
  [TASK_RELATION.WAITING]: "motion-pause-outline",
};

// --------------------------------------------------------------------------
export function pathFromTask({ uuid }) {
  return `/notes/tasks/${ uuid }`;
}

import PropTypes from "prop-types"
import { EditorState } from "prosemirror-state"
import { EditorView } from "prosemirror-view"
import React, { useEffect, useRef, useState } from "react"

import nodeViews from "lib/ample-editor/views"
import CodeBlockRenderedView from "lib/ample-editor/views/code-block-rendered-view"
import { transformTraditionalLink } from "lib/ample-editor/views/link-view"

// --------------------------------------------------------------------------
// Mimics what happens when rendering public notes in ample-web
export default function RenderedDemo({ document }) {
  const [ renderedContent, setRenderedContent ] = useState(null);

  const containerRef = useRef(null);
  const editorViewRef = useRef(null);
  useEffect(
    () => {
      const { current: editorView } = editorViewRef;

      if (editorView) {
        editorView.updateState(EditorState.create({ doc: document }));
      } else {
        // eslint-disable-next-line camelcase
        const { code_block, ...viewsForRender } = nodeViews();
        viewsForRender.code_block = (node, view, getPos) => new CodeBlockRenderedView(node, view, getPos);

        const container = window.document.createElement("div");
        container.className = "ample-editor readonly";
        containerRef.current = container;

        // eslint-disable-next-line no-new
        editorViewRef.current = new EditorView(container, {
          attributes: { class: "ample-editor-editor" },
          editable: () => false,
          nodeViews: viewsForRender,
          state: EditorState.create({ doc: document }),

          // Custom Amplenote-specific props
          hostApp: {},
          interactiveHeadingAnchors: true,
        });
      }

      setRenderedContent(containerRef.current.innerHTML);
    },
    [ document ],
  );

  // This is what public note rendering normally does to handle links
  useEffect(
    () => {
      if (!renderedContent) return;

      const ampleEditorDOM = window.document.querySelector(".ample-editor.rendered-demo");
      if (!ampleEditorDOM) return;

      Array.from(ampleEditorDOM.querySelectorAll(".link")).forEach(link => {
        transformTraditionalLink(link);
      });
    },
    [ renderedContent ]
  );

  return (
    <div
      className="ample-editor readonly rendered-demo"
      dangerouslySetInnerHTML={ { __html: renderedContent } }
    />
  );
}

RenderedDemo.propTypes = {
  document: PropTypes.object,
};

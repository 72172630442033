import { compact } from "lodash"

// --------------------------------------------------------------------------
export default function resolvePluginActionPromises(pluginActionPromises, options = {}) {
  const {
    // eslint-disable-next-line no-empty-function
    eachPluginAction = _pluginAction => {},
    // eslint-disable-next-line no-empty-function
    setPluginActions = (_pluginActions, _isLoading) => {},
    shouldCancel = () => false,
  } = options;

  if (!pluginActionPromises) return;

  // eslint-disable-next-line no-undefined
  const resolvedPluginActions = new Array(pluginActionPromises.length).fill(undefined);

  pluginActionPromises.forEach((pluginActionPromise, pluginActionIndex) => {
    pluginActionPromise.then(pluginAction => {
      resolvedPluginActions[pluginActionIndex] = pluginAction;

      // eslint-disable-next-line no-shadow
      const loadingPluginActions = !resolvedPluginActions.every(pluginAction => {
        return typeof(pluginAction) !== "undefined";
      });

      if (shouldCancel()) return;

      if (pluginAction) eachPluginAction(pluginAction);

      setPluginActions(compact(resolvedPluginActions), loadingPluginActions);
    });
  });
}

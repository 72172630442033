// --------------------------------------------------------------------------
export default function storageValueFromDocument(document, noteStorageKey) {
  // eslint-disable-next-line no-undefined
  if (!document) return undefined;

  const { attrs } = document;
  // eslint-disable-next-line no-undefined
  if (!attrs) return undefined;

  const { storage } = attrs;
  // eslint-disable-next-line no-undefined
  if (!storage) return undefined;

  return storage[noteStorageKey];
}

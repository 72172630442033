import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

// --------------------------------------------------------------------------
export default class ChangeCompletedTaskStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    const { task, changes } = json;
    return new ChangeCompletedTaskStep(schema, task, changes);
  }

  // --------------------------------------------------------------------------
  constructor(schema, task, changes) {
    super();

    this.schema = schema;
    this.task = task;
    this.changes = changes;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const { uuid } = this.task;

    if (uuid) {
      const serializedDoc = doc.toJSON();

      const completedTasks = (serializedDoc.attrs.completedTasks || []).map(completedTask => {
        if (completedTask.uuid === uuid) {
          return { ...this.task, ...this.changes }
        } else {
          return completedTask;
        }
      });

      serializedDoc.attrs = { ...serializedDoc.attrs, completedTasks };

      doc = Node.fromJSON(this.schema, serializedDoc);
    }

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    const taskAfter = { ...this.task, ...this.changes };
    // This is not _exactly_ true, as changes may not include all task attributes, while this.task will, but it will
    // result in the inverse change.
    return new ChangeCompletedTaskStep(this.schema, taskAfter, this.task);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      stepType: "changeCompletedTask",
      task: this.task,
      changes: this.changes,
    };
  }
}
Step.jsonID("changeCompletedTask", ChangeCompletedTaskStep);

import PropTypes from "prop-types"
import React, { useCallback, useRef } from "react"
import { List, SimpleListItem } from "@rmwc/list"

import { blurTargetOnEnter } from "lib/ample-editor/components/task-detail/util"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"

// --------------------------------------------------------------------------
function Suggestion({ suggestion }) {
  const { selected, text } = suggestion;

  return (
    <SimpleListItem
      className={ `suggestion ${ selected ? "selected" : "" }` }
      onMouseDown={ preventEventDefault }
      text={ text }
      tabIndex={ -1 }
    />
  );
}

// --------------------------------------------------------------------------
function Suggestions({ selectSuggestion, suggestions }) {
  const onAction = useCallback(
    event => {
      const suggestion = suggestions[event.detail];
      if (suggestion) selectSuggestion(suggestion);
    },
    [ selectSuggestion, suggestions ]
  );

  return (
    <List className="suggestions" onAction={ onAction }>
      {
        suggestions.map(suggestion => (
          <Suggestion
            key={ suggestion.text + suggestion.value }
            suggestion={ suggestion }
          />
        ))
      }
    </List>
  );
}

// --------------------------------------------------------------------------
export default function TextInputWithSuggestions(props) {
  const {
    additionalClassName,
    autoFocus,
    disabled,
    invalid,
    onBlur,
    onFocus,
    onKeyDown,
    placeholder,
    selectSuggestion,
    setValue,
    suggestions,
    value,
  } = props;

  const onChange = event => {
    setValue(event.target.value);
  };

  let className = `text-input ${ invalid ? "invalid" : "valid" }`;
  if (additionalClassName) className += ` ${ additionalClassName }`;

  const inputRef = useRef();
  const selectSuggestionAndBlur = useCallback(
    suggestion => {
      selectSuggestion(suggestion);

      const { current: input } = inputRef;
      if (input) input.blur();
    },
    [ selectSuggestion ]
  );

  return (
    <React.Fragment>
      <input
        autoFocus={ autoFocus }
        className={ className }
        disabled={ disabled }
        onBlur={ onBlur }
        onFocus={ onFocus }
        onChange={ onChange }
        onKeyDown={ onKeyDown }
        onKeyPress={ blurTargetOnEnter }
        placeholder={ placeholder }
        ref={ inputRef }
        type="text"
        value={ value }
      />
      {
        suggestions
          ? (
            <Suggestions
              selectSuggestion={ selectSuggestionAndBlur }
              suggestions={ suggestions }
            />
          )
          : null
      }
    </React.Fragment>
  );
}

TextInputWithSuggestions.propTypes = {
  additionalClassName: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  selectSuggestion: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({
    selected: PropTypes.bool,
    text: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  })),
  value: PropTypes.string.isRequired,
};

import { compareAsc, format, parseISO, startOfDay } from "date-fns"
import { groupBy } from "lodash"
import PropTypes from "prop-types"
import { EditorView } from "prosemirror-view"
import React from "react"

import TasksEditor from "lib/ample-editor/components/tasks-editor"
import { hiddenTasksFindParamsFromFindPluginState } from "lib/ample-editor/plugins/find-plugin"

// --------------------------------------------------------------------------
function groupHiddenTasks(hiddenTasks) {
  const today = startOfDay(Date.now());

  const hiddenTasksByDay = groupBy(hiddenTasks, hiddenTask => {
    const { attrs: { startAt } } = hiddenTask;
    return (startAt ? startOfDay(startAt * 1000) : today).toISOString();
  });

  const groupedHiddenTasks = [];

  Object.keys(hiddenTasksByDay).sort((left, right) => compareAsc(parseISO(left), parseISO(right))).forEach(day => {
    const tasks = hiddenTasksByDay[day];
    const groupId = day; // Note this is an ISO string, not a Date (so it can be more easily compared/used as a key)
    const groupName = `Hidden until ${ format(parseISO(day), "iiii, LLLL d") }`;
    groupedHiddenTasks.push({ groupId, groupName, tasks });
  });

  return groupedHiddenTasks;
}

// --------------------------------------------------------------------------
export default class HiddenTasks extends React.PureComponent {
  static propTypes = {
    checkListItemPluginState: PropTypes.object,
    dispatchChanges: PropTypes.func.isRequired,
    editorProps: PropTypes.shape({
      hostApp: PropTypes.shape({
        cloneNodes: PropTypes.func,
        fetchNoteContent: PropTypes.func,
        linkNote: PropTypes.func,
        openAttachment: PropTypes.func,
        openNoteLink: PropTypes.func,
        startAttachmentUpload: PropTypes.func,
        startMediaUpload: PropTypes.func,
        suggestNotes: PropTypes.func,
      }).isRequired,
      onPopupPositioned: PropTypes.func,
      onTaskGroupHeadingClick: PropTypes.func,
      renderTaskExpander: PropTypes.func,
    }).isRequired,
    filePluginState: PropTypes.object,
    findPluginState: PropTypes.object,
    parentEditorView: PropTypes.instanceOf(EditorView).isRequired,
    readonly: PropTypes.bool,
    tasks: PropTypes.array.isRequired,
  };

  _tasksEditorRef = React.createRef();

  // --------------------------------------------------------------------------
  closeRichFootnote() {
    const { current: tasksEditor } = this._tasksEditorRef;
    if (tasksEditor) tasksEditor.closeRichFootnote();
  }

  // --------------------------------------------------------------------------
  componentDidMount() {
    const { findPluginState } = this.props;

    const { currentIndex, query } = hiddenTasksFindParamsFromFindPluginState(findPluginState);
    if (query) {
      const { current: tasksEditor } = this._tasksEditorRef;
      if (tasksEditor) tasksEditor.find(query, null, { currentIndex });
    }
  }

  // --------------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    const { findPluginState: findPluginStateWas } = prevProps;
    const { findPluginState } = this.props;

    if (findPluginState !== findPluginStateWas) {
      const {
        currentIndex: currentIndexWas,
        query: queryWas,
      } = hiddenTasksFindParamsFromFindPluginState(findPluginStateWas);
      const { currentIndex, query } = hiddenTasksFindParamsFromFindPluginState(findPluginState);

      if (currentIndex !== currentIndexWas || query !== queryWas) {
        const { current: tasksEditor } = this._tasksEditorRef;
        if (tasksEditor) tasksEditor.find(query, null, { currentIndex });
      }
    }
  }

  // --------------------------------------------------------------------------
  render() {
    const {
      checkListItemPluginState,
      editorProps,
      filePluginState,
      parentEditorView,
      readonly,
      tasks,
    } = this.props;

    const tasksWithGroup = groupHiddenTasks(tasks);

    return (
      <div className="hidden-tasks">
        <TasksEditor
          checkListItemPluginState={ checkListItemPluginState }
          dispatchChanges={ this._dispatchChanges }
          editorProps={ editorProps }
          filePluginState={ filePluginState }
          parentEditorView={ parentEditorView }
          readonly={ readonly }
          ref={ this._tasksEditorRef }
          tasksWithGroup={ tasksWithGroup }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _changesFromGroupId = (uuid, groupId) => {
    const { tasks } = this.props;

    const day = parseISO(groupId);
    const newStartAtDay = Math.floor(day.getTime() / 1000);

    const task = tasks.find(hiddenTask => hiddenTask.attrs.uuid === uuid);
    if (task) {
      // Retain the same hour of day from the current startAt
      let { attrs: { startAt } } = task;

      const startAtDay = Math.floor(startOfDay(startAt * 1000).getTime() / 1000);
      if (startAtDay === newStartAtDay) return {};

      const offsetInDay = startAt - startAtDay;
      startAt = newStartAtDay + offsetInDay;

      return { startAt };
    } else {
      const now = Math.floor(Date.now() / 1000);
      if (newStartAtDay < now) {
        return { startAt: now + 3600 };
      } else {
        return { startAt: newStartAtDay };
      }
    }
  };

  // --------------------------------------------------------------------------
  _dispatchChanges = (changesByUUID, meta) => {
    this.props.dispatchChanges(changesByUUID, meta, this._changesFromGroupId);
  };
}

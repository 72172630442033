import { Plugin, PluginKey } from "prosemirror-state"

import { transactionMarkedReadonly } from "lib/ample-editor/lib/transaction-util"
import { animateCompletedTask } from "lib/ample-editor/plugins/completed-check-list-items-plugin"
import AddCompletedTaskStep from "lib/ample-editor/steps/add-completed-task-step"
import { completedTaskFromCheckListItem, isDoneFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// Intended for use in the TasksEditor only when it is maintaining its own internal
// history, as we need to keep track of tasks that are completed within the document,
// so completion can be undone.
// --------------------------------------------------------------------------
const tasksCompletionPlugin = new Plugin({
  // --------------------------------------------------------------------------
  appendTransaction: (transactions, oldState, newState) => {
    if (transactions.find(transactionMarkedReadonly)) return;

    const { schema } = newState;
    const transform = newState.tr;

    newState.doc.forEach((groupNode, groupNodePos) => {
      if (groupNode.type !== schema.nodes.tasks_group) return;

      groupNode.forEach((taskNode, taskNodeOffset) => {
        const { attrs } = taskNode;

        if (isDoneFromTask(attrs)) {
          const originalNodePos = groupNodePos + 1 + taskNodeOffset;
          const taskNodePos = transform.mapping.map(originalNodePos);

          // Note that we move the task to completedTasks even if it has been crossed-out instead of completed. This
          // allows undo to work, as otherwise the task would get lost in the subsequent update of the tasks-editor.
          const completedTask = completedTaskFromCheckListItem(taskNode.toJSON());
          transform.step(new AddCompletedTaskStep(schema, completedTask));
          animateCompletedTask(transform, taskNode, taskNodePos, originalNodePos);
          transform.delete(taskNodePos, taskNodePos + taskNode.nodeSize);
        }
      });
    });

    if (transform.docChanged) return transform;
  },

  // --------------------------------------------------------------------------
  key: new PluginKey("tasks-completion"),
});
export default tasksCompletionPlugin;

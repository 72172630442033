import { useEffect, useRef, useState } from "react"

// --------------------------------------------------------------------------
export default function useDelayedValue(initialValue, finalValue, initialDelay = 250) {
  const [ value, setValue ] = useState(initialValue);

  const timerRef = useRef(null);
  useEffect(
    () => {
      timerRef.current = setTimeout(() => setValue(finalValue), initialDelay);

      return () => {
        clearTimeout(timerRef.current);
      };
    },
    []
  );

  return value;
}

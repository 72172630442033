import { clamp } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import useResizeObserver from "@react-hook/resize-observer"
import { Button } from "@rmwc/button"
import SwipeListener from "swipe-listener"

import { isAndroid } from "lib/ample-editor/lib/client-info"
import { TASK_COMPLETION_MODE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const BUTTONS_WIDTH_PX = 200;
// Afer this amount of vertical scrolling, we won't try to open the swipe anymore as it's likely the user only intends
// to vertically scroll (iOS only, on Android they are mutually exclusive).
const MAX_VERTICAL_MOVEMENT = 80;
// Min px before we actually show the buttons, despite the swipe having started, mainly intended to reduce the chance
// of seeing a sliver of the buttons when the intention is to scroll vertically (on iOS)
const MIN_HORIZONTAL_DELTA = 8;
const MIN_HORIZONTAL_SWIPE_DISTANCE_PX = 100; // Library default is 10px
const MIN_VERTICAL_SWIPE_DISTANCE_PX = 500; // We're essentially disabling vertical here
const TALL_BUTTON_MIN_HEIGHT = 88; // px height of the row when it is three lines or has focus and has mobile widgets

// --------------------------------------------------------------------------
// On Android, you can't call preventDefault on a touchmove event if there is scrolling happening in an ancestor
// (native) ScrollView, and it's _very_ easy to scroll vertically (even by a pt or two) while swiping horizontally.
// Additionally, Android will not emit the touchend event in the webview if the native ScrollView is scrolled even
// after touchmove has been emitted, so we need to instead lock the _native_ ScrollView's scrolling while swiping, so
// the swipe doesn't get stuck (due to not getting touchend event).
function disableAndroidScroll() {
  if (!window.callAmplenoteHostApp || !isAndroid) return;

  // We don't want to pull in an embed dependency here, so duplicating constant value here
  window.callAmplenoteHostApp("setScrollEnabled" /* EMBED_EDITOR_MESSAGE.SET_SCROLL_ENABLED */, { enabled: false });
}

// --------------------------------------------------------------------------
function enableAndroidScroll() {
  if (!window.callAmplenoteHostApp || !isAndroid) return;

  // We don't want to pull in an embed dependency here, so duplicating constant value here
  window.callAmplenoteHostApp("setScrollEnabled" /* EMBED_EDITOR_MESSAGE.SET_SCROLL_ENABLED */, { enabled: true });
}

// --------------------------------------------------------------------------
class SwipeHandler {
  _completeTask = null;
  _container = null;
  _canStartSwipe = null;
  _destroyTimer = null;
  // Whether the swipe bar is in the open state (released by user, at a fixed open position)
  _isOpen = false;
  _lastYOffset = null;
  _swipePrevented = false;
  _totalVerticalMovement = 0;
  // The wrapper element created by `createWrapper`
  _wrapper = null;

  // --------------------------------------------------------------------------
  constructor(container, canStartSwipe, completeTask) {
    this._container = container;
    this._canStartSwipe = canStartSwipe;
    this._completeTask = completeTask;

    // eslint-disable-next-line no-new
    new SwipeListener(this._container, {
      // https://github.com/umanghome/swipe-listener?tab=readme-ov-file#options
      lockAxis: true,
      minHorizontal: MIN_HORIZONTAL_SWIPE_DISTANCE_PX,
      minVertical: MIN_VERTICAL_SWIPE_DISTANCE_PX,
      mouse: false,
      preventScroll: false,
      touch: true,
    });

    this._container.addEventListener("swiping", this._onSwiping);
    this._container.addEventListener("swiperelease", this._onSwipeRelease);
  }

  // --------------------------------------------------------------------------
  destroy = () => {
    clearTimeout(this._destroyTimer);

    if (this._wrapper) {
      ReactDOM.unmountComponentAtNode(this._wrapper);
      this._container.removeChild(this._wrapper);
      this._wrapper = null;
    }

    enableAndroidScroll();
  };

  // --------------------------------------------------------------------------
  _close = () => {
    this._isOpen = false;

    this._container.classList.remove("swiping");
    this._container.removeAttribute("style");

    if (this._wrapper) {
      // The width attribute has a CSS transition on it timed to match the transition on the transform of the container
      this._wrapper.setAttribute("style", "width: 0");
    }

    // Leaving the buttons visible during the closing transition
    this._destroyTimer = setTimeout(this.destroy, 250);
  };

  // --------------------------------------------------------------------------
  _closeAndCompleteTask = taskCompletionMode => {
    this._close();
    this._completeTask(taskCompletionMode);
  };

  // --------------------------------------------------------------------------
  _onSwiping = event => {
    disableAndroidScroll();

    const [ x0, x1 ] = event.detail.x;
    const [ y0, y1 ] = event.detail.y;

    if (this._lastYOffset === null) this._lastYOffset = y0;

    if (y1 !== this._lastYOffset) {
      this._totalVerticalMovement += Math.abs(y1 - this._lastYOffset);
      this._lastYOffset = y1;
    }

    // In case the user caught the row before it finished closing
    clearTimeout(this._destroyTimer);

    // .swiping disables transitions so we get 1:1 movement with touch
    this._container.classList.add("swiping");

    this._swipePrevented = false;

    if (this._isOpen) {
      const delta = clamp(Math.floor(x0 - x1), -BUTTONS_WIDTH_PX, 0);
      this._wrapper.setAttribute("style", `width: ${ BUTTONS_WIDTH_PX + delta }px`);
      this._container.setAttribute("style", `transform: translateX(${ -BUTTONS_WIDTH_PX - delta }px)`);
    } else if (x1 < x0) {
      if (!this._canStartSwipe() || this._totalVerticalMovement > MAX_VERTICAL_MOVEMENT) {
        this._swipePrevented = true;
        return;
      }

      if (!this._wrapper) {
        this._wrapper = document.createElement("div");
        this._wrapper.className = "swipeable-row-buttons-wrapper";
        this._container.appendChild(this._wrapper);
      }

      ReactDOM.render(
        <SwipeableRowButtons
          completeTask={ this._closeAndCompleteTask }
          width={ BUTTONS_WIDTH_PX }
        />,
        this._wrapper
      );

      let delta = clamp(Math.floor(x0 - x1), 0, BUTTONS_WIDTH_PX);
      if (delta < MIN_HORIZONTAL_DELTA) delta = 0;

      this._wrapper.setAttribute("style", `width: ${ delta }px`);
      this._container.setAttribute("style", `transform: translateX(${ -delta }px)`);
    } else {
      if (this._wrapper) this._wrapper.setAttribute("style", "width: 0");
      this._container.removeAttribute("style");
    }
  };

  // --------------------------------------------------------------------------
  _onSwipeRelease = event => {
    enableAndroidScroll();

    this._lastYOffset = null;
    this._totalVerticalMovement = 0;

    if (this._swipePrevented || !this._wrapper) {
      this._close();
      return;
    }

    const [ x0, x1 ] = event.detail.x;

    const delta = clamp(Math.floor(x0 - x1), 0, BUTTONS_WIDTH_PX);
    if (delta > MIN_HORIZONTAL_SWIPE_DISTANCE_PX) {
      this._open();
    } else {
      this._close();
    }
  };

  // --------------------------------------------------------------------------
  _open = () => {
    this._isOpen = true;

    this._container.classList.remove("swiping");
    this._container.setAttribute("style", `transform: translateX(${ -BUTTONS_WIDTH_PX }px)`);

    this._wrapper.setAttribute("style", `width: ${ BUTTONS_WIDTH_PX }px`);
  };
}

// --------------------------------------------------------------------------
export function createSwipeableRowButtonsWrapper(container, canStartSwipe, completeTask) {
  const swipeHandler = new SwipeHandler(container, canStartSwipe, completeTask);

  // Cleanup function
  return swipeHandler.destroy;
}

// --------------------------------------------------------------------------
function SwipeableRowButton({ className, label, onClick }) {
  const [ isTall, setIsTall ] = useState(false);

  const buttonRef = useRef();

  useResizeObserver(buttonRef, ({ contentRect }) => {
    setIsTall(contentRect.height >= TALL_BUTTON_MIN_HEIGHT);
  });

  return (
    <Button
      className={ `swipeable-row-button ${ isTall ? "tall" : "" } ${ className }` }
      label={ label }
      onClick={ onClick }
      ref={ buttonRef }
    />
  )
}

// --------------------------------------------------------------------------
function SwipeableRowButtons({ completeTask, width }) {
  const style = useMemo(() => ({ width: `${ width }px` }), [ width ]);

  const onCrossOutClick = useCallback(
    () => {
      completeTask(TASK_COMPLETION_MODE.CROSS_OUT);
    },
    [ completeTask ]
  );

  const onDismissClick = useCallback(
    () => {
      completeTask(TASK_COMPLETION_MODE.DISMISS);
    },
    [ completeTask ]
  );

  return (
    <div className="swipeable-row-buttons" style={ style }>
      <SwipeableRowButton
        className="cross-out"
        label="Cross out"
        onClick={ onCrossOutClick }
      />
      <SwipeableRowButton
        className="dismiss"
        label="Dismiss"
        onClick={ onDismissClick }
      />
    </div>
  );
}

SwipeableRowButtons.propTypes = {
  width: PropTypes.number.isRequired,
};

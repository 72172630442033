import { defer } from "lodash"
import { Plugin, PluginKey } from "prosemirror-state"

import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
export const TARGET_VIEW_TYPE_DESCRIPTION_EDITOR = "description-editor";
export const TARGET_VIEW_TYPE_TASKS_EDITOR = "tasks-editor";

// --------------------------------------------------------------------------
export const getTargetViewStates = (topLevelEditorState, allowFallbackView = true) => {
  const targetViewByType = targetViewPluginKey.getState(topLevelEditorState) || {};
  const { targetView, targetViewType } = activeTargetView(targetViewByType, allowFallbackView);
  if (!targetView) {
    return { state: topLevelEditorState, historyState: topLevelEditorState, targetViewType: null };
  }

  const state = targetView ? targetView.state : topLevelEditorState;
  const historyState = targetViewType === TARGET_VIEW_TYPE_TASKS_EDITOR ? topLevelEditorState : state;

  return { state, historyState, targetViewType };
};

// --------------------------------------------------------------------------
export const getTargetViews = (topLevelEditorView, allowFallbackView = true) => {
  const targetViewByType = targetViewPluginKey.getState(topLevelEditorView.state) || {};
  const { targetView, targetViewType } = activeTargetView(targetViewByType, allowFallbackView);
  if (!targetView) {
    return { editorView: topLevelEditorView, historyEditorView: topLevelEditorView, targetViewType: null };
  }

  const editorView = targetView || topLevelEditorView;
  const historyEditorView = targetViewType === TARGET_VIEW_TYPE_TASKS_EDITOR ? topLevelEditorView : editorView;

  return { editorView, historyEditorView, targetViewType };
};

// --------------------------------------------------------------------------
const dispatchDummyTransaction = editorView => {
  const { dispatch, state } = editorView;

  const transaction = state.tr.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false)
  dispatch(transaction);
  return !transaction.getMeta(TRANSACTION_META_KEY.REJECTED);
};

// --------------------------------------------------------------------------
const buildFocusChangeHandler = topLevelEditorView => () => {
  if (!dispatchDummyTransaction(topLevelEditorView)) {
    defer(buildFocusChangeHandler(topLevelEditorView));
  }
};

// --------------------------------------------------------------------------
// Note the command returns false here not to indicate that it _couldn't_ work, but to indicate that it was rejected
// because we're in the midst of dispatching a different transaction
export const registerTargetView = (topLevelEditorView, targetView, targetViewType) => (state, dispatch) => {
  if (dispatch) {
    // The blur handler will see the document.body as focused when clicking on a different element shifts focus, but
    // we'd like to know what has been focused when computing the new active view, which won't happen until later
    const onBlur = () => { defer(buildFocusChangeHandler(topLevelEditorView)) };
    const onFocus = buildFocusChangeHandler(topLevelEditorView);

    const transaction = state.tr;
    transaction.setMeta(targetViewPluginKey, { onBlur, onFocus, targetView, targetViewType });
    transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);
    dispatch(transaction);

    if (transaction.getMeta(TRANSACTION_META_KEY.REJECTED)) {
      // TODO this happens when hitting tab in a link - can just let top-level dispatch handle it by dispatching a
      // second transaction?
      defer(() => {
        registerTargetView(topLevelEditorView, targetView, targetViewType)(
          topLevelEditorView.state,
          topLevelEditorView.dispatch
        );
      });
      return true;
    }
  }

  return true;
};

// --------------------------------------------------------------------------
let lastActiveTargetViewType = null;

// --------------------------------------------------------------------------
const activeTargetView = (targetViewByType, allowFallbackView = true) => {
  const keys = Object.keys(targetViewByType);
  if (keys.length === 0) return {};

  let eligibleTargetViewType = null;
  for (let i = 0; i < keys.length; i++) {
    const targetViewType = keys[i];
    const { targetView } = targetViewByType[targetViewType];

    if (!document.body.contains(targetView.dom)) continue;

    if (targetView.dom.contains(document.activeElement)) {
      lastActiveTargetViewType = targetViewType;
      return { targetView, targetViewType };
    } else if (targetViewType === lastActiveTargetViewType) {
      eligibleTargetViewType = targetViewType;
    }
  }
  if (!eligibleTargetViewType) return {};

  if (allowFallbackView) {
    // If the focus is in a non-top-level editor and the user clicks on a toolbar button that doesn't immediately re-
    // focus the editor (i.e. file attachment buttons), we would otherwise default back to the top-level editor, thus
    // preventing the use of those buttons.
    if (!document.activeElement || !document.activeElement.classList.contains("ProseMirror")) {
      const { targetView } = targetViewByType[eligibleTargetViewType];
      return { targetView, targetViewType: eligibleTargetViewType };
    }
  }

  return {};
};

// --------------------------------------------------------------------------
// Returns null if no changes were made
const prunedTargetViewByType = originalTargetViewByType => {
  const targetViewByType = { ...originalTargetViewByType };

  let changed = false;
  Object.keys(targetViewByType).forEach(targetViewType => {
    const { targetView } = targetViewByType[targetViewType];

    if (!document.body.contains(targetView.dom)) {
      unregisterTargetView(targetViewByType, targetViewType);
      changed = true;
    }
  });

  return changed ? targetViewByType : null;
};

// --------------------------------------------------------------------------
const unregisterTargetView = (targetViewByType, targetViewType) => {
  const { onBlur, onFocus, targetView } = targetViewByType[targetViewType];

  targetView.dom.removeEventListener("blur", onBlur, false);
  targetView.dom.removeEventListener("focus", onFocus, false);

  delete targetViewByType[targetViewType];
}

// --------------------------------------------------------------------------
const targetViewPluginKey = new PluginKey("target-view");

// --------------------------------------------------------------------------
// Allows EditorViews embedded in the main editor to be the "target" view that is used for toolbars or other state
// that depends on the editor view.
const targetViewPlugin = new Plugin({
  // --------------------------------------------------------------------------
  key: targetViewPluginKey,

  // --------------------------------------------------------------------------
  state: {
    // Plugin state keys are targetViewType strings; values are shaped as:
    // {
    //    onBlur: function,
    //    onFocus: function,
    //    targetView: EditorView,
    //  }
    init: (_config, _state) => ({}),
    apply: (tr, pluginState, _oldState, _state) => {
      const meta = tr.getMeta(targetViewPluginKey);
      if (meta) {
        const { onBlur, onFocus, targetView, targetViewType } = meta;
        const targetViewByType = prunedTargetViewByType(pluginState) || { ...pluginState };

        if (targetViewType in targetViewByType) {
          unregisterTargetView(targetViewByType, targetViewType);
        }

        targetView.dom.addEventListener("blur", onBlur, false);
        targetView.dom.addEventListener("focus", onFocus, false);

        targetViewByType[targetViewType] = { onBlur, onFocus, targetView };

        return targetViewByType;
      } else {
        const newPluginState = prunedTargetViewByType(pluginState);
        if (newPluginState) return newPluginState;
      }

      return pluginState;
    },
  },
});
export default targetViewPlugin;

import { closeHistory } from "prosemirror-history"
import React from "react"
import ReactDOM from "react-dom"

import { positionPopupContainer } from "lib/ample-editor/lib/popup-util"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import {
  emojiPopperPluginKey,
  emojiSelectorString,
  openEmojiPopperTextStartPos,
} from "lib/ample-editor/plugins/emoji-popper"

import "emoji-mart/css/emoji-mart.css"

// --------------------------------------------------------------------------
const MAX_POPUP_WIDTH = 350; // Derived by inspecting emoji mart popper

// --------------------------------------------------------------------------
export const LazyNimblePicker = React.lazy(async () => {
  try {
    return await import(/* webpackChunkName:"emoji-data" */"lib/ample-editor/components/nimble-picker-with-data");
  /* eslint-disable no-unreachable */
  } catch (_error) {
    // ChunkLoadError - i.e. the chunk can't be loaded due to network error, or whatever. This can also be handled
    // by a higher-level react error boundary, but there are some gotchas in how the error boundary has to render
    // the children (see https://github.com/facebook/react/issues/14254) so this is simpler
    // eslint-disable-next-line no-undef,global-require
    return require("lib/ample-editor/components/nimble-picker-failure");
  }
});

// --------------------------------------------------------------------------
export default class EmojiPopperView {
  _isOpen = false;
  _lastQuery = null;
  _picker = null;

  // --------------------------------------------------------------------------
  constructor(view) {
    this._insertEmoji = this._insertEmoji.bind(this, view);

    this.dom = document.createElement("div");
    this.dom.className = "emoji-popper-container";
    if (view.dom.parentNode) view.dom.parentNode.appendChild(this.dom);

    this.update(view);
  }

  // --------------------------------------------------------------------------
  handleKeyDown(view, event) {
    if (!this._isOpen) return false;

    switch (event.key) {
      case "Escape":
        if (this._close(view)) return true;
        break;

      case "Enter":
      case "Tab": {
        const { suggestedEmoji } = emojiPopperPluginKey.getState(view.state);
        if (suggestedEmoji && this._insertEmoji(suggestedEmoji)) return true;
      }
      break;

      default:
        break;
    }

    return false;
  }

  // --------------------------------------------------------------------------
  // Purpose picked a slightly uglier name so Webstorm's cmd-space looks up its definition
  searchPicker(query) {
    this._lastQuery = query;
    if (!this._picker) return null;

    const emojiIndex = this._picker.search ? this._picker.search.emojiIndex : null;

    if (query && emojiIndex) {
      const matchingEmojis = emojiIndex.search(query);
      this._picker.handleSearch(matchingEmojis);

      let suggestedEmoji;
      if (matchingEmojis && matchingEmojis.length > 0) {
        suggestedEmoji = matchingEmojis[0];
      } else {
        suggestedEmoji = null;
      }

      this._picker.handleEmojiOver(suggestedEmoji);

      return suggestedEmoji;
    }

    return null;
  }

  // --------------------------------------------------------------------------
  update(editorView) {
    const popperPos = openEmojiPopperTextStartPos(editorView.state);

    if (popperPos !== null) {
      if (this.dom) {
        this._isOpen = true;

        // Options at https://github.com/missive/emoji-mart#components
        ReactDOM.render(
          <React.Suspense fallback={ <div className="emoji-mart-loading">Loading...</div> }>
            <LazyNimblePicker
              emoji="point_up"
              native={ true }
              onSelect={ this._insertEmoji }
              pickerRef={ this._setPicker }
              sheetSize={ 32 }
              title="Pick your emoji..."
            />
          </React.Suspense>,
          this.dom
        );

        positionPopupContainer(editorView, this.dom, MAX_POPUP_WIDTH, popperPos);
      }
    } else {
      this._isOpen = false;
      ReactDOM.unmountComponentAtNode(this.dom);
    }
  }

  // --------------------------------------------------------------------------
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom);
    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  _close = view => {
    const { dispatch, state } = view;

    const textStartPos = openEmojiPopperTextStartPos(state);
    if (textStartPos === null) return false;

    dispatch(state.tr.setMeta(emojiPopperPluginKey, { cancelStartPos: textStartPos }).setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false));

    return true;
  };

  // --------------------------------------------------------------------------
  _insertEmoji(view, emoji) {
    const { dispatch, state } = view;

    const textStartPos = openEmojiPopperTextStartPos(state);
    if (textStartPos === null) return false;

    const emojiString = emojiSelectorString(textStartPos, state) || "";

    const transaction = state.tr;
    transaction.insertText(emoji.native, textStartPos, textStartPos + emojiString.length + 1); // 1 covers the colon before the string
    transaction.setMeta(emojiPopperPluginKey, { start: null });
    closeHistory(transaction);
    dispatch(transaction);

    view.focus();

    return true;
  }

  // --------------------------------------------------------------------------
  _setPicker = picker => {
    this._picker = picker;

    // Since the picker is lazy loaded, and searched imperatively, we need to trigger the search when it first mounts,
    // in case there was input while it was loading
    if (picker && this._lastQuery) {
      this.searchPicker(this._lastQuery);
    }
  };
}

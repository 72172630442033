import { escape } from "lodash"

// --------------------------------------------------------------------------
const WORD_DELIMITER = /[\s/-]/; // This pattern must match exactly one UTF-16 code unit (that's a very likely concern when extending to handle CJK that don't use spaces as word delimiters).

// --------------------------------------------------------------------------
// Score the match based on how many words overlap
// Returns keys { rendered: html, score: numeric score, inputFullWordOverlap: true } iff all the words in lowerInputString are found
//   null if not every word from `lowerInputString` is present in `compareString`
function deriveWordwiseMatch(lowerInputString, compareString, string, matchPrefix, matchSuffix, shouldEscapeHTML) {
  const inputWords = lowerInputString.trim().split(WORD_DELIMITER);
  const compareWords = compareString.split(WORD_DELIMITER);
  let carryScore = 0;
  let compareWordIndex = -1;
  const result = [];
  let score = 0;

  const maybeEscape = shouldEscapeHTML ? escape : text => text;

  const match = inputWords.every(inputWord => {
    // Find the next word in compareWords that starts with inputWord
    compareWordIndex = compareWords.findIndex((compareWord, checkedIndex) => (checkedIndex > compareWordIndex) && compareWord.startsWith(inputWord));

    if (compareWordIndex === -1) {
      // A future version of this might fall back to character-wise searching here?
      return false;
    } else {
      let startPos = compareWords.slice(0, compareWordIndex).join(" ").length;
      if (compareWordIndex > 0) {
        // +1 for the space after the final word prior to match onset (except for when match is first word, when there is no preceding space)
        startPos += 1;
      }
      const matchWord = compareWords[compareWordIndex];
      let stillMatching = true;

      // Push all the unmatched characters prior to our current match
      for (let i = result.length; i < startPos; i++) {
        result.push(maybeEscape(string[i]));
      }

      for (let i = 0; i < matchWord.length; i++) {
        const word = string[result.length];

        if (stillMatching && matchWord[i] === inputWord[i]) {
          carryScore += 1;
          score += carryScore;
          result.push(matchPrefix + maybeEscape(word) + matchSuffix);
        } else {
          stillMatching = false;
          carryScore = 0;
          result.push(maybeEscape(word));
        }
      }
    }

    return true;
  });

  if (match && result.length) {
    if (result.length < string.length) {
      result.push(string.slice(result.length));
    }

    return {
      inputFullWordOverlap: true,
      rendered: result.join(""),
      score,
      text: compareString,
    };
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
function isDelimiter(character) {
  return character && character.match(WORD_DELIMITER);
}

// --------------------------------------------------------------------------
// Originally inlined from the `fuzzy` lib. Tested across a variety of fuzzy-match-*.test.js files in ample-notes-store/util
//
// Returns either null, if the entirety of `pattern` was not found, or an object with `rendered` and `score` keys if match was found
//
// Inputs deduced to include (in the context of Quick Open):
// `pattern`: The input string given by the user. Must be matched in its entirety for a non-null result to be returned.
// `string`: The string to match
// `matchPrefix`: Passthrough variable to prepend to each character of a match, e.g., <b
// `matchSuffix`: Passthrough variable to append to each character of a match, e.g., </bOtherwise returns >
// `minimumMatchLength` match runs shorter than this will not contribute to an increased match score. If no instances of `pattern` of at least minimumMatchLength are found in `string`, null is returned
//
// Returns null if not all the characters of `pattern` appear in `string` in the order present from pattern.
// Also returns null if minimumMatchLength > 1 and no `pattern` substring of `minimumMatchLength` can be found in `string`
//
// Otherwise returns { rendered: html string, score: float, inputFullWordOverlap: bool, text: the `string` that matched }
export default function fuzzyMatch(pattern, string, matchPrefix, matchSuffix, { minimumMatchLength = 1, shouldEscapeHTML = false } = {}) {
  const compareString = string.toLowerCase();
  const inputStringLength = pattern.length;
  const length = string.length;
  const lowerInputString = pattern.toLowerCase();
  if (!inputStringLength || !length) return null;
  matchPrefix = matchPrefix || "";
  matchSuffix = matchSuffix || "";

  let carryScore = 0;
  const letterwiseResult = []; // An array of the string's letter, with matched characters surrounded in matchPrefix/matchSuffix
  let letterwiseScore = 0;
  let matchFound = false; // All of the characters from `pattern` were found somewhere in `string`, while abiding the rules of `minimumMatchLength`
  let matchFullyConsecutive = true;
  let patternIndex = 0;
  let wordwiseMatch = null;

  const maybeEscape = shouldEscapeHTML ? escape : text => text;

  for (let i = 0; i < length; i++) {
    const userInputAtIndex = lowerInputString[patternIndex];
    if (compareString[i] === userInputAtIndex || (isDelimiter(compareString[i]) && isDelimiter(userInputAtIndex))) {
      letterwiseResult.push(matchPrefix + maybeEscape(string[i]) + matchSuffix);
      patternIndex += 1;
      if (string[i] !== " ") { // No points for matching blank spaces, helps improve parity vs the wordwiseScore mechanism, which also doesn't give points for space
        carryScore += 1; // The more consecutive characters in a row, the greater the match score
        letterwiseScore += carryScore;
      }

      if (patternIndex === inputStringLength) {
        matchFound = true;

        for (i = i + 1; i < length; i++) {
          letterwiseResult.push(maybeEscape(string[i])); // Run out the match result
        }
        break;
      }
    } else {
      if (carryScore > 0) {
        if (carryScore < minimumMatchLength) {
          patternIndex -= carryScore; // Sorry pattern, you have not advanced if not by minimumMatchLength
        }

        // It might yet be fully consecutive after sending them back to the beginning of the pattern
        if (patternIndex > 0) {
          matchFullyConsecutive = false;
        }
      }
      letterwiseResult.push(maybeEscape(string[i]));
      carryScore = 0;
    }
  }

  if (matchFound) {
    if (!matchFullyConsecutive) {
      // If we didn't already get max score, we'll check how the score of a wordwise match would compare
      wordwiseMatch = deriveWordwiseMatch(lowerInputString, compareString, string, matchPrefix, matchSuffix, shouldEscapeHTML);
    }

    if (!wordwiseMatch || letterwiseScore > wordwiseMatch.score) {
      return {
        inputFullWordOverlap: matchFullyConsecutive,
        rendered: letterwiseResult.join(""),
        score: letterwiseScore,
        text: compareString,
      };
    } else {
      return wordwiseMatch;
    }
  }

  return null;
}

import { DOMSerializer } from "prosemirror-model"
import { TableMap } from "prosemirror-tables"

import { isCycleVarColor } from "lib/ample-editor/lib/color-util"
import { TABLE_DEPTH } from "lib/ample-editor/lib/table/table-constants"
import { calculateFormula } from "lib/ample-editor/lib/table/table-plugin-formula"
import { CELL_CONTENT_CLASS, CELL_INNER_CONTENT_CLASS } from "lib/ample-editor/lib/table/table-schema"
import { tableFromPos, textMatchesFormula } from "lib/ample-editor/lib/table/table-util"

// --------------------------------------------------------------------------
function createElement(nodeType, className = null) {
  const element = document.createElement(nodeType)
  if (className) element.className = className
  return element;
}

// --------------------------------------------------------------------------
// Doles out node attrs to appropriate dom styles & classes for td and its inner border divs
// We have to nest multiple divs within the td to provide borders because when a table has border-collapse applied,
// the arbitrary criteria of "what cell is upper left?" dictates when borders appear. We also need
// divs to provider low opacity overlay on cells to demarcate when they're selected.
export default class TableCellView {
  // --------------------------------------------------------------------------
  constructor(node, view, getPos) {
    this._domSerializer = DOMSerializer.fromSchema(view.state.schema);
    this._node = node;

    this.dom = document.createElement("td");
    const borderDom = createElement("div", "border-content user-chosen");
    this.dom.appendChild(borderDom);
    // Cell content uses flex & direction: column to allow for vertical centering...
    const cellContentParentDom = createElement("div", CELL_CONTENT_CLASS);
    borderDom.appendChild(cellContentParentDom);
    // ...but we don't want every otherwise-inline element in a cell with hard breaks to be its own line, as is
    // the case if the cell contents are direct descendants of CELL_CONTENT_CLASS's flex column. So we group cell contents in:
    const cellContentDom = createElement("div", CELL_INNER_CONTENT_CLASS);
    cellContentParentDom.appendChild(cellContentDom);
    const selectionBorderDom = createElement("div", "border-content selection-outline");
    borderDom.appendChild(selectionBorderDom);
    const selectionBorderDomOverlay = createElement("div", "border-overlay");
    selectionBorderDom.appendChild(selectionBorderDomOverlay);
    this.contentDOM = cellContentDom;

    this.setSelection = this.setSelection.bind(this, view, getPos);
    this.update = this.update.bind(this, view, getPos);
    this.update(node, []);
  }

  // --------------------------------------------------------------------------
  update(view, getPos, node) {
    if (node.type.spec.tableRole !== "cell") return false;
    if (!Number.isInteger(getPos()) || getPos() >= view.state.doc.content.size) return false;

    const { state: { selection } } = view;
    const { columnIndex, rowIndex } = this._getColumnRow(view, getPos());

    const formulaRenderable = this._applyFormulaRenderable(view, selection, getPos, node);

    if (!formulaRenderable) {
      const formula = this.dom.querySelector(".formula-result");
      if (formula) formula.remove();
      this.contentDOM.className = CELL_INNER_CONTENT_CLASS;
    }
    const attrs = node.attrs;

    this._applyAlign(attrs.align)

    if (attrs.colwidth) {
      this.dom.style.width = `${ attrs.colwidth }px`;
    } else {
      this.dom.style.removeProperty("width");
    }

    this._applyBorders(attrs);

    const { backgroundColor, color } = attrs;
    this._applyBackgroundColor(backgroundColor);
    this._applyForegroundColor(color);

    // Observed once in a month when user's getPos() somehow got out of sync with doc, causing inability to look up $table
    if (columnIndex !== null) {
      this.dom.setAttribute("data-column-index", columnIndex);
      this.dom.setAttribute("data-row-index", rowIndex);
    }
    return true;
  }

  // --------------------------------------------------------------------------
  _applyFormulaRenderable(view, selection, getPos, node) {
    const formulaCell = textMatchesFormula(node.textContent);

    let formulaRenderable = false;
    if (formulaCell && (!selection.$head || selection.$head.pos < getPos() ||
        selection.$head.pos > getPos() + node.nodeSize)) {
      formulaRenderable = this._renderFormula(view, getPos);
      if (formulaRenderable) {
        this.contentDOM.className = `${ CELL_INNER_CONTENT_CLASS } valid-formula`;
      }
    }

    return formulaRenderable;
  }

  // --------------------------------------------------------------------------
  _applyBorders(attrs) {
    [ "Bottom", "Left", "Right", "Top" ].forEach(direction => {
      const borderContentEl = this.dom.querySelector(".border-content.user-chosen");
      if (borderContentEl) {
        if (attrs[`border${ direction }`]) {
          borderContentEl.setAttribute(`data-border-${ direction }`, "true");
        } else {
          borderContentEl.removeAttribute(`data-border-${ direction }`);
        }
      }
    });
  }

  // --------------------------------------------------------------------------
  _applyAlign(align) {
    if (align) {
      // const textAlignStyle = `text-align: ${ node.attrs.align }`; // Requisite for Sheets to maintain alignment
      const justifyFromAlign = { left: "flex-start", center: "center", right: "flex-end" };
      this.dom.style.textAlign = align;
      this.dom.style.alignItems = justifyFromAlign[align];
      this.dom.setAttribute("data-align", align);
    } else {
      this.dom.style.textAlign = "";
      this.dom.style.alignItems = "";
      this.dom.removeAttribute("data-align");
    }
  }

  // --------------------------------------------------------------------------
  _applyBackgroundColor(backgroundColor) {
    if (isCycleVarColor(backgroundColor)) {
      // Ugliness of deleting alternate color designation is deemed acceptable by WBH as of Nov 2022 since
      // next best option would seem to be to capture all rendered attrs & return false on update() when they aren't dequal() :(
      this.dom.style.backgroundColor = "";
      this.dom.setAttribute("data-background-color", backgroundColor.replace("cycle-color-", ""));
    } else if (backgroundColor) {
      this.dom.style.backgroundColor = backgroundColor;
      this.dom.removeAttribute("data-background-color");
    } else {
      this.dom.style.backgroundColor = "";
      this.dom.removeAttribute("data-background-color");
    }
  }

  // --------------------------------------------------------------------------
  _applyForegroundColor(color) {
    if (isCycleVarColor(color)) {
      this.dom.style.color = "";
      this.dom.setAttribute("data-text-color", color.replace("cycle-color-", ""));
    } else if (color) {
      this.dom.style.color = color;
      this.dom.removeAttribute("data-text-color");
    } else {
      this.dom.style.color = "";
      this.dom.removeAttribute("data-text-color");
    }
  }

  // --------------------------------------------------------------------------
  _getColumnRow(view, pos) {
    const { state: { doc } } = view;
    const $table = tableFromPos(doc.resolve(pos));
    if (!$table) return { columnIndex: null, rowIndex: null };
    const map = TableMap.get($table.nodeAfter);
    const rect = map.findCell(pos - $table.start(TABLE_DEPTH));

    return { columnIndex: rect.left, rowIndex: rect.top };
  }

  // --------------------------------------------------------------------------
  setSelection(view, getPos, head, anchor, root, force = false) {
    const cellDesc = view.docView.getDesc(this.dom);
    const childrenSize = cellDesc.children.reduce((acc, child) => acc + child.size, 0);
    const from = Math.min(anchor, head);
    const to = Math.max(anchor, head);
    if (from === 0 && to === childrenSize) {
      // Prevents mobile Safari from trying to additionally select the text within the cell
    } else {
      // This emulates what PM's ViewDesc.setSelection does to traverse children, delegating selection setting to them
      for (let i = 0, offset = 0; i < cellDesc.children.length; i++) {
        const child = cellDesc.children[i]
        const end = offset + child.size;
        if (from > offset && to < end) {
          // If a formula-result is present in this cell when user puts their focus in it, remove the formula
          // result so they can edit the text itself
          if (this.dom.querySelector(".formula-result")) {
            this.dom.querySelector(".valid-formula").classList.remove("valid-formula");
            this.dom.querySelector(".formula-result").remove();
          }

          return child.setSelection(head - offset - child.border, anchor - offset - child.border, root, force);
        }
        offset = end;
      }
      // As of Dec 2022 WBH observes this case will be reached if selection spans multiple children
      // In these cases selection still functions as expected, so some secondary mechanism is taking care of setting/showing
      // selection across those children. May need to figure out what mechanism that is, if multi-children
      // selection in cells behaves poorly. There is no straightforward way to call PM's ViewDesc.setSelection as backup,
      // but it is theoretically possible, should that later prove necessary
    }
  }

  // --------------------------------------------------------------------------
  _isCellNodeEmpty(cellNode) {
    return (cellNode.childCount === 1 && cellNode.firstChild.type.name === "paragraph" && cellNode.firstChild.content.size === 0);
  }

  // --------------------------------------------------------------------------
  // Return true if a formula of some sort could be rendered, false if this doesn't appear to be a renderable node
  _renderFormula(view, getPos) {
    const $cell = view.state.doc.resolve(getPos());
    if (!$cell || !$cell.nodeAfter || $cell.nodeAfter.type.spec.tableRole !== "cell") return false;
    const value = calculateFormula($cell);
    if (value !== null) {
      // If we have a cell and it has a valid formula, then we know we have two levels of depth below the main table cell
      // (for paragraph and paragraph contents)
      const $cellContent = view.state.doc.resolve(getPos() + 1);
      const contentNode = $cellContent.nodeAfter;
      const contentDOM = this._domSerializer.serializeNode(contentNode);

      // If formula cell is a hodgepodge of marked and unmarked text, this will strip them down such that our result is only the value
      const stripExtraneousElements = (element, text) => {
        if (element.firstElementChild) {

          while (element.firstChild !== element.firstElementChild) {
            element.removeChild(element.firstChild);
          }

          while (element.lastChild !== element.firstElementChild) {
            element.removeChild(element.lastChild);
          }

          stripExtraneousElements(element.firstElementChild, text);

        } else {
          element.textContent = text;
        }
      };
      stripExtraneousElements(contentDOM, value);

      const formulaEl = this.dom.querySelector(".formula-result");
      if (formulaEl) {
        formulaEl.textContent = ""; // clearing children
        formulaEl.appendChild(contentDOM);
      } else {
        const newFormulaEl = createElement("div", "formula-result");
        newFormulaEl.appendChild(contentDOM);
        const formulaParentEl = this.dom.querySelector(`.${ CELL_CONTENT_CLASS }`) || this.dom.querySelector(".border-content");
        formulaParentEl.appendChild(newFormulaEl);
      }
      return true;
    } else {
      return false;
    }
  }
}

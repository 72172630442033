/* eslint-disable */

// --------------------------------------------------------------------------
// This was originally inlined from prosemirror-markdown to modify various
// behaviors
// --------------------------------------------------------------------------

import { flatten, omit, partition, pickBy } from "lodash"
import { Node } from "prosemirror-model"

import { hexColorFromCompoundColor, isCycleVarColor, numberFromCycleColor } from "lib/ample-editor/lib/color-util"
import { imageFromLinkAttributes } from "lib/ample-editor/lib/link-util"
import { descriptionDocumentFromValue } from "lib/ample-editor/lib/rich-footnote-util"
import TABLE_CELL_ATTRIBUTE_DEFAULTS from "lib/ample-editor/lib/table/table-cell-attribute-defaults"
// Note that the exporter also is expected to work with the description schema, but this is necessary to support
// hidden tasks
import { schema } from "lib/ample-editor/schema"
import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import { checkListItemFromCompletedTask, flagsObjectFromFlagsString, isDoneFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// Some markdown parsers require 4 spaces for an indented (nested) list
const LIST_ITEM_INDENT = "    ";

const CSS_HEX_COLOR_PATTERN = /^#(?:[\dA-F]{3,4}|[\dA-F]{6}|[\dA-F]{8})$/i;
const CSS_VARIABLE_COLOR_PATTERN = /^var\(--[\w\-]+\)$/

// --------------------------------------------------------------------------
// List items don't support some block content that would otherwise be allowed, like blockquotes, so we need
// to escape it when we get to the start of the text in the list item.
function escapeListItemContent(state, text) {
  return (text || "").replace(/^[(:#\-+>]/, "\\$&").replace(/^(\d+)\./, "$1\\.");
}

// --------------------------------------------------------------------------
// Not intended to handle much, just basic (valid) CSS hex colors and CSS variables
function isValidColorValue(value) {
  return value && (CSS_HEX_COLOR_PATTERN.test(value) || CSS_VARIABLE_COLOR_PATTERN.test(value));
}

// --------------------------------------------------------------------------
function serializeInlineAttributes(inlineAttributes) {
  if (inlineAttributes && Object.keys(inlineAttributes).length > 0) {
    return "<!-- " + JSON.stringify(inlineAttributes) + " -->";
  } else {
    return "";
  }
}

// --------------------------------------------------------------------------
function withoutDefaultAttributes(type, attrs) {
  const defaultAttributesByNodeName = DEFAULT_ATTRIBUTES_BY_NODE_NAME[type];

  return pickBy(attrs, (value, key) => {
    return !(key in defaultAttributesByNodeName) || value !== defaultAttributesByNodeName[key];
  });
}

// --------------------------------------------------------------------------
function withScheduledListItemAttributes(nonDefaultAttributes) {
  const inlineAttributes = {};

  if ("duration" in nonDefaultAttributes) {
    inlineAttributes.duration = nonDefaultAttributes.duration;
  }

  if ("notify" in nonDefaultAttributes) {
    inlineAttributes.notify = nonDefaultAttributes.notify;
  }

  return inlineAttributes;
}

// --------------------------------------------------------------------------
function writeBulletListItemAttributes(state, attrs, { writeIndent = false } = {}) {
  const nonDefaultAttributes = withoutDefaultAttributes("bullet_list_item", attrs);

  const inlineAttributes = withScheduledListItemAttributes(nonDefaultAttributes);

  if (writeIndent && "indent" in attrs) {
    inlineAttributes.indent = attrs.indent;
  }

  if ("scheduledAt" in nonDefaultAttributes) {
    inlineAttributes.startAt = nonDefaultAttributes.scheduledAt;
  }

  writeInlineAttributes(state, inlineAttributes);
}

// --------------------------------------------------------------------------
// Note that the names of attributes are translated from internal naming scheme to
// an external naming scheme that differs in some cases.
function writeCheckListItemInlineAttributes(state, attrs, { writeIndent = false } = {}) {
  const nonDefaultAttributes = withoutDefaultAttributes("check_list_item", attrs);

  const inlineAttributes = withScheduledListItemAttributes(nonDefaultAttributes);

  if ("startRule" in nonDefaultAttributes) {
    inlineAttributes.hideRule = nonDefaultAttributes.startRule;
  }

  if ("startAt" in nonDefaultAttributes) {
    inlineAttributes.hideUntil = nonDefaultAttributes.startAt;
  }

  if (writeIndent && "indent" in nonDefaultAttributes) {
    inlineAttributes.indent = nonDefaultAttributes.indent;
  }

  if ("flags" in nonDefaultAttributes) {
    const { flags: flagsString } = nonDefaultAttributes;
    const flags = flagsObjectFromFlagsString(flagsString);
    if (flags.important) inlineAttributes.important = true;
    if (flags.urgent) inlineAttributes.urgent = true;
  }

  if ("due" in nonDefaultAttributes) {
    inlineAttributes.startAt = nonDefaultAttributes.due;
  }

  if ("uuid" in nonDefaultAttributes) {
    inlineAttributes.uuid = nonDefaultAttributes.uuid;
  }

  writeInlineAttributes(state, inlineAttributes);
}

// --------------------------------------------------------------------------
function writeInlineAttributes(state, inlineAttributes) {
  if (Object.keys(inlineAttributes).length > 0) {
    state.out += serializeInlineAttributes(inlineAttributes);
  }
}

// --------------------------------------------------------------------------
function writeNumberListItemAttributes(state, attrs, { writeIndent = false } = {}) {
  const nonDefaultAttributes = withoutDefaultAttributes("number_list_item", attrs);

  const inlineAttributes = {};

  if (writeIndent && "indent" in attrs) {
    inlineAttributes.indent = attrs.indent;
  }

  if ("offset" in nonDefaultAttributes) {
    inlineAttributes.offset = nonDefaultAttributes.offset;
  }

  writeInlineAttributes(state, inlineAttributes);
}

// --------------------------------------------------------------------------
function writeTableCellInlineAttributes(state, attrs) {
  const inlineAttributes = {};

  Object.keys(TABLE_CELL_ATTRIBUTE_DEFAULTS).forEach(key => {
    if (key in attrs && attrs[key] !== TABLE_CELL_ATTRIBUTE_DEFAULTS[key]) {
      if (!inlineAttributes.cell) inlineAttributes.cell = {};
      inlineAttributes.cell[key] = attrs[key];
    }
  });

  writeInlineAttributes(state, inlineAttributes);
}

// --------------------------------------------------------------------------
// ::- This is an object used to track state and expose
// methods related to markdown serialization. Instances are passed to
// node and mark serialization methods (see `toMarkdown`).
class MarkdownSerializerState {
  // --------------------------------------------------------------------------
  constructor(nodes, marks, footnoteState) {
    this.closed = false;
    this.delim = this.out = "";
    this.footnoteCount = 0;
    this.footnoteState = footnoteState;
    this.inTable = false;
    this.lastListItemSpacesIndent = -1;
    this.marks = marks;
    this.nodes = nodes;
    this.shouldEscapeListItemContent = false;
  }

  flushClose(size) {
    if (this.closed) {
      if (!this.atBlank()) this.out += "\n";
      if (size == null) size = 2;
      if (size > 1) {
        let delimMin = this.delim;
        let trim = /\s+$/.exec(delimMin);
        if (trim) delimMin = delimMin.slice(0, delimMin.length - trim[0].length);
        for (let i = 1; i < size; i++)
          this.out += delimMin + "\n"
      }
      this.closed = false
    }
  }

  // :: (string, ?string, Node, ())
  // Render a block, prefixing each line with `delim`, and the first
  // line in `firstDelim`. `node` should be the node that is closed at
  // the end of the block, and `f` is a function that renders the
  // content of the block.
  wrapBlock(delim, firstDelim, node, f) {
    let old = this.delim;
    this.write(firstDelim || delim);
    this.delim += delim;
    f();
    this.delim = old;
    this.closeBlock(node)
  }

  atBlank() {
    return /(^|\n)$/.test(this.out)
  }

  // :: ()
  // Ensure the current content ends with a newline.
  ensureNewLine() {
    if (!this.atBlank()) this.out += "\n"
  }

  // :: (?string)
  // Prepare the state for writing output (closing closed paragraphs,
  // adding delimiters, and so on), and then optionally add content
  // (unescaped) to the output.
  write(content) {
    this.flushClose();
    if (this.delim && this.atBlank())
      this.out += this.delim;
    if (content) this.out += content
  }

  writeFootnote(node, href, image, renderDescription) {
    if (!this.footnoteState) return null;

    this.footnoteCount += 1;
    if (this.footnoteCount === 1) this.footnoteState.write("\n\n");

    this.footnoteState.ensureNewLine();
    this.footnoteState.write(`[^${ this.footnoteCount }]: `);

    if (node) {
      this.footnoteState.write("[");
      this.footnoteState.renderInline(node);
      this.footnoteState.write("](");
      if (href) this.footnoteState.write(this.footnoteState.esc(href));
      this.footnoteState.write(")");
      this.footnoteState.write("\n\n");
    }

    if (renderDescription) {
      // Subsequent lines must be indented to be considered part of the footnote
      this.footnoteState.delim = "    ";
      renderDescription(this.footnoteState);
      this.footnoteState.delim = "";
      this.footnoteState.flushClose();
    }

    if (image) this.footnoteState.write(`    ![](${ image })\n\n`);

    return this.footnoteCount;
  }

  writeMediaText(text) {
    let footnoteNumber = null;
    if (text) {
      footnoteNumber = this.writeFootnote(null, null, null, footnoteState => {
        footnoteState.write(footnoteState.esc(text).replace(/^\s*(.)/mg, "    $1").replace(/^\s+/, ""));

        footnoteState.delim = "";
        footnoteState.ensureNewLine();
        footnoteState.write("\n");
      });
    }

    return footnoteNumber !== null ? ` [^${ footnoteNumber }]` : "";
  }

  // :: (Node)
  // Close the block for the given node.
  closeBlock(node) {
    this.closed = node
  }

  // When jumping more than one indent level, markdown consider the list items contiguous, so we need to move
  // the indent to an inline attribute instead
  listItemLeadingSpace(indent) {
    const maxSpaceBasedIndent = this.lastListItemSpacesIndent + 1;
    const canFullyIndentWithSpaces = indent <= maxSpaceBasedIndent;

    const leadingSpace = this.repeat(
      LIST_ITEM_INDENT,
      canFullyIndentWithSpaces ? indent : maxSpaceBasedIndent
    );

    this.lastListItemSpacesIndent = canFullyIndentWithSpaces ? indent : maxSpaceBasedIndent;

    return {
      leadingSpace,
      shouldWriteIndentAttribute: !canFullyIndentWithSpaces,
    };
  }

  // :: (string, ?bool)
  // Add the given text to the document. When escape is not `false`,
  // it will be escaped.
  text(text, escape) {
    const lines = text.split("\n");
    for (let i = 0; i < lines.length; i++) {
      const startOfLine = this.atBlank() || this.closed;
      this.write();

      const lineOutput = escape !== false ? this.esc(lines[i], startOfLine) : lines[i];

      if (this.shouldEscapeListItemContent && i === 0) {
        this.out += escapeListItemContent(this, lineOutput);
        this.shouldEscapeListItemContent = false;
      } else {
        this.out += lineOutput;
      }

      if (i !== lines.length - 1) this.out += "\n";
    }
  }

  // :: (Node)
  // Render the given node as a block.
  render(node, parent, index) {
    if (typeof parent === "number") throw new Error("!");
    const nodeConverter = this.nodes[node.type.name];
    if (nodeConverter) nodeConverter(this, node, parent, index);
  }

  // :: (Node)
  // Render the contents of `parent` as block nodes.
  renderContent(parent, isTopLevelNode = false) {
    parent.forEach((node, _, i) => {
      this.render(node, parent, i);

      if (isTopLevelNode && !node.type.spec.isListItem) {
        this.lastListItemSpacesIndent = -1;
      }
    });
  }

  // :: (Node)
  // Render the contents of `parent` as inline content.
  renderInline(parent) {
    let active = [], trailing = "";
    let progress = (node, _, index) => {
      let marks = node ? node.marks : [];

      // Remove marks from `hard_break` that are the last node inside
      // that mark to prevent parser edge cases with new lines just
      // before closing marks.
      // (FIXME it'd be nice if we had a schema-agnostic way to
      // identify nodes that serialize as hard breaks)
      if (node && node.type.name === "hard_break")
        marks = marks.filter(m => {
          if (index + 1 == parent.childCount) return false;
          let next = parent.child(index + 1);
          return m.isInSet(next.marks) && (!next.isText || /\S/.test(next.text))
        });

      let leading = trailing;
      trailing = "";
      // If whitespace has to be expelled from the node, adjust
      // leading and trailing accordingly.
      if (node && node.isText && marks.some(mark => {
        let info = this.marks[mark.type.name];
        return info && info.expelEnclosingWhitespace;
      })) {
        let [_, lead, inner, trail] = /^(\s*)(.*?)(\s*)$/m.exec(node.text);
        leading += lead;
        trailing = trail;
        if (lead || trail) {
          node = inner ? node.withText(inner) : null;
          if (!node) marks = active
        }
      }

      let inner = marks.length && marks[marks.length - 1];
      let noEsc = inner && this.marks[inner.type.name].escape === false;
      let len = marks.length - (noEsc ? 1 : 0);

      // We want non-mixable marks to be last, so they are the innermost marks in markdown, e.g. for inline code marks,
      // which can't have other marks inside them or they will be interpreted as literal code characters
      marks = flatten(partition(marks, mark => this.marks[mark.type.name].mixable));

      // Try to reorder 'mixable' marks, such as em and strong, which
      // in Markdown may be opened and closed in different order, so
      // that order of the marks for the token matches the order in
      // active.
      outer: for (let i = 0; i < len; i++) {
        let mark = marks[i];
        if (!this.marks[mark.type.name].mixable) break;
        for (let j = 0; j < active.length; j++) {
          let other = active[j];
          if (!this.marks[other.type.name].mixable) break;
          if (mark.eq(other)) {
            if (i > j)
              marks = marks.slice(0, j).concat(mark).concat(marks.slice(j, i)).concat(marks.slice(i + 1, len));
            else if (j > i)
              marks = marks.slice(0, i).concat(marks.slice(i + 1, j)).concat(mark).concat(marks.slice(j, len));
            continue outer
          }
        }
      }

      // Find the prefix of the mark set that didn't change
      let keep = 0;
      while (keep < Math.min(active.length, len) && marks[keep].eq(active[keep])) ++keep;

      // Close the marks that need to be closed
      while (keep < active.length)
        this.text(this.markString(active.pop(), false, parent, index), false);

      // Output any previously expelled trailing whitespace outside the marks
      if (leading) this.text(leading);

      // Open the marks that need to be opened
      if (node) {
        while (active.length < len) {
          let add = marks[active.length];
          active.push(add);
          this.text(this.markString(add, true, parent, index), false)
        }

        // Render the node. Special case code marks, since their content
        // may not be escaped.
        if (noEsc && node.isText)
          this.text(
            this.markString(inner, true, parent, index) + node.text + this.markString(inner, false, parent, index + 1),
            false
          );
        else
          this.render(node, parent, index)
      }
    };
    parent.forEach(progress);
    progress(null, null, parent.childCount);
  }

  renderTable(node) {
    // Need to know when we're in a table so we can avoid closing paragraph nodes with newlines, as we expect a table
    // cell to have precisely one paragraph node, and markdown tables can't include newlines
    this.inTable = true;

    this.renderContent(node);

    this.inTable = false;
  }

  escapeListItemContent(callback) {
    const shouldEscapeListItemContentWas = this.shouldEscapeListItemContent;
    try {
      this.shouldEscapeListItemContent = true;
      callback();
    } finally {
      this.shouldEscapeListItemContent = shouldEscapeListItemContentWas;
    }
  }

  // :: (string, ?bool) → string
  // Escape the given string so that it can safely appear in Markdown
  // content. If `startOfLine` is true, also escape characters that
  // has special meaning only at the start of the line.
  esc(str, startOfLine) {
    str = (str || "").replace(/[`*\\~\[\]|]/g, "\\$&");
    return startOfLine ? this.escStartOfLine(str) : str;
  }

  escStartOfLine(str) {
    return (str || "").replace(/^[:#\-*+>]/, "\\$&").replace(/^(\d+)\./, "$1\\.");
  }

  quote(str) {
    var wrap = str.indexOf('"') == -1 ? '""' : str.indexOf("'") == -1 ? "''" : "()";
    return wrap[0] + str + wrap[1]
  }

  // :: (string, number) → string
  // Repeat the given string `n` times.
  repeat(str, n) {
    let out = "";
    for (let i = 0; i < n; i++) out += str;
    return out
  }

  // : (Mark, bool, string?) → string
  // Get the markdown string for a given opening or closing mark.
  markString(mark, open, parent, index) {
    let info = this.marks[mark.type.name];
    let value;
    if (open) {
      if (typeof(info.open) === "function") {
        value = info.open(mark);
      } else {
        value = info.open;
      }
    } else {
      if (typeof(info.close) === "function") {
        value = info.close(mark);
      } else {
        value = info.close;
      }
    }
    return typeof value == "string" ? value : value(this, mark, parent, index)
  }
}

// --------------------------------------------------------------------------
// ::- A specification for serializing a ProseMirror document as
// Markdown/CommonMark text.
class MarkdownSerializer {
  // :: (Object<(state: MarkdownSerializerState, node: Node, parent: Node, index: number)>, Object)
  // Construct a serializer with the given configuration. The `nodes`
  // object should map node names in a given schema to function that
  // take a serializer state and such a node, and serialize the node.
  //
  // The `marks` object should hold objects with `open` and `close`
  // properties, which hold the strings that should appear before and
  // after a piece of text marked that way, either directly or as a
  // function that takes a serializer state and a mark, and returns a
  // string. `open` and `close` can also be functions, which will be
  // called as
  //
  //     (state: MarkdownSerializerState, mark: Mark,
  //      parent: Fragment, index: number) → string
  //
  // Where `parent` and `index` allow you to inspect the mark's
  // context to see which nodes it applies to.
  //
  // Mark information objects can also have a `mixable` property
  // which, when `true`, indicates that the order in which the mark's
  // opening and closing syntax appears relative to other mixable
  // marks can be varied. (For example, you can say `**a *b***` and
  // `*a **b***`, but not `` `a *b*` ``.)
  //
  // To disable character escaping in a mark, you can give it an
  // `escape` property of `false`. Such a mark has to have the highest
  // precedence (must always be the innermost mark).
  //
  // The `expelEnclosingWhitespace` mark property causes the
  // serializer to move enclosing whitespace from inside the marks to
  // outside the marks. This is necessary for emphasis marks as
  // CommonMark does not permit enclosing whitespace inside emphasis
  // marks, see: http://spec.commonmark.org/0.26/#example-330
  constructor(nodes, marks) {
    // :: Object<(MarkdownSerializerState, Node)> The node serializer
    // functions for this serializer.
    this.nodes = nodes;
    // :: Object The mark serializer info.
    this.marks = marks;
  }

  // :: (Node, ?Object) → string
  // Serialize the content of the given node to
  // [CommonMark](http://commonmark.org/).
  serialize(content) {
    const footnoteState = new MarkdownSerializerState(this.nodes, this.marks);
    const state = new MarkdownSerializerState(this.nodes, this.marks, footnoteState);
    state.renderContent(content, true);

    if (content.attrs) {
      const { attrs: { completedTasks, hiddenTasks } } = content;

      if (hiddenTasks && hiddenTasks.length > 0) {
        state.write(`# Hidden tasks<!-- {"omit":true} -->\n\n`);

        state.lastListItemSpacesIndent = -1
        hiddenTasks.forEach((hiddenTask, hiddenTaskIndex) => {
          const node = Node.fromJSON(schema, hiddenTask);
          state.render(node, content, hiddenTaskIndex);
        });
      }

      if (completedTasks && completedTasks.length > 0) {
        state.write(`# Completed tasks<!-- {"omit":true} -->\n\n`);

        state.lastListItemSpacesIndent = -1

        // We want the most recently completed tasks to appear first
        for (let completedTaskIndex = completedTasks.length - 1; completedTaskIndex > -1; completedTaskIndex--) {
          const completedTask = completedTasks[completedTaskIndex];
          if (!completedTask.p) continue;

          const checkListItem = checkListItemFromCompletedTask(completedTask);
          const node = Node.fromJSON(schema, checkListItem);
          state.render(node, content, completedTaskIndex);
        }
      }
    }

    return state.out + footnoteState.out;
  }
}

// --------------------------------------------------------------------------
// Markdown references
//  https://github.github.com/gfm/
//  https://www.markdownguide.org/extended-syntax/
const markdownSerializer = new MarkdownSerializer(
  // --------------------------------------------------------------------------
  // node conversion rules
  {
    // --------------------------------------------------------------------------
    attachment(state, node) {
      const { attrs: { data, name, text } } = node;

      const footnote = state.writeMediaText(text);

      state.write(`[${ state.esc(name || "attachment") }](${ state.esc(data) })${ footnote }`);
      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    blockquote(state, node) {
      state.wrapBlock("> ", null, node, () => state.renderContent(node))
    },
    // --------------------------------------------------------------------------
    bullet_list_item(state, node) {
      const { attrs } = node;

      const indent = attrs.indent || 0;

      const { leadingSpace, shouldWriteIndentAttribute } = state.listItemLeadingSpace(indent);
      state.write(`${ leadingSpace }- `);
      state.escapeListItemContent(() => state.renderInline(node));
      writeBulletListItemAttributes(state, attrs, { writeIndent: shouldWriteIndentAttribute });
      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    check_list_item(state, node) {
      const { attrs } = node;

      const indent = attrs.indent || 0;
      const isDone = isDoneFromTask(attrs);
      const { leadingSpace, shouldWriteIndentAttribute } = state.listItemLeadingSpace(indent);

      state.write(`${ leadingSpace }- [${ isDone ? "x" : " " }] `);

      state.escapeListItemContent(() => state.renderInline(node));
      writeCheckListItemInlineAttributes(state, attrs, { writeIndent: shouldWriteIndentAttribute });
      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    code_block(state, node) {
      let { textContent } = node;

      // Code blocks can be present in list items, in which case they need to be output with the same indent level
      // as the list item plus two spaces, and on a separate line, or they will break out of the list item
      let leadingSpaceFromListItem = "";
      if (state.lastListItemSpacesIndent > -1) {
        const { leadingSpace } = state.listItemLeadingSpace(state.lastListItemSpacesIndent);
        leadingSpaceFromListItem = "  " + leadingSpace;
        textContent = textContent.replace(/^/mg, leadingSpaceFromListItem);
        state.write("\n");
      }

      state.write(leadingSpaceFromListItem + "```" + (node.attrs.language || "") + "\n");

      // Need to escape code fences, but not all characters that require escaping in markdown, as that would change
      // the code in the block
      textContent = textContent.replace(/^(\s*)```(\s*)$/mg, "$1\\`\\`\\`$2");

      state.text(textContent, false);
      state.ensureNewLine();
      state.write(leadingSpaceFromListItem + "```");
      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    embed(state, node) {
      const { attrs: { aspectRatio, src } } = node;
      state.write(`<object data="${ src }" data-aspect-ratio="${ aspectRatio }" />`)
      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    hard_break(state, node, parent, index) {
      if (state.inTable) {
        state.write("<br />");
      } else {
        for (let i = index + 1; i < parent.childCount; i++) {
          if (parent.child(i).type !== node.type) {
            state.write("\\\n");
            return
          }
        }
      }
    },
    // --------------------------------------------------------------------------
    heading(state, node) {
      state.write(state.repeat("#", node.attrs.level) + " ");
      state.renderInline(node);

      if (node.attrs.collapsed) {
        state.write("<!-- " + JSON.stringify({ collapsed: true }) + " -->");
      }

      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    horizontal_rule(state, node) {
      state.ensureNewLine();
      state.write("\n");
      state.write("---");
      state.closeBlock(node)
    },
    // --------------------------------------------------------------------------
    image(state, node) {
      const { attrs: { src, text, width } } = node;

      const footnote = state.writeMediaText(text);

      let basename = "";
      if (width) {
        basename = (src || "").split("\\").pop().split("/").pop() + `${ state.inTable ? "\\" : "" }|${ width }`;
      }

      state.write(`![${ basename }](${ state.esc(src) })${ footnote }`);

      if (node.content.size > 2) {
        state.ensureNewLine();
        state.wrapBlock("> ", null, node, () => state.renderContent(node))
      }
    },
    // --------------------------------------------------------------------------
    link(state, node) {
      const { description, href } = node.attrs;

      const image = imageFromLinkAttributes(node.attrs);

      if (description || image) {
        // See https://www.ii.com/links-footnotes-markdown/ for some discussion of different ways to make footnotes
        // in markdown. We're using a hybrid of approaches described there, as of 6/2023 that results in a footnote
        // that works in GitHub's editor, works okay in Obsidian, and has reasonable fallback behavior elsewhere. It
        // also retains the context of the link, unlike a `some [^1]` style footnote.
        const footnoteNumber = state.writeFootnote(
          node,
          href || "",
          image,
          description
            ? footnoteState => {
              const descriptionNode = descriptionDocumentFromValue(description);
              footnoteState.renderContent(descriptionNode);
            }
            : null
        );
        state.write("[")
        state.renderInline(node);
        state.write(`][^${ footnoteNumber }]`);
      } else {
        state.write("[");
        state.renderInline(node);
        if (href && href.includes(" ")) {
          state.write("](<" + state.esc(href) + ">)");
        } else {
          state.write("](" + state.esc(href) + ")");
        }
      }
    },
    // --------------------------------------------------------------------------
    number_list_item(state, node) {
      const { attrs } = node;

      const indent = attrs.indent || 0;

      const { leadingSpace, shouldWriteIndentAttribute } = state.listItemLeadingSpace(indent);
      state.write(`${ leadingSpace }1. `);
      state.escapeListItemContent(() => state.renderInline(node));
      writeNumberListItemAttributes(state, attrs, { writeIndent: shouldWriteIndentAttribute });
      state.closeBlock(node);
    },
    // --------------------------------------------------------------------------
    paragraph(state, node) {
      if (node.childCount) {
        state.renderInline(node);
      } else if (!state.inTable) {
        // This is how an empty paragraph is denoted in markdown (alternatively, <br /> can
        // be used, but relies on the parser parsing HTML, which ours doesn't).
        state.write("\\");
      }

      if (!state.inTable) state.closeBlock(node)
    },
    // --------------------------------------------------------------------------
    // Some assumptions about our table structure (mostly enforced by schema.js/table-plugin.js, but repeated here for
    // clarity):
    //  - There's always at least one row
    //  - `table` nodes only contain `table_row` children
    //  - `table_row` nodes only contain `table_cell` children
    //  - Each row always has at least one cell
    //  - Each row has the same number of cells
    //  - The first row may or may not be a header row
    table(state, node) {
      const { attrs: { fullWidth }, firstChild: firstRow } = node;
      if (!firstRow) return;

      const cellCount = firstRow.childCount;

      const inlineAttributes = {};
      if (fullWidth) inlineAttributes.fullWidth = true;

      // Most markdown flavors require a header row, and the delimiter row is necessary to configure column alignment
      state.write(state.repeat("| ", cellCount) + serializeInlineAttributes(inlineAttributes) + "|\n");
      state.write(state.repeat("|-", cellCount) + "|\n");

      state.renderTable(node);
    },
    // --------------------------------------------------------------------------
    table_cell(state, node) {
      // Since we can't include block content (or newlines), the presence of anything but a single paragraph of content
      // has to push all the content to a footnote
      if (node.childCount === 1 && node.firstChild.type.name === "paragraph") {
        state.renderInline(node);
      } else {
        const footnoteNumber = state.writeFootnote(null, null, null, footnoteState => {
          footnoteState.write("\n");
          footnoteState.renderContent(node);
        });
        state.write(`[^${ footnoteNumber }]`);
      }

      writeTableCellInlineAttributes(state, node.attrs);
      state.write("|");
    },
    // --------------------------------------------------------------------------
    table_row(state, node) {
      state.write("|");
      state.renderContent(node);
      state.write("\n");
    },
    // --------------------------------------------------------------------------
    tasks_group(state, node) {
      state.renderContent(node);
    },
    // --------------------------------------------------------------------------
    text(state, node) {
      state.text(node.text)
    },
    // --------------------------------------------------------------------------
    video(state, node) {
      const { attrs: { src, width } } = node;

      let basename = (src || "").split("\\").pop().split("/").pop();
      if (width) basename += `${ state.inTable ? "\\" : "" }|${ width }`;

      state.write(`![${ basename }](${ state.esc(src) })`);
    },
  },
  // --------------------------------------------------------------------------
  // mark conversion rules
  {
    code: { open: "`", close: "`" },
    em: { open: "*", close: "*", mixable: true, expelEnclosingWhitespace: true },
    highlight: {
      close: mark => {
        const { backgroundColor, color } = mark.attrs || {};
        const commentColors = {};

        if (isCycleVarColor(backgroundColor)) {
          commentColors["backgroundCycleColor"] = numberFromCycleColor(backgroundColor);
        }
        if (isCycleVarColor(color)) {
          commentColors["cycleColor"] = numberFromCycleColor(color);
        }

        return `${ Object.keys(commentColors).length ? `<!-- ${ JSON.stringify(commentColors) } -->` : "" }</mark>`;
      },
      expelEnclosingWhitespace: true,
      mixable: true,
      open: mark => {
        let style= "";

        const { backgroundColor, color } = mark.attrs || {};

        if (isValidColorValue(backgroundColor)) {
          style += `background-color:${ backgroundColor };`;
        } else if (isCycleVarColor(backgroundColor)) {
          style += `background-color:${ hexColorFromCompoundColor(backgroundColor) };`
        }

        if (isValidColorValue(color)) {
          style += `color:${ color };`;
        } else if (isCycleVarColor(color)) {
          style += `color:${ hexColorFromCompoundColor(color) };`;
        }

        if (style) {
          return `<mark style="${ style }">`;
        } else {
          return "<mark>";
        }
      },
    },
    strikethrough: { open: "~~", close: "~~", mixable: true, expelEnclosingWhitespace: true },
    strong: { open: "**", close: "**", mixable: true, expelEnclosingWhitespace: true },
  }
);
export default markdownSerializer;

import PropTypes from "prop-types"
import React from "react"
import { IconButton } from "@rmwc/icon-button"
import Tippy from "@tippyjs/react"

import { hasModifierKey, hasModifierKeyExceptShift } from "lib/ample-editor/lib/event-util"

// --------------------------------------------------------------------------
export default class SetIntegerMenu extends React.PureComponent {
  // --------------------------------------------------------------------------
  static propTypes = {
    cancel: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    setInteger: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
  };

  state = {
    value: "",
  };

  _containerRef = React.createRef();

  // --------------------------------------------------------------------------
  render() {
    const { label, tooltip, units } = this.props;
    const { value } = this.state;

    let inputClassName = "text-input numeric";
    if (value !== "") inputClassName += isNaN(value) ? " invalid" : " valid";

    return (
      <div className="set-integer-menu sub-menu" ref={ this._containerRef }>
        <div className="label">{ label }</div>
        <input
          autoFocus
          className={ inputClassName }
          inputMode="numeric"
          maxLength={ 4 }
          onChange={ this._setValue }
          onKeyDown={ this._onKeyDown }
          type="text"
          size={ Math.max(1, value.length) }
          value={ value }
        />
        <div className="label">{ units }</div>

        <Tippy content={ tooltip } delay={ 150 }>
          <span className="enter-icon-button-container">
            <IconButton icon="keyboard_return" onClick={ this._setInteger } />
          </span>
        </Tippy>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _isFocused() {
    const { current: container } = this._containerRef;
    return container && document.activeElement && container.contains(document.activeElement);
  }

  // --------------------------------------------------------------------------
  _onKeyDown = event => {
    switch (event.key) {
      case "Escape":
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this.props.cancel();
        }
        break;

      case "Enter":
        if (!hasModifierKey(event)) {
          event.preventDefault();
          this._setInteger();
        }
        break;

      case "Tab":
        if (!hasModifierKeyExceptShift(event) && this._isFocused()) {
          event.preventDefault();
          this.props.cancel();
        }
        break;

      default:
        break;
    }
  };

  // --------------------------------------------------------------------------
  _setInteger = () => {
    const { value } = this.state;

    if (value === "") {
      this.props.setInteger(null);
    } else if (isNaN(value)) {
      this.props.cancel();
    } else {
      this.props.setInteger(parseInt(value, 10));
    }
  };

  // --------------------------------------------------------------------------
  _setValue = event => {
    this.setState({ value: event.target.value })
  };
}

import { Fragment, Slice } from "prosemirror-model"

import findSectionPositions from "lib/ample-editor/util/find-section-positions"

// --------------------------------------------------------------------------
export default function buildReplaceNodes(nodes, section) {
  return function(state, dispatch) {
    const { schema } = state;
    const newContent = Fragment.fromJSON(schema, nodes);

    let replaceEndPos;
    let replaceStartPos;
    if (section) {
      const { endPos, startPos } = findSectionPositions(section, state);

      replaceStartPos = startPos;
      replaceEndPos = endPos;
    } else {
      replaceStartPos = 0;
      replaceEndPos = state.doc.content.size;
    }

    if (replaceStartPos > -1) {
      if (replaceEndPos < 0) replaceEndPos = state.doc.content.size;

      const transaction = state.tr.replace(replaceStartPos, replaceEndPos, new Slice(newContent, 0, 0));
      if (dispatch) dispatch(transaction);

      return true;
    } else {
      return false;
    }
  }
}

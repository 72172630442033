import { dequal } from "dequal/lite"
import { PluginKey } from "prosemirror-state"
import { TableMap } from "prosemirror-tables"

import { isNumeric, numberFromText, NUMERIC_REGEX } from "lib/ample-editor/lib/math-util"
import {
  CELL_RESIZE_INACTIVE,
  CHARACTER_WIDTH_DEFAULT,
  COLUMN_WIDTH_BLOCK_DEFAULT_PX,
  COLUMN_WIDTH_BOLD_MULTIPLIER,
  COLUMN_WIDTH_HEADING_MULTIPLIER,
  COLUMN_WIDTH_LINK_ICON_PX,
  COLUMN_WIDTH_INDENT_OFFSET,
  COLUMN_WIDTH_MAX_ESTIMATE_PX,
  COLUMN_WIDTH_NODE_PADDING_PX,
  MIN_CELL_DEPTH,
  SORT_APPLIED,
  TABLE_DEPTH
} from "lib/ample-editor/lib/table/table-constants"

const AVAILABLE_FORMULAS = {
  AVERAGE: "average",
  MAX: "max",
  MEAN: "mean",
  MEDIAN: "median",
  MIN: "min",
  MODE: "mode",
  PRODUCT: "product",
  SUM: "sum",
};

export const FORMULA_RANGE = {
  ABOVE: "above",
  BELOW: "below",
  LEFT: "left",
  RIGHT: "right",
};

export const FORMULA_REGEX = new RegExp(`^=\\s*(${ Object.values(AVAILABLE_FORMULAS).join("|") })\\s*\\(([\\d]*)\\s*(${ Object.values(FORMULA_RANGE).join("|") }*)\\)\\s*$`);

// --------------------------------------------------------------------------
export const tablePluginKey = new PluginKey("table");

// --------------------------------------------------------------------------
export function isCellResizePossible(state) {
  return tablePluginKey.getState(state).cellToResizePos !== CELL_RESIZE_INACTIVE;
}

// --------------------------------------------------------------------------
export function setTableMeta(transform, tableMeta) {
  transform.setMeta(tablePluginKey, tableMeta);
}

// --------------------------------------------------------------------------
export function setTableRedrawMeta(transaction) {
  transaction.setMeta(tablePluginKey, { redrawRequested: true });
}

// --------------------------------------------------------------------------
export function textMatchesFormula(text) {
  return text.match(FORMULA_REGEX);
}

// --------------------------------------------------------------------------
export function isRenderFormulaCell($cell) {
  if (!$cell) return false;
  return textMatchesFormula($cell.nodeAfter.textContent);
}

// --------------------------------------------------------------------------
export function rowFromPos($pos) {
  if ($pos.nodeAfter && $pos.nodeAfter.type.spec.tableRole === "row") return $pos;
  let $row = null;
  for (let depth = $pos.depth; depth >= MIN_CELL_DEPTH - 1; depth--) {
    const parent = $pos.node(depth);
    if (parent.type.spec.tableRole === "row") {
      $row = $pos.doc.resolve($pos.before(depth));
      break;
    }
  }
  return $row;
}

// --------------------------------------------------------------------------
export function dismissCellSelectionMenuPos(state) {
  return tablePluginKey.getState(state).dismissSelectionPos;
}

// --------------------------------------------------------------------------
export function cellFromPos($pos) {
  if ($pos.nodeAfter && $pos.nodeAfter.type.spec.tableRole === "cell") return $pos;
  let $cell = null;
  for (let depth = $pos.depth; depth >= MIN_CELL_DEPTH; depth--) {
    const parent = $pos.node(depth);
    if (parent.type.spec.tableRole === "cell") {
      $cell = $pos.doc.resolve($pos.before(depth));
      break;
    }
  }
  return $cell;
}

// --------------------------------------------------------------------------
// DRY the pattern of looking up the resolved $cell position of a row/column pair
// Note that if requesting a row/column not yet in the table, this will return the position where that cell *will* be
// placed, *if* created. The position returned might not currently have a $cell in its nodeAfter
export function cellFromTable($table, row, column, { doc = null, map = null } = {}) {
  doc = doc || $table.doc;
  map = map || TableMap.get($table.nodeAfter);
  if (row > map.height || column > map.width) return null;
  const tableStart = $table.pos + 1; // $table.pos + 1 == $table.start(TABLE_DEPTH), hard to judge which is more intuitive
  let innerTablePos = null;
  try {
    innerTablePos = map.positionAt(parseInt(row, 10), parseInt(column, 10), $table.nodeAfter);
  } catch (error) {
    return null;
  }

  if (innerTablePos === null) return null;
  return doc.resolve(tableStart + innerTablePos);
}

// --------------------------------------------------------------------------
export function tableFromPos($pos) {
  if (!$pos) return null;
  if ($pos.nodeAfter && $pos.nodeAfter.type.spec.tableRole === "table") return $pos;
  const $table = $pos.doc.resolve($pos.before(TABLE_DEPTH));
  if ($table && $table.nodeAfter && $table.nodeAfter.type.spec.tableRole === "table") return $table;
  return null;
}

// --------------------------------------------------------------------------
export function forEachColumnIndexCell($table, columnIndex, cellFunction) {
  const map = TableMap.get($table.nodeAfter);
  for (let row = 0; row < map.height; row++) {
    const $columnCell = cellFromTable($table, row, columnIndex, { map });
    // WBH has observed rare cases in prod where TableMap gets out of sync and returns non-cell positions.
    // This ensures we'll only call cellFunction on actual cells
    if ($columnCell && $columnCell.nodeAfter && $columnCell.nodeAfter.type.spec.tableRole === "cell") {
      cellFunction($columnCell, row);
    }
  }
}

// --------------------------------------------------------------------------
// True if none of the cells contain content
export function isRowEmpty($row) {
  if (!$row || !$row.nodeAfter) return true;
  const firstCell = $row.nodeAfter.firstChild;
  const cellContainer = firstCell.firstChild;
  const firstCellEmpty = cellContainer.childCount === 0 && cellContainer.textContent === "";
  return firstCellEmpty && $row.nodeAfter.content.size === firstCell.nodeSize * $row.nodeAfter.childCount;
}

// --------------------------------------------------------------------------
export function lastRow($table) {
  return rowFromPos($table.doc.resolve($table.pos + $table.nodeAfter.nodeSize - 2));
}

// --------------------------------------------------------------------------
export function forEachColumnCell($table, $cellFromColumn, cellFunction) {
  return forEachColumnIndexCell($table, $cellFromColumn.index(), cellFunction);
}

// --------------------------------------------------------------------------
export function bottomCellInColumn($table, $cellFromColumn) {
  const map = TableMap.get($table.nodeAfter);
  if (!$cellFromColumn || !$cellFromColumn.nodeAfter || $cellFromColumn.nodeAfter.type.spec.tableRole !== "cell") throw new Error("Unexpected cellPos", $cellFromColumn.pos);
  return cellFromTable($table, map.height - 1, $cellFromColumn.index(), { map });
}

// --------------------------------------------------------------------------
export function forEachTableCell($table, cellFunction) {
  const map = TableMap.get($table.nodeAfter);
  for (let row = 0; row < map.height; row++) {
    for (let column = 0; column < map.width; column++) {
      const $cell = cellFromTable($table, row, column, { map });
      // WBH Nov 2023 empirically observed that TableMap can get out of sync with the actual table, returning "$cell"s that don't have a nodeAfter
      if ($cell.nodeAfter) {
        cellFunction($cell);
      }
    }
  }
}

// --------------------------------------------------------------------------
export function columnFromCell($cell) {
  return $cell.index();
}

// --------------------------------------------------------------------------
export function rowIndexFromCell($cell) {
  if (!$cell.nodeAfter || $cell.nodeAfter.type.spec.tableRole !== "cell") return null;
  return rowFromPos($cell).index();
}

// --------------------------------------------------------------------------
export function columnWidthFromColumnIndex($table, columnIndex, startRowIndex) {
  const $cell = cellFromTable($table, startRowIndex, columnIndex);
  return $cell.nodeAfter.attrs.colwidth;
}

// --------------------------------------------------------------------------
// Estimate the pixel width of a column from its contents. Of course this estimation is crude since it can't
// know the potential font magnification, or how padding widths might drift in design
// vNext can factor in hard breaks, but v1 as of Nov 2022 does not
// `startRowIndex`: starting index of row to evaluate for width
// `endRowIndex`: ending index of row to evaluate for width
export function estimateColumnContentWidth($table, columnIndex, startRowIndex, endRowIndex) {
  let maxContentPx = 0;
  forEachColumnIndexCell($table, columnIndex, $columnCell => {
    $columnCell.nodeAfter.forEach((child, _offset) => {
      if (maxContentPx > COLUMN_WIDTH_MAX_ESTIMATE_PX) return;
      const rowIndex = rowIndexFromCell($columnCell);
      if (rowIndex < startRowIndex || rowIndex > endRowIndex) return;
      let fragment, fragmentCharacterWidth, fragmentWidth;
      let fragmentOffset = COLUMN_WIDTH_NODE_PADDING_PX; // Estimating the width of a column starts with its intrinsic padding
      let nodeWidth = 0;

      switch (child.type.name) {
        case "paragraph": fragmentCharacterWidth = CHARACTER_WIDTH_DEFAULT;
          break;
        case "heading":
          if (child.attrs.level <= 3) fragmentCharacterWidth = CHARACTER_WIDTH_DEFAULT * COLUMN_WIDTH_HEADING_MULTIPLIER[child.attrs.level];
          else fragmentCharacterWidth = CHARACTER_WIDTH_DEFAULT;
          break;
        case "code_block":
          if (maxContentPx < COLUMN_WIDTH_BLOCK_DEFAULT_PX + fragmentOffset) {
            maxContentPx = COLUMN_WIDTH_BLOCK_DEFAULT_PX + fragmentOffset;
            return;
          }
          break;
        default:
          fragmentCharacterWidth = CHARACTER_WIDTH_DEFAULT;
      }

      if ([ "blockquote", "bullet_list_item", "number_list_item" ].includes(child.type.name)) {
        fragmentOffset += COLUMN_WIDTH_INDENT_OFFSET;
        child = child.firstChild; // The actual content resides one node deeper
      } else {
        fragmentOffset += 0;
      }

      for (let fragmentIndex = 0; fragmentIndex < child.content.childCount; fragmentIndex++) {
        fragment = child.content.content[fragmentIndex];

        if (fragment.type.name === "link") {
          fragmentOffset += COLUMN_WIDTH_LINK_ICON_PX;
          fragment = fragment.firstChild; // The actual content resides one node deeper
        }

        switch (fragment.type.name) {
          case "text":
            fragmentWidth = pixelEstimateFromString(fragment.text, fragmentCharacterWidth);
            if (fragment.marks.find(m => [ "code", "strong" ].includes(m.type.name))) fragmentWidth *= COLUMN_WIDTH_BOLD_MULTIPLIER;
            nodeWidth += fragmentWidth;
            break;

          case "image":
          case "video":
            fragmentWidth = fragment.attrs.width ? parseInt(fragment.attrs.width, 10) : COLUMN_WIDTH_BLOCK_DEFAULT_PX;
            nodeWidth += fragmentWidth;
            break;

          default:
            break;
        }
      }
      if ((nodeWidth + fragmentOffset) > maxContentPx) {
        maxContentPx = nodeWidth + fragmentOffset;
      }
    });
  });

  return Math.floor(Math.min(maxContentPx, COLUMN_WIDTH_MAX_ESTIMATE_PX));
}

// --------------------------------------------------------------------------
export function pixelEstimateFromString(string, characterWidthMultiplier = CHARACTER_WIDTH_DEFAULT) {
  const length = string.length;
  if (!length) return 0;
  const tinyMatch = string.match(/[iIjl,';.]/g);
  const smallMatch = string.match(/[frt"\s]/g);
  const kindaWideMatch = string.match(/[24679]/g);
  const wideMatch = string.match(/[mwMW%]/g);
  const upperMatch = string.match(/[A-HJ-LN-VX-Z]/g);
  const matchSum = match => match ? match.length : 0;
  const normalCount = length - matchSum(tinyMatch) - matchSum(smallMatch) - matchSum(kindaWideMatch) -
    matchSum(wideMatch) - matchSum(upperMatch);

  // Scalar values estimated from zooming in on Roboto characters and approximating their width relative to
  // the documented average of 8
  const scaledCharacterSum = matchSum(tinyMatch) * 0.45 + matchSum(smallMatch) * 0.6 + normalCount +
    matchSum(kindaWideMatch) * 1.2 + matchSum(upperMatch) * 1.2 + matchSum(wideMatch) * 1.4;
  return scaledCharacterSum * characterWidthMultiplier;
}

// --------------------------------------------------------------------------
// An evolving set of heuristics to deduce whether the first row is a header. #advantage_tinyco
// `$cell` any row from a column that will be checked for whether header has different bg color as signal of header
export function tableHasHeaderRow($table, $cell) {
  const map = TableMap.get($table.nodeAfter);
  if (map.height < 2) return false;
  const $maybeHeaderRow = $table.doc.resolve($table.start($table.depth + 1));

  let distinctFirstRowColor = false;
  let headerColor, headerMarksDiffer;
  let $matchedCell = null;
  let headerBold = null;

  forEachColumnCell($table, $cell, ($columnCell, row) => {
    const columnAttrs = $columnCell.nodeAfter.attrs;
    if (row === 0) {
      headerBold = cellHasBold($columnCell);
      headerColor = { backgroundColor: columnAttrs.backgroundColor, color: columnAttrs.color };
    } else if (dequal({ backgroundColor: columnAttrs.backgroundColor, color: columnAttrs.color }, headerColor)) {
      $matchedCell = $columnCell;
    }

    if (row === 1) {
      headerMarksDiffer = !dequal(cellHasBold($columnCell), headerBold);
    }
  });

  if ((headerColor.backgroundColor || headerColor.color) && !$matchedCell) {
    distinctFirstRowColor = true;
  }

  // In v1, WBH contemplating this might be sufficient, but further data to be gathered before potentially nixing the var
  if (distinctFirstRowColor || headerMarksDiffer) return true;

  let firstSecondRowNumericDifference = false;
  $maybeHeaderRow.nodeAfter.forEach((maybeHeaderCellNode, _offset, columnIndex) => {
    const $cellBelow = cellFromTable($table, 1, columnIndex, { map });
    const contentBefore = maybeHeaderCellNode.textContent.trim();
    const contentAfter = $cellBelow.nodeAfter.textContent.trim();

    // If first and second rows have content, but only second row is a number or date, that suggests header
    if (contentBefore.length && contentAfter.length) {
      if (NUMERIC_REGEX.test(contentAfter) && !NUMERIC_REGEX.test(contentBefore)) {
        firstSecondRowNumericDifference = true;
      } else if (!Number.isNaN(Date.parse(contentAfter)) && Number.isNaN(Date.parse(contentBefore))) {
        firstSecondRowNumericDifference = true;
      }
    }
  });

  return firstSecondRowNumericDifference;
}

// --------------------------------------------------------------------------
// Lots of regexes on behalf of avoiding forcing users to set explicit cell data types like apps designed
// by committees
//
// `$cell` is the starting $cell from the column to be sorted
// `$sortEndCell` the (inclusive) final cell that will be sorted
// Return an array of row indexes that should be sorted
export function sortedRowIndexesFromColumn($table, $cell, hasHeader, sortDirection, { $sortEndCell = null } = {}) {
  if ($sortEndCell === null) {
    $cell = cellFromTable($table, 0, columnFromCell($cell));
    $sortEndCell = bottomCellInColumn($table, $cell);
  }
  const startSortRow = Math.max(rowFromPos($cell).index(), (hasHeader ? 1 : 0));
  let endSortRow = rowFromPos($sortEndCell).index();
  const $firstCellLastRow = cellFromTable($table, endSortRow, 0);
  let hasTotalRow = false;
  let sum = 0;
  if ($firstCellLastRow && $firstCellLastRow.nodeAfter && /total/.test($firstCellLastRow.nodeAfter.textContent.toLowerCase())) {
    hasTotalRow = true;
  }
  const sortableRows = [];
  forEachColumnCell($table, $cell, ($columnCell, rowIndex) => {
    if (rowIndex >= startSortRow && rowIndex <= endSortRow) {
      const cellContent = $columnCell.nodeAfter.textContent;
      const compactValue = cellContent.replace(/\s/g, "");
      let value;
      if (!compactValue.length) {
        value = null;
      } else if (isNumeric(compactValue)) {
        value = numberFromText(cellContent);

        if (rowIndex < endSortRow) {
          sum += value;
        } else {
          if (sum && Math.floor(sum) === Math.floor(value)) hasTotalRow = true;
        }
      } else if (!Number.isNaN(Date.parse(cellContent))) {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse
        value = Date.parse(cellContent);
      } else {
        value = cellContent.toLowerCase();
      }

      if (rowIndex < endSortRow || !hasTotalRow) {
        sortableRows.push({ value, rowIndex });
      }
    }
  });

  if (hasTotalRow) endSortRow -= 1;

  const sortedRows = sortableRows.sort((a, b) => {
    if (a.value === null) return 1;
    if (b.value === null) return -1;
    if (a.value < b.value) {
      return sortDirection === SORT_APPLIED.ASC ? -1 : 1;
    }
    if (a.value > b.value) {
      return sortDirection === SORT_APPLIED.ASC ? 1 : -1;
    }
    return 0;
  });

  const map = TableMap.get($table.nodeAfter);
  const result = [];
  for (let row = 0; row < map.height; row++) {
    if (row < startSortRow || row > endSortRow) {
      result.push(row);
    } else {
      const sortIndex = row - startSortRow;
      result.push(sortedRows[sortIndex].rowIndex);
    }
  }
  return result;
}

// --------------------------------------------------------------------------
// Because $cell.marks() does not look deep enough into the abyss as of Nov 2022. If resolved position.marks()
// starts listing every mark contained in the node after that position, then this function could be removed
function cellHasBold($cell) {
  let hasBold = false;
  for (let childIndex = 0; childIndex < $cell.nodeAfter.childCount; childIndex++) {
    if (hasBold) break;
    const childNode = $cell.nodeAfter.child(childIndex);
    if (!childNode || !childNode.childCount) continue;
    const fragment = childNode.child(0);
    const markTypes = fragment.marks.map(m => m.type.name);
    hasBold = markTypes.includes("strong")
  }
  return hasBold;
}

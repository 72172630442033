// --------------------------------------------------------------------------
// Safari WebViews have a lot of trouble with <object> PDFs, not allowing scrolling,
// and even when scrolling is handled with an additional iframe wrapper, only showing
// the first page. In the mobile app (embed) we can load up the heavy-weight PDF.js
// to render the PDF inline instead
export default function createPdfJsIframe(url) {
  const iframe = document.createElement("iframe");
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("srcdoc", `
    <!DOCTYPE html>
    <html>
      <head>
        <script src="./pdfjs/polyfills.js"></script>
        <script src="./pdfjs/pdf.mjs" type="module"></script>
        <style>
          canvas {
            width: 100%;
          }
        </style>
      </head>
      <body>
        <script type="module">
          document.addEventListener("touchstart", function(_event) {
            window.parent.postMessage("pdfjs.touchstart", "*");
          });
          
          document.addEventListener("touchmove", function(_event) {
            window.parent.postMessage("pdfjs.touchmove", "*");
          });
          
          document.addEventListener("touchend", function(_event) {
            window.parent.postMessage("pdfjs.touchend", "*");
          });
          
          document.addEventListener("touchcancel", function(_event) {
            window.parent.postMessage("pdfjs.touchcancel", "*");
          });
          
          document.addEventListener("DOMContentLoaded", function(_event) {
            globalThis.pdfjsLibPromise.then(pdfjsLib => {
              pdfjsLib.GlobalWorkerOptions.workerSrc = "./pdf.worker.mjs";
            
              var loadingTask = pdfjsLib.getDocument(${ JSON.stringify(url) });
              loadingTask.promise.then(function(pdf) {
                const { numPages } = pdf;
                
                let currentPage = 1;
                var renderPage = function(page) {
                  var viewport = page.getViewport({ scale: 1 });
                  
                  var canvas = document.createElement("canvas");
                  canvas.style.display = "block";
                  var context = canvas.getContext("2d");
                  canvas.height = viewport.height;
                  canvas.width = viewport.width;
                  document.body.appendChild(canvas);
                  
                  page.render({ canvasContext: context, viewport: viewport });
                  
                  if (currentPage < numPages) {
                    currentPage += 1;
                    pdf.getPage(currentPage).then(renderPage);
                  }
                };
                pdf.getPage(currentPage).then(renderPage);
              }, function (reason) {
                // PDF loading error
                console.error(reason);
              });
            });
          });
        </script>
      </body>
    </html>
  `);

  return iframe;
}

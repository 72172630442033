import { get, omit } from "lodash"
import { closeHistory } from "prosemirror-history"
import { Fragment, NodeRange, Slice } from "prosemirror-model"
import { TextSelection, Selection } from "prosemirror-state"
import { canSplit, liftTarget } from "prosemirror-transform"

import { wrapEachIn } from "lib/ample-editor/lib/commands"
import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"
import ExpandingListItemSelection, {
  expandedSelectionEligible,
} from "lib/ample-editor/lib/expanding-list-item-selection"
import { allListItemsBetween } from "lib/ample-editor/lib/list-item-util"
import MultiListItemSelection from "lib/ample-editor/lib/multi-list-item-selection"
import { completeTask, updateTaskAttributes } from "lib/ample-editor/lib/task-commands"
import TransactionMerger from "lib/ample-editor/lib/transaction-merger"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import {
  getExpandedTaskUUID,
  setExpandedTaskUUID,
  setHighlightedTaskUUID,
} from "lib/ample-editor/plugins/check-list-item-plugin"
import {
  nodesHidingNodeAtPos,
  nonHiddenPosAfter,
  nonHiddenPosBefore,
} from "lib/ample-editor/plugins/collapsible-nodes-plugin"
import { updateEditorTabsPluginState } from "lib/ample-editor/plugins/editor-tabs-plugin"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import ChangeHiddenTaskAttrsStep from "lib/ample-editor/steps/change-hidden-task-attrs-step"
import RemoveCompletedTaskStep from "lib/ample-editor/steps/remove-completed-task-step"
import RemoveHiddenTaskStep from "lib/ample-editor/steps/remove-hidden-task-step"
import { TASK_COMPLETION_MODE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// Note that the commands in this file do not depend on specific node types -
// instead, they use the `isListItem` attribute of the node spec. This allows
// the commands to be agnostic of any particular schema.
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Indent level 0 is the default, so there are 1 more indent levels than this number
const MAX_LIST_ITEM_INDENT = 9;

// --------------------------------------------------------------------------
function adjustNodeIndent(transform, node, nodePos, delta) {
  const currentIndent = (node.attrs.indent || 0);
  const indent = Math.min(Math.max(0, currentIndent + delta), MAX_LIST_ITEM_INDENT);

  transform.setNodeMarkup(nodePos, null, { ...node.attrs, indent });
}

// --------------------------------------------------------------------------
// Returns true if any list item was changed
export function buildCompleteListItems({ listItemPos = null, taskCompletionMode = TASK_COMPLETION_MODE.NORMAL } = {}) {
  return function(state, dispatch) {
    const { doc, schema } = state;
    const listSelection = extractListSelection(state, listItemPos);
    if (!listSelection) return false;

    const nodesToMarkDone = extractListNodesFromSelection(doc, listSelection);
    const transactionMerger = new TransactionMerger(state);

    let anyListItemChanged = false;
    let baseNodeWasCrossedOut = null;

    if (taskCompletionMode === TASK_COMPLETION_MODE.NORMAL) {
      const { attrs: { storage } } = doc;
      const defaultTaskCompletionMode = storage && storage.defaultTaskCompletionMode;
      if (defaultTaskCompletionMode) taskCompletionMode = defaultTaskCompletionMode;
    }

    nodesToMarkDone.forEach(({ node, nodePos }) => {
      if (node.type === schema.nodes.check_list_item) {
        if (transactionMerger.apply(completeTask(node.attrs.uuid, taskCompletionMode))) {
          anyListItemChanged = true;
        }
      } else {
        anyListItemChanged = true;

        const { marks: { strikethrough: strikethroughType } } = schema;

        // We'll let this toggle whether bullet and number list items have their content crossed out, as they don't
        // have the same concept of completion that check-list-item nodes do.
        if (baseNodeWasCrossedOut === null) {
          const $baseNode = doc.resolve(listSelection.from);
          if (!$baseNode.nodeAfter) return false; // Observed 1x in production in Linux Firefox as of Dec 2022

          const textNodes = [];
          doc.nodesBetween($baseNode.pos, $baseNode.pos + $baseNode.nodeAfter.nodeSize, childNode => {
            if (childNode.isText) textNodes.push(childNode);
          });

          baseNodeWasCrossedOut = textNodes.length > 0 && textNodes.every(childNode => {
            return strikethroughType.isInSet(childNode.marks);
          });
        }

        if (baseNodeWasCrossedOut) {
          transactionMerger.transaction.removeMark(nodePos, nodePos + node.nodeSize, strikethroughType);
        } else {
          transactionMerger.transaction.addMark(nodePos, nodePos + node.nodeSize, strikethroughType.create());
        }
      }
    });

    if (!anyListItemChanged) return false;

    if (dispatch) {
      dispatch(transactionMerger.transaction.setMeta(TRANSACTION_META_KEY.RETAIN_EDITOR_FOCUS, true));
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function buildDeleteListItem(uuid) {
  return function(state, dispatch) {
    const result = findListItem(state.doc, 0, uuid);
    if (!result) return false;

    const { hiddenTask, node, nodePos } = result;

    if (dispatch) {
      const transaction = closeHistory(state.tr);

      if (hiddenTask) {
        transaction.step(new RemoveHiddenTaskStep(state.schema, hiddenTask));
      } else {
        transaction.delete(transaction.mapping.map(nodePos), transaction.mapping.map(nodePos + node.nodeSize));

        const { attrs: { indent } } = node;

        let nextNodePos = nodePos + node.nodeSize;
        let nextNode = state.doc.resolve(nextNodePos).nodeAfter;
        while (nextNode && nextNode.type.spec.isListItem && nextNode.attrs.indent > indent) {
          // This is making the assumption that the descendant items started at one indent
          // from the deleted item - in cases where there's a jump of more than one indent,
          // there may be some reason the user wanted that, so we'll leave it when updating
          // the indent level of the descendants.
          const newIndent = Math.max(0, nextNode.attrs.indent - 1);

          transaction.setNodeMarkup(
            transaction.mapping.map(nextNodePos),
            null,
            { ...nextNode.attrs, indent: newIndent }
          );

          nextNodePos = nextNodePos + nextNode.nodeSize;
          nextNode = state.doc.resolve(nextNodePos).nodeAfter;
        }
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function buildHighlightListItem(uuid) {
  return function(state, dispatch) {
    const { doc } = state;

    let completedTask = null;
    let hiddenTask = null;
    let node = null;
    let nodePos = null;

    const result = findListItem(doc, 0, uuid);
    if (result) {
      ({ hiddenTask, node, nodePos } = result);
    } else {
      completedTask = findCompletedTask(doc, uuid);
    }
    if (!completedTask && !hiddenTask && !node) return false;

    if (dispatch) {
      const transaction = state.tr;

      transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);

      setHighlightedTaskUUID(transaction, uuid);

      if (nodePos) {
        // Ensure the task isn't in a hidden-by-collapse section
        const hiddenByNodes = nodesHidingNodeAtPos(state, nodePos);
        hiddenByNodes.forEach(({ node: hiddenByNode, nodePos: hiddenByNodePos }) => {
          const attrs = { ...hiddenByNode.attrs, collapsed: false };
          transaction.setNodeMarkup(hiddenByNodePos, null, attrs);
        });

        // Place the cursor at the end of the check list item
        transaction.setSelection(TextSelection.near(transaction.doc.resolve(nodePos + node.nodeSize), -1));

        // Make sure the selection is visible
        transaction.scrollIntoView();
      } else {
        // Make sure the tab containing the task is actually visible
        updateEditorTabsPluginState(transaction, { currentTab: completedTask ? EDITOR_TAB.COMPLETED : EDITOR_TAB.HIDDEN });
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
function buildIndentListItem(allowFailure = false, onlyMatchStart = false) {
  return function(state, dispatch) {
    const { doc, selection: { $from: $originalFrom, $to: $originalTo } } = state;
    const listSelection = listSelectionFromState(state);
    if (!listSelection) return false;

    const adjustments = [];
    doc.nodesBetween(listSelection.from, listSelection.to, (node, nodePos) => {
      if (!node.type.spec.isListItem) return;

      const currentIndent = (node.attrs.indent || 0);
      if (currentIndent >= MAX_LIST_ITEM_INDENT) return;

      adjustments.push({ node, nodePos });
    });

    if (onlyMatchStart && $originalFrom.pos === $originalTo.pos && $originalFrom.pos !== $originalFrom.start($originalFrom.depth)) {
      return false;
    }

    if (allowFailure && adjustments.length === 0) {
      return false;
    }

    if (dispatch) {
      const transform = state.tr;

      adjustments.forEach(({ node, nodePos }, index) => {
        adjustNodeIndent(transform, node, nodePos, 1);

        // If a node is indented such that it would become hidden, we'd prefer to expand the new parent so the node won't
        // be hidden
        if (index === 0 && (node.type.name === "bullet_list_item" || node.type.name === "check_list_item")) {
          const parentListSelection = ExpandingListItemSelection.createFromEnd(
            transform.doc.resolve(nodePos), node.attrs.indent
          );

          if (parentListSelection instanceof ExpandingListItemSelection) {
            const $parentNodePos = parentListSelection.$from;
            const maybeParentNode = $parentNodePos.nodeAfter;
            if (maybeParentNode && maybeParentNode !== node) {
              transform.setNodeMarkup($parentNodePos.pos, null, { ...maybeParentNode.attrs, collapsed: false });
            }
          }
        }
      });

      dispatch(transform);
    }

    return true;
  }
}
export const indentListItem = buildIndentListItem();
export const maybeIndentListItem = buildIndentListItem(true);
export const maybeIndentListItemAtStart = buildIndentListItem(true, true);

// --------------------------------------------------------------------------
export function buildListItemsHideUntil({ hideUntil, listItemPos = null, startAt = null } = {}) {
  return function(state, dispatch) {
    const { doc, schema } = state;
    const listSelection = extractListSelection(state, listItemPos);
    if (!listSelection) return false;

    const nodesToHide = extractListNodesFromSelection(doc, listSelection);
    const transactionMerger = new TransactionMerger(state);

    let anyListItemChanged = false;

    nodesToHide.forEach(({ node, _nodePos }) => {
      if (node.type === schema.nodes.check_list_item) {
        const updates = { startAt: hideUntil };
        if (startAt !== null) updates.due = startAt;
        if (transactionMerger.apply(updateTaskAttributes(node.attrs.uuid, updates))) {
          anyListItemChanged = true;
        }
      }
    });

    if (!anyListItemChanged) return false;

    if (dispatch) {
      dispatch(transactionMerger.transaction.setMeta(TRANSACTION_META_KEY.RETAIN_EDITOR_FOCUS, true));
    }

    return true;
  }
}

// --------------------------------------------------------------------------
function buildMoveListItemDown(allowFailure = false) {
  return function(state, dispatch) {
    const { depth } = selectedListItemDepth(state);
    if (depth === null) return false;

    const { doc, selection } = state;

    const listSelection = movableListSelection(doc, selection, depth);
    const { $from, $to } = listSelection;
    const nextNode = listSelection.$to.nodeAfter;

    // There's no list item to move through in this direction, so we'll see if we can just outdent one level
    if (!nextNode || !nextNode.type.spec.isListItem) {
      if ($from.sameParent($to)) {
        return buildOutdentListItem(allowFailure, false)(state, dispatch);
      } else {
        return !allowFailure;
      }
    }

    if (dispatch) {
      const nextListSelection = ExpandingListItemSelection.create(doc.resolve(listSelection.$to.after(depth)));

      const transform = state.tr;

      const nextListStartPos = nextListSelection.$from.pos;
      const nextListEndPos = nextListSelection.$to.pos;
      const insertSlice = doc.slice(nextListStartPos, nextListEndPos);

      transform.deleteRange(nextListStartPos, nextListEndPos);

      const insertPos = $from.before(depth);
      const mappedInsertPos = transform.mapping.map(insertPos);

      transform.insert(mappedInsertPos, insertSlice.content);

      transform.setSelection(selection.map(transform.doc, transform.mapping)).scrollIntoView();

      dispatch(transform);
    }

    return true;
  }
}
export const moveListItemDown = buildMoveListItemDown();
export const maybeMoveListItemDown = buildMoveListItemDown(true);

// --------------------------------------------------------------------------
function buildMoveListItemUp(allowFailure = false) {
  return function(state, dispatch) {
    const { depth, fromNode } = selectedListItemDepth(state);
    if (depth === null) return false;

    const { doc, selection, selection: { $from, $to } } = state;

    const listSelection = movableListSelection(doc, selection, depth);

    const $beforePos = doc.resolve($from.before(depth));
    const previousNode = $beforePos.nodeBefore;

    // There's no list item to move through in this direction, so we'll see if we can just outdent one level
    if (!previousNode || !previousNode.type.spec.isListItem) {
      if ($from.sameParent($to)) {
        return buildOutdentListItem(allowFailure, false)(state, dispatch);
      } else {
        return !allowFailure;
      }
    }

    if (dispatch) {
      const $previousNodePos = doc.resolve($beforePos.pos - previousNode.nodeSize);
      const previousListSelection = ExpandingListItemSelection.createFromEnd($previousNodePos, fromNode.attrs.indent || 0);

      const transform = state.tr;

      const previousListStartPos = previousListSelection.$from.pos;
      const previousListEndPos = previousListSelection.$to.pos;
      const insertSlice = doc.slice(previousListStartPos, previousListEndPos);

      transform.deleteRange(previousListStartPos, previousListEndPos);

      const insertPos = listSelection.$to.after(depth);
      const mappedInsertPos = transform.mapping.map(insertPos);

      transform.insert(mappedInsertPos, insertSlice.content);

      let mappedSelection;
      if (selection instanceof MultiListItemSelection) {
        // The -1 (`bias` argument) exists because anchor/head in an MLIS fall on node boundaries, where deciding whether to move the
        // selection in response to an insert at the anchor/head position can't be deduced without knowing which direction selection is moving
        mappedSelection = selection.map(transform.doc, transform.mapping, -1);
      } else {
        mappedSelection = selection.map(transform.doc, transform.mapping);
      }
      transform.setSelection(mappedSelection).scrollIntoView();

      dispatch(transform);
    }

    return true;
  }
}
export const moveListItemUp = buildMoveListItemUp();
export const maybeMoveListItemUp = buildMoveListItemUp(true);

// --------------------------------------------------------------------------
// Note that this is expected to work like shift-tab would in a code editor, outdenting regardless of where in the item
// the cursor is.
function buildOutdentListItem(allowFailure = false, canOverrideAllowFailure = true) {
  return function(state, dispatch) {
    const { doc, selection: { $from, $to } } = state;
    const listSelection = listSelectionFromState(state);
    if (!listSelection) return false;

    const adjustments = [];
    let deltaIndent = -1;
    doc.nodesBetween(listSelection.from, listSelection.to, (node, nodePos) => {
      if (!node.type.spec.isListItem) return;

      const indent = (node.attrs.indent || 0);
      const newIndent = Math.max(0, indent + deltaIndent);

      // We want to apply the same outdent to all child list items selected by ExpandingListItemSelection as we've
      // applied to the root of the list
      if (listSelection instanceof ExpandingListItemSelection && nodePos === listSelection.from) {
        deltaIndent = newIndent - indent;
      }

      if (indent !== newIndent) {
        adjustments.push({ node, nodePos, indent: newIndent });
      }
    });

    if (adjustments.length === 0) {
      if (allowFailure) return false;

      // If we're outdenting the first item in the document, we'll allow failure even if the caller didn't specify to
      // allow it, as the caller _probably_ is overriding some default behavior that we don't want to do _nothing_ in
      // this case.
      if ($from.pos === $to.pos && state.doc.resolve($from.pos).node(1) === state.doc.firstChild) {
        return !canOverrideAllowFailure;
      }
    }

    if (dispatch) {
      const transform = state.tr;

      adjustments.forEach(({ node, nodePos }) => {
        adjustNodeIndent(transform, node, nodePos, -1);
      });

      dispatch(transform);
    }

    return true;
  }
}
export const outdentListItem = buildOutdentListItem();
export const maybeOutdentListItem = buildOutdentListItem(true);

// --------------------------------------------------------------------------
// Per https://bonanza.atlassian.net/browse/AMPLENOTE-1521, this will turn a bullet to a check_list_item,
// and any other kind of block to a bullet
export function buildToggleBulletItemCheckListItem($pos) {
  return function(state, dispatch) {
    const $head = $pos || state.selection.$head;

    const { schema } = state;
    const listItemDepth = listItemDepthFromSchema(schema);
    const blockNode = $head.node(listItemDepth);

    if (blockNode && blockNode.type === schema.nodes.bullet_list_item) {
      // The description-schema/editor doesn't have check_list_item nodes
      if (!schema.nodes.check_list_item) return false;

      const { attrs: { uuid } } = blockNode;

      return wrapEachIn(schema.nodes.check_list_item, null, $pos)(state, transaction => {
        const completedTasks = transaction.doc.attrs.completedTasks;
        const matchingCompletedTask = completedTasks.find(completedTask => {
          const { uuid: completedTaskUUID } = completedTask;
          return completedTaskUUID === uuid && completedTask.crossedOut;
        });

        if (matchingCompletedTask) {
          const { marks: { strikethrough: strikethroughType } } = schema;

          transaction.step(new RemoveCompletedTaskStep(schema, matchingCompletedTask));

          const nodePos = $head.before(listItemDepth);
          transaction.removeMark(
            transaction.mapping.map(nodePos),
            transaction.mapping.map(nodePos + blockNode.nodeSize),
            strikethroughType
          );
        }

        if (dispatch) dispatch(transaction);
      });
    } else {
      // In tasks schema, no bullet list items
      if (!schema.nodes.bullet_list_item) return false;

      return wrapEachIn(schema.nodes.bullet_list_item, null, $pos)(state, dispatch);
    }
  }
}

// --------------------------------------------------------------------------
// Note that, while this works at a basic level for check-list-item nodes, it's highly preferable to use
// `updateTask` or `updateTaskAttributes` for check-list-items/hidden tasks, as those handle more cases that
// make for a smoother experience for the user.
export function buildUpdateListItemAttributes(uuid, updates) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const result = findListItem(state.doc, 0, uuid);
    if (!result) return false;

    const { hiddenTask, node, nodePos } = result;

    if (dispatch) {
      const transaction = state.tr;

      if ((hiddenTask || node.type.name === "check_list_item") && updates.completedAt) {
        const defaultTaskCompletionMode = get(doc, "attrs.storage.defaultTaskCompletionMode");
        if (defaultTaskCompletionMode === TASK_COMPLETION_MODE.CROSS_OUT) {
          if (!updates.crossedOutAt) updates.crossedOutAt = updates.completedAt;
          updates = omit(updates, [ "completedAt" ]);
        }
      }

      if (hiddenTask) {
        transaction.step(new ChangeHiddenTaskAttrsStep(schema, hiddenTask.attrs, updates));
      } else {
        const attrs = { ...node.attrs, ...updates };
        transaction.setNodeMarkup(nodePos, null, attrs)
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
function extractListNodesFromSelection(doc, listSelection) {
  const nodesToMarkDone = [];
  doc.nodesBetween(listSelection.from, listSelection.to, (node, nodePos) => {
    if (node.type.spec.isListItem) {
      nodesToMarkDone.push({ node, nodePos });
      return false;
    }
  });

  return nodesToMarkDone;
}

// --------------------------------------------------------------------------
function extractListSelection(state, listItemPos) {
  const { doc, schema } = state;
  let listSelection;
  const multiItemEligible = expandedSelectionEligible(schema);
  if (listItemPos !== null) {
    if (multiItemEligible) {
      listSelection = ExpandingListItemSelection.create(doc.resolve(listItemPos));
    } else {
      const $listPos = doc.resolve(listItemPos);
      listSelection = TextSelection.create(
        doc, $listPos.pos, $listPos.pos + ($listPos.nodeAfter ? $listPos.nodeAfter.nodeSize : 1)
      );
    }
  } else {
    listSelection = listSelectionFromState(state);
  }

  return listSelection;
}

// --------------------------------------------------------------------------
function findCompletedTask(document, uuid) {
  const completedTasks = document.attrs.completedTasks || [];

  for (let i = 0; i < completedTasks.length; i++) {
    const completedTask = completedTasks[i];
    if (completedTask.uuid === uuid) return completedTask;
  }

  return null;
}

// --------------------------------------------------------------------------
// From prosemirror-commands
function findCutBefore($pos) {
  if (!$pos.parent.type.spec.isolating) for (let i = $pos.depth - 1; i >= 0; i--) {
    if ($pos.index(i) > 0) return $pos.doc.resolve($pos.before(i + 1));
    if ($pos.node(i).type.spec.isolating) break;
  }
  return null;
}

// --------------------------------------------------------------------------
function findListItem(node, nodePos, uuid) {
  let pos = nodePos;

  for (let i = 0; i < node.childCount; i++) {
    const childNode = node.child(i);

    switch (childNode.type.name) {
      case "bullet_list_item":
      case "check_list_item":
      case "number_list_item":
        if (childNode.attrs.uuid === uuid) return { node: childNode, nodePos: pos };
        break;

      case "tasks_group": {
        // Only valid in tasksSchema documents (i.e. in the TasksEditor)
        const result = findListItem(childNode, pos + 1, uuid);
        if (result) return result;

        break;
      }

      default:
        break;
    }

    pos += childNode.nodeSize;
  }

  if (node.type.name === "doc") {
    const hiddenTasks = node.attrs.hiddenTasks || [];
    for (let hiddenTasksIndex = 0; hiddenTasksIndex < hiddenTasks.length; hiddenTasksIndex++) {
      const hiddenTask = hiddenTasks[hiddenTasksIndex];
      if (hiddenTask.attrs.uuid === uuid) return { hiddenTask, nodePos: null };
    }
  }

  return null;
}

// --------------------------------------------------------------------------
// joinBackward won't join content _into_ a list node, so we need a special version that will
// Note that is is only intended to join content _onto_ list items. It doesn't handle cases where the node being
// joined backwards is itself a list item (or in a list item).
export function joinBackwardToListItem(state, dispatch, view) {
  const { listNodeType } = selectedListItemDepth(state);
  if (listNodeType) return false;

  const { selection: { $cursor } } = state;
  if (!$cursor || (view ? !view.endOfTextblock("backward", state) : $cursor.parentOffset > 0)) {
    return false;
  }

  const $cut = findCutBefore($cursor);
  if (!$cut) return false;

  const before = $cut.nodeBefore;
  if (!before.type.spec.isListItem) return false;

  // We're going to cut out the content of the node being joined backwards and insert it into the
  // list-item's content (iff it's valid)
  const listItemContent = before.firstChild;
  const after = $cut.nodeAfter;
  if (!listItemContent.type.validContent(after.type.contentMatch)) return false;

  if (dispatch) {
    const transform = state.tr;

    // Need to move past the close of the list item and list item content node
    const insertPos = $cut.pos - 2;

    transform.delete($cut.pos, $cut.pos + after.nodeSize);
    transform.insert(insertPos, after.content);

    const selection = TextSelection.create(transform.doc, insertPos);
    transform.setSelection(selection);

    dispatch(transform.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export function listItemDepthFromSchema(schema) {
  return schema.nodes.tasks_group ? 2 : 1;
}

// --------------------------------------------------------------------------
// Get the current selection, in whatever type of selection class best correlates with the user's selection
// efforts (e.g., MultiListItemSelection, ExpandingListItemSelection, TextSelection, whatever Selection.near() is)
export function listSelectionFromState(state) {
  const { doc, selection } = state;
  let listSelection;
  const multiItemEligible = expandedSelectionEligible(state.schema);
  if (multiItemEligible && selection instanceof MultiListItemSelection) {
    listSelection = selection;
  } else {
    const { $from, $to, depth } = selectedListItemDepth(state, true);
    if (depth === null) return null;

    const expandingSelectionEligible = allListItemsBetween($from, $to, { onlySingleParent: true });
    listSelection = multiItemEligible && expandingSelectionEligible
      ? ExpandingListItemSelection.create(doc.resolve($to.before(depth)))
      : TextSelection.create(doc, $from.pos, $to.pos);
  }
  return listSelection;
}

// --------------------------------------------------------------------------
function movableListSelection(doc, selection, depth) {
  if (selection instanceof MultiListItemSelection) {
    return selection;
  } else {
    return ExpandingListItemSelection.create(doc.resolve(selection.$to.before(depth)));
  }
}

// --------------------------------------------------------------------------
// Returns the _start_ position of the visible list node immediately following the list node that the given position falls in,
// which can be used in setNodeMarkup calls to set the next list node's attributes. Assumes there is a next list node.
function nextListNodePos(state, listNodeDepth, $pos) {
  try {
    const resultPos = nonHiddenPosAfter($pos.after(listNodeDepth) + 1, state);
    return state.doc.resolve(resultPos).before(listNodeDepth);
  } catch (_error) {
    // In the wild, the resolve call is observed to throw a RangeError: Position N out of range - but this has not
    // been reproducible _except_ in iOS 14 Safari when pressing enter at the end of a bullet list item would move the
    // selection to the start of the next bullet list item, after which we would run enter commands (splitListItem) with
    // the doc in a state that didn't match the selection. We've received notifications for this from a variety of
    // browsers and OS-es, so it could be something similar - or something else entirely.
    return null;
  }
}

// --------------------------------------------------------------------------
// Traverse from state.selection's $from down through node depths until we encounter a node w/ `spec.isListItem`.
// Return $from, $to, and depth if such a node is found, with depth=null if not underlying list item found
export function selectedListItemDepth(state, skipEmptyLineSelections = false) {
  const { doc, selection } = state;

  let { $from, $to } = selection;

  if (selection instanceof MultiListItemSelection) {
    // MLIS selection ranges place extents between nodes. To create parity with standard text selection ranges,
    // we need to massage $from & $to back up into the adjacent nodes with text area
    const $fromInNode = doc.resolve(nonHiddenPosAfter($from.pos, state, true));
    const $toInNode = doc.resolve(nonHiddenPosBefore($to.pos, state, true));
    if ($fromInNode && $toInNode && $fromInNode.pos <= $toInNode.pos) {
      $from = $fromInNode;
      $to = $toInNode;
    } else {
      return { $from, $to, depth: null };
    }
  }

  if (skipEmptyLineSelections) {
    const fromNode = $from.node($from.depth);
    if (fromNode && fromNode.type === state.schema.nodes.paragraph && $from.pos === $from.end($from.depth)) {
      const nextNodePos = $from.after($from.depth) + 1;
      if (nextNodePos < doc.content.size) {
        const $nextNodePos = doc.resolve(nextNodePos);
        const nextNode = $nextNodePos.node();
        if (nextNode && nextNode.type.spec.isListItem) {
          // We want the cursor to be on the node _inside_ the list item node
          $from = doc.resolve($nextNodePos.start($nextNodePos.depth + 1));
        }
      }
    }

    const toNode = $to.node($to.depth);
    if (toNode && toNode.type === state.schema.nodes.paragraph && $to.pos === $to.start($to.depth)) {
      const previousNodePos = $to.before($to.depth) - 1;
      if (previousNodePos > 0) {
        const $previousNodePos = doc.resolve(previousNodePos);
        const previousNode = $previousNodePos.node();
        if (previousNode && previousNode.type.spec.isListItem) {
          // We want the cursor to be on the node _inside_ the list item node
          $to = $previousNodePos;
        }
      }
    }
  }

  for (let depth = $from.depth - 1; depth > 0; depth--) {
    const fromNode = $from.node(depth);
    const toNode = $to.node(depth);

    if (fromNode && toNode && fromNode.type.spec.isListItem) {
      return { $from, $to, depth, listNodeType: toNode.type === fromNode.type ? fromNode.type : null, fromNode, toNode };
    }
  }

  return { $from, $to, depth: null };
}

// --------------------------------------------------------------------------
function splitEmptyListItem(state, dispatch, listNodeType, $from) {
  // In an empty block. If this is a nested list, the wrapping
  // list item should be split. Otherwise, bail out and let next
  // command handle lifting.
  if ($from.depth === 2 || $from.node(-3).type !== listNodeType || $from.index(-2) !== $from.node(-2).childCount - 1) {
    return false;
  }

  if (dispatch) {
    let wrap = Fragment.empty;
    const keepItem = $from.index(-1) > 0;
    // Build a fragment containing empty versions of the structure
    // from the outer list item to the parent node of the cursor
    for (let d = $from.depth - (keepItem ? 1 : 2); d >= $from.depth - 3; d--) {
      wrap = Fragment.from($from.node(d).copy(wrap));
    }
    // Add a second list item with an empty default start node
    wrap = wrap.append(Fragment.from(listNodeType.createAndFill()));

    const tr = state.tr.replace($from.before(keepItem ? null : -1), $from.after(-3), new Slice(wrap, keepItem ? 3 : 2, 2));
    tr.setSelection(state.selection.constructor.near(tr.doc.resolve($from.pos + (keepItem ? 3 : 2))));
    dispatch(tr.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
// :: (NodeType) → (state: EditorState, dispatch: ?(tr: Transaction)) → bool
// Build a command that splits a non-empty textblock at the top level
// of a list item by also splitting that list item.
export function splitListItem(state, dispatch) {
  const { depth: listNodeDepth, listNodeType } = selectedListItemDepth(state);
  if (listNodeDepth === null) return false;

  const { $from, $to } = state.selection;
  if (!$from.sameParent($to)) return false;

  const listNode = $from.node(listNodeDepth);

  if ($from.parent.content.size === 0) {
    return splitEmptyListItem(state, dispatch, listNodeType, $from);
  }

  const childNodeDepth = listNodeDepth + 1;
  let nextType = null;
  try {
    nextType = $to.pos === $from.end(childNodeDepth) ? listNode.contentMatchAt(0).defaultType : null;
  } catch (_error) {
    // Likely `TypeError: Cannot read properties of undefined (reading 'content')` in `ResolvedPos.end`
  }
  const tr = state.tr.delete($from.pos, $to.pos);
  const types = nextType && [ null, { type: nextType } ];
  const nodesToSplit = 1 + $from.depth - listNodeDepth;

  try {
    if (!canSplit(tr.doc, $from.pos, nodesToSplit, types)) return false;
  } catch (_error) {
    // `Error: Called contentMatchAt on a node with invalid content` is thrown by `canSplit` (via call to `canReplace`,
    // which calls `contentMatchAt`) in some unknown condition in the wild. We'll just treat that as an un-splittable
    // node, though it's unclear exactly what the structure is.
    return false;
  }

  if (dispatch) {
    const { nodeInsertPos, newListNodeAttributes } = splitThroughListNode(
      state, tr, listNode, listNodeDepth, listNodeType, nodesToSplit, types
    );

    if (nodeInsertPos !== null) {
      try {
        tr.setNodeMarkup(nodeInsertPos, null, newListNodeAttributes);
      } catch (_error) {
        // Likely `RangeError: No node at given position` thrown by `setNodeMarkup`
        return false;
      }
    }

    dispatch(tr.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
// Determine the method by which a listNode should be split: either by manually chopping characters from end and
// splicing them to next non-hidden node (if we're splitting through hidden content) or via tr.split.
// Then return the position and attributes for the node to be inserted as a result of splitting a list item
// at the position in `state`.
// As of initial implementation (May 2021), subsequent to this method, the `nodeInsertPos` and `newListNodeAttributes`
// are used as arguments in a call to `setNodeMarkup`
function splitThroughListNode(state, tr, listNode, listNodeDepth, listNodeType, nodesToSplit, types) {
  const { $anchor, $head, $from, $to } = state.selection;
  const { doc, schema } = state;
  let newListNodeAttributes = {};

  const splittingAtStart = $from.pos === $to.start($from.depth);
  let nodeInsertPos = null;
  let splitThroughHiddenNodes = false;
  let splitStartAt;
  let throughHiddenPos;

  if (!splittingAtStart) {
    // Determine where the next node will be positioned, what its contents will be, and what indentation level to adopt
    splitStartAt = $to.after(listNodeDepth);
    throughHiddenPos = nonHiddenPosAfter(splitStartAt, state);
    splitThroughHiddenNodes = splitStartAt < throughHiddenPos;
    let indentNode; // Which node should our newly created node adopt its indentation from?
    if (splitThroughHiddenNodes) {
      indentNode = listNode; // Always use the indent level of the parent when splitting through nodes
    } else {
      const { nodeAfter: nextNode } = doc.resolve(throughHiddenPos);
      if (nextNode && nextNode.attrs.indent >= listNode.attrs.indent) indentNode = nextNode; // If there's a next node that's at least our current indent level, we'll use that
      else indentNode = listNode;
    }

    if (indentNode && indentNode.type === listNodeType) {
      newListNodeAttributes.indent = Math.max(listNode.attrs.indent || 0, indentNode.attrs.indent || 0);
    }
  }

  if (splitThroughHiddenNodes) {
    const textEndPos = splitStartAt - 1;
    const splitContent = doc.slice($from.pos, textEndPos);
    const textContent = splitContent.content.textBetween(0, splitContent.size);
    const newNode = listNode.copy(splitContent.content);
    nodeInsertPos = throughHiddenPos;
    if (textContent.length) {
      tr.delete($from.pos, $from.pos + textContent.length);
      nodeInsertPos -= textContent.length;
    }
    try {
      tr.insert(nodeInsertPos, newNode);
    } catch (_error) {
      // RangeError: Position n out of range
      return { nodeInsertPos: null, newListNodeAttributes: {} };
    }
    const $insert = Selection.near(tr.doc.resolve(nodeInsertPos), $head.pos > $anchor.pos ? 1 : -1).$anchor;
    tr.setSelection(TextSelection.create(tr.doc, $insert.pos));
  } else {
    tr.split($from.pos, nodesToSplit, types);
  }

  if (listNode.type === schema.nodes.number_list_item) {
    // We want to reset the offset for each subsequent number list item we split out
    newListNodeAttributes = omit(newListNodeAttributes, [ "offset" ]);
  }

  if (!nodeInsertPos) { // If the new node won't stretch across hidden nodes, then we have yet to calculate its insertPos
    if (splittingAtStart) {
      nodeInsertPos = nextListNodePos({ ...state, doc: tr.doc }, listNodeDepth, doc.resolve($from.before(listNodeDepth)));
    } else {
      nodeInsertPos = nextListNodePos({ ...state, doc: tr.doc }, listNodeDepth, $from);
    }
    newListNodeAttributes.indent = (newListNodeAttributes.indent || listNode.attrs.indent);
  }

  return { nodeInsertPos, newListNodeAttributes };
}
// --------------------------------------------------------------------------
export function toggleListItemDetail(state, dispatch) {
  const { toNode: node } = selectedListItemDepth(state);
  if (!node) return false;

  const { schema } = state;

  const canShowListItemDetails = node.type === schema.nodes.check_list_item ||
    (node.type === schema.nodes.bullet_list_item && node.attrs.scheduledAt);
  if (!canShowListItemDetails) return false;

  if (dispatch) {
    const expandedTaskUUID = node.attrs.uuid;

    const transaction = setOpenLinkPosition(state.tr, null);

    setExpandedTaskUUID(transaction, expandedTaskUUID === getExpandedTaskUUID(state) ? null : expandedTaskUUID);

    dispatch(transaction);
  }

  return true;
}

// --------------------------------------------------------------------------
// Pulls the content of a list item up and removes the list item, if the selection is at the start of the list item
export function unwrapListItemAtStart(state, dispatch) {
  const { depth } = selectedListItemDepth(state);
  if (depth === null) return false;

  const { selection: { $from } } = state;

  // Account for opening positions of any nested nodes
  const contentStartPos = $from.start(depth) + ($from.depth - depth);
  if ($from.pos !== contentStartPos) return false;

  const contentRange = new NodeRange(
    state.doc.resolve($from.start(depth + 1)),
    state.doc.resolve($from.end(depth + 1)),
    depth
  );

  const targetDepth = liftTarget(contentRange);
  if (typeof(targetDepth) === "undefined") return false;

  if (dispatch) {
    dispatch(state.tr.lift(contentRange, targetDepth));
  }
  return true;
}

import { buildToggleNodeCollapsed } from "lib/ample-editor/commands/toggle-node-collapsed"
import { isTasksSchema } from "lib/ample-editor/components/tasks-editor/tasks-schema"
import ExpandingListItemSelection from "lib/ample-editor/lib/expanding-list-item-selection"
import LIST_ITEM_COMMAND from "lib/ample-editor/lib/list-item-command"
import {
  buildCompleteListItems,
  buildDeleteListItem,
  buildListItemsHideUntil,
  buildToggleBulletItemCheckListItem,
  buildUpdateListItemAttributes,
  listItemDepthFromSchema,
} from "lib/ample-editor/lib/list-item-commands"
import { deleteTasks, updateTaskAttributes } from "lib/ample-editor/lib/task-commands"
import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import { urlFromNewNoteParams } from "lib/ample-util/note-url"
import {
  calculateTaskValue,
  changesFromRepeat,
  durationFromMinutes,
  flagsObjectFromFlagsString,
  flagsStringFromFlagsObject,
  repeatRuleFromDuration,
  rruleFromRepeatRule,
  TASK_COMPLETION_MODE,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function buildCloneListItemsCommand(editorView, menuPos, commandOptions, { listItemType = null, move = false } = {}) {
  const { props: { hostApp: { cloneNodes } } } = editorView;
  if (!cloneNodes) return null;

  return function(state, _dispatch) {
    const { name, url, suggestionTags: tagTexts } = commandOptions;

    const isTasksEditor = isTasksSchema(state.schema);

    let $pos;
    try {
      $pos = state.doc.resolve(menuPos);
    } catch (_error) {
      // `RangeError: Position n out of range`
      return;
    }

    // In the tasks editor, there's an extra tasks_group node wrapping tasks. Note these depths are the depths
    // of the paragraph _in_ the check_list_item node, as we're using `before` we'll get the parent of that node
    const listNodeDepth = isTasksEditor ? 3 : $pos.depth - 1;
    const $listNodePos = state.doc.resolve($pos.before(listNodeDepth));

    let listNodes;
    if (isTasksEditor) {
      listNodes = [ $listNodePos.node() ];
    } else {
      const listSelection = ExpandingListItemSelection.create($listNodePos);
      listNodes = listSelection.content().content.content;
    }

    const firstListNode = listNodes[0];
    const indentDelta = (firstListNode && firstListNode.attrs && firstListNode.attrs.indent) || 0;

    const nodes = [];
    listNodes.forEach(listNode => {
      const defaultAttributes = DEFAULT_ATTRIBUTES_BY_NODE_NAME[listItemType || listNode.type.name] || {};

      if ("uuid" in defaultAttributes) {
        const nodeJSON = listNode.toJSON();

        if (nodeJSON.type !== listItemType) {
          nodeJSON.type = listItemType;

          const updatedAttrs = {};
          Object.keys(defaultAttributes).forEach(attributeName => {
            if (!(attributeName in listNode.attrs)) return;
            if (!(attributeName in defaultAttributes)) return;

            updatedAttrs[attributeName] = listNode.attrs[attributeName];
          });

          if ("scheduledAt" in defaultAttributes && !("scheduledAt" in updatedAttrs)) {
            updatedAttrs.scheduledAt = nodeJSON.attrs.due || defaultAttributes["scheduledAt"];
          }

          nodeJSON.attrs = updatedAttrs;
        }

        // Force the top-level indent to zero when cloning a list node into another note, as there's no telling what
        // it would mean in the context of that note if it were indented
        if ("indent" in defaultAttributes) {
          nodeJSON.attrs = {
            ...nodeJSON.attrs,
            indent: Math.max(0, (listNode.attrs.indent || 0) - indentDelta),
          };
        }

        nodes.push(nodeJSON);
      }
    });

    const noteURL = url || urlFromNewNoteParams({ name, tags: tagTexts });
    cloneNodes(nodes, noteURL, { move });
  }
}

// --------------------------------------------------------------------------
function maxTaskValue(node, nodePos = 0) {
  let maxValue = 0;

  let pos = nodePos;
  for (let i = 0; i < node.childCount; i++) {
    const childNode = node.child(i);

    if (childNode.type.name === "check_list_item") {
      maxValue = Math.max(maxValue, calculateTaskValue(childNode.attrs));
    } else if (childNode.type.name === "tasks_group") {
      // Only valid in tasksSchema documents (i.e. in the TasksEditor)
      maxValue = Math.max(maxValue, maxTaskValue(childNode, pos + 1));
    }

    pos += childNode.nodeSize;
  }

  return maxValue;
}

// --------------------------------------------------------------------------
export default function applyListItemCommand(
  editorView, pos, attributes, listItemType, listItemCommand, listItemCommandOptions,
) {
  const { uuid } = attributes || {};
  if (!uuid) return;

  const { dispatch, state } = editorView;

  let editorCommand = null;
  switch (listItemCommand) {
    case LIST_ITEM_COMMAND.COMPLETE: {
      const $pos = state.doc.resolve(pos);

      editorCommand = buildCompleteListItems({ listItemPos: $pos.before(listItemDepthFromSchema(state.schema)) });

      break;
    }

    case LIST_ITEM_COMMAND.CROSS_OUT: {
      const $pos = state.doc.resolve(pos);

      editorCommand = buildCompleteListItems({
        listItemPos: $pos.before(listItemDepthFromSchema(state.schema)),
        taskCompletionMode: TASK_COMPLETION_MODE.CROSS_OUT
      });

      break;
    }

    case LIST_ITEM_COMMAND.DELETE:
      if (listItemType === "check_list_item") {
        editorCommand = deleteTasks([ uuid ]);
      } else {
        editorCommand = buildDeleteListItem(uuid);
      }
      break;

    case LIST_ITEM_COMMAND.DISMISS: {
      const $pos = state.doc.resolve(pos);

      editorCommand = buildCompleteListItems({
        listItemPos: $pos.before(listItemDepthFromSchema(state.schema)),
        taskCompletionMode: TASK_COMPLETION_MODE.DISMISS
      });

      break;
    }

    case LIST_ITEM_COMMAND.DURATION: {
      const { minutes } = listItemCommandOptions;

      const changes = { duration: durationFromMinutes(minutes) };

      if (listItemType === "check_list_item") {
        editorCommand = updateTaskAttributes(uuid, changes);
      } else {
        editorCommand = buildUpdateListItemAttributes(uuid, changes);
      }

      break;
    }

    case LIST_ITEM_COMMAND.EVERY: {
      const { repeat } = listItemCommandOptions;

      if (listItemType === "check_list_item") {
        const changes = changesFromRepeat(attributes, repeat);
        editorCommand = updateTaskAttributes(uuid, changes);
      } else {
        // The incoming repeat is a relative repeat (duration), but we don't really have that
        // concept with scheduled bullets (they are "completed" at the scheduled time, making them the same as fixed
        // repeat), so we convert to a fixed repeating interval (rrule) here.
        const { scheduledAt } = attributes;
        const startDate = scheduledAt ? new Date(scheduledAt * 1000) : new Date();
        const repeatRule = repeatRuleFromDuration(repeat, startDate);
        const newRepeat = rruleFromRepeatRule(repeatRule);
        editorCommand = buildUpdateListItemAttributes(uuid, { repeat: newRepeat });
      }

      break;
    }

    case LIST_ITEM_COMMAND.COLLAPSE:
    case LIST_ITEM_COMMAND.EXPAND: {
      const $pos = state.doc.resolve(pos);

      editorCommand = buildToggleNodeCollapsed($pos.before(listItemDepthFromSchema(state.schema)));

      break;
    }

    case LIST_ITEM_COMMAND.HIDE: {
      const $pos = state.doc.resolve(pos);

      const { startAt } = listItemCommandOptions;
      editorCommand = buildListItemsHideUntil({
        hideUntil: startAt,
        listItemPos: $pos.before(listItemDepthFromSchema(state.schema)),
      });

      break;
    }

    case LIST_ITEM_COMMAND.SCHEDULE: {
      const $pos = state.doc.resolve(pos);

      const { startAt } = listItemCommandOptions;
      editorCommand = buildListItemsHideUntil({
        hideUntil: startAt,
        listItemPos: $pos.before(listItemDepthFromSchema(state.schema)),
        startAt,
      });

      break;
    }

    case LIST_ITEM_COMMAND.SET_TASK_SCORE: {
      const { points: userRequestedTaskScore } = listItemCommandOptions;
      const valueWithoutPoints = calculateTaskValue({ ...attributes, points: 0 });
      const newPoints = userRequestedTaskScore - valueWithoutPoints;

      // newPoints isn't actually what the user sent, since each task has "intrinsic value" that must be accounted
      // for in order to set a `points` value that adds up to the number the user wants to see
      const changes = { points: newPoints };
      editorCommand = updateTaskAttributes(uuid, changes);

      break;
    }

    case LIST_ITEM_COMMAND.SWITCH_TO_BULLET:
    case LIST_ITEM_COMMAND.SWITCH_TO_SCHEDULED_BULLET:
    case LIST_ITEM_COMMAND.SWITCH_TO_TASK: {
      const $pos = state.doc.resolve(pos);
      editorCommand = buildToggleBulletItemCheckListItem($pos);
      break;
    }

    case LIST_ITEM_COMMAND.IMPORTANT:
    case LIST_ITEM_COMMAND.URGENT: {
      const { flags: flagsString } = attributes;
      const flags = flagsObjectFromFlagsString(flagsString);

      const flagName = listItemCommand === LIST_ITEM_COMMAND.IMPORTANT ? "important" : "urgent";
      flags[flagName] = !flags[flagName];

      editorCommand = updateTaskAttributes(uuid, { flags: flagsStringFromFlagsObject(flags) });

      break;
    }

    case LIST_ITEM_COMMAND.MAX_SCORE: {
      const maxValue = maxTaskValue(state.doc, 0);
      const valueWithoutPoints = calculateTaskValue({ ...attributes, points: 0 });
      const points = maxValue - valueWithoutPoints;

      editorCommand = updateTaskAttributes(uuid, { points });

      break;
    }

    case LIST_ITEM_COMMAND.COPY:
    case LIST_ITEM_COMMAND.MOVE:
      editorCommand = buildCloneListItemsCommand(editorView, pos, listItemCommandOptions, {
        listItemType,
        move: listItemCommand === LIST_ITEM_COMMAND.MOVE,
      });
      break;

    case LIST_ITEM_COMMAND.RESET_SCORE:
      editorCommand = updateTaskAttributes(uuid, { points: 0 });
      break;

    case LIST_ITEM_COMMAND.START: {
      const { date } = listItemCommandOptions;

      const timestamp = date ? Math.floor(date.getTime() / 1000) : null;
      if (listItemType === "check_list_item") {
        editorCommand = updateTaskAttributes(uuid, { due: timestamp });
      } else {
        editorCommand = buildUpdateListItemAttributes(uuid, { scheduledAt: timestamp });
      }

      break;
    }

    default:
      break;
  }

  if (editorCommand) {
    editorCommand(state, dispatch);
  }

  editorView.focus();
}

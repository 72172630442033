import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"

import TooltipIcon from "lib/ample-editor/components/tooltip-icon"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"

// --------------------------------------------------------------------------
const makeHotkeySeparator = () => '<span class="hotkey-separator">+</span>';

// --------------------------------------------------------------------------
function ToolbarCommandButton({ active, commandName, disabled, executeCommand, icon, tooltip }) {
  const pathData = VECTOR_ICON_PATHS[icon];
  if (pathData) {
    icon = (
      <svg viewBox="0 0 24 24">
        <path d={ pathData } />
      </svg>
    );
  }

  let className = `button ${ commandName }`;
  if (active) className += " active";
  if (disabled) className += " disabled";

  const tooltipContent = useMemo(
    () => tooltip.replace(/\+/g, makeHotkeySeparator),
    []
  );

  const onMouseDown = useCallback(
    event => {
      event.preventDefault();

      executeCommand(commandName);
    },
    []
  );

  return (
    <span className={ className } onMouseDown={ onMouseDown } data-active={ active }>
      <TooltipIcon icon={ icon } tooltip={ tooltipContent } />
    </span>
  );
}

ToolbarCommandButton.propTypes = {
  active: PropTypes.bool,
  commandName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  executeCommand: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
  tooltip: PropTypes.string,
};

export default React.memo(
  ToolbarCommandButton,
  // We expect the commandName to be used as a key, with only the active/disabled props ever changing, as an
  // optimization since the toolbar consumes props that change on every selection and document change. This mainly
  // makes it easier to determine what is re-rendering, as this is not terribly expensive in itself.
  (props, nextProps) => nextProps.active === props.active && nextProps.disabled === props.disabled,
);

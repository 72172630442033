import markdownParser from "lib/ample-editor/lib/markdown-parser"

// --------------------------------------------------------------------------
// "plugin" here is an Amplenote plugin, not a ProseMirror plugin
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Protecting plugin authors from themselves, so they don't insert tens of megabytes of content into a note
// accidentally, creating a very non-performant note (maybe even crashing the tab for memory use).
export const MAX_MARKDOWN_CONTENT_LENGTH = 100000;

// --------------------------------------------------------------------------
export function parsePluginMarkdownContent(markdownContent) {
  if (markdownContent && markdownContent.length > MAX_MARKDOWN_CONTENT_LENGTH) {
    throw new Error("markdownContent is too long");
  }

  return markdownParser.parse(markdownContent);
}

import isUrl from "is-url"
import { defer } from "lodash"
import { v4 as uuidv4 } from "uuid"
import { Selection, TextSelection } from "prosemirror-state"

import { codeBlockDepth } from "lib/ample-editor/lib/code-block/code-block-util"
import { localFileURLFromUUID } from "lib/ample-editor/plugins/file-plugin"
import { blobFromDataURL } from "lib/ample-editor/lib/image-util"
import { linkSelectionTransform } from "lib/ample-editor/lib/link-commands"
import { indentFromListElement } from "lib/ample-editor/lib/list-item-util"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import { schema } from "lib/ample-editor/schema"

// --------------------------------------------------------------------------
const LI_TAG_NAME = "LI";

// --------------------------------------------------------------------------
// Handle drop/paste/etc of src="data:" images, which can have a significant impact on editor performance, by uploading
// them instead
export function handleDataURLImages(view, slice, pos) {
  const { props: { hostApp: { startMediaUpload } } } = view;
  if (!startMediaUpload) return;

  slice.content.descendants(node => {
    if (node.type !== schema.nodes.image) return;

    const { attrs: { src } } = node;
    if (!src || !src.startsWith("data:")) return;

    const image = blobFromDataURL(src);
    if (!image) return;

    const uuid = uuidv4();
    node.attrs.src = localFileURLFromUUID(uuid);

    // Need to wait until the image is inserted in the document
    defer(() => {
      startMediaUpload(uuid, image, pos);
    });
  });
}

// --------------------------------------------------------------------------
// When pasting text selected from Chrome "View source", we must unwrap the table HTML that is serialized so as to
// paste the actual code that was copied
export function unwrapHtmlSource(domFragment) {
  if (domFragment.querySelector("table tr td.line-number+td.line-content")) {
    const result = document.createElement("pre");
    Array.from(domFragment.querySelectorAll("td.line-content")).forEach(node => {
      result.append(node.textContent);
      result.append(document.createElement("br"));
    });
    domFragment.querySelector("table").replaceWith(result);
  }
}

// --------------------------------------------------------------------------
// Prior to parsing the incoming DOM into ProseMirror nodes, we want to lift up all nested UL/OL lists such that
// there are no UL/OL elements showing up nested in LI elements. We do this because our model of lists doesn't
// include a wrapping list element, just individual list items (for much greater flexibility and simplicity in the
// editor). We need to keep track of what the indent would be for the incoming nodes, which we'll do by adding an
// indent-1 class and incrementing it each time we lift the list item.
// Params
// `listNode` a `ul` or `ol` element whose direct parent (`wrapperNode` below) is an `li` or `ul`
// `selector` the CSS rule we are looking to consolidate (e.g., `ul > ul`)
export function liftNestedList(listNode, selector) {
  // Handle any lists nested inside this list's items first, so this list doesn't contain any other lists prior to
  // lifting it.
  const nestedListNode = listNode.querySelector(selector);
  if (nestedListNode) liftNestedList(nestedListNode, selector);

  const wrapperNode = listNode.parentNode;
  if (wrapperNode.tagName.toUpperCase() === LI_TAG_NAME) {
    liftListItem(listNode, wrapperNode);
  } else {
    liftListWrapper(listNode, wrapperNode);
  }

  wrapperNode.removeChild(listNode);
}

// --------------------------------------------------------------------------
export function handleInsertIntoRichFootnote(view, slice, event) {
  const { state, state: { selection, selection: { $from, $to } } } = view;
  if (!(selection instanceof TextSelection)) return false;
  if ((!$from.parent || $from.parent.type !== schema.nodes.link) && selection.empty) return false;
  if (slice.content.childCount !== 1) return false;
  if (codeBlockDepth($from)) return false;
  let replaceText = false;

  let content = slice.content.content[0];
  if (content.type === schema.nodes.paragraph && content.childCount === 1) content = content.content.content[0];
  let linkAttrs = {};
  if (content.type.name === "image" || content.type.name === "video") {
    linkAttrs.media = { url: content.attrs.src };
  } else if (isUrl(content.textContent)) {
    const selectedString = state.doc.textBetween($from.pos, $to.pos);
    if (selectedString && selectedString.length && isUrl(selectedString.trim())) {
      replaceText = true;
    }
    linkAttrs.href = content.textContent;
  } else {
    return false;
  }

  let transform = state.tr;
  if ($from.parent.type === schema.nodes.link) {
    const linkPos = $from.pos - $from.parentOffset - 1;
    const newAttrs = { ...$from.parent.attrs, ...linkAttrs };
    if (replaceText) {
      transform.replaceRangeWith($from.pos, $from.pos + $from.nodeAfter.nodeSize, schema.nodes.link.create(newAttrs, schema.text(content.textContent)));
    } else {
      transform.setNodeMarkup(linkPos, schema.nodes.link, newAttrs);
    }
  } else {
    const $cursor = $from.nodeAfter && $from.nodeAfter.type === schema.nodes.link ? $from : $to;
    if ($cursor.nodeAfter && $cursor.nodeAfter.type === schema.nodes.link) {
      linkAttrs = { ...$cursor.nodeAfter.attrs, ...linkAttrs };
      if (replaceText) {
        transform.replaceRangeWith($from.pos, $to.pos, schema.nodes.link.create(linkAttrs, schema.text(content.textContent)));
      } else {
        transform.setNodeMarkup($cursor.pos, schema.nodes.link, linkAttrs);
      }
    } else {
      transform = linkSelectionTransform(state, false, { defaultLinkAttributes: linkAttrs });
      if (!transform) return false;
    }
  }

  event.stopPropagation(); // As of Dec 2022 this was required to an image from being inserted inline by other listeners
  view.dispatch(transform)
  return true;
}

// --------------------------------------------------------------------------
export function expandMarkdownToHTML(markdown) {
  markdown = markdown.replace(/(\s|^)\*([^*\n]+)\*(\s|$)/g, "$1<strong>$2</strong>$3");
  markdown = markdown.replace(/(\s|^)_([^_\n]+)_(\s|$)/g, "$1<em>$2</em>$3");
  markdown = markdown.replace(/(\s|^)`([^`\n]+)`(\s|$)/g, "$1<span class='pasted-code'>$2</span>$3");
  return markdown;
}

// --------------------------------------------------------------------------
export function pasteListItems(view, fromNode, slice) {
  const { selection: { $from, empty } } = view.state;
  const tr = view.state.tr;

  tr.deleteSelection();

  if (fromNode.content.size > 2) {
    const atListItemStart = empty && $from.pos === $from.start($from.depth);
    const insertPos = tr.mapping.map(atListItemStart ? $from.before(-1) : $from.after(-1));

    // Need to use replaceRange here to correctly close any nodes left open in the slice
    tr.replaceRange(insertPos, insertPos, slice);

    let selectionPosition = null;
    try {
      selectionPosition = Selection.findFrom(tr.doc.resolve(insertPos + slice.content.size), 1)
    } catch (_error) {
      // resolve can throw RangeError: Position N out of range
    }
    tr.setSelection(selectionPosition || Selection.atEnd(tr.doc));
  } else if (empty) {
    // Pasting into an empty list item - we prefer to take the attributes from the list item being pasted, so
    // we need to delete the empty list item first, then insert (using replaceRange to handle open/close positions
    // in the pasted slice correctly) the new list items so the first item's content isn't added to the existing
    // check-list-item, losing the first pasted item's attributes
    const insertPos = $from.before(-1);
    tr.delete(insertPos, $from.end(-1));
    tr.replaceRange(insertPos, insertPos, slice);
  } else {
    tr.replaceSelection(slice);
  }

  view.dispatch(tr.scrollIntoView().setMeta(TRANSACTION_META_KEY.PASTE, true));
  return true;
}

// --------------------------------------------------------------------------
// When allowing Prosemirror to handle pasting content that originated within a list item, it will remove any
// links in that content when pasting it into another list item. This function instead preserves the copied content
// when moving it into a new list item
export function liftPastedListItemSlice(slice, $from) {
  slice.content = slice.content.content[0].content;
  slice.openStart = $from.depth - 1;
  slice.openEnd = $from.depth - 1;
}

// --------------------------------------------------------------------------
// Local functions
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
function liftListItem(listNode, wrapperNode) {
  const nextSibling = wrapperNode.nextSibling;
  listNode.childNodes.forEach(liNode => {
    // Malformed HTML that is pasted in might contain non-list nodes as children of wrapperNode. We'll omit these
    // since they're not valid HTML (and they shouldn't have an indent-* class applied to them)
    if ([ "LI", "UL", "OL" ].includes(liNode.tagName)) {
      // Must deep clone node or the insertBefore/appendChild will move the actual node out of childNodes, making
      // iteration not work as expected.
      const clonedLiNode = liNode.cloneNode(true);
      const className = clonedLiNode.className || "";

      const indent = indentFromListElement(liNode);
      const match = className.match(/\bindent-(\d)/);
      if (match) {
        clonedLiNode.className = className.replace(match[0], `indent-${ indent }`);
      } else {
        clonedLiNode.className = className + ` indent-${ Math.max(indent || 0, 0) }`;
      }

      if (nextSibling) {
        wrapperNode.parentNode.insertBefore(clonedLiNode, nextSibling);
      } else {
        wrapperNode.parentNode.appendChild(clonedLiNode);
      }
      wrapperNode = listNode.parentNode
    }
  });
}

// --------------------------------------------------------------------------
function liftListWrapper(listWrapperNode, insertIntoNode) {
  const nextSibling = listWrapperNode.nextSibling;
  const listWrapperIndent = indentFromListElement(listWrapperNode);
  listWrapperNode.childNodes.forEach(liNode => {
    liNode = liNode.cloneNode(true);

    // If we already lifted this, its class might have been assigned to list item. Otherwise, we can take it from the UL/OL node
    if (listWrapperIndent !== null && (!liNode.className || !liNode.className.match(/\bindent-(\d)/))) {
      liNode.className = `${ liNode.className || "" } indent-${ listWrapperIndent }`;
    }

    if (nextSibling) {
      insertIntoNode.insertBefore(liNode, nextSibling);
    } else {
      insertIntoNode.appendChild(liNode);
    }
  });
}

import { omit } from "lodash"
import PropTypes from "prop-types"
import { Node } from "prosemirror-model"
import { EditorState } from "prosemirror-state"
import React from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import { IconButton } from "@rmwc/icon-button"

import EditorViewWrapper from "lib/ample-editor/components/editor-view-wrapper"
import ReferencingNotes from "lib/ample-editor/components/referencing-notes"
import TagIcon from "lib/ample-editor/components/tag-icon"
import HostAppContext from "lib/ample-editor/contexts/host-app-context"
import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"
import { targetFromURL } from "lib/ample-editor/lib/link-util"
import storageValueFromDocument from "lib/ample-editor/util/storage-value-from-document"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import checkListItemPlugin from "lib/ample-editor/plugins/check-list-item-plugin"
import { schema } from "lib/ample-editor/schema"
import nodeViews from "lib/ample-editor/views"
import { noteParamsFromHref, urlFromNoteParams } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
function isEmptyDocument(document) {
  // This matches the logic used to show a placeholder in the editor (for an empty document)
  return document.childCount === 1 &&
    document.firstChild.type.name === "paragraph" &&
    document.firstChild.content.size === 0;
}

// --------------------------------------------------------------------------
function Metadata({ metadata, nonBlankLinkTarget, noteHref }) {
  const { icon, name, tags } = metadata;

  const renderedTags = tags.map(tag => {
    const { text } = tag;

    return (
      <div className="tag" key={ text }>
        <TagIcon { ...tag } />
        { text }
      </div>
    );
  });

  return (
    <div className="note-preview-metadata">
      <div className="note-name">
        <i className="note-icon material-icons">{ icon }</i>
        { name }

        <IconButton
          href={ noteHref }
          icon="open_in_new"
          tag="a"
          target={ targetFromURL(noteHref, nonBlankLinkTarget) }
        />
      </div>
      { renderedTags.length ? (<div className="note-tags">{ renderedTags }</div>) : null }
    </div>
  );
}

// --------------------------------------------------------------------------
export default class NotePreview extends React.PureComponent {
  static contextType = HostAppContext;
  static propTypes = {
    nonBlankLinkTarget: PropTypes.string,
    noteHref: PropTypes.string.isRequired,
  };

  state = {
    backgroundColor: null,
    bannerImageURL: null,
    document: null,
    failed: false,
    metadata: null,
    referencedNote: null,
    referencingNotes: null,
  };

  _editorProps = null;
  _editorState = null;
  _editorView = null;
  _nodeViews = omit(nodeViews(), [ "link" ]);

  // --------------------------------------------------------------------------
  async componentDidMount() {
    const { fetchNoteContent, fetchReferencingNotes, mountPluginEmbed } = this.context;
    const { noteHref } = this.props;

    const noteParams = noteParamsFromHref(noteHref);

    if (noteParams && noteParams.tab === EDITOR_TAB.REFERENCES) {
      const { referencedNote, referencingNotes } = await fetchReferencingNotes(noteHref);
      this.setState({ referencedNote, referencingNotes });
      return;
    }

    const noteURL = urlFromNoteParams(noteParams);
    const { icon, name, node, tags } = await fetchNoteContent(noteURL);
    if (!node) {
      this.setState({ failed: true });
      return;
    }

    const document = Node.fromJSON(schema, node);

    this._editorState = EditorState.create({
      doc: document,
      plugins: [
        checkListItemPlugin,
      ],
    });

    this._editorProps = {
      hostApp: {
        mountPluginEmbed,
        openAttachment: this._openAttachment,
      },
    };

    this.setState({
      backgroundColor: storageValueFromDocument(document, "backgroundColor"),
      bannerImageURL: storageValueFromDocument(document, "bannerImageURL"),
      document,
      metadata: { icon, name, tags },
    });
  }

  // --------------------------------------------------------------------------
  render() {
    const {
      backgroundColor,
      bannerImageURL,
      failed,
      metadata,
      referencedNote,
      referencingNotes,
    } = this.state;
    if (failed) return null;

    const { noteHref, nonBlankLinkTarget } = this.props;

    let className = "preview-section note-preview";
    if (referencedNote && referencingNotes) className += " note-references-tab";

    return (
      <React.Fragment>
        { backgroundColor ? (<div className="background-color-preview" style={ { backgroundColor } } />) : null }
        { bannerImageURL ? (<div className="banner-image-preview"><img src={ bannerImageURL } /></div>) : null }

        <div className={ className }>
          {
            metadata
              ? (<Metadata metadata={ metadata } nonBlankLinkTarget={ nonBlankLinkTarget } noteHref={ noteHref } />)
              : null
          }
          { this._renderContent() }
        </div>
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _dispatchTransaction = transaction => {
    const editorState = this._editorState.apply(transaction);

    if (this._editorView !== null) {
      this._editorView.updateState(editorState);
    }

    this._editorState = editorState;

    const noteLink = transaction.getMeta(TRANSACTION_META_KEY.OPEN_NOTE_LINK);
    if (noteLink) {
      const { openNoteLink } = this.context;
      if (openNoteLink) openNoteLink(noteLink);
    }
  };

  // --------------------------------------------------------------------------
  _isEditable = () => {
    return false;
  };

  // --------------------------------------------------------------------------
  _openAttachment = async (attachmentURL, options = {}) => {
    const { openAttachment } = this.context;
    if (openAttachment) await openAttachment(attachmentURL, options);
  };

  // --------------------------------------------------------------------------
  _refreshReferencingNotes = async () => {
    const { fetchReferencingNotes } = this.context;
    const { noteHref } = this.props;

    const noteParams = noteParamsFromHref(noteHref);
    if (!noteParams || noteParams.tab !== EDITOR_TAB.REFERENCES) return;

    const noteURL = urlFromNoteParams(noteParams);
    const { referencedNote, referencingNotes } = await fetchReferencingNotes(noteURL);
    this.setState({ referencedNote, referencingNotes });
  };

  // --------------------------------------------------------------------------
  _renderContent() {
    const { document, referencedNote, referencingNotes } = this.state;

    if (!document && !referencedNote && !referencingNotes) {
      return (
        <div className="loading-indicator-container">
          <CircularProgress size="medium" />
        </div>
      );
    }

    if (document) {
      if (isEmptyDocument(document)) {
        return (<div className="note-preview-editor empty">no content yet</div>);
      }

      return (
        <EditorViewWrapper
          className="note-preview-editor"
          dispatchTransaction={ this._dispatchTransaction }
          editorProps={ this._editorProps }
          editorState={ this._editorState }
          isEditable={ this._isEditable }
          nodeViews={ this._nodeViews }
          setEditorView={ this._setEditorView }
        />
      );
    } else if (referencedNote && referencingNotes) {
      return (
        <ReferencingNotes
          readonly
          referencedNote={ referencedNote }
          referencingNotes={ referencingNotes }
          refreshReferencingNotes={ this._refreshReferencingNotes }
        />
      )
    }
  }

  // --------------------------------------------------------------------------
  _setEditorView = editorView => {
    this._editorView = editorView;
  };
}

import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { Button } from "@rmwc/button"
import Tippy from "@tippyjs/react"

import { hexColorFromString, isCycleVarColor } from "lib/ample-editor/lib/color-util"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import {
  MIXED_TABLE_SELECTION_VALUE,
} from "lib/ample-editor/lib/table/table-constants"

const FULL_HEX_LENGTH = 6; // Perhaps don't require all 6 characters in vNext
const COLORS_PER_LINE_COUNT = 11;
const COLOR_LINES_COUNT = 5;

// --------------------------------------------------------------------------
function usePasteHandling(setColorAsHex) {
  // --------------------------------------------------------------------------
  const handlePaste = async _event => {
    let text;
    try {
      text = await navigator.clipboard.readText();
    } catch (error) {
      return;
    }

    // Automatically strips possible "#" prefix, and converts non-hex colors to hex if possible
    const colorString = hexColorFromString(text);
    if (colorString && colorString.length) {
      setColorAsHex(colorString);
    }
  };

  useEffect(() => {
    window.addEventListener("paste", handlePaste);

    return () => { // Remove event listener on cleanup
      window.removeEventListener("paste", handlePaste);
    };
  }, [ setColorAsHex ]);
}

// --------------------------------------------------------------------------
function renderCustomColorOption(customColorSelected, customColorFocused, printableHex, setCustomColorFocused,
    onHexColorChange, onHexColorKeydown, clickApplyColor, noFillButtonAvailable) {
  let noFillButton = null;
  if (noFillButtonAvailable) {
    noFillButton = (
      <div className="color-swatch-no-fill" data-color="" onClick={ clickApplyColor } onMouseDown={ preventEventDefault }>
        <div className="material-icons">hide_source</div>
        <div className="no-fill-text">No fill</div>
      </div>
    );
  }

  return (
    <div className="custom-color-container">
      <div className="padded-content">
        { noFillButton }
        <div className={ `custom-color-input-container${ customColorSelected ? " is-selected" : "" }` }>
          <div className={ `outline-container ${ customColorFocused ? " active" : ""}` }>
            <div className="material-icons hex-indicator-icon" style={ { color: printableHex.length === FULL_HEX_LENGTH ? `#${ printableHex }` : "" } }>circle</div>
            <label className="hash">#</label>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className="custom-color-input"
              maxLength="6"
              onBlur={ () => setCustomColorFocused(false) }
              onChange={ onHexColorChange }
              onFocus={ () => setCustomColorFocused(true) }
              onKeyDown={ onHexColorKeydown }
              placeholder="Custom"
              type="text"
              value={ printableHex }
            />
          </div>
          <Button
            className="custom-color-button"
            data-color={ printableHex.length === FULL_HEX_LENGTH ? `#${ printableHex }` : "" }
            disabled={ printableHex.length !== FULL_HEX_LENGTH }
            label="Apply"
            onClick={ clickApplyColor }
            onMouseDown={ preventEventDefault }
          />
        </div>
        <Tippy
          content={ <><div>Custom colors will remain the same across light and dark modes, while colors picked from the palette above will dynamically adjust</div></> }
        >
          <span className="material-icons help-icon">help</span>
        </Tippy>
      </div>
    </div>
  );
}

// --------------------------------------------------------------------------
// This component is the color menu that pops out upon clicking certain icons in CellSelectionMenu, the star found below
export default function ColorSelectionMenu({
  allowCustom,
  applySelectedColor,
  selectedColor,
  noFillButtonAvailable,
  noGradientColorsAvailable,
}) {
  const selectedColorAsHex = selectedColor && selectedColor.length === FULL_HEX_LENGTH ? selectedColor : "";
  const colorAsVariable = selectedColor && isCycleVarColor(selectedColor) ? selectedColor : null;
  const [ colorAsHex, setColorAsHex ] = useState("");
  const [ customColorFocused, setCustomColorFocused ] = useState(false);
  usePasteHandling(setColorAsHex);

  useEffect(() => {
    setColorAsHex(selectedColorAsHex);
  }, [ selectedColorAsHex ]);

  // --------------------------------------------------------------------------
  const clickApplyColor = useCallback(event => {
    applySelectedColor(event.currentTarget.dataset.color);
  }, [ applySelectedColor ]);

  // --------------------------------------------------------------------------
  const onHexColorChange = event => {
    const validValue = event.target.value.toUpperCase().match(/^[0-9A-F]{0,6}$/);
    if (validValue) {
      setColorAsHex(validValue[0]);
    }
  };

  // --------------------------------------------------------------------------
  const onHexColorKeydown = useCallback(event => {
    if (event.key === "Enter") {
      event.preventDefault(); // We don't want form submit to propagate to other elements, even if chooseColor somehow failed
      applySelectedColor(`#${ colorAsHex }`);
    }
  }, [ applySelectedColor, colorAsHex ]);

  let printableHex = colorAsHex && colorAsHex.replace("#", "").
    replace(MIXED_TABLE_SELECTION_VALUE.toString(), "");
  printableHex = printableHex || "";

  const iconProps = { icon: "tag" };
  if (printableHex.length === FULL_HEX_LENGTH) {
    iconProps.style = { color: `#${ printableHex }` };
  }

  const swatchEl = colorIndex => (
    <div
      className={ `color-swatch ${ colorAsVariable === `cycle-color-${ colorIndex }` ? "is-selected" : "" }` }
      data-color={ `cycle-color-${ colorIndex }` }
      data-background-color={ colorIndex }
      key={ `cycle-color-${ colorIndex }` }
      onClick={ clickApplyColor }
      onMouseDown={ preventEventDefault }
    >
      <div className="inner-swatch" />
    </div>
  )

  const gradientStartIndex = COLOR_LINES_COUNT * COLORS_PER_LINE_COUNT + 1;
  const colorSwatchRows = [];
  for (let row = 0; row < COLOR_LINES_COUNT; row++) {
    const rowSwatches = [];
    for (let column = 0; column < COLORS_PER_LINE_COUNT; column++) {
      const colorIndex = row * COLORS_PER_LINE_COUNT + column + 1;
      rowSwatches.push(swatchEl(colorIndex))
    }
    if (!noGradientColorsAvailable) {
      rowSwatches.push(swatchEl(gradientStartIndex + row));
    }
    colorSwatchRows.push(<div className="color-swatch-row" key={ `swatch-${ row }` }>{ rowSwatches }</div>);
  }

  const customColorSelected = printableHex.length && printableHex === selectedColorAsHex;

  return (
    <div className="color-selection-menu">
      <div className="padded-content">
        <div className="color-swatches-container">
          { colorSwatchRows }
        </div>
      </div>
      {
        allowCustom
          ? renderCustomColorOption(customColorSelected, customColorFocused, printableHex, setCustomColorFocused, onHexColorChange, onHexColorKeydown, clickApplyColor, noFillButtonAvailable)
          : null
      }
    </div>
  );
}
ColorSelectionMenu.propTypes = {
  allowCustom: PropTypes.bool,
  applySelectedColor: PropTypes.func.isRequired, // Propagate the color back to a CellSelection to which it will be applied to existing attrs
  selectedColor: PropTypes.string, // Either a hex minus "#", OR an AN cell color string
  noFillButtonAvailable: PropTypes.bool, // If true, we'll add button to left side that will call applySelectedColor with "" when clicked
};

import PropTypes from "prop-types"
import React from "react"
import {
  List,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
} from "@rmwc/list"

import { formatDate, formatDateTime } from "lib/ample-util/date"

// --------------------------------------------------------------------------
export const DATE_SUGGESTION_OPTION = {
  START_DATE_TASK: 0,
  START_DATE_EVENT: 1,
  HIDE_UNTIL: 2,
  SCHEDULE: 3,
  DISMISS: 4,
};

// --------------------------------------------------------------------------
export default function DateSuggestionMenu({
  apply,
  bulletInSchema,
  dismiss,
  optionCount,
  selectedIndex,
  suggestion
}) {
  // --------------------------------------------------------------------------
  const onClickAccept = (dateSuggestionOption, event) => {
    event.preventDefault(); // Don't focus the clicked component
    event.stopPropagation(); // Don't propagate this back up to RMWC where it will find an unmounted component after calling apply
    apply(dateSuggestionOption);
  };

  // --------------------------------------------------------------------------
  const renderDismissListItem = () => {
    return (
      <ListItem
        className="date-suggestion-menu-item popup-list-item dismiss"
        onClick={ dismiss }
        selected={ selectedIndex === DATE_SUGGESTION_OPTION.DISMISS }
      >
        <ListItemGraphic icon="close" />
        <ListItemText>
          <ListItemPrimaryText>Dismiss date suggestion</ListItemPrimaryText>
        </ListItemText>
      </ListItem>
    )
  };

  // --------------------------------------------------------------------------
  const { date } = suggestion;
  const userSpecifiedTime = date.getHours() > 0;

  const userTimeString = userSpecifiedTime ? formatDateTime(date) : formatDate(date);
  let convertToBulletOption;
  if (bulletInSchema) {
    convertToBulletOption = (
      <ListItem
        className="date-suggestion-menu-item popup-list-item start-date-event"
        onClick={ onClickAccept.bind(null, DATE_SUGGESTION_OPTION.START_DATE_EVENT) }
        selected={ selectedIndex === DATE_SUGGESTION_OPTION.START_DATE_EVENT }
        title="Set time to start on event"
      >
        <ListItemGraphic icon="event" />
        <ListItemText>
          <ListItemPrimaryText>{ `Set start ${ userSpecifiedTime ? "time" : "date" } for event` }</ListItemPrimaryText>
          <ListItemSecondaryText>for { userTimeString }</ListItemSecondaryText>
        </ListItemText>
      </ListItem>
    )
  }

  return (
    <div className="date-suggestion-menu popup-list">
      <List className="suggestion-menu-options">
        <ListItem
          className="date-suggestion-menu-item popup-list-item start-date-task"
          onClick={ onClickAccept.bind(null, DATE_SUGGESTION_OPTION.START_DATE_TASK) }
          selected={ selectedIndex === DATE_SUGGESTION_OPTION.START_DATE_TASK }
          title="Set time to start on task"
        >
          <ListItemGraphic icon="calendar_today" />
          <ListItemText>
            <ListItemPrimaryText>{ `Set start ${ userSpecifiedTime ? "time" : "date" } for task` }</ListItemPrimaryText>
            <ListItemSecondaryText>for { userTimeString }</ListItemSecondaryText>
          </ListItemText>
        </ListItem>
        { convertToBulletOption }
        <ListItem
          className="date-suggestion-menu-item popup-list-item hide-until"
          onClick={ onClickAccept.bind(null, DATE_SUGGESTION_OPTION.HIDE_UNTIL) }
          selected={ selectedIndex === DATE_SUGGESTION_OPTION.HIDE_UNTIL }
        >
          <ListItemGraphic icon="visibility_off" />
          <ListItemText>
            <ListItemPrimaryText>Hide task</ListItemPrimaryText>
            <ListItemSecondaryText>until { userTimeString }</ListItemSecondaryText>
          </ListItemText>
        </ListItem>
        <ListItem
          className="date-suggestion-menu-item popup-list-item schedule"
          onClick={ onClickAccept.bind(null, DATE_SUGGESTION_OPTION.SCHEDULE) }
          selected={ selectedIndex === DATE_SUGGESTION_OPTION.SCHEDULE }
        >
          <ListItemGraphic icon="schedule_send" />
          <ListItemText>
            <ListItemPrimaryText>Hide until starting task</ListItemPrimaryText>
            <ListItemSecondaryText>on { userTimeString }</ListItemSecondaryText>
          </ListItemText>
        </ListItem>
        { (optionCount - 1) > DATE_SUGGESTION_OPTION.HIDE_UNTIL ? renderDismissListItem() : null }
      </List>
      <div className="instruction-label">
        <span><b>tab</b> to cycle </span>
        <ListItemMeta icon="keyboard_return" /> to select
        <span><b>esc</b> to cancel</span>
      </div>
    </div>
  );
}

DateSuggestionMenu.propTypes = {
  apply: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  optionCount: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  suggestion: PropTypes.shape({
    date: PropTypes.object,
    text: PropTypes.string,
  }).isRequired,
};

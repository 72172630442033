import { v4 as uuidv4 } from "uuid"
import { TextSelection } from "prosemirror-state"

import { mediaFromURL, urlFromLinkAttributes } from "lib/ample-editor/lib/link-util"
import { addFilePluginAction, findLocalFileNodes, localFileURLFromUUID } from "lib/ample-editor/plugins/file-plugin"
import { linkSelectionTransform } from "lib/ample-editor/lib/link-commands"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import ChangeCompletedTaskStep from "lib/ample-editor/steps/change-completed-task-step"
import ChangeHiddenTaskContentStep from "lib/ample-editor/steps/change-hidden-task-content-step"
import SetAttrsStep from "lib/ample-editor/steps/set-attrs-step"

// --------------------------------------------------------------------------
const buildInsertPendingMedia = createLocalNode => (view, loadMedia, pos, { insertTransformCallback = null } = {}) => {
  const uuid = createLocalNode(pos)(view.state, transform => {
    if (view.dispatch) {
      if (insertTransformCallback) insertTransformCallback(transform);
      view.dispatch(transform);
    }
  });

  const { props: { hostApp: { startMediaUpload } } } = view;

  loadMedia
    .then(media => { startMediaUpload(uuid, media, pos); })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.error(error);
      updateLocalFileURL(uuid, null)(view.state, view.dispatch);
    });
};

// --------------------------------------------------------------------------
// Determine if state's selection is positioned such that an insert of nodeTypeName should be placed
// in a Rich Footnote instead of the document. If so, dispatch the transform to place the upload
// inside a link
const insertLocalFileIntoLink = (nodeTypeName, fileUrl) => (state, dispatch) => {
  if (!state) return false;
  if (![ "image", "video" ].includes(nodeTypeName)) return false;
  const { selection, selection: { $head } } = state;
  if (!(selection instanceof TextSelection)) return false;
  const selectionInLink = $head.parent && $head.parent.type.name === "link";
  if (selection.empty && !selectionInLink) return false;
  if (selection.$from.nodeAfter && selection.$from.nodeAfter.type.name === nodeTypeName) return false;

  let transform = state.tr;
  const linkAttrs = { media: { url: fileUrl } };
  if (nodeTypeName === "video") linkAttrs.media.type = "video";
  if (!selectionInLink) {
    // linkSelectionTransform will deduce whether selection is in a position eligible to become a link,
    // returning a null transform if not
    transform = linkSelectionTransform(state, false, { defaultLinkAttributes: linkAttrs });
    if (!transform) return false;
  } else {
    const linkPos = $head.pos - $head.parentOffset - 1;
    transform.setNodeMarkup(linkPos, state.schema.nodes.link, { ...$head.parent.attrs, ...linkAttrs });
  }

  if (dispatch) dispatch(transform);
  return true;
};

// --------------------------------------------------------------------------
const createLocalFile = (nodeTypeName, attributeName, attributes, pos) => (state, dispatch) => {
  const { schema } = state;

  const uuid = uuidv4();

  if (!insertLocalFileIntoLink(nodeTypeName, localFileURLFromUUID(uuid))(state, dispatch)) {
    let imageContent;
    if (nodeTypeName === "image") {
      ({ attributes, imageContent } = attributesFromSelectionImage(nodeTypeName, state, attributes));
    }

    const node = schema.nodes[nodeTypeName].create({ ...attributes, [attributeName]: localFileURLFromUUID(uuid) });
    const transform = state.tr;

    // If selection was present, this image/video file supersedes it
    transform.deleteSelection();
    transform.insert(pos, node);
    if (imageContent) {
      transform.insert(pos + 1, imageContent);
    }
    addFilePluginAction(transform, { add: { uuid } });

    if (dispatch) dispatch(transform);
  }

  return uuid;
};

// --------------------------------------------------------------------------
const attributesFromSelectionImage = (nodeTypeName, state, attributes) => {
  const { selection: { $from } } = state;
  if (!$from || !$from.nodeAfter || !$from.nodeAfter.type.name === "image") return { attributes };
  const { attrs: { align, width } } = $from.nodeAfter;
  const { content } = $from.nodeAfter;
  const adoptedAttributes = { align, width, ...attributes };
  return { attributes: adoptedAttributes, imageContent: content };
}

// --------------------------------------------------------------------------
// Returns null if the given `updateNode` callback never returns a non-null updated node. If
// the callback returns an updated node, returns the updated content that should be replace
// the passed in content.
//
// Note that the `content` here is a JSON representation of content (array of nodes)
const contentWithUpdatedNodes = (content, updateNode) => {
  if (!content) return null;

  let contentUpdated = false;

  const newContent = [];
  for (let childIndex = 0; childIndex < content.length; childIndex++) {
    let childNode = content[childIndex];

    const updatedNode = updateNode(childNode);
    if (updatedNode) {
      childNode = updatedNode;
      contentUpdated = true;
    }

    if (childNode.content && childNode.content.length > 0) {
      const newChildNodeContent = contentWithUpdatedNodes(childNode.content, updateNode);
      if (newChildNodeContent) {
        childNode = { ...childNode, content: newChildNodeContent };
        contentUpdated = true;
      }
    }

    newContent.push(childNode);
  }

  return contentUpdated ? newContent : null;
};

// --------------------------------------------------------------------------
const localFileURLUpdater = (localFileURL, url) => node => {
  switch (node.type) {
    case "attachment": {
      const { attrs: { data } } = node;

      if (data && data.startsWith(localFileURL)) {
        return { ...node, attrs: { ...node.attrs, data: url } };
      }

      return null;
    }

    case "image":
    case "video": {
      const { attrs: { src } } = node;

      if (src && src.startsWith(localFileURL)) {
        return { ...node, attrs: { ...node.attrs, src: url } };
      }

      return null;
    }

    case "link": {
      const mediaURL = urlFromLinkAttributes(node.attrs);
      if (mediaURL && mediaURL.startsWith(localFileURL)) {
        return { ...node, attrs: { ...node.attrs, media: { ...node.attrs.media, url } } };
      }

      return null;
    }

    default:
      return null;
  }
};

// --------------------------------------------------------------------------
const updateSelectionInsertTransformCallback = pos => transform => {
  const newSelection = TextSelection.near(transform.doc.resolve(pos + 1));
  if (newSelection !== null) transform.setSelection(newSelection);
};

// --------------------------------------------------------------------------
export const attachLinkMedia = (type, linkNodePos) => (state, dispatch) => {
  const { doc, schema } = state;

  const node = doc.nodeAt(linkNodePos);
  if (!node || node.type !== schema.nodes.link) return null;

  const uuid = uuidv4();

  const transform = state.tr;

  const media = mediaFromURL(localFileURLFromUUID(uuid), type);
  transform.setNodeMarkup(linkNodePos, null, { ...node.attrs, media });
  addFilePluginAction(transform, { add: { uuid } });

  if (dispatch) dispatch(transform);

  return uuid;
};

// --------------------------------------------------------------------------
const createLocalAttachment = (name, type, pos) => createLocalFile("attachment", "data", { name, type }, pos);
export const createLocalImage = (pos, attributes = {}) => createLocalFile("image", "src", attributes, pos);
export const createLocalVideo = (pos, attributes = {}) => createLocalFile("video", "src", attributes, pos);

// --------------------------------------------------------------------------
export const insertAttachment = (view, attachmentFile, pos, updateSelection = false) => {
  const insertTransformCallback = updateSelection ? updateSelectionInsertTransformCallback(pos) : null;
  insertPendingAttachment(view, attachmentFile, pos, { insertTransformCallback });
};

// --------------------------------------------------------------------------
export const insertPendingAttachment = (view, attachment, pos, { insertTransformCallback = null } = {}) => {
  const uuid = createLocalAttachment(attachment.name, attachment.type, pos)(view.state, transform => {
    if (view.dispatch) {
      if (insertTransformCallback) insertTransformCallback(transform);
      view.dispatch(transform);
    }
  });

  const { props: { hostApp: { startAttachmentUpload } } } = view;

  startAttachmentUpload(uuid, attachment, pos);
};

// --------------------------------------------------------------------------
export const insertPendingImage = buildInsertPendingMedia(createLocalImage);
const insertPendingVideo = buildInsertPendingMedia(createLocalVideo);

// --------------------------------------------------------------------------
export const insertMedia = (view, mediaFile, pos, updateSelection = false) => {
  const loadMedia = new Promise(resolve => resolve(mediaFile));

  const insertTransformCallback = updateSelection ? updateSelectionInsertTransformCallback(pos) : null;

  if (mediaFile.type.startsWith("image/")) {
    insertPendingImage(view, loadMedia, pos, { insertTransformCallback });
  } else if (mediaFile.type.startsWith("video/")) {
    insertPendingVideo(view, loadMedia, pos, { insertTransformCallback });
  }
};

// --------------------------------------------------------------------------
export const updateLocalFileURLs = urlByUUID => (state, dispatch) => {
  const transaction = state.tr;

  // We don't want this transform added to history, since undoing it would leave us with a (presumably invalidated)
  // temporary image again
  transaction.setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);

  Object.keys(urlByUUID).forEach(uuid => {
    let url = urlByUUID[uuid];
    let shouldUpdateDocument = false;

    const failedLocalFileURL = localFileURLFromUUID(uuid, true);
    if (url === null || url === failedLocalFileURL) {
      if (url === null) url = failedLocalFileURL;
      shouldUpdateDocument = true;
    } else if (url.startsWith("data:") || url.startsWith("blob:") || url.startsWith("file:")) {
      addFilePluginAction(transaction, { update: { uuid, url } });
    } else {
      addFilePluginAction(transaction, { remove: { uuid } });
      shouldUpdateDocument = true;
    }

    if (shouldUpdateDocument) {
      const { schema } = state;

      findLocalFileNodes(state, uuid).forEach(({ node, pos }) => {
        if (node.type === schema.nodes.image || node.type === schema.nodes.video) {
          transaction.step(new SetAttrsStep(pos, { ...node.attrs, src: url }));
        } else if (node.type === schema.nodes.link) {
          transaction.step(new SetAttrsStep(pos, { ...node.attrs, media: { ...(node.attrs.media || {}), url } }));
        } else if (node.type === schema.nodes.attachment) {
          transaction.step(new SetAttrsStep(pos, { ...node.attrs, data: url }));
        }
      });

      const { attrs: { completedTasks, hiddenTasks } } = transaction.doc;
      const localFileURL = localFileURLFromUUID(uuid);

      const updateNode = localFileURLUpdater(localFileURL, url);

      (completedTasks || []).forEach(completedTask => {
        const newContent = contentWithUpdatedNodes(completedTask.p, updateNode);
        if (newContent) transaction.step(new ChangeCompletedTaskStep(schema, completedTask, { p: newContent }));
      });

      (hiddenTasks || []).forEach(({ attrs, content }) => {
        const newContent = contentWithUpdatedNodes(content, updateNode);
        if (newContent) transaction.step(new ChangeHiddenTaskContentStep(schema, attrs.uuid, content, newContent));
      });
    }
  });

  if (dispatch) dispatch(transaction);
  return true;
};

// --------------------------------------------------------------------------
export const updateLocalFileURL = (uuid, url) => updateLocalFileURLs({ [uuid]: url });

// --------------------------------------------------------------------------
// Note that the value is shown to the user and is what we match in fuzzy matching (sans dashes)
const LIST_ITEM_COMMAND = {
  COLLAPSE: "collapse",
  COMPLETE: "complete",
  COPY: "copy",
  CROSS_OUT: "cross-out",
  DELETE: "delete",
  DISMISS: "dismiss",
  DURATION: "duration",
  EVERY: "every",
  EXPAND: "expand",
  IMPORTANT: "important",
  HIDE: "hide",
  MAX_SCORE: "max-score",
  MOVE: "move",
  RESET_SCORE: "reset-score",
  SCHEDULE: "schedule",
  SET_TASK_SCORE: "set-task-score",
  START: "start",
  SWITCH_TO_BULLET: "switch-to-bullet",
  SWITCH_TO_SCHEDULED_BULLET: "switch-to-scheduled-bullet",
  SWITCH_TO_TASK: "switch-to-task",
  URGENT: "urgent",
};
export default LIST_ITEM_COMMAND;

import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"
import { v4 as uuidv4 } from "uuid"

// --------------------------------------------------------------------------
export default class ChangeHiddenTaskAttrsStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    const { changes, taskAttrs } = json;
    return new ChangeHiddenTaskAttrsStep(schema, taskAttrs, changes);
  }

  // --------------------------------------------------------------------------
  constructor(schema, taskAttrs, changes) {
    super();

    this.changes = changes;
    this.schema = schema;
    this.taskAttrs = taskAttrs;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const { uuid } = this.taskAttrs;

    const serializedDoc = doc.toJSON();

    let changedCount = 0;
    const hiddenTasks = (serializedDoc.attrs.hiddenTasks || []).map(hiddenTask => {
      if (hiddenTask.attrs.uuid === uuid) {
        const updatedHiddenTask = { ...hiddenTask, attrs: { ...hiddenTask.attrs, ...this.changes } }

        // If there are multiple hidden tasks with the same UUID, we can't easily deal with it because the UUID is how
        // we identify the task to make changes. We're not really sure what the caller meant (i.e. which task to change,
        // so we'll just assume they all should be changed the same way, but give any duplicates a new uuid so they
        // can be handled separately in the future.
        if (changedCount > 0) {
          updatedHiddenTask.attrs = { ...updatedHiddenTask.attrs, uuid: uuidv4() };
        }
        changedCount += 1;

        return updatedHiddenTask;
      } else {
        return hiddenTask;
      }
    });

    serializedDoc.attrs = { ...serializedDoc.attrs, hiddenTasks };

    doc = Node.fromJSON(this.schema, serializedDoc);

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    // This is not _exactly_ true, as changes may not include all task attributes, while this.taskAttrs will, but it
    // will result in the inverse change.
    const taskAttrsAfter = { ...this.taskAttrs, ...this.changes };
    return new ChangeHiddenTaskAttrsStep(this.schema, taskAttrsAfter, this.taskAttrs);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      changes: this.changes,
      stepType: "changeHiddenTaskAttrs",
      taskAttrs: this.taskAttrs,
    };
  }
}
Step.jsonID("changeHiddenTaskAttrs", ChangeHiddenTaskAttrsStep);

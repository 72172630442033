import { default as parseFrontMatter } from "gray-matter"
import markdownit from "markdown-it"
import markdownItFootnote from "markdown-it-footnote"
import markdownItMark from "markdown-it-mark"
import { markdownItTable } from "markdown-it-table"
import { Fragment, Mark } from "prosemirror-model"

import { valueFromDescriptionDocumentContent } from "lib/ample-editor/lib/rich-footnote-util"
import TABLE_CELL_ATTRIBUTE_DEFAULTS from "lib/ample-editor/lib/table/table-cell-attribute-defaults"
import { schema } from "lib/ample-editor/schema"
import {
  completedTaskFromCheckListItem,
  isDoneFromTask,
  isHiddenFromTask,
  minutesFromDuration,
  flagsStringFromFlagsObject,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// Originally inlined from prosemirror-markdown to adjust for our schema, with additional modifications to handle
// advanced markdown usage (tables, lists, etc).
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
const BLANK_LINE_TEXT_CONTENTS = [
  "\\",
  "<br>",
  "<br/>",
  "<br />",
];

const PLUGIN_EMBED_OBJECT_PATTERN = /^<object\s+data=['"](plugin:\/\/[0-9A-F-]+(?:\?[^#"]+)?)['"]\s*(?:data-aspect-ratio=['"]([.\d]+)['"]\s*)?\/>\n?$/i;

const SUPPORTED_NOTE_STORAGE_KEYS = [
  "appearance",
  "backgroundColor",
  "bannerImageFullWidth",
  "bannerImageURL",
  "defaultTaskCompletionMode",
  "maxOpenTasks",
];

// --------------------------------------------------------------------------
function buildAttrs(spec, token, tokens, i) {
  if (spec.getAttrs) return spec.getAttrs(token, tokens, i)
  // For backwards compatibility when `attrs` is a Function
  else if (spec.attrs instanceof Function) return spec.attrs(token)
  else return spec.attrs
}

// --------------------------------------------------------------------------
function consumeInlineAttributes({ attrs, type }, inlineAttributes) {
  function consumeInlineAttribute(name, callback) {
    if (name in inlineAttributes) {
      // eslint-disable-next-line callback-return
      callback(inlineAttributes[name]);
      delete inlineAttributes[name];
    }
  }

  // These attributes are shared between these node types
  if (type === schema.nodes.bullet_list_item || type === schema.nodes.check_list_item) {
    consumeInlineAttribute("duration", duration => {
      if (minutesFromDuration(duration) !== null) attrs.duration = duration;
    });

    consumeInlineAttribute("indent", indent => {
      attrs.indent = indent;
    });

    consumeInlineAttribute("notify", notify => {
      if (minutesFromDuration(notify) !== null) attrs.notify = notify;
    });

    consumeInlineAttribute("repeat", repeat => {
      attrs.repeat = repeat;
    });

    consumeInlineAttribute("uuid", uuid => {
      attrs.uuid = uuid;
    });
  }

  // These attributes are specific to each node type
  if (type === schema.marks.highlight) {
    consumeInlineAttribute("backgroundColor", backgroundColor => {
      attrs.backgroundColor = backgroundColor;
    });

    consumeInlineAttribute("color", color => {
      attrs.color = color;
    });

    for (const [ jsonKey, domAttrName ] of [ [ "cycleColor", "color" ], [ "backgroundCycleColor", "backgroundColor" ] ]) {
      if (/\d+/.test(inlineAttributes[jsonKey])) {
        // When a cycle color is used, the attr value is typically stored in the form `cycle-color-1/#ffffff` but if
        // we can't sample the color for whatever reason (e.g., the color is a gradient), then we fall back to `cycle-color-1`
        attrs[domAttrName] = `cycle-color-${ inlineAttributes[jsonKey] }${ /^#/.test(attrs[domAttrName]) ? `/${ attrs[domAttrName] }` : "" }`;
        delete inlineAttributes[jsonKey];
      }
    }
  } else if (type === schema.nodes.bullet_list_item) {
    consumeInlineAttribute("startAt", startAt => {
      attrs.scheduledAt = startAt;
    });
  } else if (type === schema.nodes.check_list_item) {
    consumeInlineAttribute("hideRule", hideRule => {
      attrs.startRule = hideRule;
    });

    consumeInlineAttribute("hideUntil", hideUntil => {
      attrs.startAt = hideUntil;
    });

    consumeInlineAttribute("startAt", startAt => {
      attrs.due = startAt;
    });

    if ("important" in inlineAttributes || "urgent" in inlineAttributes) {
      const flags = {};

      consumeInlineAttribute("important", important => {
        if (important) flags.important = true;
      });

      consumeInlineAttribute("urgent", urgent => {
        if (urgent) flags.urgent = true;
      });

      attrs.flags = flagsStringFromFlagsObject(flags);
    }
  } else if (type === schema.nodes.heading) {
    consumeInlineAttribute("collapsed", collapsed => {
      if (collapsed) attrs.collapsed = true;
    });

    if (inlineAttributes["omit"]) {
      delete inlineAttributes["omit"];
      return true;
    }
  } else if (type === schema.nodes.number_list_item) {
    consumeInlineAttribute("indent", indent => {
      attrs.indent = indent;
    });

    consumeInlineAttribute("offset", offset => {
      attrs.offset = offset;
    });
  } else if (type === schema.nodes.table_cell) {
    consumeInlineAttribute("cell", tableCellAttributes => {
      Object.keys(TABLE_CELL_ATTRIBUTE_DEFAULTS).forEach(attributeName => {
        if (!(attributeName in tableCellAttributes)) return;

        const attributeValue = tableCellAttributes[attributeName];
        const defaultAttributeValue = TABLE_CELL_ATTRIBUTE_DEFAULTS[attributeName];
        if (attributeValue !== defaultAttributeValue) {
          attrs[attributeName] = attributeValue;
        }
      });
    });
  } else if (type === schema.nodes.table) {
    consumeInlineAttribute("fullWidth", fullWidth => {
      if (fullWidth) attrs.fullWidth = true;
    });
  }

  return false;
}

// --------------------------------------------------------------------------
// To detect the variety of check list items we support (and output), we get bullet list items that start
// with the check-list-item designator ("[]"), which we need to strip out at this point
function convertBulletListItemToCheckListItem(state, info) {
  const { content, type } = info;

  if (type.name !== "bullet_list_item" || content.length === 0) return;

  const paragraphNode = content[0];
  if (paragraphNode.type.name !== "paragraph" || paragraphNode.childCount === 0) return;

  const { firstChild: textNode } = paragraphNode;
  if (textNode.type.name !== "text" || !textNode.text) return;

  const checkboxMatch = textNode.text.match(/^\s*\[(\s*x\s*|\s?)]\s*/i);
  if (!checkboxMatch) return;

  let newParagraphNode;
  const newText = textNode.text.slice(checkboxMatch[0].length);
  if (newText.length > 0) {
    const newTextNode = state.schema.text(newText, textNode.marks);

    newParagraphNode = paragraphNode.type.createAndFill(
      paragraphNode.attrs,
      paragraphNode.content.replaceChild(0, newTextNode),
      paragraphNode.marks
    );
  } else {
    // Can't have an empty text node
    const paragraphChildNodes = [];
    paragraphNode.content.forEach((paragraphChildNode, _offset, index) => {
      if (index > 0) paragraphChildNodes.push(paragraphChildNode);
    });

    newParagraphNode = paragraphNode.type.createAndFill(
      paragraphNode.attrs,
      Fragment.fromArray(paragraphChildNodes),
      paragraphNode.marks
    );
  }

  // Content is a plain array, not a ProseMirror Fragment, so it's safe to mutate
  content[0] = newParagraphNode;

  // `info` is a plain object, not yet a ProseMirror `Node`, so it's safe to mutate
  info.type = state.schema.nodes["check_list_item"];

  if (checkboxMatch[1].trim().toLowerCase() === "x") {
    info.attrs.completedAt = Math.floor(Date.now() / 1000);
  }
}

// --------------------------------------------------------------------------
// These are pretty restrictive colors, matching what we support in markdown-serializer.js
function cssColorFromStyle(style, colorName) {
  const hexColorPattern = new RegExp(`[";]\\s*${ colorName }:\\s*(#(?:[\\dA-F]{3,4}|[\\dA-F]{6}|[\\dA-F]{8}));`, "i");
  const hexColorMatch = style.match(hexColorPattern);
  if (hexColorMatch) return hexColorMatch[1];

  const cssVarPattern = new RegExp(`[";]\\s*${ colorName }:\\s*(var\\(--[\\w\\-]+\\))\\s*;`, "i");
  const cssVarMatch = style.match(cssVarPattern);
  if (cssVarMatch) return cssVarMatch[1];

  return null;
}

// --------------------------------------------------------------------------
function maybeMerge(a, b) {
  if (a.isText && b.isText && Mark.sameSet(a.marks, b.marks)) {
    return a.withText(a.text + b.text)
  }
}

// --------------------------------------------------------------------------
// In some cases, the markdown might contain multiple child nodes, but we only allow a single (paragraph or
// code block) child node in list items, so they need to be merged here into one child node
function mergeListItemChildren(state, info) {
  const { content } = info;
  // eslint-disable-next-line no-shadow
  const { schema } = state;

  if (content.length === 0) return;

  if (content.length === 1) {
    const [ childNode ] = content;

    // For some unknown reason, the markdown-it parser will import a list item with an empty nested list item
    // as a heading instead of a paragraph
    if (childNode.type === schema.nodes.heading) {
      info.content = Fragment.from(schema.nodes.paragraph.createAndFill(null, childNode.content));
    }

    return;
  }

  const canMergeContent = content.every(({ type }) => {
    // Note this doesn't account for combining to a single code_block child
    return type === schema.nodes.paragraph ||
      type === schema.nodes.heading ||
      type === schema.nodes.horizontal_rule;
  });

  if (!canMergeContent) return;

  const firstChildNode = content[0];

  let firstChildParagraph;
  if (firstChildNode.type === schema.nodes.paragraph) {
    firstChildParagraph = firstChildNode;
  } else if (firstChildNode.type === schema.nodes.heading) {
    firstChildParagraph = schema.nodes.paragraph.createAndFill(null, firstChildNode.content);
  } else if (firstChildNode.type === schema.nodes.horizontal_rule) {
    firstChildParagraph = schema.nodes.paragraph.createAndFill(null, [ schema.text("---") ]);
  } else {
    return;
  }

  const combinedParagraph = content.slice(1).reduce((firstParagraph, childNode) => {
    const { content: childContent, type: childType } = childNode;

    // eslint-disable-next-line default-case
    switch (childType) {
      case schema.nodes.horizontal_rule:
        if (firstParagraph.content.childCount > 0) {
          firstParagraph.content = firstParagraph.content
            .addToEnd(schema.nodes.hard_break.create())
            .addToEnd(schema.text("---"));
        } else {
          firstParagraph.content = Fragment.from(schema.text("---"));
        }
        break;

      case schema.nodes.heading:
      case schema.nodes.paragraph:
        if (childContent.childCount > 0) {
          if (firstParagraph.content) {
            firstParagraph.content = firstParagraph.content
              .addToEnd(schema.nodes.hard_break.create())
              .append(childContent);
          } else {
            firstParagraph.content = childContent;
          }
        }
        break;
    }

    return firstParagraph;
  }, firstChildParagraph);

  info.content = [ combinedParagraph ];
}

// --------------------------------------------------------------------------
function addInlineAttributes(state, json) {
  let attributes = null;
  try {
    attributes = JSON.parse(json);
  } catch (_error) {
    // eslint-disable-next-line no-console
    console.error(_error)
    // Ignore any malformed JSON
  }

  if (typeof(attributes) === "object" && attributes !== null) {
    state.addInlineAttributes(attributes);
  }
}

// --------------------------------------------------------------------------
// Commonmark treats multiple > characters as multiple nested blockquotes, but we
// just want a single blockquote wrapper
function unnestBlockquotes(state, info) {
  const { content } = info;
  // eslint-disable-next-line no-shadow
  const { schema } = state;

  if (content.length === 1 && content[0].type === schema.nodes.blockquote) {
    info.content = content[0].content;
  }
}

// --------------------------------------------------------------------------
// Object used to track the context of a running parse.
class MarkdownParseState {
  completedTasks = [];
  footnoteAnchorStack = [];
  footnoteLinkNodeByAnchor = {};
  footnoteStack = [];
  hiddenTasks = [];
  // Inline attributes are set by HTML comments and apply to the current top-level document child node, regardless
  // of how deep in the document they are found (i.e. in a paragraph node in a list item)
  inlineAttributesStack = [ {} ];
  listStack = [];
  pendingListNodesIndex = -1;
  pendingListNodesStack = [];
  stack = [];

  // eslint-disable-next-line no-shadow
  constructor(schema, tokenHandlers) {
    this.schema = schema;
    this.stack = [ { type: schema.topNodeType, attrs: null, content: [], marks: Mark.none } ];
    this.tokenHandlers = tokenHandlers;
  }

  top() {
    return this.stack[this.stack.length - 1];
  }

  topParent() {
    return this.stack[this.stack.length - 2];
  }

  push(elt) {
    if (this.stack.length) this.top().content.push(elt)
  }

  // Adds the given text to the current position in the document,
  // using the current marks as styling.
  addText(text) {
    if (!text) return
    const top = this.top();
    const nodes = top.content;
    const last = nodes[nodes.length - 1];
    const node = this.schema.text(text, top.marks);
    let merged;
    if (last && (merged = maybeMerge(last, node))) nodes[nodes.length - 1] = merged
    else nodes.push(node)
  }

  // Adds the given mark to the set of active marks.
  openMark(mark) {
    const top = this.top()
    top.marks = mark.addToSet(top.marks)
  }

  // Removes the given mark from the set of active marks.
  closeMark(mark) {
    const top = this.top();

    // Note that typically `openMark` is called with the same reference as this function, but in the case of marks
    // that can show up as inline HTML tags (e.g. highlight <mark> tags), the `mark` that has just been passed in
    // won't be the same as the mark that was originally opened, so we need to find that mark so we can get any
    // modifications it had and can keep applying modifications in `consumeInlineAttributes`
    const openedMark = top.marks.find(otherMark => otherMark.type === mark.type);

    top.marks = mark.type.removeFromSet(top.marks);

    consumeInlineAttributes(openedMark || mark, this.currentInlineAttributes());
  }

  parseTokens(tokens) {
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      // UNCOMMENT to print out tokens as they are parsed, which helps with debugging
      // console.log("token", token.type)

      const handler = this.tokenHandlers[token.type];
      if (!handler) throw new Error("Token type `" + token.type + "` not supported by Markdown parser");
      handler(this, token, tokens, i);
    }
  }

  // --------------------------------------------------------------------------
  addInlineAttributes(attributes) {
    const inlineAttributes = this.inlineAttributesStack[this.inlineAttributesStack.length - 1];
    this.inlineAttributesStack[this.inlineAttributesStack.length - 1] = { ...inlineAttributes, ...attributes };
  }

  // --------------------------------------------------------------------------
  // Add a node at the current position.
  // eslint-disable-next-line no-shadow
  addNode(type, attrs, content) {
    const top = this.top();

    let node = type.createAndFill(attrs, content, top ? top.marks : []);
    if (!node) {
      // There are some things in markdown that aren't valid in our schema, like a blockquote child of a list item,
      // or multiple list item children, but we'll make some effort to retain the node if the children are the problem
      if (content.length) {
        node = type.createAndFill(attrs, [ content[0] ], top ? top.marks : []);
      }

      // Can't create empty text nodes,
      if (!node && type.name !== "text") {
        node = type.createAndFill(attrs, null, top ? top.marks : []);
      }

      if (!node) return null;
    }

    // markdown-it doesn't handle <br /> (with html parsing off) nor \ to denote a blank
    // line in markdown, while otherwise blank lines get collapsed, so we'll manually
    // detect a paragraph that is just "\" as a blank paragraph to allow the serializer
    // to output blank lines
    if (node.type.name === "paragraph" && node.childCount === 1 && node.marks.length === 0 && BLANK_LINE_TEXT_CONTENTS.includes(node.textContent)) {
      node = type.createAndFill(attrs, [], []);
      if (!node) return null;
    }

    if (type === this.schema.nodes.check_list_item) {
      if (isDoneFromTask(attrs)) {
        this.completedTasks.push(completedTaskFromCheckListItem(node.toJSON()));
        return null;
      } else if (isHiddenFromTask(attrs)) {
        this.hiddenTasks.push(node.toJSON());
        return null;
      }
    }

    this.push(node);
    return node;
  }

  clearInlineAttributes() {
    this.inlineAttributesStack[this.inlineAttributesStack.length - 1] = {};
  }

  // --------------------------------------------------------------------------
  currentInlineAttributes() {
    return this.inlineAttributesStack[this.inlineAttributesStack.length - 1];
  }

  // Wrap subsequent content in a node of the given type.
  // eslint-disable-next-line no-shadow
  openNode(type, attrs) {
    this.stack.push({ type, attrs, content: [], marks: Mark.none });
  }

  // Close and return the node that is currently on top of the stack.
  closeNode({ afterNodeAdded = null, beforeNodeAdded = null, shouldOmit = null } = {}) {
    const info = this.stack.pop();

    if (shouldOmit && shouldOmit(this, info)) {
      return null;
    }

    if (beforeNodeAdded) beforeNodeAdded(this, info);

    // If we're in a nested list, we need to hoist the list items to the top level of the document, as we don't
    // nest our list structures as the markdown-it parser does
    if (info.type.spec.isListItem && this.listStack.length > 1) {
      // Despite being nested, we want to treat this as a top level node in regard to inline attributes
      consumeInlineAttributes(info, this.currentInlineAttributes());
      this.clearInlineAttributes();

      if (this.pendingListNodesIndex < 0) {
        this.pendingListNodesStack[this.pendingListNodesStack.length - 1].push(info);
      } else {
        this.pendingListNodesStack[this.pendingListNodesStack.length - 1].splice(this.pendingListNodesIndex, 0, info);
        // This is the node that contained any nested lists, so subsequent nodes should be placed _after_ the nodes
        // from the nested list
        this.pendingListNodesIndex = -1;
      }

      return null;
    }

    if (!info.attrs) info.attrs = {};

    if (info.type === this.schema.nodes.doc) {
      info.attrs.completedTasks = (info.attrs.completedTasks || []).concat(this.completedTasks);
      this.completedTasks = [];

      info.attrs.hiddenTasks = (info.attrs.hiddenTasks || []).concat(this.hiddenTasks);
      this.hiddenTasks = [];
    }

    const shouldOmitNode = consumeInlineAttributes(info, this.currentInlineAttributes());

    if (this.stack.length === 1) {
      // Top-level node (direct child of the document)
      this.clearInlineAttributes();
    }

    if (shouldOmitNode) return null;

    const node = this.addNode(info.type, info.attrs, info.content);
    if (afterNodeAdded) afterNodeAdded(this, node);

    // We're at the top level of the document, so any pending list nodes need to be inserted now, before we
    // open a new list item. We won't get a closeList if there's another top-level list item in this list, so we
    // need to insert the pending list nodes now.
    if (info.type.spec.isListItem && this.listStack.length === 1 && this.pendingListNodesStack.length > 0) {
      const pendingListNodes = this.pendingListNodesStack[this.pendingListNodesStack.length - 1];

      pendingListNodes.forEach(pendingListNodeInfo => {
        this.addNode(pendingListNodeInfo.type, pendingListNodeInfo.attrs, pendingListNodeInfo.content);
      });

      this.pendingListNodesStack[this.pendingListNodesStack.length - 1] = [];
    }

    return node;
  }

  openList(listItemType) {
    this.listStack.push(listItemType);
    this.inlineAttributesStack.push({});
    this.pendingListNodesIndex = -1;
    this.pendingListNodesStack.push([]);
  }

  listItemType() {
    return this.listStack[this.listStack.length - 1];
  }

  closeList() {
    this.listStack.pop();
    this.inlineAttributesStack.pop();

    const pendingListNodes = this.pendingListNodesStack.pop();

    if (this.listStack.length === 0) {
      // Only when we're back at the top-level list's level can we insert any nested list items (which will have
      // indents set appropriately)
      pendingListNodes.forEach(info => {
        this.addNode(info.type, info.attrs, info.content);
      });
    } else {
      // When traversing nested lists, the parser travels all the way into the most nested item, then starts closing
      // lists from the most nested to the least nested, so we need to re-order the pending list nodes in the stack
      // so they're in the correct least-to-most-nested traversal order
      const parentPendingListNodes = this.pendingListNodesStack[this.pendingListNodesStack.length - 1];

      // The (single) list node the children are nested under has yet to be added, so we need to know to
      // insert it in the correct location in the parent's pending list nodes.
      this.pendingListNodesIndex = parentPendingListNodes.length;

      parentPendingListNodes.push(...pendingListNodes);
    }
  }

  closeFootnoteAnchor() {
    return this.footnoteAnchorStack.pop();
  }

  getFootnoteLink(anchor) {
    return this.footnoteLinkNodeByAnchor[anchor];
  }

  openFootnoteAnchor(anchor) {
    this.footnoteAnchorStack.push(anchor);
  }

  registerFootnoteLink(anchor, node) {
    this.footnoteLinkNodeByAnchor[anchor] = node;
  }

  replaceFootnoteLink(linkNode, newNodes, parentNode = null) {
    if (parentNode === null) parentNode = this.stack[0];

    // content can either be a normal array or a Fragment
    const contentIsFragment = !Array.isArray(parentNode.content);

    const childCount = contentIsFragment ? parentNode.content.childCount : parentNode.content.length;
    for (let childIndex = 0; childIndex < childCount; childIndex++) {
      const childNode = contentIsFragment ? parentNode.content.child(childIndex) : parentNode.content[childIndex];

      // Note that we want to replace the paragraph parent of the link node, not the link node itself, unless it's not
      // wrapped in that way for some reason
      const shouldReplaceChildNode = (
        childNode.type.name === "paragraph" &&
        childNode.content.childCount === 1 &&
        childNode.content.child(0) === linkNode
      ) || childNode === linkNode;

      if (shouldReplaceChildNode) {
        const newContentFragment = Fragment.from(newNodes);

        if (contentIsFragment) {
          parentNode.content = Fragment.from(
            parentNode.content.cut(0, childIndex)
              .append(newContentFragment)
              .append(parentNode.content.cut(childIndex + 1, parentNode.content.childCount))
          );
        } else {
          parentNode.content = Fragment.from(
            parentNode.content.slice(0, childIndex)
              .append(newContentFragment)
              .append(parentNode.content.slice(childIndex + 1))
          );
        }

        return true;
      } else if (this.replaceFootnoteLink(linkNode, newNodes, childNode)) {
        return true;
      }
    }

    return false;
  }

  openFootnote() {
    this.footnoteStack.push(null)
  }

  isInFootnote() {
    return this.footnoteStack.length > 0;
  }

  closeFootnote() {
    this.footnoteStack.pop();
  }
}

// --------------------------------------------------------------------------
// Code content is represented as a single token with a `content`
// property in Markdown-it.
function noCloseToken(spec, type) {
  return spec.noCloseToken || type === "code_inline" || type === "code_block" || type === "fence";
}

// --------------------------------------------------------------------------
export function parseMarkdown(markdownWithFrontMatter) {
  let data;
  let markdown;
  try {
    ({ content: markdown, data } = parseFrontMatter(markdownWithFrontMatter));
  } catch (_error) {
    // Probably YAMLException due to something that wasn't front-matter at the beginning of the markdown, but looked
    // like it, or malformed front-matter.
    data = {};
    markdown = markdownWithFrontMatter;
  }

  // The frontmatter parser will produce a string for the front matter data if there isn't a valid YAML document, but
  // it's likely the user wanted a sort of heading if that's the case, rather than simply mis-formatting frontmatter,
  // so we'll fall back to including it in the document
  //    ---
  //    a:b
  //    ---
  if (typeof(data) === "string") {
    data = {};
    markdown = markdownWithFrontMatter;
  }

  let document = markdownParser.parse(markdown);


  if ("storage" in data) {
    const { storage } = data;

    const validStorage = {};

    SUPPORTED_NOTE_STORAGE_KEYS.forEach(key => {
      if (key in storage) validStorage[key] = storage[key];
    });

    if (Object.keys(validStorage).length > 0) {
      document = document.type.create({ ...document.attrs, storage: validStorage }, document.content);
    }
  }

  const note = {};

  if ("created" in data) {
    const created = Date.parse(data.created);
    if (!Number.isNaN(created)) note.created = Math.floor(created / 1000);
  }

  if (typeof(data.title) === "string") {
    note.name = data.title;
  }

  if (typeof(data.uuid) === "string") {
    note.remoteUUID = data.uuid;
  }

  if ("tags" in data) {
    const { tags } = data;
    if (Array.isArray(tags)) note.tags = tags.filter(tag => typeof(tag) === "string");
  }

  if ("version" in data) {
    const version = parseInt(data.version, 10);
    if (!Number.isNaN(version)) note.version = version;
  }

  return { document, note };
}

// --------------------------------------------------------------------------
function withoutTrailingNewline(str) {
  return str[str.length - 1] === "\n" ? str.slice(0, str.length - 1) : str;
}

// --------------------------------------------------------------------------
// eslint-disable-next-line no-empty-function
function noOp() {}

// --------------------------------------------------------------------------
// eslint-disable-next-line no-shadow
function tokenHandlers(schema, tokens) {
  const handlers = Object.create(null);
  // eslint-disable-next-line guard-for-in
  for (const type in tokens) {
    const spec = tokens[type];
    if (spec.block) {
      const { afterNodeAdded, beforeNodeAdded, shouldOmit } = spec;

      if (typeof(spec.block) === "function") {
        if (noCloseToken(spec, type)) {
          const { getText } = spec;

          // eslint-disable-next-line no-shadow
          handlers[type] = (state, tok, tokens, i) => {
            const nodeTypeName = spec.block(state, tok);
            if (nodeTypeName === null) return;

            const nodeType = schema.nodeType(nodeTypeName);
            const text = getText ? getText(state, tok, nodeType) : withoutTrailingNewline(tok.content);

            if (spec.beforeNodeOpened) spec.beforeNodeOpened(state);
            state.openNode(nodeType, buildAttrs(spec, tok, tokens, i));
            state.addText(text);
            state.closeNode({ afterNodeAdded, beforeNodeAdded, shouldOmit });
          };
        } else {
          // eslint-disable-next-line no-shadow
          handlers[type + "_open"] = (state, tok, tokens, i) => {
            const nodeType = schema.nodeType(spec.block(state, tok));
            return state.openNode(nodeType, buildAttrs(spec, tok, tokens, i));
          };
          handlers[type + "_close"] = state => state.closeNode({ afterNodeAdded, beforeNodeAdded, shouldOmit });
        }
      } else {
        const nodeType = schema.nodeType(spec.block);
        if (noCloseToken(spec, type)) {
          const { getText } = spec;

          // eslint-disable-next-line no-shadow
          handlers[type] = (state, tok, tokens, i) => {
            const textOrTextNodes = getText ? getText(state, tok, nodeType) : withoutTrailingNewline(tok.content);

            if (spec.beforeNodeOpened) spec.beforeNodeOpened(state);
            state.openNode(nodeType, buildAttrs(spec, tok, tokens, i));

            if (typeof(textOrTextNodes) === "string") {
              state.addText(textOrTextNodes);
            } else if (textOrTextNodes) {
              textOrTextNodes.forEach(textNode => {
                textNode.marks.forEach(mark => state.openMark(mark));

                state.addText(textNode.text);

                textNode.marks.forEach(mark => state.closeMark(mark));
              });
            }

            state.closeNode({ afterNodeAdded, beforeNodeAdded, shouldOmit });
          }
        } else {
          // eslint-disable-next-line no-shadow
          handlers[type + "_open"] = (state, tok, tokens, i) => {
            if (spec.beforeNodeOpened) spec.beforeNodeOpened(state);
            return state.openNode(nodeType, buildAttrs(spec, tok, tokens, i));
          };
          handlers[type + "_close"] = state => state.closeNode({ afterNodeAdded, beforeNodeAdded, shouldOmit });
        }
      }
    } else if (spec.node) {
      const nodeType = schema.nodeType(spec.node)
      // eslint-disable-next-line no-shadow
      handlers[type] = (state, tok, tokens, i) => state.addNode(nodeType, buildAttrs(spec, tok, tokens, i))
    } else if (spec.mark) {
      const markType = schema.marks[spec.mark];

      if (noCloseToken(spec, type)) {
        // eslint-disable-next-line no-shadow
        handlers[type] = (state, tok, tokens, i) => {
          const mark = markType.create(buildAttrs(spec, tok, tokens, i));
          state.openMark(mark);
          state.addText(withoutTrailingNewline(tok.content));
          state.closeMark(mark);
        }
      } else {
        let mark;
        // eslint-disable-next-line no-shadow
        handlers[spec.openToken || (type + "_open")] = (state, tok, tokens, i) => {
          mark = markType.create(buildAttrs(spec, tok, tokens, i));
          return state.openMark(mark);
        };
        handlers[spec.closeToken || (type + "_close")] = state => state.closeMark(mark);
      }
    } else if (spec.ignore) {
      if (noCloseToken(spec, type)) {
        handlers[type] = noOp
      } else {
        handlers[type + "_open"] = noOp
        handlers[type + "_close"] = noOp
      }
    } else if (spec.listItemType) {
      handlers[type + "_open"] = state => state.openList(spec.listItemType);
      handlers[type + "_close"] = state => state.closeList();
    } else {
      throw new RangeError("Unrecognized parsing spec " + JSON.stringify(spec))
    }
  }

  handlers.text = (state, tok) => state.addText(tok.content)
  handlers.inline = (state, tok) => state.parseTokens(tok.children)
  handlers.softbreak = handlers.softbreak || (state => state.addText("\n"))

  return handlers
}

// --------------------------------------------------------------------------
// A configuration of a Markdown parser. Such a parser uses
// [markdown-it](https://github.com/markdown-it/markdown-it) to
// tokenize a file, and then runs the custom rules it is given over
// the tokens to create a ProseMirror document tree.
class MarkdownParser {
  // Create a parser with the given configuration. You can configure
  // the markdown-it parser to parse the dialect you want, and provide
  // a description of the ProseMirror entities those tokens map to in
  // the `tokens` object, which maps token names to descriptions of
  // what to do with them. Such a description is an object, and may
  // have the following properties:
  constructor(
    // The parser's document schema.
    // eslint-disable-next-line no-shadow
    schema,
    // This parser's markdown-it tokenizer.
    tokenizer,
    // The value of the `tokens` object used to construct this
    // parser. Can be useful to copy and modify to base other parsers
    // on.
    tokens
  ) {
    this.schema = schema;
    this.tokenHandlers = tokenHandlers(schema, tokens);
    this.tokenizer = tokenizer;
  }

  // Parse a string as [CommonMark](http://commonmark.org/) markup,
  // and create a ProseMirror document as prescribed by this parser's
  // rules.
  //
  // The second argument, when given, is passed through to the
  // [Markdown
  // parser](https://markdown-it.github.io/markdown-it/#MarkdownIt.parse).
  parse(text, markdownEnv = {}) {
    const state = new MarkdownParseState(this.schema, this.tokenHandlers);
    let doc;
    state.parseTokens(this.tokenizer.parse(text, markdownEnv));
    do { doc = state.closeNode() } while (state.stack.length);
    return doc || this.schema.topNodeType.createAndFill();
  }
}

// --------------------------------------------------------------------------
// A parser parsing unextended [CommonMark](http://commonmark.org/),
// without inline HTML, and producing a document in the basic schema.
const markdownParser = new MarkdownParser(
  schema,
  // https://github.com/markdown-it/markdown-it
  markdownit("commonmark", { html: true })
    .enable("strikethrough")
    .use(markdownItFootnote)
    .use(markdownItMark)
    // The build-in GFM table support only allows inline content in table cells (per GFM spec), but we
    // want block content in tables
    .use(markdownItTable),
  {
    // Blocks
    blockquote: {
      beforeNodeAdded: (state, info) => {
        unnestBlockquotes(state, info);
      },
      block: "blockquote",
    },
    bullet_list: { listItemType: "bullet_list_item" },
    code_block: { block: "code_block", noCloseToken: true },
    fence: {
      block: "code_block",
      getAttrs: tok => ({ language: tok.info || null }),
      noCloseToken: true,
    },
    // This is the wrapper for each set of a `footnote_anchor` and `footnote_block`
    footnote: {
      block: "paragraph",
      beforeNodeOpened: state => {
        state.openFootnote();
      },
      shouldOmit: (state, { content }) => {
        state.closeFootnote();

        const anchor = state.closeFootnoteAnchor();
        const linkNode = state.getFootnoteLink(anchor);
        if (!linkNode) return false;

        // There are two ways we use a footnote:
        //    1. For the content of a Rich Footnote that otherwise can't be represented inline in markdown
        //    2. For content that can't be represented in markdown where it is located in the document - e.g.
        //       a bullet list item in a table cell
        // In the second case, we will have the label be a completely blank line, as there was no specific text that
        // was replaced - in that case we want to completely replace the link node that was going to be inserted with
        // whatever the remainder (after the blank first paragraph) of the footnote is
        if (content.length > 1 && content[0].type.name === "paragraph" && content[0].childCount === 0) {
          state.replaceFootnoteLink(linkNode, content.slice(1));
          return true;
        }

        // There are three things we want to detect and hoist to the link node:
        //  - A paragraph with a single link node with the text matching the `linkNode`. The href of that link node
        //    should be the href for our `linkNode`.
        //  - A paragraph with a single image node, that will be the image for our `linkNode`.
        //  - Any other paragraphs/nodes will form the description content for our `linkNode`.

        const descriptionContent = [];
        content.forEach((node, contentIndex) => {
          const isParagraphNode = node.type.name === "paragraph";
          if (isParagraphNode && node.childCount === 1) {
            const { firstChild } = node;

            if (firstChild.type.name === "link") {
              if (firstChild.textContent === linkNode.textContent) {
                linkNode.attrs.href = firstChild.attrs.href;
                return;
              }
            } else if (firstChild.type.name === "image") {
              if (firstChild.attrs.src) {
                linkNode.attrs.media = { url: firstChild.attrs.src }
                if (firstChild.attrs.text) linkNode.attrs.media.text = firstChild.attrs.text;
                return;
              }
            }
          }

          // In this case, there's a missing blank line so the paragraph could be a link/image + a hard break
          if (isParagraphNode && contentIndex === 0 && node.childCount > 1 && node.child(1).type.name === "hard_break") {
            const { firstChild } = node;

            let usedFirstChild = false;
            if (firstChild.type.name === "link") {
              if (firstChild.textContent === linkNode.textContent) {
                linkNode.attrs.href = firstChild.attrs.href;
                usedFirstChild = true;
              }
            } else if (firstChild.type.name === "image") {
              if (firstChild.attrs.src) {
                linkNode.attrs.media = { url: firstChild.attrs.src }
                if (firstChild.attrs.text) linkNode.attrs.media.text = firstChild.attrs.text;
                usedFirstChild = true;
              }
            }

            if (usedFirstChild) {
              if (node.childCount > 2) {
                const descriptionContentNode = node.cut(firstChild.nodeSize + node.child(1).nodeSize);
                descriptionContent.push(descriptionContentNode);
              }

              return;
            }
          }

          descriptionContent.push(node);
        });

        if (descriptionContent.length > 0) {
          const descriptionDocumentContent = { content: descriptionContent.map(node => node.toJSON()) };
          linkNode.attrs.description = valueFromDescriptionDocumentContent(descriptionDocumentContent);
        }

        // Don't leave the footnote label as the href of the link if we've ended up using it as the text of the link
        if (linkNode.textContent === linkNode.attrs.href) {
          linkNode.attrs.href = "";
        }

        return true;
      },
    },
    // This is the anchor in the footnote section; the `label` is the "^1" in "[^1]: some footnote"
    footnote_anchor: {
      block: "link",
      getAttrs: tok => {
        const { meta: { label } } = tok;
        return { href: label };
      },
      noCloseToken: true,
      shouldOmit: (state, node) => {
        state.openFootnoteAnchor(node.attrs.href);
        return true;
      },
    },
    // This is the wrapper for all footnote blocks (`footnote` parser rule, above)
    footnote_block: { ignore: true },
    // This is the link in the note itself, but it won't include proceeding text when we use the less common
    // `[some][^1]` syntax for a footnote link - it's just the `[^1]` part
    footnote_ref: {
      afterNodeAdded: (state, node) => {
        const { attrs: { href } } = node;
        if (href) state.registerFootnoteLink(href, node);
      },
      block: "link",
      getAttrs: tok => {
        const { meta: { label } } = tok;
        return { href: label };
      },
      getText: (state, tok) => {
        const { content } = state.top();

        let textOrTextNodes = null;

        // Consume the link content in the previous text node (the "some" in `[some][^1]`)
        if (content && content.length > 0) {
          const closingNode = content[content.length - 1];
          const closingBracketMatch = closingNode.type.name === "text" && closingNode.text
            ? closingNode.text.match(/(?<!\\)]$/)
            : null;
          if (closingBracketMatch) {
            // This is intentionally cached so we can modify `content` without affecting how many elements are removed
            const lastIndex = content.length - 1;

            // If there's any sort of mark applied to the text in the link, we might have a set of nodes like:
            // - text "["
            // - mark text strong("something")
            // - text "]"
            // In which case we want to consume them all
            for (let i = lastIndex; i >= 0; i--) {
              const openingNode = content[i];

              // We're assuming we only have text nodes inside the link label - other cases aren't accounted for
              if (openingNode.type.name !== "text") break;
              if (!openingNode.text) continue;

              // Once we find the opening node, we can extract everything after that node
              const openingBracketMatch = openingNode.text.match(/(?<!\\)\[/);
              if (!openingBracketMatch) continue;

              // Remove closing ]
              if (closingNode.text === "]") {
                content.splice(lastIndex, 1);
              } else {
                closingNode.text = closingNode.text.slice(0, closingNode.text.length - 1);
              }

              textOrTextNodes = content.splice(i + 1, lastIndex);

              // Remove opening [
              if (openingNode.text === "[") {
                content.splice(i, 1);
              } else if (openingBracketMatch.index === 0) {
                // e.g. "[something"
                openingNode.text = openingNode.text.slice(1);
                content.splice(i, 1);
                textOrTextNodes.unshift(openingNode);
              } else {
                // e.g. "some [thing"
                const openingText = openingNode.text.slice(openingBracketMatch.index + 1);
                textOrTextNodes.unshift({ ...openingNode, text: openingText });
                openingNode.text = openingNode.text.slice(0, openingBracketMatch.index);
              }

              break;
            }
          }
        }

        return textOrTextNodes !== null ? textOrTextNodes : withoutTrailingNewline(tok.content || tok.meta.label || "");
      },
      noCloseToken: true,
    },
    hardbreak: { node: "hard_break" },
    heading: {
      block: "heading",
      getAttrs: tok => ({ level: +tok.tag.slice(1) })
    },
    hr: { node: "horizontal_rule" },
    // These are HTML tags alone on a line, for the most part we just want to treat them as text containers
    html_block: {
      block: (state, tok) => {
        if (tok.content.match(PLUGIN_EMBED_OBJECT_PATTERN)) {
          return "embed";
        }

        // When there's a cell with just inline HTML comments followed by a cell with a link, the parser incorrectly
        // parses the inline HTML comments cell as a block with a trailing `|` character, for reasons unknown.
        if (state.stack.length && state.stack[state.stack.length - 1].type === state.schema.nodes.table_cell) {
          const jsonCommentMatch = tok.content.match(/^<!--\s+({.+})\s+-->\|$/);
          if (jsonCommentMatch) {
            addInlineAttributes(state, jsonCommentMatch[1]);
            return null; // Don't emit an empty text node
          }
        }

        return "paragraph";
      },
      getAttrs: tok => {
        const pluginEmbedMatch = tok.content.match(PLUGIN_EMBED_OBJECT_PATTERN);
        if (pluginEmbedMatch) {
          const attrs = { src: pluginEmbedMatch[1] };
          if (pluginEmbedMatch[2]) {
            const aspectRatio = parseFloat(pluginEmbedMatch[2], 10);
            if (!Number.isNaN(aspectRatio)) attrs.aspectRatio = aspectRatio;
          }
          return attrs;
        }

        // eslint-disable-next-line no-undefined
        return undefined;
      },
      noCloseToken: true,
    },
    // These are any HTML tags that show up in other content in a line, i.e. are not alone on the line
    html_inline: {
      block: (state, tok) => {
        // We want to support <br /> as a means of inserting a hard_break in locations where we can't insert a newline
        // (or escaped empty line), like inside a table cell
        if (tok.content.match(/^<br\s*\/?>$/i)) {
          return "hard_break";
        }

        const jsonCommentMatch = tok.content.match(/^<!--\s+({.+})\s+-->$/);
        if (jsonCommentMatch) {
          addInlineAttributes(state, jsonCommentMatch[1]);
          return null; // Don't emit an empty text node
        }

        // Handle inline html attributes - since we're processing as a block here, we'll get an open/close for
        // each inline tag, so we can convert those to the appropriate marks
        if (tok.content === "</mark>") {
          // Passing nothing to `create` will result in the defaultAttrs cache object being used, but we want to
          // be able to modify attrs in place when consuming inline attributes, so we need a new object.
          state.closeMark(state.schema.marks.highlight.create({}));
        } else if (tok.content.match(/^<mark(?:\s|>$)/)) {
          const attrs = {};

          const backgroundColor = cssColorFromStyle(tok.content, "background-color");
          if (/data-background-color=['"]\d+['"]/.test(tok.content)) {
            const cycleColorNumber = tok.content.match(/data-background-color=['"](\d+)['"]/)[1];
            attrs.backgroundColor = `cycle-color-${ cycleColorNumber }/${ backgroundColor }`
          } else if (backgroundColor) {
            attrs.backgroundColor = backgroundColor;
          }

          const color = cssColorFromStyle(tok.content, "color");
          if (/data-color=['"]\d+['"]/.test(tok.content)) {
            const cycleColorNumber = tok.content.match(/data-color=['"](\d+)['"]/)[1];
            attrs.color = `cycle-color-${ cycleColorNumber }/${ color }`
          } else if (color) {
            attrs.color = color;
          }

          state.openMark(state.schema.marks.highlight.create(attrs));
        }

        return "text"; // Just insert it as plain text (HTML comments will be dropped)
      },
      getText: (state, tok, nodeType) => {
        return nodeType.name === "hard_break" ? null : withoutTrailingNewline(tok.content);
      },
      noCloseToken: true,
    },
    image: {
      getAttrs: tok => {
        // eslint-disable-next-line no-shadow
        const attrs = {
          alt: tok.children[0] && tok.children[0].content || null,
          src: tok.attrGet("src"),
          title: tok.attrGet("title") || null,
        };

        const { content } = tok;
        const widthMatch = (content || "").match(/\|(\d+)(?:x\d+)?$/i);
        if (widthMatch) {
          attrs.width = parseInt(widthMatch[1], 10);
        }

        return attrs;
      },
      node: "image",
    },
    link: { block: "link", getAttrs: tok => ({ href: tok.attrGet("href") }) },
    list_item: {
      beforeNodeAdded: (state, info) => {
        if (state.isInFootnote()) {
          // In footnotes, all content is indented one extra level - we want to remove that extra indent now
          if ("indent" in (info.attrs || {})) {
            info.attrs.indent = Math.max(0, info.attrs.indent - 1);
          }
        }

        convertBulletListItemToCheckListItem(state, info);
        mergeListItemChildren(state, info);
      },
      block: (state, _tok) => state.listItemType(),
      getAttrs: tok => {
        // The "level" here is 1 + the number of spaces, but spaces are only counted in increments of 2 and can only
        // be 2 spaces more than the previous list item, where the first list item in a set of list items defines the
        // base level of 1 (i.e. if it's indented 4 spaces then anything <= 4 spaces is level 1, while anything >= 6
        // spaces can increase the level relative the the previous list item).
        const indent = Math.max(0, Math.ceil((tok.level - 1) / 2));
        return { indent };
      }
    },
    ordered_list: { listItemType: "number_list_item" },
    paragraph: { block: "paragraph" },
    softbreak: { node: "hard_break" },
    table: { block: "table" },
    tbody: { ignore: true },
    td: { block: "table_cell" },
    tr: {
      block: "table_row",
      shouldOmit: (state, { content }) => {
        // Markdown tables require a header, but we don't care to include a blank header row in the tables
        // we parse, so we'll omit the first row of a table if it's empty
        if (state.top().content.length === 0) {
          const isEmpty = content.every(({ content: cellContent }) => {
            if (cellContent.childCount !== 1) return false;

            const { content: paragraphContent } = cellContent.firstChild;
            return paragraphContent.size === 0;
          });

          if (isEmpty) return true;
        }

        return false;
      }
    },
    th: { block: "table_cell" },
    thead: { ignore: true },

    // Marks
    code_inline: { mark: "code", noCloseToken: true },
    em: { mark: "em" },
    mark: { mark: "highlight" },
    strikethrough: { mark: "strikethrough", openToken: "s_open", closeToken: "s_close" },
    strong: { mark: "strong" },
  }
);
export default markdownParser;

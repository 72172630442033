import { keyBy, upperFirst } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import {
  ListDivider,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText
} from "@rmwc/list"
import Tippy from "@tippyjs/react"

import TagIcon from "lib/ample-editor/components/tag-icon"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import { SEARCH_HEADINGS_CHARACTER } from "lib/ample-editor/lib/parse-link-target-text"
import { isNoteURL, noteParamsFromURL } from "lib/ample-util/note-url"
import { noteUUIDFromNoteParams } from "lib/ample-util/note-uuid"

// --------------------------------------------------------------------------
export function headingNoteURLFromNoteSuggestion(noteSuggestion, searchText) {
  if (noteSuggestion) {
    const { headingNoteURL, url } = noteSuggestion;
    if (headingNoteURL) {
      // The URL of the option may differ from the note that we want to get heading suggestions for
      return headingNoteURL;
    } else if (url) {
      return url;
    }

    return null;
  }

  if (searchText && searchText.startsWith(SEARCH_HEADINGS_CHARACTER)) {
    // Searching for headings in current note e.g. using "#blah" as the full search text
    return "#";
  }

  const [ noteSearchTerm, headingSearchTerm ] = (searchText || "").split(SEARCH_HEADINGS_CHARACTER, 2);
  if (typeof(headingSearchTerm) !== "undefined" && isNoteURL(noteSearchTerm)) {
    // Searching a specific note by typing the URL + "#"
    return noteSearchTerm;
  }

  return null;
}

// --------------------------------------------------------------------------
export function isNewNoteSuggestion(noteSuggestion) {
  return !noteSuggestion.url && !noteSuggestion.isUnpersistedDailyNote;
}

// --------------------------------------------------------------------------
export function keyFromNoteSuggestion(noteSuggestion) {
  const { isUnpersistedDailyNote, name, url } = noteSuggestion;

  return url || `new-note-suggestion-${ isUnpersistedDailyNote ? "unpersisted-daily-note-" : "" }${ name }`;
}

// --------------------------------------------------------------------------
export function newTagTextsFromNoteSuggestion(noteSuggestion) {
  let { newTags, isUnpersistedDailyNote } = noteSuggestion;

  if (isUnpersistedDailyNote && noteSuggestion.tags) {
    newTags = newTags.concat(noteSuggestion.tags);
  }

  return newTags.map(({ text }) => text);
}

// --------------------------------------------------------------------------
export function noteUUIDFromNoteSuggestion(noteSuggestion) {
  if (!noteSuggestion) return null;

  const { url } = noteSuggestion;
  if (!url) return null;

  const noteParams = noteParamsFromURL(url)
  return noteParams ? noteUUIDFromNoteParams(noteParams) : null;
}

// --------------------------------------------------------------------------
function Tags({ matchingFilterTags, newTags, tags }) {
  if (matchingFilterTags.length === 0 && newTags.length === 0 && tags.length === 0) return null;

  const existingTagByText = keyBy(tags, "text");

  const matchingFilterTagsContent = (matchingFilterTags || []).map(({ text, ...tagMetadata }) => {
    delete existingTagByText[text];

    return (
      <div className="tag existing" key={ text }>
        <TagIcon { ...tagMetadata } />
        { text }
      </div>
    );
  });

  const newTagsContent = (newTags || []).map(({ canBeOmitted, match, text, ...tagMetadata }) => {
    delete existingTagByText[text];

    const newTagContent = (
      <div className="tag" key={ text }>
        <strong>+</strong><TagIcon { ...tagMetadata } />
        { match ? <span dangerouslySetInnerHTML={ { __html: match } }/> : text }
      </div>
    );

    if (canBeOmitted) {
      return (
        <Tippy
          content="Add ~ to the beginning of the link text to omit this tag"
          delay={ 200 }
          key={ text }
          placement="bottom"
          touch={ false }
        >
          { newTagContent }
        </Tippy>
      );
    } else {
      return newTagContent;
    }
  });

  const existingTagsContent = Object.values(existingTagByText).map(({ text, ...tagMetadata }) => (
    <div className="tag existing" key={ text }>
      <TagIcon { ...tagMetadata } />
      { text }
    </div>
  ));

  return (
    <ListItemSecondaryText>
      { newTagsContent }
      { matchingFilterTagsContent }
      { existingTagsContent }
    </ListItemSecondaryText>
  );
}

// --------------------------------------------------------------------------
export function NoteSuggestion(props) {
  const {
    acceptNoteSuggestion,
    actionDescription,
    isActiveSuggestion,
    noteSuggestion,
  } = props;

  const { html, matchingFilterTags, name, newTags, tags } = noteSuggestion;

  const isExistingNote = !isNewNoteSuggestion(noteSuggestion);

  let icon;
  let text;
  if (isExistingNote) {
    icon = noteSuggestion.icon || "description";
    text = noteSuggestion.text || (html ? <span dangerouslySetInnerHTML={ { __html: html } } /> : name);
  } else {
    icon = "create";
    text = noteSuggestion.text || (
      <span>
        { upperFirst(actionDescription || "link") } to a new note named <span className="value">{ name }</span>
      </span>
    );
  }

  const { shares } = newTags.find(newTag => newTag.shares) || {};

  let sharedTagsWarning = null;
  if (shares) {
    sharedTagsWarning = (
      <ListItemSecondaryText className="shared-tag-warning">
        <i className="material-icons">error</i>
        <span className="text">
            Applying this tag will share the note with { shares } user{ shares === 1 ? "" : "s" }
          </span>
      </ListItemSecondaryText>
    )
  }

  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      acceptNoteSuggestion(noteSuggestion);
    },
    [ noteSuggestion ]
  );

  let className = "link-target-menu-item popup-list-item";
  if (sharedTagsWarning) className += " tall";

  return (
    <ListItem
      className={ className }
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
      selected={ isActiveSuggestion }
      tabIndex="-1"
    >
      <ListItemGraphic icon={ icon } />
      <ListItemText>
        <ListItemPrimaryText>
          { text }
        </ListItemPrimaryText>

        <Tags
          matchingFilterTags={ matchingFilterTags }
          newTags={ newTags }
          tags={ tags || [] }
        />

        { sharedTagsWarning }
      </ListItemText>
      { isActiveSuggestion ? (<ListItemMeta icon="keyboard_return" />) : null }
    </ListItem>
  );
}

NoteSuggestion.propTypes = {
  acceptNoteSuggestion: PropTypes.func.isRequired,
  actionDescription: PropTypes.string,
  isActiveSuggestion: PropTypes.bool,
  noteSuggestion: PropTypes.object.isRequired,
};

// --------------------------------------------------------------------------
export default function NoteSuggestions(props) {
  const {
    acceptNoteSuggestion,
    actionDescription,
    activeNoteSuggestion,
    noteSuggestions,
  } = props;

  const renderedSuggestions = [];

  noteSuggestions.forEach((noteSuggestion, index) => {
    renderedSuggestions.push(
      <NoteSuggestion
        acceptNoteSuggestion={ acceptNoteSuggestion }
        actionDescription={ actionDescription }
        isActiveSuggestion={ noteSuggestion === activeNoteSuggestion }
        key={ keyFromNoteSuggestion(noteSuggestion) }
        noteSuggestion={ noteSuggestion }
      />
    );

    if (index === 0 && noteSuggestions.length > 1) {
      renderedSuggestions.push(<ListDivider key="divider"/>);
    }
  });

  return renderedSuggestions;
}

NoteSuggestions.propTypes = {
  acceptNoteSuggestion: PropTypes.func.isRequired,
  actionDescription: PropTypes.string,
  activeNoteSuggestion: PropTypes.object,
  noteSuggestions: PropTypes.array.isRequired,
};

import { Node } from "prosemirror-model"
import { insertPoint } from "prosemirror-transform"

import { uncompletedTaskExists } from "lib/ample-editor/lib/task-commands"
import RemoveCompletedTaskStep from "lib/ample-editor/steps/remove-completed-task-step"
import { checkListItemFromCompletedTask, derivedUUIDFromUUID } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export function buildRestoreCompletedTask(uuid) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const completedTasks = doc.attrs.completedTasks;
    const completedTask = completedTasks.find(({ uuid: completedTaskUUID }) => completedTaskUUID === uuid);
    if (!completedTask) return false;

    if (dispatch) {
      const checkListItem = checkListItemFromCompletedTask(completedTask);
      delete checkListItem.attrs.completedAt;
      delete checkListItem.attrs.crossedOutAt;
      delete checkListItem.attrs.dismissedAt;

      // Make sure points don't get added immediately, even if a really old item is restored
      checkListItem.attrs.pointsUpdatedAt = Math.floor(Date.now() / 1000);

      const transform = state.tr;

      transform.step(new RemoveCompletedTaskStep(schema, completedTask));

      if (completedTask.crossedOut) {
        removeCrossedOutBulletListItem(schema, transform, completedTask.uuid);
      }

      if (shouldRestoreTask(doc, checkListItem.attrs)) {
        const checkListItemNode = Node.fromJSON(schema, checkListItem);
        const insertPos = insertPoint(transform.doc, transform.doc.content.size, checkListItemNode.type) || 0;
        transform.insert(insertPos, checkListItemNode);
      }

      dispatch(transform);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
function removeCrossedOutBulletListItem(schema, transform, taskUUID) {
  const { doc } = transform;

  let nodePos = 0;
  for (let childIndex = 0; childIndex < doc.childCount; childIndex++) {
    const node = doc.child(childIndex);

    if (node.type === schema.nodes.bullet_list_item && node.attrs.uuid === taskUUID) {
      // Note that we're not actually checking that the content is crossed out (strikethrough mark), as it's assumed
      // that's the case due to the matching UUIDs, and would otherwise be problematic to restore a check list item
      // with the same UUID.
      transform.delete(nodePos, nodePos + node.nodeSize);

      // We're not using the transform's position mapping, so we don't want to try to remove multiple nodes in some
      // unforeseen circumstance
      return;
    }

    nodePos += node.nodeSize;
  }
}

// --------------------------------------------------------------------------
function shouldRestoreTask(doc, taskAttributes) {
  const { repeat, uuid } = taskAttributes;
  if (!repeat) return true;

  // When a repeating task is completed, the next instance's UUID is derived from the original task's UUID, which is
  // what the completed task has
  const derivedUUID = derivedUUIDFromUUID(uuid);

  return !uncompletedTaskExists(doc, derivedUUID);
}

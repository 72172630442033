import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

import RemoveHiddenTaskStep from "./remove-hidden-task-step"

// --------------------------------------------------------------------------
export default class AddHiddenTaskStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    return new AddHiddenTaskStep(schema, json.task);
  }

  // --------------------------------------------------------------------------
  constructor(schema, task) {
    super();

    this.schema = schema;
    this.task = task;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const serializedDoc = doc.toJSON();

    const hiddenTasks = (serializedDoc.attrs.hiddenTasks || []).concat(this.task);
    serializedDoc.attrs = { ...serializedDoc.attrs, hiddenTasks };

    doc = Node.fromJSON(this.schema, serializedDoc);

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    return new RemoveHiddenTaskStep(this.schema, this.task);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      stepType: "addHiddenTask",
      task: this.task,
    };
  }
}
Step.jsonID("addHiddenTask", AddHiddenTaskStep);

import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

// --------------------------------------------------------------------------
export default class SetDocumentAttrsStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    if (json.attrs !== null && typeof json.attrs !== "object") {
      throw new RangeError("Invalid input for SetDocumentAttrsStep.fromJSON");
    }

    return new SetDocumentAttrsStep(schema, json.attrs);
  }

  // --------------------------------------------------------------------------
  constructor(schema, attrs) {
    super();

    this.attrs = attrs;
    this.schema = schema;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const serializedDoc = doc.toJSON();
    serializedDoc.attrs = { ...this.attrs };

    doc = Node.fromJSON(this.schema, serializedDoc);

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert(doc) {
    return new SetDocumentAttrsStep(this.schema, doc.attrs);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return new SetDocumentAttrsStep(this.schema, this.attrs);
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return { stepType: "setDocumentAttrs", attrs: this.attrs };
  }
}
Step.jsonID("setDocumentAttrs", SetDocumentAttrsStep);

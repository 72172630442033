import PropTypes from "prop-types"
import React from "react"
import tippy from "tippy.js"

import { deviceSupportsHover } from "../lib/client-info"

// --------------------------------------------------------------------------
// Optimizes the tooltipped icons by ensuring they never re-render when the
// toolbar changes, since the toolbar re-renders on selection and document change.
export default class TooltipIcon extends React.Component {
  // --------------------------------------------------------------------------
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.any.isRequired,
    tooltip: PropTypes.string.isRequired,
  };

  _elementRef = React.createRef();
  _tooltip = null;

  // --------------------------------------------------------------------------
  componentDidMount() {
    if (deviceSupportsHover) {
      const { tooltip } = this.props;

      this._tooltip = tippy(this._elementRef.current, {
        animation: "shift-away-subtle",
        allowHTML: true,
        content: tooltip,
        delay: [ 500, 150 ],
        ignoreAttributes: true,
        placement: "bottom",
        touch: false,
      });
    }
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    if (this._tooltip) {
      this._tooltip.destroy();
      this._tooltip = null;
    }
  }

  // --------------------------------------------------------------------------
  render() {
    const { icon, className } = this.props;

    return (
      <i className={ `material-icons ${ className || "" }` } ref={ this._elementRef }>{ icon }</i>
    );
  }

  // --------------------------------------------------------------------------
  shouldComponentUpdate() {
    return false;
  }
}

import { getOpenLinkPos, setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"

// --------------------------------------------------------------------------
export default function closePopups(editorView) {
  const { state } = editorView;

  const openLinkPos = getOpenLinkPos(state);
  if (openLinkPos !== null) {
    editorView.dispatch(setOpenLinkPosition(state.tr, null));
  }

  [
    "closeDateSuggestionsMenu",
    "closeExpressionMenu",
    "closeListItemCommandsMenu",
    "closeNoteLinkMenu",
  ].forEach(closePropName => {
    editorView.someProp(closePropName, close => {
      close();
      return true;
    });
  });
}

import memoize from "memoize-one"
import PropTypes from "prop-types"
import React from "react"
import Tippy from "@tippyjs/react"

import TextInputWithSuggestions from "lib/ample-editor/components/task-detail/text-input-with-suggestions"
import { durationFromTimeText, timeSuggestionsFromText } from "lib/ample-editor/components/task-detail/util"
import { dayPartFromText } from "lib/ample-editor/lib/parse-date-text"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import { DAY_PARTS, dayPartFromDuration, dayPartTextFromDayPart, timeTextFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export default class FuzzyTimeInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    duration: PropTypes.string.isRequired,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    setDayPart: PropTypes.func,
    setDuration: PropTypes.func.isRequired,
  };

  state = {
    dayPartInputDayPart: null,
    dayPartInputText: null,
    dayPartSuggestions: [],
    timeInputDuration: null,
    timeInputText: null,
    timeSuggestions: [],
  };

  _suppressBlurUpdate = false;

  // --------------------------------------------------------------------------
  render() {
    const { className, setDayPart } = this.props;

    return (
      <div className={ `fuzzy-time-input time-input ${ className }` }>
        <div className="text-input-container">
          { setDayPart ? this._renderDayPartInput() : null }
          { this._renderTimeInput() }
          { this._renderIcon() }
        </div>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _memoizedDayPartSuggestions = memoize((dayPartInputText, selectedDayPart) => {
    return Object.keys(DAY_PARTS).map(dayPart => {
      const selected = dayPart === selectedDayPart;
      const { name } = DAY_PARTS[dayPart];
      return { selected, text: name, value: dayPart };
    });
  });

  // --------------------------------------------------------------------------
  _memoizedTimeSuggestions = memoize((timeInputText, selectedDuration) => {
    return timeSuggestionsFromText(timeInputText).map(({ duration, text }) => {
      // Allow the duration from props to include non-time segments by only matching against the end
      const durationFragment = duration.substr(1);

      const selected = selectedDuration.endsWith(durationFragment);

      return { selected, text, value: duration };
    });
  });

  // --------------------------------------------------------------------------
  _onDayPartInputBlur = () => {
    const { dayPartInputDayPart } = this.state;
    this._setDayPart(dayPartInputDayPart);

    this.setState({ dayPartInputDayPart: null, dayPartInputText: null });
  };

  // --------------------------------------------------------------------------
  _onTimeInputBlur = () => {
    if (this._suppressBlurUpdate) {
      this._suppressBlurUpdate = false;
      return;
    }

    const { timeInputDuration } = this.state;
    this._setDuration(timeInputDuration);

    this.setState({ timeInputDuration: null, timeInputText: null });
  };

  // --------------------------------------------------------------------------
  _onTimeInputFocus = event => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(event);

    // This is the default behavior when focusing via the keyboard, but we want it to happen when focusing via a mouse
    // click as well
    event.target.select();
  };

  // --------------------------------------------------------------------------
  _renderDayPartInput() {
    const { dayPart, disabled, duration, onFocus, onKeyDown } = this.props;
    const { dayPartInputDayPart, timeInputDuration } = this.state;

    let { dayPartInputText } = this.state;

    let effectiveDayPart;

    if (dayPartInputText !== null) {
      effectiveDayPart = dayPartInputDayPart;
    } else {
      effectiveDayPart = dayPart || dayPartFromDuration(timeInputDuration || duration);
      dayPartInputText = effectiveDayPart ? dayPartTextFromDayPart(effectiveDayPart) : "";
    }

    const dayPartSuggestions = this._memoizedDayPartSuggestions(dayPartInputText || "", effectiveDayPart);

    return (
      <TextInputWithSuggestions
        additionalClassName={ `day-part ${ dayPart ? "" : "derived-value" }` }
        disabled={ disabled }
        invalid={ !effectiveDayPart }
        onBlur={ this._onDayPartInputBlur }
        onFocus={ onFocus }
        onKeyDown={ onKeyDown }
        selectSuggestion={ this._selectDayPartSuggestion }
        setValue={ this._setDayPartInputText }
        suggestions={ dayPartSuggestions }
        value={ dayPartInputText || "" }
      />
    );
  }

  // --------------------------------------------------------------------------
  _renderIcon() {
    const { dayPart, duration } = this.props;

    let className = "time-type-icon";
    if (dayPart) className += " derived-value";

    let d;
    let tooltip;
    if (!duration) {
      className += " unset";
      d = VECTOR_ICON_PATHS["clock-check-outline"];
      tooltip = "Select a time.";
    } else if (dayPart) {
      d = VECTOR_ICON_PATHS["progress-clock"];
      tooltip = "Time may shift within the selected range.";
    } else {
      d = VECTOR_ICON_PATHS["clock-check-outline"];
      tooltip = "Time has been manually selected.";
    }

    return (
      <Tippy content={ tooltip }>
        <svg className={ className } tabIndex="-1" viewBox="0 0 24 24">
          <path d={ d } fill="#b4bfcc" />
        </svg>
      </Tippy>
    );
  }

  // --------------------------------------------------------------------------
  _renderTimeInput() {
    const { dayPart, disabled, duration, onKeyDown } = this.props;
    const { timeInputDuration } = this.state;

    let { timeInputText } = this.state;

    const effectiveDuration = timeInputText !== null ? timeInputDuration : duration;

    if (timeInputText === null && duration) {
      timeInputText = timeTextFromDuration(duration);
    }

    const selectedDuration = this.state.timeInputDuration || this.props.duration || "";
    const timeSuggestions = this._memoizedTimeSuggestions(timeInputText || "", selectedDuration);

    return (
      <TextInputWithSuggestions
        additionalClassName={ `time ${ dayPart ? "derived-value" : "" }` }
        disabled={ disabled }
        invalid={ !(effectiveDuration && effectiveDuration.includes("T")) }
        onBlur={ this._onTimeInputBlur }
        onFocus={ this._onTimeInputFocus }
        onKeyDown={ onKeyDown }
        selectSuggestion={ this._selectTimeSuggestion }
        setValue={ this._setTimeInputText }
        suggestions={ timeSuggestions }
        value={ timeInputText || "" }
      />
    );
  }

  // --------------------------------------------------------------------------
  _selectDayPartSuggestion = ({ text: dayPartInputText, value: dayPartInputDayPart }) => {
    this.setState({ dayPartInputDayPart, dayPartInputText });
    this._setDayPart(dayPartInputDayPart);
  };

  // --------------------------------------------------------------------------
  _selectTimeSuggestion = ({ text: timeInputText, value: timeInputDuration }) => {
    this.setState({ timeInputDuration, timeInputText });
    this._setDuration(timeInputDuration);

    // Clicking to select an option can blur the input, but the state update may not have completed by that point, so
    // if the user has typed in some text - e.g. "11:30" that will be used instead of whatever option then actually
    // clicked on.
    this._suppressBlurUpdate = true;
  };

  // --------------------------------------------------------------------------
  _setDayPart = dayPart => {
    if (dayPart === null) return;

    if (dayPart !== this.props.dayPart) {
      this.props.setDayPart(dayPart);
    }
  };

  // --------------------------------------------------------------------------
  _setDayPartInputText = dayPartInputText => {
    const dayPartInputDayPart = dayPartFromText(dayPartInputText);
    this.setState({ dayPartInputDayPart, dayPartInputText });
  };

  // --------------------------------------------------------------------------
  // We don't want to be constantly updating the value as the user types in the
  // field, since "1", "11", "11:1", "11:11", and "11:11pm" are all valid,
  // so we'd be setting the value for each one of those, instead of just waiting
  // until they are done to update the value
  _setDuration = duration => {
    if (duration === null) return; // No change

    if (duration !== this.props.duration) {
      this.props.setDuration(duration);
    }
  };

  // --------------------------------------------------------------------------
  _setTimeInputText = timeInputText => {
    const timeInputDuration = durationFromTimeText(timeInputText);
    this.setState({ timeInputDuration, timeInputText });
  };
}

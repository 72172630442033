import { closeHistory } from "prosemirror-history"
import { NodeRange } from "prosemirror-model"
import { Plugin, PluginKey } from "prosemirror-state"
import { findWrapping } from "prosemirror-transform"
import { Decoration, DecorationSet } from "prosemirror-view"
import { v4 as uuidv4 } from "uuid"

import { buildInsertTableOfContents } from "lib/ample-editor/commands/table-of-content-commands"
import { TABLE_OF_CONTENTS_EXPRESSION } from "lib/ample-editor/components/expression-menu"
import { isDescriptionSchema } from "lib/ample-editor/components/rich-footnote/description-schema"
import evaluateExpression, { EXPRESSION_PATTERN } from "lib/ample-util/evaluate-expression"
import sliceFromText from "lib/ample-editor/lib/slice-from-text"
import ExpressionPluginView from "lib/ample-editor/views/expression-plugin-view"
import buildEditorContext from "lib/ample-editor/util/build-editor-context"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"
import resolvePluginActionPromises from "lib/ample-util/resolve-plugin-action-promises"

// --------------------------------------------------------------------------
function buildReplaceExpressionTransform(state, expressionText, start, end) {
  const { result, wrapInLink } = evaluateExpression(expressionText);
  if (result === null) return null;

  const transform = state.tr;

  const resultText = result.toString();

  // Replace entire match with plain-text content
  transform.insertText(
    resultText,
    transform.mapping.map(start),
    transform.mapping.map(end)
  );

  const { schema } = state;

  if (wrapInLink && !isDescriptionSchema(schema)) {
    // Wrap inserted text with a link node
    const $linkStart = transform.doc.resolve(transform.mapping.map(start));
    const $linkEnd = transform.doc.resolve(transform.mapping.map(end));
    const range = new NodeRange($linkStart, $linkEnd, $linkStart.depth);

    const linkAttrs = { description: expressionText };
    const wrapping = findWrapping(range, schema.nodes.link, linkAttrs);
    if (wrapping) transform.wrap(range, wrapping);
  }

  return transform;
}

// --------------------------------------------------------------------------
export default function createExpressionPlugin({
  disableExpressionMenu = false,
  tableOfContentsEnabled = false,
} = {}) {
  const pluginKey = new PluginKey("expression-menu");

  let lastInsertTextPluginActions = null;
  let pluginView = null;

  // We cache the result for use in the final replacement, since the user needs to type something - and thus see
  // the expression menu, which calls this - before they can perform a replacement (i.e. you can't paste `{1+2}` and
  // have it evaluated.
  const getInsertTextPluginActions = async editorView => {
    const { props: { hostApp: { getPluginActions } } } = editorView;

    let pluginActionPromises = null;
    if (getPluginActions) {
      const editorContext = buildEditorContext(editorView);
      pluginActionPromises = await getPluginActions([ PLUGIN_ACTION_TYPE.INSERT_TEXT ], editorContext);
    }

    lastInsertTextPluginActions = null;

    // Note that we're resolving these for use here, while they are resolved individually by the caller
    resolvePluginActionPromises(pluginActionPromises, {
      setPluginActions: pluginActions => { lastInsertTextPluginActions = pluginActions; },
    });

    return pluginActionPromises;
  };

  const hasPendingReplacementAtPos = (editorView, pos) => {
    const pluginState = pluginKey.getState(editorView.state);
    const decorations = pluginState ? pluginState.find(pos, pos) : [];
    return decorations.length > 0;
  };

  // This is matches what prosemirror-inputrules does, but provides a different path for undo that is more suitable,
  // and consolidates handling of expressions into this plugin.
  const replaceExpressions = (editorView, from, to, text) => {
    if (editorView.composing) return false

    const { state } = editorView;
    const $from = state.doc.resolve(from);
    if ($from.parent.type.spec.code) return false;

    // Only go 500 character back, at most (matching prosemirror-inputrules behavior)
    const textBefore = $from.parent.textBetween(Math.max(0, $from.parentOffset - 500), $from.parentOffset, null, "\ufffc") + text;

    const regex = new RegExp(EXPRESSION_PATTERN);
    const match = regex.exec(textBefore);
    if (!match) return false;

    // We want to do this in two transactions: the first that inserts the actual text and closing brace, and the
    // second that performs the replacement, so undo returns us to the un-replaced state.
    // Even if we don't have a valid expression, we'll still insert the close brace and content.
    const insertTextTransform = closeHistory(state.tr.insertText(text, from, to));
    editorView.dispatch(insertTextTransform);

    const start = insertTextTransform.mapping.map(from - (match[0].length - text.length));
    const end = insertTextTransform.mapping.map(to);

    const expressionText = match[1].trim();

    const insertTextPluginAction = (lastInsertTextPluginActions || []).find(({ checkResult, name }) => {
      const trigger = typeof(checkResult) === "string" ? checkResult : name;
      return trigger === expressionText;
    });

    let replaceExpressionTransform;
    if (insertTextPluginAction) {
      const decorationUUID = uuidv4();

      replaceExpressionTransform = editorView.state.tr;
      replaceExpressionTransform.setMeta(pluginKey, { add: { end, start, uuid: decorationUUID } });

      // We use the decoration to keep track of where the expression is, as the document may change while we're
      // waiting
      const getDecoration = () => {
        const pluginState = pluginKey.getState(editorView.state);
        const decorations = pluginState ? pluginState.find(null, null, ({ uuid }) => uuid === decorationUUID) : null;
        return decorations ? decorations[0] : null;
      };

      const { run } = insertTextPluginAction;
      const editorContext = buildEditorContext(editorView, getDecoration);

      run(editorContext).then(resultText => {
        const decoration = getDecoration();
        if (!decoration) return;

        const slice = sliceFromText(state.schema, resultText);

        // We use the decoration to keep track of where the expression is, as the document may change while we're
        // waiting
        const transform = editorView.state.tr.replaceRange(decoration.from, decoration.to, slice);
        transform.setMeta(pluginKey, { remove: decorationUUID });
        editorView.dispatch(closeHistory(transform));
      }).catch(_error => {
        const transform = editorView.state.tr.setMeta(pluginKey, { remove: decorationUUID });
        editorView.dispatch(closeHistory(transform));
      });
    } else if (tableOfContentsEnabled && expressionText.toLowerCase() === TABLE_OF_CONTENTS_EXPRESSION) {
      buildInsertTableOfContents(start, end)(editorView.state, transaction => {
        replaceExpressionTransform = transaction;
      });
    } else {
      replaceExpressionTransform = buildReplaceExpressionTransform(editorView.state, expressionText, start, end);
    }

    if (replaceExpressionTransform) {
      editorView.dispatch(closeHistory(replaceExpressionTransform));
    }

    return true;
  }

  return new Plugin({
    key: pluginKey,

    props: {
      closeExpressionMenu: () => {
        if (pluginView) pluginView.dismissMenu();
      },

      decorations: state => {
        return pluginKey.getState(state) || DecorationSet.empty;
      },

      handleDOMEvents: {
        // Matching prosemirror-input implementation
        compositionend: editorView => {
          setTimeout(() => {
            const { state: { selection: { $cursor } } } = editorView;
            if ($cursor) replaceExpressions(editorView, $cursor.pos, $cursor.pos, "");
          });
        }
      },

      handleKeyDown: (editorView, event) => {
        return pluginView ? pluginView.handleKeyDown(editorView, event) : false;
      },

      handleTextInput: (view, from, to, text) => {
        return replaceExpressions(view, from, to, text);
      },

      isExpressionMenuOpen: () => {
        return pluginView ? pluginView.isMenuOpen() : false;
      },

      // For testing, without needing to have the plugin view to call this
      refreshInsertTextPluginActions: editorView => {
        return getInsertTextPluginActions(editorView);
      },
    },

    state: {
      init: () => DecorationSet.empty,
      apply: (tr, pluginState, _lastState, _state) => {
        const meta = tr.getMeta(pluginKey);
        if (meta) {
          const { add, remove } = meta;
          if (add) {
            const { end, start, uuid } = add;
            const decoration = Decoration.inline(start, end, { class: "pending-text-replacement" }, { uuid });
            return pluginState.add(tr.doc, [ decoration ]);
          } else if (remove) {
            const decorations = pluginState.find(null, null, ({ uuid }) => uuid === remove);
            return pluginState.remove(decorations);
          }
        }

        if (tr.docChanged) {
          return pluginState.map(tr.mapping, tr.doc);
        } else {
          return pluginState;
        }
      },
    },

    view: disableExpressionMenu ? null : editorView => {
      pluginView = new ExpressionPluginView(
        editorView,
        getInsertTextPluginActions,
        pos => hasPendingReplacementAtPos(editorView, pos),
        tableOfContentsEnabled,
      );
      return pluginView;
    },
  });
}

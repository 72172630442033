import { pick } from "lodash"
import { TextSelection } from "prosemirror-state"

import { isPosHidden } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
// The `joinForward` command provides most of the desired "delete" behavior, but only accounts for paragraphs
// being joined at the same depth. We may have a paragraph nested in a list with a top-level paragraph after it (or
// vice versa) in which case the behavior we actually want is to join the two paragraphs together, regardless of whether
// their depth matches.
export function buildDeleteParagraphForward(paragraphType) {
  return function(state, dispatch) {
    const { doc, selection: { $cursor, empty } } = state;
    if (!$cursor || !empty) return false;

    const paragraph = $cursor.parent;
    if (paragraph.type !== paragraphType) return false;

    // Make sure we're at the end of the paragraph's content
    const paragraphEndPos = $cursor.end($cursor.depth);
    if ($cursor.pos !== paragraphEndPos) return false;

    // There's a special case when a top-level paragraph is empty - we can just delete it and move on
    if (paragraph.nodeSize === 2 && $cursor.depth <= 1) {
      // ...unless we're at the end of the document (1 to close doc, 1 to close paragraph = paragraphEndPos)
      if (paragraphEndPos === doc.content.size - 2) return false;

      if (dispatch) {
        dispatch(state.tr.delete($cursor.start($cursor.depth) - 1, paragraphEndPos + 1));
      }

      return true;
    }

    const afterParagraphBlockPos = $cursor.after(1);
    const nextParagraphPos = findNextParagraphPos(paragraphType, state, paragraph, afterParagraphBlockPos);
    if (!nextParagraphPos) return false;

    if (dispatch) {
      const { schema } = state;

      const transaction = state.tr;

      const $nextParagraphPos = doc.resolve(nextParagraphPos);
      const $topParent = doc.resolve($nextParagraphPos.before(1));

      // `isolating` in node type is interpreted to mean "I don't join to other blocks", a la table cell
      if ($topParent.nodeAfter.type.spec.isolating) return false;

      if ($nextParagraphPos.depth >= 1) {
        transaction.deleteRange($nextParagraphPos.before(1), $nextParagraphPos.after(1));
      } else {
        transaction.delete($nextParagraphPos.pos, $nextParagraphPos.pos + $nextParagraphPos.nodeAfter.nodeSize);
      }

      const nextParagraph = $nextParagraphPos.nodeAfter;
      if (nextParagraph && nextParagraph.nodeSize > 2) {
        transaction.insert($cursor.pos, nextParagraph.content);

        // Retain the current selection position, since we're only modifying what comes _after_ this and the insert
        // will have just moved the cursor to the end of the inserted content
        transaction.setSelection(new TextSelection(transaction.doc.resolve($cursor.pos)));
      }

      // If the paragraph we're moving was in a check list item, removing it will remove the check list item, but we want
      // to transfer any attributes on that check list item to the check list item we're moving the content into.
      const checkListItemNodeType = schema.nodes.check_list_item;
      const nextCheckListItem = $nextParagraphPos.parent;
      if (nextCheckListItem && nextCheckListItem.type === checkListItemNodeType) {
        const checkListItem = $cursor.node($cursor.depth - 1);
        if (checkListItem && checkListItem.type === checkListItemNodeType) {
          const checkListItemAttributeNames = Object.keys(checkListItemNodeType.spec.attrs);
          transaction.setNodeMarkup(
            $cursor.before($cursor.depth - 1),
            null,
            {
              ...checkListItem.attrs,
              ...pick(nextCheckListItem.attrs, checkListItemAttributeNames)
            }
          );
        }
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
// Look through nodes after `startPos` in search of the first non-collapsed block node. If that subsequent block
// node is a paragraph or contains one a return value is non-null
// `startPos` first position of an element after startNode
// Returns position of a paragraph within the first non-collapsed block >= startPos (technically, the position where we will insert the paragraph)
function findNextParagraphPos(paragraphType, state, startNode, startPos) {
  const { doc } = state;
  let result = null;
  let foundNextBlock = false;

  doc.nodesBetween(startPos, doc.nodeSize - 3, (node, pos) => {
    // Already found/scanned the subsequent node for paragraphs, now just running out the clock
    if (result || foundNextBlock) return false;

    // Don't bother looking at the start paragraph, or the parent node if this is the last paragraph in the parent -
    // in both cases those would be the first paragraph found from the start position.
    if (node === startNode || node.lastChild === startNode) return false;
    if (isPosHidden(state, pos)) return false;

    // Even if this block isn't one we can use, we have to record that it's the one & only contiguous non-hidden block
    foundNextBlock = true;
    if (node.type === paragraphType) {
      result = pos;
    } else {
      node.descendants((child, posInParent) => {
        if (!result && child.type === paragraphType) {
          result = pos + posInParent + 1; // 1 for the space taken by opening of parent element
          return false;
        }
      });
    }

    return false;
  });

  return result;
}

/* eslint-disable no-unused-vars */
import { listItemNodeFromPos } from "lib/ample-editor/lib/list-item-util"
import AMPLENOTE_AREA, { amplenoteParamsFromHref } from "lib/ample-util/amplenote-area"

// --------------------------------------------------------------------------
export function amplenoteParamsFromTaskLinkNode(node, editorState, getPos) {
  // TEMP disabled, pending completion of AN-1677

  // const { schema } = editorState;
  //
  // // Must be a link node
  // if (!node || node.type !== schema.nodes.link) return null;
  //
  // const { attrs: { href } } = node;
  //
  // // Must be a link to a specific task
  // const amplenoteParams = amplenoteParamsFromHref(href);
  // if (!amplenoteParams || amplenoteParams.area !== AMPLENOTE_AREA.TASKS || !amplenoteParams.task) return null;
  //
  // // Must be in a task
  // try {
  //   const listItem = listItemNodeFromPos(editorState.doc.resolve(getPos()));
  //   if (!listItem || listItem.type !== schema.nodes.check_list_item) return null;
  // } catch (_error) {
  //   // Probably `Position ... out of range`
  //   return null;
  // }
  //
  // return amplenoteParams;
}

// --------------------------------------------------------------------------
export function isTaskLinkNode(node, editorState, getPos) {
  return !!amplenoteParamsFromTaskLinkNode(node, editorState, getPos);
}

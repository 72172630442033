import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"
import { Chip } from "@rmwc/chip"

import TagIcon from "lib/ample-editor/components/tag-icon"
import {
  filterReferencesFromNoteReferences,
  noteReferencesFromFilterReferences
} from "lib/ample-util/filter-references"
import { filterTagFromTagFilters, tagFiltersFromFilterTag } from "lib/ample-util/filter-tag"
import { noteParamsFromNoteUUID } from "lib/ample-util/note-uuid"

// --------------------------------------------------------------------------
// NOTE that the "tag" and "note" types referenced here are not as they are in ample-notes-store code: they have
// the necessary metadata already mixed into them, rather than being raw data types.
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
function FilterReferencesChip({ isNegated, note, noteUUID, setNoteReferences }) {
  const removeFilter = useCallback(
    () => {
      setNoteReferences(noteReferences => noteReferences.filter(noteReference => noteReference.noteUUID !== noteUUID));
    },
    [ noteUUID, setNoteReferences ]
  );

  return (
    <Chip
      className={ `filter-chip ${ isNegated ? "negated" : "" }` }
      icon="description"
      label={ note.name || noteUUID }
      onTrailingIconInteraction={ removeFilter }
      trailingIcon="close"
    />
  );
}

// --------------------------------------------------------------------------
function FilterTagChip({ isNegated, setTagFilters, tag, tagText }) {
  const removeFilter = useCallback(
    () => {
      setTagFilters(tagFilters => tagFilters.filter(tagFilter => tagFilter.tagText !== tagText));
    },
    [ setTagFilters, tagText ]
  );

  return (
    <Chip
      className={ `filter-chip ${ isNegated ? "negated" : "" }` }
      icon={ (<TagIcon { ...tag } crossedOut={ isNegated } />) }
      label={ tagText }
      onTrailingIconInteraction={ removeFilter }
      trailingIcon="close"
    />
  );
}

// --------------------------------------------------------------------------
export default function FilterChips({ filterParams, getNote, getTag, setFilterParams }) {
  const filterChips = [];

  const { references: filterReferences, tag: filterTag } = filterParams;

  const noteReferences = useMemo(() => noteReferencesFromFilterReferences(filterReferences), [ filterReferences ]);
  const setNoteReferences = useCallback(
    newNoteReferences => {
      if (typeof(newNoteReferences) === "function") {
        newNoteReferences = newNoteReferences(noteReferences);
      }

      setFilterParams({ ...filterParams, references: filterReferencesFromNoteReferences(newNoteReferences) });
    },
    [ filterParams, noteReferences, setFilterParams ]
  );

  const tagFilters = useMemo(() => tagFiltersFromFilterTag(filterTag), [ filterTag ]);
  const setTagFilters = useCallback(
    newTagFilters => {
      if (typeof(newTagFilters) === "function") {
        newTagFilters = newTagFilters(tagFilters);
      }

      setFilterParams({ ...filterParams, tag: filterTagFromTagFilters(newTagFilters) })
    },
    [ filterParams, setFilterParams, tagFilters ]
  );

  if (noteReferences.length > 0) {
    noteReferences.forEach(({ isNegated, noteUUID }) => {
      const note = getNote(noteUUID) || noteParamsFromNoteUUID(noteUUID);

      filterChips.push(
        <FilterReferencesChip
          isNegated={ isNegated }
          key={ `references-${ noteUUID }${ isNegated ? "-negated" : "" }` }
          note={ note }
          noteUUID={ noteUUID }
          setNoteReferences={ setNoteReferences }
        />
      );
    });
  }

  if (tagFilters.length > 0) {
    tagFilters.forEach(({ isNegated, tagText }) => {
      const tag = getTag(tagText) || { text: tagText };

      filterChips.push(
        <FilterTagChip
          isNegated={ isNegated }
          key={ `tag-${ tagText }${ isNegated ? "-negated" : "" }` }
          setTagFilters={ setTagFilters }
          tag={ tag }
          tagText={ tagText }
        />
      );
    });
  }

  if (filterChips.length > 0) {
    return (
      <div className="filter-chips">{ filterChips }</div>
    );
  } else {
    return null;
  }
}

FilterChips.propTypes = {
  filterParams: PropTypes.shape({
    references: PropTypes.string,
    tag: PropTypes.string,
  }).isRequired,
  getNote: PropTypes.func,
  getTag: PropTypes.func,
  setFilterParams: PropTypes.func.isRequired,
};

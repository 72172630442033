import { closeHistory } from "prosemirror-history"
import { NodeSelection, TextSelection } from "prosemirror-state"
import React from "react"
import ReactDOM from "react-dom"

import ImageOptionsMenu from "lib/ample-editor/components/image/image-options-menu"
import { buildInsertImageText } from "lib/ample-editor/commands/image-commands"
import { buildPluginImage, transactionFromPluginImageUpdates } from "lib/ample-editor/lib/plugin-image-util"
import replaceFromMarkdown from "lib/ample-editor/commands/replace-from-markdown"
import MediaView from "lib/ample-editor/views/media-view"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"

// --------------------------------------------------------------------------
// 1x1 $ample-gray PNG (from http://png-pixel.com)
const ERROR_IMAGE = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPcsv/MfwAHrgNAOkBd9gAAAABJRU5ErkJggg==";

// --------------------------------------------------------------------------
function textInsertionDisabled(editorView) {
  const { state: { schema } } = editorView;

  return !!schema.nodes.image.spec.disableTextInsertion;
}

// --------------------------------------------------------------------------
export default class ImageView extends MediaView {
  disableTextblockHacks = false;

  _menuButton = null;
  _menuContainer = null;
  _menuOpen = false;
  _openButton = null;
  _textButton = null;

  // --------------------------------------------------------------------------
  constructor(initialNode, editorView, getPos) {
    const { state: { schema } } = editorView;

    super(
      editorView,
      getPos,
      schema.nodes.image,
      () => {
        const image = document.createElement("div");
        image.className = "image";
        image.contentEditable = "false";
        return image;
      },
      () => {
        const imgElement = document.createElement("img");

        imgElement.onerror = () => {
          this._contentElement.setAttribute("src", ERROR_IMAGE);
        };

        imgElement.onload = () => {
          if (this._contentElement.getAttribute("src") === ERROR_IMAGE) {
            this._contentElement.className = "failed"
          } else {
            this._contentElement.className = "";
            this._naturalWidth = this._contentElement.naturalWidth;
            this._onContentElementWidthSet();
          }
        };

        return imgElement;
      },
      (node, src, { failed, uploading }) => {
        this._onUpdate(editorView, node, src, { failed, uploading });
      }
    );

    this.dom = document.createElement("div");
    this.dom.className = "image-view";
    this.dom.appendChild(this._container);

    this._addCaption = this._addCaption.bind(this, editorView, getPos);
    this._deleteImage = this._deleteImage.bind(this, editorView, getPos);
    this._getImageOptionPluginActions = this._getImageOptionPluginActions.bind(this, editorView, getPos);
    this._insertImageText = this._insertImageText.bind(this, editorView, getPos);
    this._setAlign = this._setAlign.bind(this, editorView, getPos);

    this._contentElement.addEventListener("click", this._onImageClick.bind(this, editorView, getPos));

    const buttonsContainer = document.createElement("div");
    buttonsContainer.className = "buttons-container";

    // Note the order of the children visually is reversed from the order in the document

    if (editorView.editable) {
      this._menuButton = document.createElement("span");
      this._menuButton.className = "menu-button overlay-button material-icons";
      this._menuButton.textContent = "more_horiz";
      this._menuButton.addEventListener("click", this._onMenuClick.bind(this, editorView, getPos));
      buttonsContainer.appendChild(this._menuButton);
    }

    this._openButton = document.createElement("a");
    this._openButton.className = "open-button overlay-button material-icons";
    this._openButton.rel = "noopener noreferrer";
    this._openButton.target = "_blank";
    this._openButton.textContent = "open_in_new";
    buttonsContainer.appendChild(this._openButton);

    if (editorView.editable) {
      this._textButton = document.createElement("span");
      this._textButton.className = "text-button overlay-button material-icons";
      this._textButton.textContent = "text_rotate_vertical";
      this._textButton.addEventListener("click", this._onTextClick);
      buttonsContainer.appendChild(this._textButton);
    }

    this._container.appendChild(buttonsContainer);

    if (editorView.editable) {
      this._menuContainer = document.createElement("div");
      this._menuContainer.className = "menu-container";
      this._container.appendChild(this._menuContainer);
    }

    this.contentDOM = document.createElement("div");
    this.contentDOM.className = "content-container";
    this.dom.appendChild(this.contentDOM);

    this.update(initialNode);
  }

  // --------------------------------------------------------------------------
  destroy(_view) {
    if (this._menuContainer) ReactDOM.unmountComponentAtNode(this._menuContainer);

    this.dom.remove();
  }

  // --------------------------------------------------------------------------
  stopEvent(event) {
    if (super.stopEvent(event)) return true;

    // Don't let editor change selection when clicking on menu button or anything in the menu itself
    if (event.target === this._menuButton) return true;
    if (event.target && event.target.closest && event.target.closest(".menu-container")) return true;

    return false;
  }

  // --------------------------------------------------------------------------
  _closeMenu = () => {
    this._menuOpen = false;

    if (this._menuContainer) {
      this.dom.classList.remove("menu-open");
      ReactDOM.unmountComponentAtNode(this._menuContainer);
    }
  }

  // --------------------------------------------------------------------------
  _addCaption = (editorView, getPos) => {
    if (!editorView.editable) return;

    const { dispatch, state } = editorView;

    const imagePos = getPos();
    const $imagePos = state.doc.resolve(imagePos);
    const imageNode = state.doc.nodeAt(imagePos);
    const childPos = $imagePos.start($imagePos.depth + 1);

    const transaction = state.tr;

    if (imageNode.childCount === 0) {
      transaction.insert(childPos, state.schema.nodes.paragraph.createAndFill());
    }

    transaction.setSelection(TextSelection.create(transaction.doc, childPos + 1));

    dispatch(closeHistory(transaction));

    editorView.focus();
  };

  // --------------------------------------------------------------------------
  _deleteImage = (editorView, getPos) => {
    const { dispatch, state } = editorView;

    const nodePos = getPos();
    const node = state.doc.nodeAt(nodePos);

    dispatch(state.tr.delete(nodePos, nodePos + node.nodeSize));
  };

  // --------------------------------------------------------------------------
  _getImageOptionPluginActions = async (editorView, getPos) => {
    const { props: { hostApp: { getPluginActions } } } = editorView;
    if (!getPluginActions) return [];

    const checkPluginImage = buildPluginImage(editorView, getPos);
    if (!checkPluginImage) return [];

    // As of 6/2020, there's no actual context for `imageOption` plugins, as the image is passed as an argument
    const checkEditorContext = {};

    const pluginActionPromises = await getPluginActions(
      [ PLUGIN_ACTION_TYPE.IMAGE_OPTION ],
      checkEditorContext,
      [ checkPluginImage ],
    );

    return pluginActionPromises.map(pluginActionPromise => pluginActionPromise.then(pluginAction => {
      if (!pluginAction) return null;

      const { run } = pluginAction;

      return {
        ...pluginAction,
        run: () => {
          const runPluginImage = buildPluginImage(editorView, getPos);
          if (!runPluginImage) return;

          const runEditorContext = {
            replaceSelection: markdownContent => {
              const { state: { doc, schema } } = editorView;

              const nodePos = getPos();
              const node = doc.nodeAt(nodePos);
              if (!node && node.type !== schema.nodes.image) return;

              const command = replaceFromMarkdown(nodePos, nodePos + node.nodeSize, markdownContent);
              return command(editorView.state, editorView.dispatch);
            },
            updateImage: updates => {
              const transaction = transactionFromPluginImageUpdates(editorView, getPos, updates);
              if (transaction) editorView.dispatch(transaction);
            },
          };
          return run(runEditorContext, runPluginImage);
        },
      };
    }));
  };

  // --------------------------------------------------------------------------
  _insertImageText = (editorView, getPos) => {
    if (!editorView.editable) return;

    const { dispatch, state } = editorView;
    buildInsertImageText(getPos())(state, dispatch);
  };

  // --------------------------------------------------------------------------
  _onImageClick = (editorView, getPos) => {
    const { dispatch, state } = editorView;
    dispatch(state.tr.setSelection(NodeSelection.create(state.doc, getPos())));
  };

  // --------------------------------------------------------------------------
  _onMenuClick = (editorView, getPos, event) => {
    event.preventDefault();

    this._menuOpen = !this._menuOpen;
    this._updateMenu(editorView, this._getNode());
  };

  // --------------------------------------------------------------------------
  _onTextClick = event => {
    event.preventDefault();

    this._insertImageText();
  };

  // --------------------------------------------------------------------------
  _onUpdate = (editorView, node, src, { failed, uploading }) => {
    this._contentElement.setAttribute("src", src);

    if (!uploading && !failed) {
      this._openButton.href = src;
    } else {
      this._openButton.removeAttribute("href");
    }

    const { attrs: { align, text } } = node;

    if (node.childCount > 0) {
      this.disableTextblockHacks = true;

      this.dom.classList.add("has-content");

      if (node.content.size > 2) {
        this.contentDOM.classList.remove("blank");
      } else {
        this.contentDOM.classList.add("blank");
      }
    } else {
      this.disableTextblockHacks = false;

      this.dom.classList.remove("has-content");
    }

    if (align === "center") {
      this.dom.classList.add("center-aligned");
    } else {
      this.dom.classList.remove("center-aligned");
    }

    if (editorView.editable && text && text.length > 0 && !textInsertionDisabled(editorView)) {
      this._container.classList.add("has-text");
    } else {
      this._container.classList.remove("has-text");
    }

    if (editorView.editable) this._contentElement.setAttribute("alt", text);
    else this._contentElement.removeAttribute("alt");

    this._updateMenu(editorView, node);
  }

  // --------------------------------------------------------------------------
  _setAlign = (editorView, getPos, align) => {
    const { dispatch, state } = editorView;

    const nodePos = getPos();
    const node = state.doc.nodeAt(nodePos);

    const transform = state.tr;
    transform.setNodeMarkup(nodePos, null, { ...node.attrs, align });
    dispatch(transform);
  };

  // --------------------------------------------------------------------------
  _updateMenu(editorView, node) {
    if (!this._menuContainer) return;

    if (this._menuOpen) {
      this.dom.classList.add("menu-open");

      const { attrs: { align, src, text } } = node;

      const canInsertText = editorView.editable && text && text.length > 0 && !textInsertionDisabled(editorView);

      const { height, width } = this._contentElement.getBoundingClientRect();

      ReactDOM.render(
        <ImageOptionsMenu
          addCaption={ node.childCount === 0 ? this._addCaption : null }
          align={ align }
          close={ this._closeMenu }
          deleteImage={ this._deleteImage }
          getImageOptionPluginActions={ this._getImageOptionPluginActions }
          height={ Math.round(height) }
          imageURL={ src }
          buildInsertImageText={ canInsertText ? this._insertImageText : null }
          naturalWidth={ this._naturalWidth }
          setAlign={ this._setAlign }
          setWidth={ this._setWidth }
          width={ Math.round(width) }
        />,
        this._menuContainer
      );
    } else {
      this.dom.classList.remove("menu-open");
      ReactDOM.unmountComponentAtNode(this._menuContainer);
    }
  }
}

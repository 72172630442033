import isUrl from "is-url"
import { DOMParser, DOMSerializer, Fragment, Slice } from "prosemirror-model"

import {
  expandMarkdownToHTML,
  handleDataURLImages,
  handleInsertIntoRichFootnote,
  liftNestedList,
  liftPastedListItemSlice,
  pasteListItems,
  unwrapHtmlSource,
} from "lib/ample-editor/lib/clipboard/clipboard-parse"
import {
  convertToListNodes,
  insertParagraphLineBreaks,
  handleMobileSafariBlobImagePaste,
  unwrapListItemContent,
} from "lib/ample-editor/lib/clipboard/clipboard-serialize"
import { filterImagesFromPaste } from "lib/ample-editor/lib/handle-paste-files"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import { schema } from "lib/ample-editor/schema"
import RemoveCompletedTaskStep from "lib/ample-editor/steps/remove-completed-task-step"
import RemoveHiddenTaskStep from "lib/ample-editor/steps/remove-hidden-task-step"
import { uuidFromDerivedUUID } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const isBlockquoteNode = node => node && node.type === schema.nodes.blockquote;
const isImageNode = node => node && node.type === schema.nodes.image;
const isListNode = node => node && node.type.spec.isListItem;

// --------------------------------------------------------------------------
// Called first thing after transformPastedHTML or transformPastedText
// https://prosemirror.net/docs/ref/#view.EditorProps.clipboardTextParser
export function buildClipboardTextParser(getView) {
  return function(text, $context) {
    const view = getView();
    const applyFormatting = !view || !view.input.shiftKey;

    if (applyFormatting && isUrl(text)) {
      const linkNode = schema.nodes.link.create({ href: text }, [ schema.text(text) ]);
      return new Slice(Fragment.from(linkNode), 0, 0);
    }

    // This is what ProseMirror's parseFromClipboard does if this method doesn't return a slice. We have to re-implement
    // this here so we can process each line of text content
    const dom = document.createElement("div");
    const tableRowRegex = /^[\s]*\|.+\|[\s]*$/;
    let tableDom;
    text.trim().split(/(?:\r\n?|\n)+/).forEach(block => {
      // If we apply formatting and line starts and ends with pipes, enter table processing loop
      if (applyFormatting && tableRowRegex.test(block)) {
        block = expandMarkdownToHTML(block);
        block = block.replace(/\|[\s]+([^|\n]+)[\s]+/g, "<td>$1</td>");
        block = block.replace(/\|[\s]*$/g, ""); // Remove trailing |
        if (tableDom) {
          tableDom.insertRow().innerHTML = block;
        } else {
          tableDom = document.createElement("table");
          dom.appendChild(tableDom).innerHTML = `<tr>${ block }</tr>`;
        }
      } else {
        tableDom = null;
        if (applyFormatting) {
          block = expandMarkdownToHTML(block);
        }
        const newParagraph = document.createElement("p");
        const appendedParagraph = dom.appendChild(newParagraph);
        appendedParagraph.innerHTML = block;
      }
    });

    return clipboardParser.parseSlice(dom, { preserveWhitespace: true, context: $context });
  }
}

// --------------------------------------------------------------------------
export function handleDrop(view, event, slice, _moved, transaction, insertPos) {
  handleDataURLImages(view, slice, insertPos);

  slice.content.descendants(node => {
    if (node.type === schema.nodes.check_list_item) {
      // When dragging a task from the completed or hidden tasks areas we want to un-hide or un-complete the task
      // (the assumption being this handler is only used in the main editor view, not task editor views)
      const { attrs: { uuid } } = node;
      if (!uuid) return;

      const originalUUID = uuidFromDerivedUUID(uuid);

      const { state: { doc: { attrs: { completedTasks, hiddenTasks } } } } = view;

      if (completedTasks) {
        // eslint-disable-next-line no-shadow
        const completedTask = completedTasks.find(completedTask => completedTask.uuid === originalUUID);
        if (completedTask) {
          node.attrs.completedAt = null;
          node.attrs.crossedOutAt = null;
          node.attrs.dismissedAt = null;

          // It's a bit weird if you drag a task out of the completed tasks area and it's hidden (moving to the other
          // tab), so we'll ensure it's unhidden
          node.attrs.startAt = null;
          node.attrs.uuid = originalUUID;

          // We want to dispatch another transaction to remove the source item, but can't do that synchronously since
          // we don't have access to the current transaction in the drop handler
          transaction.step(new RemoveCompletedTaskStep(schema, completedTask));
        }
      }

      if (hiddenTasks) {
        const hiddenTask = hiddenTasks.find(({ attrs }) => attrs.uuid === originalUUID);
        if (hiddenTask) {
          node.attrs.startAt = null;
          node.attrs.uuid = originalUUID;

          // We want to dispatch another transaction to remove the source item, but can't do that synchronously since
          // we don't have access to the current transaction in the drop handler
          transaction.step(new RemoveHiddenTaskStep(schema, hiddenTask));
        }
      }
    }
  });

  return false;
}

// --------------------------------------------------------------------------
export function handlePaste(view, event, slice) {
  const { selection: { $from } } = view.state;

  // List node content is in a p node inside each list node, so the parent node should be the list node. Similarly,
  // image note content is a p node inside an img node.
  const fromNode = $from.node(-1);

  if (isListNode(fromNode)) {
    const pastingListItems = slice.content.content.length > 1 && slice.content.content.every(isListNode);
    if (pastingListItems) {
      // When pasting multiple list items in an existing list item, we'd like some different behavior from the default
      // ProseMirror behavior of pasting just the first list item's content and dropping the remaining list items (due
      // to the list item schema preventing their nesting).
      return pasteListItems(view, fromNode, slice);
    } else if (slice.content.content.length === 1 && isListNode(slice.content.content[0]) &&
        (fromNode.content.content.length > 1 || fromNode.content.content[0].content.size > 0)) {
      // If we're pasting from a list item into a non-empty list item, we want to raise the pasted content so we don't
      // lose any links with hrefs in the slice
      liftPastedListItemSlice(slice, $from);
    }
  }

  // When pasting a blockquote in an image caption, it will replace the image with the blockquote's content because
  // the blockquote isn't a valid child of the image. We'd rather insert the blockquote content directly
  if (isImageNode(fromNode)) {
    const pastingBlockquote = slice.content.childCount === 1 && isBlockquoteNode(slice.content.firstChild);
    if (pastingBlockquote && fromNode.type.validContent(slice.content.firstChild.content)) {
      const tr = view.state.tr;

      tr.deleteSelection();

      // We want to insert the paragraph child of the blockquote's content
      const blockquoteContent = slice.content.firstChild.content;
      tr.insert($from.pos, blockquoteContent.firstChild.content)

      view.dispatch(tr.scrollIntoView().setMeta(TRANSACTION_META_KEY.PASTE, true));
      return true;
    }
  }

  handleDataURLImages(view, slice, $from.pos);
  const pasted = handleInsertIntoRichFootnote(view, slice, event);
  if (pasted) return true;

  if (handleMobileSafariBlobImagePaste(view, slice, $from.pos)) {
    return true;
  }

  if (slice.content.size === 2 && isImageNode(slice.content.firstChild)) {
    // When you right click on an image in Chrome and select "Copy image", Chrome will copy an HTML <img> tag to the
    // clipboard, but will _also_ copy the image file. Pasting this results in a paste event that has HTML that will
    // get turned into an image node (by prosemirror) along with a file that _we_ would otherwise detect and convert to
    // an image. To prevent inserting the image twice - once for each of these components in the clipboard event - we
    // will stop the event from propagating out to our paste handler.
    if (filterImagesFromPaste(event)) {
      event.stopPropagation();
    }

    // When pasting an image, ProseMirror will try to fix the image's _content_ in to the document, even though it's
    // empty, in which case we need to force it to not try to fit the slice's _content_ in, but just insert the slice
    // entirely
    slice.openStart = 0;
    slice.openEnd = 0;
  }

  // Let ProseMirror handle the paste
  return false;
}

// --------------------------------------------------------------------------
export function transformPastedHTML(html) {
  // Uncomment this to get some useful info on what is incoming during paste operations
  // console.log("transformPastedHTML", html, typeof(html));

  // On Mobile Safari before iOS 11.4, copied images are pasted as:
  //    <img src="webkit-fake-url://b5c90246-dae0-4b31-96bf-43b998217152/imagepng">
  // The fix for this landed in macOS Safari relatively recently, per https://bugs.webkit.org/show_bug.cgi?id=49141
  // but as of 1/2018 has yet to land in Mobile Safari.
  const match = /\bsrc=["']webkit-fake-url:\/\/[a-z\d-]+\//.exec(html);
  if (match) {
    // These images show up locally, until the page is refreshed. There is no way to access the image through
    // the given fake URL due to CORS protection (can't CORS with a webkit-fake-url protocol), so instead of
    // letting the user think their image is uploaded
    return '<p>Unable to paste image due to <a href="https://bugs.webkit.org/show_bug.cgi?id=49141">this bug</a>.</p>';
  }

  // Some IDEs like to place code in the clipboard wrapped in <pre> tags, but with rich formatting using spans and
  // newlines using <br> tags that aren't intended to be considered code. ProseMirror handles stripping the spans
  // used for formatting, but that leaves us with missing newlines, as ProseMirror will strip those too. We'll use
  // some basic heuristics to try and detect these malformed <pre> tags and convert the br tags to newlines
  // Note that RubyMine places a null character "\0" at the end of the string in some - but not all - cases.
  const formattedPreMatch = /^<html><head><meta http-equiv="content-type" content="text\/html; charset=UTF-8"><\/head><body><pre .+<\/pre><\/body><\/html>\0?$/.exec(html);
  if (formattedPreMatch) {
    return html.replace(/<br\s?\/?>/g, "\n");
  }

  try {
    // OneNote does this weird thing where it puts in "\u{13}\u{10}" as a newline (even on macOS) when creating rich
    // link html, but that doesn't work right when parsed, often causing tags to not be recognized (e.g.
    // "<a\u{13}\u{10}href=" isn't recognized as a link) and other weird behavior (possibly due to ProseMirror doing
    // weird things on the carriage return insert, browser depending).
    // eslint-disable-next-line no-control-regex
    return html.replace(/\u0013\u0010/ug, "\n");
  } catch (_error) {
    return html; // Unicode regexps / character properties not supported, in older browsers
  }
}

// --------------------------------------------------------------------------
export function transformPastedText(text) {
  // Uncomment this to get some useful info on what is incoming during paste operations
  // console.log("transformPastedText", text, typeof(text));

  // Without this, PM will want to interpret <enclosed stuff> as HTML elements, and thus will strip them
  text = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");

  return text;
}

// --------------------------------------------------------------------------
class ClipboardParser extends DOMParser {
  // --------------------------------------------------------------------------
  // Parse HTML in preparation for being pasted into an anote
  // Generally such parsing happens from the node's parseDOM() method in schema, but some desirable
  // HTML transformations don't translate cleanly to a particular schema element
  parseSlice(dom, options = {}) {
    // Each loop limited to 100 iterations to cope with degenerate cases doing an excessive amount of work
    [ "ol > ol", "ul > ul", "li > ol", "li > ul" ].forEach(listInListItemSelector => {
      for (let i = 0; i < 100; i++) {
        const ulNode = dom.querySelector(listInListItemSelector);
        if (!ulNode) break;
        liftNestedList(ulNode, listInListItemSelector);
      }
    });

    // Remove all inserted footnote nodes - we already will have the data we need on the actual anchor node for a link
    // mark, so they are unnecessary (and only were inserted to paste outside of the editor)
    const footnoteNodes = dom.querySelectorAll(".an-fn");
    Array.from(footnoteNodes).forEach(footnoteNode => footnoteNode.parentNode.removeChild(footnoteNode));
    unwrapHtmlSource(dom);

    return super.parseSlice(dom, options);
  }
}
export const clipboardParser = new ClipboardParser(schema, DOMParser.schemaRules(schema));

// --------------------------------------------------------------------------
class ClipboardSerializer extends DOMSerializer {
  // --------------------------------------------------------------------------
  // Transform Prosemirror fragment into a returned string of HTML ready to be pasted into another app
  serializeFragment(fragment, options = {}, target) {
    const domFragment = super.serializeFragment(fragment, options, target);

    convertToListNodes(domFragment, ".bullet-list-item", "ul");
    convertToListNodes(domFragment, ".number-list-item", "ol");

    unwrapListItemContent(domFragment);
    insertParagraphLineBreaks(domFragment);

    return domFragment;
  }
}
export const clipboardSerializer = new ClipboardSerializer(
  ClipboardSerializer.nodesFromSchema(schema),
  ClipboardSerializer.marksFromSchema(schema)
);

import { Plugin, PluginKey } from "prosemirror-state"

import EDITOR_TAB from "lib/ample-editor/lib/editor-tab"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import EditorTabsPluginView from "lib/ample-editor/views/editor-tabs-plugin-view"

// --------------------------------------------------------------------------
const DEFAULT_TAB = EDITOR_TAB.COMPLETED;

// --------------------------------------------------------------------------
const editorTabsPluginKey = new PluginKey("editorTabs");

// --------------------------------------------------------------------------
function defaultTabFromState(doc) {
  const { attrs: { completedTasks, hiddenTasks } } = doc;

  if (completedTasks.length > 0) return EDITOR_TAB.COMPLETED;
  if (hiddenTasks.length > 0) return EDITOR_TAB.HIDDEN;

  return null;
}

// --------------------------------------------------------------------------
export function getEditorTabsPluginState(state) {
  return editorTabsPluginKey.getState(state);
}

// --------------------------------------------------------------------------
export function updateEditorTabsPluginState(transaction, pluginStateUpdates) {
  return transaction.setMeta(editorTabsPluginKey, pluginStateUpdates).setMeta(TRANSACTION_META_KEY.ADD_TO_HISTORY, false);
}

// --------------------------------------------------------------------------
export default function createEditorTabsPlugin(options = {}) {
  const { createPluginView = true, initialTab = null, onTabChange = null } = options;

  return new Plugin({
    key: editorTabsPluginKey,
    state: {
      init: (_config, state) => {
        const { doc } = state;

        return {
          currentTab: (initialTab && initialTab.name) || defaultTabFromState(doc, null) || DEFAULT_TAB,
          expanded: initialTab ? !!initialTab.expanded : true,
        }
      },
      apply: (tr, pluginState, oldState, _state) => {
        let { currentTab, expanded } = pluginState;

        const meta = tr.getMeta(editorTabsPluginKey);
        if (meta) {
          if ("currentTab" in meta) currentTab = meta.currentTab;
          if ("expanded" in meta) expanded = meta.expanded;
          if ("haveReferencingNotes" in meta && meta.haveReferencingNotes && defaultTabFromState(tr.doc) === null) {
            currentTab = EDITOR_TAB.REFERENCES;
          }
        } else {
          // Switch to hidden/completed tasks when first completing/hiding a task, to help indicate what's happening
          const { doc: { attrs: { completedTasks, hiddenTasks } } } = tr;
          if (completedTasks !== oldState.doc.attrs.completedTasks || hiddenTasks !== oldState.doc.attrs.hiddenTasks) {
            if (defaultTabFromState(oldState.doc) === null) {
              currentTab = defaultTabFromState(tr.doc);
            }
          }
        }

        if (currentTab !== pluginState.currentTab || expanded !== pluginState.expanded) {
          if (onTabChange) onTabChange({ expanded, name: currentTab });

          return { currentTab, expanded };
        } else {
          return pluginState;
        }
      },
    },
    view: createPluginView ? editorView => new EditorTabsPluginView(editorView) : null,
  });
}

import { format } from "date-fns"
import Sugar from "sugar-date"

import BigEval from "lib/ample-util/big-eval"
import { dailyNoteNameFromDate } from "lib/ample-util/jots"

// --------------------------------------------------------------------------
// `match[1]` is the actual expression content
export const EXPRESSION_PATTERN = /{([^{}\n]+)}$/;

// Doesn't require the closing brace, or any actual content in the expression
export const OPEN_EXPRESSION_PATTERN = /{([^{}\n]+)?$/;

// --------------------------------------------------------------------------
export function replaceExpression(value, replacementMapping = {}) {
  const match = value.match(EXPRESSION_PATTERN);
  if (!match) return null;

  const expression = match[1];

  let result;
  if (expression in replacementMapping) {
    result = replacementMapping[expression];
  } else {
    const evaluateExpressionResult = evaluateExpression(match[1]);
    result = evaluateExpressionResult.result;
  }
  if (result === null) return null;

  return value.replace(EXPRESSION_PATTERN, result);
}

// --------------------------------------------------------------------------
export default function evaluateExpression(expressionText) {
  let result;
  let wrapInLink = false;
  const lowerCaseExpression = expressionText.toLowerCase();
  try {
    if (lowerCaseExpression === "now") {
      result = `[${ format(Date.now(), "HH:mm:ss") }]`;
    } else {
      // Strip comma and underscore from the expression provided to BigEval since it can't hang with the humanness of it all. No valid math expressions use these characters per https://github.com/aviaryan/BigEval.js/#operators
      const bigEvalExpression = expressionText.replace(/(\d)[,_](\d)/g, "$1$2");
      result = new BigEval().exec(bigEvalExpression);

      if (isNaN(result)) {
        const sugarParams = {};
        const date = Sugar.Date.create(expressionText, { params: sugarParams });
        if (!date.getTime || isNaN(date.getTime())) return { result: null };

        // The specificity param indicates how specific the user was (year/month/week/day/hour) - we want "tomorrow" /
        // "yesterday" to translate to date strings that match daily note names, but if the user got more specific
        // we'll need to include the actual time.
        if (sugarParams.specificity && sugarParams.specificity < 4) { // DAY_INDEX = 4
          result = `${ dailyNoteNameFromDate(date) } at ${ format(date, "HH:mm:ss") }`;
        } else {
          result = dailyNoteNameFromDate(date);
        }
      } else {
        wrapInLink = true;
      }
    }
  } catch (_error) {
    return { result: null };
  }

  return { result, wrapInLink };
}

// --------------------------------------------------------------------------
// Note that this is copied into ample-mobile for reference there, so it should not import anything
// --------------------------------------------------------------------------

const TOOLBAR_COMMAND = {
  CLEAR_MARKS: "clear-active-marks",
  DELETE_TABLE_COLUMN: "delete-table-column",
  DELETE_TABLE_ROW: "delete-table-row",
  EDIT_LINK_DESCRIPTION: "link-edit_description",
  EDIT_LINK_MEDIA: "link-edit_media",
  EXTRACT_TO_LINK_DESCRIPTION: "link-extract_to_description",
  EXTRACT_TO_LINKED_NOTE: "link-extract_to_linked_note",
  INSERT_TABLE_COLUMN_AFTER: "insert-table-column-after",
  INSERT_TABLE_COLUMN_BEFORE: "insert-table-column-before",
  INSERT_TABLE_ROW: "insert-table-row",
  LIST_ITEM_INDENT: "list_item-indent",
  LIST_ITEM_MOVE_DOWN: "list_item-move_down",
  LIST_ITEM_MOVE_UP: "list_item-move_up",
  LIST_ITEM_OUTDENT: "list_item-outdent",
  LIST_ITEM_REORDER: "list_item-reorder",
  REDO: "redo",
  TABLE_COLUMN_SORT: "table-column-sort",
  TABLE_OF_CONTENTS_REFRESH: "table-of-contents-refresh",
  TOGGLE_BLOCK_ATTACHMENT: "block-attachment",
  TOGGLE_BLOCK_BLOCKQUOTE: "block-blockquote",
  TOGGLE_BLOCK_BULLET_LIST_ITEM: "block-bullet_list_item",
  TOGGLE_BLOCK_CHECK_LIST_ITEM: "block-check_list_item",
  TOGGLE_BLOCK_CODE_BLOCK: "block-code_block",
  TOGGLE_BLOCK_HEADING_1: "block-heading-1",
  TOGGLE_BLOCK_HEADING_2: "block-heading-2",
  TOGGLE_BLOCK_HEADING_3: "block-heading-3",
  TOGGLE_BLOCK_IMAGE: "block-image",
  TOGGLE_BLOCK_NUMBER_LIST_ITEM: "block-number_list_item",
  TOGGLE_BLOCK_TABLE: "block-table",
  TOGGLE_BLOCK_VIDEO: "block-video",
  TOGGLE_COLLAPSED: "toggle-collapsed",
  TOGGLE_LINK: "inline-link",
  TOGGLE_MARK_CODE: "mark-code",
  TOGGLE_MARK_EM: "mark-em",
  TOGGLE_MARK_HIGHLIGHT: "mark-highlight",
  TOGGLE_MARK_STRIKETHROUGH: "mark-strikethrough",
  TOGGLE_MARK_STRONG: "mark-strong",
  TOGGLE_TABLE_COLUMN_LOCKED_WIDTH: "table-column-locked-width",
  TOGGLE_TABLE_FULL_WIDTH: "table-full-width",
  UNDO: "undo",
};
export default TOOLBAR_COMMAND;

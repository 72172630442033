/* eslint-disable */

// --------------------------------------------------------------------------
// Copied from ProseMirror-view source:
const result = {};
if (typeof navigator !== "undefined" && typeof document !== "undefined") {
  var ie_edge = /Edge\/(\d+)/.exec(navigator.userAgent);
  var ie_upto10 = /MSIE \d/.test(navigator.userAgent);
  var ie_11up = /Trident\/(?:[7-9]|\d{2,})\..*rv:(\d+)/.exec(navigator.userAgent);

  result.linux = /Linux/.test(navigator.platform);
  result.mac = /Mac/.test(navigator.platform);
  var ie = result.ie = !!(ie_upto10 || ie_11up || ie_edge);
  result.ie_version = ie_upto10 ? document.documentMode || 6 : ie_11up ? +ie_11up[1] : ie_edge ? +ie_edge[1] : null;
  result.gecko = !ie && /gecko\/(\d+)/i.test(navigator.userAgent);
  result.gecko_version = result.gecko && +(/Firefox\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1];
  var chrome = !ie && /Chrome\/(\d+)/.exec(navigator.userAgent);
  result.chrome = !!chrome;
  result.chrome_version = chrome && +chrome[1];
  result.locale = navigator.language || "en-US";
  // Is true for both iOS and iPadOS for convenience
  result.safari = !ie && /Apple Computer/.test(navigator.vendor);
  result.ios = result.safari && (/Mobile\/\w+/.test(navigator.userAgent) || navigator.maxTouchPoints > 2);
  result.android = /Android \d/.test(navigator.userAgent);
  result.webkit = "webkitFontSmoothing" in document.documentElement.style;
  result.webkit_version = result.webkit && +(/\bAppleWebKit\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1];

  // Additional checks not in prosemirror-view source
  result.firefox = /Firefox\/\d/i.test(navigator.userAgent) && !/Seamonkey\/\d/i.test(navigator.userAgent);
}
//  This is very crude, but unfortunately both these browsers _pretend_
//  that they have a clipboard API—all the objects and methods are
//  there, they just don't work, and they are hard to test.
export const brokenClipboardAPI = (result.ie && result.ie_version < 15) || (result.ios && result.webkit_version < 604);

// --------------------------------------------------------------------------
export const isAndroid = result.android;

// --------------------------------------------------------------------------
// e.g., en-US, useful for parsing numbers in native format
export const navigatorLocale = result.locale;

// --------------------------------------------------------------------------
export const isAndroidChrome = isAndroid && result.chrome;

// --------------------------------------------------------------------------
// Apple platforms use command (⌘) as a modifier key
export const isApplePlatform = result.ios || result.mac;

// --------------------------------------------------------------------------
export const isFirefox = result.firefox;

// --------------------------------------------------------------------------
export const isIOS = result.ios;

// --------------------------------------------------------------------------
// This isn't strictly iOS 14 check, but there were some behavior changes in the Mobile Safari shipped with
// iOS 14 (webkit version 605) that need to be handled differently
export const isIOS14OrLater = result.ios && result.webkit_version > 604;

// --------------------------------------------------------------------------
export const isLinux = result.linux;

// --------------------------------------------------------------------------
export const isMacOS = result.mac;

// --------------------------------------------------------------------------
export const isSafari = result.safari;

// --------------------------------------------------------------------------
export const isWindows = !isLinux && !isApplePlatform && !process.env.NODE_ENV === "test";

// --------------------------------------------------------------------------
// Android 8 doesn't support `hover: hover` well, reporting that the device supports hover
export const deviceSupportsHover = !isAndroidChrome && window.matchMedia("(hover: hover)").matches;

// --------------------------------------------------------------------------
export const modKeyName = isApplePlatform ? "⌘" : "ctrl";
export const altKeyName = isApplePlatform ? "option" : "alt";

import { TextSelection } from "prosemirror-state"
import {
  cellAround,
  CellSelection,
  inSameTable,
} from "prosemirror-tables"

import {
  applyColumnSelect,
  applyRowSelect,
  setColumnWidth,
} from "lib/ample-editor/lib/table/table-commands"
import {
  CELL_MIN_WIDTH,
  CELL_RESIZE_INACTIVE,
  CELL_SELECT_INACTIVE,
  WIDTH_OF_RESIZE_HANDLE
} from "lib/ample-editor/lib/table/table-constants"
import {
  cellFromPos,
  cellFromTable,
  isCellResizePossible,
  tableFromPos,
  tablePluginKey,
} from "lib/ample-editor/lib/table/table-util"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"
import {
  cellResizeTimeout,
  clearCellHover,
  selectionStartPos
} from "lib/ample-editor/plugins/table-plugin"

// --------------------------------------------------------------------------
export const tableTouchSelect = (view, tablePos, beforeTouchSelectionPos, event) => {
  const { state: { doc, selection } } = view;

  if (tablePos > doc.content.size) return false;
  const $table = tableFromPos(doc.resolve(tablePos));
  const touchDom = event.target.closest("td");
  if (touchDom) {
    const $cell = cellFromTable($table, touchDom.dataset.rowIndex, touchDom.dataset.columnIndex);
    if (!$cell) return;

    event.preventDefault(); // Without this, Chrome pops a context window on every tap as of Nov 2022

    let selectionCellUnchanged = false;
    if (beforeTouchSelectionPos) {
      const $selectionWas = doc.resolve(beforeTouchSelectionPos);
      const $selectionWasCell = cellFromPos($selectionWas);
      selectionCellUnchanged = $selectionWasCell && $selectionWasCell.pos === $cell.pos;
    }

    let cellSelection = $cell.nodeAfter && $cell.nodeAfter.type.spec.tableRole === "cell" ? new CellSelection($cell) : null;
    // If user already had a CellSelection, or if touch start and end are within same cell, focus via TextSelection
    if (!cellSelection || selection.eq(cellSelection) || selectionCellUnchanged) {
      if (!view.hasFocus()) view.focus();
      // https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent/changedTouches
      const touch = event.changedTouches[0];
      const touchPos = view.posAtCoords({ left: Math.floor(touch.clientX), top: Math.floor(touch.clientY) });
      const $touchPos = doc.resolve(touchPos.pos);
      cellSelection = TextSelection.near($touchPos);
    }

    view.dispatch(view.state.tr.setSelection(cellSelection));
    return true
  }
};

// --------------------------------------------------------------------------
// Prior to mousedown, the mouse might find its way into a state worth recording:
// * Might be hovering near the right border of a cell, in which case it is recorded as a drag candidate (cellToResizePos)
// * Might be hovering near left edge of a row, in which case it should record that a row is selectable (hoverRowSelectPos)
// * Might be hovering near top of first column in table, in which case column should be selectable (hoverColumnSelectPos)
// As of Nov 2022, anything found will get dispatched in a meta that is recorded into tablePlugins state
export function onTableMouseMove(domView, view, getPos, event) {
  const { state } = view;

  const pluginState = tablePluginKey.getState(state);
  if (!pluginState || pluginState.dragging) return; // Handled by the move() inside mouseDown handler

  let resizeHoverPos = CELL_RESIZE_INACTIVE;
  let hoverColumnSelectPos = CELL_SELECT_INACTIVE;
  let hoverRowSelectPos = CELL_SELECT_INACTIVE;
  const domCell = domCellAround(event.target);
  if (domCell) {
    const { left: hoveredCellLeftX, right: hoveredCellRightX, top: hoveredCellTop } = domCell.getBoundingClientRect();
    const pixelsFromCellEdge = Math.min(event.clientX - hoveredCellLeftX, hoveredCellRightX - event.clientX);
    const nearCellsRightEdge = hoveredCellRightX - event.clientX <= WIDTH_OF_RESIZE_HANDLE;
    const columnIndex = parseInt(domCell.dataset.columnIndex, 10);
    const rowIndex = parseInt(domCell.dataset.rowIndex, 10);
    const nearTableTop = rowIndex === 0 && Math.abs(hoveredCellTop - event.clientY) <= WIDTH_OF_RESIZE_HANDLE;

    if (pixelsFromCellEdge <= WIDTH_OF_RESIZE_HANDLE || nearTableTop) {
      const { doc } = state;
      if (!Number.isInteger(getPos()) || getPos() >= doc.content.size) return; // WBH observed this possible Dec 2022 following hot reloads
      const $table = tableFromPos(doc.resolve(getPos()));
      if (!$table) return; // Observed in prod Dec 2022, presumably due to timing irregularities in when this handler is throttle/called vs doc changes

      if (pixelsFromCellEdge <= WIDTH_OF_RESIZE_HANDLE) {
        const resizeColumnIndex = columnIndex - (nearCellsRightEdge ? 0 : 1);
        const $cellLeftOfResizeBorder = resizeColumnIndex >= 0 ? cellFromTable($table, rowIndex, resizeColumnIndex) : null;
        if (resizeColumnIndex < 0 || (columnIndex === 0 && event.clientX - hoveredCellLeftX <= WIDTH_OF_RESIZE_HANDLE)) {
          // Hovering near left edge of the first column
          const $edgeCell = cellFromTable($table, rowIndex, 0);
          hoverRowSelectPos = $edgeCell ? $edgeCell.pos : CELL_SELECT_INACTIVE;
        } else {
          resizeHoverPos = $cellLeftOfResizeBorder ? $cellLeftOfResizeBorder.pos : CELL_RESIZE_INACTIVE;
        }
      } else {
        const $cell = cellFromTable($table, rowIndex, columnIndex);
        if ($cell && $cell.nodeAfter && $cell.nodeAfter.type.spec.tableRole === "cell") hoverColumnSelectPos = $cell.pos;
      }
    }
  }

  const updatedMetas = [];
  if (resizeHoverPos !== domView.resizeHoverPos) {
    domView.setResizeHoverPos(resizeHoverPos);
    if (resizeHoverPos === CELL_RESIZE_INACTIVE) {
      if (pluginState.cellToResizePos !== resizeHoverPos) {
        updatedMetas.setCellToResizePos = CELL_RESIZE_INACTIVE;
      }
      if (domView._resizeTimer) clearTimeout(domView._resizeTimer);
      domView._resizeTimer = null;
    } else if (resizeHoverPos !== CELL_RESIZE_INACTIVE && !domView._resizeTimer) {
      domView._resizeTimer = setTimeout(cellResizeTimeout.bind(null, view, domView, resizeHoverPos), 500);
    }
  }

  if (hoverRowSelectPos !== pluginState.hoverRowSelectPos) updatedMetas.setHoverRowSelectPos = hoverRowSelectPos;
  if (hoverColumnSelectPos !== pluginState.hoverColumnSelectPos) updatedMetas.setHoverColumnSelectPos = hoverColumnSelectPos;

  if (Object.keys(updatedMetas).length) {
    const setCellToResize = state.tr.setMeta(tablePluginKey, updatedMetas);
    view.dispatch(setCellToResize);
  }
}

// --------------------------------------------------------------------------
// This mouseleave covers the user leaving the actual editor, at which point we need to ensure that any hover
// decorations that may have been applied when the user was adjacent to the table (but after the view's mouseLeave
// fired) are removed
export function onTablePropMouseLeave(view) {
  const { tr } = view.state;
  tr.setMeta(tablePluginKey, { leftView: true });
  tr.setMeta(TRANSACTION_META_KEY.AUTOMATIC, true);
  view.dispatch(tr);
}

// --------------------------------------------------------------------------
// Starts by capturing various info into tablePlugin's meta so we can react to what happens after mousedown
// Then sets up three handlers to follow mouseDown event: for moving cursor, for dragging, and for releasing mouseDown
export function onTableMouseDown(view, startEvent) {
  if (startEvent.ctrlKey || startEvent.metaKey) return;

  // First order of business to determine whether this mouseDown appears to be start of a selection or a column resize
  const mouseDownStartDOMCell = domInCell(view, startEvent.target);
  const startPluginState = tablePluginKey.getState(view.state);
  let resizing = false; // Used in move handler function below
  let rowColumnSelecting = false;

  if (isCellResizePossible(view.state) && !startPluginState.dragging) {
    startColumnResize(view, startPluginState, startEvent);
    resizing = true;
  } else if (startPluginState.hoverColumnSelectPos !== CELL_SELECT_INACTIVE) {
    startEvent.preventDefault();
    const selected = applyColumnSelect(startPluginState.hoverColumnSelectPos, { shiftHeld: startEvent.shiftKey })(view.state, view.dispatch);
    if (selected) {
      if (!view.hasFocus()) { // Column selection can't act on keystrokes unless editor has focus
        view.focus();
      }
      rowColumnSelecting = true;
    } else {
      return;
    }
  } else if (startPluginState.hoverRowSelectPos !== CELL_SELECT_INACTIVE) {
    startEvent.preventDefault();
    const selected = applyRowSelect(startPluginState.hoverRowSelectPos, { shiftHeld: startEvent.shiftKey })(view.state, view.dispatch);
    if (selected) {
      if (!view.hasFocus()) { // Row selection can't act on keystrokes unless editor has focus
        view.focus();
      }
      rowColumnSelecting = true;
    } else {
      return;
    }
  } else if (startEvent.shiftKey) {
    startOrExpandSelection(view, startEvent, mouseDownStartDOMCell, setCellSelection);
  } else if (mouseDownStartDOMCell) {
    // If user mouses down in a table when cell selection is active, ensure they can escape the cell selection
    // If user clicked on a table cell that contains a formula result, ensure we hide the result and put user in a position to modify formula
    if (view.state.selection instanceof CellSelection || mouseDownStartDOMCell.querySelector(".formula-result")) {
      const mousePos = view.posAtCoords({ left: startEvent.clientX, top: startEvent.clientY });
      if (mousePos) {
        startEvent.preventDefault();
        const transform = view.state.tr;
        // If user clicked a formula result, put them at start of the cell to ensure they are in a position that will remain available after Swapping cell contents
        if (mouseDownStartDOMCell.querySelector(".formula-result")) {
          const $cell = cellFromPos(view.state.doc.resolve(mousePos.pos));
          const selection = TextSelection.near($cell);
          transform.setSelection(selection);
        } else {
          transform.setSelection(TextSelection.create(transform.doc, mousePos.pos));
        }
        view.dispatch(transform);
      }
    }
  } else {
    // Not in a cell (to change cell selection), nor resizing column: this handler's work is done. Let
    // other views decide whether to react
    return;
  }

  // --------------------------------------------------------------------------
  // Create and dispatch a cell selection between the given anchor and
  // the position under the mouse.
  function setCellSelection($anchor, event) {
    suppressTextSelectionUpdate(view);

    let $head = cellUnderMouse(view, event);
    const starting = tablePluginKey.getState(view.state).selectionStartPos === null;
    if (!$head || !inSameTable($anchor, $head)) {
      if (starting) $head = $anchor;
      else return;
    }
    const selection = new CellSelection($anchor, $head);
    if (starting || !view.state.selection.eq(selection)) {
      const tr = view.state.tr.setSelection(selection);
      if (starting) {
        tr.setMeta(tablePluginKey, { setSelectionStartPos: $anchor.pos });
      }
      view.dispatch(tr);
    }
  }

  // --------------------------------------------------------------------------
  // Stop listening to mouse motion events.
  function finish(event) {
    view.domObserver.suppressingSelectionUpdates = false;
    view.root.removeEventListener("mouseup", finish);
    view.root.removeEventListener("dragstart", stopSelection);
    view.root.removeEventListener("mousemove", mouseDownMove);
    const pluginState = tablePluginKey.getState(view.state);

    if (pluginState.dragging && pluginState.cellToResizePos !== CELL_RESIZE_INACTIVE) {
      updateCellToResizeColumnWidth(view, pluginState.cellToResizePos, draggedWidth(pluginState.dragging, event));
    }

    const transform = view.state.tr;
    clearCellHover(transform, { redraw: false });
    view.dispatch(transform);
  }

  // --------------------------------------------------------------------------
  function stopSelection() {
    const pluginState = tablePluginKey.getState(view.state);

    // If a column resize is in progress, detecting a drag start should be a null-op
    if (!pluginState.dragging) {
      return finish();
    }
  }

  // --------------------------------------------------------------------------
  function mouseDownMove(event) {
    // WBH Nov 2023 observes that destructuring view.state / view.state.doc has been observed to break functionality,
    // perhaps because view object evolves over the course of the drag?
    const pluginState = tablePluginKey.getState(view.state);
    const anchor = selectionStartPos(view.state);

    if (resizing && pluginState.cellToResizePos !== CELL_RESIZE_INACTIVE) {
      const width = draggedWidth(pluginState.dragging, event);
      updateCellToResizeColumnWidth(view, pluginState.cellToResizePos, width);
    } else if (rowColumnSelecting) {
      expandSelectionOnHoverCellChange(view.state, view.dispatch);
    } else if (anchor !== null || domInCell(view, event.target) !== mouseDownStartDOMCell) {
      let $anchor;
      if (anchor !== null && anchor < view.state.doc.content.size) {
        $anchor = view.state.doc.resolve(anchor);
      } else {
        // Moving out of the initial cell -- start a new cell selection
        $anchor = cellUnderMouse(view, startEvent);
        if (!$anchor) return stopSelection();
      }
      if ($anchor) setCellSelection($anchor, event);
    }
  }

  view.root.addEventListener("mouseup", finish);
  view.root.addEventListener("dragstart", stopSelection);
  view.root.addEventListener("mousemove", mouseDownMove);
}

// --------------------------------------------------------------------------
export function onTableMouseLeave(view) {
  const { state } = view;
  const pluginState = tablePluginKey.getState(state);
  if (!pluginState || pluginState.dragging) return; // Handled by the move() inside mouseDown handler

  const transform = state.tr;
  clearCellHover(transform, { preserveDragFrom: true });
  view.dispatch(transform);
}

// --------------------------------------------------------------------------
// Local functions
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Return resolved $cell position if the event is over a cell, or null if not.
// `preventBetweenCellNull` when true, when the user hovers on the border between cells, instead of returning null
//    we will return the pos of the cell to the left of the mouse position
function cellUnderMouse(view, event, { preventBetweenCellNull = false } = {}) {
  const { state: { doc } } = view;
  const mousePos = view.posAtCoords({ left: event.clientX, top: event.clientY });
  if (!mousePos) return null;
  const $mousePos = doc.resolve(mousePos.pos);
  const $cellAround = cellAround($mousePos);
  if ($cellAround || !preventBetweenCellNull) return $cellAround;
  const $cell = cellFromPos($mousePos);
  if (!$cell || !$cell.nodeBefore || $cell.nodeBefore.type.spec.tableRole !== "cell") return null;

  // When hovering on a cell boundary, the sought result for the one caller who uses this parameter is to return
  // the cell _left_ of where the user hovered. If other callers want something else, we can revise the param name/value
  // to allow for something less weirdly specific than:
  return doc.resolve($cell.pos - $cell.nodeBefore.nodeSize);
}

// --------------------------------------------------------------------------
function domCellAround(domElement) {
  while (domElement && domElement.nodeName !== "TD") {
    domElement = domElement.classList && domElement.classList.contains("ProseMirror") ? null : domElement.parentNode;
  }
  // WBH unclear how domCell can be non-null value when getBoundingClientRect is undefined, but it has
  // been observed in Sentry as of Dec 2022. Currently interpreted to mean the found domElement is invalid
  return domElement && domElement.getBoundingClientRect ? domElement : null;
}

// --------------------------------------------------------------------------
function draggedWidth(dragging, event) {
  const { startWidth } = dragging;
  const delta = event.clientX - dragging.startX;
  return Math.max(CELL_MIN_WIDTH, startWidth + delta);
}

// --------------------------------------------------------------------------
function updateCellToResizeColumnWidth(view, cellToResizePos, width) {
  const { state: { doc } } = view;
  const $cell = cellFromPos(doc.resolve(cellToResizePos));
  if ($cell) {
    const $table = tableFromPos($cell);
    setColumnWidth($table, $cell.index(), width)(view.state, view.dispatch);
  } else {
    // eslint-disable-next-line no-console
    console.error("Unable to find cell to resize at", cellToResizePos);
  }
}

// --------------------------------------------------------------------------
const expandSelectionOnHoverCellChange = (state, dispatch) => {
  const pluginState = tablePluginKey.getState(state);
  const { columnSelectDragFrom, hoverColumnSelectPos, hoverRowSelectPos, rowSelectDragFrom, selectDragTo } = pluginState;

  if (hoverColumnSelectPos !== CELL_SELECT_INACTIVE && columnSelectDragFrom !== CELL_SELECT_INACTIVE &&
      selectDragTo !== hoverColumnSelectPos) {
    applyColumnSelect(columnSelectDragFrom, { columnHeadPos: hoverColumnSelectPos })(state, dispatch);
  } else if (hoverRowSelectPos !== CELL_SELECT_INACTIVE && rowSelectDragFrom !== CELL_SELECT_INACTIVE &&
      selectDragTo !== hoverRowSelectPos) {
    applyRowSelect(rowSelectDragFrom, { rowCellHeadPos: hoverRowSelectPos })(state, dispatch);
  }
}

// --------------------------------------------------------------------------
// Inlined from pm-tables (where it's not exported)
function domInCell(view, dom) {
  for (; dom && dom !== view.dom; dom = dom.parentNode) {
    if (dom.nodeName === "TD") return dom;
  }
}

// --------------------------------------------------------------------------
function startOrExpandSelection(view, mouseDownEvent, startDOMCell, setCellSelection) {
  let $newAnchor = null;

  if (view.state.selection instanceof CellSelection) {
    $newAnchor = view.state.selection.$anchorCell;
  } else if (startDOMCell) {
    const $selectionAnchor = cellAround(view.state.selection.$anchor);
    if (Number.isInteger($selectionAnchor.pos) && cellUnderMouse(view, mouseDownEvent).pos !== $selectionAnchor.pos) {
      // Adding to a selection that starts in another cell (causing a cell selection to be created).
      $newAnchor = $selectionAnchor;
    }
  }

  if ($newAnchor) {
    mouseDownEvent.preventDefault();
    setCellSelection($newAnchor, mouseDownEvent);
  }
}

// --------------------------------------------------------------------------
function startColumnResize(view, pluginState, mouseDownEvent) {
  const { state, state: { doc } } = view;
  if (pluginState.cellToResizePos >= doc.content.size) return;
  suppressTextSelectionUpdate(view);
  const cellToResize = doc.nodeAt(pluginState.cellToResizePos);
  if (cellToResize && cellToResize.type.spec.tableRole === "cell") {
    const domMatch = view.domAtPos(pluginState.cellToResizePos);
    const cellDom = domMatch.node.childNodes[domMatch.offset];
    const width = cellToResize.attrs.colwidth ? cellToResize.attrs.colwidth : cellDom.offsetWidth;
    const dragStartTransform = state.tr.setMeta(tablePluginKey, {
      setDragging: { startX: mouseDownEvent.clientX, startWidth: width },
    });
    view.dispatch(dragStartTransform);
    mouseDownEvent.preventDefault();
  } else {
    // eslint-disable-next-line no-console
    console.error("Unable to find cell to resize at", pluginState.cellToResizePos, "found", cellToResize);
  }
}

// --------------------------------------------------------------------------
// Necessary to prevent pm-view's selection handler from creating TextSelection when dragging a
// cell selection through text. This must always be unset when the mouse drag is complete, lest the user's
// ability to create TextSelection get permanently suppressed
function suppressTextSelectionUpdate(view) {
  view.domObserver.suppressingSelectionUpdates = true;
}

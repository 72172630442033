import { orderBy } from "lodash"
import { TextSelection } from "prosemirror-state"

import { buildCompleteListItems, listSelectionFromState } from "lib/ample-editor/lib/list-item-commands"
import { setQuickAdjustPopup } from "lib/ample-editor/plugins/check-list-item-plugin"
import { setOpenLinkPosition } from "lib/ample-editor/plugins/link-plugin"
import { calculateTaskValue, TASK_COMPLETION_MODE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export function buildSetSelectedCheckListItemDuration(duration) {
  return function(state, dispatch) {
    const { depth, node } = selectedCheckListItem(state);
    if (!node) return false;

    if (dispatch) {
      const { selection: { $head } } = state;
      const nodePos = $head.before(depth);
      dispatch(state.tr.setNodeMarkup(nodePos, null, { ...node.attrs, duration }));
    }
  }
}

// --------------------------------------------------------------------------
export function buildSetTaskDetailQuickAdjustPopup(uuid, attributeName) {
  return function(state, dispatch) {
    if (dispatch) {
      const transaction = setOpenLinkPosition(state.tr, null);

      setQuickAdjustPopup(transaction, uuid ? { uuid, attributeName } : null);

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function buildToggleSelectedCheckListItemFlag(flagValue) {
  return function(state, dispatch) {
    const { depth, node } = selectedCheckListItem(state);
    if (!node) return false;

    if (dispatch) {
      const { attrs: { flags } } = node;

      flagValue = flagValue.toUpperCase();

      const hasFlag = flags && flags.indexOf(flagValue) > -1;
      const newFlags = hasFlag ? flags.replace(flagValue, "") : ((flags || "") + flagValue);

      const { selection: { $head } } = state;
      const nodePos = $head.before(depth);
      dispatch(state.tr.setNodeMarkup(nodePos, null, { ...node.attrs, flags: newFlags }));
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function crossOutSelectedCheckListItem(state, dispatch) {
  if (!selectionContainsCheckListItem(state)) return false;

  return buildCompleteListItems({ taskCompletionMode: TASK_COMPLETION_MODE.CROSS_OUT })(state, dispatch);
}

// --------------------------------------------------------------------------
export function deleteSelectedCheckListItemAtStart(state, dispatch) {
  const { selection: { empty, $from } } = state;
  if (!empty) return false;
  if ($from.pos !== $from.start($from.depth)) return false;

  const { depth, node } = selectedCheckListItem(state);
  if (!node || node.content.size > 2) return false;

  if (dispatch) {
    const nodePos = $from.before(depth);

    const transaction = state.tr;

    transaction.delete(nodePos, nodePos + node.nodeSize)
    transaction.setSelection(TextSelection.near(transaction.doc.resolve(nodePos), -1));

    dispatch(transaction);
  }

  return true;
}

// --------------------------------------------------------------------------
// Returns true if any item was dismissed
export function dismissSelectedCheckListItem(state, dispatch) {
  if (!selectionContainsCheckListItem(state)) return false;

  return buildCompleteListItems({ taskCompletionMode: TASK_COMPLETION_MODE.DISMISS })(state, dispatch);
}

// --------------------------------------------------------------------------
export function reorderCheckListItems(state, dispatch) {
  const { depth, node } = selectedCheckListItem(state);
  if (!node) return false;

  const { doc, schema, selection: { $head } } = state;

  const nodes = [];

  let startPos = null;
  let $cursor = $head;
  for (let i = 0; i < 100; i++) { // Arbitrary limit of 100 as infinite loop paranoia
    const previousNodeEnd = $cursor.before(depth) - 1;
    if (previousNodeEnd <= 1) break;

    $cursor = doc.resolve(previousNodeEnd);
    const previousNode = $cursor.node(depth);
    if (!previousNode || previousNode.type !== schema.nodes.check_list_item) break;

    // Return whether we can do something as soon as possible if this is just a test
    if (dispatch === null) return true;

    startPos = $cursor.before(depth);
    nodes.unshift(previousNode);
  }

  nodes.push(node);

  let endPos = null;
  $cursor = $head;
  for (let i = 0; i < 100; i++) { // Arbitrary limit of 100 as infinite loop paranoia
    const nextNodeStart = $cursor.after(depth) + 1;
    if (nextNodeStart >= doc.content.size) break;

    $cursor = doc.resolve(nextNodeStart);
    const nextNode = $cursor.node(depth);
    if (!nextNode || nextNode.type !== schema.nodes.check_list_item) break;

    // Return whether we can do something as soon as possible if this is just a test
    if (dispatch === null) return true;

    endPos = $cursor.after(depth);
    nodes.push(nextNode);
  }

  if (nodes.length === 1) return false;

  if (startPos === null) startPos = $head.before(depth);
  if (endPos === null) endPos = $head.after(depth);

  if (dispatch) {
    // eslint-disable-next-line no-shadow
    const rootNodes = nodes.reduce((rootNodes, node) => {
      const lastRootNode = rootNodes[rootNodes.length - 1];
      if (!lastRootNode || node.attrs.indent <= lastRootNode.node.attrs.indent) {
        rootNodes.push({ node, descendants: [] });
      } else {
        lastRootNode.descendants.push(node);
      }
      return rootNodes;
    }, []);

    const orderedRootNodes = orderBy(
      rootNodes,
      [
        // eslint-disable-next-line no-shadow
        ({ node, descendants }) => descendants.reduce(
          (sum, descendant) => sum + calculateTaskValue(descendant.attrs),
          calculateTaskValue(node.attrs)
        ),
        (_node, nodeIndex) => nodeIndex,
      ],
      [
        "desc", // by points
        "asc", // by index
      ]
    );

    const orderedNodes = orderedRootNodes.reduce(
      // eslint-disable-next-line no-shadow
      (nodes, { node, descendants }) => {
        nodes.push(node);
        descendants.forEach(descendant => nodes.push(descendant));
        return nodes;
      },
      []
    );

    const tr = state.tr;
    tr.replaceWith(startPos, endPos, orderedNodes);

    // Move the cursor to the start of the first node
    let nodePos = startPos;

    tr.doc.nodesBetween(startPos, endPos, (updatedNode, updatedNodePos) => {
      if (updatedNode === orderedNodes[0]) {
        nodePos = updatedNodePos + 2; // Account for opening position of node and first paragraph
      }
      return false;
    });

    tr.setSelection(TextSelection.create(tr.doc, nodePos));

    dispatch(tr.scrollIntoView());
  }

  return true;
}

// --------------------------------------------------------------------------
export function selectCheckListItemContent(state, dispatch) {
  const { depth, node } = selectedCheckListItem(state);
  if (!node) return false;

  if (dispatch) {
    const { doc, selection: { $from, $to } } = state;

    const $start = doc.resolve($from.start(depth));
    const $end = doc.resolve($to.end(depth));

    dispatch(state.tr.setSelection(TextSelection.between($start, $end)));
  }

  return true;
}

// --------------------------------------------------------------------------
function selectedCheckListItem(state) {
  const { schema, selection: { $anchor, $head } } = state;
  if (!$anchor.sameParent($head)) return {};

  let depth = $head.depth - 1;
  let node = $head.node(depth);
  if (!node) return {};

  if (node.type !== schema.nodes.check_list_item) {
    // Check the grandparent node
    depth -= 1;
    node = $head.node(depth);
    if (node.type !== schema.nodes.check_list_item) return {};
  }

  return { depth, node };
}

// --------------------------------------------------------------------------
function selectionContainsCheckListItem(state) {
  const listSelection = listSelectionFromState(state);
  if (!listSelection) return false;

  const { doc, schema } = state;
  let checklistItemFound = false;
  doc.nodesBetween(listSelection.from, listSelection.to, node => {
    if (node.type === schema.nodes.check_list_item) checklistItemFound = true;
    if (checklistItemFound || node.type !== schema.nodes.tasks_group) return false;
  });

  return checklistItemFound;
}

import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

// --------------------------------------------------------------------------
export const placeholderPluginKey = new PluginKey("placeholder");

// --------------------------------------------------------------------------
// Based on https://discuss.prosemirror.net/t/how-to-input-like-placeholder-behavior/705/12
export const placeholderPlugin = new Plugin({
  key: placeholderPluginKey,
  props: {
    decorations(state) {
      const { doc } = state;

      if (doc.childCount !== 1 || doc.firstChild.type.name !== "paragraph" || doc.firstChild.content.size > 0) return;

      const placeholderText = placeholderPluginKey.getState(state);
      if (!placeholderText) return;

      const placeHolder = document.createElement("span");
      placeHolder.classList.add("placeholder");
      placeHolder.setAttribute("data-placeholder", placeholderText);

      // Note this is inserted _after_ the empty paragraph - not inside the empty paragraph - to work around
      // Firefox contentEditable issues (cursor disappearing when placeholder is inserted but not visible)
      return DecorationSet.create(doc, [ Decoration.widget(2, placeHolder) ]);
    }
  },
  state: {
    init(config) {
      return config.placeholder;
    },
    apply(tr, placeholder) {
      return placeholder;
    }
  }
});

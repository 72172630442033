import { isSameDay } from "date-fns"
import Sugar from "sugar-date"
import { DAY_PARTS } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
// "Day parts" are members of the DAY_PART enum, e.g., DAY_PART.EARLY_MORNING
export function dayPartFromText(text) {
  const prefix = text.toLowerCase();

  const dayParts = Object.keys(DAY_PARTS);
  for (let i = 0; i < dayParts.length; i++) {
    const dayPart = dayParts[i];

    const { name } = DAY_PARTS[dayPart];
    if (name.toLowerCase().startsWith(prefix)) {
      return dayPart;
    }
  }

  return null;
}

// --------------------------------------------------------------------------
export default function parseDateText(text) {
  if (!text) return null;

  // Sugar gets a bit confused with "Tomorrow, July 21" and similar, thinking you mean July 2001
  let haveRelativeDay = false;
  text = text.replace(/^(today|tomorrow),(.+)$/i, (_match, relativeDay, remainder) => {
    haveRelativeDay = true;
    return `${ relativeDay } ${ remainder.trim() }`;
  });

  // Keys are described under "Parsing Tokens" on https://sugarjs.com/docs/#/DateParsing
  const params = {};

  // https://sugarjs.com/docs/#/Date/create
  // Try date text in the online util in the "Parsing" section at https://sugarjs.com/dates/
  const date = Sugar.Date.create(text, { future: true, params });
  if (isNaN(date)) return null;

  // As of Sep 2023, the sugar-date 2.0.6 package interprets "next [month name]" to occur in 2001.
  // This check can be removed when we upgrade Sugar, assuming that gets fixed:
  if (date.getFullYear() === 2001 && text.includes("next")) {
    date.setFullYear((new Date()).getFullYear() + 1);
  }

  if (haveRelativeDay) {
    const textWithoutRelativeDay = text.replace(/^(today|tomorrow)/i, "").trim();

    const dateWithoutRelativeDay = Sugar.Date.create(textWithoutRelativeDay, { future: true });
    if (!isNaN(dateWithoutRelativeDay) && !isSameDay(dateWithoutRelativeDay, date)) {
      return dateWithoutRelativeDay;
    }
  } else if ("weekday" in params && params.specificity === 4) { // 4 => day
    // See https://github.com/andrewplummer/Sugar/issues/589#issuecomment-286944873 for specificity values

    // Note we only care about full weekday names as this is intended to handle the ambiguity from a roundtrip through
    // `formatDate` and the user editing the output of that to change just the numeric day number.
    const textWithoutWeekday = text.replace(/^(monday|tuesday|wednesday|thursday|friday|saturday|sunday)/i, "").trim();

    const dateWithoutWeekday = Sugar.Date.create(textWithoutWeekday, { future: true });
    if (!isNaN(dateWithoutWeekday) && !isSameDay(dateWithoutWeekday, date)) {
      return dateWithoutWeekday;
    }
  }

  return date;
}

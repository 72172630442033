import { splitBlock } from "prosemirror-commands"
import { TextSelection } from "prosemirror-state"

import { COLLAPSIBLE_HEADING_DEPTH } from "lib/ample-editor/lib/collapsible-defines"
import { isPosHiddenNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
export function enterInHeading(state, dispatch) {
  const { schema, selection: { empty, $to } } = state;
  if (!empty) return false;

  const node = $to.node(COLLAPSIBLE_HEADING_DEPTH);
  if (!node || node.type !== schema.nodes.heading || !node.attrs.collapsed) return false;

  // Accounts for opening positions of any nested nodes
  const contentEndPos = $to.end(COLLAPSIBLE_HEADING_DEPTH) - ($to.depth - COLLAPSIBLE_HEADING_DEPTH);
  if ($to.pos === contentEndPos) {
    // Insert new nodes after the heading to jump into, potentially including a hr to end the heading's section
    if (dispatch) {
      const transaction = state.tr;

      let posAfter = $to.after(COLLAPSIBLE_HEADING_DEPTH);

      let haveHorizontalRule = false;
      while (isPosHiddenNode(state, posAfter)) {
        const nodeAfter = state.doc.nodeAt(posAfter);
        posAfter += nodeAfter.nodeSize;
        haveHorizontalRule = nodeAfter.type === schema.nodes.horizontal_rule;
      }

      const newNodes = [];

      let insertPos = posAfter;

      const nodeAfter = state.doc.nodeAt(posAfter);
      if (nodeAfter && nodeAfter.type === schema.nodes.horizontal_rule) {
        insertPos += nodeAfter.nodeSize;
      } else if (!haveHorizontalRule) {
        newNodes.push(schema.nodes.horizontal_rule.createAndFill());
        haveHorizontalRule = true;
      }

      newNodes.push(schema.nodes.paragraph.createAndFill());

      transaction.insert(insertPos, newNodes);

      const newCursorPos = posAfter + (haveHorizontalRule ? -1 : 0);
      const $newCursor = transaction.doc.resolve(transaction.mapping.map(newCursorPos));
      transaction.setSelection(TextSelection.between($newCursor, $newCursor));

      dispatch(transaction);
    }
  } else {
    // When splitting collapsed headings, the new heading will subsume the collapsed section (by virtue of being
    // inserted between the existing collapsed heading and the first sibling of the collapsed section) but we want
    // to indicate that the collapsed heading is no longer collapsed.
    return splitBlock(state, transaction => {
      const nodeAttrs = { ...node.attrs, collapsed: false };
      transaction.setNodeMarkup($to.before(COLLAPSIBLE_HEADING_DEPTH), null, nodeAttrs);

      if (dispatch) dispatch(transaction);
    });
  }

  return true;
}

import { COLLAPSIBLE_HEADING_DEPTH } from "lib/ample-editor/lib/collapsible-defines"
import { selectedListItemDepth } from "lib/ample-editor/lib/list-item-commands"
import { isPosCollapsibleNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
// Returns true or false if a collapsible node could be found at the current
// cursor pos, indicating if the item is expanded or collapsed, or `null` if
// the cursor isn't in a collapsible node.
export default function expandCollapseNodeAvailable(state) {
  const { selection: { $cursor } } = state;
  if (!$cursor) return null;

  const { depth } = selectedListItemDepth(state);
  if (depth) {
    const $listItemPos = state.doc.resolve($cursor.before(depth));
    if (!$listItemPos.nodeAfter || !$listItemPos.nodeAfter.type.spec.isListItem) {
      return null;
    }

    if (isPosCollapsibleNode(state, $listItemPos.pos)) {
      return $listItemPos.nodeAfter.attrs.collapsed;
    } else {
      return null;
    }
  } else {
    const headingNode = $cursor.node(COLLAPSIBLE_HEADING_DEPTH);
    const { type: { schema } } = headingNode;
    if (headingNode.type !== schema.nodes.heading) return null;

    const $headingPos = state.doc.resolve($cursor.before(COLLAPSIBLE_HEADING_DEPTH));

    if (isPosCollapsibleNode(state, $headingPos.pos)) {
      return headingNode.attrs.collapsed;
    } else {
      return null;
    }
  }
}

import PropTypes from "prop-types"
import React from "react"

import { hexColorFromTag } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
const ICON_PATH = "M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M7,18H9L9.35,16H13.35L13,18H15L15.35,16H17.35L17.71,14H15.71L16.41,10H18.41L18.76,8H16.76L17.12,6H15.12L14.76,8H10.76L11.12,6H9.12L8.76,8H6.76L6.41,10H8.41L7.71,14H5.71L5.35,16H7.35L7,18M10.41,10H14.41L13.71,14H9.71L10.41,10Z";
const ICON_PATH_CROSSED_OUT = "M3 2.586 21.414 21 20 22.414l-1.828-1.828H5.475a1.889 1.889 0 0 1-1.89-1.889V6l-2-2L3 2.586Zm5.559 8.387-.525 3.002H6.145l-.34 1.889h1.89l-.331 1.889h1.888l.331-1.89h3.778l-.33 1.89h1.888l.062-.358-3.42-3.42H9.923l.244-1.394-1.608-1.608Zm10.138-7.387c1.043 0 1.889.846 1.889 1.89v11.107l-4.641-4.64.306-1.746h1.889l.33-1.889h-1.889l.34-1.888h-1.889l-.34 1.888H12.31l-1.183-1.183.128-.705-.834-.001-2.834-2.833h11.11Zm-10.914 6.61-.831-.83-.146.831h.977Z";
const ICON_PATH_SHARED = "M18 19.5c.914 0 1.781.103 2.556.286l.287.073C22.723 20.367 24 21.362 24 22.5V24H12v-1.5l.012-.188c.132-1.059 1.37-1.973 3.145-2.453l.287-.073A11.178 11.178 0 0118 19.5zM19 3a2 2 0 012 2v5.241a5.591 5.591 0 00-2.484-.843L18.76 8h-2l.36-2h-2l-.36 2h-4l.36-2h-2l-.36 2h-2l-.35 2h2l-.7 4h-2l-.36 2h2L7 18h2l.35-2 3.114.001c.137.763.429 1.473.842 2.097-1.557.638-2.746 1.622-3.267 2.902H5a2 2 0 01-2-2V5a2 2 0 012-2zm-1 8.625a3.375 3.375 0 012.33 5.814l-.101.095A3.375 3.375 0 1118 11.624zM14.41 10l-.139.789-.04.035a5.66 5.66 0 00-.13.122l-.024.023a5.651 5.651 0 00-1.336 2.03c-.119.312-.21.638-.273.974l-.004.026L9.71 14l.7-4z";
const ICON_PATH_SHARED_CROSSED_OUT = "M15.317 19.818 19.499 24H12v-1.5l.012-.188c.126-1.013 1.264-1.894 2.917-2.388l.228-.065.16-.041ZM22.711 24l-4.496-4.497L18 19.5c.784 0 1.532.075 2.219.213l.337.073.287.073c1.802.487 3.05 1.421 3.15 2.5L24 22.5V24h-1.289ZM3.585 8.084l4.664 4.664-.215 1.227H6.145l-.34 1.889h1.89l-.331 1.889h1.888l.331-1.89h1.78l1.56 1.562c.04.081.08.161.123.24l.048.088c.07.123.143.242.22.359-1.384.57-2.469 1.4-3.063 2.473H5.475a1.889 1.889 0 0 1-1.89-1.888V8.084ZM18 11.625a3.375 3.375 0 0 1 2.474 5.669l-.145.145-.1.095a3.362 3.362 0 0 1-2.229.841 3.37 3.37 0 0 1-1.097-.182l-2.096-2.096A3.375 3.375 0 0 1 18 11.625ZM3.564 2l1.61 1.61c.098-.015.199-.024.3-.024h13.223c1.043 0 1.889.846 1.889 1.89v4.527a5.593 5.593 0 0 0-2.303-.621l.187-1.074h-1.889l.34-1.888h-1.889l-.34 1.888h-3.777l.34-1.888h-1.89l-.211 1.17 2.607 2.607h2.6l-.106.606a5.62 5.62 0 0 0-1.75 2.99l-2.171-2.172.004-.019-3.216-3.216-.004.019L3.66 4.948l.006-.018L2.15 3.414 3.564 2Z";

// --------------------------------------------------------------------------
export default function TagIcon({ className, color, crossedOut, onClick, shares }) {
  const fillColor = hexColorFromTag({ color });

  let path;
  if (shares) {
    path = crossedOut ? ICON_PATH_SHARED_CROSSED_OUT : ICON_PATH_SHARED;
  } else {
    path = crossedOut ? ICON_PATH_CROSSED_OUT : ICON_PATH;
  }

  return (
    <svg
      className={ `material-icons ${ className || "tag-icon" }` }
      onClick={ onClick }
      viewBox="0 0 24 24"
    >
      <path fill={ fillColor } d={ path } />
    </svg>
  );
}

TagIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  crossedOut: PropTypes.bool,
  onClick: PropTypes.func,
  shares: PropTypes.number,
};

import Sugar from "sugar-date"

import { durationParamsFromDuration, isoDurationFromDurationParams } from "lib/ample-util/tasks"
import { durationFromTimeText } from "lib/ample-editor/components/task-detail/util"

// --------------------------------------------------------------------------
// See https://github.com/andrewplummer/Sugar/issues/589#issuecomment-286944873 for specificity values
const SUGAR_SPECIFICITY_DAY = 4;

// --------------------------------------------------------------------------
export default function durationFromText(inputText, existingDuration = null, { parseTimes = false } = {}) {
  if (inputText === "") return "";

  const sugarParams = {};
  const date = Sugar.Date.create(inputText + " from now", { future: true, params: sugarParams });

  if (isNaN(date)) {
    if (parseTimes) {
      const timeDuration = durationFromTimeText(inputText);
      if (timeDuration) return timeDuration;
    }

    return "";
  }

  if (sugarParams.specificity < SUGAR_SPECIFICITY_DAY) {
    if (parseTimes) {
      const timeDuration = durationFromTimeText(inputText);
      return timeDuration || "";
    }

    const durationParams = {
      hours: sugarParams.hour,
      minutes: sugarParams.minute,
      seconds: sugarParams.second,
    };

    return isoDurationFromDurationParams(durationParams);
  } else {
    const durationParams = {
      years: sugarParams.year,
      months: sugarParams.month,
      weeks: sugarParams.week,
      days: sugarParams.day,
    };

    // Attempt to retain the time of day from the existing duration
    if (existingDuration && existingDuration.length > 0) {
      const existingDurationParams = durationParamsFromDuration(existingDuration);
      if (existingDurationParams) {
        [ "hours", "minutes", "seconds" ].forEach(key => {
          const value = existingDurationParams[key];
          if (value) durationParams[key] = value;
        });
      }
    }

    return isoDurationFromDurationParams(durationParams);
  }
}

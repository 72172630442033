// --------------------------------------------------------------------------
// IMPORTANT TERMINOLOGY NOTE:
//
//   a "filter group" is the string representation of a set of group filters, while a "group filter" is an
//   object describing one of those filters.
//
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
const FILTER_GROUP = {
  ARCHIVED: "archived",
  CREATED: "created",
  DELETED: "deleted",
  HAS_TASKS: "taskLists",
  INDEXING: "indexing",
  NOT_CREATED: "notCreated",
  PLUGIN: "plugin",
  PUBLIC: "public",
  SAVING: "saving",
  SHARE_RECEIVED: "shareReceived",
  SHARE_SENT: "shareSent",
  SHARED: "shared",
  STALE: "stale",
  UPDATED_THIS_WEEK: "thisWeek",
  UPDATED_TODAY: "today",
  UNTAGGED: "untagged",
  VAULT: "vault",
};
export default FILTER_GROUP;

const VALID_FILTER_GROUPS = Object.values(FILTER_GROUP);

export const FILTER_GROUPS_DELIMITER = ",";
export const NEGATED_GROUP_PREFIX = "^";

// --------------------------------------------------------------------------
export function filterGroupFromGroupFilter({ group, isNegated }) {
  return isNegated ? NEGATED_GROUP_PREFIX + group : group;
}

// --------------------------------------------------------------------------
export function filterGroupFromGroupFilters(groupFilters) {
  if (groupFilters && groupFilters.length) {
    return groupFilters.map(filterGroupFromGroupFilter).join(FILTER_GROUPS_DELIMITER);
  }

  return null;
}

// --------------------------------------------------------------------------
// A "group" here is a combined set of filter groups, e.g. as found in a URL query string as "group=..."
export function groupFiltersFromFilterGroup(filterGroup, { validate = false } = {}) {
  const filterGroups = filterGroup ? filterGroup.split(FILTER_GROUPS_DELIMITER) : [];

  const groupFilters = [];

  filterGroups.forEach(group => {
    let isNegated = false;

    if (group.startsWith(NEGATED_GROUP_PREFIX)) {
      isNegated = true;
      group = group.slice(NEGATED_GROUP_PREFIX.length);
    }

    if (validate && !VALID_FILTER_GROUPS.includes(group)) {
      return;
    }

    groupFilters.push({ group, isNegated });
  });

  return groupFilters;
}

// --------------------------------------------------------------------------
export function toValidFilterGroup(filterGroup) {
  if (!filterGroup) return null;

  return filterGroupFromGroupFilters(groupFiltersFromFilterGroup(filterGroup, { validate: true }));
}

import { NodeSelection } from "prosemirror-state"

import { isAndroidChrome } from "lib/ample-editor/lib/client-info"
import VECTOR_ICON_PATHS from "lib/ample-editor/lib/vector-icon-paths"
import { getLocalFileMetadata, isFailedLocalFileURL, isLocalFileURL } from "lib/ample-editor/plugins/file-plugin"

// --------------------------------------------------------------------------
const MIN_CONTENT_WIDTH = 40;

// --------------------------------------------------------------------------
// Touch events don't report the position in the same way as mouse events, so this handles both
const pageXFromDragEvent = event => {
  if (event.type.startsWith("touch")) {
    const touch = event.touches[0]; // Note we only track/use the first touch
    return touch.pageX;
  } else {
    return event.pageX;
  }
};

// --------------------------------------------------------------------------
// Common base class for image and video node views
export default class MediaView {
  _androidChromeSpace = null;
  // This is the top-level container - which may be the same as this.dom - that is treated as the root of the managed
  // media view (for resizing, etc)
  _container = null;
  // This is the element containing the content (i.e. video or image) and is what gets sized/resized
  _contentElement = null;
  _indicatorElement = null;
  _naturalWidth = null;
  _nodeType = null;
  _onUpdateCallback = null;
  _resizedWidth = null;
  _resizeHandle = null;
  _resizing = false;

  // --------------------------------------------------------------------------
  constructor(editorView, getPos, nodeType, createContainer, createContentElement, onUpdateCallback) {
    this.update = this.update.bind(this, editorView);
    this._getNode = this._getNode.bind(this, editorView, getPos);
    this._setWidth = this._setWidth.bind(this, editorView, getPos);
    this._onHandleDragStart = this._onHandleDragStart.bind(this, editorView, getPos);

    this._onUpdateCallback = onUpdateCallback;

    if (createContainer) {
      this._container = createContainer();
    } else {
      this.dom = document.createElement("div");
      this.dom.className = nodeType.name;
      this._container = this.dom;
    }

    this._contentElement = createContentElement();
    this._container.appendChild(this._contentElement);

    if (editorView.editable) {
      this._resizeHandle = document.createElement("span");
      this._resizeHandle.className = "resize-handle overlay-button";
      this._resizeHandle.setAttribute("draggable", "false");
      this._resizeHandle.addEventListener("mousedown", this._onHandleDragStart);
      this._resizeHandle.addEventListener("touchstart", this._onHandleDragStart);

      const largeSVG = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      largeSVG.setAttribute("viewBox", "0 0 24 24");

      const largePath = document.createElementNS("http://www.w3.org/2000/svg", "path");
      largePath.setAttribute("d", VECTOR_ICON_PATHS["arrow-expand"]);
      largeSVG.appendChild(largePath);

      const smallSVG = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      smallSVG.setAttribute("viewBox", "0 0 32 32");

      const smallPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
      // Provided by Liz in AN-1540
      smallPath.setAttribute("d", "M32 20v10a2 2 0 0 1-2 2H20l12-12Z");
      smallSVG.appendChild(smallPath);

      this._resizeHandle.appendChild(largeSVG);
      this._resizeHandle.appendChild(smallSVG);

      this._container.appendChild(this._resizeHandle);
    }

    if (isAndroidChrome) {
      // Android on Chrome has some issues with non-contentEditable islands in contentEditable, some of which are
      // described in https://github.com/ProseMirror/prosemirror/issues/903 - the main one that affects images is when
      // pressing backspace with the cursor after the image or with the image node selected (i.e. after tapping on the
      // image), the keyboard is dismissed and the image isn't deleted. To address this, we can give Android Chrome a
      // zero-width non-breaking space that _is_ editable. Backspace will delete this node, which ProseMirror can then
      // detect (backspace on Android Chrome isn't a real keypress, so PM detects it based on what happened to the DOM)
      // See ignoreMutation for additional ramifications of this workaround
      this._androidChromeSpace = document.createElement("span");
      this._androidChromeSpace.className = "android-chrome-space";
      this._androidChromeSpace.textContent = "\ufeff";
      this._androidChromeSpace.contentEditable = "true";
      this._androidChromeSpace.setAttribute("draggable", "false");
      this._container.appendChild(this._androidChromeSpace);
    }

    this._indicatorElement = document.createElement("span");
    this._indicatorElement.className = "indicator overlay-button material-icons";
    this._container.appendChild(this._indicatorElement);

    this._nodeType = nodeType;
  }

  // --------------------------------------------------------------------------
  ignoreMutation(_event) {
    // Since this is a leaf node, there's no internal modification we care about, and we want to let resizing happen
    // without ProseMirror trying to interpret it as a meaningful mutation
    return true;
  }

  // --------------------------------------------------------------------------
  stopEvent(event) {
    if (this._resizing) {
      // If we're in the midst of resizing, we don't want HTML drag to kick in, since it would drag a copy of the
      // image, resulting in duplication
      if (event.type === "dragstart") event.preventDefault();

      return true;
    }

    return event.target && event.target.classList && event.target.classList.contains("resize-handle");
  }

  // --------------------------------------------------------------------------
  update(editorView, node) {
    if (node.type !== this._nodeType) return false;

    let { attrs: { src, width } } = node;

    if (this._resizedWidth) width = this._resizedWidth;

    // We want to keep using the last source width we had, as it allows us to re-load media when transitioning from
    // local-only to remotely-persisted without a flicker of no size being set (particularly noticeable with videos,
    // as they can take a bit longer to load initially)
    if (width === null && !this._resizing && this._naturalWidth !== null) {
      width = this._naturalWidth;
    }

    if (width !== null) {
      this._contentElement.style.height = null;
    }

    const uploading = isLocalFileURL(src);
    const failed = isFailedLocalFileURL(src);

    if (uploading) {
      // This just forces a fallback to a placeholder
      src = "";

      const localFileMetadata = getLocalFileMetadata(editorView.state, node.attrs.src);
      if (localFileMetadata) {
        const { url: localSrc, width: localWidth, height: localHeight } = localFileMetadata;

        if (localSrc) src = localSrc;
        if (!width) {
          if (localHeight) this._contentElement.style.height = `${ localHeight }px`;
          if (localWidth) width = localWidth;
        }
      }
    }

    this._setContentElementWidth(width);

    if (failed) this._container.classList.add("failed");
    else this._container.classList.remove("failed");

    if (uploading && !failed) this._container.classList.add("uploading");
    else this._container.classList.remove("uploading");

    this._onUpdateCallback(node, src, { failed, uploading });

    return true;
  }

  // --------------------------------------------------------------------------
  _clampWidth = width => {
    if (width === null) return width;

    if (this._naturalWidth !== null) width = Math.min(width, this._naturalWidth);
    return Math.max(width, MIN_CONTENT_WIDTH);
  };

  // --------------------------------------------------------------------------
  _getNode = (editorView, getPos) => {
    const { state: { doc } } = editorView;

    let node;
    try {
      node = doc.nodeAt(getPos());
    } catch (_error) {
      // Can throw a `RangeError: Index N out of range for ...` in `nodeAt`
      return null;
    }

    return (node && node.type === this._nodeType) ? node : null;
  };

  // --------------------------------------------------------------------------
  _onContentElementWidthSet = () => {
    if (!this._resizeHandle) return;

    const { height } = this._contentElement.getBoundingClientRect();
    if (height) {
      if (height < 48) {
        this._resizeHandle.classList.add("hidden");
      } else {
        this._resizeHandle.classList.remove("hidden");
      }

      if (height < 72) {
        this._resizeHandle.classList.add("short");
      } else {
        this._resizeHandle.classList.remove("short");
      }
    } else {
      this._resizeHandle.classList.remove("hidden");
      this._resizeHandle.classList.remove("short");
    }
  };

  // --------------------------------------------------------------------------
  _onHandleDragStart = (view, getPos, event) => {
    event.preventDefault();

    this._resizing = true;
    this._container.classList.add("resizing");

    const startX = pageXFromDragEvent(event);
    const startWidth = this._contentElement.getBoundingClientRect().width;

    const onDragMove = dragMoveEvent => {
      dragMoveEvent.preventDefault();

      const x = pageXFromDragEvent(dragMoveEvent);
      const diffX = x - startX;

      this._resizedWidth = this._clampWidth(startWidth + diffX);
      this._setContentElementWidth(this._resizedWidth);

      // We want to read back the _actual_ width the content is set to, as it might be limited by the size of the
      // editor, even though we've attempted to make it larger
      this._resizedWidth = this._contentElement.getBoundingClientRect().width;
      this._setContentElementWidth(this._resizedWidth);
    };

    const onDragEnd = dragEndEvent => {
      dragEndEvent.preventDefault();

      document.removeEventListener("mousemove", onDragMove);
      document.removeEventListener("mouseup", onDragEnd);

      document.removeEventListener("touchmove", onDragMove);
      document.removeEventListener("touchcancel", onDragEnd);
      document.removeEventListener("touchend", onDragEnd);

      this._container.classList.remove("resizing");
      this._resizing = false;

      const width = this._clampWidth(this._resizedWidth);
      this._resizedWidth = null;
      this._setWidth(width);
    };

    document.addEventListener("mousemove", onDragMove);
    document.addEventListener("mouseup", onDragEnd);

    document.addEventListener("touchmove", onDragMove);
    document.addEventListener("touchcancel", onDragEnd);
    document.addEventListener("touchend", onDragEnd);
  };

  // --------------------------------------------------------------------------
  _setContentElementWidth = width => {
    if (width !== null) {
      this._contentElement.style.width = `${ width }px`;
    } else {
      this._contentElement.style.width = null;
    }

    this._onContentElementWidthSet();
  };

  // --------------------------------------------------------------------------
  _setWidth = (editorView, getPos, width) => {
    if (width === null) return;

    const nodePos = getPos();
    const node = this._getNode();
    if (!node) return;

    const transform = editorView.state.tr;
    transform.setNodeMarkup(nodePos, null, { ...node.attrs, width });
    transform.setSelection(NodeSelection.create(transform.doc, nodePos));
    editorView.dispatch(transform);
  };
}

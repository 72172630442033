import { formatDistanceToNow } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import { hasModKeyOnly } from "lib/ample-editor/lib/event-util"
import { amplenoteParamsFromURL, urlFromAmplenoteParams } from "lib/ample-util/amplenote-area"
import { formatDate, formatTime } from "lib/ample-util/date"

// --------------------------------------------------------------------------
export const INLINE_DETAILS_TYPE = {
  CREATED: "created",
  SCHEDULED_DATETIME: "scheduled-datetime",
  SCHEDULED_TIME: "scheduled-time",
};

// --------------------------------------------------------------------------
export function createInlineDetails(wrapper, editorView) {
  const { props: { hostApp: { getTaskNote } } } = editorView;

  const inlineDetails = document.createElement("div");
  inlineDetails.className = "inline-details";
  inlineDetails.contentEditable = "false";
  wrapper.appendChild(inlineDetails);

  wrapper.classList.add("has-inline-details");
  if (getTaskNote) wrapper.classList.add("with-note-link");

  return inlineDetails;
}

// --------------------------------------------------------------------------
export default function InlineDetails({ attrs, note, openNoteLink, type, uuid }) {
  const { createdAt, due } = attrs;

  let noteLink = null;
  if (note) {
    const { name, url } = note;

    const onClick = event => {
      if (openNoteLink) {
        event.preventDefault();

        // Add highlightTaskUUID to the note URL
        const amplenoteParams = amplenoteParamsFromURL(url);
        if (amplenoteParams && amplenoteParams.note) {
          amplenoteParams.note = { ...amplenoteParams.note, highlightTaskUUID: uuid };
        }

        const noteURL = urlFromAmplenoteParams(amplenoteParams) || url;
        openNoteLink(noteURL, { openInSidebar: hasModKeyOnly(event) });
      }
    };

    noteLink = (<a className="note-link" href={ url } onClick={ onClick }>{ name }</a>);
  }

  let detail = null;
  switch (type) {
    case INLINE_DETAILS_TYPE.SCHEDULED_DATETIME:
      if (due) {
        detail = (<span>Scheduled for { formatDate(due * 1000, { short: true, strict: true }) } at { formatTime(due * 1000) }</span>);
      } else {
        detail = (<span className="de-emphasized">Unscheduled</span>);
      }
      break;

    case INLINE_DETAILS_TYPE.SCHEDULED_TIME:
      if (due) {
        detail = (<span>Scheduled for { formatTime(due * 1000) }</span>);
      } else {
        detail = (<span className="de-emphasized">Unscheduled</span>);
      }
      break;

    default:
    case INLINE_DETAILS_TYPE.CREATED: {
      const relativeCreatedAt = createdAt
        ? formatDistanceToNow(createdAt * 1000, { addSuffix: true })
        : "a while back";

      detail = (<span>Created { relativeCreatedAt }</span>);
      break;
    }
  }

  return (
    <React.Fragment>
      { noteLink }
      { detail }
    </React.Fragment>
  );
}

InlineDetails.propTypes = {
  attrs: PropTypes.shape({
    createdAt: PropTypes.number,
    due: PropTypes.number,
  }).isRequired,
  note: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  openNoteLink: PropTypes.func,
  type: PropTypes.oneOf(Object.values(INLINE_DETAILS_TYPE)).isRequired,
  uuid: PropTypes.string,
};

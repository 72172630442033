import { closeHistory } from "prosemirror-history"
import { Fragment } from "prosemirror-model"
import { v4 as uuidv4 } from "uuid"

import findCheckListItem from "lib/ample-editor/lib/find-check-list-item"
import findSectionPositions from "lib/ample-editor/util/find-section-positions";

// --------------------------------------------------------------------------
export default function buildInsertNodes(nodes, { atEnd = false, keepUUIDs = false, section = null } = {}) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const nodesToInsert = [];

    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];

      if (!node) continue;

      if (node.type === "check_list_item") {
        const taskUUID = node.attrs ? node.attrs.uuid : null;

        // Don't add the task if it's already in the note
        if (taskUUID && findCheckListItem(doc, taskUUID)) continue;

        node = {
          ...node,
          attrs: {
            ...node.attrs,
            uuid: (keepUUIDs ? taskUUID : null) || uuidv4()
          },
        };
      }

      nodesToInsert.push(node);
    }

    if (nodesToInsert.length === 0) return false;

    const fragment = Fragment.fromJSON(schema, nodesToInsert);

    if (dispatch) {
      let insertPos = null;
      if (section) {
        const { startPos } = findSectionPositions({ heading: { anchor: section }, index: 0 }, state);
        if (startPos > -1) {
          insertPos = startPos;
        }
      }

      if (insertPos === null) {
        insertPos = atEnd ? state.doc.content.size : 0;
      }

      dispatch(closeHistory(state.tr).insert(insertPos, fragment));
    }

    return true;
  }
}

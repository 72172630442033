// --------------------------------------------------------------------------
const findProseMirrorAncestor = domNode => {
  if (!domNode) return null;

  if (domNode.classList && domNode.classList.contains("ProseMirror")) {
    return domNode;
  }

  return findProseMirrorAncestor(domNode.parentNode);
};
export default findProseMirrorAncestor;

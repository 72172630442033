import { selectAll } from "prosemirror-commands"
import { GapCursor } from "prosemirror-gapcursor"
import { redo, undo } from "prosemirror-history"
import { DOMSerializer } from "prosemirror-model"
import { NodeSelection, Selection, TextSelection } from "prosemirror-state"

import {
  CODE_BLOCK_COPY_ICON_CLASS,
  copyIconTitle,
  LANGUAGE,
  languageFromText
} from "lib/ample-editor/lib/code-block/code-block-util"
import { selectThroughCodeMirrorBoundary } from "lib/ample-editor/lib/code-block-commands"
import importRetry from "lib/ample-editor/lib/import-retry"
import { cellFromPos, cellFromTable, tableFromPos } from "lib/ample-editor/lib/table/table-util"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

const MILLISECONDS_TO_SHOW_COPY_RESULT = 5000;

// --------------------------------------------------------------------------
function importCodeMirrorEditor() {
  return importRetry(() => import(/* webpackChunkName: "code-mirror-editor" */ "lib/ample-editor/lib/code-block/code-mirror-editor"));
}

// --------------------------------------------------------------------------
export default class CodeBlockView {
  _clearCopyIconResult = false;
  _copiedTimer = null;
  _editorView = null;
  _getPos = null;
  _languageEm = LANGUAGE.JAVASCRIPT;
  _node = null;
  _nodeType = null;
  _noEscape = null;
  _setEditorLanguage = null;
  _updating = null;

  // --------------------------------------------------------------------------
  constructor(node, editorView, getPos) {
    this._node = node;
    this._editorView = editorView;
    this._getPos = getPos;
    this._languageEm = node.attrs.language || LANGUAGE.JAVASCRIPT;
    this.dom = document.createElement("div"); // Placeholder until CodeMirror is loaded
    this.dom.className = this._classNameWithLanguage(this._languageEm);
    if (this.dom.dataset) this.dom.dataset.language = this._languageEm;

    this._nodeType = node.type;
    this._updating = false; // This flag is used to avoid an update loop between the outer and inner editor

    // Promises are used by tests that need to ensure the cmEditor has loaded before running asserts
    this.lazyCodeMirrorLoadedPromise = importCodeMirrorEditor().then(module => {
      const { createCodeMirrorEditor, loadLanguageModule, setEditorLanguage } = module;

      this._setEditorLanguage = setEditorLanguage;

      return loadLanguageModule(this._languageEm).then(languageModule => {
        const readonly = !editorView.editable;
        const codeText = this._node && this._node.textContent;
        const codeMirrorEditorModule = createCodeMirrorEditor(codeText, editorView, this, this._serializeCodeMirror,
          { readonly, languageModule }
        );

        this._onLoadCodeMirrorModule(codeMirrorEditorModule)
      });
    });
  }

  // --------------------------------------------------------------------------
  _onLoadCodeMirrorModule(codeMirrorEditor) {
    this.codeMirrorEditor = codeMirrorEditor;

    // The editor's outer node is our DOM representation
    this.dom.appendChild(this.codeMirrorEditor.dom);

    const copyIcon = document.createElement("div");
    copyIcon.className = CODE_BLOCK_COPY_ICON_CLASS;
    copyIcon.setAttribute("title", copyIconTitle(this._languageEm));
    copyIcon.addEventListener("click", this._copyCode.bind(this));
    this.dom.appendChild(copyIcon);

    if (this._editorView.editable && this._editorView.hasFocus()) {
      const { state: { selection: { $head } } } = this._editorView;
      if ($head && Number.isInteger(this._getPos()) && (this._getPos() + 1 === $head.start())) {
        this.codeMirrorEditor.focus();
      }
    }

    this.update(this._node);
  }

  // --------------------------------------------------------------------------
  update(node) {
    if (node.type !== this._nodeType) return false;
    this._node = this._getNode(this._editorView, this._getPos);
    if (this._updating) return true;

    if (this.codeMirrorEditor) {
      this._propagateTextUpdateToCodeMirror(node);

      if (this._languageEm !== node.attrs.language) {
        this._languageEm = node.attrs.language;
        if (this.dom.dataset) this.dom.dataset.language = this._languageEm;
        this.dom.className = this._classNameWithLanguage(this._languageEm);
        if (this._setEditorLanguage) this._setEditorLanguage(this._languageEm, this._editorView, this);
      }
    }

    if (this._clearCopyIconResult) {
      this._clearCopyIconResult = false;
      const copyIcon = this.dom.querySelector(`.${ CODE_BLOCK_COPY_ICON_CLASS }`);
      copyIcon.setAttribute("title", copyIconTitle(this._languageEm));
      copyIcon.classList.remove("copy-success");
      copyIcon.classList.remove("copy-failure");
    }

    return !!this.codeMirrorEditor;
  }

  // --------------------------------------------------------------------------
  // Used when Prosemirror has handled an update that ends with cursor being in the CodeMirror block,
  // in which case we need to focus CM so cursor is visible (i.e., when pressing up in a code block atop doc)
  setSelection(anchor, head) {
    if (!this.codeMirrorEditor || !this._editorView.editable) return;
    this.codeMirrorEditor.focus();
    this.dispatchCodeMirrorTransaction({ selection: { anchor, head } });
  }

  // --------------------------------------------------------------------------
  // Check whether this arrow press departs from CodeMirror block, and pick a pos if so
  maybeEscape(unit, dir) {
    const { state: cmState } = this.codeMirrorEditor;
    let { main } = cmState.selection;
    if (!main.empty) return false;
    if (unit === "line") {
      main = cmState.doc.lineAt(main.head);
    }
    if (dir < 0 ? main.from > 0 : main.to < cmState.doc.length) return false;
    const { state, state: { selection: { $head } } } = this._editorView;
    let targetPos;
    const $cell = cellFromPos($head);
    if ($cell && unit === "line") {
      const $table = tableFromPos($cell);
      const rowIndex = $cell.index($cell.depth - 1);
      const $nextCell = cellFromTable($table, rowIndex + dir, $cell.index());
      targetPos = $nextCell && $nextCell.pos + 2; // + 2 to get past the cell and its paragraph
    }
    if (!targetPos) {
      targetPos = this._getPos() + (dir < 0 ? 0 : this._node.nodeSize);
    }
    const selection = Selection.near(state.doc.resolve(targetPos), dir);
    const transform = state.tr;
    if ($head && selection.$head.pos === $head.pos) {
      const gapCursor = new GapCursor(state.doc.resolve(targetPos));
      transform.setSelection(gapCursor);
    } else {
      transform.setSelection(selection);
    }
    this._editorView.dispatch(transform.scrollIntoView());
    this._editorView.focus();
  }

  // --------------------------------------------------------------------------
  handleEscapeKeypress() {
    const { state: { selection: { $head } } } = this._editorView;
    if ($head && Number.isInteger(this._getPos()) && (this._getPos() + 1 === $head.start())) {
      const { state } = this._editorView;
      const transform = state.tr;
      this._editorView.dispatch(transform.setSelection(NodeSelection.create(state.doc, $head.before())).scrollIntoView());
      this._editorView.focus();
      return true;
    }
  }

  // --------------------------------------------------------------------------
  shiftArrow(direction, selectLineCommand) {
    const { dispatch, state } = this._editorView;

    const crossedBlock = selectThroughCodeMirrorBoundary(direction)(state, dispatch);
    if (crossedBlock && this.codeMirrorEditor) {
      this.dispatchCodeMirrorTransaction({ selection: { anchor: 0, head: 0 } });
      this._editorView.focus();
    } else {
      selectLineCommand(this.codeMirrorEditor);
    }

    return true;
  }

  // --------------------------------------------------------------------------
  destroy() {
    if (this._isCodeMirrorActiveElement()) {
      // ProseMirror loses focus without this because CodeMirror is a contenteditable div wrapped in a non-contenteditable
      // container. When the last vestige of CM's inner contenteditable island is removed, Chrome's assumes that
      // the editing is complete and returns `document.activeElement` to equal `body`
      this._editorView.focus();
    }

    if (this.codeMirrorEditor) {
      this.codeMirrorEditor.destroy();
    }

    clearTimeout(this._copiedTimer);
  }

  // --------------------------------------------------------------------------
  insertLineWithoutEscape(insertLineCommand) {
    this._noEscape = true;
    insertLineCommand(this.codeMirrorEditor);
    this._noEscape = false;
  }

  // --------------------------------------------------------------------------
  progressiveSelectAll() {
    if (!this.codeMirrorEditor) return false;
    const { state: { selection: codemirrorSelection } } = this.codeMirrorEditor;
    const range = codemirrorSelection.ranges[codemirrorSelection.mainIndex];
    if (!Number.isInteger(range.from) || !Number.isInteger(range.to)) return false;
    if ((range.to - range.from) < this._node.content.size) return false;

    // Entire CM block is already selected. If user is still pressing Cmd-A, safe to imagine they want the
    // entire doc selected
    this.dispatchCodeMirrorTransaction(null, {
      dispatchCallback: () => {
        const { dispatch, state } = this._editorView;
        this.codeMirrorEditor.dispatch({ selection: { anchor: 0, head: 0 } });
        selectAll(state, dispatch);
      }
    });

    // If PM view isn't focused, we won't be able to delete the range
    this._editorView.focus();

    return true;
  }

  // --------------------------------------------------------------------------
  jumpToHome(withSelection) {
    return this._jumpToDirection("home", withSelection);
  }

  // --------------------------------------------------------------------------
  jumpToEnd(withSelection) {
    return this._jumpToDirection("end", withSelection);
  }

  // --------------------------------------------------------------------------
  undo() {
    undo(this._editorView.state, tr => this._editorView.dispatch(tr.setMeta(TRANSACTION_META_KEY.CODE_BLOCK_UNDO, true)));
    return true;
  }

  // --------------------------------------------------------------------------
  redo() {
    redo(this._editorView.state, tr => this._editorView.dispatch(tr.setMeta(TRANSACTION_META_KEY.CODE_BLOCK_REDO, true)));
    return true;
  }

  // --------------------------------------------------------------------------
  dispatchCodeMirrorTransaction = (dispatchParams, { dispatchCallback = null } = {}) => {
    if (this._updating) return;

    this._updating = true;
    try {
      if (dispatchCallback) {
        dispatchCallback();
      } else {
        this.codeMirrorEditor.dispatch(dispatchParams);
      }
    } finally {
      this._updating = false;
    }
  }

  // --------------------------------------------------------------------------
  // Private methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  _classNameWithLanguage(language) {
    return `code-block language-${ language || "unknown" }`
  }

  // --------------------------------------------------------------------------
  // Propagate update(s) from CodeMirror to be dispatched to ProseMirror
  // Note that as of December 2022 it doesn't propagate changes that only affect the selection, based on the assumption
  // that PM shouldn't care/need to know about what selection is doing inside CM
  // `update`: A ViewUpdate object passed by the CM #updateListener method
  //           https://codemirror.net/docs/ref/#view.ViewUpdate
  _forwardUpdateToProsemirror(update) {
    if (this._updating) return;

    // `main` is a SelectionRange from CM (where it is possible to have multiple selection ranges)
    const { main } = update.state.selection;

    if (!this.codeMirrorEditor.hasFocus) {
      if (this._editorView.hasFocus()) {
        // When the CM editor is blurred, it might still have an expanded selection that shows as highlighted, in which
        // case we want to un-highlight it (by collapsing the selection)
        const { anchor, head } = main;
        if (anchor !== head) this.dispatchCodeMirrorTransaction({ selection: { anchor: head, head } });
      }

      return;
    }

    let offset = this._getPos() + 1;
    const startingOffset = offset;

    const { state } = this._editorView;
    const transform = state.tr;
    let leavesBlock = false;
    if (update.docChanged) {
      // fromA/toA provides the extent of the change in the starting document, fromB/toB the extent of the replacement in the changed document
      update.changes.iterChanges((fromA, toA, fromB, toB, text) => {
        if (leavesBlock) return;
        if (state.selection.$head && this._forwardUpdateLeavesCodeBlock(fromA, toA, fromB, toB, text)) {
          this._escapeCodeBlock(state, transform, fromB, toB);
          leavesBlock = true;
        } else if (text.length) {
          transform.replaceWith(offset + fromA, offset + toA, state.schema.text(text.toString()));
        } else {
          transform.delete(offset + fromA, offset + toA);
        }
        offset += (toB - fromB) - (toA - fromA);
      });
    }

    if (!leavesBlock) {
      const selection = TextSelection.create(transform.doc, startingOffset + main.anchor, startingOffset + main.head);
      if (!this._editorView.state.selection.eq(selection)) {
        transform.setSelection(selection);
      }
    }

    if (!leavesBlock && update.docChanged) {
      const $codePos = transform.doc.resolve(startingOffset + main.head);
      if ($codePos.parent && $codePos.parent.type.spec.code) {
        const codeContent = $codePos.parent.textContent;
        const firstNewLineIndex = codeContent.indexOf("\n");
        if ($codePos.parentOffset <= firstNewLineIndex || firstNewLineIndex === -1) {
          const firstLine = firstNewLineIndex === -1 ? codeContent : codeContent.substr(0, firstNewLineIndex);
          const languageEm = languageFromText(firstLine);

          if (languageEm && languageEm !== this._languageEm) {
            transform.setNodeAttribute(this._getPos(), "language", languageEm);
          }
        }
      }
    }

    this._editorView.dispatch(transform);
    if (leavesBlock && this._isCodeMirrorActiveElement()) {
      this._editorView.focus();
    }
  }

  // --------------------------------------------------------------------------
  _escapeCodeBlock(state, transform, fromB, toB) {
    transform.delete(this._getPos() + fromB, this._getPos() + toB); // Delete the Enter that CM added
    const $codeBlock = transform.doc.resolve(this._getPos());
    const parentNode = $codeBlock.parent;
    const insertPos = this._getPos() + this._node.nodeSize - (toB - fromB);
    let selection;
    if (parentNode && parentNode.type.spec.isListItem) {
      transform.insert(insertPos, parentNode.type.createAndFill());
      // + 2 to account for the opening position of the new list node
      selection = Selection.near(transform.doc.resolve(insertPos + 2));
    } else {
      transform.insert(insertPos, state.schema.nodes.paragraph.createAndFill());
      selection = Selection.near(transform.doc.resolve(insertPos + 1));
    }

    transform.setSelection(selection);
  }

  // --------------------------------------------------------------------------
  _forwardUpdateLeavesCodeBlock(fromA, toA, fromB, toB, textLeaf) {
    if (this._noEscape) return false;
    const isEnterPress = (fromA === fromB && toB === toA + 1 && textLeaf.text && textLeaf.text.length === 2);
    if (!isEnterPress || !fromA || !this._node) return false;

    const previousCharacter = this._node.textBetween(fromA - 1, fromA);
    return previousCharacter === "\n" && this._node.content.size === fromA;
  }

  // --------------------------------------------------------------------------
  // Lifted from MediaView._getPos. If tempted to use this elsewhere, let's DRY it eh?
  _getNode = (editorView, getPos) => {
    const { state: { doc } } = editorView;

    let node;
    try {
      node = doc.nodeAt(getPos());
    } catch (_error) {
      // Can throw a `RangeError: Index N out of range for ...` in `nodeAt`
      return null;
    }

    return (node && node.type === this._nodeType) ? node : null;
  };

  // --------------------------------------------------------------------------
  _serializeCodeMirror = (event, codeMirrorView) => {
    const data = event.clipboardData;

    if (data) {
      const { state: { selection: codeMirrorSelection } } = codeMirrorView;
      const range = codeMirrorSelection.ranges[codeMirrorSelection.mainIndex];
      const { state, state: { doc } } = this._editorView;
      const codeText = doc.textBetween(this._getPos() + range.from + 1, this._getPos() + range.to + 1);
      const fragment = doc.content.cut(this._getPos() + range.from + 1, this._getPos() + range.to + 1);
      const domSerializer = DOMSerializer.fromSchema(state.schema);
      const htmlFragment = domSerializer.serializeFragment(fragment);

      const preEl = htmlFragment.querySelector("pre");
      // WBH Added this check based on a single exception observed in November 2022 wherein htmlFragment.querySelector("pre")
      // was null for reasons that could not be reproduced in a few minutes trying. In 99.9% of cases, we expect
      // preEl to exist. But if it doesn't, codeText might? And if neither do, we won't bother preventDefaulting this event
      if ((codeText && codeText.length) || preEl) {
        const html = preEl ? preEl.outerHTML : `<pre>${ codeText }</pre>`;
        event.preventDefault();
        data.clearData();
        data.setData("text/html", html);
        data.setData("text/plain", codeText);
      } else {
        // eslint-disable-next-line no-console
        console.error("Failed to serialize CodeMirror content to clipboard");
      }
    }
  };

  // --------------------------------------------------------------------------
  // `homeOrEnd` a string "home" or "end"
  // `withSelection` bool for whether we should extend selection through range
  _jumpToDirection = (homeOrEnd, withSelection) => {
    if (!this.codeMirrorEditor) return false;
    const { state: { selection: codemirrorSelection } } = this.codeMirrorEditor;
    const range = codemirrorSelection.ranges[codemirrorSelection.mainIndex];
    if (!Number.isInteger(range.head)) return false;
    const currentLine = this.codeMirrorEditor.state.doc.lineAt(range.head);
    const scrollTo = homeOrEnd === "home" ? "start" : "end";
    let destinationPos;
    if (homeOrEnd === "home") {
      const linePos = Math.min(range.from, range.to) - currentLine.from;
      const whitespaceMatch = currentLine.text.match(/^[\s]+/);
      const lineWhitespace = whitespaceMatch ? whitespaceMatch[0].length : 0;
      if (linePos <= lineWhitespace) {
        destinationPos = currentLine.from;
      } else {
        destinationPos = currentLine.from + lineWhitespace; // Jump to start of line text before jumping to left boundary
      }
    } else {
      destinationPos = currentLine.to;
    }
    const selection = { anchor: withSelection ? range.anchor : destinationPos, head: destinationPos };

    this.codeMirrorEditor.focus();
    this.dispatchCodeMirrorTransaction({
      scrollIntoView: { x: scrollTo },
      selection,
    });

    return true;
  }

  // --------------------------------------------------------------------------
  // Used as precursor before jumping focus from CM's inner contenteditable div back to PM's outer contenteditable div
  _isCodeMirrorActiveElement() {
    if (window && this.codeMirrorEditor) {
      return window.document.activeElement === this.codeMirrorEditor.dom.querySelector("div[contenteditable]");
    } else {
      return false;
    }
  }

  // --------------------------------------------------------------------------
  _propagateTextUpdateToCodeMirror(node) {
    const newText = node.textContent;
    const currentText = this.codeMirrorEditor.state.doc.toString();

    // If ProseMirror's textContent doesn't match CodeMirror's, dispatch the missing characters to CodeMirror
    if (newText !== currentText) {
      let start = 0;
      let currentEnd = currentText.length;
      let newEnd = newText.length;
      while (start < currentEnd && currentText.charCodeAt(start) === newText.charCodeAt(start)) {
        ++start;
      }
      while (currentEnd > start && newEnd > start &&
      currentText.charCodeAt(currentEnd - 1) === newText.charCodeAt(newEnd - 1)) {
        currentEnd--;
        newEnd--;
      }

      this.dispatchCodeMirrorTransaction({ changes: { from: start, insert: newText.slice(start, newEnd), to: currentEnd } });
    }
  }

  // --------------------------------------------------------------------------
  async _copyCode(event) {
    if (!this.codeMirrorEditor) return false;
    const text = this.codeMirrorEditor.state.doc.toString();
    const clickedEl = event.currentTarget;
    try {
      await navigator.clipboard.writeText(text);
      clickedEl.classList.add("copy-success");
      clickedEl.setAttribute("title", "Copied!");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Failed to copy code to clipboard", err);
      clickedEl.classList.add("copy-fail");
    }

    // In case another countdown had been in progress, it's superseded by this one
    clearTimeout(this._copiedTimer);

    this._copiedTimer = setTimeout(() => {
      this._clearCopyIconResult = true;
      this.update(this._node);
    }, MILLISECONDS_TO_SHOW_COPY_RESULT);
  }
}

import { Fragment, Slice } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

// --------------------------------------------------------------------------
// We want to be able to change the src of an image node without adding that change to history, so it can't be undone
// and - even better - the undo removes the image (if it was the last action) and a redo of that removal re-adds the
// image with the _updated_ src.
//
// Based on https://discuss.prosemirror.net/t/preventing-image-placeholder-replacement-from-being-undone/1394
export default class SetAttrsStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    if (typeof json.pos !== "number" || (json.attrs !== null && typeof json.attrs !== "object")) {
      throw new RangeError("Invalid input for SetAttrsStep.fromJSON");
    }

    return new SetAttrsStep(json.pos, json.attrs);
  }

  // --------------------------------------------------------------------------
  constructor(pos, attrs) {
    super();
    this.pos = pos;
    this.attrs = attrs;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const target = doc.nodeAt(this.pos);
    if (!target) return StepResult.fail("No node at given position");

    // Calling `create` for a text node will throw `NodeType.create can't construct text nodes` - as of 8/2022 it's
    // unclear why this would be called with a text node, but has happened in the wild (called via `undo` command).
    if (target.type.isText) return StepResult.fail("Can't construct text nodes");

    const newNode = target.type.create(this.attrs, Fragment.empty, target.marks);
    const slice = new Slice(Fragment.from(newNode), 0, target.isLeaf ? 0 : 1);
    return StepResult.fromReplace(doc, this.pos, this.pos + 1, slice);
  }

  // --------------------------------------------------------------------------
  invert(doc) {
    const target = doc.nodeAt(this.pos);
    return new SetAttrsStep(this.pos, target ? target.attrs : null);
  }

  // --------------------------------------------------------------------------
  map(mapping) {
    const pos = mapping.mapResult(this.pos, 1);
    return pos.deleted ? null : new SetAttrsStep(pos.pos, this.attrs);
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return { stepType: "setAttrs", pos: this.pos, attrs: this.attrs };
  }
}
Step.jsonID("setAttrs", SetAttrsStep);

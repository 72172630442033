import { closeHistory } from "prosemirror-history"

import { setExpandedTaskUUID } from "lib/ample-editor/plugins/check-list-item-plugin"
import RemoveHiddenTaskStep from "lib/ample-editor/steps/remove-hidden-task-step"

// --------------------------------------------------------------------------
export default function buildRemoveNodes(uuids) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const nodesToRemove = [];

    doc.forEach((node, pos) => {
      const { attrs } = node;

      if ("uuid" in attrs && uuids.includes(attrs.uuid)) {
        nodesToRemove.push({ node, nodePos: pos });
        uuids = uuids.filter(uuid => uuid !== attrs.uuid);
      }
    });

    const hiddenTasks = doc.attrs.hiddenTasks || [];
    for (let i = 0; i < hiddenTasks.length; i++) {
      const hiddenTask = hiddenTasks[i];
      if (!hiddenTask.attrs) continue;

      const { attrs: { uuid } } = hiddenTask;
      if (uuids.includes(uuid)) {
        nodesToRemove.push({ hiddenTask });
      }
    }

    if (dispatch) {
      const transaction = closeHistory(state.tr);

      nodesToRemove.reverse().forEach(({ hiddenTask, node, nodePos }) => {
        if (hiddenTask) {
          transaction.step(new RemoveHiddenTaskStep(schema, hiddenTask));
        } else {
          transaction.delete(
            transaction.mapping.map(nodePos),
            transaction.mapping.map(nodePos + node.nodeSize)
          );

          setExpandedTaskUUID(transaction, null);
        }
      });

      dispatch(transaction);
    }

    return true;
  }
}

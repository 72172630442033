import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { parseMarkdown } from "lib/ample-editor/lib/markdown-parser"
import markdownSerializer from "lib/ample-editor/lib/markdown-serializer"

// --------------------------------------------------------------------------
export default function MarkdownDemo({ document, replaceDocument }) {
  const [ markdown, setMarkdown ] = useState("");

  const textareaRef = useRef(null);
  const pendingSelectionRef = useRef(null);

  // When setting the document from markdown, serializing back to markdown will escape delimiter characters that
  // aren't completed (e.g. "*blah" will be serialized as "\*blah" since the asterisk isn't closed with a second),
  // so we'd prefer to just keep using the raw value that we have in the textarea when receiving a document that
  // we've just set based on it.
  const lastDocumentFromMarkdownRef = useRef(null);

  useEffect(
    () => {
      if (document === lastDocumentFromMarkdownRef.current) return;

      const newMarkdown = document ? markdownSerializer.serialize(document) : "";
      setMarkdown(newMarkdown);
    },
    [ document ]
  );

  const onChange = useCallback(
    event => {
      const { target: { selectionEnd, selectionStart, value: newValue } } = event;
      pendingSelectionRef.current = { selectionEnd, selectionStart };

      setMarkdown(newValue);

      const { document: newDocument } = parseMarkdown(newValue);
      lastDocumentFromMarkdownRef.current = newDocument;
      replaceDocument(newDocument);
    },
    [ replaceDocument ]
  );

  useEffect(
    () => {
      const { current: textarea } = textareaRef;

      // Auto resize height to match content
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";

      // Restore selection after re-render
      const { current: pendingSelection } = pendingSelectionRef;
      if (pendingSelection) {
        const { selectionEnd, selectionStart } = pendingSelection;
        textarea.setSelectionRange(selectionStart, selectionEnd);
        pendingSelectionRef.current = null;
      }
    },
    [ markdown ]
  );

  return (
    <textarea
      className="markdown-demo"
      onChange={ onChange }
      ref={ textareaRef }
      value={ markdown }
    />
  );
}

MarkdownDemo.propTypes = {
  document: PropTypes.object.isRequired,
  replaceDocument: PropTypes.func.isRequired,
};

import React from "react"

import TooltipIcon from "lib/ample-editor/components/tooltip-icon"
import { SUPPORTED_ATTACHMENT_CONTENT_TYPES } from "lib/ample-editor/lib/filter-files"

// --------------------------------------------------------------------------
export default class ToolbarAttachmentButton extends React.Component {
  // --------------------------------------------------------------------------
  shouldComponentUpdate(nextProps) {
    return nextProps.isActive !== this.props.isActive;
  }

  // --------------------------------------------------------------------------
  render() {
    const { isActive } = this.props;

    return (
      <label className="toolbar-attachment-button">
        <input
          accept={ SUPPORTED_ATTACHMENT_CONTENT_TYPES.join(",") }
          onChange={ this._onFileInputChange }
          type="file"
        />

        <span className="button" data-active={ isActive }>
          <TooltipIcon icon="attach_file" tooltip="Attach a file" />
        </span>
      </label>
    );
  }

  // --------------------------------------------------------------------------
  _onFileInputChange = event => {
    if (event.target.files.length === 0) return;

    this.props.insertAttachment(event.target.files[0]);

    event.target.value = null;
  };
}

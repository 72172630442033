import memoize from "memoize-one"
import PropTypes from "prop-types"
import React from "react"

import NextInstanceInput from "lib/ample-editor/components/task-detail/next-instance-input"
import TextInputWithSuggestions from "lib/ample-editor/components/task-detail/text-input-with-suggestions"
import TimeInput from "lib/ample-editor/components/task-detail/time-input"
import {
  dateSuggestionsFromText,
  DURATION_ANCHOR_NAME,
  DURATION_ANCHOR_RELATION,
  SAME_DAY_DURATION,
  sameDayText,
} from "lib/ample-editor/components/task-detail/util"
import durationFromText from "lib/ample-editor/lib/duration-from-text"
import {
  adjustDurationTime,
  dateTextFromDuration,
  durationFromTimestamp,
  isLessThanDailyDuration,
  minutesFromDuration,
  shortTimeTextFromDuration,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export default class DurationInput extends React.PureComponent {
  // --------------------------------------------------------------------------
  static propTypes = {
    anchorName: PropTypes.oneOf(Object.values(DURATION_ANCHOR_NAME)).isRequired,
    anchorRelation: PropTypes.oneOf(Object.values(DURATION_ANCHOR_RELATION)).isRequired,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    duration: PropTypes.string,
    expectedNextInstanceAt: PropTypes.number,
    nextInstanceAt: PropTypes.number,
    onFocus: PropTypes.func,
    setDuration: PropTypes.func.isRequired,
    setNextInstance: PropTypes.func,
  };

  // --------------------------------------------------------------------------
  state = {
    dateInputText: null,
    focused: false,
  };

  _dateTimeInputContainer = null;
  _resetFocusTimeout = null;

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    clearTimeout(this._resetFocusTimeout);
  }

  // --------------------------------------------------------------------------
  render() {
    const { nextInstanceAt, duration } = this.props;
    const { focused } = this.state;

    const nextInstance = nextInstanceAt ? new Date(nextInstanceAt * 1000) : null;

    const isValid = duration && duration.length > 0;

    let className = `duration-input ${ isValid ? "valid" : "invalid" }`;
    if (focused) className += " focused";

    return (
      <div className={ className }>
        <div
          className="date-time-input-container repeating-input-container"
          onBlur={ this._onBlur }
          onFocus={ this._onFocus }
          ref={ this._setDateTimeInputContainer }
        >
          { this._renderDateInput(nextInstance) }
          { this._renderTimeInput() }
        </div>
        { this._renderNextInstance(nextInstance) }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _memoizedDateSuggestions = memoize((anchorName, anchorRelation, dateInputText, selectedDuration) => {
    return dateSuggestionsFromText(anchorName, anchorRelation, dateInputText).map(({ duration, text }) => {
      const selected = duration === selectedDuration || !!(selectedDuration && selectedDuration.startsWith(duration + "T"));
      return { selected, text, value: duration };
    });
  });

  // --------------------------------------------------------------------------
  _onBlur = () => {
    // When shifting focus from the date input to the time input, there will be a single render where nothing is
    // focused, which will switch the time input back to hidden, preventing it from focusing. To let it focus, we'll
    // defer the detection of focus being lost by one tick, so we can query whether focus has just moved within the
    // container, rather than moving out of it.
    clearTimeout(this._resetFocusTimeout);
    this._resetFocusTimeout = setTimeout(this._resetFocus, 1);
  };

  // --------------------------------------------------------------------------
  // Clear out the user's text so we fall back to a validly formatted duration matching the `duration` prop
  _onDateInputBlur = () => {
    this.setState({ dateInputText: null });
  };

  // --------------------------------------------------------------------------
  _onFocus = event => {
    this.setState({ focused: true });

    const { onFocus } = this.props;
    if (onFocus) onFocus(event);
  };

  // --------------------------------------------------------------------------
  _renderDateInput(nextInstance) {
    const { autoFocus, disabled, duration, anchorName, anchorRelation } = this.props;

    let { dateInputText } = this.state;

    // If the user hasn't changed the text, pull it from the duration, normalizing it in the process
    if (dateInputText === null && duration !== "") {
      if (isLessThanDailyDuration(duration) && anchorRelation === DURATION_ANCHOR_RELATION.BEFORE) {
        // The "before due" type inputs treat values under 24 hours, like `PT5H` as "5AM on the due day", not "5
        // hours before due"
        if (minutesFromDuration(duration) === 0) {
          // We want "PT0H" to just be "Day task is due" instead of the more confusing "12:00 am on the due day"
          dateInputText = sameDayText(anchorName);
        } else {
          dateInputText = `${ shortTimeTextFromDuration(duration) } on the ${ anchorName } day`;
        }
      } else {
        dateInputText = dateTextFromDuration(duration);
        if (dateInputText !== null) {
          if (dateInputText.length === 0) {
            dateInputText = sameDayText(anchorName);
          } else {
            dateInputText += ` ${ anchorRelation } ${ anchorName }`;
          }
        }
      }
    }

    const invalid = !(duration && duration.length > 0);

    const dateSuggestions = this._memoizedDateSuggestions(anchorName, anchorRelation, dateInputText || "", duration);

    return (
      <div className="text-input-container duration-date-container">
        <TextInputWithSuggestions
          additionalClassName={ nextInstance ? "with-next-instance" : "" }
          autoFocus={ autoFocus }
          invalid={ invalid }
          disabled={ disabled }
          onBlur={ this._onDateInputBlur }
          placeholder={ `e.g. 2 days ${ anchorRelation } ${ anchorName }` }
          selectSuggestion={ this._selectDateSuggestion }
          setValue={ this._setDateInputText }
          suggestions={ dateSuggestions }
          value={ dateInputText || "" }
        />
        <i className="material-icons repeat-icon">cached</i>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderNextInstance = nextInstance => {
    if (!nextInstance) return null;

    const { disabled, duration, expectedNextInstanceAt } = this.props;

    let expectedNextInstance;
    if (duration) {
      expectedNextInstance = expectedNextInstanceAt ? new Date(expectedNextInstanceAt * 1000) : null;
    } else {
      expectedNextInstance = nextInstance;
    }

    return (
      <NextInstanceInput
        disabled={ disabled }
        expectedNextInstance={ expectedNextInstance }
        nextInstance={ nextInstance }
        setNextInstance={ this._setNextInstance }
      />
    );
  };

  // --------------------------------------------------------------------------
  _renderTimeInput() {
    const { disabled, duration, setDuration } = this.props;

    if (duration && isLessThanDailyDuration(duration)) return null;

    return (
      <TimeInput
        disabled={ disabled }
        duration={ duration }
        setDuration={ setDuration }
      />
    );
  }

  // --------------------------------------------------------------------------
  _resetFocus = () => {
    const focused = !!(this._dateTimeInputContainer && this._dateTimeInputContainer.querySelector(":focus"));
    this.setState({ focused });
  };

  // --------------------------------------------------------------------------
  _selectDateSuggestion = ({ text }) => {
    this._setDateInputText(text);
  };

  // --------------------------------------------------------------------------
  _setDateInputText = dateInputText => {
    const { anchorName, anchorRelation, duration, nextInstanceAt } = this.props;

    this.setState({ dateInputText });

    let newDuration;
    if (dateInputText && dateInputText.toUpperCase() === sameDayText(anchorName).toUpperCase()) {
      newDuration = SAME_DAY_DURATION;
    } else {
      let normalizedInputText = dateInputText.replace(new RegExp(`\\s${ anchorRelation }(\\s+${ anchorName })?\\s*$`), "");
      normalizedInputText = normalizedInputText.replace(/\safter\s*$/, "").trim();

      const timeDuration = (duration && duration.length > 0) ? duration : SAME_DAY_DURATION;

      newDuration = durationFromText(normalizedInputText, timeDuration, {
        parseTimes: anchorRelation === DURATION_ANCHOR_RELATION.BEFORE
      });
    }

    // When first assigning the duration, it's possible for there already to be a (manually specified) next instance,
    // in which case we would prefer to use the time from that instance for the rule, unless the duration being
    // specified is less than daily (e.g. "3 hours")
    if (!duration && newDuration && nextInstanceAt) {
      newDuration = adjustDurationTime(newDuration, durationFromTimestamp(nextInstanceAt));
    }

    if (newDuration !== duration) {
      this.props.setDuration(newDuration);
    }
  };

  // --------------------------------------------------------------------------
  _setDateTimeInputContainer = container => {
    this._dateTimeInputContainer = container;
  };

  // --------------------------------------------------------------------------
  _setNextInstance = date => {
    const { setNextInstance } = this.props;
    if (setNextInstance) setNextInstance(date);
  };
}

import { NodeSelection, Selection, TextSelection } from "prosemirror-state"

import { ARROW } from "lib/ample-editor/lib/selection-util"
import { nonHiddenPosAfter, nonHiddenPosBefore } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

export const MAX_DEPTH_TO_ENTER_DOC_CHILD_SELECT = 2;

// --------------------------------------------------------------------------
export const onDocChildrenArrowPress = arrowKey => (state, dispatch, view) => {
  const { doc, selection, selection: { $head, $anchor } } = state;

  if (selection instanceof DocChildrenSelection) {
    if (!dispatch) return true;
    const movingLeftOrUp = [ ARROW.LEFT, ARROW.UP ].includes(arrowKey);
    let cursorPos;
    // Pick a moveIncrement such that when we adjust the cursorPos it will be at a valid location for cursor (i.e., not a list boundary)
    if (movingLeftOrUp) {
      cursorPos = nonHiddenPosBefore($head.pos - 1, state, true);
      cursorPos = (cursorPos === null ? 0 : cursorPos);
    } else {
      cursorPos = nonHiddenPosAfter($head.pos + 1, state, true);
      cursorPos = (cursorPos === null ? doc.content.size : cursorPos);
    }

    const transform = state.tr;
    if (view.input.shiftKey) {
      const $cursor = doc.resolve(cursorPos);
      const newHeadPos = movingLeftOrUp ? $cursor.before(1) : $cursor.after(1);
      let $newHead = doc.resolve(newHeadPos);
      if ($newHead.pos === $head.pos) {
        if (movingLeftOrUp) {
          $newHead = $newHead.nodeBefore ? doc.resolve($newHead.pos - $newHead.nodeBefore.nodeSize) : $newHead;
        } else {
          $newHead = $newHead.nodeAfter ? doc.resolve($newHead.pos + $newHead.nodeAfter.nodeSize) : $newHead;
        }
      }
      transform.setSelection(DocChildrenSelection.create($anchor, $newHead));
      dispatch(transform.scrollIntoView());
    } else {
      const $textAnchor = Selection.near(transform.doc.resolve(cursorPos), cursorPos > $anchor.pos ? 1 : -1).$anchor;
      transform.setSelection(TextSelection.create(transform.doc, $textAnchor.pos));
      dispatch(transform.scrollIntoView());
    }
    return true;
  } else if (selection instanceof NodeSelection && view.input.shiftKey && $anchor.nodeAfter.type.spec.code) {
    return maybeInvokeDocChildrenSelection(arrowKey, state, dispatch);
  }
}

// --------------------------------------------------------------------------
// When we reach the edge of a code block, a NodeSelection gets created. If user wishes to continue moving,
// and there are nodes to move to, a DocChildrenSelection should be invoked. If no nodes can be moved to,
// we still return true so that we don't lose current selection
const maybeInvokeDocChildrenSelection = (arrowKey, state, dispatch) => {
  const { selection: { $anchor, $head } } = state;
  const movingLeftOrUp = [ ARROW.LEFT, ARROW.UP ].includes(arrowKey);
  if (movingLeftOrUp && !$anchor.nodeBefore) return true; // Nothing to do but stay put
  if (!movingLeftOrUp && !$head.nodeAfter) return true;

  if (dispatch) {
    const { doc, tr: transform } = state;
    let docChildrenSelection;
    if (movingLeftOrUp) {
      // In a NodeSelection, $anchor is always starting edge of block & $head is end of block, but if user pressed up,
      // we want to swap those around so that the bottom of the node (which had been $head) now becomes $anchor
      docChildrenSelection = DocChildrenSelection.create($head, doc.resolve($anchor.pos - $anchor.nodeBefore.nodeSize));
    } else {
      docChildrenSelection = DocChildrenSelection.create($anchor, doc.resolve($head.pos + $head.nodeAfter.nodeSize))
    }
    dispatch(transform.setSelection(docChildrenSelection));
  }
  return true;
}

// --------------------------------------------------------------------------
// Akin to PM SelectionRange, except that this Selection type includes $from, $to, $anchor and $head, which
// a SelectionRange does not contain, thus causing myriad exceptions upon trying to use. This selection also
// constrains nodes that are direct children of the doc so we don't have to worry about traversing node depth
export default class DocChildrenSelection extends Selection {
  // --------------------------------------------------------------------------
  // Snap $anchor and $head to proximal node boundaries
  static create($anchor, $head) {
    if (!$anchor && !$head) return null;

    if ($head && $head.pos !== $head.before(1)) $head = $head.doc.resolve($head.before(1));
    if ($anchor && $anchor.pos !== $anchor.before(1)) $anchor = $anchor.doc.resolve($anchor.before(1));

    if (!$anchor) $anchor = $head;
    if (!$head) $head = $anchor;

    return new DocChildrenSelection($anchor, $head);
  }

  // --------------------------------------------------------------------------
  constructor($anchor, $head) {
    super($anchor, $head);
    this.type = "doc-children";
  }

  // --------------------------------------------------------------------------
  eq(other) {
    return other instanceof DocChildrenSelection && other.anchor === this.anchor && other.head === this.head;
  }

  // --------------------------------------------------------------------------
  map(doc, mapping) {
    const $head = doc.resolve(mapping.map(this.head));
    if ($head.parent !== $head.doc) {
      return Selection.near($head);
    }
    const $anchor = doc.resolve(mapping.map(this.anchor));
    if ($anchor.parent !== $anchor.doc) {
      const $headNear = Selection.near($head);
      const $anchorNear = Selection.near($anchor)
      return new TextSelection($anchorNear, $headNear);
    }
    return new DocChildrenSelection($anchor, $head);
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return { type: "doc-children", anchor: this.anchor, head: this.head };
  }
}

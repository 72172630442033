import { joinBackward } from "prosemirror-commands"
import { NodeSelection, TextSelection } from "prosemirror-state"

import { COLLAPSIBLE_HEADING_DEPTH } from "lib/ample-editor/lib/collapsible-defines"
import { isPosHiddenNode } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
// We only care about hidden nodes at the top level of the document
const DOC_DEPTH = 0;

// --------------------------------------------------------------------------
export function backspaceAfterCollapsedNode(state, dispatch) {
  const { doc, schema, selection, selection: { empty, $from } } = state;

  if (!(selection instanceof NodeSelection)) {
    if (!empty || $from.pos !== $from.start(COLLAPSIBLE_HEADING_DEPTH)) return false;
  }

  const nodeIndex = $from.index(DOC_DEPTH);
  // We need at least a hidden node and the collapsible node before the node the cursor is in
  if (nodeIndex < 2) return false;

  const previousNodePos = $from.posAtIndex(nodeIndex - 1, DOC_DEPTH);
  if (!isPosHiddenNode(state, previousNodePos)) return false;

  let collapsibleNodeIndex = nodeIndex - 2;
  let collabsibleNodePos = $from.posAtIndex(collapsibleNodeIndex, DOC_DEPTH);
  while (isPosHiddenNode(state, collabsibleNodePos)) {
    if (collapsibleNodeIndex === 0) return false;

    collapsibleNodeIndex = collapsibleNodeIndex - 1;
    collabsibleNodePos = $from.posAtIndex(collapsibleNodeIndex, DOC_DEPTH);
  }

  const collapsibleNode = doc.nodeAt(collabsibleNodePos);
  if (!collapsibleNode || collapsibleNode.type !== schema.nodes.heading) return false;

  if (dispatch) {
    let joinBackwardTransaction = null;
    const didJoinBackward = joinBackward(state, transaction => {
      const attrs = { ...collapsibleNode.attrs, collapsed: false };
      transaction.setNodeMarkup(collabsibleNodePos, null, attrs);
      joinBackwardTransaction = transaction;
    });

    if (didJoinBackward) {
      // If we joined backward and the section ended with a hr, it probably got deleted, but now we want to
      // re-insert it _after_ the content that's been joined backwards, so that content is part of the heading's
      // section
      const previousNode = doc.nodeAt(previousNodePos);
      if (previousNode && previousNode.type === schema.nodes.horizontal_rule) {
        // Make sure it actually got deleted (joining an empty paragraph backwards will delete the paragraph and
        // not the hr)
        const mappedNodePos = joinBackwardTransaction.doc.resolve(joinBackwardTransaction.mapping.map($from.start()))
        const nodeBefore = mappedNodePos.nodeBefore;
        if (!nodeBefore || nodeBefore.type !== schema.nodes.horizontal_rule) {
          joinBackwardTransaction.insert(
            joinBackwardTransaction.mapping.map($from.end()),
            schema.nodes.horizontal_rule.createAndFill(),
          );
        }
      }

      dispatch(joinBackwardTransaction);
    } else {
      // If we have something like a hr NodeSelected and try to join backwards, it will fail as it can't be
      // joined, so we'll just expand the collapsed section and move the cursor to the end of it
      const transaction = state.tr;
      const attrs = { ...collapsibleNode.attrs, collapsed: false };
      transaction.setNodeMarkup(collabsibleNodePos, null, attrs);

      const previousNode = doc.nodeAt(previousNodePos);
      const $newCursor = transaction.doc.resolve(previousNodePos + previousNode.nodeSize);
      transaction.setSelection(TextSelection.between($newCursor, $newCursor, -1));
      dispatch(transaction);
    }
  }

  return true;
}

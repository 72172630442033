import { compact, sortBy } from "lodash"

import { noteParamsFromNoteUUID, noteUUIDFromNoteParams } from "lib/ample-util/note-uuid"

// --------------------------------------------------------------------------
// Helper functions and constants relating to `references` filter parameter that can
// be used to filter a result set based on the notes that items reference (or don't reference).
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
const NEGATED_REFERENCE_PREFIX = "^";
export const REFERENCES_DELIMITER = ",";

// --------------------------------------------------------------------------
export function filterReferencesFromNoteReferences(noteReferences) {
  if (!noteReferences) return null;

  return sortBy(noteReferences, ({ noteUUID }) => noteUUID).map(({ isNegated, noteUUID }) => {
    return `${ isNegated ? NEGATED_REFERENCE_PREFIX : "" }${ noteUUID }`;
  }).join(REFERENCES_DELIMITER);
}

// --------------------------------------------------------------------------
export function noteReferencesFromFilterReferences(filterReferences) {
  return compact((filterReferences ? filterReferences.split(REFERENCES_DELIMITER) : []).map(filterReference => {
    const isNegated = filterReference.startsWith(NEGATED_REFERENCE_PREFIX);
    if (isNegated) filterReference = filterReference.slice(NEGATED_REFERENCE_PREFIX.length);

    const noteParams = noteParamsFromNoteUUID(filterReference);
    return noteParams ? { ...noteParams, isNegated, noteUUID: filterReference } : null;
  }));
}

// --------------------------------------------------------------------------
// Note references can include negation, so this tests if a given list of note UUIDs satisfies all the note references'
// requirements (to be considered a match).
export function referencedNoteUUIDsMatchNoteReferences(noteReferences, referencedNoteUUIDs, sourceNoteUUID) {
  return noteReferences.every(({ isNegated, noteUUID }) => {
    const taskIsInNote = sourceNoteUUID === noteUUID;
    const taskReferencesNote = referencedNoteUUIDs && referencedNoteUUIDs.includes(noteUUID);

    if (isNegated) {
      return !taskIsInNote && !taskReferencesNote;
    } else {
      return taskIsInNote || taskReferencesNote;
    }
  });
}

// --------------------------------------------------------------------------
export function taskMatchesNoteReferences(noteReferences, task) {
  const { references: referencedNoteUUIDs } = task;

  const taskNoteUUID = noteUUIDFromNoteParams(task);

  return referencedNoteUUIDsMatchNoteReferences(noteReferences, referencedNoteUUIDs, taskNoteUUID);
}

import { closeBrackets } from "@codemirror/autocomplete"
import { defaultKeymap, indentLess, indentMore, insertBlankLine, selectLineDown, selectLineUp } from "@codemirror/commands"
import { javascript } from "@codemirror/lang-javascript"
import { bracketMatching, HighlightStyle, StreamLanguage, syntaxHighlighting } from "@codemirror/language"
import { Compartment } from "@codemirror/state"
import { drawSelection, EditorView as CodeMirror, keymap as cmKeymap } from "@codemirror/view"
import { tags } from "@lezer/highlight"

import {
  CODE_BLOCK_COPY_ICON_CLASS,
  copyIconTitle,
  LANGUAGE,
  LEGACY_MODE_LANGUAGES,
} from "lib/ample-editor/lib/code-block/code-block-util"
import languagesWithData from "lib/ample-editor/lib/code-block/languages-with-data"
import importRetry from "lib/ample-editor/lib/import-retry"
import { ARROW } from "lib/ample-editor/lib/selection-util"

// --------------------------------------------------------------------------
const languageConf = new Compartment();

// --------------------------------------------------------------------------
export function codeMirrorKeymap(editorView, codeBlockView) {
  return [
    { key: "ArrowUp", run: () => codeBlockView.maybeEscape("line", -1) },
    { key: "ArrowLeft", run: () => codeBlockView.maybeEscape("char", -1) },
    { key: "ArrowDown", run: () => codeBlockView.maybeEscape("line", 1) },
    { key: "ArrowRight", run: () => codeBlockView.maybeEscape("char", 1) },
    { key: "Ctrl-a", mac: "Cmd-a", run: () => codeBlockView.progressiveSelectAll() },
    { key: "Ctrl-k", mac: "Cmd-k", run: () => true }, // CM handles this so PM won't go trying to put a link in the code_block
    { key: "Ctrl-y", mac: "Cmd-shift-z", run: () => codeBlockView.redo() },
    { key: "Ctrl-z", mac: "Cmd-z", run: () => codeBlockView.undo() },
    { key: "End", mac: "Cmd-ArrowRight", run: () => codeBlockView.jumpToEnd() },
    { key: "Escape", run: () => codeBlockView.handleEscapeKeypress() },
    { key: "Home", mac: "Cmd-ArrowLeft", run: () => codeBlockView.jumpToHome() },
    {
      key: "Shift-ArrowDown",
      run: () => codeBlockView.shiftArrow(ARROW.DOWN, codeMirrorEditor => selectLineDown(codeMirrorEditor))
    },
    {
      key: "Shift-ArrowUp",
      run: () => codeBlockView.shiftArrow(ARROW.UP, codeMirrorEditor => selectLineUp(codeMirrorEditor)),
    },
    { key: "Shift-End", mac: "Shift-Cmd-ArrowRight", run: () => codeBlockView.jumpToEnd(true) },
    {
      key: "Shift-Enter",
      run: () => {
        codeBlockView.insertLineWithoutEscape(codeMirrorEditor => {
          insertBlankLine({ state: codeMirrorEditor.state, dispatch: codeMirrorEditor.dispatch });
        });
      }
    },
    { key: "Shift-Home", mac: "Shift-Cmd-ArrowLeft", run: () => codeBlockView.jumpToHome(true) },
    { key: "Shift-Tab", run: () => indentLess(codeBlockView.codeMirrorEditor) },
    { key: "Tab", run: () => indentMore(codeBlockView.codeMirrorEditor) },
  ]
}

// --------------------------------------------------------------------------
// `languageModule` a loaded function such as `javascript()` or `import { xml } from "lib/ample-editor/lib/code-block/codemirror/xml`
//    defaults to javascript() when null
export function createCodeMirrorEditor(textContent, editorView, codeBlockView, serializer, { readonly = null, languageModule = null } = {}) {
  // Don't allow CodeMirror to forcefully declare what Ctrl-ArrowLeft should do when DefaultKeyBindings.dict might
  // already specify a user-defined preference for their Ctrl-Arrow behavior
  const ampleDefaultKeymap = [ ...defaultKeymap ].filter(keyDefinition =>
    (![ "Ctrl-ArrowLeft", "Ctrl-ArrowRight" ].includes(keyDefinition.mac)));
  const extensions = [
    cmKeymap.of([
      ...codeMirrorKeymap(editorView, codeBlockView),
      ...ampleDefaultKeymap
    ]),
    drawSelection(),
    closeBrackets(),
    bracketMatching(),
    syntaxHighlighting(highlightStyle),
    CodeMirror.updateListener.of(update => codeBlockView._forwardUpdateToProsemirror(update))
  ];

  if (languageModule) {
    extensions.push(languageConf.of(languageModule))
  } else {
    extensions.push(languageConf.of(javascript()))
  }

  if (readonly) {
    extensions.push(CodeMirror.editable.of(false));
  }

  // Create a CodeMirror instance, options described at https://codemirror.net/docs/ref
  return new CodeMirror({
    doc: textContent,
    extensions: [
      ...extensions,
      CodeMirror.domEventHandlers({
        copy(event, cmView) {
          serializer(event, cmView);
        },
      })
    ],
  });
}

// --------------------------------------------------------------------------
export const highlightStyle = HighlightStyle.define([
  { tag: tags.atom, class: "cm-atom" },
  { tag: tags.attributeName, class: "cm-attribute" },
  { tag: tags.attributeValue, class: "cm-variable-3" },
  { tag: tags.bracket, class: "cm-bracket" },
  { tag: tags.comment, class: "cm-comment" },
  { tag: tags.invalid, class: "cm-invalidchar" },
  { tag: tags.keyword, class: "cm-keyword" },
  { tag: tags.literal, class: "cm-literal" },
  { tag: tags.keyword, class: "cm-keyword" },
  { tag: tags.labelName, class: "cm-keyword" },
  { tag: tags.link, class: "cm-link" },
  { tag: tags.meta, class: "cm-meta" },
  { tag: tags.name, class: "cm-property" },
  { tag: tags.namespace, class: "cm-type" },
  { tag: tags.number, class: "cm-number" },
  { tag: tags.operator, class: "cm-operator" },
  { tag: tags.definition(tags.propertyName), class: "cm-special" },
  { tag: tags.punctuation, class: "cm-punctuation" },
  { tag: tags.regexp, class: "cm-special" },
  { tag: tags.separator, class: "cm-separator" },
  { tag: tags.special(tags.variableName), class: "cm-special" },
  { tag: tags.strikethrough, textDecoration: "line-through" },
  { tag: tags.string, class: "cm-string" },
  { tag: tags.strong, class: "cm-strong" },
  { tag: tags.typeName, class: "cm-type" },
  { tag: tags.definition(tags.variableName), class: "cm-variable" },
  { tag: tags.local(tags.variableName), class: "cm-variable-2" },
]);

// --------------------------------------------------------------------------
// Legacy mode languages were plucked from https://github.com/codemirror/legacy-modes/tree/main/mode on behalf of
// not importing a 2.5mb npm package
export function loadLanguageModule(languageEm) {
  if (languageEm in LEGACY_MODE_LANGUAGES) {
    const { file, extract } = (LEGACY_MODE_LANGUAGES[languageEm] || {});

    return importRetry(() => import(/* webpackChunkName: "code-mirror-legacy-modes" */ `lib/ample-editor/lib/code-block/codemirror/${ file }`))
      .then(exportedFunctions => {
        const exportedLanguage = exportedFunctions[extract];
        if (!exportedLanguage) throw new Error(`Couldn't find language ${ languageEm } among exported functions in path`);
        // The reason these languages are in a "legacy-modes" package is because they aren't natively StreamLanguages, but
        // we can dynamically convert them to be usable by CM6
        return StreamLanguage.define(exportedLanguage);
      });
  } else if (Object.values(LANGUAGE).includes(languageEm)) {
    const exportedLanguage = languagesWithData(languageEm);
    if (!exportedLanguage) {
      throw new Error(`Couldn't find language ${ languageEm } in exportable function ${ languageEm } among exported functions in lang-${ languageEm }`);
    }
    return Promise.resolve(exportedLanguage);
  } else {
    // Default to JS
    return Promise.resolve(javascript());
  }
}

// --------------------------------------------------------------------------
// `languageName` A string representation of a language name aka one of the values within the `LANGUAGE` object
export async function setEditorLanguage(languageEm, editorView, codeBlockView) {
  const languageModule = await loadLanguageModule(languageEm);
  const updatedLanguage = languageConf.reconfigure(languageModule);

  if (codeBlockView.dom) {
    const copyIcon = codeBlockView.dom.querySelector(`.${ CODE_BLOCK_COPY_ICON_CLASS }`);
    if (copyIcon) {
      copyIcon.setAttribute("title", copyIconTitle(languageEm));
    }
  }

  codeBlockView.codeMirrorEditor.dispatch({ effects: updatedLanguage });
}

import { noteParamsFromURL } from "lib/ample-util/note-url"
import { isDoneFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export function extractLinkDescriptionText(value) {
  if (!value) return "";
  if (typeof value === "string") return value;

  // If it's not a plain string, it will be an array of description schema nodes

  let result = "";

  for (let i = 0; i < value.length; i++) {
    const node = value[i];
    const { text, hiddenText } = extractNodeContent(node);
    if (text) result += text;
    if (hiddenText) result += hiddenText;
  }

  return result;
}

// --------------------------------------------------------------------------
function extractNodeAttribute(node, attributeName) {
  if (!node.attrs) return "";

  const attributeValue = node.attrs[attributeName];
  return attributeValue ? ` ${ attributeValue } ` : "";
}

// --------------------------------------------------------------------------
// Handles raw JSON representation of document content, as found in the completedTasks
export default function extractNodeContent(node) {
  const result = { hiddenText: "", text: "", noteLinks: [] };

  if (!node) return result;

  let childDelimiter = "";
  let shouldHideText = false;

  // eslint-disable-next-line default-case
  switch (node.type) {
    case "attachment":
      result.hiddenText = extractNodeAttribute(node, "text");
      return result;

    case "check_list_item": {
      const { attrs } = node;
      if (attrs) {
        shouldHideText = isDoneFromTask(attrs);
      }
      break;
    }

    case "hard_break":
      result.text = "\n";
      return result;

    case "image":
      result.hiddenText = extractNodeAttribute(node, "text");
      break;

    case "link":
      if (node.attrs) {
        const { attrs: { description, href, media } } = node;
        if (description) result.hiddenText += extractLinkDescriptionText(description);
        if (href) {
          const noteLink = noteParamsFromURL(href);
          if (noteLink) result.noteLinks.push(noteLink);
        }
        if (media && media.text) result.hiddenText += media.text;
      }
      break;

    case "table_row":
      childDelimiter = " ";
      break;

    case "table":
      childDelimiter = "\n";
      break;

    case "text": {
      if (node.text) result.text = node.text;
      return result;
    }
  }

  const content = node.content || [];
  for (let i = 0; i < content.length; i++) {
    const { hiddenText: childHiddenText, text: childText, noteLinks: childNoteLinks } = extractNodeContent(content[i]);

    if (childText) {
      result.text += childText;
      if (childDelimiter && i !== content.length - 1) result.text += childDelimiter;
    }

    if (childNoteLinks) result.noteLinks.push(...childNoteLinks);
    if (childHiddenText) result.hiddenText += childHiddenText;
  }

  if (shouldHideText && result.text.length > 0) {
    result.hiddenText = result.hiddenText.length > 0 ? (result.text + " " + result.hiddenText) : result.text;
    result.text = "";
  }

  return result;
}

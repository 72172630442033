import { closeHistory } from "prosemirror-history"
import { Fragment, Slice } from "prosemirror-model"
import { dropPoint } from "prosemirror-transform"

import markdownParser from "lib/ample-editor/lib/markdown-parser"

// --------------------------------------------------------------------------
export default function replaceFromMarkdown(from, to, markdownContent) {
  return function(state, dispatch) {
    const newDocument = markdownParser.parse(markdownContent);

    // We need the fragment to be in the destination schema, e.g. if this is being inserted in a tasks editor,
    // trying to insert the document produced by the markdownParser will fail because it produces documents
    // in the standard editor schema. Note that we want to do this before calling maxOpen so we have the correct
    // opening depth applied by the correct schema.
    const fragment = Fragment.fromJSON(state.schema, newDocument.content.toJSON());

    // We'd like to fit the slice in as much as possible in the current location (i.e. not creating new
    // paragraphs when trying to insert some inline content), so mark it as open on both sides as much as possible
    // but note that we don't want to open isolating nodes, like table cells.
    const slice = Slice.maxOpen(fragment, false);

    // Note that there's some fuzziness in `replaceRange`, so we're not testing that the drop point is exactly
    // the insert point
    if (dropPoint(state.doc, to, slice) === null) {
      return false;
    }

    if (dispatch) {
      dispatch(closeHistory(state.tr.replaceRange(from, to, slice)));
    }

    return true;
  }
}

import PropTypes from "prop-types"
import React from "react"
import { CircularProgress } from "@rmwc/circular-progress"
import scriptjs from "scriptjs"

// --------------------------------------------------------------------------
const TWITTER_WIDGET_JS_URL = "https://platform.twitter.com/widgets.js";

// https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-parameter-reference
const TWITTER_WIDGET_OPTIONS = {
  align: "center",
  dnt: true,
};

// --------------------------------------------------------------------------
// Loosely based on https://github.com/saurabhnemade/react-twitter-embed/blob/master/src/components/TwitterTweetEmbed.js
export default class TwitterPreview extends React.PureComponent {
  // --------------------------------------------------------------------------
  static propTypes = {
    onLoad: PropTypes.func,
    tweetId: PropTypes.string.isRequired,
  };

  _containerRef = React.createRef();
  _unmounted = false;

  state = {
    failed: false,
    loading: true,
  };

  // --------------------------------------------------------------------------
  componentDidMount() {
    scriptjs(TWITTER_WIDGET_JS_URL, this._renderTwitterWidget);
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._unmounted = true;
  }

  // --------------------------------------------------------------------------
  render() {
    const { loading } = this.state;

    return (
      <div className="preview-section twitter-preview" ref={ this._containerRef }>
        { loading ? <div className="loader"><CircularProgress size="large" /></div> : null }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _renderTwitterWidget = async () => {
    if (this._unmounted) return;

    // Loading twitter javascript failed
    if (!window.twttr) {
      this.setState({ failed: true });
      return;
    }

    const { onLoad, tweetId } = this.props;

    try {
      await window.twttr.widgets.createTweet(tweetId, this._containerRef.current, TWITTER_WIDGET_OPTIONS);
    } catch (error) {
      this.setState({ failed: true });
      throw error;
    }

    this.setState({ loading: false });

    if (onLoad) onLoad();
  }
}

import { filter } from "lodash"

// --------------------------------------------------------------------------
export const SUPPORTED_ATTACHMENT_CONTENT_TYPES = [
  "application/json",
  "application/msword", // .doc
  "application/pdf",
  "application/vnd.ms-excel", // .xls
  "application/vnd.ms-powerpoint", // .ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template", // .dotx
  "audio/aac", // .aac
  "audio/basic", // .snd
  "audio/m4a", // .m4a
  "audio/mid", // .mid
  "audio/mpeg", // .mp3
  "audio/mp4", // .mp4
  "audio/ogg", // .ogg
  "audio/vnd.wav", // .wav
  "audio/vorbis", // .vorbis
  "audio/wav", // .wav
  "audio/x-aiff", // .aiff
  "audio/x-m4a", // .m4a
  "audio/x-mpegurl", // .m3u
  "audio/x-wav", // .wav
  "text/csv", // .csv
];

// --------------------------------------------------------------------------
function buildFilterByTypePrefix(prefix) {
  return function(files) {
    // `files` is not an array, but a `FileList`
    return filter(files, file => file.type && file.type.startsWith(prefix));
  }
}

// --------------------------------------------------------------------------
export function filterAttachments(files) {
  // `files` is not an array, but a `FileList`
  return filter(files, file => SUPPORTED_ATTACHMENT_CONTENT_TYPES.includes(file.type));
}

// --------------------------------------------------------------------------
export const filterImages = buildFilterByTypePrefix("image/");

// --------------------------------------------------------------------------
export const filterVideos = buildFilterByTypePrefix("video/");

import { brokenClipboardAPI } from "lib/ample-editor/lib/client-info"
import { insertMedia } from "lib/ample-editor/lib/file-commands"
import { filterImages, filterVideos } from "lib/ample-editor/lib/filter-files"

// --------------------------------------------------------------------------
export const filterImagesFromPaste = event => {
  const dataTransfer = brokenClipboardAPI ? null : event.clipboardData;
  if (!dataTransfer || !dataTransfer.files || dataTransfer.files.length === 0) return [];

  return filterImages(dataTransfer.files);
};

// --------------------------------------------------------------------------
export const filterVideosFromPaste = event => {
  const dataTransfer = brokenClipboardAPI ? null : event.clipboardData;
  if (!dataTransfer || !dataTransfer.files || dataTransfer.files.length === 0) return [];

  return filterVideos(dataTransfer.files);
};

// --------------------------------------------------------------------------
export default function handlePasteFiles(view, event) {
  const mediaFiles = filterImagesFromPaste(event).concat(filterVideosFromPaste(event));
  if (mediaFiles.length === 0) return false;

  event.preventDefault();

  mediaFiles.forEach(mediaFile => {
    const transaction = view.state.tr;
    const { selection } = transaction;

    insertMedia(view, mediaFile, selection.from);
  });

  return true;
}

import { Node } from "prosemirror-model"

import tasksSchema from "lib/ample-editor/components/tasks-editor/tasks-schema"
import { checkListItemFromCompletedTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function attributeFromCheckListItem(checkListItem, attributeName) {
  const attrs = checkListItem ? (checkListItem.attrs || {}) : {};

  const value = (attributeName in attrs)
    ? attrs[attributeName]
    : tasksSchema.nodes.check_list_item.attrs[attributeName].default;

  // The attribute may be explicitly set to `undefined`
  return (typeof value === "undefined") ? null : value;
}

// --------------------------------------------------------------------------
function tupleByUUIDFromDoc(doc) {
  const tupleByUUID = {};

  doc.forEach(tasksGroup => {
    const groupId = tasksGroup.attrs.id;

    tasksGroup.forEach(checkListItem => {
      const { attrs: { uuid } } = checkListItem;
      tupleByUUID[uuid] = { checkListItem, groupId };
    });
  });

  doc.attrs.completedTasks.forEach(completedTask => {
    // If you complete a task then un-complete it, it will be in both the document and the completedTasks section -
    // but the version in the document is the more up-to-date version
    if (tupleByUUID[completedTask.uuid]) return;

    const checkListItem = checkListItemFromCompletedTask(completedTask);

    // This is a special case where a crossed out task is moved to completedTasks only so the editor can undo the
    // cross-out action (otherwise there's no task to restore)
    if (checkListItem.attrs.crossedOutAt) {
      delete checkListItem.attrs.completedAt;
    }

    tupleByUUID[checkListItem.attrs.uuid] = {
      checkListItem: Node.fromJSON(tasksSchema, checkListItem)
    };
  });

  return tupleByUUID;
}

// --------------------------------------------------------------------------
function updatesFromTuples(tupleWas, tuple) {
  const updates = {};

  const { checkListItem, groupId } = tuple;
  const { checkListItem: checkListItemWas, groupId: groupIdWas } = tupleWas || {};

  // Note that completed tasks don't keep track of the groupId, since they are no longer updated by the external data
  // source, so we stop trying to reconcile changes to their group.
  if (typeof(groupId) !== "undefined" && groupId !== groupIdWas) {
    updates.groupId = groupId;
  }

  if (!checkListItemWas || !checkListItem.content.eq(checkListItemWas.content)) {
    updates.content = checkListItem.content.toJSON();
  }

  Object.keys(tasksSchema.nodes.check_list_item.attrs).forEach(attributeName => {
    if (attributeName === "uuid" || attributeName === "indent") return;

    const valueWas = attributeFromCheckListItem(checkListItemWas, attributeName);
    const value = attributeFromCheckListItem(checkListItem, attributeName);

    if (value !== valueWas) updates[attributeName] = value;
  });

  return updates;
}

// --------------------------------------------------------------------------
// Returns an Object mapping task uuid to changes to the task attributes, content, or groupId that were made between
// the old doc and new doc. Note these docs are tasksSchema documents, not standard schema documents.
export default function taskUpdatesFromDocChanges(doc, docWas) {
  const updatesByUUID = {};

  const tupleByUUID = tupleByUUIDFromDoc(doc);
  const tupleWasByUUID = tupleByUUIDFromDoc(docWas);

  const addedTupleByUUID = {};
  const removedTupleByUUID = { ...tupleWasByUUID };
  Object.keys(tupleByUUID).forEach(uuid => {
    delete removedTupleByUUID[uuid];

    const tuple = tupleByUUID[uuid];
    const tupleWas = tupleWasByUUID[uuid];
    if (!tupleWas) {
      addedTupleByUUID[uuid] = tuple;
      return;
    }

    const updates = updatesFromTuples(tupleWas, tuple);
    if (Object.keys(updates).length === 0) return;

    updatesByUUID[uuid] = updates;
  });

  Object.keys(removedTupleByUUID).forEach(uuid => {
    const updates = updatesFromTuples(null, removedTupleByUUID[uuid]);
    updatesByUUID[uuid] = { deleted: updates };
  });

  Object.keys(addedTupleByUUID).forEach(uuid => {
    const updates = updatesFromTuples(null, addedTupleByUUID[uuid]);
    updatesByUUID[uuid] = { added: updates };
  });

  return updatesByUUID;
}

import { addHours, addMinutes, isEqual, startOfDay } from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"

import DateAdjustPopup from "lib/ample-editor/components/date-adjust-popup"
import FuzzyTimeInput from "lib/ample-editor/components/task-detail/fuzzy-time-input"
import TimeInput from "lib/ample-editor/components/task-detail/time-input"
import { blurTargetOnEnter } from "lib/ample-editor/components/task-detail/util"
import parseDateText from "lib/ample-editor/lib/parse-date-text"
import { formatDate, getWeekStartsOn } from "lib/ample-util/date"
import { DAY_PART, durationFromTimestamp, timestampFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const DAY_PICKER_INPUT_CLASS_NAMES = {
  container: "date-input-container DayPickerInput",
  overlayWrapper: "DayPickerInput-OverlayWrapper",
  overlay: "DayPickerInput-Overlay"
};

// --------------------------------------------------------------------------
function renderNothing() {
  return null;
}

// --------------------------------------------------------------------------
export default class DateTimeInput extends React.Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    adjustPopupType: PropTypes.oneOf([ "due", "none", "startAt" ]).isRequired,
    className: PropTypes.string,
    date: PropTypes.object,
    dayPart: PropTypes.string,
    disabled: PropTypes.bool,
    onDateChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    placeholder: PropTypes.string,
    selectExistingText: PropTypes.bool,
    setDayPart: PropTypes.func,
    setTimeOnChange: PropTypes.bool,
  };

  state = {
    forceDayPicker: false,
    inputText: "",
  };

  _dayPickerInputRef = React.createRef();
  _haveFocusedDateInput = false;

  // --------------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        { this._renderDateInput() }
        { this._renderTimeInput() }
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _adjustDate = date => {
    this._setDate(date);
    this._closeDayPicker();
  };

  // --------------------------------------------------------------------------
  _closeDayPicker = () => {
    const { current: dayPickerInput } = this._dayPickerInputRef;
    if (dayPickerInput) dayPickerInput.hideDayPicker();
  };

  // --------------------------------------------------------------------------
  // Clear out the user's text so we fall back to a validly formatted date matching the `date` prop
  _onDateInputBlur = () => {
    this.setState({ inputText: null });
  };

  // --------------------------------------------------------------------------
  _onDateInputChange = event => {
    const inputText = event.target.value;
    this.setState({ forceDayPicker: true, inputText });
  };

  // --------------------------------------------------------------------------
  _onDateInputFocus = event => {
    const { selectExistingText, onFocus } = this.props;
    if (onFocus) onFocus(event);

    if (!this._haveFocusedDateInput) {
      if (selectExistingText && event.target.value) event.target.select();

      this._haveFocusedDateInput = true;
    }
  };

  // --------------------------------------------------------------------------
  _onDayPickerHide = () => {
    this.setState({ forceDayPicker: false });
  };

  // --------------------------------------------------------------------------
  _onTimeInputFocus = event => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(event);

    // This is the default behavior when focusing via the keyboard, but we want it to happen when focusing via a mouse
    // click as well
    event.target.select();
  };

  // --------------------------------------------------------------------------
  _parseDateText = text => {
    const { date } = this.props;
    const { inputText } = this.state;

    // react-day-picker will try to re-parse whatever date string we have, but we omit the year for dates this year,
    // while parseDateText assumes any date given is in the future. This would result in the calendar picker showing
    // next year if `date` is in the past (but this year). We want to show the calendar picker for this year until the
    // user has entered something different in the input, as that would indicate they want a _new_ date and presumably
    // want it to be in the future.
    if (!inputText && date && text === formatDate(date)) {
      return date;
    }

    return parseDateText(text);
  };

  // --------------------------------------------------------------------------
  _renderDateInput = () => {
    const { adjustPopupType, autoFocus, date, disabled, onKeyDown, placeholder } = this.props;
    const { forceDayPicker, inputText } = this.state;

    let className = `date-input ${ date !== null ? "valid" : "invalid" }`;
    if (this.props.className) className += ` ${ this.props.className }`;

    const inputProps = {
      autoFocus,
      className,
      disabled,
      onBlur: this._onDateInputBlur,
      onChange: this._onDateInputChange,
      onFocus: this._onDateInputFocus,
      onKeyDown,
      onKeyPress: blurTargetOnEnter,
    };

    const extraProps = {};

    if (adjustPopupType === "none") {
      extraProps.overlayComponent = renderNothing;
    } else if (!forceDayPicker && (inputText || date)) {
      extraProps.overlayComponent = this._renderQuickAdjustDropdown;
    }

    return (
      <DayPickerInput
        { ...extraProps }
        classNames={ DAY_PICKER_INPUT_CLASS_NAMES }
        dayPickerProps={ { firstDayOfWeek: getWeekStartsOn() } }
        formatDate={ formatDate }
        inputProps={ inputProps }
        onDayChange={ this._setDay }
        onDayPickerHide={ this._onDayPickerHide }
        parseDate={ this._parseDateText }
        placeholder={ placeholder || `e.g. ${ formatDate(Date.now()) }` }
        ref={ this._dayPickerInputRef }
        // Uncomment to make the popup show before the input is focused, allowing for easier styling
        // showOverlay
        value={ inputText || date || "" }
      />
    );
  };

  // --------------------------------------------------------------------------
  _renderQuickAdjustDropdown = ({ selectedDay, month, input, classNames, ...extraProps }) => {
    const { adjustPopupType, date } = this.props;

    return (
      <DateAdjustPopup
        close={ this._closeDayPicker }
        date={ date }
        dateType={ adjustPopupType }
        extraProps={ extraProps }
        onDateChange={ this._adjustDate }
        selectDay={ this._showDayPicker }
      />
    );
  };

  // --------------------------------------------------------------------------
  _renderTimeInput = () => {
    const {
      adjustPopupType,
      className,
      date,
      dayPart,
      disabled,
      onFocus,
      onKeyDown,
      setDayPart,
    } = this.props;

    const timestamp = date ? Math.floor(date.getTime() / 1000) : null;
    const duration = timestamp ? durationFromTimestamp(timestamp, date) : "";

    if (setDayPart) {
      return (
        <FuzzyTimeInput
          className={ className }
          dayPart={ dayPart }
          disabled={ disabled }
          duration={ duration }
          onFocus={ onFocus }
          onKeyDown={ onKeyDown }
          setDayPart={ setDayPart }
          setDuration={ this._setTime }
        />
      );
    } else {
      const { setTimeOnChange } = this.props;

      return (
        <TimeInput
          className={ className }
          disabled={ disabled }
          disableSuggestions={ adjustPopupType === "none" }
          duration={ duration }
          onFocus={ this._onTimeInputFocus }
          onKeyDown={ onKeyDown }
          setDuration={ this._setTime }
          setDurationOnChange={ setTimeOnChange }
        />
      );
    }
  };

  // --------------------------------------------------------------------------
  _setDate = (newDate, allowDefaultTime = true) => {
    const { dayPart, date, setDayPart } = this.props;

    let newDayPart = null;

    if (allowDefaultTime && newDate) {
      if (setDayPart && dayPart) {
        newDayPart = dayPart;
      } else if (newDate.getHours() === 0 && newDate.getMinutes() === 0) {
        // Defaults to 9am
        const hours = date ? date.getHours() : 9;
        const minutes = date ? date.getMinutes() : 0;

        if (hours) newDate = addHours(newDate, hours);
        if (minutes) newDate = addMinutes(newDate, minutes);

        if (setDayPart && !date) newDayPart = DAY_PART.MORNING;
      }
    }

    if (!newDayPart && newDate && date && isEqual(newDate, date)) return;

    this.props.onDateChange(newDate, newDayPart);
  };

  // --------------------------------------------------------------------------
  // DayPickerInput likes to give dates that are at 12 noon, but we'd prefer them to be 12 am
  _setDay = date => {
    this._setDate(date ? startOfDay(date) : null);
  };

  // --------------------------------------------------------------------------
  _setTime = duration => {
    const timestamp = duration ? timestampFromDuration(duration, this.props.date) : null;
    this._setDate(timestamp ? new Date(timestamp * 1000) : null, !timestamp);
  };

  // --------------------------------------------------------------------------
  _showDayPicker = () => {
    this.setState({ forceDayPicker: true });
  };
}

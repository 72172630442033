import { VISIBLE_LIST_ITEM_CLASS } from "lib/ample-editor/lib/collapsible-defines"
import { insertPendingImage } from "lib/ample-editor/lib/file-commands"
import { indentFromListElement } from "lib/ample-editor/lib/list-item-util"
import { CELL_CONTENT_CLASS, CELL_INNER_CONTENT_CLASS } from "lib/ample-editor/lib/table/table-schema"
import TRANSACTION_META_KEY from "lib/ample-editor/lib/transaction-meta-key"

// --------------------------------------------------------------------------
const BR_TAG_NAME = "BR";
const LI_TAG_NAME = "LI";
const PARAGRAPH_TAG_NAME = "P";
const DIV_TAG_NAME = "DIV";

// --------------------------------------------------------------------------
// We represent list nodes as divs, to work around browser-specific contentEditable bugs with ul/ol + li nodes, but we
// want to expose that to external tools (via paste) as ul > li (or ol > li) nodes, combining any sequential list items
// into a single ol/ul parent so there isn't a huge amount of spacing when pasted in the external tool.
export function convertToListNodes(domFragment, selector, wrapperNodeType) {
  // Convert all list item divs to nested ul/ol > li nodes
  const listItems = domFragment.querySelectorAll(`div${ selector }`);
  let rootWrapper;

  Array.from(listItems).forEach(divNode => {
    if (!rootWrapper || divNode.previousSibling !== rootWrapper) {
      // Start a new list item group
      rootWrapper = document.createElement(wrapperNodeType);
      rootWrapper.className = `indent-${ indentFromListElement(divNode) }`;
      insertDivNodeAsListItemInWrapper(divNode, rootWrapper);
      divNode.parentNode.replaceChild(rootWrapper, divNode);
    } else {
      // Proceed through a contiguous block of divNode elements to append to rootWrapper
      const groupListItems = Array.from(rootWrapper.querySelectorAll("li"));
      const lastListItem = groupListItems[groupListItems.length - 1];
      const lastItemIndent = indentFromListElement(lastListItem.parentNode)
      const divIndent = indentFromListElement(divNode);
      let insertDivNodeInEl = lastListItem.closest(wrapperNodeType);
      let insertDivNodeInElIndent = null;

      if (divIndent < lastItemIndent) {
        insertDivNodeInEl = lastListItem.parentNode.closest(`${ wrapperNodeType }.indent-${ divIndent }`) || rootWrapper;
      } else {
        // While divNode is more indented than our last wrapper node, create new nodes and traverse into them
        while (divIndent > (insertDivNodeInElIndent = indentFromListElement(insertDivNodeInEl))) {
          const wrapper = document.createElement(wrapperNodeType);
          wrapper.className = `indent-${ insertDivNodeInElIndent + 1 }`;
          insertDivNodeInEl.appendChild(wrapper);
          insertDivNodeInEl = wrapper;
        }
      }

      insertDivNodeAsListItemInWrapper(divNode, insertDivNodeInEl);
      divNode.parentNode.removeChild(divNode);
    }
  });
}

// --------------------------------------------------------------------------
// If paragraph is empty, we need to add a <br> for it to paste as a newline in various external apps, including
// GMail, depending on whether paragraph's parent imparts break
export function insertParagraphLineBreaks(domFragment) {
  const paragraphs = domFragment.querySelectorAll("p");
  Array.from(paragraphs).forEach(paragraphNode => {
    if (paragraphNode.innerHTML.length) {
      // We want the same treatment (as empty) for an empty paragraph that only contains a break tag
      const isEmptyParagraphWithBreak = paragraphNode.children.length === 1 &&
        paragraphNode.firstChild && paragraphNode.firstChild.tagName === BR_TAG_NAME;

      if (!isEmptyParagraphWithBreak) return;
    }

    const divNode = document.createElement("div");
    divNode.innerHTML = paragraphNode.innerHTML;

    const parentNode = paragraphNode.parentNode;
    let parentImpartsBreak = false;
    if (parentNode) {
      const elementImpartsBreak = [ LI_TAG_NAME, PARAGRAPH_TAG_NAME ].includes(parentNode.tagName);
      const divImpartsBreak = parentNode.tagName === DIV_TAG_NAME &&
        (parentNode.matches("[class*='list-item']") || parentNode.classList.contains(CELL_INNER_CONTENT_CLASS) ||
          parentNode.classList.contains(CELL_CONTENT_CLASS));
      parentImpartsBreak = elementImpartsBreak || divImpartsBreak;
    }

    if (!parentImpartsBreak) {
      divNode.append(document.createElement("br"));
    }

    paragraphNode.replaceWith(divNode);
  });
}

// --------------------------------------------------------------------------
// In Mobile Safari on iOS 11.4 and later, copied images are pasted as:
//   <img src="blob:http://www.example.com/3955202440-AeFf-4a9e-b82c-cae3822d96d4"/>
// Where "http://www.example.com/" is the page the user is on. We need to fetch this image so we can upload
// a real image, since this is only available on the local device (see https://stackoverflow.com/a/48481872). It
// would be nice to intercept this sooner, but the clipboard data associated with the paste is a "text/uri-list"
// where using getData("URL") returns the _original_ URL of the image, not the locally cached version that is
// represented in the blob. We'd much rather use the locally cached version.
export function handleMobileSafariBlobImagePaste(view, slice, pos) {
  let handledBlobImagePaste = false;

  slice.content.forEach(node => {
    if (handledBlobImagePaste) return false;

    if (node.type.name === "image") {
      const { attrs: { src } } = node;

      if (src.indexOf("blob:") === 0) {
        handledBlobImagePaste = true;

        insertPendingImage(view, fetch(src).then(response => response.blob()), pos, {
          insertTransformCallback: transform => {
            transform.deleteSelection().scrollIntoView().setMeta(TRANSACTION_META_KEY.PASTE, true);
          }
        });
      }

      return false;
    }

    return true;
  });

  return handledBlobImagePaste;
}

// --------------------------------------------------------------------------
// List item nodes contain paragraphs, with the paragraph containing the actual content. Leaving the <p> tags in the
// resulting list item html is often interpreted as extra spacing in external applications (e.g. GMail). We'll unwrap
// the content and drop the <p> tag for a better paste outside of the editor
export function unwrapListItemContent(domFragment) {
  const paragraphs = domFragment.querySelectorAll("ol.number-list-item > li > p, ul.bullet-list-item > li > p");

  Array.from(paragraphs).forEach(paragraph => {
    while (paragraph.firstChild) {
      paragraph.parentNode.append(paragraph.firstChild);
    }
    paragraph.parentNode.removeChild(paragraph);
  });
}

// --------------------------------------------------------------------------
// Local functions
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
function insertDivNodeAsListItemInWrapper(divNode, wrapperEl) {
  const liNode = document.createElement("li");

  const contentElements = extractInnerContent(divNode);

  Object.keys(divNode.dataset).forEach(dataAttributeName => {
    liNode.dataset[dataAttributeName] = divNode.dataset[dataAttributeName];
  });

  liNode.append(...contentElements);

  wrapperEl.appendChild(liNode);
}

// --------------------------------------------------------------------------
// Traverse into a list node until we get to the inner content within the default element wrappings used by AN/Prosemirror
// In particular we want to return the contents inside the <p> element, so we don't end up with a bunch of vertical padding when pasting list items to Gmail
function extractInnerContent(listNode) {
  const { children, firstChild } = listNode;

  const isOuterBulletContainer = firstChild && firstChild instanceof HTMLElement && children.length === 1 &&
    (firstChild.tagName === PARAGRAPH_TAG_NAME || firstChild.classList.contains(VISIBLE_LIST_ITEM_CLASS) ||
      firstChild.tagName === DIV_TAG_NAME);

  if (isOuterBulletContainer) {
    return extractInnerContent(firstChild);
  } else if (listNode.tagName === PARAGRAPH_TAG_NAME || listNode.tagName === DIV_TAG_NAME) {
    // If the paragraph node has element children, we'll keep them grouped under a div element that doesn't carry
    // implicit margin like a <p>
    if (children.length) {
      const divEl = document.createElement("div");
      listNode.childNodes.forEach(childNode => {
        divEl.appendChild(childNode.cloneNode(true));
      });
      return [ divEl ];
    } else {
      return Array.from(listNode.childNodes.values());
    }
  } else {
    return [ listNode ];
  }
}


import {
  addMinutes,
  addSeconds,
  areIntervalsOverlapping,
  differenceInMinutes,
  endOfHour,
  setHours,
  setMinutes,
  startOfHour,
  startOfMinute,
} from "date-fns"
import { sortBy } from "lodash"

import { advanceDateForDurationParams, durationParamsFromDuration, minutesFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const TIME_SLOT_MINUTES = 30;
const TIME_SLOT_SECONDS = TIME_SLOT_MINUTES * 60

// --------------------------------------------------------------------------
function findTasks(documentContent, exceptUUID, fromDate, toDate) {
  const interval = { start: fromDate, end: toDate };

  const tasks = [];

  findTasksHelper(documentContent, exceptUUID, interval, tasks);

  if (documentContent.attrs) {
    const { attrs: { hiddenTasks } } = documentContent;
    if (hiddenTasks) {
      hiddenTasks.forEach(hiddenTask => {
        const { attrs: { uuid } } = hiddenTask;

        if (uuid !== exceptUUID) {
          const task = taskIfOverlapping(hiddenTask.attrs, interval);
          if (task) tasks.push(task);
        }
      });
    }
  }

  return tasks;
}

// --------------------------------------------------------------------------
function findTasksHelper(node, exceptUUID, interval, tasks) {
  (node.content || []).forEach(childNode => {
    if (childNode.type === "check_list_item") {
      const { attrs: { uuid } } = childNode;

      if (uuid !== exceptUUID) {
        const task = taskIfOverlapping(childNode.attrs, interval);
        if (task) tasks.push(task);
      }
    } else if (childNode.type === "tasks_group") {
      // Only valid in tasksSchema documents (i.e. in the TasksEditor)
      findTasksHelper(childNode, exceptUUID, interval, tasks);
    }
  });

  return null;
}

// --------------------------------------------------------------------------
function taskIfOverlapping(attrs, interval) {
  const timeSlotRange = timeSlotRangeFromTaskAttributes(attrs);
  if (!timeSlotRange) return null;

  const [ startDate, endDate ] = timeSlotRange;

  if (!areIntervalsOverlapping({ start: startDate, end: endDate }, interval)) return null;

  return { startDate, endDate, uuid: attrs.uuid };
}

// --------------------------------------------------------------------------
function timeSlotRangeFromTaskAttributes({ due, duration }) {
  if (!due) return null;

  const dueDate = new Date(due * 1000);

  const startDate = startOfMinute(setMinutes(
    dueDate,
    TIME_SLOT_MINUTES * Math.floor(dueDate.getMinutes() / TIME_SLOT_MINUTES)
  ));

  const durationParams = duration ? durationParamsFromDuration(duration) : null;

  let endDate;
  if (durationParams) {
    endDate = advanceDateForDurationParams(durationParams, startDate);
  } else {
    endDate = addSeconds(startDate, TIME_SLOT_SECONDS);
  }

  return [ startDate, endDate ];
}

// --------------------------------------------------------------------------
export function weightedTimeSlots(documentContent, { due, duration, uuid }, firstHour, lastHour, prioritizeHour = null) {
  let dueDate = due ? new Date(due * 1000) : new Date();

  const hour = dueDate.getHours();
  if (hour < firstHour || hour > lastHour) {
    const newHour = Math.floor((firstHour + lastHour) / 2);
    dueDate = setMinutes(setHours(dueDate, newHour), 0);
  }

  const minutesNeeded = minutesFromDuration(duration) || TIME_SLOT_MINUTES;

  const fromDate = startOfHour(setHours(dueDate, firstHour));
  const toDate = addMinutes(endOfHour(setHours(dueDate, lastHour)), minutesNeeded);

  const tasks = findTasks(documentContent, uuid, fromDate, toDate);

  // 1. Divide the scheduling period into time slots

  const timeSlots = new Array(Math.ceil(differenceInMinutes(toDate, fromDate) / TIME_SLOT_MINUTES));
  for (let i = 0; i < timeSlots.length; i++) {
    timeSlots[i] = {
      cost: 0,
      startDate: addMinutes(fromDate, i * TIME_SLOT_MINUTES),
      taskCount: 0,
    };
  }

  // 2. Mark time slots occupied by existing tasks

  for (let i = 0; i < tasks.length; i++) {
    const { startDate, endDate } = tasks[i];

    const startTimeSlot = Math.floor(Math.max(0, differenceInMinutes(startDate, fromDate)) / TIME_SLOT_MINUTES);
    const timeSlotsCount = Math.ceil(differenceInMinutes(endDate, startDate) / TIME_SLOT_MINUTES);
    for (let slot = startTimeSlot; slot < Math.min(timeSlots.length, startTimeSlot + timeSlotsCount); slot++) {
      timeSlots[slot].taskCount += 1;
    }
  }

  // 3. Calculate a cost to schedule the task in each time slot as the total number of tasks that would overlap the
  //    task we are scheduling if it were placed in each slot, and how close it is to the prioritized hour

  const slotsNeeded = Math.min(timeSlots.length, Math.ceil(minutesNeeded / TIME_SLOT_MINUTES));

  // We want to prioritize slots closest to prioritizeHour, allowing a schedule to start filling around a certain
  // time slot instead of always from the first slot (e.g. if you have a time slot of midnight -> 6 am, we might want
  // to prefer to fill up the slots around 4 am first, moving on to earlier times as the later times fill up).
  if (prioritizeHour === null) prioritizeHour = Math.floor((lastHour - firstHour) / 2);
  const prioritizeSlot = Math.floor(((prioritizeHour - firstHour) * 60) / TIME_SLOT_MINUTES);

  for (let i = 0; i < timeSlots.length; i++) {
    for (let offset = 0; offset < slotsNeeded; offset++) {
      const { taskCount } = timeSlots[i + offset] || {};
      if (taskCount) timeSlots[i].cost += taskCount;
    }

    // Note this is always under 1, so it never overcomes another task being scheduled in a slot
    timeSlots[i].cost += Math.abs(i - prioritizeSlot) / timeSlots.length;
  }

  return sortBy(timeSlots, ({ cost }) => cost).map(({ startDate }) => startDate);
}

// --------------------------------------------------------------------------
export default function scheduleTask(documentContent, { due, duration, uuid }, firstHour, lastHour, prioritizeHour = null) {
  const [ startDate ] = weightedTimeSlots(documentContent, { due, duration, uuid }, firstHour, lastHour, prioritizeHour);

  return Math.floor(startDate.getTime() / 1000);
}

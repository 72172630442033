import {
  bucketFromTaskValue,
  calculateTaskValue,
  flagsObjectFromFlagsString,
  TASK_COMPLETION_EFFECT_LEVEL_EM
} from "lib/ample-util/tasks"
import { v4 as uuidv4 } from "uuid"
import { Decoration } from "prosemirror-view"

const MINIMAL_TASK_OUT_MS = 500;
const NORMAL_TASK_OUT_MS = 700;
const DAZZLE_LONGEST_DURATION = 2000;
const DAZZLE_BLUE_DURATION = 1000;
const MARIO_ANIMATION_DURATION = 1300;
const DAZZLE_POINTS_PER_TRACER = 10;
const DAZZLE_TRACER_MIN_COUNT = 3;
const DAZZLE_TRACER_MAX_COUNT = 10;
const HIGH_VALUE_DURATION = 1000;
const IMPORTANT_DURATION = 700;
const MAX_LASER_HEIGHT_PX = 100;

// --------------------------------------------------------------------------
// To determine how grand an effect to play, final Task Score is calculated then subtracted by portion
// attributable to start time
const completionEffectTaskScore = checkListAttrs => {
  const calculatedPoints = calculateTaskValue({ ...checkListAttrs, due: null });

  // times 10 divided 10 to reduce likelihood of a floating point value for calculatedPoints (such as 4.9999 observed) gets treated as nearest integer
  const taskScoreRoundedToTenths = Math.round((calculatedPoints) * 10) / 10;
  return taskScoreRoundedToTenths;
}

// --------------------------------------------------------------------------
const appendIcon = (rootEl, className) => {
  const iconContainer = document.createElement("div");
  iconContainer.className = `${ className } icon-container`;
  const icon = document.createElement("div");
  icon.className = "icon";
  iconContainer.append(icon)
  rootEl.append(iconContainer);
  return iconContainer;
};

// --------------------------------------------------------------------------
const hideDomNode = domNode => {
  if (domNode) domNode.style.visibility = "hidden";
};

// --------------------------------------------------------------------------
const createNormalAnimationElements = (animatedIconContainer, newDomNode, checkListAttrs, important) => {
  const domClasses = newDomNode.classList;
  let directionClass, rotationClass;
  let animationTime = NORMAL_TASK_OUT_MS;

  createDoneIcon(newDomNode);

  if (domClasses.contains("value-0") || domClasses.contains("value-1")) {
    animationTime = MINIMAL_TASK_OUT_MS;
  } else if (domClasses.contains("value-2") || domClasses.contains("value-3")) {
    rotationClass = Math.floor(Math.random() * 2) === 1 ? "rotate-left" : "rotate-right";
    directionClass = Math.floor(Math.random() * 2) === 1 ? "out-left" : "out-right";
    appendIcon(animatedIconContainer, `complete-icon ${ directionClass } ${ rotationClass } yellow`);
  }

  if (domClasses.contains("value-3")) {
    directionClass = directionClass === "out-left" ? "out-right" : "out-left";
    rotationClass = rotationClass === "rotate-left" ? "rotate-right" : "rotate-left"
    appendIcon(animatedIconContainer, `red complete-icon ${ directionClass } ${ rotationClass } rainbow`);
    animationTime = HIGH_VALUE_DURATION;
  }

  if (important) {
    appendIcon(animatedIconContainer, "important");
    animationTime = Math.max(IMPORTANT_DURATION, animationTime);
  }

  return animationTime;
}

// --------------------------------------------------------------------------
const createStarPoofAnimation = animatedIconContainer => {
  const starsToGenerate = 4;
  const directions = { 0: "northwest", 1: "northeast", 2: "southeast", 3: "southwest" };
  const direction = Math.round(Math.random()) === 0 ? "clockwise" : "counter-clockwise";
  const startIndex = Math.round(Math.random() * starsToGenerate);
  for (let i = 0; i < starsToGenerate; i++) {
    const index = [ (i + startIndex) % starsToGenerate ];
    const directionIndex = direction === "clockwise" ? index : (starsToGenerate - 1 - index);
    appendIcon(animatedIconContainer, `dazzle-star-icon ${ directions[directionIndex] } launch-${ index }`);
  }
}

// --------------------------------------------------------------------------
const createTracerStarAnimation = (animatedIconContainer, taskScore) => {
  const animalCandidates = { 0: "bazooka-bunny", 1: "slaughtering-sloth" };
  const random = Math.floor(Math.random() * Object.keys(animalCandidates).length);
  const chosenAnimal = animalCandidates[random];
  appendIcon(animatedIconContainer, `dazzle-antagonist ${ chosenAnimal }`);

  const tracerCount = Math.min(DAZZLE_TRACER_MAX_COUNT, Math.max(DAZZLE_TRACER_MIN_COUNT, taskScore / DAZZLE_POINTS_PER_TRACER));
  const colorMap = { 0: "red", 1: "orange", 2: "yellow", 3: "rainbow", 4: "purple" };
  for (let index = 0; index < tracerCount; index++) {
    const color = colorMap[index % Object.keys(colorMap).length];
    appendIcon(animatedIconContainer, `dazzle-tracer-icon ${ color } tracer-${ index }`);
  }
}

// --------------------------------------------------------------------------
const createLaserCatAnimation = animatedIconContainer => {
  appendIcon(animatedIconContainer, "dazzle-antagonist laser-cat");
  appendIcon(animatedIconContainer, "laser-cat-eyes");
  appendIcon(animatedIconContainer, "laser-beam");

  const container = animatedIconContainer.querySelector(".laser-beam");
  const leftEye = document.createElement("div");
  leftEye.className = "left-eye-laser-beam";
  container.append(leftEye);
}

// --------------------------------------------------------------------------
const createMarioJumpAnimation = (animatedIconContainer, newDomNode) => {
  appendIcon(animatedIconContainer, "jump-box");
  appendIcon(animatedIconContainer, "mario-jump");
  appendIcon(animatedIconContainer, "dazzle-star-icon animation-delayed northeast launch-0");
  const iconEl = animatedIconContainer.querySelector(".mario-jump");
  const imageEl = document.createElement("img");
  imageEl.classList.add("mario-data")
  imageEl.src = "";
  import(/* webpackChunkName:"animation-data" */"lib/ample-editor/lib/completion-animation/animation-data").then(animationData => {
    imageEl.src = animationData["MARIO_DATA"];
  });
  iconEl.append(imageEl);
  newDomNode.classList.add("animation-delayed");
}

// --------------------------------------------------------------------------
const createDoneIcon = newDomNode => {
  const checkbox = newDomNode.querySelector(".input-container .checkbox");
  const doneIconContainer = document.createElement("span");
  doneIconContainer.className = "checked-done-icon-container"
  const doneIcon = document.createElement("span");
  doneIcon.className = "checked-done-icon material-icons";
  doneIcon.textContent = "done";
  doneIconContainer.append(doneIcon);
  checkbox.after(doneIconContainer);
}

// --------------------------------------------------------------------------
const createDazzleAnimationElements = (animatedIconContainer, newDomNode, originalDomNode, checkListAttrs, important) => {
  const domClasses = newDomNode.classList;
  if (domClasses.contains("value-0") || domClasses.contains("value-1")) {
    createDoneIcon(newDomNode);
  }
  const taskScore = completionEffectTaskScore(checkListAttrs);
  let animationTime = DAZZLE_BLUE_DURATION;

  if (domClasses.contains("value-1")) {
    newDomNode.querySelector(".checked-done-icon").style.top = 0;
    newDomNode.classList.add("spin-done-icon");
  } else if (domClasses.contains("value-2")) {
    if (Math.round(Math.random() * 2) < 1) {
      createMarioJumpAnimation(animatedIconContainer, newDomNode);
      animationTime = MARIO_ANIMATION_DURATION;
    } else {
      createStarPoofAnimation(animatedIconContainer);
    }
  } else if (domClasses.contains("value-3")) {
    newDomNode.classList.add("no-cross-out")
    // 2/3rds chance of getting laser cat if height of task line doesn't exceed max laser cat height
    if (originalDomNode.offsetHeight < MAX_LASER_HEIGHT_PX && Math.round(Math.random() * 2) < 2) {
      createLaserCatAnimation(animatedIconContainer);
    } else {
      createTracerStarAnimation(animatedIconContainer, taskScore);
    }
    animationTime = DAZZLE_LONGEST_DURATION;
  } else {
    animationTime = MINIMAL_TASK_OUT_MS; // Don't unduly delay a value-0 task
  }

  if (important) {
    appendIcon(animatedIconContainer, "important");
    animationTime = Math.max(IMPORTANT_DURATION, animationTime);
  }

  return animationTime;
}

// --------------------------------------------------------------------------
const createAnimationElements = (document, newDomNode, originalDomNode, checkListAttrs, effectLevelEm) => {
  let animationTime;

  const animatedIconContainer = document.createElement("div");
  animatedIconContainer.className = "animated-icons-container";
  const { important } = flagsObjectFromFlagsString(checkListAttrs.flags);

  if (effectLevelEm === TASK_COMPLETION_EFFECT_LEVEL_EM.NORMAL) {
    animationTime = createNormalAnimationElements(animatedIconContainer, newDomNode, checkListAttrs, important);
  } else if (effectLevelEm === TASK_COMPLETION_EFFECT_LEVEL_EM.DAZZLE) {
    animationTime = createDazzleAnimationElements(animatedIconContainer, newDomNode, originalDomNode, checkListAttrs, important);
  } else if (effectLevelEm === TASK_COMPLETION_EFFECT_LEVEL_EM.MINIMAL) {
    animationTime = MINIMAL_TASK_OUT_MS;
    createDoneIcon(newDomNode);
  }

  newDomNode.append(animatedIconContainer);
  return animationTime;
}

// --------------------------------------------------------------------------
// Based on effectLevelEm, create and append animation elements to document that correspond to the checkListAttrs
// of the completed task
export const startTaskCompletedEffects = (document, originalDomNode, checkListAttrs, nodePos, effectLevelEm) => {
  const newDomNode = originalDomNode.cloneNode(true);

  // In the Mobile Safari WebView on iOS 14, the cloned node has been a text node in at least one instance in the
  // wild (throwing `TypeError: ... querySelector is undefined`), presumably due to something unexpected happening
  // to the document (being replaced?) between the nodePos being calculated and nodeDOM being called above).
  if (!newDomNode.querySelector) return null;

  newDomNode.classList.add(`level-${ effectLevelEm }`);
  newDomNode.classList.remove(...[ "value-0", "value-1", "value-2", "value-3" ]);
  const taskScore = completionEffectTaskScore(checkListAttrs);
  let valueClass = `value-${ bucketFromTaskValue(taskScore) }`;
  if (valueClass === "value-3" && checkListAttrs.dismissedAt) valueClass = "value-2"; // No laser kitty for merely dismissing tasks, impressive though it may be
  newDomNode.classList.add(valueClass);

  let destroyAnimationMs = MINIMAL_TASK_OUT_MS;
  const checkbox = newDomNode.querySelector(".input-container .checkbox");
  if (checkbox) {
    destroyAnimationMs = createAnimationElements(document, newDomNode, originalDomNode, checkListAttrs, effectLevelEm);
  }

  // Remove child elements that increase height of completed task
  hideDomNode(newDomNode.querySelector(".details-container"));
  hideDomNode(newDomNode.querySelector(".widgets-bar"));

  newDomNode.classList.remove("expanded");
  newDomNode.classList.add("animating-completion");

  const key = uuidv4();
  const decoration = Decoration.widget(nodePos, newDomNode, { key });

  return { decoration, destroyAnimationMs, key, newDomNode };
}

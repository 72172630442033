/* eslint-disable */

import { subDays } from "date-fns"
import { sortBy } from "lodash"
import applyDevTools from "prosemirror-dev-tools"
import React from "react"
import { v4 as uuidv4 } from "uuid"

import { openAttachment, uploadAttachment, uploadMedia } from "api"
import FindInEditor from "find-in-editor"
import { INLINE_DETAILS_TYPE } from "lib/ample-editor/components/check-list-item/inline-details"
import TasksEditor from "lib/ample-editor/components/tasks-editor"
import { probeImage } from "lib/ample-editor/lib/image-util"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"
import { calculateTaskValue, isDoneFromTask } from "lib/ample-util/tasks"
import LocalFileStore from "local-file-store"
import snackbarQueue from "snackbar-queue"

// --------------------------------------------------------------------------
const initialTaskByUUID = {
  [uuidv4()]: {
    createdAt: Math.floor(subDays(Date.now(), 3).getTime() / 1000),
    groupId: "1",
    originalIndex: 0,
    content: [
      {
        type: "paragraph",
        content: [ { type: "text", text: "some task" } ]
      }
    ]
  },
  [uuidv4()]: {
    createdAt: Math.floor(subDays(Date.now(), 2).getTime() / 1000),
    groupId: "2",
    originalIndex: 0,
    content: [
      {
        type: "paragraph",
        content: [ { type: "text", text: "another task" } ]
      },
    ]
  },

  [uuidv4()]: {
    createdAt: Math.floor(subDays(Date.now(), 1).getTime() / 1000),
    groupId: "2",
    originalIndex: 1,
    content: [
      {
        type: "paragraph",
        content: [ { type: "text", text: "whatever task" } ]
      }
    ]
  }
};

// --------------------------------------------------------------------------
function openNoteLink(noteLink) {
  snackbarQueue.notify({ body: noteLink, title: "openNoteLink" });
}

// --------------------------------------------------------------------------
export default class TasksEditorDemo extends React.Component {
  _editorView = null;
  _localFileStore = null;
  _taskByUUID = initialTaskByUUID;
  _tasksEditorRef = React.createRef();

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      editorProps: {
        inlineCheckListItemDetailsType: INLINE_DETAILS_TYPE.CREATED,
        hostApp: {
          getPluginActions: pluginActionTypes => {
            const pluginActions = [];

            if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.INSERT_TEXT)) {
              pluginActions.push({
                icon: "extension",
                name: "Test Plugin",
                run: async editorContext => {
                  // Need to wait a tic before calling replaceSelection because we're not really async yet here (in
                  // this demo), while in the real use cases we are.
                  await new Promise(resolve => setTimeout(resolve, 1));
                  await editorContext.replaceSelection("*some markdown*");
                  return null;
                },
                type: PLUGIN_ACTION_TYPE.REPLACE_TEXT,
              });
            }

            if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.REPLACE_TEXT)) {
              pluginActions.push({
                icon: "extension",
                name: "Test Plugin",
                run: async (editorContext, text) => {
                  return `${ text } is the worst text\nIt has a newline now\n\nBleh`;
                },
                type: PLUGIN_ACTION_TYPE.REPLACE_TEXT,
              });
            }

            return pluginActions.map(pluginAction => Promise.resolve(pluginAction));
          },
          openAttachment,
          openNoteLink,
          startAttachmentUpload: this._startAttachmentUpload,
          startMediaUpload: this._startMediaUpload,
        },
      },
      includeSourceNotes: true,
      reverseGroupOrder: false,
      taskByUUID: this._taskByUUID,
      localFiles: LocalFileStore.loadLocalFiles(),
    };

    this._localFileStore = new LocalFileStore(
      () => this.state.localFiles,
      localFiles => { this.setState({ localFiles }); },
    );
  }

  // --------------------------------------------------------------------------
  render() {
    const { editorProps, includeSourceNotes, reverseGroupOrder, taskByUUID } = this.state;

    const taskByGroupId = {};
    Object.keys(taskByUUID).forEach(uuid => {
      const { content, deleted, groupId, ...attrs } = taskByUUID[uuid];

      if (deleted || isDoneFromTask(attrs)) return;

      const task = {
        attrs: { ...attrs, uuid },
        content,
      };

      task.note = {
        icon: "description",
        name: "some note",
        url: "https://www.amplenote.com/notes/1"
      };

      if (groupId in taskByGroupId) {
        taskByGroupId[groupId].push(task);
      } else {
        taskByGroupId[groupId] = [ task ];
      }
    });

    let groupIds = Object.keys(taskByGroupId).sort();
    if (reverseGroupOrder) groupIds = groupIds.reverse();

    const tasksWithGroup = groupIds.map(groupId => {
      const tasks = taskByGroupId[groupId];

      return {
        groupIcon: { person: null },
        groupId,
        groupName: `Group ${ groupId }`,
        tasks: sortBy(tasks, [
          checkListItem => -calculateTaskValue(checkListItem.attrs),
          // This gives us a stable ordering for equal-valued tasks when they are edited (editing moves it to the
          // end of the list since it's re-added to taskByUUID)
          checkListItem => checkListItem.attrs.originalIndex,
        ]),
      };
    });

    return (
      <React.Fragment>
        <div className="ample-editor">
          <TasksEditor
            dispatchChanges={ this._dispatchChanges }
            editorProps={ editorProps }
            includeSourceNotes={ includeSourceNotes }
            onEditorViewCreated={ this._onEditorViewCreated }
            ref={ this._tasksEditorRef }
            tasksWithGroup={ tasksWithGroup }
          />
        </div>

        <div className="debug-actions-row">
          <div>
            <a onClick={ this._switchGroupOrder }>Switch group order</a>
          </div>
          <div>
            <a onClick={ this._toggleInlineDetails }>Toggle inline details</a> | <a
            onClick={ this._toggleIncludeSourceNotes }>Toggle source notes</a>
          </div>
        </div>

        <div className="debug-actions-row">
          <FindInEditor editorRef={ this._tasksEditorRef }/>
        </div>
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _dispatchChanges = (changesByTaskUUID, _meta) => {
    let taskByUUID = this._taskByUUID;

    Object.keys(changesByTaskUUID).forEach(taskUUID => {
      let { added, deleted, ...changes } = changesByTaskUUID[taskUUID];

      const task = taskByUUID[taskUUID];
      if (!task) {
        if (added) {
          taskByUUID = { ...taskByUUID, [taskUUID]: added };
        }

        return;
      }

      if (added) {
        changes = { ...changes, ...added, deleted: false };
      }

      if (deleted) {
        changes = { ...changes, deleted: true };
      }

      if (Object.keys(changes).length === 0) return;
      taskByUUID = { ...taskByUUID, [taskUUID]: { ...task, ...changes } };
    });

    this._taskByUUID = taskByUUID;
    this.setState({ taskByUUID });
  };

  // --------------------------------------------------------------------------
  _onEditorViewCreated = editorView => {
    // Applying this again on a hot reload will result in an error
    if (!this._editorView) applyDevTools(editorView);
    this._editorView = editorView;
  };

  // --------------------------------------------------------------------------
  _startAttachmentUpload = (uuid, file, _pos) => {
    this._localFileStore.store(uuid, file, "attachment", null).then(() => {
      return this._uploadLocalAttachment(uuid, file);
    });
  };

  // --------------------------------------------------------------------------
  _startMediaUpload = (localFileUUID, file) => {
    const { current: tasksEditor } = this._tasksEditorRef;

    const objectURL = URL.createObjectURL(file);
    tasksEditor.replaceLocalFileURLs({ [localFileUUID]: objectURL });

    this._localFileStore.store(localFileUUID, file, "media", objectURL).then(() => {
      return this._uploadLocalMedia(localFileUUID, file);
    });
  };

  // --------------------------------------------------------------------------
  // This simulates some external change adjusting the order of the groups
  _switchGroupOrder = () => {
    this.setState({ reverseGroupOrder: !this.state.reverseGroupOrder });
    if (this._editorView) this._editorView.focus();
  };

  // --------------------------------------------------------------------------
  _toggleIncludeSourceNotes = () => {
    this.setState({ includeSourceNotes: !this.state.includeSourceNotes });
  };

  // --------------------------------------------------------------------------
  _toggleInlineDetails = () => {
    const {
      editorProps,
      editorProps: {
        inlineCheckListItemDetailsType: inlineCheckListItemDetailsTypeWas,
      }
    } = this.state;

    let inlineCheckListItemDetailsType;
    switch (inlineCheckListItemDetailsTypeWas) {
      case INLINE_DETAILS_TYPE.CREATED:
        inlineCheckListItemDetailsType = INLINE_DETAILS_TYPE.SCHEDULED;
        break;

      case INLINE_DETAILS_TYPE.SCHEDULED:
        inlineCheckListItemDetailsType = null;
        break;

      default:
        inlineCheckListItemDetailsType = INLINE_DETAILS_TYPE.CREATED;
        break;
    }

    // We're just using this to force a re-render, we don't actually consume it directly
    this.setState({ editorProps: { ...editorProps, inlineCheckListItemDetailsType } });
  };

  // --------------------------------------------------------------------------
  _uploadLocalAttachment = (localFileUUID, file) => {
    const { current: tasksEditor } = this._tasksEditorRef;

    return uploadAttachment(file).then(url => {
      tasksEditor.replaceLocalFileURLs({ [localFileUUID]: url });

      this._localFileStore.remove(localFileUUID);
    }).catch(error => {
      console.log("uploadLocalAttachment failed");
      console.error(error);
      tasksEditor.replaceLocalFileURLs({ [localFileUUID]: `local://${ localFileUUID }?failed` });
    });
  };

  // --------------------------------------------------------------------------
  async _uploadLocalMedia(localFileUUID, file) {
    const { current: tasksEditor } = this._tasksEditorRef;

    try {
      const imageInfo = await probeImage(file);
      const url = await uploadMedia(file, imageInfo ? imageInfo.mime : null);
      tasksEditor.replaceLocalFileURLs({ [localFileUUID]: url });
      this._localFileStore.remove(localFileUUID);
    } catch (error) {
      console.log(`uploadLocalMedia failed with "${ error.message || error.toString() }"`);
      console.error(error);
      tasksEditor.replaceLocalFileURLs({ [localFileUUID]: `local://${ localFileUUID }?failed` });
    }
  }
}

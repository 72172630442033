// --------------------------------------------------------------------------
function isMatchingReference(referenceText, noteName) {
  if (referenceText === noteName) return true;

  const [ referenceWithoutAnchor ] = referenceText.split("#", 2);
  if (referenceWithoutAnchor === noteName) return true;

  return false;
}

// --------------------------------------------------------------------------
export default function buildMismatchedReferencesByUrl(referencedNote, referencesByUrl) {
  const { name } = referencedNote || {};
  if (!name) return {};

  const mismatchedReferencesByUrl = {};

  Object.keys(referencesByUrl).forEach(url => {
    const references = referencesByUrl[url] || [];

    const mismatchedReferences = [];
    references.forEach(reference => {
      const { text, updateTextOptions } = reference;
      if (!updateTextOptions) return;

      if (!isMatchingReference(text, name)) {
        mismatchedReferences.push(reference);
      }
    });

    if (mismatchedReferences.length > 0) {
      mismatchedReferencesByUrl[url] = mismatchedReferences;
    }
  });

  return mismatchedReferencesByUrl;
}

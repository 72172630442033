import { isSafari } from "lib/ample-editor/lib/client-info"
import { VISIBLE_LIST_ITEM_CLASS } from "lib/ample-editor/lib/collapsible-defines"
import { listItemClassName, onListItemClick, onListItemTouchStart } from "lib/ample-editor/lib/list-item-view-util"

// --------------------------------------------------------------------------
export default class NumberListItemView {
  _uuid = null;

  // --------------------------------------------------------------------------
  constructor(editorView, getPos, node) {
    this._uuid = node.attrs.uuid;
    this.update = this.update.bind(this, editorView, getPos);

    // The full DOM structure of a bullet:
    // Outer wrapper: .bullet-list-item.indent-0.has-children this div defines the indent level
    // for the bullet, and dictates whether to expander icon (its before element). It possesses the full width of
    // the bullet element so it can receive a click event from the area left of the visible bullet
    // Inner wrapper: .visible-list-item this div houses the :before element whose ::marker is the visible bullet
    this.dom = document.createElement("div");

    this.dom.addEventListener("click", onListItemClick.bind(null, editorView, getPos));
    // WBH once saw Chrome recommend adding { passive: true } to this event, but testing that approach showed that it
    // allowed the cursor to move to the touched location, whereas this version keeps cursor in place (and keyboard
    // from popping up), as desired
    this.dom.addEventListener("touchstart", onListItemTouchStart.bind(null, editorView, getPos));

    this.contentDOM = document.createElement("div");
    this.contentDOM.className = VISIBLE_LIST_ITEM_CLASS;
    this.dom.appendChild(this.contentDOM);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  update(editorView, getPos, node) {
    if (node.type.name !== "number_list_item") return false;

    const { attrs: { indent, offset, uuid } } = node;

    // ProseMirror's view will repurpose a previous HTMLElement during render for optimization; this causes
    // undesired animation of the old bullet's margin-left to new bullet's margin-left, so is prevented
    if (this._uuid !== uuid) return false;
    this.dom.setAttribute("data-uuid", uuid);

    const { state } = editorView;
    let className = `number-list-item${ listItemClassName(state, node, getPos) }`;
    if (isSafari) className += " use-counter-reset";
    this.dom.className = className;

    if (offset) {
      if (isSafari) {
        this.dom.style = `counter-reset: number-list-items-${ indent } ${ offset }`;
      } else {
        // The counter-increment will be cancelled out by the counter-set used in browsers that support it, so
        // we can use a new counter-set with the offset instead
        this.dom.style = `counter-set: number-list-items-${ indent } ${ offset }`;
      }
    }

    return true;
  }
}

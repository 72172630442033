/* eslint-disable */

import { cloneDeep, mapValues } from "lodash"

import { dataURLFromBlob } from "lib/ample-editor/lib/image-util"

// --------------------------------------------------------------------------
export default class LocalFileStore {
  _getLocalFiles = null;
  _setLocalFiles = null;

  // --------------------------------------------------------------------------
  static loadLocalFiles() {
    return mapValues(JSON.parse(localStorage.getItem("localFiles")) || {}, ({ dataURL, kind }) => {
      // objectURLs don't survive a page refresh, so we'll fall back to the dataURL
      return { dataURL, kind, objectURL: null };
    });
  }

  // --------------------------------------------------------------------------
  constructor(getLocalFiles, setLocalFiles) {
    this._getLocalFiles = getLocalFiles;
    this._setLocalFiles = setLocalFiles;
  }

  // --------------------------------------------------------------------------
  remove = uuid => {
    console.log("clearing local file", uuid);

    this._modifyLocalFiles(localFiles => {
      const localFile = localFiles[uuid];
      if (localFile && localFile.objectURL) URL.revokeObjectURL(localFile.objectURL);

      delete localFiles[uuid];
    });
  };

  // --------------------------------------------------------------------------
  removeAll = () => {
    this._modifyLocalFiles(localFiles => {
      Object.keys(localFiles).forEach(uuid => {
        const localFile = localFiles[uuid];
        if (localFile && localFile.objectURL) URL.revokeObjectURL(localFile.objectURL);

        delete localFiles[uuid]
      });
    });
  };

  // --------------------------------------------------------------------------
  store = (uuid, file, kind, objectURL) => {
    console.log("storing local file", uuid);

    return dataURLFromBlob(file).then(dataURL => {
      this._modifyLocalFiles(localImages => {
        localImages[uuid] = { dataURL, kind, objectURL };
      });
    });
  };

  // --------------------------------------------------------------------------
  _modifyLocalFiles = callback => {
    const localFiles = cloneDeep(this._getLocalFiles());
    callback(localFiles);
    this._setLocalFiles(localFiles);

    try {
      localStorage.setItem("localFiles", JSON.stringify(localFiles));
    } catch (error) {
      // Likely QuotaExceededError
      console.error(error);
    }
  };
}

import { startOfDay } from "date-fns"
import { pick } from "lodash"
import { Fragment, Node } from "prosemirror-model"
import { Plugin, PluginKey } from "prosemirror-state"
import { insertPoint } from "prosemirror-transform"
import { v4 as uuidv4 } from "uuid"

import { transactionMarkedReadonly } from "lib/ample-editor/lib/transaction-util"
import { getExpandedTaskUUID } from "lib/ample-editor/plugins/check-list-item-plugin"
import { animateCompletedTask } from "lib/ample-editor/plugins/completed-check-list-items-plugin"
import AddCompletedTaskStep from "lib/ample-editor/steps/add-completed-task-step"
import AddHiddenTaskStep from "lib/ample-editor/steps/add-hidden-task-step"
import ChangeHiddenTaskAttrsStep from "lib/ample-editor/steps/change-hidden-task-attrs-step"
import RemoveHiddenTaskStep from "lib/ample-editor/steps/remove-hidden-task-step"
import SetAttrsStep from "lib/ample-editor/steps/set-attrs-step"
import {
  completedTaskFromCheckListItem,
  dailyTaskValue,
  isDoneFromTask,
  isHiddenFromTask,
  nextInstanceFromTask,
} from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
const listItemPluginKey = new PluginKey("list-item");

// --------------------------------------------------------------------------
// `nodePos` may be null if there isn't a corresponding node being completed (e.g. if the task is in the hidden tasks
// section)
function addCompletedTask(schema, transform, checkListItem, nodePos = null, originalNodePos = null) {
  const { attrs: { crossedOutAt } } = checkListItem;

  if (crossedOutAt) {
    const insertPos = insertPoint(
      transform.doc,
      nodePos !== null ? nodePos : transform.doc.content.size,
      schema.nodes.bullet_list_item
    ) || 0;

    const newNode = Node.fromJSON(schema, {
      ...checkListItem,
      // Clear out scheduling attributes - this is just an indication of a task that was completed, not a bullet
      // list item that represents a task scheduled at a particular time (i.e. we show the completed task on the
      // calendar, not this bullet list item)
      attrs: { ...pick(checkListItem.attrs, [ "collapsed", "indent", "uuid" ]), crossedOutAt },

      type: schema.nodes.bullet_list_item.name
    });
    transform.insert(insertPos, newNode);
    transform.addMark(insertPos, insertPos + newNode.nodeSize, schema.marks.strikethrough.create());
  }

  const completedTask = completedTaskFromCheckListItem(checkListItem);
  transform.step(new AddCompletedTaskStep(schema, completedTask));

  // Using originalNodePos because when this transaction is processed by completed-check-list-items-plugin-view, we will
  // be extracting cloned nodes from the nodeDOM that existed prior to mapping new nodePos
  if (originalNodePos !== null && nodePos !== null && !crossedOutAt) {
    animateCompletedTask(transform, checkListItem, nodePos, originalNodePos);
  }
}

// --------------------------------------------------------------------------
// Add points for each day the item is present in a note that is opened (starting the day _after_ it is created)
function addTaskPoints(transform, pointsAdditionCutoff, node, nodePos) {
  const { createdAt, points, pointsUpdatedAt } = node.attrs;

  // Don't add to items with no content
  if (node.childCount === 1 && node.textContent.length === 0) return;

  const timestamp = pointsUpdatedAt || createdAt;
  if (timestamp === null) return;

  if (timestamp < pointsAdditionCutoff) {
    const updatedAttributes = {
      ...node.attrs,
      points: (points || 0) + dailyTaskValue(node.attrs),
      pointsUpdatedAt: Math.floor(Date.now() / 1000),
    };

    transform.step(new SetAttrsStep(nodePos, updatedAttributes));
  }
}

// --------------------------------------------------------------------------
function applyHiddenTaskConstraints(state, uuids, transform, task) {
  const { schema } = state;
  const { attrs } = task;

  const uuid = ensureUniqueUUID(schema, uuids, transform, attrs);
  if (uuid !== attrs.uuid) task = { ...task, attrs: { ...attrs, uuid } };

  if (isDoneFromTask(attrs)) {
    addCompletedTask(schema, transform, task);
    updateHiddenTaskForCompletion(state, transform, task);
    return;
  }

  if (isHiddenFromTask(attrs)) return;

  // If the Task Detail is open for this item, the user may just be in the process of changing the properties, so we'll
  // wait until they close it to move it out of the container (if still pertinent at that point).
  const expandedTaskUUID = getExpandedTaskUUID(state);
  if (uuid !== null && uuid === expandedTaskUUID) return;

  moveFromHiddenTasks(schema, transform, task);
}

// --------------------------------------------------------------------------
function applyListItemNodeConstraints(state, pointsAdditionCutoff, uuids, transform, node, originalNodePos) {
  const { schema } = state;

  const nodePos = transform.mapping.map(originalNodePos);

  const uuid = ensureUniqueUUID(schema, uuids, transform, node.attrs, { node, nodePos });

  if (node.type === schema.nodes.check_list_item) {
    const { attrs } = node;

    // Move the item to the correct location, based on the attributes

    if (isDoneFromTask(attrs)) {
      updateTaskForCompletion(state, transform, node, nodePos, null);
      // Note this needs to be called after `updateTaskForCompletion`, as it may insert nodes at `nodePos`
      addCompletedTask(schema, transform, node.toJSON(), nodePos, originalNodePos);
      return;
    }

    if (isHiddenFromTask(attrs)) {
      const expandedTaskUUID = getExpandedTaskUUID(state);
      if (uuid !== expandedTaskUUID) {
        moveToHiddenTasks(schema, transform, node, nodePos);
        return;
      }
    }

    addTaskPoints(transform, pointsAdditionCutoff, node, nodePos);
  }
}

// --------------------------------------------------------------------------
function applyListItemNodeConstraintsInBlockquote(state, pointsAdditionCutoff, uuids, transform, blockquoteNode, blockquoteNodePos) {
  blockquoteNode.forEach((node, nodePos) => {
    if (!node.type.spec.isListItem) return;

    applyListItemNodeConstraints(
      state,
      pointsAdditionCutoff,
      uuids,
      transform,
      node,
      // `+ 1` to account for opening position of the blockquote
      blockquoteNodePos + 1 + nodePos,
    );
  });
}

// --------------------------------------------------------------------------
function applyListItemNodeConstraintsInTable(state, pointsAdditionCutoff, uuids, transform, tableNode, tableNodePos) {
  tableNode.forEach((rowNode, rowNodePos) => {
    rowNode.forEach((cellNode, cellNodePos) => {
      cellNode.forEach((node, nodePos) => {
        if (!node.type.spec.isListItem) return;

        applyListItemNodeConstraints(
          state,
          pointsAdditionCutoff,
          uuids,
          transform,
          node,
          // `+ 1`s to account for opening positions of each of the table/row/cell
          tableNodePos + 1 + rowNodePos + 1 + cellNodePos + 1 + nodePos,
        );
      });
    });
  });
}

// --------------------------------------------------------------------------
function ensureUniqueUUID(schema, uuids, transform, attributes, { node = null, nodePos = null } = {}) {
  let attributeUpdates = null;

  let { uuid } = attributes;
  if (typeof(uuid) === "undefined" || uuid === null || uuid in uuids) {
    // Id is not set or already taken (e.g. due to duplication) => generate and set a new id
    uuid = uuidv4();

    // Note that we're also setting creation time now
    attributeUpdates = { uuid, createdAt: Math.floor(Date.now() / 1000) };
  } else if ("createdAt" in attributes) {
    // The check-list-item might have been converted from a bullet-list-item or other node that has a `uuid` attribute,
    // in which case we still want a createdAt time set (since that other node type might not have one).
    const { createdAt } = attributes;
    if (!createdAt) {
      attributeUpdates = { createdAt: Math.floor(Date.now() / 1000) };
    }
  }
  uuids[uuid] = true;

  if (attributeUpdates !== null) {
    if (nodePos !== null) {
      try {
        transform.step(new SetAttrsStep(nodePos, { ...attributes, ...attributeUpdates }));
      } catch (error) {
        fixInvalidContentOrThrow(error, node, nodePos, schema, transform);
        transform.step(new SetAttrsStep(nodePos, { ...attributes, ...attributeUpdates }));
      }
    } else {
      transform.step(new ChangeHiddenTaskAttrsStep(schema, attributes, attributeUpdates))
    }
  }

  return uuid;
}

// --------------------------------------------------------------------------
// In some cases, this is the first chance we have to observe invalid node content (e.g. multiple paragraphs,
// which as of 11/2022 can happen in some unknown way) as this throws `RangeError: Invalid content ...`. We can
// fix the most basic (expected) cases at this point, hopefully leaving the note editable for the user.
function fixInvalidContentOrThrow(error, node, nodePos, schema, transform) {
  if (!(error instanceof RangeError) || !node || node.childCount < 2) throw error;

  let canJoinChildren = true;

  let newSiblingContent = Fragment.empty;
  let newParagraphContent = Fragment.empty;
  node.forEach(childNode => {
    if (childNode.type === schema.nodes.code_block) {
      newSiblingContent = newSiblingContent.append(Fragment.from(childNode));
      return;
    } else if (childNode.type !== schema.nodes.paragraph && childNode.type !== schema.nodes.heading) {
      canJoinChildren = false;
      return;
    }

    if (newParagraphContent.size > 0) {
      newParagraphContent = newParagraphContent.addToEnd(schema.nodes.hard_break.create());
    }
    newParagraphContent = newParagraphContent.append(childNode.content);
  });

  if (!canJoinChildren) throw error;

  transform.replaceRangeWith(
    nodePos + 1,
    nodePos + node.nodeSize - 1,
    schema.nodes.paragraph.createAndFill(null, newParagraphContent),
  );

  if (newSiblingContent.size > 0) {
    transform.insert(transform.doc.resolve(nodePos + 1).after(), newSiblingContent);
  }
}

// --------------------------------------------------------------------------
function moveFromHiddenTasks(schema, transform, hiddenTask) {
  let insertPos = null;

  for (let childIndex = 0, childPos = 0; childIndex < transform.doc.childCount; childIndex++) {
    const child = transform.doc.child(childIndex);

    if (child.type === schema.nodes.check_list_item) {
      insertPos = childPos;
      break;
    }

    childPos += child.nodeSize;
  }

  if (insertPos === null) {
    insertPos = insertPoint(transform.doc, transform.doc.content.size, schema.nodes.check_list_item) || 0;
  }

  transform.insert(transform.mapping.map(insertPos), Node.fromJSON(schema, hiddenTask));
  transform.step(new RemoveHiddenTaskStep(schema, hiddenTask));
}

// --------------------------------------------------------------------------
function moveToHiddenTasks(schema, transform, node, nodePos) {
  // We need to use the most up-to-date node, as the transform may _also_ have changed attributes of the node
  const updatedNode = transform.doc.nodeAt(nodePos);

  // This is probably FUD, but if something goes wrong, we don't want to move the wrong (type of) item
  if (!updatedNode || updatedNode.type !== node.type) return;

  transform.step(new AddHiddenTaskStep(schema, node.toJSON()));
  transform.delete(nodePos, nodePos + updatedNode.nodeSize);
}

// --------------------------------------------------------------------------
function updateHiddenTaskForCompletion(state, transform, hiddenTask) {
  const { schema } = state;

  const nextInstance = nextInstanceFromTask(hiddenTask);
  if (nextInstance !== null) {
    // We've already created a copy of the task in completedTasks, so if we're creating a new instance of a repeating
    // task we'll just update the attributes - including uuid - of the task we already have to create the new instance.
    const nextInstanceIsHidden = isHiddenFromTask(nextInstance.attrs);

    transform.step(new ChangeHiddenTaskAttrsStep(schema, hiddenTask.attrs, nextInstance.attrs));

    if (!nextInstanceIsHidden) {
      moveFromHiddenTasks(schema, transform, { ...hiddenTask, attrs: { ...hiddenTask.attrs, ...nextInstance.attrs } });
    }
  } else {
    transform.step(new RemoveHiddenTaskStep(schema, hiddenTask));
  }
}

// --------------------------------------------------------------------------
function updateTaskForCompletion(state, transform, node, nodePos) {
  const { schema } = state;

  const nextInstance = nextInstanceFromTask(node);
  if (nextInstance !== null) {
    // We've already created a copy of the task in completedTasks, so if we're creating a new instance of a repeating
    // task we'll just update the attributes - including uuid - of the task we already have to create the new instance.
    const nextInstanceIsHidden = isHiddenFromTask(nextInstance.attrs);

    const { doc } = transform;

    node = doc.nodeAt(nodePos);

    if (nextInstanceIsHidden) {
      transform.setNodeMarkup(nodePos, null, nextInstance.attrs);
      moveToHiddenTasks(schema, transform, transform.doc.nodeAt(nodePos), nodePos);
    } else {
      // And move it to the end of any set of contiguous check-list-items
      let childPos = nodePos;
      for (let childIndex = doc.resolve(childPos + 1).index(0); childIndex < doc.childCount; childIndex++) {
        const childNode = doc.child(childIndex);
        if (!childNode || childNode.type !== schema.nodes.check_list_item) break;
        childPos += childNode.nodeSize - 1;
      }

      if (childPos > nodePos) {
        const newNode = schema.nodes.check_list_item.create(nextInstance.attrs, node.content, node.marks);
        transform.insert(doc.resolve(childPos).after(1), newNode);
        transform.delete(nodePos, nodePos + node.nodeSize);
      } else {
        transform.setNodeMarkup(nodePos, null, nextInstance.attrs);
      }
    }
  } else {
    node = transform.doc.nodeAt(nodePos);

    transform.delete(nodePos, nodePos + node.nodeSize);
  }
}

// --------------------------------------------------------------------------
// Handles automatic changes to the document required to keep list items (whether in the document as
// bullet_list_item/check_list_item/number_list_item nodes, in hiddenTasks, or in completedTasks) in the correct state.
// --------------------------------------------------------------------------
export default function createListItemPlugin() {
  return new Plugin({
    // --------------------------------------------------------------------------
    // There are some constraints we'd like to ensure are applied to list items:
    //
    // 1. That each node gets its own unique uuid. This is vital to disambiguate when merging changes that may re-order
    //    lists seemingly replace the content of items with new text (which it would appear like if we didn't have a
    //    stable uuid for each list item).
    //
    // 2. (check_list_item only) Assign attributes that can't have a static default (e.g. "created at").
    //    See: https://discuss.prosemirror.net/t/release-0-23-0-possibly-to-be-1-0-0/959/17
    //
    // 3. (check_list_item only)  Ensure that the item is in the correct location, depending on the `startAt` and
    //    `startRule` attributes
    //
    // 4. (check_list_item only) Complete/dismiss a task if completedAt/crossedOutAt/dismissedAt attributes get set
    //
    appendTransaction: (transactions, oldState, newState) => {
      // Don't make changes if the transform says the editor is readonly
      if (transactions.find(transactionMarkedReadonly)) return;

      const { schema: { nodes: { blockquote: blockquoteType, table: tableType } } } = newState;

      const transform = newState.tr;
      const uuids = {};

      const pointsAdditionCutoff = Math.floor(startOfDay(new Date()).getTime() / 1000);

      newState.doc.forEach((node, nodePos) => {
        if (node.type.spec.isListItem) {
          applyListItemNodeConstraints(newState, pointsAdditionCutoff, uuids, transform, node, nodePos);
        } else if (node.type === blockquoteType) {
          applyListItemNodeConstraintsInBlockquote(newState, pointsAdditionCutoff, uuids, transform, node, nodePos);
        } else if (node.type === tableType) {
          applyListItemNodeConstraintsInTable(newState, pointsAdditionCutoff, uuids, transform, node, nodePos);
        }
      });

      newState.doc.attrs.hiddenTasks.forEach(hiddenTask => {
        applyHiddenTaskConstraints(newState, uuids, transform, hiddenTask);
      });

      if (transform.docChanged) return transform;
    },

    // --------------------------------------------------------------------------
    key: listItemPluginKey,
  });
}

import { get, omit } from "lodash"
import { closeHistory } from "prosemirror-history"
import { Fragment, Node } from "prosemirror-model"
import { insertPoint } from "prosemirror-transform"

import { buildRestoreCompletedTask } from "lib/ample-editor/commands/completed-task-commands"
import findCheckListItem from "lib/ample-editor/lib/find-check-list-item"
import { getExpandedTaskUUID, setExpandedTaskUUID } from "lib/ample-editor/plugins/check-list-item-plugin"
import { retainOpenLinkPos } from "lib/ample-editor/plugins/link-plugin"
import ChangeCompletedTaskStep from "lib/ample-editor/steps/change-completed-task-step"
import ChangeHiddenTaskAttrsStep from "lib/ample-editor/steps/change-hidden-task-attrs-step"
import ChangeHiddenTaskContentStep from "lib/ample-editor/steps/change-hidden-task-content-step"
import RemoveCompletedTaskStep from "lib/ample-editor/steps/remove-completed-task-step"
import RemoveHiddenTaskStep from "lib/ample-editor/steps/remove-hidden-task-step"
import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"
import { checkListItemFromCompletedTask, TASK_COMPLETION_MODE } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function findTask(doc, uuid, { matchBulletListItems = false } = {}) {
  const checkListItemResult = findCheckListItem(doc, uuid, { matchBulletListItems });
  if (checkListItemResult) return checkListItemResult;

  const hiddenTasks = doc.attrs.hiddenTasks || [];
  for (let i = 0; i < hiddenTasks.length; i++) {
    const hiddenTask = hiddenTasks[i];
    if (hiddenTask.attrs.uuid === uuid) return { hiddenTask, nodePos: null };
  }

  const completedTasks = doc.attrs.completedTasks || [];
  for (let i = 0; i < completedTasks.length; i++) {
    const completedTask = completedTasks[i];
    if (completedTask.uuid === uuid) return { completedTask, nodePos: null };
  }

  return { node: null, nodePos: null };
}

// --------------------------------------------------------------------------
export function completeTask(uuid, taskCompletionMode = TASK_COMPLETION_MODE.NORMAL) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const { hiddenTask, node, nodePos } = findTask(doc, uuid);
    if (!node && !hiddenTask) return false;

    if (dispatch) {
      const transaction = state.tr;

      const now = Math.floor(Date.now() / 1000);

      const updates = {};
      switch (taskCompletionMode) {
        case TASK_COMPLETION_MODE.CROSS_OUT:
          updates.crossedOutAt = now;
          break;

        case TASK_COMPLETION_MODE.DISMISS:
          updates.dismissedAt = now;
          break;

        default:
        case TASK_COMPLETION_MODE.NORMAL:
          updates.completedAt = now;
          break;
      }

      if (hiddenTask) {
        transaction.step(new ChangeHiddenTaskAttrsStep(schema, hiddenTask.attrs, updates));
      } else {
        transaction.setNodeMarkup(nodePos, null, { ...node.attrs, ...updates })
      }

      if (getExpandedTaskUUID(state) === uuid) {
        setExpandedTaskUUID(transaction, null);
      }

      if (node && nodePos !== null) {
        retainOpenLinkPos(
          linkPos => linkPos === null || linkPos < nodePos || linkPos > nodePos + node.nodeSize,
          state,
          transaction
        );
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function deleteTasks(taskUUIDs) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const tasks = taskUUIDs.map(taskUUID => findTask(doc, taskUUID)).filter(task => {
      return task.node || task.hiddenTask || task.completedTask;
    });
    if (tasks.length === 0) return false;

    if (dispatch) {
      const transaction = closeHistory(state.tr);

      tasks.forEach(({ completedTask, hiddenTask, node, nodePos }) => {
        if (completedTask) {
          transaction.step(new RemoveCompletedTaskStep(schema, completedTask));
        } else if (hiddenTask) {
          transaction.step(new RemoveHiddenTaskStep(schema, hiddenTask));
        } else {
          transaction.delete(
            transaction.mapping.map(nodePos),
            transaction.mapping.map(nodePos + node.nodeSize)
          );
          setExpandedTaskUUID(transaction, null);
        }
      });

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function uncompletedTaskExists(doc, uuid) {
  const { hiddenTask, node } = findTask(doc, uuid);
  return node || hiddenTask;
}

// --------------------------------------------------------------------------
// This is more generic version of updateTaskAttributes/updateTaskContent intended to match the behavior of the
// UPDATE_TASK note-content-action in client applications (so it can be applied to a note open in the editor)
export function updateTask(uuid, updates) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const { completedTask, hiddenTask, node, nodePos } = findTask(doc, uuid, { matchBulletListItems: true });
    if (!completedTask && !hiddenTask && !node) return false;

    if ((node && node.type === schema.nodes.bullet_list_item) || updates.isScheduledBullet) {
      // Translate to what we can update for bullet_list_items
      const bulletListItemUpdates = {};

      Object.keys(updates).forEach(key => {
        const value = updates[key];

        switch (key) {
          case "content":
            bulletListItemUpdates.content = value;
            break;

          case "due":
            bulletListItemUpdates.scheduledAt = value;
            break;

          default:
            if (key in DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"]) {
              bulletListItemUpdates[key] = value;
            }
            break;
        }
      });

      if (updates.isScheduledBullet === false) {
        bulletListItemUpdates.type = schema.nodes.check_list_item;

        bulletListItemUpdates.due = "scheduledAt" in bulletListItemUpdates
          ? bulletListItemUpdates.scheduledAt
          : node.attrs.scheduledAt;
      } else if (updates.isScheduledBullet === true) {
        // Converting check-list-item to bullet-list-item
        bulletListItemUpdates.isScheduledBullet = true;
        bulletListItemUpdates.type = schema.nodes.bullet_list_item;

        if (!("scheduledAt" in bulletListItemUpdates)) {
          let due = DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"];
          if (node) {
            due = node.attrs.due;
          } else if (hiddenTask) {
            due = hiddenTask.attrs.due;
          } else if (completedTask) {
            due = completedTask.due;
          }
          if (due) bulletListItemUpdates.scheduledAt = due;
        }
      }

      if (Object.keys(bulletListItemUpdates).length === 0) return false;

      updates = bulletListItemUpdates;
    }

    if (dispatch) {
      const transaction = state.tr;

      if (updates.completedAt) {
        const defaultTaskCompletionMode = get(doc, "attrs.storage.defaultTaskCompletionMode");
        if (defaultTaskCompletionMode === TASK_COMPLETION_MODE.CROSS_OUT) {
          if (!updates.crossedOutAt) updates.crossedOutAt = updates.completedAt;
          updates = omit(updates, [ "completedAt" ]);
        }
      }

      let { body, content, isScheduledBullet, type: newType, ...attrs } = updates;

      if (body) {
        content = [ { type: "paragraph", content: [ { type: "text", text: body } ] } ];
      }

      const haveAttrUpdates = Object.keys(attrs).length > 0;

      if (completedTask) {
        // We only support two cases for updates to completed tasks:
        if ("completedAt" in attrs && attrs.completedAt === null) {
          // 1. restoring them - this matches UPDATE_TASK note-content-action behavior, though we could support more,
          // with the caveat being that completed tasks store content as an unwrapped single paragraph node, which may
          // not be what we are receiving in `content` (it's always an array, but may contain more than one child, or the
          // one child may not be a paragraph - as of 9/2020 neither of those are expected cases, but nor are they
          // actually checked for, with a number of disparate code systems producing the updated content included in
          // UPDATE_TASK actions).
          return buildRestoreCompletedTask(uuid)(state, dispatch);
        } else if (isScheduledBullet === true) {
          // 2. converting a completed task to a scheduled bullet (though strictly speaking the "scheduled" part isn't
          // enforced, but in practise this can only happen on tasks with `due` set).
          transaction.step(new RemoveCompletedTaskStep(schema, completedTask));

          const checkListItem = checkListItemFromCompletedTask(completedTask);
          delete checkListItem.attrs.completedAt;
          delete checkListItem.attrs.crossedOutAt;
          delete checkListItem.attrs.dismissedAt;

          const bulletListItemNode = Node.fromJSON(schema, {
            attrs: { ...checkListItem.attrs, ...attrs },
            content: content || checkListItem.content,
            type: "bullet_list_item"
          });

          const insertPos = insertPoint(transaction.doc, transaction.doc.content.size, bulletListItemNode.type) || 0;
          transaction.insert(insertPos, bulletListItemNode);
        } else {
          return false;
        }
      } else if (hiddenTask) {
        if (isScheduledBullet === true) {
          transaction.step(new RemoveHiddenTaskStep(schema, hiddenTask));

          const bulletListItemNode = Node.fromJSON(schema, {
            attrs: { ...hiddenTask.attrs, ...attrs },
            content: content || hiddenTask.content,
            type: "bullet_list_item"
          });

          const insertPos = insertPoint(transaction.doc, transaction.doc.content.size, bulletListItemNode.type) || 0;
          transaction.insert(insertPos, bulletListItemNode);
        } else {
          if (content) transaction.step(new ChangeHiddenTaskContentStep(schema, uuid, hiddenTask.content, content));
          if (haveAttrUpdates) transaction.step(new ChangeHiddenTaskAttrsStep(schema, hiddenTask.attrs, attrs));
        }
      } else {
        // Task in document directly
        if (content) {
          transaction.replaceWith(nodePos + 1, nodePos + node.nodeSize, Fragment.fromJSON(schema, content));
        }

        if (haveAttrUpdates || newType) {
          transaction.setNodeMarkup(nodePos, newType || null, { ...node.attrs, ...attrs })
        }
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function updateTaskAttributes(uuid, updates) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const { completedTask, hiddenTask, node, nodePos } = findTask(doc, uuid);
    if (!completedTask && !hiddenTask && !node) return false;

    if (dispatch) {
      const transaction = state.tr;

      if (updates.completedAt) {
        const defaultTaskCompletionMode = get(doc, "attrs.storage.defaultTaskCompletionMode");
        if (defaultTaskCompletionMode === TASK_COMPLETION_MODE.CROSS_OUT) {
          if (!updates.crossedOutAt) updates.crossedOutAt = updates.completedAt;
          updates = omit(updates, [ "completedAt" ]);
        }
      }

      if (completedTask) {
        transaction.step(new ChangeCompletedTaskStep(schema, completedTask, updates));
      } else if (hiddenTask) {
        transaction.step(new ChangeHiddenTaskAttrsStep(schema, hiddenTask.attrs, updates));
      } else {
        const attrs = { ...node.attrs, ...updates };
        transaction.setNodeMarkup(nodePos, null, attrs)
      }

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function updateTaskContent(uuid, newContent) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const { completedTask, hiddenTask, node, nodePos } = findTask(doc, uuid);
    if (!completedTask && !hiddenTask && !node) return false;

    if (dispatch) {
      const transaction = state.tr;

      if (completedTask) {
        const p = (newContent[0] || {}).content;
        transaction.step(new ChangeCompletedTaskStep(schema, completedTask, { p }));
      } else if (hiddenTask) {
        transaction.step(new ChangeHiddenTaskContentStep(schema, uuid, hiddenTask.content, newContent));
      } else {
        transaction.replaceWith(nodePos + 1, nodePos + node.nodeSize, Fragment.fromJSON(schema, newContent))
      }

      dispatch(transaction);
    }

    return true;
  }
}

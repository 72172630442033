import { useCallback, useEffect, useImperativeHandle, useState } from "react"

import { hasModifierKey } from "lib/ample-editor/lib/event-util"

// --------------------------------------------------------------------------
export default function useSelectedSuggestion(cancel, ref, selectSuggestion, selectSuggestionOnTab, suggestions) {
  const [ selectedIndex, setSelectedIndex ] = useState(0);

  const length = suggestions ? suggestions.length : 0;

  // Clamp selected index when length changes
  useEffect(
    () => {
      setSelectedIndex(selectedIndexWas => Math.min(selectedIndexWas, Math.max(0, length - 1)));
    },
    [ length ]
  );

  const moveSelectedIndex = useCallback(
    delta => {
      setSelectedIndex(selectedIndexWas => {
        const lastIndex = Math.max(0, length - 1);

        let newSelectedIndex = selectedIndexWas + delta;

        if (newSelectedIndex < 0) {
          newSelectedIndex = Math.max(0, Math.min(length + newSelectedIndex, lastIndex));
        } else if (newSelectedIndex > lastIndex) {
          newSelectedIndex = Math.min(Math.max(0, newSelectedIndex - length), lastIndex);
        }

        return newSelectedIndex;
      });
    },
    [ length ]
  );

  useImperativeHandle(ref, () => ({
    acceptActiveSuggestion: () => {
      const selectedSuggestion = suggestions[selectedIndex];
      if (selectedSuggestion) selectSuggestion(selectedSuggestion);
    },
    handleKeyDown: event => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "ArrowDown":
        case "ArrowUp":
          if (!hasModifierKey(event)) {
            moveSelectedIndex(event.key === "ArrowDown" ? 1 : -1);
            return true;
          }
          break;

        case "Tab":
          if (!selectSuggestionOnTab) return true;
        // eslint-disable-next-line no-fallthrough
        case "Enter": {
          const selectedSuggestion = suggestions[selectedIndex];
          if (selectedSuggestion) selectSuggestion(selectedSuggestion);
          return true;
        }

        case "Escape":
          cancel();
          return true;
      }
    },
  }));

  return selectedIndex;
}

import { FILTER_GROUPS_DELIMITER } from "lib/ample-util/filter-group"
import { REFERENCES_DELIMITER } from "lib/ample-util/filter-references"
import { TAGS_DELIMITER } from "lib/ample-util/tags"

// --------------------------------------------------------------------------
// Utility functions for working with filter parameters that can be applied
// to various areas, particularly as they appear in a URL's query string
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function areBlankFilterParams(filterParams) {
  return !filterParams.group && !filterParams.query && !filterParams.references && !filterParams.tag;
}

// --------------------------------------------------------------------------
export function filterGroupFromSearch(search) {
  const groupMatch = search.match(/[&?]group=(.+?)(?:&|\?|$)/i);
  return groupMatch ? decodeURIComponent(groupMatch[1]) : null;
}

// --------------------------------------------------------------------------
export function filterQueryFromSearch(search) {
  const queryMatch = search.match(/[&?]query=(.+?)(?:&|\?|$)/i);
  return queryMatch ? decodeURIComponent(queryMatch[1]) : null;
}

// --------------------------------------------------------------------------
export function filterReferencesFromSearch(search) {
  const referencesMatch = search.match(/[&?]references=(.+?)(?:&|\?|$)/i);
  return referencesMatch ? decodeURIComponent(referencesMatch[1]) : null;
}

// --------------------------------------------------------------------------
export function filterTagFromSearch(search) {
  const tagMatch = search.match(/[&?]tag=(.+?)(?:&|\?|$)/i);
  return tagMatch ? decodeURIComponent(tagMatch[1]) : null;
}

// --------------------------------------------------------------------------
// Provided `search` should include leading "?"
export function filterParamsFromSearch(search) {
  return {
    group: filterGroupFromSearch(search),
    query: filterQueryFromSearch(search),
    references: filterReferencesFromSearch(search),
    tag: filterTagFromSearch(search),
  };
}

// --------------------------------------------------------------------------
export function filterParamsFromSearchText(searchText) {
  let tag = null;
  let query = searchText.replace(/\b\s*in:\s?#?([\^\-/\p{Ll}\p{Lo}\p{Nd}\\,]+)(?=\s|$)/uig, (_match, tagText) => {
    tag = tag !== null ? `${ tag }${ TAGS_DELIMITER }${ tagText }` : tagText;
    return "";
  });

  let group = null;
  query = query.replace(/\b\s*group:\s?([\^\w\-,]+)(?:\b|$)/ig, (_match, groupText) => {
    group = group !== null ? `${ group }${ FILTER_GROUPS_DELIMITER }${ groupText }` : groupText;
    return "";
  });

  let references = null;
  query = query.replace(/\b\s*references:\s?([\^a-f0-9\-,]+)(?:\b|$)/ig, (_match, referencesText) => {
    references = references !== null ? `${ references }${ REFERENCES_DELIMITER }${ referencesText }` : referencesText;
    return "";
  });

  query = query.trim();
  if (!query) query = null;

  return { group, query, references, tag };
}

// --------------------------------------------------------------------------
// Note this returns an empty string if there are no filters (rather than "?"), and includes a leading "?"
export function searchFromFilterParams(filterParams) {
  const { group = null, query = null, references = null, tag = null } = filterParams || {};

  let search = "";

  if (group) search += `?group=${ encodeURIComponent(group) }`;
  if (query) search += `${ search.length > 0 ? "&" : "?" }query=${ encodeURIComponent(query) }`;
  if (references) search += `${ search.length > 0 ? "&" : "?" }references=${ encodeURIComponent(references) }`;
  if (tag) search += `${ search.length > 0 ? "&" : "?" }tag=${ encodeURIComponent(tag) }`;

  return search;
}

// --------------------------------------------------------------------------
export function searchTextFromFilterParams(filterParams) {
  const { group, query, references, tag } = filterParams;

  let searchText = "";

  if (group) {
    searchText += `group:${ group } `;
  }

  if (tag) {
    searchText += `in:${ tag } `;
  }

  if (references) {
    searchText += `references:${ references } `;
  }

  if (query) {
    searchText += query;
  }

  return searchText;
}

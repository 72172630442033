import { endOfList, startOfList } from "lib/ample-editor/lib/list-item-util"
import { anchorNameFromHeadingText } from "lib/ample-util/note-url"

// --------------------------------------------------------------------------
export function buildInsertTableOfContents(start, end) {
  return function(state, dispatch) {
    const { doc, schema, schema: { nodes } } = state;

    const headerLinks = [];
    doc.forEach((node, _pos) => {
      if (node.type === nodes.heading) {
        const anchorName = anchorNameFromHeadingText(node.textContent);
        if (anchorName) {
          const linkToHeader = nodes.link.create({ href: `#${ anchorName }` }, [
            schema.text(node.textContent)
          ]);
          const listItem = nodes.number_list_item.create({ indent: node.attrs.level - 1 }, [
            nodes.paragraph.create(null, linkToHeader)
          ]);
          headerLinks.push(listItem);
        }
      }
    });

    if (dispatch) {
      const transaction = state.tr;
      const deleteNodePos = Math.max(0, transaction.mapping.map(start) - 1);
      transaction.delete(deleteNodePos, transaction.mapping.map(end));
      transaction.insert(deleteNodePos, headerLinks);

      dispatch(transaction);
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export function maybeRefreshTableOfContents(state, dispatch) {
  const { doc, schema: { nodes }, selection } = state;
  if (!selection || !selection.from || !selection.to) return null;

  const { $from, $to } = selection;
  if (!$from) return null;
  let $maybeLinkPos = $from;
  if ($maybeLinkPos.nodeAfter && $maybeLinkPos.nodeAfter.type === nodes.number_list_item) {
    $maybeLinkPos = doc.resolve($maybeLinkPos.pos + 1);
  }
  if ($maybeLinkPos && $maybeLinkPos.nodeAfter && $maybeLinkPos.nodeAfter.type === nodes.paragraph) {
    $maybeLinkPos = doc.resolve($maybeLinkPos.pos + 1);
  }
  if ($maybeLinkPos && $maybeLinkPos.nodeAfter && $maybeLinkPos.nodeAfter.type === nodes.link) {
    // $maybeLinkPos is a good official guess
  } else {
    const startPos = $from.start(-1);
    if (Number.isInteger(startPos)) $maybeLinkPos = doc.resolve(startPos);
  }
  if (!$maybeLinkPos || !$maybeLinkPos.nodeAfter) return null;
  if ($maybeLinkPos.nodeAfter && $maybeLinkPos.nodeAfter.type !== nodes.link) return null;
  const href = $maybeLinkPos.nodeAfter.attrs.href;
  const text = $maybeLinkPos.nodeAfter.textContent;
  if (!href || !text) return null;
  const hrefFromText = anchorNameFromHeadingText(text)
  if (href === `#${ hrefFromText }`) {
    const $maybeBulletPos = doc.resolve($maybeLinkPos.before(-1));
    if (!$maybeBulletPos || !$maybeBulletPos.nodeAfter || $maybeBulletPos.nodeAfter.type !== nodes.number_list_item) return null;
    const $start = startOfList($maybeBulletPos);
    const $end = endOfList($maybeBulletPos);
    if ($start && $end && $start.pos <= $from.pos && $end.pos >= $to.pos) {
      if (dispatch) {
        return buildInsertTableOfContents($start.pos, $end.pos)(state, dispatch);
      } else {
        return true;
      }
    }
  }

  return null;
}

import { Node } from "prosemirror-model"

import descriptionSchema from "../components/rich-footnote/description-schema"

// --------------------------------------------------------------------------
function documentFromContent(content) {
  return Node.fromJSON(descriptionSchema, { type: "doc", content });
}

// --------------------------------------------------------------------------
function removeEmptyTextNodes(content) {
  if (!content) return content;

  const validContent = [];
  content.forEach(child => {
    if (child.type === "text" && !child.text) return;

    if (child.content) {
      child = { ...child, content: removeEmptyTextNodes(child.content) };
    }

    validContent.push(child);
  });

  return validContent;
}

// --------------------------------------------------------------------------
// The incoming value prop can be a string of plain text, or an actual ProseMirror
// content array. This allows for handling of legacy plain text descriptions, and
// for a more compact storage format (i.e. we can just store the text if there's a
// single paragraph.
export function descriptionDocumentFromValue(value) {
  let content;
  if (typeof(value) === "string") {
    content = [
      {
        type: "paragraph",
        content: value.length > 0 ? [ { type: "text", text: value } ] : []
      }
    ]
  } else {
    content = value || [ { type: "paragraph" } ];
  }

  try {
    return documentFromContent(content);
  } catch (error) {
    if (error.toString().includes("Empty text nodes are not allowed")) {
      try {
        return documentFromContent(removeEmptyTextNodes(content));
      } catch (_error) {
        // Fall back to default below
      }
    }

    // Typically, a "RangeError: Unknown node type: ..." - we could try to extract just the
    // known node types, but we'll just fall back to not showing the content for now.
    return documentFromContent([ { type: "paragraph" } ]);
  }
}

// --------------------------------------------------------------------------
// We want to persist the content a bit more concisely than ProseMirror would
// give us, and treat an empty document as a `""` value
export function valueFromDescriptionDocumentContent(documentContent) {
  // Pull content array out, i.e.: `{ type: "doc", content }`
  const { content } = documentContent;

  if (content.length === 1) {
    const node = content[0];

    if (node.type === "paragraph") {
      // Empty document
      if (!node.content) return "";

      // Single text node: convert to plain string
      if (node.content.length === 1 && node.content[0].type === "text" && !node.content[0].marks) {
        return node.content[0].text;
      }
    }
  }

  return content;
}

// --------------------------------------------------------------------------
export function valueFromDescriptionDocument(document) {
  return valueFromDescriptionDocumentContent(document.toJSON());
}

import { CellSelection, selectedRect, TableMap } from "prosemirror-tables"

import { CELL_SELECTION_DIRECTION, MIXED_TABLE_SELECTION_VALUE } from "lib/ample-editor/lib/table/table-constants"
import { cellFromPos, tableFromPos } from "lib/ample-editor/lib/table/table-util"

// --------------------------------------------------------------------------
// Expand selection to include all columns or rows that within current CellSelection range
// cellSelectionDirection: member of `CELL_SELECTION_DIRECTION`
export const expandCellSelectionRange = cellSelectionDirection => (state, dispatch) => {
  const { selection } = state;
  const $table = tableFromPos(selection.$from);
  if (!$table) return false;

  const transform = state.tr;
  let $anchorCell, $headCell;
  if (selection instanceof CellSelection) {
    $anchorCell = selection.$anchorCell;
    $headCell = selection.$headCell;
  } else {
    $anchorCell = cellFromPos(state, selection.$anchor);
    $headCell = cellFromPos(state, selection.$head);
  }

  if (cellSelectionDirection === CELL_SELECTION_DIRECTION.COLUMN) {
    const cellSelection = CellSelection.colSelection($anchorCell, $headCell);
    const $anchorWas = cellSelection.$anchorCell;
    // The default $headCell chosen by colSelection is placed such that mobile browsers scroll down to end of
    // column, which is annoying since CellSelection pops atop column. Swapping the cell positions prevents mobile
    // from scrolling down to try to focus the column selection as of Dec 2022
    cellSelection.$anchorCell = cellSelection.$headCell;
    cellSelection.$headCell = $anchorWas;
    dispatch(transform.setSelection(cellSelection));
  } else if (cellSelectionDirection === CELL_SELECTION_DIRECTION.ROW) {
    const cellSelection = CellSelection.rowSelection($anchorCell, $headCell);
    dispatch(transform.setSelection(cellSelection));
  } else {
    throw new Error(`Invalid cellSelectionDirection: ${ cellSelectionDirection }`);
  }

  return true;
}

// --------------------------------------------------------------------------
export function upperRightCellPos(rect) {
  const map = rect.map;
  return rect.tableStart + map.positionAt(rect.top, rect.right - 1, rect.table);
}

// --------------------------------------------------------------------------
// Inlined from pm-tables without scrutiny
export function isTextSelectionAcrossCells({ $from, $to }) {
  let fromCellBoundaryNode;
  let toCellBoundaryNode;

  for (let i = $from.depth; i > 0; i--) {
    const node = $from.node(i);
    if (node.type.spec.tableRole === "cell") {
      fromCellBoundaryNode = node;
      break;
    }
  }

  for (let i = $to.depth; i > 0; i--) {
    const node = $to.node(i);
    if (node.type.spec.tableRole === "cell") {
      toCellBoundaryNode = node;
      break;
    }
  }

  return fromCellBoundaryNode !== toCellBoundaryNode && $to.parentOffset === 0;
}

// --------------------------------------------------------------------------
// Return a hash of attrs that apply to the CellSelection within the state
/* eslint-disable max-depth */
export function selectionAttrs(state) {
  const attrs = {};
  const rect = selectedRect(state);
  const $table = tableFromPos(state.selection.$from);
  const map = TableMap.get($table.nodeAfter);

  // For each batch-adjustable attr of a cell, deduce whether the range of selection contains a value, null,
  // or a mix (aka MIXED_TABLE_SELECTION_VALUE)
  for (let column = rect.left; column < rect.right; column++) {
    for (let row = rect.top; row < rect.bottom; row++) {
      const cell = map.map[column + row * map.width];
      const pos = $table.pos + 1 + cell;
      const node = state.doc.nodeAt(pos);
      if (column === rect.left && row === rect.top) {
        attrs.align = node.attrs.align;
        attrs.textColor = node.attrs.color;
        attrs.backgroundColor = node.attrs.backgroundColor;
      } else {
        if (attrs.align !== node.attrs.align) attrs.align = MIXED_TABLE_SELECTION_VALUE;
        if (attrs.textColor !== node.attrs.color) attrs.textColor = MIXED_TABLE_SELECTION_VALUE;
        if (attrs.backgroundColor !== node.attrs.backgroundColor) attrs.backgroundColor = MIXED_TABLE_SELECTION_VALUE;
      }

      if (column === rect.left) {
        if (row === rect.top) {
          attrs.borderLeft = node.attrs.borderLeft;
        } else {
          if (attrs.borderLeft !== node.attrs.borderLeft) attrs.borderLeft = MIXED_TABLE_SELECTION_VALUE;
        }
      }
      if (row === rect.top) {
        if (column === rect.left) {
          attrs.borderTop = node.attrs.borderTop;
        } else {
          if (attrs.borderTop !== node.attrs.borderTop) attrs.borderTop = MIXED_TABLE_SELECTION_VALUE;
        }
      }
      if (column === rect.right - 1) {
        if (row === rect.top) {
          attrs.borderRight = node.attrs.borderRight;
        } else {
          if (attrs.borderRight !== node.attrs.borderRight) attrs.borderRight = MIXED_TABLE_SELECTION_VALUE;
        }
      }
      if (row === rect.bottom - 1) {
        if (column === rect.left) {
          attrs.borderBottom = node.attrs.borderBottom;
        } else {
          if (attrs.borderBottom !== node.attrs.borderBottom) attrs.borderBottom = MIXED_TABLE_SELECTION_VALUE;
        }
      }
    }
  }
  return attrs;
}
/* eslint-enable max-depth */

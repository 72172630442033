import PropTypes from "prop-types"
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useAsyncEffect } from "@react-hook/async"
import {
  List,
  ListDivider,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText
} from "@rmwc/list"
import Tippy from "@tippyjs/react"

import ColorSelectionMenu from "lib/ample-editor/components/color-selection-menu"
import { modKeyName } from "lib/ample-editor/lib/client-info"
import { hexColorFromCycleColor, isCycleVarColor, numberFromCycleColor } from "lib/ample-editor/lib/color-util"
import { preventEventDefault } from "lib/ample-editor/lib/event-util"
import TOOLBAR_COMMAND from "lib/ample-editor/lib/toolbar-command"
import VECTOR_ICON_PATHS, { VectorIcon as OriginalVectorIcon } from "lib/ample-editor/lib/vector-icon-paths"
import resolvePluginActionPromises from "lib/ample-util/resolve-plugin-action-promises"

// --------------------------------------------------------------------------
const SUB_MENU = {
  BACKGROUND_COLOR: "background-color",
  EXTRACT_CONTENT: "extract-content",
  FOREGROUND_COLOR: "foreground-color",
  FORMAT_TEXT: "format-text",
};

const TOOLTIP_DELAY = [ 500, 150 ];

// --------------------------------------------------------------------------
function useReplaceTextPluginActions(getReplaceTextPluginActions, selectedText) {
  const [ replaceTextPluginActions, setReplaceTextPluginActions ] = useState(null);

  const selectedTextRef = useRef();
  selectedTextRef.current = selectedText;
  // Clear out when unmounting so async effects don't try to call `setReplaceTextPluginActions`
  useEffect(() => () => { selectedTextRef.current = null; }, [])

  useAsyncEffect(
    async () => {
      if (!getReplaceTextPluginActions) return;

      const pluginActionPromises = await getReplaceTextPluginActions(selectedText);
      if (!pluginActionPromises || selectedTextRef.current !== selectedText) return;

      resolvePluginActionPromises(pluginActionPromises, {
        setPluginActions: setReplaceTextPluginActions,
        shouldCancel: () => selectedTextRef.current !== selectedText,
      });
    },
    [ selectedText ]
  );

  return replaceTextPluginActions;
}

// --------------------------------------------------------------------------
function colorFromSelection(editorView, foreground) {
  // Get current Prosemirror selection from editorView:
  const { state, state: { schema, selection } } = editorView;
  if (!selection || !selection.from || !selection.to) return null;
  if (!schema.marks.highlight) return null;
  let color = null;

  state.doc.nodesBetween(selection.from, selection.to, (node, _pos) => {
    if (color !== null || !node.marks) return false;
    const highlightMark = node.marks.find(m => m.type === schema.marks.highlight);
    if (highlightMark) {
      if (!foreground && highlightMark.attrs.backgroundColor) {
        color = highlightMark.attrs.backgroundColor;
      } else if (foreground && highlightMark.attrs.color) {
        color = highlightMark.attrs.color;
      }
      return false;
    }
  });

  return color;
}

// --------------------------------------------------------------------------
function ExtractContentButton(props) {
  const {
    availableCommands,
    closeSubMenu,
    editorView,
    executeCommand,
    getReplaceTextPluginActions,
    selectedText,
    selectExtractToNoteTarget,
    subMenu,
    toggleExtractContentSubMenu,
  } = props;

  const canExtractToLinkDescription = availableCommands[TOOLBAR_COMMAND.EXTRACT_TO_LINK_DESCRIPTION];
  const canExtractToLinkedNote = availableCommands[TOOLBAR_COMMAND.EXTRACT_TO_LINKED_NOTE];

  const replaceTextPluginActions = useReplaceTextPluginActions(getReplaceTextPluginActions, selectedText);
  const haveReplaceTextPluginActions = replaceTextPluginActions && replaceTextPluginActions.length > 0;

  const onExtractToLinkClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      closeSubMenu();

      executeCommand(TOOLBAR_COMMAND.EXTRACT_TO_LINK_DESCRIPTION);
    },
    [ executeCommand ]
  );

  const onExtractToNoteClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      closeSubMenu();

      selectExtractToNoteTarget();
    },
    []
  );

  if (!canExtractToLinkDescription && !canExtractToLinkedNote && !haveReplaceTextPluginActions) {
    return null;
  }

  let buttonClassName = "button combo-button";
  let menu = null;
  if (subMenu === SUB_MENU.EXTRACT_CONTENT) {
    buttonClassName += " open";
    menu = (
      <RightSubMenu editorView={ editorView }>
        {
          canExtractToLinkDescription
            ? (
              <ListItem
                onClick={ onExtractToLinkClick }
                onMouseDown={ preventEventDefault }
              >
                <ListItemGraphic icon={ (<VectorIcon name="message-text-outline" />) } />
                <ListItemText>Extract to Rich Footnote</ListItemText>
              </ListItem>
            )
            : null
        }
        {
          canExtractToLinkedNote
            ? (
              <ListItem
                onClick={ onExtractToNoteClick }
                onMouseDown={ preventEventDefault }
              >
                <ListItemGraphic icon={ (<VectorIcon name="file-document-outline"/>) } />
                <ListItemText>Extract to a note</ListItemText>
              </ListItem>
            )
            : null
        }
        {
          (replaceTextPluginActions || []).map(({ checkResult, icon, name, replaceText }) => (
            <PluginListItem
              checkResult={ checkResult }
              closeSubMenu={ closeSubMenu }
              icon={ icon }
              key={ name + (checkResult || "") }
              name={ name }
              replaceText={ replaceText }
            />
          ))
        }
      </RightSubMenu>
    );
  }

  const button = (
    <Tippy
      content="Extract selection"
      delay={ TOOLTIP_DELAY }
      placement="top"
      touch={ false }
    >
      <div
        className={ buttonClassName }
        onClick={ toggleExtractContentSubMenu }
        onMouseDown={ preventEventDefault }
      >
        <VectorIcon name="file-move-outline" />
      </div>
    </Tippy>
  );

  return (
    <React.Fragment>
      <div className="divider" />
      { menu }
      { button }
    </React.Fragment>
  );
}

// --------------------------------------------------------------------------
function PluginListItem({ checkResult, closeSubMenu, icon, name, replaceText }) {
  const onClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      closeSubMenu();
      replaceText();
    },
    [ closeSubMenu, replaceText ]
  );

  let text;
  if (typeof(checkResult) === "string" && checkResult !== name) {
    text = (
      <React.Fragment>
        <ListItemPrimaryText>
          { checkResult }
        </ListItemPrimaryText>
        <ListItemSecondaryText>
          { name }
        </ListItemSecondaryText>
      </React.Fragment>
    )
  } else {
    text = name;
  }

  return (
    <ListItem
      onClick={ onClick }
      onMouseDown={ preventEventDefault }
    >
      <ListItemGraphic icon={ icon } />
      <ListItemText>
        { text }
      </ListItemText>
    </ListItem>
  );
}

// --------------------------------------------------------------------------
function RightSubMenu({ children, editorView }) {
  const [ style, setStyle ] = useState({});

  const listRef = useRef();

  // We don't know the width of the menu yet, so we need to render it then immediately measure so it doesn't go
  // off the right edge of the editor, as the selection-menu has been placed already, but this is an absolute positioned
  // menu relative to that, stretching to the right and potentially extending past the editor's edge.
  useLayoutEffect(
    () => {
      const { current: list } = listRef;
      const listElement = list && list.root ? list.root.ref : null;
      // In jsdom/non-browser environment, the listElement won't have an offsetParent
      if (listElement && listElement.offsetParent) {
        const bounds = listElement.getBoundingClientRect();
        const offsetParentBounds = listElement.offsetParent.getBoundingClientRect(); // .selection-menu

        const editorParentNode = editorView.dom.parentNode;
        const editorParentBounds = editorParentNode.getBoundingClientRect();
        const newLeft = Math.max(
          0,
          Math.min(
            bounds.left,
            editorParentBounds.left + editorParentBounds.width - bounds.width
          )
        ) - offsetParentBounds.left;

        setStyle({ left: newLeft });
      }
    },
    []
  );

  return (
    <List className="sub-menu right" ref={ listRef } style={ style }>
      { children }
    </List>
  );
}

// --------------------------------------------------------------------------
function VectorIcon({ name }) {
  return (<OriginalVectorIcon className="selection-menu-vector-icon" name={ name } />);
}

VectorIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(VECTOR_ICON_PATHS)).isRequired,
};

// --------------------------------------------------------------------------
export default class SelectionMenu extends React.Component {
  static propTypes = {
    availableCommands: PropTypes.object.isRequired,
    editorView: PropTypes.object.isRequired,
    executeCommand: PropTypes.func.isRequired,
    getReplaceTextPluginActions: PropTypes.func,
    selectedText: PropTypes.string.isRequired,
    selectExtractToNoteTarget: PropTypes.func.isRequired,
  };

  state = {
    replaceTextPluginActions: null,
    subMenu: null,
  };

  _unmounted = false;

  // --------------------------------------------------------------------------
  async componentDidMount() {
    const { getReplaceTextPluginActions, selectedText } = this.props;
    if (!getReplaceTextPluginActions) return;

    const pluginActionPromises = await getReplaceTextPluginActions(selectedText);
    if (this._unmounted || !pluginActionPromises) return;

    resolvePluginActionPromises(pluginActionPromises, {
      setPluginActions: pluginActions => this.setState({ replaceTextPluginActions: pluginActions }),
      shouldCancel: () => this._unmounted,
    });
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    this._unmounted = true;
  }

  // --------------------------------------------------------------------------
  render() {
    const {
      availableCommands,
      editorView,
      executeCommand,
      getReplaceTextPluginActions,
      selectedText,
      selectExtractToNoteTarget,
    } = this.props;
    const { subMenu } = this.state;

    const listItemButtons = [
      this._renderButton(TOOLBAR_COMMAND.TOGGLE_BLOCK_CHECK_LIST_ITEM, {
        tooltip: "Task list", vectorIcon: "checkbox-marked-outline",
      }),
      this._renderButton(TOOLBAR_COMMAND.TOGGLE_BLOCK_BULLET_LIST_ITEM, {
        icon: "format_list_bulleted", tooltip: "Bulleted list",
      }),
      this._renderButton(TOOLBAR_COMMAND.TOGGLE_BLOCK_NUMBER_LIST_ITEM, {
        icon: "format_list_numbered", tooltip: "Numbered list",
      }),
    ];

    if (listItemButtons.find(button => button !== null)) {
      listItemButtons.push(<div className="divider" key="divider" />);
    }

    const colorButtons = [
      this._renderColorPickerSubMenuItem("format_color_text", "Text color", SUB_MENU.FOREGROUND_COLOR),
      this._renderColorPickerSubMenuItem("format_color_fill", "Background color", SUB_MENU.BACKGROUND_COLOR),
      [ SUB_MENU.BACKGROUND_COLOR, SUB_MENU.FOREGROUND_COLOR ].includes(subMenu) ? this._renderColorPickerMenu() : null,
    ].filter(n => n);

    return (
      <div className="selection-menu">
        { this._renderFormatTextButton() }

        <div className="divider" />
        { listItemButtons }
        { colorButtons }

        { this._renderLinkButtons() }

        <ExtractContentButton
          availableCommands={ availableCommands }
          closeSubMenu={ this._closeSubMenu }
          editorView={ editorView }
          executeCommand={ executeCommand }
          getReplaceTextPluginActions={ getReplaceTextPluginActions }
          selectedText={ selectedText }
          selectExtractToNoteTarget={ selectExtractToNoteTarget }
          subMenu={ subMenu }
          toggleExtractContentSubMenu={ this._toggleExtractContentSubMenu }
        />
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _closeSubMenu = () => {
    this.setState({ subMenu: null });
  };

  // --------------------------------------------------------------------------
  _renderButton(commandName, { icon = null, tooltip = null, vectorIcon = null } = {}) {
    const { availableCommands, executeCommand } = this.props;
    if (!(commandName in availableCommands)) return null;

    let renderedIcon = null;
    if (icon) {
      renderedIcon = (<i className="material-icons">{ icon }</i>);
    } else if (vectorIcon) {
      renderedIcon = (<VectorIcon name={ vectorIcon } />);
    }

    const active = availableCommands[commandName];
    const disabled = (active === null || typeof active === "undefined");
    const onClick = disabled ? null : () => { executeCommand(commandName); };

    return (
      <Tippy
        content={ tooltip }
        delay={ TOOLTIP_DELAY }
        key={ commandName }
        placement="top"
        touch={ false }
      >
        <div
          className={ `button ${ active ? "active" : "" } ${ disabled ? "disabled" : "" }` }
          onClick={ onClick }
          onMouseDown={ preventEventDefault }
        >
          { renderedIcon }
        </div>
      </Tippy>
    );
  }

  // --------------------------------------------------------------------------
  _renderFormatTextButton() {
    const { subMenu } = this.state;

    let buttonClassName = "button combo-button";
    let menu = null;
    if (subMenu === SUB_MENU.FORMAT_TEXT) {
      const { availableCommands } = this.props;

      buttonClassName += " open";

      menu = (
        <List className="sub-menu">
          { this._renderSubMenuItem(TOOLBAR_COMMAND.TOGGLE_MARK_STRONG, "format_bold", `${ modKeyName }+b`, "bold") }
          { this._renderSubMenuItem(TOOLBAR_COMMAND.TOGGLE_MARK_EM, "format_italic", `${ modKeyName }+i`, "italic") }
          { this._renderSubMenuItem(TOOLBAR_COMMAND.TOGGLE_MARK_STRIKETHROUGH, "format_strikethrough", null, "strikethrough") }
          { this._renderSubMenuItem(TOOLBAR_COMMAND.TOGGLE_MARK_HIGHLIGHT, "brush", `${ modKeyName }+h`, "highlight") }
          { this._renderSubMenuItem(TOOLBAR_COMMAND.TOGGLE_MARK_CODE, "code", "ctrl+`", "code") }

          {
            availableCommands[TOOLBAR_COMMAND.CLEAR_MARKS]
              ? (
                <React.Fragment>
                  <ListDivider />
                  {
                    this._renderSubMenuItem(
                      TOOLBAR_COMMAND.CLEAR_MARKS,
                      "format_clear",
                      `${ modKeyName }+/`,
                      "clear formatting",
                      { ignoreActive: true }
                    )
                  }
                </React.Fragment>
              )
              : null
          }
        </List>
      );
    }

    const button = (
      <Tippy
        content="Text styles"
        delay={ TOOLTIP_DELAY }
        placement="top"
        touch={ false }
      >
        <div
          className={ buttonClassName }
          onClick={ this._toggleFormatTextSubMenu }
          onMouseDown={ preventEventDefault }
        >
          <VectorIcon name="format-text-variant" />
        </div>
      </Tippy>
    );

    return (
      <React.Fragment>
        { button }
        { menu }
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _renderColorPickerMenu() {
    const { subMenu } = this.state;

    const applyAndCloseSubMenu = (name, value) => {
      this._closeSubMenu();
      const { dispatch, state, state: { schema, selection } } = this.props.editorView;
      if (!selection) return false;
      const { ranges } = selection;
      const markType = schema.marks.highlight;
      const transaction = state.tr;
      const hexColor = hexColorFromCycleColor(value);
      const colorValue = hexColor ? `${ value }/${ hexColor }` : value;
      let attrs = { [name]: colorValue };
      for (let i = 0; i < ranges.length; i++) {
        const { $from, $to } = ranges[i];
        if ($from.nodeAfter && $from.nodeAfter.marks.find(m => m.type === markType) && $to.nodeBefore && $to.nodeBefore.marks.find(m => m.type === markType)) {
          attrs = { ...$from.nodeAfter.marks.find(m => m.type === markType).attrs, ...attrs };
        }

        transaction.addMark($from.pos, $to.pos, markType.create(attrs));
      }

      dispatch(transaction);
    };

    const domAttr = subMenu === SUB_MENU.BACKGROUND_COLOR ? "backgroundColor" : "color";
    const currentColor = colorFromSelection(this.props.editorView, subMenu === SUB_MENU.FOREGROUND_COLOR);
    const applySelectedColor = selectedColorString => applyAndCloseSubMenu(domAttr, selectedColorString);

    return (
      <ColorSelectionMenu
        applySelectedColor={ applySelectedColor }
        key="color-selection"
        selectedColor={ (typeof currentColor === "string") ? currentColor.replace("#", "") : "" }
        noFillButtonAvailable={ subMenu === SUB_MENU.BACKGROUND_COLOR }
      />
    );
  }

  // --------------------------------------------------------------------------
  _renderLinkButtons() {
    const { availableCommands, executeCommand } = this.props;

    const linkActive = availableCommands[TOOLBAR_COMMAND.TOGGLE_LINK];
    const linkDescriptionActive = availableCommands[TOOLBAR_COMMAND.EDIT_LINK_DESCRIPTION];
    const linkMediaActive = availableCommands[TOOLBAR_COMMAND.EDIT_LINK_MEDIA];

    const disabled = (linkActive === null || typeof linkActive === "undefined");
    const onEditDescriptionClick = disabled ? null : () => { executeCommand(TOOLBAR_COMMAND.EDIT_LINK_DESCRIPTION); };
    const onEditMediaClick = disabled ? null : () => { executeCommand(TOOLBAR_COMMAND.EDIT_LINK_MEDIA); };
    const onLinkClick = disabled ? null : () => { executeCommand(TOOLBAR_COMMAND.TOGGLE_LINK); };

    return (
      <React.Fragment>
        <Tippy
          content={ `Link - ${ modKeyName }+k` }
          delay={ TOOLTIP_DELAY }
          placement="top"
          touch={ false }
        >
          <div
            className={ `button ${ linkActive ? "active" : "" } ${ disabled ? "disabled" : "" }` }
            onClick={ onLinkClick }
            onMouseDown={ preventEventDefault }
          >
            <i className="material-icons">link</i>
          </div>
        </Tippy>

        <Tippy
          content="Rich Footnote description"
          delay={ TOOLTIP_DELAY }
          placement="top"
          touch={ false }
        >
          <div
            className={ `button ${ linkDescriptionActive ? "active" : "" } ${ disabled ? "disabled" : "" }` }
            onClick={ onEditDescriptionClick }
            onMouseDown={ preventEventDefault }
          >
            <VectorIcon name="message-text-outline" />
          </div>
          </Tippy>

        <Tippy
          content="Rich Footnote image"
          delay={ TOOLTIP_DELAY }
          placement="top"
          touch={ false }
        >
          <div
            className={ `button ${ linkMediaActive ? "active" : "" } ${ disabled ? "disabled" : "" }` }
            onClick={ onEditMediaClick }
            onMouseDown={ preventEventDefault }
          >
            <VectorIcon name="message-image-outline" />
          </div>
        </Tippy>
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _renderSubMenuItem(commandName, icon, shortcut, text, { ignoreActive = false } = {}) {
    const { availableCommands, executeCommand } = this.props;
    if (!(commandName in availableCommands)) return null;

    const onClick = event => {
      event.preventDefault();

      this.setState({ subMenu: null });

      executeCommand(commandName);
    };

    const active = availableCommands[commandName] && !ignoreActive;

    return (
      <ListItem
        className={ active ? "active" : null }
        onClick={ onClick }
        onMouseDown={ preventEventDefault }
      >
        <ListItemGraphic icon={ icon } />
        <ListItemText>{ text }</ListItemText>
        { shortcut ? (<ListItemMeta>{ shortcut }</ListItemMeta>) : null }
      </ListItem>
    );
  }

  // --------------------------------------------------------------------------
  _renderColorPickerSubMenuItem(icon, text, subMenuType) {
    const { subMenu } = this.state;
    const active = subMenu === subMenuType;

    const onClick = event => {
      event.preventDefault();

      this.setState({ subMenu: subMenu === subMenuType ? null : subMenuType });
    }

    const isForeground = subMenuType === SUB_MENU.FOREGROUND_COLOR;
    const selectionColor = colorFromSelection(this.props.editorView, isForeground);
    let color = null;
    let dataColor = null;
    if (selectionColor) {
      if (isCycleVarColor(selectionColor)) {
        dataColor = numberFromCycleColor(selectionColor);
      } else {
        color = selectionColor;
      }
    }

    return (
      <Tippy
        content={ `Pick ${ isForeground ? "text" : "background" } highlight color` }
        delay={ TOOLTIP_DELAY }
        key={ `sub-${ subMenuType }` }
        placement="top"
        touch={ false }
      >
        <div
          className={ `color-pick-option button pick-${ isForeground ? "foreground" : "background" } ${ active ? "active" : "" }` }
          onClick={ onClick }
          onMouseDown={ preventEventDefault }
        >
          <i className="material-icons" data-text-color={ dataColor } style={ color ? { color } : null }>{ icon }</i>
        </div>
      </Tippy>
    );
  }

  // --------------------------------------------------------------------------
  _toggleExtractContentSubMenu = () => {
    const { subMenu } = this.state;

    this.setState({ subMenu: subMenu === SUB_MENU.EXTRACT_CONTENT ? null : SUB_MENU.EXTRACT_CONTENT });
  };

  // --------------------------------------------------------------------------
  _toggleFormatTextSubMenu = () => {
    const { subMenu } = this.state;
    this.setState({ subMenu: subMenu === SUB_MENU.FORMAT_TEXT ? null : SUB_MENU.FORMAT_TEXT });
  };
}

import { TextSelection } from "prosemirror-state"
import React from "react"
import ReactDOM from "react-dom"

import ExpressionMenu from "lib/ample-editor/components/expression-menu"
import { positionPopupContainer } from "lib/ample-editor/lib/popup-util"
import { OPEN_EXPRESSION_PATTERN } from "lib/ample-util/evaluate-expression"

// --------------------------------------------------------------------------
function calculateExpressionMenuParams(state, hasPendingReplacementAtPos) {
  const { selection, selection: { $from, $to } } = state;
  if (!(selection instanceof TextSelection) || !$to.parent || !$to.sameParent($from)) return null;
  if ($from.parent.type.spec.code) return null;

  const { schema: { marks: { code: codeMarkType } } } = state;
  if (codeMarkType && codeMarkType.isInSet($from.marks())) return null;

  const startOffset = Math.max(0, $to.parentOffset - 500);
  // Note that we want to treat any non-text leaf nodes (e.g. hard breaks) as newlines, as they should stop expression
  // matching entirely
  const textBefore = $to.parent.textBetween(startOffset, $to.parentOffset, null, "\n");
  const match = textBefore.match(OPEN_EXPRESSION_PATTERN);
  if (!match) return null;

  const startPos = $to.pos - (match[1] ? match[1].length : 0);

  // Make sure the opening brace isn't in a code mark
  const $startPos = state.doc.resolve(startPos);
  if (codeMarkType && codeMarkType.isInSet($startPos.marks())) return null;

  // There might be a long replacement happening from the opening bracket
  if (hasPendingReplacementAtPos(startPos)) return null;

  return {
    endPos: $to.pos,
    startPos,
    text: match[1] || "",
  };
}

// --------------------------------------------------------------------------
export default class ExpressionPluginView {
  _editorView = null;
  _expressionMenuRef = React.createRef();
  _getInsertTextPluginActions = null;
  _hasPendingReplacementAtPos = null;
  _menuContainer = null;
  _tableOfContentsEnabled = false;

  // --------------------------------------------------------------------------
  constructor(editorView, getInsertTextPluginActions, hasPendingReplacementAtPos, tableOfContentsEnabled) {
    this._editorView = editorView;
    this._getInsertTextPluginActions = getInsertTextPluginActions;
    this._hasPendingReplacementAtPos = hasPendingReplacementAtPos;
    this._tableOfContentsEnabled = tableOfContentsEnabled;

    this._menuContainer = document.createElement("div");
    this._menuContainer.className = "expression-menu-container";
    if (editorView.dom.parentNode) {
      editorView.dom.parentNode.appendChild(this._menuContainer);
    }
  }

  // --------------------------------------------------------------------------
  destroy() {
    this._unmount();
    this._menuContainer.remove();
  }

  // --------------------------------------------------------------------------
  dismissMenu() {
    const { current: expressionMenu } = this._expressionMenuRef;
    if (expressionMenu) expressionMenu.dismiss();
  }

  // --------------------------------------------------------------------------
  handleKeyDown(editorView, event) {
    const { current: expressionMenu } = this._expressionMenuRef;
    return expressionMenu ? expressionMenu.handleKeyDown(editorView, event) : false;
  }

  // --------------------------------------------------------------------------
  isMenuOpen() {
    const { current: expressionMenu } = this._expressionMenuRef;
    return expressionMenu ? !expressionMenu.isDismissed() : false;
  }

  // --------------------------------------------------------------------------
  update(editorView) {
    const { state } = editorView;

    const expressionMenuParams = calculateExpressionMenuParams(state, this._hasPendingReplacementAtPos);

    if (expressionMenuParams !== null) {
      const { startPos, text } = expressionMenuParams;

      const getInsertTextPluginActions = () => {
        return this._getInsertTextPluginActions(editorView);
      };

      ReactDOM.render(
        <ExpressionMenu
          completeExpression={ this._completeExpression }
          getInsertTextPluginActions={ getInsertTextPluginActions }
          ref={ this._expressionMenuRef }
          tableOfContentsEnabled={ this._tableOfContentsEnabled }
          text={ text }
        />,
        this._menuContainer,
        () => {
          positionPopupContainer(editorView, this._menuContainer, 300, startPos);
        }
      );
    } else {
      this._unmount();
    }
  }

  // --------------------------------------------------------------------------
  _completeExpression = text => {
    const { state } = this._editorView;

    const expressionMenuParams = calculateExpressionMenuParams(state, this._hasPendingReplacementAtPos);
    if (expressionMenuParams === null) return false;

    const { endPos, startPos } = expressionMenuParams;

    // We want the inputrules plugin to handle the input, so we get the exact expression behavior we expect
    this._editorView.someProp("handleTextInput", handleTextInput => {
      return handleTextInput(this._editorView, startPos, endPos, text + "}");
    });

    return true;
  };

  // --------------------------------------------------------------------------
  _unmount() {
    try {
      ReactDOM.unmountComponentAtNode(this._menuContainer);
    } catch (_error) {
      // React can raise errors in some cases if the parent has been removed from the DOM, or in other unclear
      // circumstances. We don't really care, so long as we've tried to unmount.
    }
  }
}

import { createContext } from "react"

// --------------------------------------------------------------------------
// This is intended to provide an EditorView instance to deeply nested components
// in a React tree, but generally only for those components to access the callbacks
// and functions provided by the editor view, not for them to (reactively) consume
// the state of the editor, as this context will not be updated when the editor
// view's state changes - once non-null, it's expected the editor view reference
// will never change.
// --------------------------------------------------------------------------
const EditorViewContext = createContext(null);
export default EditorViewContext;

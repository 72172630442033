import { base, keyName } from "w3c-keyname"

import { isApplePlatform } from "lib/ample-editor/lib/client-info"

// --------------------------------------------------------------------------
// This uses the logic from prosemirror-keymap for determining the key that was
// pressed regardless of whether shift or alt was held. We don't entirely care
// how accurate this is, more that it matches what prosemirror does, so we can
// match prosemirror keymap handling when an input (or other non-prosemirror)
// element is active.
//
// This is mainly intended to handle Windows, where Edge will say "!" is the key when
// alt and shift are held and "1" is pressed.
export function baseKeyName(event) {
  const name = keyName(event)

  if (!event.altKey && !event.metaKey && !event.shiftKey && name.charCodeAt(0) < 128) {
    return name;
  }

  const isChar = name.length === 1 && name !== " ";
  if (!isChar) return name;

  const baseName = base[event.keyCode];
  if (baseName) return baseName;

  return name;
}

// --------------------------------------------------------------------------
export function hasModifierKey(event) {
  return event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
}

// --------------------------------------------------------------------------
export function hasModifierKeyExceptShift(event) {
  return event.altKey || event.ctrlKey || event.metaKey;
}

// --------------------------------------------------------------------------
export function hasModKeyOnly(event) {
  return !event.altKey && !event.shiftKey && (
    isApplePlatform
      ? (event.metaKey && !event.ctrlKey)
      : (!event.metaKey && event.ctrlKey)
  );
}

// --------------------------------------------------------------------------
export function preventEventDefault(event) {
  event.preventDefault()
}

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import scrollIntoView from "scroll-into-view-if-needed"

import { domElementForFindMatch } from "lib/ample-editor/plugins/find-plugin"

// --------------------------------------------------------------------------
export default function FindInEditor({ editorRef }) {
  const [ matchIndex, setMatchIndex ] = useState(0);
  const [ matchCount, setMatchCount ] = useState(0);
  const [ query, setQuery ] = useState("");

  const onChange = useCallback(
    event => {
      setQuery(event.target.value);
    },
    []
  );

  const onKeyDown = useCallback(
    event => {
      if (event.key === "ArrowUp") {
        event.preventDefault();
        editorRef.current.findPrevious();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        editorRef.current.findNext();
      }
    },
    []
  );

  useEffect(
    () => {
      const { current: editor } = editorRef;
      if (!editor) return;

      editor.find(query || null, (index, count) => {
        setMatchIndex(index);
        setMatchCount(count);

        if (editor._editorView) {
          // This is being called from the transaction that updates the plugin state,
          // but we need to wait for the plugin state to be updated before we can
          // resolve the DOM node to scroll to
          setTimeout(() => {
            const domElement = domElementForFindMatch(editor._editorView);
            if (domElement) scrollIntoView(domElement, { behavior: "smooth", scrollMode: "if-needed" });
          }, 1);
        }
      });
    },
    [ query ]
  );

  const additionalInfo = query
    ? (
      <React.Fragment>
        &nbsp;
        <a onClick={ () => editorRef.current.findNext() }>
          Next
        </a>
        &nbsp;
        <a onClick={ () => editorRef.current.findPrevious() }>
          Prev
        </a>
        &nbsp;
        <span>{ matchCount ? matchIndex + 1 : 0 }/{ matchCount }</span>
      </React.Fragment>
    )
    : null;

  return (
    <div>
      <input
        onChange={ onChange }
        onKeyDown={ onKeyDown }
        placeholder="Find in editor"
        type="text"
        value={ query }
      />
      { additionalInfo }
    </div>
  );
}

FindInEditor.propTypes = {
  editorRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

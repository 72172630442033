import { CellSelection, selectedRect, TableMap } from "prosemirror-tables"

import BigEvalTableFormulas from "lib/ample-editor/lib/big-eval-table-formulas"
import {
  isNumeric,
  numberFromText,
  numericResultFromValues,
  precisionFromNumber,
} from "lib/ample-editor/lib/math-util"
import {
  cellFromPos,
  cellFromTable,
  forEachTableCell,
  FORMULA_RANGE,
  FORMULA_REGEX,
  isRenderFormulaCell,
  setTableRedrawMeta,
  tableFromPos,
  tablePluginKey,
} from "lib/ample-editor/lib/table/table-util"

export const MAX_FORMULA_DECIMAL_PRECISION = 2;

// --------------------------------------------------------------------------
const CALCULATION_STATE = {
  VALID_CALCULATION: "valid-calculation",
  VALID_SYNTAX: "valid-syntax", // Can't calculate result, but syntax is valid
  INVALID_SYNTAX: "invalid-syntax", // Valid formula
}

// --------------------------------------------------------------------------
// An appendTransaction of the table plugin, this looks over differences between oldState and newState to decide
// whether changes have occurred that merit redrawing the table, or recalculating its formula cells.
export function setRedrawIfFormulaDependentsChanged(state, oldState, transactions, tr) {
  const selection = state.selection;
  if (!selection) return tr;
  const $cell = selection.$head && cellFromPos(selection.$head);
  const $cellWas = oldState.selection.$head && cellFromPos(oldState.selection.$head);
  if (rowOrColumnAddedOrRemoved(state, oldState)) {
    tr = tr || state.tr;
    setTableRedrawMeta(tr);
  } else if (transactions.find(txn => txn.docChanged)) {
    const cellPosArray = [];
    if ($cell && $cellWas && $cell.pos === $cellWas.pos && $cell.nodeAfter.textContent !== $cellWas.nodeAfter.textContent) {
      cellPosArray.push($cell.pos);
    } else if ($cell && selection instanceof CellSelection) {
      const selectionWas = oldState.selection;
      if (!selection.content().eq(selectionWas.content())) {
        const rect = selectedRect(state);
        const positionsInRect = rect.map.cellsInRect(rect);
        cellPosArray.push(...positionsInRect.map(pos => pos + rect.tableStart));
      }
    }

    // If cells changed, redrawDependentCells will flag them to check if they or the formulas that depend on them need to be redrawn
    if (cellPosArray.length) {
      tr = tr || state.tr;
      redrawDependentCells(state, tr, tableFromPos($cell), cellPosArray);
    }
  } else if ($cellWas && isRenderFormulaCell($cellWas) && (!$cell || $cell.pos !== $cellWas.pos)) {
    // Leaving a formula cell: redraw the table and recalculate dependentCells
    tr = tr || state.tr;
    const tableMeta = tr.getMeta(tablePluginKey);
    const $table = tableFromPos($cellWas);
    if ($table) {
      tr.setMeta(tablePluginKey, { ...tableMeta, redrawAt: $table.pos, redrawRequested: true });
    }
  }

  return tr;
}

// --------------------------------------------------------------------------
// Return an object that contains keys for any cell whose value will impact the value of another cell due
// to formula dependency.
// Format is "row-col": [ "row-col", "row-col" ], where both row and col are 0-based indexes
// Example: { "0-0": ["0-5"], "0-2": ["0-5"], "0-3": ["0-5"], "0-4": ["0-5", "1-5"] }
export function formulaDependenciesFromTable($table) {
  const dependentCells = {};
  const formulaCells = [];

  // Add a "row-col" key or value array member to dependentCells
  const addToCellIndex = (dependentRow, dependentCol, sourceRow, sourceCol) => {
    const dependentKey = `${ dependentRow }-${ dependentCol }`;
    dependentCells[dependentKey] = dependentCells[dependentKey] || [];
    if (sourceRow !== dependentRow || sourceCol !== dependentCol) {
      dependentCells[dependentKey].push(`${ sourceRow }-${ sourceCol }`);
    }
  }

  forEachTableCell($table, $cell => {
    const cellText = $cell.nodeAfter.textContent;
    if (calculationStateFromText(cellText) === CALCULATION_STATE.VALID_SYNTAX) {
      formulaCells.push({ cellText, pos: $cell.pos, row: $cell.index(-1), col: $cell.index() });
    }
  });

  // Loop over formulaCells to build the dependentCells hash of which changed cells require cell recalculations
  formulaCells.forEach(({ cellText, row: formulaRowIndex, col: formulaColumnIndex }) => {
    const text = cellText.toLowerCase();
    const matchDirection = text.match(/above|below|left|right/);
    if (matchDirection) {
      const map = TableMap.get($table.nodeAfter);
      const direction = matchDirection[0];
      const startRowIndex = { above: 0, below: Math.min(formulaRowIndex + 1, map.height - 1) }[direction];
      const endRowIndex = { above: Math.max(formulaRowIndex - 1, 0), below: map.height - 1 }[direction];
      const startColumnIndex = { left: 0, right: Math.min(formulaColumnIndex + 1, map.width - 1) }[direction];
      const endColumnIndex = { left: Math.max(formulaColumnIndex - 1, 0), right: map.width - 1 }[direction];

      // If startRowIndex is a number, the use specified either "above" or "below", so we'll loop over rows
      if (isNumeric(startRowIndex)) {
        for (let row = startRowIndex; row <= endRowIndex; row++) {
          addToCellIndex(row, formulaColumnIndex, formulaRowIndex, formulaColumnIndex);
        }
      } else {
        for (let col = startColumnIndex; col <= endColumnIndex; col++) {
          addToCellIndex(formulaRowIndex, col, formulaRowIndex, formulaColumnIndex);
        }
      }
    }
  });

  return dependentCells;
}

// --------------------------------------------------------------------------
export function calculateFormula($cell) {
  if (!$cell.nodeAfter || !$cell.nodeAfter.textContent.length) return null;
  const text = $cell.nodeAfter.textContent.toLowerCase();
  const validFormula = text.match(FORMULA_REGEX);
  if (!validFormula) return null;
  const formula = validFormula[1];
  const rangeCellCount = validFormula[2];
  const range = validFormula[3];

  if (!range) return null;
  const values = valuesFromRange($cell, range, rangeCellCount);
  if (!values.length) return null;
  const numericValues = values.map(v => numberFromText(v)).filter(n => Number.isFinite(n));
  if (!numericValues.length) return null;
  let result;
  try {
    const bigEvalExpression = `${ formula }(${ numericValues.join(", ") })`;
    const bigEval = new BigEvalTableFormulas();
    result = bigEval.exec(bigEvalExpression);
    let maxPrecision = MAX_FORMULA_DECIMAL_PRECISION;
    if (!/([/*]|average)/i.test(formula)) {
      // Assuming that division/multiplication weren't used, precision should be no greater than precision of the numbers in calculation
      maxPrecision = Math.min(MAX_FORMULA_DECIMAL_PRECISION, Math.max(...numericValues.map(n => precisionFromNumber(n))));
    }
    return numericResultFromValues(result, values, maxPrecision);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Error calculating formula", e);
    return null;
  }
}

// --------------------------------------------------------------------------
function calculationStateFromText(text) {
  return text.match(FORMULA_REGEX)
    ? CALCULATION_STATE.VALID_SYNTAX
    : CALCULATION_STATE.INVALID_SYNTAX;
}

// --------------------------------------------------------------------------
function valuesFromRange($cell, formulaRange, rangeCount = null) {
  const $table = tableFromPos($cell);
  const map = TableMap.get($table.nodeAfter);
  const values = [];
  if (rangeCount) {
    if (isNumeric(rangeCount)) {
      rangeCount = parseInt(rangeCount, 10);
    } else {
      return values;
    }
  }

  if ([ FORMULA_RANGE.ABOVE, FORMULA_RANGE.BELOW ].includes(formulaRange)) {
    let startRow = { above: 0, below: Math.min($cell.index(-1) + 1, map.height) }[formulaRange];
    let endRow = { above: Math.max($cell.index(-1) - 1, 0), below: map.height }[formulaRange];
    if (Number.isFinite(rangeCount) && rangeCount >= 0) {
      if (formulaRange === FORMULA_RANGE.ABOVE) startRow = Math.max(endRow - rangeCount + 1, startRow);
      else endRow = Math.min(startRow + rangeCount - 1, endRow);
    }

    for (let row = startRow; row <= endRow; row++) {
      if (row === $cell.index(-1)) continue;
      const $rowCell = cellFromTable($table, row, $cell.index(), { map });
      if ($rowCell) values.push($rowCell.nodeAfter.textContent);
    }
  } else if ([ FORMULA_RANGE.LEFT, FORMULA_RANGE.RIGHT ].includes(formulaRange)) {
    let startCol = { left: 0, right: Math.min($cell.index() + 1, map.width - 1) }[formulaRange];
    let endCol = { left: Math.max($cell.index() - 1, 0), right: map.width - 1 }[formulaRange];
    if (Number.isFinite(rangeCount) && rangeCount >= 0) {
      if (formulaRange === FORMULA_RANGE.LEFT) startCol = Math.max(endCol - rangeCount + 1, startCol);
      else endCol = Math.min(startCol + rangeCount - 1, endCol);
    }

    for (let col = startCol; col <= endCol; col++) {
      if (col === $cell.index()) continue;
      const $colCell = cellFromTable($table, $cell.index(-1), col, { map });
      if ($colCell) values.push($colCell.nodeAfter.textContent);
    }
  }

  return values;
}

// --------------------------------------------------------------------------
// `changedCellPosArray` array of cell positions that changed, we will check if each has a formula dependent on
//    it and add that formula's pos to redraw array if so
function redrawDependentCells(state, tr, $table, changedCellPosArray) {
  if (!$table) return;
  const pluginState = tablePluginKey.getState(state);
  const dependentCells = pluginState.formulaDependentCells;
  const map = TableMap.get($table.nodeAfter);
  const redrawPosArray = [];
  if (dependentCells && changedCellPosArray.length) {
    changedCellPosArray.forEach(changedCellPos => {
      const $cell = cellFromPos($table.doc.resolve(changedCellPos));
      const key = `${ $cell.index(-1) }-${ $cell.index() }`;
      if (dependentCells[key]) {
        dependentCells[key].forEach(dependentCellTuple => {
          const redrawRow = parseInt(dependentCellTuple.split("-")[0], 10);
          const redrawCol = parseInt(dependentCellTuple.split("-")[1], 10);
          const $dependentCell = cellFromTable($table, redrawRow, redrawCol, { map });
          if ($dependentCell && !redrawPosArray.includes($dependentCell.pos)) {
            redrawPosArray.push($dependentCell.pos);
          }
        });
      }
    });
  }

  const tableMeta = tr.getMeta(tablePluginKey);
  tr.setMeta(tablePluginKey, { ...tableMeta, setRedrawPosArray: redrawPosArray });
}

// --------------------------------------------------------------------------
function rowOrColumnAddedOrRemoved(state, oldState) {
  const $table = state.selection && state.selection.$head && tableFromPos(state.selection.$head);
  if (!$table) return false;
  const $tableWas = oldState.selection && oldState.selection.$head && tableFromPos(oldState.selection.$head);
  if (!$tableWas) return false;
  const map = TableMap.get($table.nodeAfter);
  const mapWas = TableMap.get($tableWas.nodeAfter);
  return map.height !== mapWas.height || map.width !== mapWas.width;
}

import { Plugin, PluginKey } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

import { urlFromLinkAttributes } from "lib/ample-editor/lib/link-util"

// --------------------------------------------------------------------------
export const addFilePluginAction = (transform, action) => {
  const actions = transform.getMeta(filePluginKey) || [];
  transform.setMeta(filePluginKey, actions.concat([ action ]));
};

// --------------------------------------------------------------------------
export const localFileURLFromUUID = (uuid, failed = false) => `local://${ uuid }${ failed ? "?failed" : "" }`;

// --------------------------------------------------------------------------
export const isLocalFileURL = url => {
  return url && url.startsWith && url.startsWith("local://");
};

// --------------------------------------------------------------------------
export const isFailedLocalFileURL = url => {
  return isLocalFileURL(url) && url.endsWith("?failed");
};

// --------------------------------------------------------------------------
// Finds all nodes _referencing_ the local file with the given uuid - note that these aren't necessarily
// image/video nodes, as link nodes can also reference local images/videos, and attachment nodes can reference local
// files as well
export const findLocalFileNodes = ({ doc, schema }, uuid) => {
  const localFileURL = localFileURLFromUUID(uuid);

  const results = [];

  doc.descendants((node, pos) => {
    let url;

    if (node.type === schema.nodes.image || node.type === schema.nodes.video) {
      url = node.attrs.src;
    } else if (node.type === schema.nodes.link) {
      url = urlFromLinkAttributes(node.attrs);
    } else if (node.type === schema.nodes.attachment) {
      url = node.attrs.data;
    } else {
      return;
    }

    if (url && url.startsWith(localFileURL)) results.push({ node, pos });
  });

  return results;
};


// --------------------------------------------------------------------------
export const getLocalFileMetadata = (state, url) => {
  const localFileByUUID = filePluginKey.getState(state);
  if (!localFileByUUID) return null;

  const uuids = Object.keys(localFileByUUID);
  for (let i = 0; i < uuids.length; i++) {
    const uuid = uuids[i];

    const localFileURL = localFileURLFromUUID(uuid);
    if (url.startsWith(localFileURL)) return localFileByUUID[uuid];
  }

  return null;
};

// --------------------------------------------------------------------------
export const getFilePluginState = state => {
  return filePluginKey.getState(state) || {};
};

// --------------------------------------------------------------------------
const filePluginKey = new PluginKey("file");

// --------------------------------------------------------------------------
const filePlugin = new Plugin({
  key: filePluginKey,

  // --------------------------------------------------------------------------
  props: {
    decorations: state => {
      const localFileByUUID = filePluginKey.getState(state);
      const uuids = Object.keys(localFileByUUID);
      if (uuids.length === 0) return DecorationSet.empty;

      const decorations = [];

      uuids.forEach(uuid => {
        const metadata = localFileByUUID[uuid];

        findLocalFileNodes(state, uuid).forEach(({ node, pos }) => {
          decorations.push(Decoration.node(pos, pos + node.nodeSize, {}, { uuid, ...metadata }));
        });
      })

      return DecorationSet.create(state.doc, decorations);
    },
  },

  // --------------------------------------------------------------------------
  state: {
    init: () => ({
      // Keys are local file UUIDs, with values shaped as:
      //  {
      //    url: String
      //    height: Number?
      //    width: Number?
      //  }
    }),
    apply(transaction, localFileByUUID, _oldState, _state) {
      const actions = transaction.getMeta(filePluginKey);
      if (!actions || actions.length === 0) return localFileByUUID;

      localFileByUUID = { ...localFileByUUID };

      for (let i = 0; i < actions.length; i++) {
        const action = actions[i];

        if (action.remove) {
          const { uuid } = action.remove;
          delete localFileByUUID[uuid];
        }

        if (action.add) {
          const { uuid, ...metadata } = action.add;
          localFileByUUID[uuid] = metadata;
        }

        if (action.update) {
          const { uuid, ...updates } = action.update;
          localFileByUUID[uuid] = { ...(localFileByUUID[uuid] || {}), ...updates };
        }
      }

      return localFileByUUID;
    }
  },
});
export default filePlugin;

import { dropPoint } from "prosemirror-transform"

import { HIDDEN_BY_COLLAPSE_CLASS } from "lib/ample-editor/lib/collapsible-defines"

// --------------------------------------------------------------------------
export default class DropCursorView {
  constructor(editorView) {
    this.editorView = editorView;
    this.cursorPos = null;
    this.element = null;
    this.timeout = null;
    this.width = 2;

    this.handlers = [ "dragover", "dragend", "drop", "dragleave" ].map(name => {
      const handler = e => this[name](e);
      editorView.dom.addEventListener(name, handler);
      return { name, handler };
    })
  }

  // --------------------------------------------------------------------------
  destroy() {
    this.handlers.forEach(({ name, handler }) => this.editorView.dom.removeEventListener(name, handler));
  }

  // --------------------------------------------------------------------------
  update(editorView, prevState) {
    if (this.cursorPos !== null && prevState.doc !== editorView.state.doc) {
      if (this.cursorPos > editorView.state.doc.content.size) {
        this.setCursor(null)
      } else {
        this.updateOverlay();
      }
    }
  }

  // --------------------------------------------------------------------------
  setCursor(pos) {
    if (pos === this.cursorPos) return;
    this.cursorPos = pos;
    if (pos === null) {
      if (this.element) {
        this.element.parentNode.removeChild(this.element);
        this.element = null;
      }
    } else {
      this.updateOverlay();
    }
  }

  // --------------------------------------------------------------------------
  targetNodeTypeMatchesDragNodeType(targetNode) {
    if (!targetNode) return false;
    const dragging = this.editorView.dragging;
    if (!dragging) return false;
    const slice = dragging.slice;
    if (!slice || !slice.content) return false;

    const firstDragNode = slice.content.content[0];
    if (!firstDragNode) return false;
    return targetNode.type === firstDragNode.type;
  }

  // --------------------------------------------------------------------------
  visibleDomNodeAt(pos) {
    const domNode = this.editorView.nodeDOM(pos);
    if (domNode && "className" in domNode && domNode.className.indexOf(HIDDEN_BY_COLLAPSE_CLASS) === -1) {
      return domNode;
    } else {
      return null;
    }
  }

  // --------------------------------------------------------------------------
  updateOverlay() {
    const { doc, selection } = this.editorView.state;
    const $pos = doc.resolve(this.cursorPos);
    const halfWidth = this.width / 2;
    let rect;
    if (!$pos.parent.inlineContent) {
      if (selection.from <= $pos.pos && $pos.pos <= selection.to) {
        const domNode = this.visibleDomNodeAt(selection.from);
        if (domNode) {
          const nodeRect = domNode.getBoundingClientRect();
          rect = { bottom: nodeRect.top + halfWidth, left: nodeRect.left, right: nodeRect.right, top: nodeRect.top - halfWidth };
        }
      }

      const { nodeBefore: before, nodeAfter: after } = $pos;
      if (!rect && (before || after)) {
        let left, right, top;
        const domNodeBeforeDropPos = before ? this.visibleDomNodeAt(this.cursorPos - before.nodeSize) : null;
        const domNodeAfterDropPos = this.visibleDomNodeAt(this.cursorPos);

        // The only case in which we want to let the before node dictate drop position is if there is no after node
        if (!domNodeAfterDropPos && domNodeBeforeDropPos) {
          const domRect = domNodeBeforeDropPos.getBoundingClientRect();
          ({ left, right, bottom: top } = domRect);
          top += this.width;
          if (before.type.spec.isListItem && this.targetNodeTypeMatchesDragNodeType(before)) {
            const listItemStyle = window.getComputedStyle(domNodeBeforeDropPos);
            left += parseInt(listItemStyle.paddingLeft, 10);
          }
        } else if (domNodeAfterDropPos && after) {
          const domRect = domNodeAfterDropPos.getBoundingClientRect();
          const domRectAbove = domNodeBeforeDropPos && domNodeBeforeDropPos.getBoundingClientRect();
          ({ left, right, top } = domRect);
          if (domRectAbove) top = (top + domRectAbove.bottom) / 2;
          if (after.type.spec.isListItem && this.targetNodeTypeMatchesDragNodeType(after)) {
            const listItemStyle = window.getComputedStyle(domNodeAfterDropPos);
            left += parseInt(listItemStyle.paddingLeft, 10);
          }
        }

        if (left) rect = { left, right, top: top - halfWidth, bottom: top + halfWidth };
      }
    }

    if (!rect) { // Dragging over a mid-line pos
      const coords = this.editorView.coordsAtPos(this.cursorPos);
      rect = { left: coords.left - halfWidth, right: coords.left + halfWidth, top: coords.top, bottom: coords.bottom };
    }

    const parent = this.editorView.dom.offsetParent;
    if (!this.element) {
      this.element = parent.appendChild(document.createElement("div"));
      this.element.className = "ample-dropcursor";
    }
    let parentLeft, parentTop;
    if (!parent || parent === document.body && window.getComputedStyle(parent).position === "static") {
      parentLeft = -pageXOffset;
      parentTop = -pageYOffset;
    } else {
      const parentRect = parent.getBoundingClientRect();
      parentLeft = parentRect.left - parent.scrollLeft;
      parentTop = parentRect.top - parent.scrollTop;
    }
    this.element.style.left = (rect.left - parentLeft) + "px";
    this.element.style.top = (rect.top - parentTop) + "px";
    this.element.style.width = (rect.right - rect.left) + "px";
    this.element.style.height = (rect.bottom - rect.top) + "px";
  }

  // --------------------------------------------------------------------------
  scheduleRemoval(timeout) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.setCursor(null), timeout);
  }

  // --------------------------------------------------------------------------
  dragover(event) {
    if (!this.editorView.editable) return;

    const pos = this.editorView.posAtCoords({ left: event.clientX, top: event.clientY });
    if (pos) {
      let target = pos.pos;

      if (this.editorView.dragging && this.editorView.dragging.slice) {
        try {
          target = dropPoint(this.editorView.state.doc, target, this.editorView.dragging.slice);
        } catch (_error) {
          // `dropPoint` can throw `Error: Called contentMatchAt on a node with invalid content`
        }
        if (target === null) return this.setCursor(null);
      }

      this.setCursor(target);
      this.scheduleRemoval(5000);
    }
  }

  // --------------------------------------------------------------------------
  dragend() {
    this.scheduleRemoval(20);
  }

  // --------------------------------------------------------------------------
  drop() {
    this.scheduleRemoval(20);
  }

  // --------------------------------------------------------------------------
  dragleave(event) {
    if (event.target === this.editorView.dom || !this.editorView.dom.contains(event.relatedTarget)) {
      this.setCursor(null);
    }
  }
}

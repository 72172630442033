import { dateTextFromDuration, isoDurationFromDurationParams } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export const DURATION_ANCHOR_NAME = {
  COMPLETE: "complete",
  DUE: "due",
  NOW: "now",
};

export const DURATION_ANCHOR_RELATION = {
  AFTER: "after",
  BEFORE: "before",
  FROM: "from",
};

// --------------------------------------------------------------------------
export const SAME_DAY_DURATION = "PT9H";

// --------------------------------------------------------------------------
export function blurTargetOnEnter(event) {
  if (event.key === "Enter") {
    event.target.blur();
  }
}

// --------------------------------------------------------------------------
function dateSuggestionsFromParams(anchorName, anchorRelation, { number = null, increment = null } = {}) {
  const suggestions = [];

  if (number === null && anchorRelation === DURATION_ANCHOR_RELATION.BEFORE) {
    suggestions.push({ duration: SAME_DAY_DURATION, text: sameDayText(anchorName) });
  }

  if (increment === null || increment === "day") {
    (number !== null ? [ number ] : [ 1, 2, 3 ]).forEach(suggestedNumber => {
      const duration = `P${ suggestedNumber }D`;
      suggestions.push({
        duration,
        text: `${ dateTextFromDuration(duration) } ${ anchorRelation } ${ anchorName }`
      });
    });
  }

  [ "week", "month" ].forEach(suggestedIncrement => {
    const suggestedNumber = number || 1;
    const duration = `P${ suggestedNumber }${ suggestedIncrement.substr(0, 1).toUpperCase() }`;
    suggestions.push({
      duration,
      text: `${ dateTextFromDuration(duration) } ${ anchorRelation } ${ anchorName }`
    });
  });

  return suggestions;
}

// --------------------------------------------------------------------------
export function dateSuggestionsFromText(anchorName, anchorRelation, text) {
  if (!text) return dateSuggestionsFromParams(anchorName, anchorRelation);

  // Maybe they entered a number we can parse
  const match = text.match(/^(\d+)\b/);
  const number = match ? parseInt(match[1], 10) : null;
  return dateSuggestionsFromParams(anchorName, anchorRelation, { number });
}

// --------------------------------------------------------------------------
export function durationFromTimeText(timeText) {
  if (timeText === "") return "";

  const timeParams = timeParamsFromTimeText(timeText) || {};

  return isoDurationFromDurationParams({
    hours: timeParams.hour,
    minutes: timeParams.minute,
  });
}

// --------------------------------------------------------------------------
export function sameDayText(anchorName) {
  if (anchorName === DURATION_ANCHOR_NAME.NOW) {
    return "Today";
  } else {
    return `Day task is ${ anchorName }`;
  }
}

// --------------------------------------------------------------------------
export function timeParamsFromTimeText(timeText) {
  const match = timeText.match(/^(\d{1,2})(?::(\d{2,}))?(?:\s*(?:([ap])m?))?\b/i);
  if (match) {
    const minute = match[2] ? parseInt(match[2], 10) : 0;
    if (minute > 60) return null;

    const meridiem = match[3] ? match[3].toLowerCase() + "m" : null;

    let hour = parseInt(match[1], 10);
    if (hour > 23) return null;

    if (meridiem === "pm" && hour < 12) hour += 12;
    if (hour === 12 && meridiem !== "pm") hour = 0;

    return { hour, minute };
  }

  return null;
}

// --------------------------------------------------------------------------
function timeSuggestionFromParams({ hour, minute }) {
  const meridiem = hour > 11 ? "pm" : "am";
  let twelveHour = hour > 12 ? hour - 12 : hour;
  if (twelveHour === 0) twelveHour = 12;

  const formattedMinute = minute ? (minute < 10 ? "0" + minute : minute) : "00";

  return {
    duration: isoDurationFromDurationParams({ hours: hour, minutes: minute }),
    text: `${ twelveHour }:${ formattedMinute } ${ meridiem }`,
  }
}

// --------------------------------------------------------------------------
function timeSuggestionsFromParams({ hour, minute } = { hour: null, minute: null }) {
  const suggestions = [];

  if (hour !== null) {
    if (hour > 12) suggestions.push(timeSuggestionFromParams({ hour: hour - 12, minute }));
    if (hour > 0) suggestions.push(timeSuggestionFromParams({ hour: hour - 1, minute }));

    suggestions.push(timeSuggestionFromParams({ hour, minute }));

    if (hour < 23) suggestions.push(timeSuggestionFromParams({ hour: hour + 1, minute }));
    if (hour < 13) suggestions.push(timeSuggestionFromParams({ hour: hour + 12, minute }));
  } else {
    // Start the list at 8 AM, as the mid-night hours are less likely to be useful
    for (let offsetHour = 8; offsetHour < 24 + 8; offsetHour++) {
      const availableHour = offsetHour % 24;
      const availableMeridiem = availableHour > 11 ? "pm" : "am";
      let twelveHour = availableMeridiem === "pm" ? availableHour - 12 : availableHour;
      if (twelveHour === 0) twelveHour = 12;

      suggestions.push({
        duration: isoDurationFromDurationParams({ hours: availableHour, minutes: 0 }),
        text: `${ twelveHour }:00 ${ availableMeridiem }`,
      });

      suggestions.push({
        duration: isoDurationFromDurationParams({ hours: availableHour, minutes: 30 }),
        text: `${ twelveHour }:30 ${ availableMeridiem }`,
      });
    }
  }

  return suggestions;
}

// --------------------------------------------------------------------------
export function timeSuggestionsFromText(text) {
  const timeParams = timeParamsFromTimeText(text);
  return timeSuggestionsFromParams(timeParams || { hour: null, minute: null });
}

import codemark from "prosemirror-codemark"

// --------------------------------------------------------------------------
const createCodePlugin = schema => {
  // prosemirror-codemark creates their own inputrules plugin to handle backticks for markdown-style code-mark creation,
  // but it's pretty lacking compared to our own inputrules for the same (as demonstrated by our test coverage, where
  // the prosemirror-codemark input rules fail in a number of cases), so we'll use ours instead.
  const [ codemarkPlugin, _ ] = codemark(schema.marks.code);

  const cmHandleClick = codemarkPlugin.props.handleClick;
  codemarkPlugin.props.handleClick = view => {
    // As of CodeMark v0.3.8, when we are initially focusing into the document, codemark dispatches a transaction
    // that causes the latent state.selection.head pos (which defaults to first pos in document when unfocused)
    // to be used as the selection pos (ignoring where user tapped in doc), depending on timing
    // More @ https://public.amplenote.com/MWPs6tiYZoAhjm8fqWxqhBp9
    const { state: { selection } } = view;
    if (!selection || !selection.$head || selection.head > selection.$head.depth) {
      cmHandleClick(view);
    }
  };

  return codemarkPlugin;
};
export default createCodePlugin;

import { NodeSelection } from "prosemirror-state"

import { isLocalFileURL } from "lib/ample-editor/plugins/file-plugin"
import MediaView from "lib/ample-editor/views/media-view"
import { isFirefox } from "lib/ample-editor/lib/client-info"

// --------------------------------------------------------------------------
const createErrorContainer = () => {
  const errorContainer = document.createElement("div");
  errorContainer.className = "error-container";
  errorContainer.contentEditable = "false";

  const errorIcon = document.createElement("i");
  errorIcon.className = "material-icons";
  errorIcon.textContent = "play_disabled";
  errorContainer.appendChild(errorIcon);

  const errorText = document.createElement("div");
  errorText.className = "error-text";
  errorText.textContent = "This video can't be played.";
  errorContainer.appendChild(errorText);

  const errorLink = document.createElement("a");
  errorLink.href = "https://www.amplenote.com/help/using_videos#Video_Limitations";
  errorLink.rel = "noopener noreferrer";
  errorLink.target = "_blank";
  errorLink.textContent = "Learn more";
  errorContainer.appendChild(errorLink);

  return errorContainer;
};

// --------------------------------------------------------------------------
export default class VideoView extends MediaView {
  _errorContainer = null;

  // --------------------------------------------------------------------------
  constructor(node, editorView, getPos) {
    const { state: { schema } } = editorView;

    super(
      editorView,
      getPos,
      schema.nodes.video,
      null, // createContainer
      () => {
        const videoElement = document.createElement("video");
        videoElement.setAttribute("controls", "");
        videoElement.setAttribute("playsInline", "");
        videoElement.setAttribute("preload", "metadata");
        videoElement.addEventListener("loadedmetadata", _event => {
          if (this._errorContainer !== null) {
            this._errorContainer.remove();
            this._errorContainer = null;
          }

          // videoWidth will be zero (in Chrome, at least) if the video load fails
          if (this._contentElement.videoWidth) {
            this._naturalWidth = this._contentElement.videoWidth;
          }

          this._onContentElementWidthSet();
        });

        if (isFirefox) {
          // On Firefox, video elements with controls don't get click events, so clicking on the video won't focus
          // it like it will on Chrome/Safari/etc. If we drop the controls attribute, we have to implement controls
          // ourselves, so instead we'll just select the video on Firefox when you play it, which is what happens when
          // clicking on any part of the video except the controls at the bottom.
          //   See: https://stackoverflow.com/questions/56059064/video-onclick-in-firefox
          videoElement.addEventListener("play", () => {
            const nodeSelection = NodeSelection.create(editorView.state.doc, getPos());
            editorView.dispatch(editorView.state.tr.setSelection(nodeSelection));
          });
        }

        // The browser could be unable to play the video format (e.g. .mp4), unable to play the codec (e.g. HEVC video
        // stream in a quicktime .mov file format), or unable to find the video. There doesn't appear to be any way
        // to distinguish between a video that doesn't exist (bad/404 URL) and a video that can't play, so we'll show
        // the same message for any video load errors.
        // Note there's also an `error` property set on the `video` element (see
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/error) but on both Chrome and Firefox,
        // the `code` (see https://developer.mozilla.org/en-US/docs/Web/API/MediaError) is 4 regardless of whether the
        // video URL doesn't exist (404) or couldn't be played (HEVC codec), so it's not useful.
        videoElement.addEventListener("error", () => {
          // There are a variety of reasons a video might fail to load while we're uploading it - we don't want to
          // show an error message to the user until it's uploaded and we're fairly sure it's not playable.
          try {
            const { attrs: { src } } = this._getNode();
            if (!src || isLocalFileURL(src)) return;
          } catch (_error) {
            // Don't break error handling
          }

          if (this._errorContainer === null) {
            this._errorContainer = createErrorContainer();
            this.dom.appendChild(this._errorContainer);
          }
        });

        return videoElement;
      },
      (_node, src) => {
        // Hack from https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/ to force Mobile
        // Safari (and as of Safari 14 on macOS 10.15, desktop Safari too) to show the first frame of a video (which
        // it won't otherwise show until the user taps on the video, even with preload="metadata") by advancing a single
        // millisecond into the video.
        if (src && !isLocalFileURL(src) && !src.includes("#t=")) {
          src = `${ src }#t=0.001`;
        }

        const srcWas = this._contentElement.getAttribute("src");

        // We need to pause the video if it is loading a source already and force a load after changing the `src`, as
        // Chrome (and maybe other webkit browsers) won't change the video if we're in the midst of loading (even in
        // the midst of loading `src=""`)
        // Note that server-side/test environments may not have HTMLMediaElement functions
        if (src !== srcWas) {
          if (this._contentElement.pause) this._contentElement.pause();

          this._contentElement.setAttribute("src", src);

          if (this._contentElement.load) this._contentElement.load();
        }
      }
    );

    this.update(node);
  }
}

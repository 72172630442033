/* eslint-disable */

import applyDevTools from "prosemirror-dev-tools"
import React from "react"

import DescriptionEditor from "lib/ample-editor/components/rich-footnote/description-editor"

// --------------------------------------------------------------------------
export default class DescriptionEditorDemo extends React.Component {
  _editorView = null;

  // --------------------------------------------------------------------------
  constructor(props) {
    super(props);

    const description = JSON.parse(localStorage.getItem("description-content")) || null;

    this.state = {
      description,
      readonly: false,
    };
  }

  // --------------------------------------------------------------------------
  render() {
    const { description, readonly } = this.state;

    return (
      <React.Fragment>
        <div className="ample-editor description-editor-demo">
          <div className="rich-footnote">
            <DescriptionEditor
              // disabled
              dispatchChanges={ this._onDescriptionEditorChange }
              key={ readonly ? "readonly" : "editable" }
              placeholder="Add detail or footnotes"
              readonly={ readonly }
              registerEditorView={ this._onEditorViewCreated }
              value={ description }
            />
          </div>
        </div>

        <div className="debug-actions-row">
          <div>
            <a onClick={ this._toggleReadonly }>Toggle readonly</a>
          </div>
          <div>
            &nbsp;
          </div>
        </div>
      </React.Fragment>
    );
  }

  // --------------------------------------------------------------------------
  _onDescriptionEditorChange = description => {
    if (description !== null) {
      this.setState({ description });

      const json = JSON.stringify(description);
      localStorage.setItem("description-content", json);
    }
  };

  // --------------------------------------------------------------------------
  _onEditorViewCreated = editorView => {
    // Applying this again on a hot reload will result in an error
    if (!this._editorView) applyDevTools(editorView);
    this._editorView = editorView;
  };

  // --------------------------------------------------------------------------
  _toggleReadonly = () => {
    this.setState({ readonly: !this.state.readonly });
  };
}

// --------------------------------------------------------------------------
// Split out to a dedicated file to minimize the dependencies pulled in by
// various disparate consumers
// --------------------------------------------------------------------------

const TABLE_CELL_ATTRIBUTE_DEFAULTS = {
  align: null,
  backgroundColor: null,
  borderBottom: null,
  borderLeft: null,
  borderRight: null,
  borderTop: null,
  color: null,
  colwidth: null,
  colspan: 1,
  rowspan: 1,
};
export default TABLE_CELL_ATTRIBUTE_DEFAULTS;

import PropTypes from "prop-types"
import React from "react"

import DateAdjustPopup from "lib/ample-editor/components/date-adjust-popup"
import PriorityAdjustPopup from "lib/ample-editor/components/priority-adjust-popup"

// --------------------------------------------------------------------------
export default class WidgetPopup extends React.PureComponent {
  _domRef = React.createRef();

  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    dismissTask: PropTypes.func.isRequired,
    getAttribute: PropTypes.func.isRequired,
    setAttribute: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------
  componentDidMount() {
    document.addEventListener("click", this._onDocumentClick, false);
  }

  // --------------------------------------------------------------------------
  componentWillUnmount() {
    document.removeEventListener("click", this._onDocumentClick, false);
  }

  // --------------------------------------------------------------------------
  render() {
    const { attributeName, close, dismissTask, getAttribute } = this.props;

    let content;
    if (attributeName === "priority") {
      content = (<PriorityAdjustPopup setPriority={ this._setPriority } />);
    } else {
      const attributeValue = getAttribute(attributeName);
      const date = attributeValue ? new Date(attributeValue * 1000) : null;
      const dueValue = attributeName === "startAt" ? getAttribute("due") : null;
      const dueDate = dueValue ? new Date(dueValue * 1000) : null;

      content = (
        <DateAdjustPopup
          close={ close }
          date={ date }
          clearDate={ attributeName === "due" ? this._clearDate : null }
          dateType={ attributeName }
          dismissTask={ attributeName === "startAt" ? dismissTask : null }
          dueDate={ dueDate }
          onDateChange={ this._setDate }
          showNumberOfDaysOption={ attributeName === "startAt" }
        />
      );
    }

    return (
      <div ref={ this._domRef }>
        { content }
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _clearDate = () => {
    this._setDate(null);
  };

  // --------------------------------------------------------------------------
  _onDocumentClick = event => {
    const { current: dom } = this._domRef;
    if (!dom || dom.contains(event.target)) return;

    this.props.close();
  };

  // --------------------------------------------------------------------------
  _setDate = date => {
    const { attributeName, setAttribute } = this.props;
    setAttribute(attributeName, date ? Math.floor(date.getTime() / 1000) : null);
  };

  // --------------------------------------------------------------------------
  _setPriority = priority => {
    const { attributeName, setAttribute } = this.props;
    setAttribute(attributeName, priority);
  };
}

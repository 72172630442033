import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useAsyncEffect } from "@react-hook/async"
import { Button } from "@rmwc/button"
import { CircularProgress } from "@rmwc/circular-progress"
import { ListItemGraphic } from "@rmwc/list"
import { Menu, MenuItem, MenuSurfaceAnchor } from "@rmwc/menu"
import { v4 as uuidv4 } from "uuid"

import ImageSizeOptions from "lib/ample-editor/components/image/image-size-options"
import useDelayedValue from "lib/ample-editor/hooks/use-delayed-value"
import { VectorIcon } from "lib/ample-editor/lib/vector-icon-paths"

import "./image-options-menu.scss"

// --------------------------------------------------------------------------
const MENU_OPTION = {
  ADD_CAPTION: "add-caption",
  DELETE_IMAGE: "delete-image",
  EXTRACT_TEXT: "extract-text",
  OPEN_IMAGE: "open-image",
};

const PLUGIN_OPTION_PREFIX = "plugin-option:";

// --------------------------------------------------------------------------
function AlignmentButtons({ align, setAlign }) {
  const onCenterAlignClick = useCallback(
    () => { setAlign("center"); },
    [ setAlign ]
  );

  const onLeftAlignClick = useCallback(
    () => { setAlign(null); },
    [ setAlign ]
  );

  return (
    <div className="alignment-buttons">
      <Button
        className={ `body-text ${ align !== "center" ? "selected" : "" }` }
        icon={ <VectorIcon name="align-left" /> }
        onClick={ onLeftAlignClick }
        outlined
      >Left-aligned</Button>
      <Button
        className={ `body-text ${ align === "center" ? "selected" : "" }` }
        icon={ <VectorIcon name="align-center" /> }
        onClick={ onCenterAlignClick }
        outlined
      >Centered</Button>
    </div>
  );
}

// --------------------------------------------------------------------------
function PluginActionItem({ promise, uuid }) {
  const shouldShowLoader = useDelayedValue(false, true);

  const { status, value: pluginAction } = useAsyncEffect(() => promise, [ promise ]);

  if (status === "loading" && shouldShowLoader) {
    return (
      <MenuItem className="image-menu-option" disabled>
        <ListItemGraphic icon={ <CircularProgress size="xsmall"/> } />
        Loading plugin
      </MenuItem>
    );
  } else if (pluginAction) {
    const { icon, name } = pluginAction;

    return (
      <MenuItem
        className="image-menu-option"
        data-option={ PLUGIN_OPTION_PREFIX + uuid }
      >
        <ListItemGraphic icon={ icon } />
        { name }
      </MenuItem>
    );
  } else {
    return null;
  }
}

// --------------------------------------------------------------------------
export default function ImageOptionsMenu(props) {
  const {
    addCaption,
    align,
    close,
    deleteImage,
    getImageOptionPluginActions,
    height,
    imageURL,
    buildInsertImageText,
    naturalWidth,
    setAlign,
    setWidth,
    width,
  } = props;

  const onClose = useCallback(
    () => { close(); },
    [ close ]
  );

  const { value: pluginActionPromises } = useAsyncEffect(
    async () => (await getImageOptionPluginActions()).map(promise => ({ promise, uuid: uuidv4() })),
    [ getImageOptionPluginActions ]
  );

  const onSelect = useCallback(
    event => {
      if (!event.detail) return;

      const { option: menuOption } = event.detail.item.dataset;
      if (!menuOption) return;

      switch (menuOption) {
        case MENU_OPTION.ADD_CAPTION:
          if (addCaption) addCaption();
          break;

        case MENU_OPTION.DELETE_IMAGE:
          if (deleteImage) deleteImage();
          break;

        case MENU_OPTION.EXTRACT_TEXT:
          if (buildInsertImageText) buildInsertImageText();
          break;

        default:
          if (menuOption.startsWith(PLUGIN_OPTION_PREFIX)) {
            const pluginActionUUID = menuOption.substring(PLUGIN_OPTION_PREFIX.length);
            const pluginActionPromise = pluginActionPromises.find(({ uuid }) => uuid === pluginActionUUID);
            if (pluginActionPromise) {
              pluginActionPromise.promise.then(pluginAction => {
                if (pluginAction) pluginAction.run();
              });
            }
          }
          break;
      }
    },
    [ addCaption, deleteImage, buildInsertImageText, pluginActionPromises ]
  );

  // Work around a weird bug in MDC (only in Safari) where `hoistToBody` and `open` result in the positioning
  // being attempted before the anchor has been measured. It generates an exception, but doesn't _seem_ to break
  // anything. If we defer a single render before opening the menu, the measurement is ready.
  const [ isOpen, setIsOpen ] = useState(false);
  useEffect(() => { setIsOpen(true); }, []);

  return (
    <MenuSurfaceAnchor>
      <Menu
        hoistToBody
        className="image-options-menu"
        focusOnOpen={ false }
        onClose={ onClose }
        onSelect={ onSelect }
        open={ isOpen }
      >
        <div className="heading">Image options</div>

        <AlignmentButtons align={ align } setAlign={ setAlign } />

        <ImageSizeOptions
          height={ height }
          naturalWidth={ naturalWidth }
          setWidth={ setWidth }
          width={ width }
        />

        {
          addCaption
            ? (
              <MenuItem className="image-menu-option" data-option={ MENU_OPTION.ADD_CAPTION }>
                <ListItemGraphic icon={ <VectorIcon name="caption-icon" /> } />
                Add a caption
              </MenuItem>
            )
            : null
        }

        {
          buildInsertImageText
            ? (
              <MenuItem className="image-menu-option" data-option={ MENU_OPTION.EXTRACT_TEXT }>
                <ListItemGraphic icon="text_rotate_vertical" />
                Extract text
              </MenuItem>
            )
            : null
        }
        {
          (imageURL && imageURL.startsWith("https://"))
            ? (
              <MenuItem
                className="image-menu-option"
                 data-option={ MENU_OPTION.OPEN_IMAGE }
                 href={ imageURL }
                 rel="noopener noreferrer"
                 tag="a"
                 target="_blank">
                <ListItemGraphic icon="open_in_new" />
                Open in new window
              </MenuItem>
            )
            : null
        }

        <MenuItem className="image-menu-option" data-option={ MENU_OPTION.DELETE_IMAGE }>
          <ListItemGraphic icon="delete_outline" />
          Delete image
        </MenuItem>

        {
          (pluginActionPromises || []).map(({ promise, uuid }) => (
            <PluginActionItem
              key={ uuid }
              promise={ promise }
              uuid={ uuid }
            />
          ))
        }
      </Menu>
    </MenuSurfaceAnchor>
  );
}

ImageOptionsMenu.propTypes = {
  align: PropTypes.string,
  addCaption: PropTypes.func,
  close: PropTypes.func.isRequired,
  deleteImage: PropTypes.func,
  getImageOptionPluginActions: PropTypes.func.isRequired,
  height: PropTypes.number,
  imageURL: PropTypes.string,
  buildInsertImageText: PropTypes.func,
  naturalWidth: PropTypes.number,
  setAlign: PropTypes.func.isRequired,
  setWidth: PropTypes.func.isRequired,
  width: PropTypes.number,
};

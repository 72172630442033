import React, { useCallback, useState } from "react"
import { Button } from "@rmwc/button"
import { TextField } from "@rmwc/textfield"

// --------------------------------------------------------------------------
const MINIMUM_WIDTH = 32;

// --------------------------------------------------------------------------
export default function ImageSizeOptions({ height, naturalWidth, setWidth, width }) {
  const [ pendingSize, setPendingSize ] = useState(null);

  const aspectRatio = (height && width) ? (height / width) : 1;

  const savePendingSize = useCallback(
    () => {
      if (pendingSize && pendingSize.width) setWidth(pendingSize.width);
      setPendingSize(null);
    },
    [ pendingSize ]
  );

  const onPendingHeightChange = useCallback(
    event => {
      const { target: { value } } = event;
      if (value === "") {
        setPendingSize({ height: NaN, width: null });
      } else {
        const pendingHeight = parseInt(value, 10);
        if (!Number.isNaN(pendingHeight)) {
          setPendingSize({ height: pendingHeight, width: Math.ceil(pendingHeight / aspectRatio) });
        }
      }
    },
    [ aspectRatio ]
  );

  const onPendingWidthChange = useCallback(
    event => {
      const { target: { value } } = event;
      if (value === "") {
        setPendingSize({ height: null, width: NaN });
      } else {
        const pendingWidth = parseInt(value, 10);
        if (!Number.isNaN(pendingWidth)) {
          setPendingSize({ height: Math.ceil(pendingWidth * aspectRatio), width: pendingWidth });
        }
      }
    },
    [ aspectRatio ]
  );

  const { height: pendingHeight, width: pendingWidth } = pendingSize || { height: null, width: null };

  let pendingHeightErrorMessage = null;
  if (pendingHeight !== null && pendingHeight !== height) {
    const minimumHeight = Math.ceil(MINIMUM_WIDTH * aspectRatio);
    const maximumHeight = Math.ceil(naturalWidth * aspectRatio);
    if (Number.isNaN(pendingHeight) || pendingHeight < minimumHeight) {
      pendingHeightErrorMessage = `Images must be at least ${ minimumHeight }px high.`;
    } else if (pendingHeight > maximumHeight) {
      pendingHeightErrorMessage = "Image cannot exceed original size.";
    }
  }

  let pendingWidthErrorMessage = null;
  if (pendingWidth !== null && pendingWidth !== width) {
    if (Number.isNaN(pendingWidth) || pendingWidth < MINIMUM_WIDTH) {
      pendingWidthErrorMessage = `Images must be at least ${ MINIMUM_WIDTH }px wide.`;
    } else if (pendingWidth > naturalWidth) {
      pendingWidthErrorMessage = "Image cannot exceed original size.";
    }
  }

  const errorMessage = pendingWidthErrorMessage || pendingHeightErrorMessage;
  const haveErrorMessage = !!errorMessage;
  const havePendingSize = pendingSize !== null;

  const onHeightKeyDown = useCallback(
    event => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "Enter":
          if (!haveErrorMessage) savePendingSize();
          break;

        case "Escape":
          event.stopPropagation();
          if (havePendingSize) setPendingSize(null);
          break;

        case "Tab":
          // Need to stop tab so editor doesn't handle it (by focusing editor)
          event.stopPropagation();
          break;
      }
    },
    [ haveErrorMessage, havePendingSize, savePendingSize ]
  );

  const onWidthKeyDown = useCallback(
    event => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case "Enter":
          if (!haveErrorMessage) savePendingSize();
          break;

        case "Escape":
          event.stopPropagation();
          if (havePendingSize) setPendingSize(null);
          break;

        case "Tab":
          // Need to stop tab so editor doesn't handle it (by focusing editor)
          event.stopPropagation();
          break;
      }
    },
    [ haveErrorMessage, havePendingSize, savePendingSize ]
  );

  return (
    <div className="size-options">
      <TextField
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        icon={ <span>W</span> }
        invalid={ !!pendingWidthErrorMessage }
        onChange={ onPendingWidthChange }
        onKeyDown={ onWidthKeyDown }
        outlined
        spellCheck="false"
        value={ Number.isNaN(pendingWidth) ? "" : (pendingWidth || width || "").toString() }
      />
      <i className="material-icons input-separator">close</i>
      <TextField
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        icon={ <span>H</span> }
        invalid={ !!pendingHeightErrorMessage }
        onChange={ onPendingHeightChange }
        onKeyDown={ onHeightKeyDown }
        outlined
        spellCheck="false"
        value={ Number.isNaN(pendingHeight) ? "" : (pendingHeight || height || "").toString() }
      />
      <Button
        disabled={ !pendingSize || !!errorMessage }
        label="Apply"
        onClick={ savePendingSize }
        unelevated
      />
      { errorMessage ? (<span className="error-message">{ errorMessage }</span>) : null }
    </div>
  );
}

// --------------------------------------------------------------------------
const POPUP_HORIZONTAL_MARGIN = 12;

// --------------------------------------------------------------------------
// Gets the left and top offsets from the childDom up to the offsetParent that contains some class (the class
// in which the popping container is located)
export function parentsOffsetFromChildDom(childDom, parentContainsClass) {
  let parentsLeftOffset = 0;
  let parentsTopOffset = 0;
  let parent = childDom.offsetParent;
  while (parent && !parent.querySelector(`.${ parentContainsClass }`)) {
    parentsLeftOffset += parent.offsetLeft;
    parentsTopOffset += parent.offsetTop;
    parent = parent.offsetParent;
  }

  return { parentsLeftOffset, parentsTopOffset };
}

// --------------------------------------------------------------------------
export function popupContainerBoundsFromDOMNode(popupContainer, parentNode, maxWidth, domNode) {
  const nodeBounds = domNode.getBoundingClientRect();
  return popupContainerBoundsFromNodeBounds(popupContainer, parentNode, maxWidth, nodeBounds);
}

// --------------------------------------------------------------------------
function popupContainerBoundsFromNodeBounds(popupContainer, parentNode, maxWidth, nodeBounds) {
  // This is only expected in tests, where the DOM is simulated
  if (!popupContainer.offsetParent) return { left: null, top: null, width: null };

  const offsetParentBounds = popupContainer.offsetParent.getBoundingClientRect();
  const parentBounds = parentNode.getBoundingClientRect();

  const top = nodeBounds.bottom - offsetParentBounds.top;
  const width = Math.min(parentBounds.width - (POPUP_HORIZONTAL_MARGIN * 2), maxWidth);

  const left = Math.max(
    POPUP_HORIZONTAL_MARGIN,
    Math.min(
      nodeBounds.left,
      parentBounds.left + parentBounds.width - width - (POPUP_HORIZONTAL_MARGIN * 2)
    )
  ) - offsetParentBounds.left;

  return { left, top, width };
}

// --------------------------------------------------------------------------
function popupContainerBoundsFromNodePos(popupContainer, editorView, maxWidth, nodePos) {
  let nodeBounds;
  try {
    nodeBounds = editorView.coordsAtPos(nodePos);
  } catch (_error) {
    // e.g. Invalid position 205 in domFromPos (called by coordsAtPos)
    return { left: null, top: null, width: null };
  }

  return popupContainerBoundsFromNodeBounds(popupContainer, editorView.dom.parentNode, maxWidth, nodeBounds);
}

// --------------------------------------------------------------------------
export function positionPopupContainer(editorView, popupContainer, maxWidth, nodePos) {
  const { left, top, width } = popupContainerBoundsFromNodePos(popupContainer, editorView, maxWidth, nodePos);
  popupContainer.setAttribute("style", `left: ${ left }px; top: ${ top }px; width: ${ width }px;`);

  const { props: { onPopupPositioned } } = editorView;
  if (onPopupPositioned) onPopupPositioned(popupContainer, nodePos);
}

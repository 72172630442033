import { navigatorLocale } from "lib/ample-editor/lib/client-info"

// --------------------------------------------------------------------------
export const NUMERIC_REGEX = /^-?[\d.,\p{Sc}]+$/u;

// --------------------------------------------------------------------------
// True if text is a string (or number) containing only numbers and symbols often found with numbers
// Ex
// isNumeric(" 1,000.00 ") => true
// isNumeric("") => false
// isNumeric("€1.034,000") => true
// isNumeric("a1") => false
// isNumeric(0) => true
// isNumeric("0.0") => true
export function isNumeric(text) {
  if (Number.isFinite(text)) return true;
  if (!text) return false;
  return NUMERIC_REGEX.test(text.trim());
}

// --------------------------------------------------------------------------
export function isGroupSeparated(numberString) {
  if (!numberString) return false;
  const groupSeparator = numericGroupSeparator(navigatorLocale);
  return groupSeparator && numberString.includes(groupSeparator);
}

// --------------------------------------------------------------------------
// Ex. formatGroupSeparated("10000") => "10,000"
export function formatGroupSeparated(numberString) {
  const groupSeparator = numericGroupSeparator(navigatorLocale);
  if (!groupSeparator) return numberString;
  return numberFromText(numberString).toLocaleString();
}

// --------------------------------------------------------------------------
// Return a float from a string that may contain a local-specific group separator and/or currency
// Ex. numberFromText("$1,000.01") => 1000.01
export function numberFromText(text) {
  if (!isNumeric(text)) return null;
  if (Number.isFinite(text)) return text;
  const localeNumberGrouper = numericGroupSeparator(navigatorLocale);
  if (localeNumberGrouper) text = text.replace(new RegExp(`\\${ localeNumberGrouper }`, "g"), "");
  return parseFloat(text.trim().replace(/\p{Sc}/gu, ""));
}

// --------------------------------------------------------------------------
export function precisionFromNumber(number) {
  if (!navigatorLocale) return 0;
  const localeDecimal = decimalSeparator(navigatorLocale);
  return number.toString().split(localeDecimal)[1]?.length || 0;
}

// --------------------------------------------------------------------------
// Format a `result` number (can be float or string) with the same trappings as the set of `values` from
// which the number was derived
// Ex: numericResultFromValues("1.234", [ "$1.20", "$0.03" ]) => "$1.23"
export function numericResultFromValues(result, values, maxDecimalPrecision) {
  const resultNumber = numberFromText(result);
  const currencySymbols = values.map(value => value && value.match(/\p{Sc}/u)).filter(n => n);
  const groupSeparated = values.find(value => isGroupSeparated(value));
  const isCurrency = !!currencySymbols[0];
  const currencyFixedDecimal = isCurrency && resultNumber !== parseInt(resultNumber, 10);
  const precisionDigits = precisionFromNumber(resultNumber);
  const exceedsPrecision = precisionDigits > maxDecimalPrecision;
  let textResult;
  if (currencyFixedDecimal) {
    textResult = `${ resultNumber.toFixed(2) }`;
  } else if (exceedsPrecision) {
    textResult = `${ resultNumber.toFixed(maxDecimalPrecision) }`;
  } else {
    textResult = `${ resultNumber }`;
  }
  textResult = groupSeparated ? formatGroupSeparated(textResult) : textResult;
  return `${ isCurrency ? currencySymbols[0] : "" }${ textResult }`;
}

// --------------------------------------------------------------------------
// Locals
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Different countries have different ways of delineating numbers. eg 1.000,00 vs 1,000.00
// This returns the character than can be omitted without changing the integer (i.e., "," in US numbers)
function numericGroupSeparator(locale) {
  if (!Intl || !locale) return null;
  const numberFormat = new Intl.NumberFormat(locale);
  const numberGroupToken = numberFormat.formatToParts(1000).find(part => part.type === "group");
  return numberGroupToken && numberGroupToken.value;
}

// --------------------------------------------------------------------------
function decimalSeparator(locale) {
  if (!Intl || !locale) return null;
  const numberFormat = new Intl.NumberFormat(locale);
  const numberGroupToken = numberFormat.formatToParts(1.1).find(part => part.type === "decimal");
  return numberGroupToken && numberGroupToken.value;
}

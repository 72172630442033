import { Plugin, PluginKey, TextSelection } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

import { getTransactionExpandedTaskUUID } from "lib/ample-editor/plugins/check-list-item-plugin"
import parseDateText, { dayPartFromText } from "lib/ample-editor/lib/parse-date-text"
import { isPlainTextBetween } from "lib/ample-editor/lib/prosemirror-util"
import DateSuggestionPluginView from "lib/ample-editor/views/date-suggestion-plugin-view"
import { DAY_PART, prioritizeHourFromDayPart } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
export const dateSuggestionPluginKey = new PluginKey("date-suggestions");

const MAX_WORDS_TO_CHECK_FROM_CURSOR = 5;
const CHARACTERS_AFTER_MATCH_TO_SHOW_SUGGESTION = 1;

// --------------------------------------------------------------------------
export const suggestionStartPos = state => {
  const suggestion = parsedDateSuggestion(state);
  return suggestion && suggestion.pos;
}

// --------------------------------------------------------------------------
export const parsedDateSuggestion = state => {
  // As of 10/2022 there is at least one case in the wild where this can be called (perhaps only from `handleKeyDown`?)
  // without `state` being present
  if (!state) return null;

  const pluginState = dateSuggestionPluginKey.getState(state);
  return pluginState && pluginState.dateSuggestion;
}

// --------------------------------------------------------------------------
export const suggestionCancelStartPos = state => {
  const pluginState = dateSuggestionPluginKey.getState(state);
  return pluginState && pluginState.suggestionCancelStartPos;
}

// --------------------------------------------------------------------------
// Deduce whether $head is in a position eligible for date suggestion
const cursorSuggestStartPos = (state, expandedTaskUUID) => {
  const { selection, selection: { empty, $head } } = state;

  // Unless selection is basic, non-empty & has a parent, no need to process suggestions
  if (!(selection instanceof TextSelection) || !empty || !$head.parent) return {};
  const checkListItemNode = checkListItemNodeFromPos($head);
  if (!checkListItemNode) return {};
  if (expandedTaskUUID && expandedTaskUUID === checkListItemNode.attrs.uuid) return {};
  if (($head.nodeBefore && !$head.nodeBefore.type.isText) || ($head.nodeAfter && !$head.nodeAfter.type.isText)) return {};

  // Because PM splits the contents of a text node into fragments around the cursor, we need to rejoin them into the actual contents of the current text node
  let taskText = "";
  let cursorAdjacentText = "";
  if ($head.nodeBefore && $head.nodeBefore.textContent) {
    const wordsBeforeCursor = $head.nodeBefore.textContent.split(" ").reverse().slice(0, MAX_WORDS_TO_CHECK_FROM_CURSOR);
    taskText += $head.nodeBefore.textContent;
    cursorAdjacentText += wordsBeforeCursor.reverse().join(" ");
  }
  if ($head.nodeAfter && $head.nodeAfter.textContent) {
    taskText += $head.nodeAfter.textContent;
    cursorAdjacentText += $head.nodeAfter.textContent.split(" ").slice(0, MAX_WORDS_TO_CHECK_FROM_CURSOR).join(" ");
  }
  const parentPos = $head.pos - $head.parentOffset;
  let textOffset = $head.textOffset;

  // Per PM docs, when cursor is "between nodes", as is true at end of a line, it returns 0, but we actually want the size since previous non-text-node:
  if ($head.nodeBefore && textOffset < $head.nodeBefore.nodeSize) textOffset = $head.nodeBefore.nodeSize;
  const textNodeStartPos = ($head.parentOffset - textOffset) + parentPos;
  if (!isPlainTextBetween(state.doc, textNodeStartPos, textNodeStartPos + cursorAdjacentText.length)) return {};

  return { cursorAdjacentText, parentPos, checkListItemNode, taskText, textNodeStartPos };
}

// --------------------------------------------------------------------------
const ensureFullDayOfWeek = sugarQuery => {
  if (nextWeekdayRegex.exec(sugarQuery) && !sugarQuery.includes("day")) {
    const dayOfWeekPortion = nextWeekdayRegex.exec(sugarQuery)[0];
    const dowWords = dayOfWeekPortion.trim().split(" ");
    const partialDay = dowWords.pop();
    const dayIndex = {
      "mon": "Monday", "tue": "Tuesday", "wed": "Wednesday", "thu": "Thursday", "fri": "Friday", "sat": "Saturday", "sun": "Sunday"
    };
    const fullDay = dayIndex[partialDay.substring(0, 3)];

    sugarQuery = sugarQuery.replace(partialDay, fullDay);
  }
  return sugarQuery;
}

// --------------------------------------------------------------------------
// Sugar accepts a relatively narrow range of inputs, this method exists to translate dateText into something that it can recognize
const sugarQueryFromDateText = dateText => {
  let sugarQuery = dateText.toLowerCase();

  // Sugar generally doesn't suggest a datetime unless the text includes a time. An exception to this is queries
  // of the form "in X [time units]" in which case Sugar won't allow any kind of time to be included in parse (returns null result if present)
  if (!/(\b|\s)(at|morning|afternoon|evening|night|[0-2]\d:\d{2})/.exec(dateText) && !inDateTimeRegex.exec(dateText)) {
    sugarQuery = sugarQuery += " morning";
  }

  if (sugarQuery.includes("morning")) {
    sugarQuery = sugarQuery.replace("morning", `${ prioritizeHourFromDayPart(DAY_PART.MORNING) }:00`);
  } else if (sugarQuery.includes("afternoon")) {
    sugarQuery = sugarQuery.replace("afternoon", `${ prioritizeHourFromDayPart(DAY_PART.AFTERNOON) }:00`);
  } else if (/evening|night/.exec(sugarQuery)) {
    sugarQuery = sugarQuery.replace(/evening|night/, `${ prioritizeHourFromDayPart(DAY_PART.NIGHT) }:00`);
  }

  sugarQuery = ensureQueryWithMinutes(sugarQuery)
  sugarQuery = sugarQuery.replace(/(\d)(nd|rd|st|th)/, "$1");
  sugarQuery = ensureFullDayOfWeek(sugarQuery);

  if (/^at/.exec(sugarQuery)) {
    let hasDateComponent = false;
    if (sugarQuery.match(containsADateRegex)) {
      hasDateComponent = true;

      // For dates with style "at 18:00 tomorrow" or "at 3pm on Tuesday", need to swap date & time (date first) for Sugar to get it
      const hourMinuteTextMatch = sugarQuery.match(/^at\s[0-2]?\d:\d{2}\s*([ap]m)?/);
      if (hourMinuteTextMatch) {
        sugarQuery = `${ sugarQuery.replace(hourMinuteTextMatch[0], "") } ${ hourMinuteTextMatch[0] }`
      }
    }

    if (!hasDateComponent) {
      // If date string ends without a meridiem, they might want to schedule it later today, or tomorrow. Pick whatever is soonest & not in the past
      const hourMinuteTextMatch = sugarQuery.match(/(?:^|\s)?at\s([0-2]?\d):(\d{2})/);
      let hour = parseInt(hourMinuteTextMatch[1], 10);
      const minute = hourMinuteTextMatch[2] && parseInt(hourMinuteTextMatch[2], 10);
      // If user proposed a time without a date, we will pick the closest datetime (today or tomorrow) at which their time hasn't passed
      if (/pm/.test(sugarQuery)) hour += 12;
      const canBePm = !/[ap]m(\b|$)/.test(sugarQuery) && hour > 0 && hour <= 12

      const currentHour = (new Date()).getHours();
      if (hour > currentHour || (minute && hour === currentHour && minute > (new Date()).getMinutes())) {
        sugarQuery = `today ${ sugarQuery }`;
      } else if (canBePm && (hour + 12) > currentHour) {
        sugarQuery = `today ${ sugarQuery.replace(hourMinuteTextMatch[0], `${ hourMinuteTextMatch[0] }pm`) }`;
      } else {
        sugarQuery = `tomorrow ${ sugarQuery }`
      }
    }
  }

  // Remove a few other words that reduce the probability that Sugar will produce a match (but were useful for ensuring we matched date text, thus their being removed as final matter)
  sugarQuery = sugarQuery.replace(/(\s|^)(on|this)/g, "").trim();

  return sugarQuery;
}

// --------------------------------------------------------------------------
const checkListItemNodeFromPos = $pos => {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const node = $pos.node(depth);
    if (!node) return null;

    // Don't allow task commands menu in links
    if (node.type.name === "link") return null;
    if (node.type.name === "check_list_item") return node;
  }

  return null;
}

// --------------------------------------------------------------------------
// Check what Sugar recognizes: https://sugarjs.com/dates
const monthRegex = /(^|\s)(on)?\s*((january|jan|february|feb|march|april|may|june|july|august|aug|september|sep|october|oct|november|nov|december|dec)\s[0-3]?\d([thsrd]{2})?)/i;
const monthPrefixedRegex = new RegExp(`(^|\\s)(on|this|next)${ monthRegex.source }`, "i");
const nextWeekdayRegex = /(^|\s)(on)?\s*((next|this)?\s*(mon|tues?|wedn?e?s?|thur?s?|fri|satu?r?|sun)d?a?y?)(?=\s|$|\b)/i;
const nextWeekdayPrefixedRegex = new RegExp(`(^|\\s)(at|on|next|this)${ nextWeekdayRegex.source }`, "i");
const nextDateRelativeRegex = /(^|\s)((next|this)\s(week|month|year))|tomorrow/i;

// If time follows a date it doesn't need an "at" prefix, e.g., "Monday 3pm" or "tomorrow 6:30" are reasonable to interpret even if their use of English leaves something to be desired
const timeAccompanyingDateRegex = /(^|\s)(at\s)?([0-2]?\d)((:\d{1,2})?\s?[ap]?m?)/i;
// If the time doesn't follow a date, we require it to be prefixed with "at", but it doesn't need a meridiem nor minutes
const timeStandaloneRegex = /(^|\s)at\s([0-2]?\d)((:\d{1,2})?\s*([apm]+)?)(?:$|\b)/i;
const relativeTimeOfDayRegex = /(^|\s)(morning|afternoon|evening|night)/i;

const inDateRegex = /(^|\s)in\s([\d]{1,3}|one|two|three|four|five|six|seven|ten)\s(day|week|month|year)s?/i;
const inTimeRegex = /(^|\s)in\s([\d]{1,3}|one|two|three|four|five|six|seven|ten)\s(minute|hour)s?/i;
const inDateTimeRegex = /(^|\s)in\s([\d]{1,3}|one|two|three|four|five|six|seven|ten)\s(minute|hour|day|week|month|year)s?/i;

// First OR of dateAndTimeRegexString handles the more common datetime format, "on Tuesday at 3pm" or "January 1st morning"
// Second part is for "at 3pm on Tuesday" or "morning on June 1"
// Third part is for "at 18:00 tomorrow". If the user included "at" and a valid time, we will allow them to drop the usual "on" that should separate a time that comes before a date
const dateAndTimeRegexString = `(
  ((${ nextWeekdayRegex.source }|${ monthRegex.source }|${ nextDateRelativeRegex.source })(${ timeAccompanyingDateRegex.source }|${ relativeTimeOfDayRegex.source }))|
  ((${ timeAccompanyingDateRegex.source }|${ relativeTimeOfDayRegex.source })(${ nextWeekdayRegex.source }|${ monthRegex.source }))|
  ((${ timeStandaloneRegex.source }|${ relativeTimeOfDayRegex.source })\\s*(${ nextDateRelativeRegex.source }|${ nextWeekdayRegex.source }))
)`.replace(/\s*\n\s*/g, "").replace(/\s+/g, " ");
const dateAndTimeRegex = new RegExp(dateAndTimeRegexString, "i");
const dateOnlyRegex = new RegExp(`(${ inDateRegex.source }|${ nextWeekdayPrefixedRegex.source }|${ monthPrefixedRegex.source }|${ nextDateRelativeRegex.source })`, "i");
const timeOnlyRegex = new RegExp(`${ timeStandaloneRegex.source }|${ inTimeRegex.source }`, "i");
const containsADateRegex = new RegExp(`(${ nextWeekdayRegex.source }|${ monthRegex.source }|${ nextDateRelativeRegex.source })`, "i");

// --------------------------------------------------------------------------
// @param {String} `text` the portion of a task text that is deemed to specify a date
// @param {Integer} `includeIndex` an index within text that must be included within (maxIndexVariance) a prospective regex match to be considered valid
//
// Returns empty object if no dateText was found at includeIndex. Otherwise, returns object with keys:
//   `sugarQueryDateText` a date precursor that Sugar will be able to recognize (potentially after augmenting via sugarQueryFromDateText, e.g., "next fri")
//   `dateText` all of the contiguous text surrounding the users query that seems to be a date (potentially a date-in progress, e.g., "next frid")
function dateTextFromText(text, includeIndex, { taskStartAt = null } = {}) {
  let match;
  let candidateResults = [];
  match = dateAndTimeRegex.exec(text);
  if (match) candidateResults.push(match);

  match = dateOnlyRegex.exec(text);
  if (match) candidateResults.push(match);

  match = timeOnlyRegex.exec(text);
  let timeMatch;
  if (match) {
    timeMatch = match;
    candidateResults.push(match);
  }

  if (candidateResults.length) {
    candidateResults = candidateResults.filter(result => result.index <= includeIndex &&
      (result.index + result[0].length + CHARACTERS_AFTER_MATCH_TO_SHOW_SUGGESTION) >= includeIndex);
    if (!candidateResults.length) return {};
    const sortedResults = candidateResults.sort((a, b) => (a[0].trim().length > b[0].trim().length ? -1 : 1));
    const longestMatch = sortedResults[0];
    let sugarQueryDateText = longestMatch[0];
    let prependDate = false;
    if (longestMatch === timeMatch && taskStartAt) {
      prependDate = true;
    }

    const remainderOfText = text.substring(longestMatch.index, text.length);
    // Grab any other characters that follow the part of their query we identified as a Sugar query precursor, e.g., "Frid" instead of the sugar precursor "Fri"
    const dateTextMatch = new RegExp(`${ sugarQueryDateText }[\\w]*`).exec(remainderOfText);
    if (prependDate) {
      const taskStartDate = new Date(taskStartAt * 1000);
      sugarQueryDateText = `${ taskStartDate.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" }) } ${ sugarQueryDateText.trim() }`
    }

    return { sugarQueryDateText: sugarQueryDateText.trim(), dateText: dateTextMatch[0].trim() };
  } else {
    return {};
  }
}

// --------------------------------------------------------------------------
// Returns null if $head is not in the midst of a suggestable date within a task.  If $head is in a suggestable date,
// returns an object with keys necessary to show & consummate a suggested date
function selectionEligibleMatch(state, { excludeStartPos = null, expandedTaskUUID = null } = {}) {
  const { cursorAdjacentText, parentPos, checkListItemNode, taskText, textNodeStartPos } = cursorSuggestStartPos(state, expandedTaskUUID);
  const { selection: { $head } } = state;
  if (!checkListItemNode) return null;

  let date;
  const cursorAdjacentStartPos = taskText.indexOf(cursorAdjacentText);
  const includeIndex = $head.pos - textNodeStartPos - cursorAdjacentStartPos;
  const { dateText, sugarQueryDateText } = dateTextFromText(cursorAdjacentText, includeIndex, { taskStartAt: checkListItemNode.attrs.due });
  if (dateText) {
    const sugarQuery = sugarQueryFromDateText(sugarQueryDateText);

    date = parseDateText(sugarQuery);
    if (!date) return null;
  } else {
    return null;
  }

  const resultIndex = taskText.indexOf(dateText);
  const startPos = textNodeStartPos + resultIndex;

  // If user previously dismissed a suggestion at this pos, don't suggest
  if (startPos === excludeStartPos) return null;

  return {
    date,
    dueDatePart: dayPartFromText(dateText),
    pos: startPos,
    text: dateText,
    taskUUID: checkListItemNode.attrs.uuid,
    taskPos: parentPos,
  };
}

// --------------------------------------------------------------------------
// If the datetime being built has a time but not minutes present, add :00 so that Sugar doesn't fail for
// datetimes like "tomorrow at 7", which by default it interprets as occurring years ago
function ensureQueryWithMinutes(sugarQuery) {
  const hasHours = /at\s[0-2]?\d/i.test(sugarQuery);
  const hasFullMinutes = /at\s[0-2]?\d:\d{2}/i.test(sugarQuery);
  if (hasHours && !hasFullMinutes) {
    const timeWithMinutesMatch = sugarQuery.match(/at\s[0-2]?\d:?\d{0,2}/)
    if (/:\d/.test(timeWithMinutesMatch)) {
      sugarQuery = sugarQuery.replace(timeWithMinutesMatch, `${ timeWithMinutesMatch }0`);
    } else {
      if (timeWithMinutesMatch.includes(":")) {
        sugarQuery = sugarQuery.replace(timeWithMinutesMatch, `${ timeWithMinutesMatch }00`);
      } else {
        sugarQuery = sugarQuery.replace(timeWithMinutesMatch, `${ timeWithMinutesMatch }:00`);
      }
    }
  }
  return sugarQuery;
}

// --------------------------------------------------------------------------
const createDateSuggestionPlugin = () => {
  let pluginView = null;

  return new Plugin({
    key: dateSuggestionPluginKey,

    // --------------------------------------------------------------------------
    props: {
      // --------------------------------------------------------------------------
      closeDateSuggestionsMenu: view => {
        if (pluginView) return pluginView.dismiss(view);
      },

      // --------------------------------------------------------------------------
      decorations: state => {
        const { doc } = state;
        const dateSuggestion = parsedDateSuggestion(state);
        if (dateSuggestion) {
          const { pos, text } = dateSuggestion;
          const decoration = Decoration.inline(pos, pos + text.length, { class: "date-suggestion-text-highlight" });
          return DecorationSet.create(doc, [ decoration ]);
        } else {
          return DecorationSet.empty;
        }
      },

      // --------------------------------------------------------------------------
      handleKeyDown: (view, event) => {
        if (pluginView) return pluginView.handleKeyDown(view, event);
      },

      // --------------------------------------------------------------------------
      isDateSuggestionMenuOpen: () => {
        return pluginView ? pluginView.isMenuOpen() : false;
      },
    },

    // --------------------------------------------------------------------------
    state: {
      init: (_config, _state) => ({
        cancelStartPos: null,
        dateSuggestion: null, // Defined via selectionEligibleMatch, keys as of June 2022 [ date, dueDatePart,  pos, text, taskUUID, taskPos ]
      }),
      apply: (tr, pluginState, lastState, state) => {
        const metaProps = tr.getMeta(dateSuggestionPluginKey);
        let { cancelStartPos, dateSuggestion } = pluginState;
        let closeMenu = false;

        if (metaProps) {
          if (Number.isInteger(metaProps.cancelStartPos)) {
            cancelStartPos = metaProps.cancelStartPos;
            closeMenu = true;
          }
        }

        // When user open Task Detail, it takes focus from task, which makes date suggestion menu inaccessible. There is no change to $head or state when this happens, and the plugin state is set after dateSuggestionPlugin, so we're checking the transaction itself for whether a newly opened task warrants potentially closing this date suggestion
        const openTaskUUID = getTransactionExpandedTaskUUID(tr);
        if (tr.selectionSet || tr.docChanged || openTaskUUID) {
          const match = selectionEligibleMatch(state, { excludeStartPos: cancelStartPos, expandedTaskUUID: openTaskUUID });

          if (match) {
            dateSuggestion = match;
          } else if (dateSuggestion) {
            closeMenu = true;
          }
        }

        if (closeMenu) {
          pluginView.closeMenu();
          dateSuggestion = null;
        }

        return { cancelStartPos, dateSuggestion };
      }
    },

    // --------------------------------------------------------------------------
    view: editorView => {
      pluginView = new DateSuggestionPluginView(editorView);
      return pluginView;
    },

    toJSON: ({ cancelStartPos, dateSuggestion }) => ({ cancelStartPos, dateSuggestion }),
    fromJSON: (_config, { cancelStartPos, dateSuggestion }) => ({ cancelStartPos, dateSuggestion }),
  });
};
export default createDateSuggestionPlugin;

import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

import ListItemCommandsMenu from "lib/ample-editor/components/list-item-commands-menu"
import { deviceSupportsHover } from "lib/ample-editor/lib/client-info"

// --------------------------------------------------------------------------
export default function BulletMenu({ applyCommand, bulletListItem, close, containerRef, editorView, isCollapsible }) {
  const { props: { hostApp: { fetchNoteContent, suggestNotes } } } = editorView;

  useEffect(
    () => {
      const listener = event => {
        const { current: container } = containerRef;
        if (!container || container.contains(event.target)) return;

        // This happens when clicking on an option that opens a sub-menu in the ListItemCommandsMenu,
        // in which case the target element will have been unmounted and removed from the dom by this point,
        // so it won't be contained in the container anymore
        const isListItemCommandsMenuClick = event.defaultPrevented &&
          event.target && event.target.closest && event.target.closest(".list-item-commands-menu");
        if (isListItemCommandsMenuClick) return;

        close();
      };
      document.addEventListener("click", listener, false);

      return () => {
        document.removeEventListener("click", listener, false);
      };
    },
    [ close ]
  );

  // We want to focus the menu so: 1. the selection menu, if shown, is hidden; and 2. the menu can be navigated with
  // the keyboard
  const editorHadFocusRef = useRef(false);
  useEffect(
    () => {
      const { current: container } = containerRef;

      editorHadFocusRef.current = editorView.hasFocus();

      const listItem = container.querySelector(".mdc-list-item");
      if (listItem) listItem.focus();

      return () => {
        if (editorHadFocusRef.current) editorView.focus();
      };
    },
    []
  );

  const listItemCommandsMenuRef = useRef(null);
  const onKeyDown = useCallback(
    event => {
      const { current: listItemCommandsMenu } = listItemCommandsMenuRef;
      if (listItemCommandsMenu) listItemCommandsMenu.handleKeyDown(editorView, event.nativeEvent);
    },
    []
  );

  const { attrs: attributes } = bulletListItem;

  return (
    <ListItemCommandsMenu
      applyCommand={ applyCommand }
      attributes={ attributes }
      canCollapse={ isCollapsible && !attributes.collapsed && !deviceSupportsHover }
      canExpand={ isCollapsible && attributes.collapsed && !deviceSupportsHover }
      dismiss={ close }
      fetchNoteContent={ fetchNoteContent }
      listItemType={ bulletListItem.type.name }
      onKeyDown={ onKeyDown }
      ref={ listItemCommandsMenuRef }
      suggestNotes={ suggestNotes }
    />
  );
}

BulletMenu.propTypes = {
  applyCommand: PropTypes.func.isRequired,
  bulletListItem: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  editorView: PropTypes.object.isRequired,
  isCollapsible: PropTypes.bool,
};

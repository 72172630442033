import {
  chainCommands,
  createParagraphNear,
  joinBackward,
  joinForward,
  liftEmptyBlock,
  selectAll,
  selectParentNode,
  selectNodeBackward,
  selectNodeForward,
  splitBlock,
  wrapIn,
} from "prosemirror-commands"
import { keydownHandler } from "prosemirror-keymap"
import { Plugin } from "prosemirror-state"

import {
  crossOutSelectedCheckListItem,
  dismissSelectedCheckListItem,
  buildSetSelectedCheckListItemDuration,
  buildToggleSelectedCheckListItemFlag,
} from "lib/ample-editor/commands/check-list-item-commands"
import { backspaceAfterCollapsedNode } from "lib/ample-editor/commands/collapsed-node-commands"
import { enterInHeading } from "lib/ample-editor/commands/heading-commands"
import { buildMoveCursorAroundImage } from "lib/ample-editor/commands/image-commands"
import { buildDeleteParagraphForward } from "lib/ample-editor/commands/paragraph-commands"
import toggleNodeCollapsed from "lib/ample-editor/commands/toggle-node-collapsed"
import { isApplePlatform, isWindows } from "lib/ample-editor/lib/client-info"
import {
  backspaceOutOfCodeBlock,
  selectThroughCodeMirrorBoundary,
  swallowCodeBlockEnter,
} from "lib/ample-editor/lib/code-block-commands"
import {
  buildSetBlockType,
  clearActiveMarks,
  clearStoredMarksAtStart,
  deleteSelectionWithRetainEditorFocus,
  exitLastMark,
  insertHardBreak,
  joinBackwardToImageCaption,
  redoWithRetainEditorFocus,
  splitImageCaption,
  toggleMark,
  undoInputRuleWithoutHistory,
  undoWithRetainEditorFocus,
} from "lib/ample-editor/lib/commands"
import { onDocChildrenArrowPress } from "lib/ample-editor/lib/doc-children-selection"
import {
  buildStartNoteLink,
  buildToggleLink,
  deleteLinkForward,
  editLink,
  exitLink,
  openNoteLink,
  splitLinkNode,
} from "lib/ample-editor/lib/link-commands"
import {
  buildCompleteListItems,
  buildToggleBulletItemCheckListItem,
  joinBackwardToListItem,
  unwrapListItemAtStart,
  maybeIndentListItem,
  maybeIndentListItemAtStart,
  moveListItemDown,
  moveListItemUp,
  outdentListItem,
  splitListItem,
  toggleListItemDetail,
} from "lib/ample-editor/lib/list-item-commands"
import { onMultiListItemSelectionArrowPress } from "lib/ample-editor/lib/multi-list-item-selection"
import { ARROW } from "lib/ample-editor/lib/selection-util"
import {
  addRowAndNext,
  addNewCellIfTopRow,
  addNewRowIfBottomRow,
  backspaceInTable,
  deleteInTable,
  escapeCellSelectionMenu,
  maybeCreateTableFromTab,
  maybeMoveRowDown,
  maybeMoveRowUp,
  moveToNextRow,
  moveToNextCell,
  moveToPrevCell,
} from "lib/ample-editor/lib/table/table-commands"

// --------------------------------------------------------------------------
export function buildKeymap(schema) {
  const keys = {};
  function bind(key, cmd) {
    keys[key] = cmd;
  }

  const backspace = chainCommands(
    // backspaceInTable needs to handle removing selection if in table since it may involve deleting rows/columns
    backspaceInTable,
    // Before deleteSelectionWithRetainEditorFocus so it can handle (expanded) node selections
    backspaceAfterCollapsedNode,
    deleteSelectionWithRetainEditorFocus,
    // Needs to be before `joinBackward` as it has undesirable behavior in the case where the cursor is
    // at the beginning of a paragraph after a paragraph that ends with an image with a caption.
    joinBackwardToImageCaption,
    backspaceOutOfCodeBlock,
    unwrapListItemAtStart,
    joinBackward,
    joinBackwardToListItem,
    selectNodeBackward
  );

  const del = chainCommands(
    deleteInTable,
    deleteSelectionWithRetainEditorFocus,
    buildDeleteParagraphForward(schema.nodes.paragraph),
    joinForward,
    deleteLinkForward,
    selectNodeForward
  );

  bind("Backspace", backspace);
  // When Shift key on iOS is activated (as with beginning of new bullet), iOS interprets a backspace as "Shift-Backspace", so we'll bind to that so user doesn't need to double-backspace
  bind("Shift-Backspace", backspace);
  bind("Mod-Backspace", backspace);
  bind("Delete", del);
  bind("Mod-Delete", del);
  bind("Mod-a", selectAll);

  bind("Mod-z", chainCommands(
    undoInputRuleWithoutHistory,
    undoWithRetainEditorFocus,
  ));
  bind("Shift-Mod-z", redoWithRetainEditorFocus);

  bind("Escape", chainCommands(
    escapeCellSelectionMenu,
    selectParentNode,
  ));

  bind("Mod-k", buildToggleLink());
  bind("Mod-b", toggleMark(schema.marks.strong));
  bind("Mod-h", toggleMark(schema.marks.highlight));
  bind("Mod-i", toggleMark(schema.marks.em));
  bind("Ctrl-`", toggleMark(schema.marks.code));
  bind("Ctrl-[", buildStartNoteLink());
  bind("Ctrl-Shift-[", buildStartNoteLink({ insertSourceTags: true }));
  bind("@", buildStartNoteLink({ closingText: "", triggerText: "@" }));

  bind("Ctrl-Space", chainCommands(
    openNoteLink,
    buildCompleteListItems(),
  ));
  bind("Ctrl-Shift-Space", crossOutSelectedCheckListItem);

  // On macOS we'll also bind this to ctrl-enter, as that's a common convention
  bind("Mod-Enter", buildToggleBulletItemCheckListItem());

  bind("Shift-Enter", chainCommands(
    insertHardBreak(schema.nodes.hard_break),
  ));

  bind("Enter", chainCommands(
    // `splitImageCaption` needs to be before:
    //    `splitBlock` - returns true and does nothing
    //    `liftEmptyBlock` - deletes the image when pressing enter in an empty caption
    //    `splitListItem` - duplicates the image and caption into a new list item
    splitImageCaption,
    splitListItem,
    moveToNextRow,
    addRowAndNext,
    enterInHeading,
    createParagraphNear,
    liftEmptyBlock,
    swallowCodeBlockEnter,
    splitBlock,
    splitLinkNode,
  ));

  bind("Tab", chainCommands(
    maybeIndentListItemAtStart,
    editLink,
    maybeIndentListItem,
    addNewCellIfTopRow,
    moveToNextCell,
    addNewRowIfBottomRow,
    maybeCreateTableFromTab,
    swallowInput,
  ));

  bind("Shift-Tab", chainCommands(
    outdentListItem,
    moveToPrevCell,
    swallowInput,
  ));

  bind("Mod-.", toggleListItemDetail);
  bind("Ctrl-,", toggleNodeCollapsed);

  bind("Mod-Shift-ArrowDown", chainCommands(moveListItemDown, maybeMoveRowDown));
  bind("Mod-Shift-ArrowUp", chainCommands(moveListItemUp, maybeMoveRowUp));

  bind("ArrowDown", chainCommands(
    onMultiListItemSelectionArrowPress(ARROW.DOWN),
    onDocChildrenArrowPress(ARROW.DOWN)));
  bind("Shift-ArrowDown", chainCommands(
    onMultiListItemSelectionArrowPress(ARROW.DOWN),
    onDocChildrenArrowPress(ARROW.DOWN),
    selectThroughCodeMirrorBoundary(ARROW.DOWN)));
  bind("ArrowLeft", chainCommands(
    clearStoredMarksAtStart,
    onMultiListItemSelectionArrowPress(ARROW.LEFT),
    onDocChildrenArrowPress(ARROW.LEFT),
    buildMoveCursorAroundImage(ARROW.LEFT),
  ));
  bind("Shift-ArrowLeft", chainCommands(
    onMultiListItemSelectionArrowPress(ARROW.LEFT),
    onDocChildrenArrowPress(ARROW.LEFT)));

  bind("ArrowRight", chainCommands(
    exitLastMark,
    exitLink,
    onMultiListItemSelectionArrowPress(ARROW.RIGHT),
    onDocChildrenArrowPress(ARROW.RIGHT),
    buildMoveCursorAroundImage(ARROW.RIGHT)
  ));
  bind("Shift-ArrowRight", chainCommands(
    onMultiListItemSelectionArrowPress(ARROW.RIGHT),
    onDocChildrenArrowPress(ARROW.RIGHT)));
  bind("ArrowUp", chainCommands(
    onMultiListItemSelectionArrowPress(ARROW.UP),
    onDocChildrenArrowPress(ARROW.UP)));
  bind("Shift-ArrowUp", chainCommands(
    onMultiListItemSelectionArrowPress(ARROW.UP),
    onDocChildrenArrowPress(ARROW.UP),
    selectThroughCodeMirrorBoundary(ARROW.UP)));

  bind("Mod-/", clearActiveMarks);

  bind("Shift-Ctrl-\\", buildSetBlockType(schema.nodes.code_block));

  // Windows needs to handle Shift+Ctrl+<number> on keyup, not keydown
  if (!isWindows) {
    bind("Shift-Ctrl-0", buildSetBlockType(schema.nodes.paragraph));

    for (let i = 1; i <= 6; i++) {
      bind("Shift-Ctrl-" + i, buildSetBlockType(schema.nodes.heading, { level: i }));
    }
  }

  const hr = schema.nodes.horizontal_rule;
  bind("Mod-_", (state, dispatch) => {
    dispatch(state.tr.replaceSelectionWith(hr.create()).scrollIntoView());
    return true
  });

  // Platform-specific bindings
  if (isApplePlatform) {
    bind("Ctrl-Enter", buildToggleBulletItemCheckListItem());
    bind("Ctrl-h", keys["Backspace"]);
    bind("Alt-Backspace", keys["Mod-Backspace"]);
    bind("Ctrl-d", keys["Delete"]);
    bind("Ctrl-Alt-Backspace", keys["Mod-Delete"]);
    bind("Alt-Delete", keys["Mod-Delete"]);
    bind("Alt-d", keys["Mod-Delete"]);
    bind("Alt-Shift-Space", dismissSelectedCheckListItem);

    bind("Mod->", openNoteLink);
    bind("Ctrl->", wrapIn(schema.nodes.blockquote));

    // As of 2/2019, on macOS 10.14.2 and Chrome 71+ (both 71 and 72), cmd-shift-up/down are no longer received by the
    // browser. Firefox receives them, but even testing on https://unixpapa.com/js/testkey.html shows that the arrow
    // keys don't generate events when cmd and shift are both down.
    bind("Ctrl-Shift-ArrowDown", moveListItemDown);
    bind("Ctrl-Shift-ArrowUp", moveListItemUp);

    bind("Ctrl-i", buildToggleSelectedCheckListItemFlag("I"));
    bind("Ctrl-u", buildToggleSelectedCheckListItemFlag("U"));

    bind("Ctrl-1", buildSetSelectedCheckListItemDuration("PT15M"));
    bind("Ctrl-3", buildSetSelectedCheckListItemDuration("PT30M"));
    bind("Ctrl-6", buildSetSelectedCheckListItemDuration("PT60M"));
    bind("Ctrl-9", buildSetSelectedCheckListItemDuration("PT90M"));
  } else {
    bind("Mod-y", redoWithRetainEditorFocus);
    bind("Shift-Ctrl-d", dismissSelectedCheckListItem);

    bind("Ctrl->", chainCommands(
      openNoteLink,
      wrapIn(schema.nodes.blockquote),
    ));

    bind("Alt-Shift-i", buildToggleSelectedCheckListItemFlag("I"));
    bind("Alt-Shift-u", buildToggleSelectedCheckListItemFlag("U"));

    bind("Alt-Shift-1", buildSetSelectedCheckListItemDuration("PT15M"));
    bind("Alt-Shift-3", buildSetSelectedCheckListItemDuration("PT30M"));
    bind("Alt-Shift-6", buildSetSelectedCheckListItemDuration("PT60M"));
    bind("Alt-Shift-9", buildSetSelectedCheckListItemDuration("PT90M"));
  }

  return keys;
}

// --------------------------------------------------------------------------
function swallowInput(_state, _dispatch) {
  return true;
}

// --------------------------------------------------------------------------
export function createKeymapPlugin(schema) {
  const pluginProps = {
    handleKeyDown: keydownHandler(buildKeymap(schema)),
  };

  // As of Windows 10, Ctrl + Shift + <number> is the default for switching the keyboard language. Even if the user
  // has no alternative keyboard languages configured, we won't get a keydown event for Ctrl + Shift + 0 - each
  // additional language configured blocks a subsequent number's keydown event.
  // The keyup event, however, _does_ fire, so we need to watch that on windows for any Ctrl + Shift + <number> combos
  // to be detected.
  if (isWindows) {
    const mapping = {
      "Shift-Ctrl-0": buildSetBlockType(schema.nodes.paragraph),
    };

    for (let i = 1; i <= 6; i++) {
      mapping["Shift-Ctrl-" + i] = buildSetBlockType(schema.nodes.heading, { level: i });
    }

    pluginProps.handleKeyUp = keydownHandler(mapping);
  }

  return new Plugin({ props: pluginProps });
}

/* eslint-disable */
import React, { forwardRef, useCallback, useMemo, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import TasksEditor from "lib/ample-editor/components/tasks-editor"
import PLUGIN_ACTION_TYPE from "lib/ample-util/plugin-action-type"
import { BLANK_TASK_CONTENT, checkListItemFromTask } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function createBlankTask(attributes = {}) {
  return {
    content: BLANK_TASK_CONTENT,
    uuid: uuidv4(),

    ...attributes,
  };
}

// --------------------------------------------------------------------------
class TasksEditorProps {
  hostApp = null;

  // --------------------------------------------------------------------------
  constructor() {
    this.hostApp = {
      cloneNodes: async (nodes, noteURL, { move = false } = {}) => {
        return false;
      },
      fetchNoteContent: (noteURL, referenceType) => {
        // Stub
      },
      getPluginActions: pluginActionTypes => {
        if (pluginActionTypes.includes(PLUGIN_ACTION_TYPE.REPLACE_TEXT)) {
          return [
            Promise.resolve({
              icon: "extension",
              name: "Test Plugin",
              run: async (editorContext, text) => {
                return `${ text } is the worst text\nIt has a newline now\n\nBleh`;
              },
              type: PLUGIN_ACTION_TYPE.REPLACE_TEXT,
            }),
          ];
        }

        return null;
      },
      linkNote: async (url, options) => {
        // Stub
      },
      openAttachment: async (attachmentURL, options = {}) => {
        // Stub
      },
      openNoteLink: (url, { highlightTaskUUID = null } = {}) => {
        return false;
      },
      startAttachmentUpload: async (localFileUUID, file, pos) => {
        // Stub
      },
      startMediaUpload: async (localFileUUID, file, pos) => {
        // Stub
      },
      suggestNotes: async query => {
        // Stub
      },
    };
  }
}

// --------------------------------------------------------------------------
// This mimics how the single task editor in ample-web works, and generally
// how the single task editors in ample-mobile work (though they have the
// additional async communication with the host app).
function TaskEditorDemo(_props, ref) {
  const tasksEditorRef = useRef();

  const [ task, setTask ] = useState(createBlankTask());

  const taskRef = useRef();
  taskRef.current = task;

  const tasksWithGroup = useMemo(
    () => {
      const checkListItems = [ checkListItemFromTask(task) ];
      return [ { groupId: "tasks", tasks: checkListItems } ];
    },
    [ task ]
  );

  const dispatchChanges = useCallback(
    (changesByTaskUUID, _meta) => {
      const taskUUIDs = Object.keys(changesByTaskUUID);

      // There's a special case when copying an existing check-list-item then pasting it into the editor, where
      // the UUID from the pasted check-list-item replaces our task UUID, at which point we lose track of the
      // task, since the editor is now editing a different task.
      if (taskUUIDs.length === 2) {
        const addedUUID = taskUUIDs.find(taskUUID => changesByTaskUUID[taskUUID].added);
        const deletedUUID = taskUUIDs.find(taskUUID => changesByTaskUUID[taskUUID].deleted);

        if (addedUUID && deletedUUID && deletedUUID === taskRef.current.uuid) {
          const { added } = changesByTaskUUID[addedUUID];
          setTask(currentTask => ({ ...currentTask, ...added }));
          return;
        }
      }

      if (taskRef.current.uuid in changesByTaskUUID) {
        const { added, deleted, groupId, ...changes } = changesByTaskUUID[taskRef.current.uuid];
        if (deleted) return false;

        setTask(currentTask => ({ ...currentTask, ...changes }));
      }
    },
    []
  );

  const editorProps = useMemo(() => new TasksEditorProps(), []);

  return (
    <div className="task-editor">
      <div className="task-editor-ample-editor ample-editor">
        <TasksEditor
          dispatchChanges={ dispatchChanges }
          editorProps={ editorProps }
          hideToolbar
          ref={ tasksEditorRef }
          // readonly
          tasksWithGroup={ tasksWithGroup }
        />
      </div>
    </div>
  );
}

TaskEditorDemo = forwardRef(TaskEditorDemo);

export default TaskEditorDemo;

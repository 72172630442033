import { useEffect, useState } from "react"

// --------------------------------------------------------------------------
export default function useShiftKeyIsDown() {
  const [ shiftKeyDown, setShiftKeyDown ] = useState(false);

  useEffect(
    () => {
      const onKeyUpDown = event => {
        setShiftKeyDown(event.shiftKey);
      };
      document.addEventListener("keydown", onKeyUpDown);
      document.addEventListener("keyup", onKeyUpDown);

      return () => {
        document.removeEventListener("keydown", onKeyUpDown);
        document.removeEventListener("keyup", onKeyUpDown);
      };
    },
    []
  );

  return shiftKeyDown;
}

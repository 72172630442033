import PropTypes from "prop-types"
import React, { useCallback, useMemo, useRef, useState } from "react"

import TextInputWithSuggestions from "lib/ample-editor/components/task-detail/text-input-with-suggestions"
import {
  durationFromTimeText,
  timeSuggestionsFromText,
} from "lib/ample-editor/components/task-detail/util"
import { durationParamsFromDuration, timeTextFromDuration } from "lib/ample-util/tasks"

// --------------------------------------------------------------------------
function buildSuggestions(selectedDurationParams, inputText) {
  return timeSuggestionsFromText(inputText).map(({ duration, text }) => {
    const durationParams = durationParamsFromDuration(duration);

    const selected = selectedDurationParams
      ? (
        selectedDurationParams.hours === durationParams.hours &&
        selectedDurationParams.minutes === durationParams.minutes &&
        selectedDurationParams.seconds === durationParams.seconds
      )
      : false;

    return { selected, text, value: duration }
  });
}

// --------------------------------------------------------------------------
function useSetValueWithRef(setValue, value) {
  const valueRef = useRef();
  valueRef.current = value;

  const setValueWithRef = useCallback(
    newValue => {
      valueRef.current = newValue;
      setValue(newValue);
    },
    [ setValue ]
  );

  return [ valueRef, setValueWithRef ];
}

// --------------------------------------------------------------------------
export default function TimeInput(props) {
  const {
    className,
    disabled,
    disableSuggestions,
    duration,
    onFocus,
    onKeyDown,
    setDuration,
    setDurationOnChange,
  } = props;

  const [ inputDuration, setInputDuration ] = useState(null);
  const [ inputText, setInputText ] = useState(null);

  // We don't want to be constantly updating the value as the user types in the
  // field, since "1", "11", "11:1", "11:11", and "11:11pm" are all valid,
  // so we'd be setting the value for each one of those, instead of just waiting
  // until they are done to update the value
  const setDurationIfChanged = useCallback(
    newDuration => {
      if (newDuration === null) return; // No change

      if (newDuration !== duration) {
        setDuration(newDuration);
      }
    },
    [ duration, setDuration ]
  );

  // The blur event can happen fast after selectSuggestion, before the dependency chain has updated the onBlur callback
  // with a new inputDuration value, so we need to push it into a ref as soon as we call setInputDuration
  const [ inputDurationRef, setInputDurationWithRef ] = useSetValueWithRef(setInputDuration, inputDuration);

  const onBlur = useCallback(
    () => {
      setDurationIfChanged(inputDurationRef.current);

      setInputDurationWithRef(null);
      setInputText(null);
    },
    [ setDurationIfChanged ]
  );

  const selectSuggestion = useCallback(
    ({ text: newInputText, value: newInputDuration }) => {
      setInputDurationWithRef(newInputDuration);
      setInputText(newInputText);

      setDurationIfChanged(newInputDuration);
    },
    [ setDurationIfChanged ]
  );

  const setValue = useCallback(
    newInputText => {
      const newInputDuration = durationFromTimeText(newInputText);
      setInputDurationWithRef(newInputDuration);
      setInputText(newInputText);

      if (setDurationOnChange && newInputDuration) {
        setDurationIfChanged(newInputDuration);
      }
    },
    [ setDurationIfChanged, setDurationOnChange ]
  );

  let effectiveInputText;
  if (inputText === null && duration !== "") {
    effectiveInputText = timeTextFromDuration(duration);
  } else {
    effectiveInputText = inputText || "";
  }

  const effectiveDuration = inputDuration || duration;
  const suggestions = useMemo(
    () => {
      if (disableSuggestions) return null;
      return buildSuggestions(durationParamsFromDuration(effectiveDuration), effectiveInputText);
    },
    [ disableSuggestions, effectiveDuration, effectiveInputText ]
  );

  return (
    <div className={ `time-input ${ className || "" }` }>
      <div className="text-input-container">
        <TextInputWithSuggestions
          disabled={ disabled }
          invalid={ !(duration && duration.includes("T")) }
          onBlur={ onBlur }
          onFocus={ onFocus }
          onKeyDown={ onKeyDown }
          selectSuggestion={ selectSuggestion }
          setValue={ setValue }
          suggestions={ suggestions }
          value={ effectiveInputText }
        />
      </div>
    </div>
  );
}

TimeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableSuggestions: PropTypes.bool,
  duration: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  setDuration: PropTypes.func.isRequired,
  // By default, setDuration will only be called when the user is considered "done" editing, e.g. by blurring the
  // input or pressing enter. Set this to true to have setDuration called on any change to the input text.
  setDurationOnChange: PropTypes.bool,
};

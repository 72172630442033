import { closeHistory } from "prosemirror-history"
import { Fragment } from "prosemirror-model"

// --------------------------------------------------------------------------
// This matches the node ordering (depth-first) of the `NOTE_ACTION_TYPE.UPDATE_IMAGE` action
function processImageNodes(node, imageNodesBySrc, pos, updater) {
  const { attrs, type } = node;

  if (type.name === "image") {
    const { src } = attrs;
    if (src) {
      let index;
      if (src in imageNodesBySrc) {
        index = imageNodesBySrc[src].length;
        imageNodesBySrc[src].push(node);
      } else {
        index = 0;
        imageNodesBySrc[src] = [ node ];
      }

      if (updater(node, pos, index)) return true;
    }
  }

  // Account for node opening position
  if (!node.isInline) pos += 1;

  let updated = false;
  node.forEach((childNode, childOffset) => {
    if (updated) return;

    if (processImageNodes(childNode, imageNodesBySrc, pos + childOffset, updater)) {
      updated = true;
    }
  });

  return updated;
}

// --------------------------------------------------------------------------
// In-editor version of `NOTE_ACTION_TYPE.UPDATE_IMAGE` note content action
export default function buildUpdateImage({ src, index }, updates) {
  if (!index) index = 0;

  return function(state, dispatch) {
    const { doc, schema } = state;

    const imageNodesBySrc = {};
    return processImageNodes(doc, imageNodesBySrc, 0, (imageNode, nodePos, imageNodeIndex) => {
      const { attrs } = imageNode;
      if (!attrs || attrs.src !== src || imageNodeIndex !== index) return false;

      if (dispatch) {
        const { content: newContent, ...attrUpdates } = updates;

        const transaction = closeHistory(state.tr);

        if (Object.keys(attrUpdates).length > 0) {
          transaction.setNodeMarkup(nodePos - 1, null, { ...imageNode.attrs, ...attrUpdates });
        }

        if (typeof(newContent) !== "undefined") {
          if (newContent) {
            const fragment = Fragment.fromJSON(schema, newContent);
            transaction.replaceWith(nodePos, nodePos + imageNode.nodeSize, fragment);
          } else {
            transaction.delete(nodePos, nodePos + imageNode.nodeSize);
          }
        }

        dispatch(transaction);
      }

      return true;
    });
  }
}

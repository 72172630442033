import { GapCursor } from "prosemirror-gapcursor"

import { ARROW } from "lib/ample-editor/lib/selection-util"
import { nonHiddenPosAfter } from "lib/ample-editor/plugins/collapsible-nodes-plugin"

// --------------------------------------------------------------------------
export function buildInsertImageText(nodePos) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const node = doc.nodeAt(nodePos);
    if (!node || node.type !== schema.nodes.image) return false;

    const { attrs: { text } } = node;
    if (!text) return false;

    if (dispatch) {
      const nodes = text.split("\n").map(line => {
        return schema.nodes.paragraph.createAndFill(null, [ schema.text(line) ]);
      });

      const insertPos = doc.resolve(nodePos).after();
      dispatch(state.tr.insert(insertPos, nodes));
    }

    return true;
  }
}

// --------------------------------------------------------------------------
function buildMaybeMoveCursorInFrontOfImage(arrowKey) {
  return function(state, dispatch) {
    const { schema: { nodes }, selection: { $head }, tr, tr: { doc } } = state;

    // Which types of elements merit placing a GapCursor between them and an image that follows?
    const validPrecedingElements = [
      nodes.blockquote,
      nodes.bulletListItem,
      nodes.checkListItem,
      nodes.hard_break,
      nodes.numberListItem,
    ];

    let $leftOfImage;
    if (arrowKey === ARROW.RIGHT) {
      let $afterNodeBeforeImage;
      if ($head.nodeAfter && validPrecedingElements.includes($head.nodeAfter.type)) {
        $afterNodeBeforeImage = doc.resolve($head.pos + $head.nodeAfter.nodeSize);
      } else if ($head.parent && $head.parent.content.size === $head.parentOffset && $head.pos < doc.content.size) {
        const nextPos = nonHiddenPosAfter($head.pos + 1, state, true);
        $afterNodeBeforeImage = doc.resolve(nextPos);
      }

      $leftOfImage = ($afterNodeBeforeImage && $afterNodeBeforeImage.nodeAfter && $afterNodeBeforeImage.nodeAfter.type === nodes.image
          ? $afterNodeBeforeImage
          : null
      );
    } else if (arrowKey === ARROW.LEFT && $head.parentOffset && $head.nodeBefore && $head.nodeBefore.type === nodes.image) {
      $leftOfImage = doc.resolve($head.pos - $head.nodeBefore.nodeSize);
    }

    if ($leftOfImage &&
      ($leftOfImage.parentOffset === 0 || ($leftOfImage.nodeBefore && validPrecedingElements.includes($leftOfImage.nodeBefore.type))) &&
      $leftOfImage.nodeAfter.type === nodes.image) {
      const selection = new GapCursor($leftOfImage);
      tr.setSelection(selection);
      if (dispatch) dispatch(tr);
      return true;
    }

    return false;
  }
}

// --------------------------------------------------------------------------
// By default browser doesn't allow cursor to be placed in front of an image at beginning of line. This works
// around the browser limitation by using a GapCursor to let the cursor be placed before a line-starting or node-starting image
// It also allows a cursor to move right past an image at the end of a note
export function buildMoveCursorAroundImage(arrowKey) {
  const maybeMoveCursorInFrontOfImage = buildMaybeMoveCursorInFrontOfImage(arrowKey);

  return function(state, dispatch) {
    const { selection: { $anchor, $head } } = state;
    if (!$head || !$anchor || $head.pos !== $anchor.pos) return false;

    if (maybeMoveCursorInFrontOfImage(state, dispatch)) {
      return true;
    } else if (arrowKey === ARROW.RIGHT) {
      return maybeMoveCursorAfterImage(state, dispatch);
    }

    return false;
  }
}

// --------------------------------------------------------------------------
// If img is at end of note, we will place a gapcursor after image in the event of right arrow
function maybeMoveCursorAfterImage(state, dispatch) {
  const { schema: { nodes }, selection: { $head }, tr, tr: { doc } } = state;

  if ($head.nodeAfter && $head.nodeAfter.type === nodes.image) {
    const nextPos = $head.pos + $head.nodeAfter.nodeSize;
    if (nextPos === doc.content.size - 1) {
      const $afterImage = doc.resolve(nextPos);
      const selection = new GapCursor($afterImage);
      tr.setSelection(selection);
      if (dispatch) dispatch(tr);
      return true;
    }
  }

  return false;
}

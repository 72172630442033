import PropTypes from "prop-types"
import React from "react"

// --------------------------------------------------------------------------
export default class PriorityAdjustPopup extends React.PureComponent {
  static propTypes = {
    setPriority: PropTypes.func.isRequired,
  };

  // --------------------------------------------------------------------------
  render() {
    return (
      <div className="task-adjust-popup">
        <div className="heading">Set priority</div>
        <div className="options">
          <div className="option left-aligned" onClick={ this._setPriority.bind(this, "UI") }>
            <i className="material-icons priority-icon">star</i>
            Urgent & Important
          </div>
          <div className="option left-aligned" onClick={ this._setPriority.bind(this, "U") }>
            <i className="material-icons priority-icon">star_half</i>
            Urgent
          </div>
          <div className="option left-aligned" onClick={ this._setPriority.bind(this, "I") }>
            <i className="material-icons priority-icon">star_border</i>
            Important
          </div>
          <div className="option left-aligned" onClick={ this._setPriority.bind(this, null) }>
            <i className="material-icons priority-icon none">star_border</i>
            None
          </div>
        </div>
      </div>
    );
  }

  // --------------------------------------------------------------------------
  _setPriority = priority => {
    this.props.setPriority(priority);
  };
}

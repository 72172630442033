// --------------------------------------------------------------------------
// Various helper functions for working with the underlying data formats used for Task Detail properties
// --------------------------------------------------------------------------
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInDays,
  differenceInSeconds,
  endOfDay,
  format,
  isAfter,
  isBefore,
  isEqual as areEqualDates,
  isSameDay,
  isToday,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
} from "date-fns"
import {
  parse as parseDuration,
  parse as parseISO8601Duration,
  toSeconds as secondsFromDurationParams,
} from "iso8601-duration"
import { invert, last, mapValues, omit, padStart, pick, pickBy } from "lodash"
import { RRule } from "rrule"

import { DEFAULT_ATTRIBUTES_BY_NODE_NAME } from "lib/ample-util/default-node-attributes"

// --------------------------------------------------------------------------
export const BLANK_TASK_CONTENT = [ { type: "paragraph" } ];
const DUE_IN_ZERO_DAYS_POINTS = 10;
const DUE_IN_ONE_DAY_POINTS = 2;

// --------------------------------------------------------------------------
const FLAGS_MAPPING = {
  I: "important",
  U: "urgent",
};
const INVERSE_FLAGS_MAPPING = invert(FLAGS_MAPPING);

// --------------------------------------------------------------------------
// These are the attributes that check-list-items have to make them "tasks", beyond the attributes they
// share with other list item types.
export const TASK_ATTRIBUTES = [
  "createdAt",
  "completedAt",
  "crossedOutAt",
  "deadline",
  "dismissedAt",
  "due",
  "dueDayPart",
  "duration",
  "flags",
  "points",
  "pointsUpdatedAt",
  "repeat",
  "startAt",
  "startRule",
];

// --------------------------------------------------------------------------
export const TASK_COMPLETION_MODE = {
  CROSS_OUT: "cross-out",
  DISMISS: "dismiss",
  NORMAL: "normal",
};

// --------------------------------------------------------------------------
export const DATE_TYPE = {
  DUE: "due",
  HIDE_UNTIL: "startAt",
};

// --------------------------------------------------------------------------
// These are defaults that will be populated by the editor, so we can elide a value if it matches one of these in
// cases where we want to reduce the footprint of a task's attributes (re-exported here for convenience and historical
// reasons)
export const TASK_ATTRIBUTE_DEFAULTS = DEFAULT_ATTRIBUTES_BY_NODE_NAME["check_list_item"];

// --------------------------------------------------------------------------
export const DAY_PART = {
  EARLY_MORNING: "E",
  MORNING: "M",
  AFTERNOON: "A",
  NIGHT: "N",
  LATE_NIGHT: "L",

  ANY_TIME: "W",
};

// --------------------------------------------------------------------------
// NOTE: this needs to be in ascending lastHour order for various functions to work correctly
// prioritizeHour is optional, defaulting to halfway through the interval if not set
export const DAY_PARTS = {
  // 12 midnight - 6:59 am
  [DAY_PART.EARLY_MORNING]: { name: "Early morning", lastHour: 6, prioritizeHour: 4 },
  // 7:00 am - 11:59 pm
  [DAY_PART.MORNING]: { name: "Morning", lastHour: 11, prioritizeHour: 9 },
  // 12:00 pm - 4:59 pm
  [DAY_PART.AFTERNOON]: { name: "Afternoon", lastHour: 16, prioritizeHour: 14 },
  // 5:00 pm - 8:59 pm
  [DAY_PART.NIGHT]: { name: "Evening", lastHour: 20, prioritizeHour: 17 },
  // 9:00 pm - 11:59 pm
  [DAY_PART.LATE_NIGHT]: { name: "Late night", lastHour: 23, prioritizeHour: 21 },

  [DAY_PART.ANY_TIME]: { name: "Any time", lastHour: null },
};

// --------------------------------------------------------------------------
export const TASK_COMPLETION_EFFECT_LEVEL_EM = {
  MINIMAL: "minimal",
  NORMAL: "normal",
  DAZZLE: "dazzle",
};

// --------------------------------------------------------------------------
export const DEFAULT_TASK_COMPLETION_EFFECT_LEVEL = TASK_COMPLETION_EFFECT_LEVEL_EM.DAZZLE;

// --------------------------------------------------------------------------
// UUID
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Adds dashes to appropriate locations in a 32 character UUID string
function formatUUID(uuid) {
  return `${ uuid.slice(0, 8) }-${ uuid.slice(8, 12) }-${ uuid.slice(12, 16) }-${ uuid.slice(16, 20) }-${ uuid.slice(20, 32) }`;
}

// --------------------------------------------------------------------------
// When a task repeats, we want to be able to determine if the next or previous instance are still in the note, mainly
// to handle merges where the task may have been completed on multiple clients. We want these derived UUIDs to be
// reversible, so generating a new random UUID with the current UUID as a PRNG seed isn't ideal (nor is it very easy
// across all the platforms we support, and the `uuid` library's `random` parameter is used verbatim, instead of being
// used as a  seed for their RNG), so we'll use the following scheme:
//
// UUIDs are 16 bytes of hexadecimal-encoded characters (32 characters, sans dashes)
// For each derivation:
//  1. Increment the last octet (the last byte of the 16 bytes of underlying data) by one, wrapping around to zero when
//     incrementing would overflow (256).
//  2. Rotate the UUID two characters right by moving the last octet to the beginning of the UUID.
//
// Item 1 gives us a larger set of unique values before a UUID will repeat. Instead of repeating after 16 derivations,
// as rotation alone would, it will repeat after 4096 derivations (16 * 256).
//
// Item 2 could be alternatively rotate by one character (4 bits), but doing so makes it very hard to observe the
// relation of UUIDs when looking at raw document data, which is a common enough occurrence when debugging merge issues
// (the entire point of this derivation) that it's worthwhile to reduce the number of derivations before a repeat by
// half.
export function derivedUUIDFromUUID(uuid) {
  let derivedUUID = uuid.replace(/-/g, "");

  // In test, uuids may not be actual UUIDs, so we'll make sure we have enough characters
  derivedUUID = padStart(derivedUUID, 32, "0");

  // Increment the last octet, so we don't repeat after 16 derivations, as rotation alone would
  let lastPair = derivedUUID.slice(30, 32);

  const lastOctet = parseInt(lastPair, 16);
  if (!isNaN(lastOctet)) {
    lastPair = padStart((lastOctet < 255 ? lastOctet + 1 : 0).toString(16), 2, "0");
  }

  // Rotate the UUID two (hexadecimal) characters right
  derivedUUID = lastPair + derivedUUID.slice(0, 30);

  return formatUUID(derivedUUID);
}

// --------------------------------------------------------------------------
export function uuidFromDerivedUUID(derivedUUID) {
  let uuid = derivedUUID.replace(/-/g, "");

  // In test, uuids may not be actual UUIDs, so we'll make sure we have enough characters
  uuid = padStart(uuid, 32, "0");

  // Decrement the first octet
  let firstPair = uuid.slice(0, 2);

  const firstOctet = parseInt(firstPair, 16);
  if (!isNaN(firstOctet)) {
    firstPair = padStart((firstOctet > 0 ? firstOctet - 1 : 255).toString(16), 2, "0");
  }

  // Rotate the UUID two (hexadecimal) characters left
  uuid = uuid.slice(2, 32) + firstPair;

  return formatUUID(uuid);
}

// --------------------------------------------------------------------------
// Points
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function calculateTaskValue(task) {
  const { dismissedAt, points: dailyPoints, due, duration, flags: flagsString } = task;

  let points = 1 + (dailyPoints || 0);

  const flags = flagsObjectFromFlagsString(flagsString);
  if (flags.important) points += 0.6;
  if (flags.urgent) points += 3;

  const minutes = minutesFromDuration(duration);
  if (minutes !== null) {
    switch (Math.min(3, Math.floor(minutes / 15))) {
      case 0: // 0 -14 mins
      case 1: // 15 - 29 mins
        points += 2;
        break;

      case 2: // 30 - 59 minutes
        points += 1;
        break;

      case 3: // 60 - 89 minutes
        points += 0.5;
        break;

      default:
        break;
    }
  }

  // 10 points if marked as due today
  // 2 points if marked as due tomorrow
  if (due) {
    const doneAt = doneAtFromTask(task);
    const referenceDate = doneAt ? new Date(doneAt * 1000) : Date.now();
    const daysUntilDue = differenceInCalendarDays(new Date(due * 1000), referenceDate);
    if (daysUntilDue === 0) points += DUE_IN_ZERO_DAYS_POINTS;
    else if (daysUntilDue === 1) points += DUE_IN_ONE_DAY_POINTS;
  }

  return dismissedAt ? points / 2 : points;
}

// --------------------------------------------------------------------------
// The number of extra points - beyond those that can be calculated in calculateTaskValue - that should be added to
// a task item each day the note it is in is opened/active/edited.
export function dailyTaskValue({ createdAt, due, duration, flags: flagsString, pointsUpdatedAt }) {
  // 0.1 points for existing
  let points = 0.1;

  // 0.1 if any time estimate set
  const minutes = minutesFromDuration(duration);
  if (minutes !== null) {
    points += 0.1;

    // 0.1 if the time estimate is 30 min OR
    // 0.2 if the time estimate is 15 min
    if (minutes < 16) {
      points += 0.2;
    } else if (minutes < 31) {
      points += 0.1;
    }
  }

  const flags = flagsObjectFromFlagsString(flagsString);
  // 0.3 points if marked important
  if (flags.important) points += 0.3;
  // 1 point if marked urgent
  if (flags.urgent) points += 1;

  // These points are added so they persist once the due date has passed
  // 10 points if the due _day_ (the day of the due date, not the due date itself) has passed since the last point accumulation
  if (due) {
    const today = Math.floor(startOfDay(Date.now()).getTime() / 1000);
    const lastAccumulatedAt = pointsUpdatedAt || createdAt;

    const dueDay = endOfDay(due * 1000);
    const dueDayCutoff = Math.floor(dueDay.getTime() / 1000);
    if (lastAccumulatedAt < dueDayCutoff && today > dueDayCutoff) {
      points += DUE_IN_ZERO_DAYS_POINTS;
    }
  }

  return points;
}

// --------------------------------------------------------------------------
// Maps the task value to four color buckets
export function bucketFromTaskValue(value) {
  if (!value || value < 2) return 0;
  if (value < 5) return 1;
  if (value < 10) return 2;

  return 3;
}

// --------------------------------------------------------------------------
// Repeat
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export const REPEAT_TYPE = {
  NONE: "none",
  FIXED: "fixed",
  RELATIVE: "complete",
};

// --------------------------------------------------------------------------
function isEmptyRepeat(repeat) {
  return !repeat || repeat === EMPTY_RELATIVE_REPEAT || repeat === EMPTY_RRULE;
}

// --------------------------------------------------------------------------
export function repeatTypeFromRepeat(repeat, { allowEmptyRepeat = true } = {}) {
  if (repeat === null) return REPEAT_TYPE.NONE;

  if (!allowEmptyRepeat && isEmptyRepeat(repeat)) return REPEAT_TYPE.NONE;

  if (repeatIsRRULE(repeat)) {
    return REPEAT_TYPE.FIXED;
  } else {
    return REPEAT_TYPE.RELATIVE;
  }
}

// --------------------------------------------------------------------------
// Calculates the next due date _after_ the given due date, relative to _now_
export function nextDueFromTask({ due, repeat, startAt, startRule }) {
  if (!repeat) return null;

  if (repeatIsRRULE(repeat)) {
    return nextInstanceFromRRule(repeat, { due, startAt, startRule });
  } else {
    // Passing null so the next instance is from now
    return nextInstanceFromDuration(repeat, null);
  }
}

// --------------------------------------------------------------------------
export function nextInstanceFromTask(task) {
  const { attrs } = task;

  const nextDue = nextDueFromTask(attrs);
  if (nextDue === null) return null;

  const { repeat, startRule, uuid } = attrs;

  return {
    ...task,

    attrs: omit({
      ...attrs,
      completedAt: null,
      createdAt: doneAtFromTask(attrs) || Math.floor(Date.now() / 1000),
      crossedOutAt: null,
      dismissedAt: null,
      due: nextDue,
      startAt: startAtFromStartRule(startRule, nextDue, repeat),
      // We want the uuid to be derived from the existing uuid, so this action can happen on multiple different
      // clients deterministically
      uuid: derivedUUIDFromUUID(uuid),
    }, [ "points", "pointsUpdatedAt" ]),
  };
}

// --------------------------------------------------------------------------
// Returns the changes that should be made to the task attributes when changing
// the `repeat` attribute to the given value. This allows for changes to other
// attributes to better handle the user's intent.
export function changesFromRepeat({ createdAt, due, startAt, startRule, repeat: repeatWas }, repeat) {
  const changes = { repeat };
  if (!repeat || repeat.length === 0) return changes;

  // If this is already "due 3 days after complete" and a specific due date is set, we want to let the user change
  // to "due 3 weeks after complete" and update the due date.
  const repeatTypeWas = repeatTypeFromRepeat(repeatWas, { allowEmptyRepeat: false });
  const repeatType = repeatTypeFromRepeat(repeat);
  if (repeatType === REPEAT_TYPE.RELATIVE) {
    if (createdAt && due && repeatTypeWas === REPEAT_TYPE.RELATIVE) {
      changes.due = nextInstanceFromDuration(repeat, createdAt);

      // Adjust the next startAt to match based on the startRule, while we're at it
      if (changes.due && startAt && startRule) {
        const newStartAt = startAtFromStartRule(startRule, changes.due, repeat);
        if (newStartAt) changes.startAt = newStartAt;
      }
    }
    if (!startRule) changes.startRule = "PT0H";
  } else if (repeatType === REPEAT_TYPE.FIXED) {
    const anchor = due ? new Date(due * 1000) : new Date();
    const repeatRule = repeatRuleFromRRULE(repeat, anchor, _dtstart => anchor);
    if (repeatRule) {
      if (isEmptyRepeat(repeatWas) && !startRule) changes.startRule = "PT0H";

      if (repeatTypeWas !== REPEAT_TYPE.FIXED) {
        // Round-tripping this so we can have the anchor set based on the due date
        changes.repeat = rruleFromRepeatRule(repeatRule);
      } else if (due && last((repeatWas || "").split("\n")) === last(repeat.split("\n"))) {
        // We're not changing the rule itself, but the anchor, which likely means just the time of day is being
        // changed - in which case we want to update the next instance to match the new time of day
        const deltaSeconds = differenceInSeconds(
          repeatRuleFromRRULE(repeat).getDTSTART(),
          repeatRuleFromRRULE(repeatWas).getDTSTART(),
        );
        changes.due = Math.floor(addSeconds(anchor, deltaSeconds).getTime() / 1000);
      } else {
        // If we're changing from one fixed repeat rule to another, it's likely the due day is based on the
        // previous rule and we want to update it to match the new rule
        const repeatRuleWas = repeatRuleFromRRULE(repeatWas, anchor, _dtstart => anchor);
        if (repeatRuleWas && due && areEqualDates(repeatRuleWas.after(anchor, true), anchor)) {
          // The previous due date may be after the next instance for the new repeat rule, so we need to
          // remove the anchor to get the next instance
          const dueDate = repeatRuleFromRRULE(repeat).after(new Date(), true);
          // eslint-disable-next-line max-depth
          if (dueDate) changes.due = Math.floor(dueDate.getTime() / 1000);
        }
      }

      if (!due && !changes.due) {
        const dueDate = repeatRule.after(anchor, false);
        changes.due = dueDate ? Math.floor(dueDate.getTime() / 1000) : null;
      }
    } else {
      changes.repeat = EMPTY_RRULE;
    }
  }

  return changes;
}

// --------------------------------------------------------------------------
function isLessThanDailyRepeatRule(repeatRule) {
  if (!repeatRule) return false;

  const { options: { freq } } = repeatRule
  return freq === RRule.HOURLY || freq === RRule.MINUTELY || freq === RRule.SECONDLY;
}

// --------------------------------------------------------------------------
export function isLessThanDailyRepeat(repeat) {
  if (repeatIsRRULE(repeat)) {
    return isLessThanDailyRepeatRule(repeatRuleFromRRULE(repeat));
  } else {
    return isLessThanDailyDuration(repeat);
  }
}

// --------------------------------------------------------------------------
// Repeat: RRULEs ("every 2 weeks")
// --------------------------------------------------------------------------
const RRULE_PREFIX = "RRULE:";
const DTSTART_PREFIX = "DTSTART:";

export const EMPTY_RELATIVE_REPEAT = "";
export const EMPTY_RRULE = RRULE_PREFIX;

// --------------------------------------------------------------------------
function repeatIsRRULE(repeat) {
  if (!repeat) return false;

  if (repeat.substr(0, RRULE_PREFIX.length) === RRULE_PREFIX) return true;

  if (repeat.substr(0, DTSTART_PREFIX.length) === DTSTART_PREFIX) {
    return repeat.indexOf(`\n${ RRULE_PREFIX }`) > -1;
  }

  return false;
}

// --------------------------------------------------------------------------
// Mainly intended to allow for (estimated) conversion from a relative repeat duration to a fixed repeating rule, so
// (estimated) future instances of a relative repeating task can be generated.
export function repeatRuleFromDuration(duration, startDate) {
  const durationParams = durationParamsFromDuration(duration);
  if (!durationParams) return null;

  const { hours, minutes, seconds } = durationParams;
  const dtstart = setSeconds(setMinutes(setHours(startDate, hours || 0), minutes || 0), seconds || 0);

  const { days, weeks, months, years } = durationParams;

  if (weeks > 0) {
    // Weeks in durations are exclusive of other units
    return new RRule({ dtstart, freq: RRule.WEEKLY, interval: weeks });
  } else if (days > 0) {
    // This is a _very_ basic approximation
    const totalDays = days + months * 30 + years * 365;
    return new RRule({ dtstart, freq: RRule.DAILY, interval: totalDays });
  } else if (months > 0) {
    const totalMonths = months + years * 12;
    return new RRule({ dtstart, freq: RRule.MONTHLY, interval: totalMonths });
  } else if (years > 0) {
    return new RRule({ dtstart, freq: RRule.YEARLY, interval: years });
  }

  return null;
}

// --------------------------------------------------------------------------
export function repeatRuleFromRRULE(rrule, fallbackStartDate = null, setDTSTARTCallback = null) {
  if (isEmptyRepeat(rrule)) return null;

  try {
    const options = RRule.parseString(rrule);
    if (options === null) return null;

    let adjustDTSTART = false;

    if (options.dtstart) {
      // rrule.js deals in "floating" DTSTART values, in that they don't intend them to be translated between timezones
      // (the caller is expected to handle that), but due to the way it's implemented in rrule.js (and how JS works),
      // translating the DTSTART=... string to a date will result in the current timezone offset being applied to the
      // string date (i.e. as if it were an actual UTC date being translated to a local date). Since this _isn't_ an
      // actual UTC date, we need to undo the application of the timezone, but we must do this _after_ we've produced
      // a new date, or we'll end up getting dates that are on incorrect days (due to the interaction between dtstart
      // and weekly/specific-day rules in rrule.js).
      adjustDTSTART = true;
    } else if (fallbackStartDate !== null) {
      options.dtstart = fallbackStartDate;
    }

    if (setDTSTARTCallback) {
      // This is a bit complicated, as the caller might want to make adjustments to the parsed dtstart, but it's not
      // necessarily in the frame of reference they might expect, so we'll adjust it back to being a time in their
      // zone so they can make changes
      options.dtstart = setDTSTARTCallback(
        adjustDTSTART ? addMinutes(options.dtstart, options.dtstart.getTimezoneOffset()) : options.dtstart
      );
      adjustDTSTART = false;
    }

    const rule = new RRule(options);

    // We're letting rrule.js offset the input dtstart by the current timezone offset, but we actually want our inputs
    // to be floating times, which we need to adjust to _after_ bringing out the next date, so we'll add some helper
    // functions to deal with the `options.dtstart` that may or may not need adjustment.

    if (adjustDTSTART) {
      // Since we've adjusted the input time, the output time will be the time in UTC, so we need to adjust it back to
      // the current timezone - note that we also need to shift any given reference dates to the correct "floating"
      // frame of reference so this works correctly.

      rule.after = (afterDate, inclusive = false) => {
        const adjustedAfterDate = subMinutes(afterDate, afterDate.getTimezoneOffset());
        const result = RRule.prototype.after.call(rule, adjustedAfterDate, inclusive);
        return result ? addMinutes(result, result.getTimezoneOffset()) : result;
      };

      rule.between = (afterDate, beforeDate, inclusive = false, iterator = null) => {
        const adjustedAfterDate = subMinutes(afterDate, afterDate.getTimezoneOffset());
        const adjustedBeforeDate = subMinutes(beforeDate, beforeDate.getTimezoneOffset());

        const instances = [];
        RRule.prototype.between.call(rule, adjustedAfterDate, adjustedBeforeDate, inclusive, (date, index) => {
          const adjustedDate = addMinutes(date, date.getTimezoneOffset());
          instances.push(adjustedDate);
          return iterator ? iterator(adjustedDate, index) : true;
        });
        return instances;
      }

      rule.getDTSTART = () => addMinutes(rule.options.dtstart, rule.options.dtstart.getTimezoneOffset());
    } else {
      rule.getDTSTART = () => rule.options.dtstart;
    }

    rule.durationFromDTSTARTTime = () => {
      const dtstart = rule.getDTSTART();
      return `PT${ dtstart.getHours() }H${ dtstart.getMinutes() }M`;
    };

    return rule;
  } catch (_error) {
    // parseString can raise: Unknown RRULE property 'BYNWEEKDAY'
    // RRule constructor can raise: Malformed RRULE string (e.g. lower case weekday "BYWEEKDAY=mo")
    return null;
  }
}

// --------------------------------------------------------------------------
export function rruleFromRepeatRule(repeatRule) {
  if (repeatRule === null) return "";

  const rrule = repeatRule.toString();

  // rrule.js deals in "floating" times (they use the "Z" UTC indicator, but are intended to be interpreted as whatever
  // zone you happen to be in, with no translation), but uses getTime when converting it to a string, which results
  // in the time being translated to UTC from the current local timezone (in Chrome, at least). We'll skip the time
  // zone offsetting and drop the "Z" to help ourselves recognize a true floating DTSTART in the future.
  return rrule.replace(/^(DTSTART:)\d{8}T\d{6}Z(\n)/, (match, prefix, suffix) => {
    const dtstart = repeatRule.getDTSTART ? repeatRule.getDTSTART() : repeatRule.options.dtstart;
    return prefix + format(dtstart, "yyyyLLdd'T'HHmmss") + suffix;
  });
}

// --------------------------------------------------------------------------
// Adjusts the time of a rrule by adjusting the DTSTART setting
export function adjustRRuleTime(rrule, nextInstanceAt, duration) {
  const { hours, minutes } = durationParamsFromDuration(duration) || {};

  const nextInstance = nextInstanceAt ? new Date(nextInstanceAt * 1000) : null;
  const repeatRule = repeatRuleFromRRULE(rrule, nextInstance, dtstart => {
    // These can be undefined if the time text isn't valid
    return setMinutes(setHours(dtstart, hours || 0), minutes || 0);
  });

  return rruleFromRepeatRule(repeatRule);
}

// --------------------------------------------------------------------------
// The anchor controls where the repeating interval starts, so "every 3 days" will repeat 3 days after the given anchor,
// allowing the due date for a specific instance to be adjusted away from the schedule without affecting the overall
// repeat rule
export function setRRuleAnchor(rrule, nextInstanceAt, anchor) {
  const nextInstance = nextInstanceAt ? new Date(nextInstanceAt * 1000) : null;
  const repeatRule = repeatRuleFromRRULE(rrule, nextInstance, _dtstart => anchor);
  return rruleFromRepeatRule(repeatRule);
}

// --------------------------------------------------------------------------
// Repeat: durations ("1 week after complete", "3 days after complete, at 5 pm")
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function adjustDurationTime(duration, newTimeDuration) {
  // Less than daily means it's something like "2 hours after complete", in which case we can't adjust the time
  // without affecting the actual repeat rule
  if (isLessThanDailyDuration(duration)) return duration;

  const durationParams = durationParamsFromDuration(duration);
  if (!durationParams) return null;

  const newTimeDurationParams = durationParamsFromDuration(newTimeDuration);
  if (newTimeDurationParams) {
    durationParams.hours = newTimeDurationParams.hours;
    durationParams.minutes = newTimeDurationParams.minutes;
    durationParams.seconds = newTimeDurationParams.seconds;
  }

  return isoDurationFromDurationParams(durationParams);
}

// --------------------------------------------------------------------------
export function advanceDateForDurationParams(durationParams, date) {
  if (!durationParams) return date;

  const {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  } = durationParams;

  if (years) date = addYears(date, years);
  if (months) date = addMonths(date, months);
  if (weeks) date = addWeeks(date, weeks);
  if (days) date = addDays(date, days);
  if (hours) date = addHours(date, hours);
  if (minutes) date = addMinutes(date, minutes);
  if (seconds) date = addSeconds(date, seconds);

  return date;
}

// --------------------------------------------------------------------------
function regressDateForDurationParams(durationParams, date) {
  if (!durationParams) return date;

  const {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  } = durationParams;

  if (years) date = subYears(date, years);
  if (months) date = subMonths(date, months);
  if (weeks) date = subWeeks(date, weeks);
  if (days) date = subDays(date, days);
  if (hours) date = subHours(date, hours);
  if (minutes) date = subMinutes(date, minutes);
  if (seconds) date = subSeconds(date, seconds);

  return date;
}

// --------------------------------------------------------------------------
export function durationParamsFromDuration(duration) {
  let params;

  try {
    params = parseISO8601Duration(duration);
  } catch (_error) {
    // Do nothing
  }

  if (!params) return null;

  // iso8601-duration supports the "weeks" designator (e.g. "P[n]W") but will drop any time associated with it
  // when parsing, while we want to handle that.
  if (params.weeks && duration.includes("T")) {
    let paramsWithoutWeeks;

    try {
      paramsWithoutWeeks = parseISO8601Duration(duration.replace(`${ params.weeks }W`, ""));
    } catch (_error) {
      // Do nothing
    }

    if (paramsWithoutWeeks) {
      // We only care about the time
      params.hours = paramsWithoutWeeks.hours;
      params.minutes = paramsWithoutWeeks.minutes;
      params.seconds = paramsWithoutWeeks.seconds;
    }
  }

  return params;
}

// --------------------------------------------------------------------------
export function dateTextFromDuration(duration) {
  const durationParams = durationParamsFromDuration(duration);
  if (!durationParams) return null;

  const components = [];

  [ "days", "weeks", "months", "years" ].forEach(interval => {
    const value = durationParams[interval];
    if (!value) return;

    components.push(`${ value } ${ value === 1 ? interval.substr(0, interval.length - 1) : interval }`)
  });

  if (components.length === 0) {
    [ "seconds", "minutes", "hours" ].forEach(interval => {
      const value = durationParams[interval];
      if (!value) return;

      components.push(`${ value } ${ value === 1 ? interval.substr(0, interval.length - 1) : interval }`)
    });
  }

  return components.join(", ");
}

// --------------------------------------------------------------------------
export function shortTimeTextFromDuration(duration) {
  const timeText = timeTextFromDuration(duration);
  return timeText.replace(/:00\s/, " ");
}

// --------------------------------------------------------------------------
export function timeTextFromDuration(duration) {
  const durationParams = durationParamsFromDuration(duration);
  if (!durationParams) return "";
  if (!duration.includes("T")) return "12:00 am";

  let { hours } = durationParams;
  if (hours > 23) hours = hours % 24;

  const meridiem = hours > 11 ? "pm" : "am";
  if (hours > 12) hours -= 12;
  if (hours === 0) hours = 12;

  let { minutes } = durationParams;
  if (minutes < 10) minutes = "0" + minutes;

  return `${ hours }:${ minutes } ${ meridiem }`;
}

// --------------------------------------------------------------------------
export function nextInstanceFromDuration(duration, anchorTimestamp = null) {
  if (!duration || duration.length === 0) return null;

  const durationParams = durationParamsFromDuration(duration);
  if (!durationParams) return null;

  let anchorDate = anchorTimestamp ? new Date(anchorTimestamp * 1000) : new Date();

  // When repeating at least daily, the time component is from the start of the day
  if ([ "days", "weeks", "months", "years" ].find(interval => durationParams[interval])) {
    anchorDate = startOfDay(anchorDate);
  }

  const nextInstance = advanceDateForDurationParams(durationParams, anchorDate);

  return Math.floor(nextInstance.getTime() / 1000);
}

// --------------------------------------------------------------------------
function nextInstanceFromRRule(repeat, { due, startAt, startRule }) {
  if (isEmptyRepeat(repeat)) return null;

  const dueDate = due ? new Date(due * 1000) : new Date();
  const repeatRule = repeatRuleFromRRULE(repeat, dueDate);
  if (!repeatRule) return null;

  const now = new Date();
  const currentInstanceDate = isAfter(now, dueDate) ? now : dueDate;

  const nextDueDate = repeatRule.after(currentInstanceDate, false);

  // A repeat rule can include a limit to the number of times it can repeat ("every week for 1 time")
  if (!nextDueDate) return null;

  const nextDue = Math.floor(nextDueDate.getTime() / 1000);

  // If a user gets behind on a repeating task, they may hide it to get it out of their list for a while, finally
  // completing it once it un-hides, which may be in the un-hidden period for the next instance. If that happens, it
  // can be confusing to the user to see it show up again immediately. Note that we only trigger this if the user
  // adjusted the hide until to be after the due date, not if they just hid it for a bit.
  if (startAt && startRule && due && startAt > due) {
    const expectedStartAt = startAtFromStartRule(startRule, due, repeat);
    if (expectedStartAt && startAt > expectedStartAt) {
      const nextStartAt = startAtFromStartRule(startRule, nextDue, repeat);
      if (nextStartAt && isAfter(now, new Date(nextStartAt * 1000))) {
        // Note that we're only advancing a single repetition period, at most - this could be a recursive call using
        // `nextDue` as the `due` attribute, but out of abundance of caution (e.g. are there edge cases where that
        // could result in an infinite loop?) we're not doing so as of 9/2019
        const nextNextDueDate = repeatRule.after(nextDueDate, false)
        return nextNextDueDate ? Math.floor(nextNextDueDate.getTime() / 1000) : null;
      }
    }
  }

  return nextDue;
}

// --------------------------------------------------------------------------
export function isLessThanDailyDuration(duration) {
  // i.e. nothing except time (T) components
  return /^PT/.test(duration);
}

// --------------------------------------------------------------------------
// Due date
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Returns the task attribute changes that should be applied when changing to a
// new due date. Note this may make changes to other task properties (e.g. start)
// to provide a better user experience.
export function changesFromDue({ createdAt, due: dueWas, repeat, startAt, startRule }, due) {
  // Note we always want to clear out the fuzzy day part when manually specifying a due date - even if the user is
  // clearing out the due date
  const changes = { due, dueDayPart: null };

  // A startAt value of `0` is used to indicate the intent to set an absolute start time, but we only want to keep
  // absolute start times that have been actually set when switching. We'd also like to re-adjust next snooze
  // instance to be relative to the new due date, based on the startRule
  if (due !== null) {
    if (startAt === 0) {
      changes.startAt = null;
    } else if (startRule) {
      if (dueWas && (!startAt || startAt === startAtFromStartRule(startRule, dueWas, repeat))) {
        const newStartAt = startAtFromStartRule(startRule, due, repeat);
        if (newStartAt) changes.startAt = newStartAt;
      }
    }

    // If a fixed repeat has been set, the user may want to adjust the anchor of the repeat rule - particularly for
    // day-based rules with intervals, like "every 2 months on the 2nd Saturday" - but since we don't expose the
    // anchor itself to the user, we'll treat due date adjustments as anchor adjustments (but only if we're
    // reasonably sure that's likely what the user wants).
    if (dueWas !== null && repeatTypeFromRepeat(repeat) === REPEAT_TYPE.FIXED) {
      const dueDateWas = new Date(dueWas * 1000);
      const dueDate = new Date(due * 1000);

      const nextDue = nextDueFromTask({ due: createdAt, repeat, startRule });
      if (isSameDay(dueDateWas, new Date(nextDue * 1000)) && due !== nextDue && !isSameDay(dueDateWas, dueDate)) {
        let anchorWas = null;
        const repeatRule = repeatRuleFromRRULE(repeat, null, dtstart => {
          anchorWas = dtstart;

          // Retain time of day from anchor
          return setMinutes(setHours(dueDate, anchorWas.getHours()), anchorWas.getMinutes());
        });

        if (isToday(anchorWas) || (isSameDay(anchorWas, dueDateWas) && isBefore(Date.now(), dueDateWas))) {
          changes.repeat = rruleFromRepeatRule(repeatRule);
        }
      }
    }
  } else {
    if (startAt === null) changes.startAt = 0;
  }

  return changes;
}

// --------------------------------------------------------------------------
// Priority
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function iconNameFromPriority(flagsString) {
  const flags = flagsObjectFromFlagsString(flagsString);

  if (flags.important && flags.urgent) {
    return "star";
  } else if (flags.urgent) {
    return "star_half";
  } else if (flags.important) {
    return "star_border";
  }

  return null;
}

// --------------------------------------------------------------------------
export function formatPriority(flagsString) {
  const flags = flagsObjectFromFlagsString(flagsString);

  if (flags.important && flags.urgent) {
    return "Urgent & Important";
  } else if (flags.important) {
    return "Important";
  } else if (flags.urgent) {
    return "Urgent";
  }

  return "No priority";
}

// --------------------------------------------------------------------------
export function flagsObjectFromFlagsString(flagsString) {
  const flagsObject = mapValues(INVERSE_FLAGS_MAPPING, () => false);

  if (flagsString) {
    flagsString = flagsString.toUpperCase();

    for (let i = 0; i < flagsString.length; i++) {
      const flag = flagsString.charAt(i);

      const flagName = FLAGS_MAPPING[flag];
      if (flagName) flagsObject[flagName] = true;
    }
  }

  return flagsObject;
}

// --------------------------------------------------------------------------
export function flagsStringFromFlagsObject(flagsObject) {
  let flagsString = "";

  Object.keys(flagsObject || {}).forEach(flagName => {
    if (flagsObject[flagName]) {
      const flag = INVERSE_FLAGS_MAPPING[flagName];
      if (flag) flagsString += flag;
    }
  });

  return flagsString || null;
}

// --------------------------------------------------------------------------
// Duration
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// Note that we normalize durations of 1 hour and longer to use hours rather than
// just minutes, so we can match external systems (e.g. ample-api's minutes to
// duration conversion) where that is more common.
export function durationFromMinutes(minutes) {
  if (!minutes) return "PT0S"; // This matches ActiveSupport::Duration.build(0).iso8601

  const hours = Math.floor(minutes / 60);
  minutes -= 60 * hours;

  let duration = "PT";
  if (hours) duration += `${ hours }H`;
  if (minutes) duration += `${ minutes }M`;

  return duration;
}

// --------------------------------------------------------------------------
// Note this reflects that we generally don't keep track of the seconds in durations
export function durationFromSeconds(seconds) {
  return durationFromMinutes(Math.floor(seconds / 60));
}

// --------------------------------------------------------------------------
export function durationFromTimestamp(timestamp, referenceDate = null) {
  if (!timestamp) return "";

  referenceDate = startOfDay(referenceDate || new Date());

  const dateWithTime = new Date(timestamp * 1000);
  const date = startOfDay(dateWithTime);

  const days = Math.abs(differenceInDays(date, referenceDate));

  const daysDuration = `P${ days }D`;
  if (areEqualDates(date, dateWithTime)) return daysDuration;

  return `${ daysDuration }T${ dateWithTime.getHours() }H${ dateWithTime.getMinutes() }M`;
}

// --------------------------------------------------------------------------
// Converts a date-fns Duration object to an ISO8601 duration. Unlike date-fns' formatISODuration, this outputs
// a more compact representation, omitting any components that are zero. Note that the Duration object matches the
// params parsed by iso8601-duration's parse function.
export function isoDurationFromDurationParams({ minutes, hours, days, weeks, months, years }) {
  if (minutes && minutes > 59) {
    const hoursFromMinutes = Math.floor(minutes / 60);
    minutes -= 60 * hoursFromMinutes;
    hours = (hours || 0) + hoursFromMinutes
  }

  if (hours && hours > 23) {
    const daysFromHours = Math.floor(hours / 24);
    hours -= 24 * daysFromHours;
    days = (days || 0) + daysFromHours
  }

  let duration = "P";

  if (years) duration += `${ years }Y`;
  if (months) duration += `${ months }M`;
  if (weeks) duration += `${ weeks }W`;
  if (days) duration += `${ days }D`;

  if (hours || minutes) {
    duration += "T";

    if (hours) duration += `${ hours }H`;
    if (minutes) duration += `${ minutes }M`;
  }

  return duration !== "P" ? duration : "PT0S";
}

// --------------------------------------------------------------------------
export function minutesFromDuration(duration) {
  if (!duration) return null;

  let durationParams;
  try {
    durationParams = parseDuration(duration);
  } catch (_error) {
    return null;
  }

  // Note this isn't accurate when durationParams contains intervals of days or larger (in which case it needs an anchor
  // date to accurately apply those), but we're assuming we have a relatively short time period here (and can cap it
  // later if necessary), so it shouldn't matter.
  const durationSeconds = secondsFromDurationParams(durationParams);
  return Math.floor(durationSeconds / 60);
}

// --------------------------------------------------------------------------
export function secondsFromDuration(duration) {
  if (!duration) return null;

  let durationParams;
  try {
    durationParams = parseDuration(duration);
  } catch (_error) {
    return null;
  }

  return secondsFromDurationParams(durationParams);
}

// --------------------------------------------------------------------------
export function timestampFromDuration(duration, referenceDate = null) {
  referenceDate = startOfDay(referenceDate || new Date());

  const durationParams = durationParamsFromDuration(duration);
  const date = advanceDateForDurationParams(durationParams, referenceDate);

  const timestamp = Math.floor(date.getTime() / 1000);

  // If the timezone of the target date is different from the reference date, e.g. due to daylight savings time,
  // the target date will have been shifted to the timezone of the reference date, so we need to adjust to the
  // correct time in the target date's timezone.
  const dateTimezoneOffset = date.getTimezoneOffset();
  const referenceDateTimezoneOffset = referenceDate.getTimezoneOffset();
  const timezoneOffsetDelta = dateTimezoneOffset - referenceDateTimezoneOffset;

  return timestamp + (timezoneOffsetDelta * 60);
}

// --------------------------------------------------------------------------
// startAt/startRule
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function changesFromStartRule({ due, repeat }, startRule) {
  const changes = { startRule };

  if (startRule !== null && startRule.length > 0) {
    if (due) {
      changes.startAt = startAtFromStartRule(startRule, due, repeat);
    } else if (repeatTypeFromRepeat(repeat) !== REPEAT_TYPE.RELATIVE) {
      changes.startAt = timestampFromDuration(startRule);
    }
  } else {
    changes.startAt = null;
  }

  return changes;
}

// --------------------------------------------------------------------------
export function isHiddenFromTask({ due, repeat, startAt, startRule }, referenceDate = null) {
  if (!referenceDate) referenceDate = Date.now();

  if (!startAt) startAt = startAtFromStartRule(startRule, due, repeat);
  if (!startAt) return false;

  return isAfter(startAt * 1000, referenceDate);
}

// --------------------------------------------------------------------------
export function startAtFromStartRule(startRule, due, repeat) {
  if (!startRule || startRule.length === 0) return null;

  const durationParams = durationParamsFromDuration(startRule);
  if (!durationParams) return null;

  let nextInstance;
  if (repeat && isLessThanDailyRepeat(repeat)) {
    // If we're repeating less than daily, consider the startRule to be exactly relative to the due date
    const dueDate = due ? new Date(due * 1000) : new Date();
    nextInstance = regressDateForDurationParams(durationParams, dueDate);
  } else {
    // The repeat either isn't set, or is at least daily, so we interpret any time in the `startRule` to indicate
    // _when_ on the day the instance occurs it should start - i.e. "P2DT2H" would be "3 days before, at 2 pm" instead
    // of "3 days and 2 hours before".

    const dueDate = startOfDay(due ? new Date(due * 1000) : new Date());

    // Rewind to the start of the day specified by the duration
    const dateDurationParams = { ...durationParams, hours: 0, minutes: 0, seconds: 0 };
    nextInstance = startOfDay(regressDateForDurationParams(dateDurationParams, dueDate));

    // Advance to the time of day specified _in_ the duration
    const timeDurationParams = { ...durationParams, years: 0, months: 0, weeks: 0, days: 0 };
    nextInstance = advanceDateForDurationParams(
      timeDurationParams,
      nextInstance
    );
  }

  return Math.floor(nextInstance.getTime() / 1000);
}

// --------------------------------------------------------------------------
// Day parts (morning, evening, etc) / dueDayPart
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function dayPartTextFromDayPart(dayPart) {
  const { name } = DAY_PARTS[dayPart] || { name: "" };
  return name;
}

// --------------------------------------------------------------------------
export function dayPartFromDuration(duration) {
  const durationParams = durationParamsFromDuration(duration);
  if (!durationParams) return null;

  const dayParts = Object.keys(DAY_PARTS);
  for (let i = 0; i < dayParts.length; i++) {
    const dayPart = dayParts[i];

    const { lastHour } = DAY_PARTS[dayPart];
    if (lastHour === null) break;

    if (durationParams.hours <= lastHour) return dayPart;
  }

  return null;
}

// --------------------------------------------------------------------------
export function hourRangeFromDayPart(targetDayPart) {
  let firstHour = 0;

  if (targetDayPart === DAY_PART.ANY_TIME) return [ 0, 23 ];

  const dayParts = Object.keys(DAY_PARTS);
  for (let i = 0; i < dayParts.length; i++) {
    const dayPart = dayParts[i];

    const { lastHour } = DAY_PARTS[dayPart];
    if (lastHour === null) break;

    if (dayPart === targetDayPart) {
      return [ firstHour, lastHour ];
    }

    firstHour = lastHour + 1;
  }

  return null;
}

// --------------------------------------------------------------------------
export function prioritizeHourFromDayPart(dayPart) {
  const { prioritizeHour } = DAY_PARTS[dayPart] || { prioritizeHour: null };
  return prioritizeHour;
}

// --------------------------------------------------------------------------
// Misc task helpers
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
export function checkListItemFromTask(task) {
  const {
    content,
    deleted,
    isScheduledBullet,
    localUUID,
    references,
    remoteUUID,
    ...attrs
  } = task;

  if (isScheduledBullet) {
    const defaultAttributes = DEFAULT_ATTRIBUTES_BY_NODE_NAME["bullet_list_item"];

    const bulletListItemAttrs = {
      scheduledAt: attrs.due || defaultAttributes["scheduledAt"],
      ...pick(attrs, Object.keys(defaultAttributes)),
    };

    return { type: "bullet_list_item", attrs: bulletListItemAttrs, content };
  } else {
    return { type: "check_list_item", attrs, content };
  }
}

// --------------------------------------------------------------------------
// Completed task helpers
// --------------------------------------------------------------------------
// After how many weeks completed tasks are trimmed down to smaller size
export const REMOVE_COMPLETED_TASKS_YEARS = 1;
export const TRIM_COMPLETED_TASKS_WEEKS = 6;

// --------------------------------------------------------------------------
export function checkListItemFromCompletedTask(completedTask) {
  const { checkedAt, crossedOut, dismissed, p, value, ...attrs } = completedTask;

  if (crossedOut) {
    attrs.crossedOutAt = checkedAt;
  } else if (dismissed) {
    attrs.dismissedAt = checkedAt;
  } else {
    attrs.completedAt = checkedAt;
  }

  const content = p ? [ { type: "paragraph", content: p } ] : [ { type: "paragraph" } ];

  return { attrs, content, type: "check_list_item" };
}

// --------------------------------------------------------------------------
export function completedTaskFromCheckListItem(checkListItem) {
  const { attrs: { collapsed, completedAt, crossedOutAt, dismissedAt, ...attrs }, content } = checkListItem;

  const completedTask = {
    // Slim down the storage format by not including default valued attributes - note that we're putting these at the
    // top-level to avoid adding "attrs: { }"
    ...pickBy(attrs, (value, key) => !(key in TASK_ATTRIBUTE_DEFAULTS) || value !== TASK_ATTRIBUTE_DEFAULTS[key]),

    checkedAt: dismissedAt || crossedOutAt || completedAt || Math.floor(Date.now() / 1000),

    // check_list_item's only allow a single child (paragraph-) node, we only care to grab the children of that node
    // to keep the completedTask representation as small as (reasonably) possible)
    p: (content[0] || {}).content,

    // Note that we want to pass the full attrs here, including completedAt/crossedOutAt/dismissedAt
    value: calculateTaskValue(checkListItem.attrs),
  };

  if (crossedOutAt) {
    completedTask.crossedOut = true;
  } else if (dismissedAt) {
    completedTask.dismissed = true;
  }

  return completedTask;
}

// --------------------------------------------------------------------------
export function doneAtFromTask({ completedAt, crossedOutAt, dismissedAt }) {
  return completedAt || crossedOutAt || dismissedAt;
}

// --------------------------------------------------------------------------
export function isDoneFromTask(task) {
  return !!doneAtFromTask(task);
}

// --------------------------------------------------------------------------
export function isOverFromScheduledBullet(task) {
  if (!task.isScheduledBullet || !task.due) return false;

  const { due, duration } = task;

  let endsAt = due;

  const durationMinutes = minutesFromDuration(duration)
  if (durationMinutes) endsAt += durationMinutes * 60;

  return endsAt < Math.floor(Date.now() / 1000);
}

import { Node } from "prosemirror-model"
import { Step, StepResult } from "prosemirror-transform"

import AddHiddenTaskStep from "./add-hidden-task-step"

// --------------------------------------------------------------------------
export default class RemoveHiddenTaskStep extends Step {
  // --------------------------------------------------------------------------
  static fromJSON(schema, json) {
    return new RemoveHiddenTaskStep(schema, json.task)
  }

  // --------------------------------------------------------------------------
  constructor(schema, task) {
    super();

    this.schema = schema;
    this.task = task;
  }

  // --------------------------------------------------------------------------
  apply(doc) {
    const { attrs: { uuid: uuidToRemove } } = this.task;

    if (uuidToRemove) {
      const serializedDoc = doc.toJSON();

      const hiddenTasks = (serializedDoc.attrs.hiddenTasks || []).filter(({ attrs: { uuid } }) => uuid !== uuidToRemove);
      serializedDoc.attrs = { ...serializedDoc.attrs, hiddenTasks };

      doc = Node.fromJSON(this.schema, serializedDoc);
    }

    return StepResult.ok(doc);
  }

  // --------------------------------------------------------------------------
  invert() {
    return new AddHiddenTaskStep(this.schema, this.task);
  }

  // --------------------------------------------------------------------------
  map(_mapping) {
    return this;
  }

  // --------------------------------------------------------------------------
  toJSON() {
    return {
      stepType: "removeHiddenTask",
      task: this.task,
    };
  }
}
Step.jsonID("removeHiddenTask", RemoveHiddenTaskStep);

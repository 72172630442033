export const AXIS = {
  HORIZONTAL: "horiz",
  VERTICAL: "vert",
};
export const CELL_ALIGN = {
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
}
export const CELL_ALIGN_ICON_ORDER = [ CELL_ALIGN.LEFT, CELL_ALIGN.CENTER, CELL_ALIGN.RIGHT ];
export const CELL_MIN_WIDTH = 50;
export const CELL_RESIZE_INACTIVE = -1;
export const CELL_SELECT_INACTIVE = -1;
export const CELL_SELECTION_MENU_CONTAINER_CLASS = "table-selection-menu-container";
export const CELL_SELECTION_DIRECTION = {
  COLUMN: "column",
  ROW: "row",
};
export const CELL_SELECTION_SUB_MENU = {
  ALIGN: "align",
  BACKGROUND_COLOR: "background-color",
  BORDER: "border",
  CELL_SELECTION: "cell-selection",
  EXPAND_SELECTION: "expand-selection",
  TEXT_COLOR: "text-color",
};
export const CHARACTER_WIDTH_DEFAULT = 8; // https://public.amplenote.com/YvMgnfE3adzXwhYzciidhCjp
export const CLEAR_BORDER_COMMAND_NAME = "clearBorder";
export const COLUMN_WIDTH_BLOCK_DEFAULT_PX = 300;
// Make COLUMN_WIDTH_ESTIMATE_EXTRA_PX too small and content will be forced to wrap when we make a bad estimate,
// which figures to be semi-frequent in v1. Make it too large and there will be visible space to the right of the content
export const COLUMN_WIDTH_BOLD_MULTIPLIER = 1.1;
export const COLUMN_WIDTH_ESTIMATE_EXTRA_PX = 15;
// Empirically derived by WBH taking screenshots of text and measuring characters vs. observed width
export const COLUMN_WIDTH_HEADING_MULTIPLIER = {
  1: 1.6,
  2: 1.5,
  3: 1.3,
}
export const COLUMN_WIDTH_LINK_ICON_PX = 22;
export const COLUMN_WIDTH_NODE_PADDING_PX = 16; // Intrinsic 8px of padding on either side of a node
export const COLUMN_WIDTH_MAX_ESTIMATE_PX = 500;
export const COLUMN_WIDTH_INDENT_OFFSET = 20;
export const LARGEST_SUBMENU_VERTICAL_PX = 370; // Derived empirically in Dec 2022
export const MIXED_TABLE_SELECTION_VALUE = -1;
export const META_TABLE_SELECTION_DISABLE = -1;
export const MIN_CELL_DEPTH = 2;
export const MOUSE_MOVE_DEBOUNCE_INTERVAL = 50;
export const SORT_APPLIED = {
  ASC: "asc",
  DESC: "desc",
};
export const TABLE_SELECTION_CONTAINER_CLASS = "table-selection-menu-container";
export const TABLE_DEPTH = 1;
export const WIDTH_OF_RESIZE_HANDLE = 5;

import ReactDOM from "react-dom"
import React from "react"

import PdfToolbar from "lib/ample-editor/components/attachment/pdf-toolbar"
import { isAndroid } from "lib/ample-editor/lib/client-info"
import createPdfJsIframe from "lib/ample-editor/lib/create-pdf-js-iframe"
import { hasModifierKey, hasModKeyOnly } from "lib/ample-editor/lib/event-util"
import { isLocalFileURL } from "lib/ample-editor/plugins/file-plugin"

// --------------------------------------------------------------------------
// https://pictogrammers.com/library/mdi/icon/paperclip/
const DEFAULT_ICON_PATH = "M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z";

// https://pictogrammers.com/library/mdi/icon/music-box-outline/
const ICON_PATH_AUDIO = "M16,9H13V14.5A2.5,2.5 0 0,1 10.5,17A2.5,2.5 0 0,1 8,14.5A2.5,2.5 0 0,1 10.5,12C11.07,12 11.58,12.19 12,12.5V7H16V9M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M5,5V19H19V5H5Z";

// https://pictogrammers.com/library/mdi/icon/file-delimited-outline/
const ICON_PATH_CSV = "M14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8L14 2M18 20H6V4H13V9H18V20M10 19L12 15H9V10H15V15L13 19H10";

// https://pictogrammers.com/library/mdi/icon/microsoft-excel/
const ICON_PATH_EXCEL = "M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z";

// https://pictogrammers.com/library/mdi/icon/code-json/
const ICON_PATH_JSON = "M5,3H7V5H5V10A2,2 0 0,1 3,12A2,2 0 0,1 5,14V19H7V21H5C3.93,20.73 3,20.1 3,19V15A2,2 0 0,0 1,13H0V11H1A2,2 0 0,0 3,9V5A2,2 0 0,1 5,3M19,3A2,2 0 0,1 21,5V9A2,2 0 0,0 23,11H24V13H23A2,2 0 0,0 21,15V19A2,2 0 0,1 19,21H17V19H19V14A2,2 0 0,1 21,12A2,2 0 0,1 19,10V5H17V3H19M12,15A1,1 0 0,1 13,16A1,1 0 0,1 12,17A1,1 0 0,1 11,16A1,1 0 0,1 12,15M8,15A1,1 0 0,1 9,16A1,1 0 0,1 8,17A1,1 0 0,1 7,16A1,1 0 0,1 8,15M16,15A1,1 0 0,1 17,16A1,1 0 0,1 16,17A1,1 0 0,1 15,16A1,1 0 0,1 16,15Z";

// https://pictogrammers.com/library/mdi/icon/microsoft-powerpoint/
const ICON_PATH_POWERPOINT = "M13.25 3.25Q14.46 3.25 15.58 3.56 16.7 3.88 17.67 4.45 18.64 5 19.44 5.81 20.23 6.61 20.8 7.58 21.38 8.55 21.69 9.67 22 10.79 22 12 22 13.21 21.69 14.33 21.38 15.45 20.8 16.42 20.23 17.39 19.44 18.19 18.64 19 17.67 19.55 16.7 20.13 15.58 20.44 14.46 20.75 13.25 20.75 12.18 20.75 11.15 20.5 10.12 20.24 9.2 19.76 8.28 19.27 7.5 18.58 6.69 17.88 6.07 17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.25 2.5 7 2.83 7H6.07Q6.69 6.12 7.5 5.42 8.28 4.72 9.2 4.24 10.13 3.76 11.15 3.5 12.18 3.25 13.25 3.25M13.88 4.53V11.37H20.72Q20.6 10 20.03 8.81 19.46 7.62 18.55 6.7 17.64 5.79 16.43 5.22 15.23 4.65 13.88 4.53M9.5 10.84Q9.5 10.27 9.3 9.87 9.11 9.46 8.78 9.21 8.45 8.95 8 8.84 7.55 8.72 7 8.72H4.37V15.27H5.91V13H6.94Q7.42 13 7.87 12.84 8.33 12.7 8.69 12.43 9.05 12.17 9.27 11.76 9.5 11.36 9.5 10.84M13.25 19.5Q14.23 19.5 15.14 19.26 16.04 19 16.85 18.58 17.66 18.13 18.33 17.5 19 16.89 19.5 16.13 20 15.36 20.33 14.47 20.64 13.58 20.72 12.62H12.64V4.53Q11.19 4.65 9.91 5.29 8.63 5.93 7.67 7H11.17Q11.5 7 11.76 7.25 12 7.5 12 7.83V16.17Q12 16.5 11.76 16.76 11.5 17 11.17 17H7.67Q8.2 17.6 8.84 18.06 9.5 18.5 10.19 18.84 10.91 19.17 11.68 19.33 12.45 19.5 13.25 19.5M6.85 10Q7.32 10 7.61 10.19 7.89 10.38 7.89 10.89 7.89 11.11 7.79 11.25 7.69 11.39 7.53 11.5 7.37 11.57 7.18 11.6 7 11.64 6.8 11.64H5.91V10H6.85Z";

// https://pictogrammers.com/library/mdi/icon/microsoft-word/
const ICON_PATH_WORD = "M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7.03 11.34L8.23 15.28H9.6L10.91 8.72H9.53L8.75 12.6L7.64 8.85H6.5L5.31 12.62L4.53 8.72H3.09L4.4 15.28H5.77M20.75 19.5V17H8.25V19.5M20.75 15.75V12.63H12V15.75M20.75 11.38V8.25H12V11.38M20.75 7V4.5H8.25V7Z";

// --------------------------------------------------------------------------
export const ATTACHMENT_ICON_PATH_BY_CONTENT_TYPE = {
  "application/json": ICON_PATH_JSON, // .json
  "application/msword": ICON_PATH_WORD, // .doc
  // https://pictogrammers.com/library/mdi/icon/file-pdf-box/
  "application/pdf": "M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M9.5 11.5C9.5 12.3 8.8 13 8 13H7V15H5.5V9H8C8.8 9 9.5 9.7 9.5 10.5V11.5M14.5 13.5C14.5 14.3 13.8 15 13 15H10.5V9H13C13.8 9 14.5 9.7 14.5 10.5V13.5M18.5 10.5H17V11.5H18.5V13H17V15H15.5V9H18.5V10.5M12 10.5H13V13.5H12V10.5M7 10.5H8V11.5H7V10.5Z",
  "application/vnd.ms-excel": ICON_PATH_EXCEL, // .xls
  "application/vnd.ms-powerpoint": ICON_PATH_POWERPOINT, // .ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": ICON_PATH_POWERPOINT, // .pptx
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ICON_PATH_EXCEL, // .xlsx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ICON_PATH_WORD, // .docx
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template": ICON_PATH_WORD, // .dotx
  "audio/aac": ICON_PATH_AUDIO, // .aac
  "audio/basic": ICON_PATH_AUDIO, // .snd
  "audio/m4a": ICON_PATH_AUDIO, // .m4a
  "audio/mid": ICON_PATH_AUDIO, // .mid
  "audio/mpeg": ICON_PATH_AUDIO, // .mp3
  "audio/mp4": ICON_PATH_AUDIO, // .mp4
  "audio/ogg": ICON_PATH_AUDIO, // .ogg
  "audio/vnd.wav": ICON_PATH_AUDIO, // .wav
  "audio/vorbis": ICON_PATH_AUDIO, // .vorbis
  "audio/wav": ICON_PATH_AUDIO, // .wav
  "audio/x-aiff": ICON_PATH_AUDIO, // .aiff
  "audio/x-m4a": ICON_PATH_AUDIO, // .m4a
  "audio/x-mpegurl": ICON_PATH_AUDIO, // .m3u
  "audio/x-wav": ICON_PATH_AUDIO, // .wav
  "text/csv": ICON_PATH_CSV, // .csv
};

// --------------------------------------------------------------------------
// There doesn't seem to be a way to test this, as the browser APIs that list plugins/support only list PDF plugins
// anyway, though other types can be displayed. These seem to be the most commonly viewable in the browser, but it's
// possible some office/document formats can be viewed in some circumstances
function canLikelyDisplayInline(contentType) {
  return contentType === "application/json" || contentType === "application/pdf" ||
    (!window.isAmplenoteEmbedEditor && contentType.startsWith("audio/"));
}

// --------------------------------------------------------------------------
function createAttachmentObjectContainer(data, type, openAttachment) {
  const attachmentObjectContainer = document.createElement("div");
  attachmentObjectContainer.className = "attachment-object-container";

  if (isAndroid && window.isAmplenoteEmbedEditor) {
    // On Android, touch events get canceled - _without_ a touchend or touchcancel event - if the native scrollview
    // that the embed is nested in scrolls vertically due to the touch, so we have to disable that scrolling to get
    // any sort of touch based scrolling in the iframe itself.
    // Since the touch is in the iframe, we don't get touch events here in the parent window for it (and they don't
    // bubble out of the iframe's region), so we need to listen for them in the iframe then send a message out to this
    // parent window so it can tell the host app to disable scrolling.

    const disableScrolling = () => {
      // We don't want to pull in an embed dependency here, so duplicating constant value here
      window.callAmplenoteHostApp("setScrollEnabled" /* EMBED_EDITOR_MESSAGE.SET_SCROLL_ENABLED */, { enabled: false });
    };

    const enableScrolling = () => {
      // We don't want to pull in an embed dependency here, so duplicating constant value here
      window.callAmplenoteHostApp("setScrollEnabled" /* EMBED_EDITOR_MESSAGE.SET_SCROLL_ENABLED */, { enabled: true });
    };

    window.addEventListener("message", event => {
      const { data: message } = event;

      if (typeof(message) === "string") {
        // eslint-disable-next-line default-case
        switch (message) {
          case "pdfjs.touchstart":
          case "pdfjs.touchmove":
            disableScrolling();
            break;

          case "pdfjs.touchend":
          case "pdfjs.touchcancel":
            enableScrolling();
            break;
        }
      }
    });
  }

  let attachmentObject;
  if (window.isAmplenoteEmbedEditor && type === "application/pdf") {
    attachmentObject = createPdfJsIframe(data);
  } else {
    attachmentObject = document.createElement("object");
    attachmentObject.setAttribute("data", data);
    attachmentObject.setAttribute("type", type);
  }
  attachmentObjectContainer.appendChild(attachmentObject);

  attachmentObject.addEventListener("error", _event => {
    if (attachmentObjectContainer.querySelector(".error-message")) return;

    const errorMessage = document.createElement("div");
    errorMessage.className = "error-message";

    const text = document.createElement("span");
    text.textContent = "Attachment cannot be displayed inline.";
    errorMessage.appendChild(text);

    const link = document.createElement("a");
    link.href = data;
    link.textContent = "Open attachment";
    link.addEventListener("click", event => {
      event.preventDefault();
      openAttachment();
    });
    errorMessage.appendChild(link);

    attachmentObjectContainer.insertBefore(errorMessage, attachmentObject);
  });

  return attachmentObjectContainer;
}

// --------------------------------------------------------------------------
function getAttachmentNode(view, getPos) {
  const { state: { doc, schema } } = view;

  const nodePos = getPos();
  const node = doc.nodeAt(nodePos);
  if (!node || node.type !== schema.nodes.attachment) return null;

  return node;
}

// --------------------------------------------------------------------------
function hasAttachmentDataAttribute(node) {
  const { attrs: { data } } = node;
  return data && data.startsWith("attachment://");
}

// --------------------------------------------------------------------------
function hasFailedDataAttribute(node) {
  const { attrs: { data } } = node;
  return data && data.endsWith("?failed");
}

// --------------------------------------------------------------------------
function hasLocalDataAttribute(node) {
  return isLocalFileURL(node.attrs.data);
}

// --------------------------------------------------------------------------
function setAttachmentExpanded(nodePos, expanded) {
  return function(state, dispatch) {
    const { doc, schema } = state;

    const node = doc.nodeAt(nodePos);
    if (!node || node.type !== schema.nodes.attachment) return false;

    if (dispatch) {
      dispatch(state.tr.setNodeMarkup(nodePos, null, { ...node.attrs, expanded }));
    }

    return true;
  }
}

// --------------------------------------------------------------------------
export default class AttachmentView {
  _attachmentObjectContainer = null;
  _expanded = false;
  _link = null;
  _linkIconPath = null;
  _opening = false;
  _pdfToolbarContainer = null;
  _statusText = null;

  // --------------------------------------------------------------------------
  constructor(node, editorView, getPos) {
    this._expand = this._expand.bind(this, editorView);
    this._onDeleteClick = this._onDeleteClick.bind(this, editorView, getPos);
    this._onLinkClick = this._onLinkClick.bind(this, editorView, getPos);
    this.update = this.update.bind(this, editorView);

    this.dom = document.createElement("div");
    this.dom.className = "attachment";

    this._link = document.createElement("a");
    this._link.className = "attachment-link";
    this._link.rel = "noopener noreferrer";
    this._link.target = "_blank";
    this._link.addEventListener("click", this._onLinkClick);
    this.dom.appendChild(this._link);

    const linkIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    linkIcon.setAttribute("class", "attachment-link-icon material-icons");
    linkIcon.setAttribute("viewBox", "0 0 24 24");

    this._linkIconPath = document.createElementNS("http://www.w3.org/2000/svg", "path");
    linkIcon.appendChild(this._linkIconPath);

    this._link.appendChild(linkIcon);

    this._linkText = document.createElement("span");
    this._linkText.className = "attachment-link-text";
    this._link.appendChild(this._linkText);

    this._statusText = document.createElement("span");
    this._statusText.className = "status-text";
    this.dom.appendChild(this._statusText);

    if (editorView.editable) {
      const deleteButton = document.createElement("a");
      deleteButton.className = "delete-button material-icons";
      deleteButton.textContent = "close";
      deleteButton.addEventListener("click", this._onDeleteClick);
      this.dom.appendChild(deleteButton);
    }

    const indicator = document.createElement("a");
    indicator.className = "indicator material-icons";
    this.dom.appendChild(indicator);

    this.update(node);
  }

  // --------------------------------------------------------------------------
  destroy() {
    if (this._pdfToolbarContainer) {
      ReactDOM.unmountComponentAtNode(this._pdfToolbarContainer);
      this.dom.removeChild(this._pdfToolbarContainer);
      this._pdfToolbarContainer = null;
    }
  }

  // --------------------------------------------------------------------------
  ignoreMutation(event) {
    if (event.type === "selection") return;

    // We change the className and status-text content when waiting on openAttachment
    return this._opening;
  }

  // --------------------------------------------------------------------------
  update(editorView, node) {
    if (node.type.name !== "attachment") return false;

    const { props: { hostApp: { openAttachment } } } = editorView;
    const { attrs: { data, expanded, name, type } } = node;

    this._linkIconPath.setAttribute("d", ATTACHMENT_ICON_PATH_BY_CONTENT_TYPE[type] || DEFAULT_ICON_PATH);
    this._linkText.textContent = name;

    const canExpand = openAttachment && canLikelyDisplayInline(type);
    let className = `attachment ${ expanded ? "expanded" : "" } ${ canExpand ? "expandable" : "" }`;

    if (hasLocalDataAttribute(node)) {
      if (hasFailedDataAttribute(node)) {
        className += " failed";
        this._statusText.textContent = "(upload failed)";
      } else {
        className += " uploading";
        this._statusText.textContent = "(uploading)";
      }
      this._link.removeAttribute("href");
    } else {
      if (this._opening) className += " opening";
      this._link.href = data;
      this._statusText.textContent = this._opening ? "(opening)" : "";
    }

    this.dom.className = className;

    if (canExpand && expanded !== this._expanded) {
      this._expanded = expanded;

      if (expanded) {
        this._expand(node);
      } else {
        this._collapse();
      }
    }

    return true;
  }

  // --------------------------------------------------------------------------
  _collapse() {
    this.dom.removeChild(this._attachmentObjectContainer);
    this._attachmentObjectContainer = null;

    if (this._pdfToolbarContainer) {
      ReactDOM.unmountComponentAtNode(this._pdfToolbarContainer);
      this.dom.removeChild(this._pdfToolbarContainer);
      this._pdfToolbarContainer = null;
    }
  }

  // --------------------------------------------------------------------------
  _expand = async (editorView, node) => {
    const { props: { hostApp: { openAttachment } } } = editorView;

    const { attrs: { data: attachmentURL } } = node;

    this._statusText.textContent = "(opening)";
    this._opening = true;
    try {
      const url = await openAttachment(attachmentURL, { getURL: true });
      if (url) {
        this._attachmentObjectContainer = createAttachmentObjectContainer(url, node.attrs.type, () => {
          openAttachment(attachmentURL);
        });

        if (window.isAmplenoteEmbedEditor && node.attrs.type === "application/pdf") {
          this._pdfToolbarContainer = document.createElement("div");
          this._pdfToolbarContainer.className = "pdf-toolbar-container";
          this.dom.appendChild(this._pdfToolbarContainer);

          const iframeRef = { current: this._attachmentObjectContainer };
          ReactDOM.render(
            <PdfToolbar
              attachmentURL={ attachmentURL }
              iframeRef={ iframeRef }
              openAttachment={ openAttachment }
            />,
            this._pdfToolbarContainer
          );
        }

        this.dom.appendChild(this._attachmentObjectContainer);
      }
    } finally {
      this._opening = false;
    }
  };

  // --------------------------------------------------------------------------
  _onDeleteClick = (view, getPos, event) => {
    event.preventDefault();

    const { state, state: { doc, schema } } = view;

    const nodePos = getPos();
    const node = doc.nodeAt(nodePos);
    if (!node || node.type !== schema.nodes.attachment) return;

    view.dispatch(state.tr.delete(nodePos, nodePos + node.nodeSize));
  };

  // --------------------------------------------------------------------------
  _onLinkClick = async (editorView, getPos, event) => {
    const node = getAttachmentNode(editorView, getPos);
    if (!node) return;

    if (hasLocalDataAttribute(node)) {
      event.preventDefault();
      return;
    }

    if (hasAttachmentDataAttribute(node)) {
      event.preventDefault();

      if (this._opening) return;

      const { attrs: { expanded } } = node;

      if (expanded && !hasModifierKey(event)) {
        const { dispatch, state } = editorView;
        setAttachmentExpanded(getPos(), false)(state, dispatch);

        return;
      }

      const { attrs: { data: attachmentURL, type: contentType } } = node;
      const { props: { hostApp: { openAttachment } } } = editorView;

      const canExpand = openAttachment && canLikelyDisplayInline(contentType);
      const isIconClick = event.target && (
        event.target.className === "attachment-link-icon" || !!event.target.closest(".attachment-link-icon")
      );
      const shouldExpand = !hasModifierKey(event) && !isIconClick && canExpand;

      if (shouldExpand) {
        const { dispatch, state } = editorView;
        setAttachmentExpanded(getPos(), true)(state, dispatch);
      } else {
        this._statusText.textContent = "(opening)";
        this._opening = true;
        try {
          await openAttachment(attachmentURL, { openInSidebar: hasModKeyOnly(event) });
        } finally {
          this._opening = false;
        }

        this.update(node);
      }
    }

    // Let the click through, as it's likely a valid data attribute copied from an <object> somewhere online
  };
}
